<script>
    export default {
        inject: ['form'],

        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            implicit: {
                type: Boolean,
                default: false
            },
        },

        watch: {
            other(n, o) {
                const i = this.form.fields.deep_dives.who.demograph_gender.indexOf(o);

                this.form.fields.deep_dives.who.demograph_gender[i] = n;
            }
        },

        data() {
            return {
                other: this.form.fields.deep_dives.who.demograph_gender.find(g => {
                    return ! ['any', 'male', 'female'].includes(g);
                })
            };
        },

        computed: {
            hasOther() {
                return this.form.fields.deep_dives.who.demograph_gender.some(g => {
                    return ! ['any', 'male', 'female'].includes(g);
                });
            }
        }
    }
</script>

<template>
    <div>
        <div class="tabs__content-helper">
            <p class="form-block-help-text">
                It’s important to accurately identify your core customer or audience, and three powerful lenses are: demographics, psychographics, and Jobs To Be Done theory.
            </p>
            <p class="form-block-help-text">
                <i>Example: Generation X men who follow Marie Kondo, enjoy home renovations, use Pinterest, and want to invent a new brand of backpacks.</i>
            </p>
            <div class="form-groups mb-5">
                <div class="form-group">
                    <h2 class="form-group__title is-blue">Now think it through&hellip; you first wrote this:</h2>
                    <NTextarea size="small" v-model="form.fields.challenge.assumption_who" :disabled="disabled || !!form.fields.challenge.slug" />
                </div>
            </div>

            <div class="form-groups">
                <div><!-- Demographics -->
                    <h2 class="form-group__title is-blue mb-2">Demographics – Who is the idea or solution for?</h2>
                    <p class="form-block-help-text text-dust-700">
                        Demographic information includes the "facs" about a person such as:
                    </p>
                    <div class="flex items-center flex-wrap sm:flex-nowrap">
                        <div class="form-group is-inline">
                            <label class="form-group__label">Age</label>
                            <NInput
                                min="0"
                                name="age_from"
                                type="number"
                                class="w-20"
                                v-model="form.fields.deep_dives.who.demograph_age.from"
                                :max="form.fields.deep_dives.who.demograph_age.to"
                                :disabled="disabled"
                            ></NInput>
                            <span>to</span>
                            <NInput
                                name="age_to"
                                type="number"
                                class="w-20"
                                v-model="form.fields.deep_dives.who.demograph_age.to"
                                :min="form.fields.deep_dives.who.demograph_age.from"
                                :disabled="disabled"
                            ></NInput>
                        </div>
                        <NCheckboxGroup label="Gender" class="form-group is-inline is-checkbox" name="gender" v-model="form.fields.deep_dives.who.demograph_gender">
                            <NCheckbox label="Any/All" value="any" :disabled="disabled"></NCheckbox>
                            <NCheckbox label="Female" value="female" :disabled="disabled"></NCheckbox>
                            <NCheckbox label="Male" value="male" :disabled="disabled"></NCheckbox>
                            <div class="flex items-center">
                                <NCheckbox label="Other:" :modelValue="other" :disabled="disabled"></NCheckbox>
                                <input v-show="hasOther"
                                    type="text"
                                    class="ml-3 underline-input"
                                    :disabled="disabled"
                                    v-model="other"
                                >
                            </div>
                        </NCheckboxGroup>
                    </div>
                    <p class="italic text-dust-700 mt-3">In the next 3 fields, you can add more than one word, separated by comma or return.</p>
                    <div class="grid grid-cols-2 gap-4 mt-3">
                        <div>
                            <div class="form-group">
                                <label class="form-group__label">Occupation</label>
                                <NTags name="interests_occupation" v-model="form.fields.deep_dives.who.occupation" placeholder="Type here" :disabled="disabled" />
                            </div>
                            <div class="form-group mt-3">
                                <label class="form-group__label">Location <span class="form-inline-help-text">(geography: country, state/region, city)</span></label>
                                <NTags name="interests_location" v-model="form.fields.deep_dives.who.location" placeholder="Type here" :disabled="disabled" />
                            </div>
                            <div class="form-group mt-3">
                                <label class="form-group__label">Media Consumed <span class="form-inline-help-text">(magazines, TV shows, social media outlets)</span></label>
                                <NTextarea name="interests_media" v-model="form.fields.deep_dives.who.media_consumed" :disabled="disabled" />
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div class="form-group w-full">
                    <h2 class="form-group__title is-blue">Psychographics</h2>
                    <p class="form-block-help-text">
                        People who are in the same <em>demographic</em> group might not fall into the same <em>psychographic</em> group, because individuals have different: attitudes, habits, principles, beliefs, activities, interests, preferences, personalities, social class, lifestyle
and values that make them very unique.
                    </p>
                    <div class="form-group w-full">
                        <label class="form-group__label">Creative Interests, Hobbies, Activities, Lifestyle, Preferences, Attitudes, Beliefs, etc.</label>
                        <NTags name="interests_hobby" v-model="form.fields.deep_dives.who.who_interests_hobbies" placeholder="Type here; press return (keyboard) or tap outside tag (touchscreen)  to start a new tag or phrase" :disabled="disabled" />
                        <!-- TO DO: Verify this can support 2000 chars in DB -->
                    </div>

                </div>
                <div class="form-group">
                    <h2 class="form-group__title is-blue">Job To Be Done Theory</h2>
                    <p class="form-block-help-text">
When your audience or customer makes a choice to use your idea or solution, they are trying to fulfill an unmet need. Successful innovations emerge from unfulfilled jobs to be done (JTBD). Identify a few possibilities. Consider a format like: “When I ____ But _____ Help me _____ So I ______” as suggested by Sunita Mohanty while at Facebook (Google her!). <em>(Suggestion: read Clayton Christensen’s books on JTBD.)</em>
                    </p>
                    <label class="form-group__label">
Describe a few reasons why your audience would consider your product or service; what are they trying to do? What is their context, barriers, goals, and desired outcomes?
                </label>
                    <NTextarea v-model="form.fields.deep_dives.who.jobs_to_be_done" placeholder="Describe a few jobs to be done" :disabled="disabled" />
                </div>
                    <p class="form-block-help-text has-margin-top">Now let’s analyze this “why” deeper in the <b>WHY</b> worksheet.</p>
            </div>
        </div>
        <div v-if="!implicit" class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <b>Note:</b> these worksheets are helpful to the community of innovators because they can understand your
                thought process and your point of view. They can see your initial “exploratory” question and your honed final question.
            </p>
        </div>
    </div>
</template>
