<script setup>
import { useAuth } from '@/composable/auth'
import { inject } from 'vue'

defineProps({
    hideTips: Boolean,
})

const form = inject('form')
const { auth } = useAuth()
const options = [
    { value: 'points', label: 'Points' },
    { value: 'other', label: 'Other...' },
]
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">
                <b>Add a prize and/or money?</b>
            </h2>
            <p class="challenge-section-heading__description">
                This money or prize is a <b>pledge</b>. If you don't get useful ideas, you don't have to fulfill the
                pledge. But be careful about your reputation!
            </p>
        </div>
        <div class="form-section-wrapper mb-4">
            <div class="two-col-icon-helper">
                <div class="two-col-icon-helper__icon">
                    <span aria-hidden="true" class="icon icon-trophy-solid"></span>
                </div>
                <div class="two-col-icon-helper__content">
                    <NRadioGroup v-model="form.fields.prize.prize_type" label="Describe the category of prize:"
                        class="!flex items-center has-block-label" name="prize_type">
                        <div class="flex flex-col sm:flex-row sm:items-center gap-2">
                            <NRadio v-for="option of options" :modelValue="option.value" :label="option.label"
                                :key="option.value" />
                        </div>
                    </NRadioGroup>
                    <div v-if="form.fields.prize.prize_type === 'other'" class="flex flex-col gap-4 mt-4">
                        <div class="form-group flex flex-col sm:!flex-row">
                            <label class="form-group__label !w-[6rem] !grow-0">Prize Title:</label>
                            <div class="max-w-[10rem]">
                                <NInput
                                    v-model="form.fields.prize.prize_title"
                                    name="prize_title"
                                    placeholder="Short Label"
                                />
                            </div>
                            <div class="mr-auto sm:px-4 py-2 flex items-center">
                                <span class="italic text-sm text-dust">Required. Max 8 characters.</span>
                            </div>
                        </div>
                        <div class="form-group flex flex-col sm:!flex-row !items-start">
                            <label class="form-group__label !w-[6rem] !grow-0">Description:</label>
                            <div class="sm:!w-[20rem]">
                                <NTextarea
                                    v-model="form.fields.prize.prize_description"
                                    name="prize_description"
                                    placeholder="Describe the prize succinctly"
                                />
                            </div>
                            <div class="mr-auto sm:px-4 py-2 sm:max-w-[16rem] flex items-center">
                                <span class="italic text-sm text-dust">Required. Max 1024 characters.
                                    This displays in the tool tip.</span>
                            </div>
                        </div>
                        <p class="italic form-block-help-text mb-0 mt-3 w-full">
                            Examples: I'll mention your idea on my podcast; I'll take you out to dinner in August; You
                            earn a 3-month internship at my company; You can borrow my car for 1 week; I'll buy you a
                            beer;
                        </p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="two-col-icon-helper">
                <div class="two-col-icon-helper__icon">
                    <NIcon as="money-bill-alt-regular" />
                </div>
                <div class="two-col-icon-helper__content">
                    <div class="grid grid-cols-12 gap=4">
                        <div class="col-span-12 sm:col-span-5">
                            <div class="form-group flex flex-col sm:!items-center sm:!flex-row">
                                <label class="form-group__label !min-w-[10rem] !grow-0">Add Prize Money:</label>
                                <NInput v-model="form.fields.prize.prize_money" placeholder="0" name="prize_money"
                                    type="number">
                                    <template #prepend>
                                        <span class="font-bold text-dust-800 mr-2">$</span>
                                    </template>
                                </NInput>
                            </div>
                        </div>
                    </div>
                    <p class="form-block-help-text mt-3 mb-0">
                        You can add more money any time if you're not getting enough ideas. We'll add these funds from your account balance. If there are not enough funds, we'll draw from your <a href="#" class="is-text-underline is-color-black" target="_blank">linked payment method</a>. If this challenge has an Entry Fee, this prize money is added fully to the participant Prize Total, not returned back to you in your share.
                    </p>
                </div>
            </div>
            <hr>
            <div class="two-col-icon-helper">
                <div class="two-col-icon-helper__icon">
                    <NIcon as="funding" />
                </div>
                <div class="two-col-icon-helper__content">
                    <div class="grid grid-cols-12 gap=4">
                        <div class="col-span-12 sm:col-span-5">
                            <div class="form-group flex flex-col sm:!items-center sm:!flex-row">
                                <label class="form-group__label !min-w-[10rem] !grow-0">Add Entry Fee:</label>
                                <NInput v-model.number="form.fields.idea_entry_process.idea_entry_fee" placeholder="0" name="idea_entry_fee"
                                    type="number">
                                    <template #prepend>
                                        <span class="font-bold text-dust-800 mr-2">$</span>
                                    </template>
                                </NInput>
                            </div>
                        </div>
                    </div>
                    <p class="form-block-help-text mt-3 mb-0">
                        An Entry Fee makes your challenge "gated" so that innovators need to pay money to enter ideas into your challenge. When the challenge closes, you keep 65% of the collected amount, 28% is added to the Challenge Prize Total (for the participants), and our platform fee is 7%. A running total of the crowd's share will be shown in the challenge card as it grows.
                    </p>
                </div>
            </div>
        </div>
        <div v-if="!hideTips" class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <div class="info-box__content">
                <p>
                    <b>Tip:</b> Adding a prize to your challenge can increase participation. If you choose money, the amount is split between the top one-third of the players and is apportioned by the neurons they earned for mentoring, rating, sharing and discussing. <RouterLink :to="{ name: 'faqs.trust-score' }" target="_blank">Learn more</RouterLink>.
                </p>
                <p>
                    This money or prize is a <b>pledge</b>. If you don't get useful ideas, you don't have to fulfill the pledge. But be careful about disappointing your players or your <b>trust score</b> will drop. <RouterLink :to="{ name: 'faqs.trust-score' }" target="_blank">Learn more</RouterLink>. 
                </p>
                <p>
                    If you are adding an <b>Entry Fee</b>, we suggest seeding the question with starter <b>Prize Money</b>. This helps some winners earn the fee amount back if they place highly. 
                </p>
                <p>
                    After you post your challenge, visit your <RouterLink :to="{ name: 'dashboard.catalyst', params: { user: auth.user.screen_name || 'me' } }" target="_blank">Catalyst Dashboard</RouterLink> to manage a running challenge.
                </p>
            </div>
        </div>
    </div>
</template>
