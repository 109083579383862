
export default new Proxy({
    'default' (detail) {
        return {
            name: 'challenges.show',
            params: { challenge: detail.challenge_slug || 'empty' },
        };
    },
    'MentorCommentRatedByInnovator': {
        name: 'dashboard.mentoring',
        params: {
            user: 'me',
        },
        query: {
            tab: 'received',
        },
    },
    'MentorCommentRatedByPeer': {
        name: 'dashboard.mentoring',
        params: {
            user: 'me',
        },
        query: {
            tab: 'received',
        },
    },
}, {
    get (target, key) {
        const handler = target[key] || target['default']

        if (typeof handler === 'function') {
            return handler
        }

        return () => handler
    }
})