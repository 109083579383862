import moment from 'moment';

export const rankColors = ['#CADA2A', '#B8529E', '#CB8623', '#6FCBDC', '#FF9F0A', '#F9EC23',
    '#3AB44A', '#C0272C', '#0D71B9', '#EC1F78'
];

export const roleColors = {
    overall: '#3ACBAB',
    innovator: '#3ACBAB',
    scout: '#A44CF1',
    rater: '#F5A623',
    analyst: '#F5A623',
    mentor: '#4A90E2',
    catalyst: '#D0021B',
    insightful: '#F8E71C',
};

export const series = [{
        user: {
            id: 'XYZ',
            first_name: 'John',
            last_name: 'Doe',
            screen_name: 'johndoe',
            avatar_url: '',
        },
        level: 0,
        rank: 0,
        score: 0,
        history: [{
            date: moment().utc().subtract(1, 'day'),
            points: 0,
            level: 0,
        }, {
            date: moment().utc(),
            points: 0,
            level: 0,
        }],
    },

    {
        user: {
            id: 'B-XYZ',
            first_name: 'Lucas',
            last_name: 'Abud',
            screen_name: 'lucas',
            avatar_url: 'https://dev-challenge-images.s3-us-west-2.amazonaws.com/CgAgArBPFVEymHmbCban08rFFqKUEMN60AHE.jpeg',
        },
        level: 0,
        rank: 0,
        score: 0,
        history: [{
            date: moment().utc().subtract(1, 'day'),
            points: 0,
            level: 0,
        }, {
            date: moment().utc(),
            points: 0,
            level: 0,
        }],
    },


    {
        user: {
            id: 'C-XYZ',
            first_name: 'Karina',
            last_name: 'Iskandarova',
            screen_name: 'karina_iskandarova',
            avatar_url: 'https://dev-challenge-images.s3-us-west-2.amazonaws.com/guGUEWTmArVugmGpK6jzW3nVnAB8bJtPc7HU.jpeg',
        },
        level: 0,
        rank: 0,
        score: 0,
        history: [{
            date: moment().utc().subtract(1, 'day'),
            points: 0,
            level: 0,
        }, {
            date: moment().utc(),
            points: 0,
            level: 0,
        }],
    }
]
