<script>
import { cn } from '@/utils/Helpers';
import { h, ref, watch } from 'vue';
import { useMarkdown, toHTML } from '@/mixins/Markdown';
import { truncateHTML } from '@/utils/Helpers';

export default {
    mixins: [useMarkdown()],

    props: {
        tag: {
            type: String,
            default: 'div',
        },
        code: {
            type: [String, Array],
            default: '',
        },
        inline: {
            type: Boolean,
            default: false,
        },
        unfurl: {
            type: Boolean,
            default: false,
        },
        truncate: {
            type: Number,
            default: null
        }
    },

    emits: ['link-clicked'],

    setup (props, { slots, attrs }) {
        const innerHTML = ref('');
        const wantsList = ref(false);

        const render = (code) => {
            if (!code) {
                const content = slots.default ? slots.default() : [];

                if (content.length > 0) {
                    const { children } = content[0]

                    code = children;
                }
            }

            // wants to make list
            wantsList.value = Array.isArray(code);
            if (wantsList.value) {
                code = code.map(c => `- ${c}\n`).join('');
            }

            if (typeof code !== 'string') {
                console.warn(`Invalid type ${typeof code}, expected string`, code);
                code = ''
            }

            const html = toHTML(code, {
                inline: props.inline,
                unfurl: props.unfurl,
                mention: true,
            })

            innerHTML.value = props.truncate ? truncateHTML(html, { length: props.truncate }) : html;
        }

        watch(() => [props.code, props.truncate], ([newValue]) => {
            render(newValue);
        }, { immediate: true });

        return () => h(props.tag, {
            class: cn('prose prose-markdown prose-stone max-w-[75ch] prose-pre:rounded-md prose-p:whitespace-pre-wrap prose-p:break-words leading-6 prose-p:leading-6 prose-pre:bg-[#282c34] prose-p:my-0 prose-blockquote:my-2 prose-blockquote:border-l-4 prose-blockquote:border-dust-300 prose-blockquote:pl-2 prose-blockquote:text-dust-500 prose-blockquote:italic', wantsList.value && 'mb-4', attrs.class),
            innerHTML: innerHTML.value,
        });
    }
}
</script>
