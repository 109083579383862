import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';

import '@/assets/sass/preset.css';
import '@/assets/sass/app.scss';

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);

// install modules
const modules = import.meta.glob('./modules/*.js', { eager: true });
Object.values(modules)
      .forEach(i => i.install({ app, router }));

app.mount('#app');