<script>
import { h, computed } from 'vue';
import { nanoid } from 'nanoid'
import closable from '@/mixins/Closable';

export default {
    name: 'Overlay',

    mixins: [closable],

    props: {
        absolute: Boolean,
        color: {
            type: String,
            default: '#212121'
        },
        opacity: {
            type: [Number, String],
            default: 0.46
        },
        value: {
            default: true
        },
        zIndex: {
            type: [Number, String],
            default: 5
        }
    },

    emits: ['overlayClick', 'click'],

    setup (props, { slots, emit }) {
        const uid = `overlay-wrap-${nanoid()}`;
        const styles = computed(() => {
            return { zIndex: props.zIndex };
        });
        const opened = computed(() => props.modelValue);
        const computedOpacity = computed(() => {
            return Number(opened ? props.opacity : 0)
        })
        const classes = computed(()=> {
            return {
                'overlay-wrap--absolute': props.absolute,
                'overlay-wrap--opened': opened,
            };
        })
        const __scrim = computed(() => {
            return h('div', {
                class: 'overlay-wrap__scrim',
                id: uid,
                style: {
                    opacity: computedOpacity.value,
                    backgroundColor: props.color,
                },
            });
        })

        const getContent = () => {
            return h(
                'div',
                { class: 'overlay-wrap__content' },
                slots.default && slots.default()
            );
        }

        return () => h('div', {
            class: [classes.value, 'overlay-wrap'],
            style: styles.value,
            onClick: (e) => {
                if (e.target.id === uid) {
                    emit('overlayClick', e)
                }

                emit('click', e);
            },
        }, [
            __scrim.value, opened.value ? getContent() : null
        ]);
    }
}
</script>
