<script setup>
import { gameSetting } from '@/utils/challenge';
import { computed } from 'vue';

const props = defineProps({
    challenge: {
        type: Object,
        required: true,
    },
    total: {
        type: [Number, Function],
        default: 0,
    },
});

const maxUserIdeas = computed(() => gameSetting(props.challenge, 'max_user_ideas', 3));
const target = computed(() => typeof props.total === 'function' ? props.total({ max: maxUserIdeas.value }) : props.total);
</script>

<template>
    <div class="flex items-center gap-2">
        <span class="text-dust-500 italic leading-none">Your ideas:</span>
        <span class="text-innovator-500 flex items-center gap-1 -mt-1">
            <NIcon
                v-for="i in maxUserIdeas"
                :as="target >= i ? 'lightbulb' : 'lightbulb-o1'"
                :key="i"
                class="text-lg"
                :id="`idea-${i}`"
            />
        </span>
        <span v-if="challenge.idea_entry_fee" class="text-dust-500 italic leading-none">
            for <b class="text-dust-700">${{ challenge.idea_entry_fee }}</b>
        </span>
    </div>
</template>