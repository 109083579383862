/** @module assumptions */
export default requester => {
    return {
        /**
         * Insight system - allow catalyst to rank proposed assumptions from 1-3,
         * given the id of the assumption to rate.
         *
         * @param {string} assumptionId The id of the relevant assumption to vote on
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        catalystVote(assumptionId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/assumptions/${assumptionId}/catalyst-vote`, {}, params, headers);
        },

        /**
         * Insight system - allow users to place up to 3 kudos per challenge (separate from ranking 1-3 or voting),
         * given the id of the assumption to which to give kudos.
         *
         * @param {string} assumptionId The id of the relevant assumption to vote on
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         * @param {Object} params Additional query arguments to append to the URL.
         */
        kudos(assumptionId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/assumptions/${assumptionId}/kudos`, {}, params, headers);
        },

        /**
         * Insight system - Allow catalyst to remove any of their ranked 1-3 votes from a given assumption.
         *
         * @param {string} assumptionId The id of the relevant assumption to vote on
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         * @param {Object} params Additional query arguments to append to the URL.
         */
        removeCatalystVote(assumptionId, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/assumptions/${assumptionId}/remove-catalyst-vote`, {}, params, headers);
        },

        /**
         * Insight system - Allow users to remove any of their 3 votes from
         * the assumptions (and place them on another assumption).
         *
         * @param {string} assumptionId The id of the relevant assumption to vote on
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        removeVote(assumptionId, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/assumptions/${assumptionId}/remove-vote`, {}, params, headers);
        },

        /**
         * Insight system - Allow users to place up to 3 votes on these new assumptions
         * (given the id of the assumption) (1 vote per assump, no vote for own).
         *
         * @param {string} assumptionId The id of the relevant assumption to vote on
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        vote(assumptionId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/assumptions/${assumptionId}/vote`, {}, params, headers);
        }
    }
}
