// modules

import "quill-mention";
import 'quill-paste-smart';

import Quill from 'quill';
import MagicUrl from'quill-magic-url';
import MarkdownShortcuts from "quill-markdown-shortcuts";

export const install = ({ app }) => {
    // register modules
    Quill.register('modules/magicUrl', MagicUrl);
    Quill.register('modules/markdownShortcuts', MarkdownShortcuts);

    const icons = Quill.import('ui/icons');

    icons['undo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>';
    icons['redo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>';
};