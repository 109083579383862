<script>
import NIcon from '@/components/NIcon.vue';
import { useRoute } from 'vue-router';
import { usePlatform } from '@/mixins/ShowsPlatform';
import { computed } from 'vue';

export default {
    mixins: [usePlatform()],
    
    components: {
        NIcon,
    },

    setup () {
        const route = useRoute();
        const handle = computed(() => route.params.user);

        // const menus = computed(() => [
        //     {
        //         label: 'Message Center',
        //         icon: 'comments-solid',
        //         to: { name: 'dashboard.messages', params: { user: handle } },
        //         hidden: true,
        //     },
        //     {
        //         label: 'My Ideas',
        //         icon: 'innovator',
        //         to: { name: 'dashboard.innovator', params: { user: handle } },
        //     },
        //     {
        //         label: 'My Mentoring',
        //         icon: 'mentor',
        //         to: { name: 'dashboard.mentoring', params: { user: handle } },
        //     },
        // ]);

        return { handle };
    },
}
</script>

<template>
    <div class="page__sidebar-inside">
        <ul>
            <li class="non-functional">
                <RouterLink :to="{ name: 'dashboard.messages', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="comments-solid" class="inline-block w-[1.5rem]" />
                    Message Center
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.innovator', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="innovator" class="inline-block w-[1.5rem] pl-[.2rem]" />
                    My Ideas
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.mentoring', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="mentor" class="inline-block w-[1.5rem]" />
                    My Mentoring
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.catalyst', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="catalyst" class="inline-block w-[1.5rem]" />
                    My Questions
                </RouterLink>
                <ul class="non-functional">
                    <li>
                        <RouterLink :to="{ name: 'challenges.create' }" active-class="!text-nerve">
                            Ask a Question
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'challenges.create' }" active-class="!text-nerve">
                            Test an Idea
                        </RouterLink>
                        <!-- TO DO: add router link to TEST IDEA category flow directly -->
                    </li>
                </ul>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.rater', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="rater" class="inline-block w-[1.5rem]" />
                    My Ratings
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.scouting', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="scout" class="inline-block w-[1.5rem]" />
                    My Players
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.money-prizes', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="money-bill-alt-regular" class="inline-block text-md w-[1.5rem]" />
                    Money &amp; Prizes
                </RouterLink>
            </li>
            <li class="non-functional">
                <RouterLink :to="{ name: 'dashboard.insightful', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="insightful" class="inline-block w-[1.5rem]" />
                    Insightful
                </RouterLink>
            </li>
            <FeatureFlag flag="director-dashboard">
                <li>
                    <RouterLink :to="{ name: 'dashboard.director', params: { user: handle } }" active-class="!text-nerve">
                        <NIcon as="director" class="inline-block w-[1.5rem]" />
                        Director
                    </RouterLink>
                    <ul class="non-functional">
                        <li>
                            <RouterLink :to="{ name: 'dashboard.director', params: { user: handle } }" active-class="!text-nerve">
                                My Olympiad
                            </RouterLink>
                        </li>
                        <li>Custom Settings</li>
                        <li>
                            <RouterLink :to="{ name: 'dashboard.suggestion-box', params: { user: handle } }" active-class="!text-nerve">
                                QQs <span class="sub ml-0">- Suggestion Box</span>
                            </RouterLink>
                        </li>
                        <li>
                            ReNerves &amp; Subscriptions
                        </li>
                    </ul>
                </li>
            </FeatureFlag>
            <li class="non-functional">
                <RouterLink :to="{ name: 'dashboard.notifications', params: { user: handle } }" active-class="!text-nerve">
                    <strong>Game Dash Notifications</strong>
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.my-progress', params: { user: handle } }" active-class="!text-nerve">
                    <NIcon as="kpi-chart" class="inline-block w-[1.5rem]" />
                    My Progress
                </RouterLink>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'dashboard.my-progress.score-stats', params: { user: handle } }" active-class="!text-nerve">
                            Scores &amp; Stats
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'dashboard.notifications', params: { user: handle } }" active-class="!text-nerve">
                            Activity
                        </RouterLink>
                    </li>
                    <li class="non-functional">
                        <RouterLink :to="{ name: 'dashboard.leaderboards', params: { user: handle } }" active-class="!text-nerve">
                            Leaderboards
                        </RouterLink>
                    </li>
                    <li class="non-functional">
                        <RouterLink :to="{ name: 'dashboard.impact', params: { user: handle } }" active-class="!text-nerve">
                            My Impact
                        </RouterLink>
                    </li>
                    <li class="non-functional">
                        <RouterLink :to="{ name: 'dashboard.awards', params: { user: handle } }" active-class="!text-nerve">
                            Awards &amp; Badges
                        </RouterLink>
                    </li>
                </ul>
            </li>
            <li class="non-functional">
                <RouterLink :to="{ name: 'dashboard.friends', params: { user: handle } }" active-class="!text-nerve">
                    Friends &amp; Following
                </RouterLink>
            </li>
            <li class="non-functional">
                <RouterLink :to="{ name: 'dashboard.my-labs', params: { user: handle } }" active-class="!text-nerve">
                    My Teams <span class="sub ml-0">- Idea Labs</span>
                </RouterLink>
            </li>
            <li class="non-functional">
                <RouterLink :to="{ name: 'dashboard.nerve-on-ah.public', params: { user: handle } }" active-class="!text-nerve">
                <strong>Nerv On Ah</strong> <span class="icon icon-code-fork" aria-hidden="true"></span>
                </RouterLink>
                <ul>
                    <li>
                        <RouterLink :to="{ name: 'dashboard.nerve-on-ah.public', params: { user: handle } }" active-class="!text-nerve">
                            <span class="sub ml-0">Closed Challenges: Public </span>
                        </RouterLink>
                    </li>

                    <li>
                        <RouterLink :to="{ name: 'dashboard.nerve-on-ah.private', params: { user: handle } }" active-class="!text-nerve">
                            <span class="sub ml-0">Closed Challenges: Private </span>
                        </RouterLink>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="non-functional">
            <li><a href="/discovernervus" class="">Nerv Us System</a></li>
            <li>
                <RouterLink :to="{ name: 'dashboard.idea-log', params: { user: handle } }" active-class="!text-nerve">
                   My Idea Log
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.resume', params: { user: handle } }" active-class="!text-nerve">
                   My Innovation Resume
                </RouterLink>
            </li>
            <li><a href="#" class="disabled">Money</a></li>
            <li><a href="#" class="disabled">Patron Funding</a></li>
            <li><a href="#" class="disabled">Projects &amp; Prototypes</a></li>
        </ul>
        <ul>
            <li class="pt-2">
                <em>Shortcuts:</em>
            </li>
            <li :class="{ disabled: !currentUser.user.id }">
                <RouterLink :to="{ name: 'account.my-account' }">
                    My Account
                </RouterLink>
            </li>
            <li>
                <a href="/nerve-boards">Nerve Boards</a><!-- to fix later -->
            </li>
            <li>
                <RouterLink :to="{ name: 'faqs' }">FAQs</RouterLink>
            </li>
        </ul>
    </div>
</template>
