<script setup>
import { computed, ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import { cn } from '@/utils/Helpers';

const props = defineProps({
  source: String,
  noContent: Boolean,
  class: [String, Object, Array],
})

const el = ref(null)
const source = computed(() => props.source)
const { copy, copied, isSupported } = useClipboard()

const copyit = () => {
    if (source.value) {
        return copy(source.value)
    }

    copy(el.value?.innerText)
}
</script>


<template>
  <span ref="el" :class="cn('flex items-center', props.class)">
    <slot v-if="!noContent" :copyit="copyit" :copy="copy" :copied="copied" :source="source">{{ source }}</slot>
    <button aria-label="button"
        v-if="isSupported"
        v-tooltip="'Click to copy'"
        type="button"
        class="inline-block focus:outline-none"
        :class="{ 'text-gray-500 hover:text-gray-600': !copied, 'ml-2': !noContent }"
        @click="copyit(source)"
    >
      <NIcon v-if="!copied" as="clone-regular"  />
      <NIcon v-else as="check-solid" />
    </button>
  </span>
</template>
