<script setup>
import { api } from '@/modules/services';
import { computed, ref } from 'vue';
import { useAuth } from '@/composable/auth';
import { useUserPlatform } from '@/composable/platform';
import { useRoute } from 'vue-router';
import Markdown from '@/components/NMarkdown.vue';
import HeaderLinks from "@/layouts/partials/HeaderLinks.vue";
import ArticleDisplay from '@/components/tools/article/Display.vue' 

const { auth } = useAuth();
const { account } = useUserPlatform();

const route = useRoute();
const bio = ref(null);
const loading = ref(false);
const profile = computed(() => {
    return account.value && account.value.profile;
});

const fetchBio = async () => {
    try {
        loading.value = true;
        const { data: about } = await api.content.find('bio', route.params.user);
        const { data: profile } = await api.user.profile(route.params.user);

        if (about.data.id) {
            bio.value = about.data;
        }

        account.value.profile = profile.data;
    } catch (e) {
        console.log(e)
    } finally {
        loading.value = false;
    }
}

const isMyBio = computed(() => {
    return auth.user.screen_name === route.params.user;
});

fetchBio();
</script>

<template>
    <div class="page">
        <HeaderLinks class="mt-4 mb-8" />

        <div class="border border-dust-300 rounded-md overflow-hidden sheet shadow mb-6">
            <div v-if="!loading" class="bg-white p-10"> 
                <div v-if="account.user.bio" class="flex flex-col gap-2 mb-5 border-b border-dashed pb-5">
                    <h2 class="m-0">Brief blurb</h2>
                    <Markdown :code="account.user.bio" class="display-block-text max-w-fit" />
                </div>
                <div v-if="bio" class="flex flex-col gap-2 mb-10 border-b border-dashed pb-5">
                    <div class="flex items-center justify-between">
                        <h2 class="m-0">Bio</h2>
                        <div v-if="isMyBio" class="flex items-center gap-2">
                            <RouterLink v-tooltip="'Edit'" :to="{ name: 'account.my-account.profile', hash: '#bio' }" class="hover:!bg-dust-100 hover:!text-dust-800 bg-dust-50 text-dust-700 rounded-full h-8 w-8 flex items-center justify-center">
                                <NIcon as="pencil-alt-solid" />
                            </RouterLink>
                        </div>
                    </div>
                    
                    <ArticleDisplay v-if="bio" :modelValue="bio" />
                </div>

                <div v-if="profile.bio" class="grid grid-cols-1 sm:grid-cols-2">
                    <div class="flex flex-col gap-2">
                        <div class="flex items-center justify-between">
                            <h2 class="m-0">School</h2>
                        </div>
                        <p v-if="profile.bio.college">
                            {{ profile.bio.college }}
                        </p>
                        <p v-else class="text-dust-400">Not filled in yet.</p>
                    </div>

                    <div class="flex flex-col gap-2">
                        <div class="flex items-center justify-between">
                            <h2 class="m-0">Work</h2>
                        </div>
                        <p v-if="profile.bio.work_place">
                            {{ profile.bio.work_place }}
                        </p>
                        <p v-else class="text-dust-400">Not filled in yet.</p>
                    </div>

                    <div class="flex flex-col gap-2">
                        <div class="flex items-center justify-between">
                            <h2 class="m-0">Location</h2>
                        </div>
                        <p v-if="profile.bio.geographical_location">
                            {{ profile.bio.geographical_location }}
                        </p>
                        <p v-else class="text-dust-400">Not filled in yet.</p>
                    </div>

                    <div class="flex flex-col gap-2">
                        <div class="flex items-center justify-between">
                            <h2 class="m-0">Interests</h2>
                        </div>
                        <div v-if="profile.bio.interests && profile.bio.interests.length" class="flex flex-wrap gap-2 items-center">
                            <span v-for="interest of profile.bio.interests" :key="interest" class="px-4 text-sm py-1 rounded-full bg-gray-100">
                                {{ interest }}
                            </span>
                        </div>
                        <p v-else class="text-dust-400">Not filled in yet.</p>
                    </div>
                </div>

                
            </div>

            <div v-else class="m-10 rounded-md bg-dust-200 w-auto h-[40rem] animate-loading"></div>
        </div>
    </div>
</template>