/** @module ideaOlympiad */
export default requester => {
    return {
        /**
         * Retrieves the most recent idea olympiad standings up to the specified timestamp
         * (possibly delayed by some small, fixed time offset) for the authenticated user who is calling the endpoint.
         *
         * See https://github.com/bignerve/game-api/issues/32
         * @param {string} olympiadId The id of the olympiad for which to retrieve the current standings
         * @param {string} ideaOlympiadQueryTime The timestamp to use for the END of the query range (i.e. the query will return results between the start of the olympiad and the given timestamp)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        currentStandings(olympiadId, ideaOlympiadQueryTime, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET',
                '/idea-olympiad/current-standings',
                Object.assign({ olympiadId, 'idea-olympiad-query-time': ideaOlympiadQueryTime }, params),
                headers
            );
        },

        /**
         * Increases the duration of the current public olympiad from 30 days to 60 days,
         * from 60 days to 90 days, or from 30 days to 90 days. Durations cannot be reduced.
         * Also adjusts the start time of any scheduled olympiad so that
         * it begins at the new end time of the current olympiad and does not overlap.
         *
         * @param {string} newDuration The new duration (one of 60, 90)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        increaseDuration(newDuration, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', '/idea-olympiad/increase-duration', {}, Object.assign({ newDuration }, params), headers
            );
        },

        /**
         * Updates the authenticated user’s olympiad pause state (to true or false).
         *
         * @param {string} profanityAllowed A string (either true or false) indicating the users intended olympiad profanity setting
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        setProfanity(profanityAllowed, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', '/idea-olympiad/profanity-setting', {}, Object.assign({ profanityAllowed }, params), headers
            );
        },

        /**
         * Schedules an idea olympiad to begin immediately after the end of the current one.
         *
         * @param {string} endTime The time at which the idea olympiad should end (cannot be more than 90 days after the start time and should be in RFC 3339 format)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        scheduleNext(endTime, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', '/idea-olympiad/schedule-next', {}, Object.assign({ endTime }, params), headers
            );
        },

        /**
         * Updates the authenticated user’s olympiad pause state (to true or false).
         *
         * @param {string} isPaused A string (either true or false) indicating the users intended olympiad state (paused or unpaused)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        updateState(isPaused, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', '/idea-olympiad/update-state', {}, Object.assign({ isPaused }, params), headers
            );
        },

        /**
         * Returns a boolean indicating whether the user is authorized to participate in that particular olympiad.
         *
         * @param {string} olympiadId The id of the olympiad to check
         * @param {string} userId The id of the user to check
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        userCanParticipate(userId, olympiadId, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/idea-olympiad/user-is-authorized', Object.assign({ olympiadId, userId }, params), headers
            );
        }
    }
}
