<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    fire: {
        type: Boolean,
        default: false,
    },
    tag: {
        type: String,
        default: 'span',
    },
    fireClass: {
        type: String,
        default: '-mt-10',
    },
});

const firing = ref(false);

watch(() => props.fire, (value) => {
    if (value) {
        firing.value = true;

        setTimeout(() => {
            firing.value = false;
        }, 4000);
    }
}, { immediate: true });
</script>

<script>
export default {
    name: 'NFireworks',
}
</script>

<template>
    <component :is="tag">
        <slot />

        <span v-if="firing" class="pyro fadeOut" :class="fireClass">
            <span class="before -mt-1"></span>
            <span class="after -mt-1"></span>
        </span>
    </component>
</template>
