<script setup>
import bus from '@/modules/bus';
import { ref, watch } from 'vue';
import { usePlatform } from '@/composable/platform';
import { useRouteQuery, useRouteParams } from '@vueuse/router';
import { useRouter, useRoute } from 'vue-router';
import { useMagicKeys, whenever } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const input = ref(null);
const { title, isUserPlatform, isBignerve } = usePlatform();
const includeCatalyst = useRouteQuery('ct', 'off');
const includeCategory = useRouteQuery('sc', 'off');
const includeClosed = useRouteQuery('cc', 'on');
const includeAll = useRouteQuery('all', 'off');
const mainCategory = useRouteQuery('mc', '');
const user = useRouteParams('user');
const query = ref(route.query.q || '');
const show = ref(!!query.value || route.name === 'home.challenges.search');
const { slash, escape } = useMagicKeys({
    passive: false,
    onEventFired(e) {
        if (!show.value && e.key === '/')
            e.preventDefault();
    },
});

const goToHome = () => {
    if (user.value)
        router.push({ name: 'home.user', params: { user: user.value } });
    else
        router.push({ name: 'home.challenges' });
}

const close = () => {
    if (!show.value) {
        return;
    }

    show.value = false;
};

const open = () => {
    show.value = true;
};

const search = async () => {
    await router.push({ name: 'home.challenges.search', query: { ...route.query, q: query.value }, params: { user: user.value || undefined } });
        
    bus.emit('search:query', query.value);
};

const reset = () => {
    query.value = '';

    search();
};

const done = () => {
    close();
    goToHome();
};

whenever(slash, () => {
    if (!show.value) {
        open();
    }
});

whenever(escape, () => {
    if (show.value) {
        done();
    }
});

watch(() => route.name, (name) => {
    if (name !== 'home.challenges.search')
        close();
});

watch(() => route.query.q, (q) => {
    query.value = q;
})

defineExpose({
    open, reset, close, showing: show.value
});
</script>

<template>
    <div class="-mt-4 w-full" id="challenge-searching-widget">
        <Transition name="show">
            <div v-if="show" v-visible="active => active && input.focus()" class="relative border border-dust-400 mt-4 py-4 px-6 bg-white shadow-high rounded-lg mb-6 flex flex-col gap-4">
                <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center sm:w-[70%]">
                    <h3 v-if="isUserPlatform" class="m-0">{{ t('component.search-challenges.title', { title }) }}</h3>
                    <h3 v-else class="m-0">Search across {{ title }} <span v-if="!isBignerve">or BigNerve</span></h3>
                    <span class="text-dust-600 font-light italic text-xs">{{ t('component.search-challenges.tip') }}</span>
                </div>
                <div class="flex flex-col sm:flex-row items-center w-full gap-3">
                    <div class="w-full sm:w-[70%] inline-flex items-center">
                        <NIcon as="search-solid" class="text-2xl mr-2 text-dust-800"/>
                        <div class="relative flex items-center gap-3 bg-dust-100 w-full rounded-lg px-4">
                            <div v-if="mainCategory" class="bg-nerve pl-3 pr-1 py-1 text-white rounded-lg text-xs flex items-center justify-center">
                                {{ mainCategory }} <button aria-label="button" type="button" class="h-5 w-5 rounded-full ml-1 hover:bg-nerve-600 leading-none" @click="mainCategory = null"><NIcon as="close" /></button>
                            </div>
                            <input v-model="query" ref="input" type="text" class="py-3 bg-transparent outline-none flex-1 placeholder:italic" :placeholder="t('component.search-challenges.placeholder')" @keydown.enter="search">
                            <button aria-label="button" type="button" class="absolute right-3 top-3 text-dust-300 hover:text-dust-500 flex items-center justify-center w-6 h-6 rounded-full" @click="reset">
                                <NIcon as="close" />
                            </button>
                        </div>
                    </div>
                    <div class="flex items-center gap-4">
                        <button aria-label="button" class="bg-dust-700 text-white rounded-lg px-4 py-2" id="search-challenge" type="button" @click="search">
                            {{ t('component.search-challenges.search') }}
                        </button>
                        <label for="search-challenge" class="whitespace-nowrap">
                            {{ t('component.search-challenges.press-enter') }}
                        </label>
                    </div>
                </div>
                <div class="flex flex-col  gap-3 sm:flex-row sm:items-center">
                    <span class="font-bold">{{ t('component.search-challenges.advanced') }}:</span>
                    <label for="include-catalyst-name" class="flex items-center">
                        <input v-model="includeCatalyst" type="checkbox" id="include-catalyst-name" class="mr-1" true-value="on" false-value="off" />
                        {{ t('component.search-challenges.include-catalyst') }}
                    </label>
                    <label  for="include-category" class="flex items-center">
                        <input v-model="includeCategory" type="checkbox" id="include-category" class="mr-1" true-value="on" false-value="off" />
                        {{ t('component.search-challenges.include-category') }}
                    </label>
                    <label for="include-closed" class="flex items-center">
                        <input v-model="includeClosed" type="checkbox" id="include-closed" class="mr-1" true-value="on" false-value="off" />
                        {{ t('component.search-challenges.include-closed') }}
                    </label>
                    <label v-if="isUserPlatform" for="include-all" class="flex items-center">
                        <input v-model="includeAll" type="checkbox" id="include-all" class="mr-1" true-value="on" false-value="off" />
                        {{ t('component.search-challenges.include-all') }}
                    </label>
                </div>

                <button aria-label="button" type="button" class="absolute right-2 top-[10px] text-dust-700 h-8 w-8 rounded-full flex items-center justify-center hover:bg-dust-100" @click="done">
                    <NIcon as="times-circle-regular" />
                </button>

                <div class="arrows-top-[15px] arrows-border-dust-500 absolute sm:right-[18rem]"></div>
                <div v-if="route.name === 'home.challenges.search'" class="arrows-bottom-[15px] arrows-border-dust-500 absolute left-15"></div>
            </div>
        </Transition>
    </div>
</template>
