<script>
export default {
    name: 'WidgetTournaments',
}
</script>

<script setup>
import { usePlatform } from '@/composable/platform';
import { computed } from 'vue';
import { useOlympiads } from '@/queries/widgets';
import TournamentMainPlatform from './TournamentMainPlatform.vue';
import Tournament from './Tournament.vue';
import Placeholder from './Placeholder.vue';

const props = defineProps({
    platformName: {
        type: String,
        default: 'Platform',
    },
    platformSlug: {
        type: String,
    },
    length: {
        type: Number,
        default: 2
    },
    showPrevious: {
        type: Boolean,
        default: true
    },
    raceType: {
        type: String,
        default: 'overall'
    },
});

const slug = computed(() => props.platformSlug);
const { isUserPlatform, isViewingSelf, isBignerve } = usePlatform();
const { data: olympiads, isFetching: busy } = useOlympiads(slug, { race_type: props.raceType });
</script>

<template>
    <div class="border border-nerve rounded-md shadow">
        <div class="px-4 py-2 bg-nerve">
            <slot name="header">
                <h2 class="font-narrow text-white my-0 font-normal">
                    <NIcon as="layer-group-solid" class="mr-2" /><span class="font-bold">{{ platformName }}</span>'s participants:
                </h2>
            </slot>
        </div>
        <div class="bg-white rounded-b-md p-4">
            <template v-if="(olympiads?.current || olympiads?.previous)">
                <Tournament
                    id="widget-olympiads"
                    v-if="olympiads?.current.olympiad.id"
                    :modelValue="olympiads?.current"
                    :length="4"
                    :class="{ 'border-b border-dashed pb-4 mb-4': showPrevious && !!olympiads?.previous.olympiad.id }"
                    :raceType="raceType"
                />
                <Tournament
                    v-if="showPrevious && olympiads?.previous.olympiad.id"
                    :modelValue="olympiads?.previous"
                    :length="3"
                    :raceType="raceType"
                    finished
                />
                <div
                    v-if="!olympiads?.current.olympiad.id && !olympiads?.previous.olympiad.id || (!showPrevious && !olympiads?.current.olympiad.id)"
                    class="text-dust-700 text-sm font-semiboldd justify-center h-40 flex items-center px-3"
                >
                    <i18n-t v-if="isUserPlatform && isViewingSelf" keypath="page.leaderboard.notify.user.empty" tag="p" class="text-center block" >
                        <template #create-new-question>
                            <router-link :to="{ name: 'challenges.create' }" tag="button" class="underline hover:text-nerve">
                                {{ $t('page.leaderboard.notify.user.create-new-question') }}
                            </router-link>
                        </template>
                    </i18n-t>
                    <span v-else>
                        {{ $t('widget.tournament.alert.empty') }}
                    </span>
                </div>
            </template>

            <template v-else-if="busy">
                <Placeholder />
                <div v-if="showPrevious" class="border-b border-dashed mb-4 mt-16" />
                <Placeholder v-if="showPrevious" />
            </template>

            <div v-else-if="isBignerve" class="text-dust-700 text-sm font-semiboldd justify-center h-40 flex items-center px-3">
                {{ $t('common.no-result', 2) }}
            </div>

            <TournamentMainPlatform v-else />
        </div>
    </div>
</template>
