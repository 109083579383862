export default requester => {
    return {
        /**
         * Get all the users.
         *
         * @param {object} params
         * @param {object} headers
         */
        users(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/admin/users', {}, params, headers);
        },

        /**
         * Get all the feedbacks.
         *
         * @param {object} params
         * @param {object} headers
         */
        feedbacks(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/comments/suggestions/feedback', {}, params, headers);
        }
    };
}
