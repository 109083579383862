<script setup>
import NLoadingNerve from '@/components/NLoadingNerve.vue';
import { computed, ref, watch } from 'vue';
import { useIntersectionObserver, whenever, useImage } from '@vueuse/core';
import { cn } from '@/utils/Helpers';

const fallback = '/img/challenge-image-defaults/challenge-preview-placeholder.svg';

defineEmits(['click']);

const props = defineProps({
    src: {
        type: [Array, String],
        default: () => [],
    },
    alt: String,
    prefetch: {
        type: Boolean,
        default: false,
    },
    offset: {
        type: Number,
        default: 200,
    },
    class: {
        type: String,
        default: '',
    },
    showLoading: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: '',
    },
});
const index = ref(0)
const source = ref(null);
const isVisible = ref(props.prefetch);
const el = ref(null);
const srcs = computed(() => {
    const imgs = Array.isArray(props.src) ? props.src : [props.src];

    return [...imgs, fallback].filter(Boolean);
});

const tryFirstImage = () => {
    index.value = 0;
    source.value = { src: srcs.value[0] };
}

const tryNextImage = (e) => {
    index.value += 1;

    if (index.value >= srcs.value.length) {
        return;
    }

    source.value = { src: srcs.value[index.value] };
}

const onImageVisible = ([state]) => {
    if (state.isIntersecting) {
        isVisible.value = state.isIntersecting;
    }
}

const { stop } = useIntersectionObserver(el, onImageVisible, {
    threshold: 0.1,
    rootMargin: `${props.offset}px 0px`,
    immediate: !props.prefetch,
});

watch(srcs, tryFirstImage, { immediate: true });

whenever(isVisible, stop);

const { isLoading, isReady } = useImage(source, { onError: tryNextImage });
</script>

<script>
export default {
    name: 'NLazyImage',
}
</script>

<template>
    <slot :isReady="isReady" :isLoading="isLoading" :src="source.src" :tryNextImage="tryNextImage" :isVisible="isVisible">
        <div ref="el" :title="title" :class="cn('bg-dust-400 overflow-hide flex-none overflow-hidden w-full aspect-4/9 relative', isLoading && 'animate-loading', props.class)">
            <img v-if="isReady && isVisible" :src="source.src" :alt="alt" class="absolute inset-0 w-full h-full object-cover object-center" @error="tryNextImage" />

            <div v-else-if="showLoading" class="w-full h-full flex items-center justify-center" >
                <NLoadingNerve />
            </div>
        </div>
    </slot>
</template>