import get from 'lodash/get';
import { ref, computed } from 'vue';

export const useFloating = () => {
	const target = ref(null);
	const popper = computed(() => {
		return get(target, 'value.$refs.popper', {
			show: () => {},
			hide: () => {},
		});
	});
    const showing = computed(() => {
        return popper.value.isShown;
    });

    const show = () => popper.value.show();
    const hide = () => popper.value.hide();

    return {
        target,
        showing,
        show,
        hide,
    }
};

