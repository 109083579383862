<script setup>
import PaymentMethod from '@/components/idea/Payment.vue';
import { useStoredIdeaData, useIdeaCreationFlow } from '@/composable/idea';
import { api } from '@/modules/services';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';

// payment code
const props = defineProps({
    challenge: Object,
});

const { data, resetData } = useStoredIdeaData(props.challenge);
const { createPayload, totalUserIdeas } = useIdeaCreationFlow(props.challenge);

const router = useRouter();
const loading = ref(false);
const amount = computed(() => props.challenge.idea_entry_fee * totalUserIdeas.value);

const startGame = (paymentIntent) => {
    router.push({
        name: 'challenges.rate',
        params: { challenge: props.challenge.slug },
        query: { payment_id: paymentIntent.id },
    });
}

const handleSuccess = async ({ prize, paymentIntent }) => {
    await api.idea.create(createPayload({
        ideas: data.value,
        prize_id: prize.id
    }))

    resetData();
    startGame(paymentIntent);
}
</script>

<template>
    <div class="flex justify-center mb-5">

        <div class="shadow-lg rounded-lg p-2 bg-white border w-full max-w-2xl">
            <div class="bg-secondary-500 text-white flex items-center py-3 px-4 rounded-t-lg">
                <h1 class="font-medium m-0 font-narrow">
                    <NIcon as="funding" class="mr-2" />
                    Idea Entry Fee
                </h1>
            </div>
            <div class="p-4 bg-white relative">
                <div v-if="loading" class="absolute z-10 inset-0 flex items-center justify-center supports-backdrop-blur:bg-white/60 bg-white/40 backdrop-blur">
                    <p>
                        Sending idea(s) to the challenge...
                    </p>
                </div>
                <Suspense>
                    <PaymentMethod
                        :amount="amount"
                        :prizeParams="{
                            challenge_id: challenge.id
                        }"
                        :disabled="loading"
                        @success="handleSuccess"
                    />

                    <template #fallback>
                        <div className="w-full py-8 space-y-2 animate-pulse">
                            <div className="space-y-2 mb-10">
                                <div className="h-8 w-1/4 bg-gray-200 rounded"></div>
                                <div className="h-4 w-5/6 bg-gray-100 rounded"></div>
                                <div className="h-4 w-3/4 bg-gray-100 rounded"></div>
                            </div>

                            <div className="space-y-2">
                                <div className="h-5 w-10 bg-gray-200 rounded"></div>
                                <div className="h-10 w-full bg-gray-100 rounded flex items-center px-3"></div>
                            </div>

                            <div className="space-y-2">
                                <div className="h-5 w-[5rem] bg-gray-200 rounded"></div>
                                <div className="h-10 w-full bg-gray-100 rounded flex items-center px-3"></div>
                            </div>

                            <div className="flex space-x-4">
                                <div className="flex-1 space-y-2">
                                    <div className="h-5 w-1/2 bg-gray-200 rounded"></div>
                                    <div className="h-10 w-full bg-gray-100 rounded flex items-center px-3"></div>
                                </div>
                                <div className="flex-1 space-y-2">
                                    <div className="h-5 w-1/2 bg-gray-200 rounded"></div>
                                    <div className="h-10 w-full bg-gray-100 rounded"></div>
                                </div>
                            </div>

                            <div className="space-y-2 pb-6">
                                <div className="h-5 w-[5rem] bg-gray-200 rounded"></div>
                                <div className="h-10 w-full bg-gray-100 rounded"></div>
                            </div>

                            <div className="h-12 w-full  bg-gray-200 rounded"></div>
                        </div>
                    </template>
                </Suspense>
            </div>
        </div>
    </div>
</template>
