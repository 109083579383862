<script>
export default {
    name: 'MyAccount',

    metaInfo: {
        title: 'My Account'
    },

    computed: {
        fields() {
            return this.$store.getters['auth/currentUser'].user;
        }
    },
};
</script>

<template>
    <article class="page">
        <NPageHeader
            title="My Account"
            subtitle="Manage your account details"
            icon="sliders-h"
        />
        <div class="page__content">
            <h2>
                Welcome!
            </h2>
            <p class="form-block-help-text">
                This section lets you update your login info, manage your payment wallets, set various preferences, design your platform masthead, and more. Check out the menu for all the options.
            </p>
            <p class="form-block-help-text">
                <NIcon as="innovator" class="text-innovator" /> If you’re looking for your ideas and your other activities (questions, mentoring, ratings, director settings), head over to  <router-link class="is-text-underline font-weight-bold is-color-black" :to="{ name: 'dashboard.dashboard', params: { user: 'me' } }">My Dashboard</router-link>.
            </p>

            <h3>
                For New Users
            </h3>
            <p class="form-block-help-text">
                Here’s what you should do next:
            </p>
            <ol>
                <li>
                Set up your <router-link class="is-text-underline font-weight-bold is-color-black" :to="{ name: 'account.my-account.masthead' }">platform "masthead"</router-link> with your photo, a brief bio, a fun slogan, and also to personalize your bignerve @handle (get your preferred username early!); 
                </li>
                <li>
                Add your <router-link class="is-text-underline font-weight-bold is-color-black" :to="{ name: 'account.my-account.payment-wallets' }">payment wallet</router-link> to receive prize money you win;
                </li>
                <li>
                Set your school, work place, geographic location, and interests in <router-link class="is-text-underline font-weight-bold is-color-black" :to="{ name: 'account.my-account.profile' }">My Profile</router-link>;
                </li>
                <li>
                <router-link class="is-text-underline font-weight-bold is-color-black" :to="{ name: 'home.challenges' }">Start playing!</router-link> Browse questions, enter your own ideas, rate ideas of other players, and more.
                </li>
            </ol>
        </div>
    </article>
</template>
