<script setup>
import { computed, ref } from 'vue';
import { usePreferences } from '@/composable/user';
import { useAuth } from '@/composable/auth';
import { api } from '@/modules/services';

const emit = defineEmits(['update:model-value']);

const props = defineProps({
    modelValue: {
        type: String,
    },
    label: {
        type: String,
        default: 'Reply-To',
    },
    required: {
        type: Boolean,
        default: false,
    },
});

const { preference } = usePreferences();
const { auth } = useAuth();
const defaultReplyTo = preference('email.reply_to');
const users = ref([auth.user]);

const selected = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:model-value', value),
});

const mappedUsers = computed(() => {
    return users.value.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
    }));
});

const fethUsersStaff = async () => {
    // define default staff user
    if (!selected.value) {
        selected.value = defaultReplyTo.value;
    }

    const { data: response } = await api.staff.users(auth.user.id);

    users.value = [...users.value, ...response.data];

    if (selected.value) {
        return;
    }
    
    if (users.value.find((user) => user.id === defaultReplyTo.value)) {
        selected.value = defaultReplyTo.value;
    } else {
        selected.value = auth.user.id;
    }
}

fethUsersStaff();

const user = computed(() => {
    return users.value.find((user) => user.id === selected.value);
});

const makeDefault = ({ target }) => {
    if (!target.checked) {
        defaultReplyTo.value = null;

        return;
    }
    
    defaultReplyTo.value = target.value;
}
</script>

<template>
    <div class="form-group my-0" >
        <NSelect v-model="selected" :label="label" :options="mappedUsers" :placeholder="mappedUsers.length ? 'Select a staff user' : 'No users on staff'" :required="required">
            <template #prepend>
                <NAvatar v-if="user" :src="user.avatar_url" :alt="user.handle" :small="true" class="mr-2"/>
            </template>
        </NSelect>

        <label for="make_default" class="pt-1 text-sm flex items-center gap-2" :class="{ disabled: !selected }">
            <input :checked="selected && defaultReplyTo === selected" @input="e => makeDefault(e)" type="checkbox" id="make_default" name="make_default" :value="selected" />
            <span class="text-dust-700">check to make default</span>
        </label>
    </div>
</template>