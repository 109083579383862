import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';

// fields = { String: String } | [String, String] | [Function, Function] | [Function, String] | [String, Function]

const useKey = (path) => {
    const [key, at] = path.split('@');

    return { key, at }
};

const defaultOptions = {
    beforeRelate: (data) => data.relationships,
}

export const relate = (fields = [], { beforeRelate } = defaultOptions) => (response) => {
    const { data: base } = response;

    // if the response is not an object, return it
    let relationships = beforeRelate(base);

    if (!fields || isEmpty(relationships)) {
        return response;
    }

    if (!isArray(fields) && isObject(fields)) {
        fields = Object.entries(fields)
    }

    if (isArray(relationships)) {
        relationships = relationships.reduce((acc, relationship) => {
            fields.forEach(([from]) => {
                const { at } = useKey(from)

                if (at && has(relationship, at)) {
                    acc[get(relationship, at)] = relationship;
                }
            })
            
            return acc;
        }, {});
    }

    // relate the data from the fields
    const relationship = (target) => {
        fields.forEach(([from, to]) => {
            let path

            if (isFunction(from)) {
                path = from(target);
            } else {
                const { key } = useKey(from)

                if (!has(target, key)) {
                    return
                }
    
                path = get(target, key)
            }

            if (path && isObject(relationships) && has(relationships, path)) {
                set(target, isFunction(to) ? to(target) : to, relationships[path]);
            }
        })
    }

    // iterate over each one to relate objects
    (isArray(base.data) ? base.data : [base.data])
        .filter(v => !!v)
        .forEach(relationship);

    return response;
}
