<script>
import Sharer from '@/components/sharing/Sharer.vue';

const accountURL = import.meta.env.VUE_APP_ACCOUNT_URL;
const [, appName] = location.hostname.split(".").reverse();

export default {
  data() {
    return {
      version: import.meta.env.VUE_APP_VERSION,
    };
  },
  components: { Sharer },
  computed: {
    isSplash() {
      return this.$route.meta.splash;
    },
    sinupURL() {
      let fullPath = this.$route.fullPath;

      if (this.$route.name === "home.challenges") {
        fullPath = "/my-dashboard";
      }

      const continueURL = encodeURIComponent(`${location.origin}${fullPath}`);
      const url = new URL(`${accountURL}/signup`);

      url.searchParams.append("scout_id", Cookie.get("scout_id"));
      url.searchParams.set("continue", continueURL);
      url.searchParams.set("app", appName);

      return url.toString();
    },
    adminRoute() {
      const url = new URL("https://admin.bignerve.com/");
      const host = location.hostname;

      if (host.includes("dev.") || host.includes(".test")) {
        url.hostname = `dev.${url.hostname}`;
      }

      return url.toString();
    },
  },
};
</script>

<template>
  <footer class="app-footer">
    <div class="container mx-auto pb-12 sm:p-0">
      <div class="app-footer__inside">
        <p class="app-footer__copyright mb-0">
          &copy; BigNerve Inc. All rights reserved.&nbsp;
          <span class="is-color-medium-gray ml-2" style="font-weight: normal"
            >v{{ version }}</span
          >
        </p>
        <ul class="app-footer__links" v-if="!isSplash">
          <li>
            <a
              href="#"
              class=""
              @click.prevent="
                () => $bus('tour:next', { guide: 'start-guide', scrollToTop: true })
              "
            >
              <img src="/img/guide-map.svg" alt="Tour" class="w-5 h-5 -mt-1 mr-1 inline" />
              Take a Tour
            </a>
          </li>
          <li v-if="!$auth.authenticated">
            <a :href="sinupURL">Create Account</a>
          </li>
          <li>
            <router-link :to="{ name: 'send-feedback' }"
              >Send Feedback</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'faqs' }">FAQs</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'contact' }">Contact Us</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'support' }">Get Support</router-link>
          </li>
          <li v-if="$auth.isAdmin">
            <a :href="adminRoute"> Admin Dashboard </a>
          </li>
        </ul>
      </div>
    </div>

    <Sharer />
  </footer>
</template>
