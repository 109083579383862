<script>
import NSwitch from '@/components/NSwitch.vue';
import { date } from '@bignerve/ui-utils';

export default {
  components: { NSwitch },
    props: {
        wallet: Object
    },

    emits: ['updated'],

    computed: {
        api () {
            return {
                action: [
                        '/me/profiles/wallets',
                        this.wallet.id
                    ].filter(v => !!v).join('/'),
                method: !this.wallet.id ? 'POST' : 'PUT'
            }
        },
    },

    methods: {
        date,

        update() {
            this.$emit('updated')
        },

        toggleState() {
            this.wallet.activate = !this.wallet.activate;

            this.$refs.form.submit();
        }
    }
};
</script>

<template>
    <NDataForm
        v-bind="api"
        :data="wallet"
        ref="form"
        @success="update"
    >
        <template #default="form">
            <NAlert type="danger" closable :open="form.errors.any()">
                <span>{{
                    form.errors.get("*") ||
                        "One of the form fields was invalid, please check your changes again?"
                }}</span>
            </NAlert>
            <NAlert type="success" closable :open="form.success">
                <span>Your payment method has been updated!</span>
            </NAlert>
            <div class="flex flex-col sm:flex-row">
                <div class="flex-grow p-2 sm:p-3">
                    <h3>
                        <NIcon as="money-bill-alt-regular" left /> Your Paypal
                        wallet
                    </h3>
                    <p>
                        If you prefer to receive prize money via <b>PayPal</b>,
                        enter your verified PayPal account email here.
                    </p>
                    <div class="form-groups mb-4">
                        <div class="form-group">
                            <div class="flex items-center w-full">
                                <img
                                    src="/img/brands/paypal.svg"
                                    alt="paypal"
                                    rule="brand"
                                    class="mr-3 h-6"
                                />
                                <NInput
                                    v-model="form.fields.wallet_key"
                                    placeholder="Enter your PayPal email"
                                    class="max-w-sm"
                                >
                                    <template #prepend>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1 -ml-1 text-dust" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                    </template>
                                </NInput>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center">
                        <button aria-label="button"
                            type="submit"
                            class="btn is-primary px-6"
                            :disabled="form.busy"
                        >
                            {{ form.busy ? "Saving..." : "Save" }}
                        </button>

                        <span
                            v-if="wallet.updated_at"
                            class="text-dust italic mr-2"
                        >
                            edited {{ date(wallet.updated_at, 'human') }}
                        </span>
                    </div>
                </div>
                <div class="flex flex-none flex-col justify-start items-center pt-2 sm:border-l border-dust-300 px-2 w-24" >
                    <NSwitch
                        v-tooltip="{
                            content: !wallet.activate
                                ? '<center>Tap to activate this wallet<br>(Only one can be active at a time.)</center>'
                                : 'Tap to inactivate this wallet',
                            html: true
                        }"
                        :modelValue="wallet.activate"
                        icon
                        @change="toggleState"
                    />
                    <b v-if="wallet.activate" class="mt-2">Active</b>
                    <i v-else class="mt-2">Inactive</i>
                </div>
            </div>
        </template>
    </NDataForm>
</template>
