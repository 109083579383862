<script setup>
import TextEditor from '@/components/form/TextEditor.vue';
import NDataForm from '@/components/form/Form.vue';
import NLoading from '@/components/NLoading.vue';
import NMarkdown from '@/components/NMarkdown.vue';
import NIcon from '@/components/NIcon.vue';
import NPlayerDetails from '@/components/game/players/Details.vue';
import NAlert from '@/components/NAlert.vue';
import moment from 'moment';
import NLoadingNerve from '@/components/NLoadingNerve.vue';
import { useResources } from '@/queries/discussion';
import { date as formatDate } from '@bignerve/ui-utils';
import { api } from '@/modules/services';
import { ref, shallowRef, nextTick, computed } from 'vue';
import { useDraft } from '@/composable/discussion';
import { useAuth } from '@/composable/auth';
import { useCancelToken } from '@/composable/requester';
import { unrefElement, useInfiniteScroll, tryOnScopeDispose } from '@vueuse/core';
import { useChannel } from '@/composable/pusher';

const dateToString = (date) => {
	return formatDate(moment.unix(date), 'HH:mm @ MM/DD/YYYY');
};

const props = defineProps({
	context: {
		type: String,
		required: true,
	},
	id: {
		type: String,
		required: true,
	},
    polling: {
        type: Boolean,
        default: false,
    },
});

const size = 5;
const typing = ref(false);
const container = ref(null);
const after = ref(null);
const loading = ref(false);
const discussions = shallowRef([]);
const hasPolling = computed(() => props.polling);
const { auth } = useAuth();
const { content } = useDraft({
	context: props.context,
	id: props.id,
});
const { data } = useResources(props.context, props.id);
const { listen } = useChannel(`discussion-${props.context}-${props.id}`);

const { abortPrevious, nextToken } = useCancelToken();
const scrollToBottom = () => {
	nextTick(() => {
		const el = unrefElement(container);

		if (el) {
			el.scrollTop = el.scrollHeight; 
		}
	});
};

const receiver = computed(() => {
    return Object.values(data.value.users || {})
        .find((d) => d.id !== auth.user.id);
});

const keepScrollPosition = (id) => {
	nextTick(() => {
		const el = unrefElement(container);

		if (el) {
			el.querySelector(`#message-${id}`).scrollIntoView();
		}
	});
};

const loadMessages = async ({ polling } = {}) => {
    abortPrevious();

	try {
        loading.value = !polling;

        const { data: response } = await api.discussions.find(
            props.context,    
            props.id, {
                size: size,
                after: after.value || undefined,
				filter: 'recent',
            },
            { cancelToken: nextToken() },
        );

		if (polling) {
			const newMessages = response.data.reduce((acc, data) => {
				if (discussions.value.find((d) => d.id === data.id)) return acc;

                return [...acc, data];
			}, [])

            discussions.value = [...discussions.value, ...newMessages.reverse()];

            if (newMessages.length > 0) {
                scrollToBottom();
            }
		} else {
			const first = discussions.value[0];
			discussions.value = [...response.data.reverse(), ...discussions.value];

			first && keepScrollPosition(first.id)
		}

		// first load scroll to bottom
		if(!after.value && !polling) {
			scrollToBottom();
		}

        after.value = response.meta.after;
    } catch (e) {
        if (e.name === 'CanceledError') {
            return;
        }

        throw e;
    } finally {
        loading.value = false;
    }
};

const handleOnSuccess = async ({ data }) => {
    content.value = '';

    // use the last after value
    after.value = null;

    discussions.value.push({
		...data,
		author: auth.user,
	});

	scrollToBottom();

    loadMessages({ polling: true });
};

const reset = () => {
    after.value = null;

    discussions.value = [];
};

useInfiniteScroll(container, () => {
	loadMessages();
}, {
	canLoadMore: () => {
        return !loading.value && after.value;
    },
	immediate: true,
	interval: 1000,
	direction: 'top',
	distance: 100,
});

useInfiniteScroll(container, () => {
     // reset the after
     after.value = null;

	loadMessages({ polling: true });
}, {
	canLoadMore: ({ isConnected }) => {
        return !loading.value && hasPolling.value && isConnected;
    },
	immediate: true,
	// interval: 1000 * 5, // use pusher
	direction: 'bottom',
	distance: 100,
});

defineExpose({
    reset,
    scrollToBottom,
    loadMessages,
});

tryOnScopeDispose(() => {
    console.log('chat closed');

    reset();
})

loadMessages();


const handleNewMeessages = async () => {
    try {
        typing.value = true;
        await loadMessages({ polling: true });
    } finally {
        typing.value = false;
    }
};

listen('message-created', handleNewMeessages);
listen('message-deleted', handleNewMeessages);
</script>

<template>
    <div class="flex flex-col">
        <div class="flex items-center !font-normal justify-between px-4 pt-2 pb-2 border-b">
            <NPlayerDetails
                :user="receiver || { id: 'hidden' }"
                :routable="false"
                class="content-start"
            />

            <RouterLink :to="{ name: 'dashboard.messages.context', params: { context: props.context, id } }" class="flex font-bold items-center gap-2 text-dust-700 px-2 py-1">
                <NIcon :as="'comments-solid'" class="text-xl" />
                <span class="hidden sm:inline-block">{{ $t('component.private-chat.view-history') }}</span>
            </RouterLink>
        </div>

        <div ref="container" class="flex flex-col overflow-y-auto sb-dust-200 sb-snug grow">
            <div v-if="discussions.length" class="px-4 flex flex-col grow justify-items-stretch">
                <div class="flex basis-auto shrink grow h-4"></div>

                <div v-for="message of discussions" :key="message.id" :id="`message-${message.id}`" class="mb-2 max-w-[90%]" :class="message.author.id !== auth.user.id ? 'self-start' : 'self-end'">
                    <div class="bg-white p-4 border rounded-md mb-2">
                        <strong>{{ message.author.id === auth.user.id ? 'You' : `${message.author.first_name} ${message.author.last_name}` }}</strong>
                        <NMarkdown :code="message.content" class="text-dust-700" />
                    </div>
                    <div class="flex justify-end">
                        <small class="text-dust-400 italic">{{ dateToString(message.created_at) }}</small>
                    </div>
                </div>

                <div v-if="typing" class="mb-2 max-w-[90%] self-start h-10 px-2 animate-loading">
                    <div class="flex justify-end gap-2">
                        <div class="h-[0.5rem] w-[0.5rem] bg-dust-500 rounded-full"></div>
                        <div class="h-[0.5rem] w-[0.5rem] bg-dust-400 rounded-full"></div>
                        <div class="h-[0.5rem] w-[0.5rem] bg-dust-300 rounded-full"></div>
                    </div>
                </div>
            </div>

            <div v-else-if="loading" class="flex items-center justify-center grow">
                <NLoadingNerve />
            </div>

            <div v-else class="flex items-center justify-center grow">
                <div class="flex flex-col items-center justify-center">
                    <NIcon as="comments-o" class="text-4xl text-dust-400" />
                    <p class="text-dust-400">{{ $t('component.private-chat.no-messages') }}</p>
                </div>
            </div>
        </div>

        <div class="hidden sm:flex items-center justify-center h-10 border-t">
            <div class="font-bold text-dust-600 text-sm flex items-center">
                Prior Messages <NIcon as="arrow-circle-up" class="ml-2" />
            </div>
        </div>

        <NDataForm
            :action="`/content/contexts/${props.context}/${props.id}/messages`"
            :data="{ content: content }"
            @success="handleOnSuccess"
            #="{ busy, errors }"
        >
            <div class="form-group">
                <div class="w-full px-4 mb-2">
                    <TextEditor
                        v-model="content"
                        :autofocus="true"
                        :toolbar="false"
                        :placeholder="$t('component.private-chat.editor-placeholder')"
                        name="post-comment"
                        required
                    />
                </div>
            </div>

            <NAlert type="danger" closable class="mb-2 mx-4" :open="errors.any()">
                <span>{{ errors.get('*') || $t('component.private-chat.default-error-message') }}</span>
            </NAlert>

            <div class="flex items-center justify-between px-4 pb-4 mx-1">
                <small class="text-dust-400">
                    {{ $t('component.private-chat.note') }}
                </small>

                <button aria-label="button"
                    type="submit"
                    class="flex items-center justify-center px-2 py-1 bg-secondary-500 min-w-[6rem] rounded-md hover:bg-secondary-600 text-white"
                    :disabled="busy"
                >
                    {{ $t('component.private-chat.send-message') }}
                    <NLoading
                        v-if="busy"
                        class="ml-2"
                        color="text-white"
                        active
                    />
                    <NIcon
                        v-else
                        as="comments-solid"
                        class="ml-2"
                    />
                </button>
            </div>
        </NDataForm>
    </div>
</template>

