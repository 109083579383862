<template>
    <div class="page__sidebar-inside">
        <ul>
            <li><router-link :to="{ name: 'about' }">About BigNerve</router-link></li>
            <li><router-link :to="{ name: 'why-innervation' }">Why "Innervator"</router-link></li>
            <li><router-link :to="{ name: 'idea-person' }">Being an Idea person</router-link></li>
            <li><router-link :to="{ name: 'code-of-conduct' }">Code of Conduct</router-link></li>
            <li><router-link :to="{ name: 'faqs' }">FAQs</router-link></li>
            <li><router-link :to="{ name: 'contact' }">Contact Us</router-link></li>
            <li><router-link :to="{ name: 'send-feedback' }">Send Feedback</router-link></li>
            <li>- - - - - - - - - - -</li>
            <li><router-link :to="{ name: 'tos' }">Terms of Service</router-link></li>
            <li><router-link :to="{ name: 'gdpr' }">GDPR Data Protection</router-link></li>
            <li><router-link :to="{ name: 'dmca' }">DMCA Notices</router-link></li>
            <li><router-link :to="{ name: 'policy' }">Privacy Policy</router-link></li>
            <li><router-link :to="{ name: 'accessibility' }">Accessibility</router-link></li>
        </ul>
    </div>
</template>
