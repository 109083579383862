<script setup>
import Display from './Display.vue';
import TextBlock from './block-types/text/Text.vue';
import ImageBlock from './block-types/image/Image.vue';
import VideoBlock from './block-types/video/Video.vue';
import DividerBlock from './block-types/divider/Divider.vue';

import { h, ref, watch } from 'vue';
import { useSortable } from '@vueuse/integrations/useSortable';
import { useVModel } from '@vueuse/core';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      title: 'Article',
      image_cover: null,
      blocks: [
        { order: 0, block_type: 'text', content: '## Title here' },
        { order: 1, block_type: 'text', content: 'Description here' },
        { order: 2, block_type: 'text', content: null },
      ],
    }),
  },
  previewing: {
	type: Boolean,
	default: false,
  },
});

const el = ref(null);
const content = useVModel(props, 'modelValue', emit, { passive: true });
const blocks = ref(content.value.blocks || []);

const BLOCK_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  HEADING: 'heading',
  EMBED: 'embed',
  QUOTE: 'quote',
  DIVIDER: 'divider',
};

const defaultBlock = {
  order: 0,
  layout: 'full',
  block_type: 'text',
  content: null,
  metadata: {},
  last_update: new Date(),
};

const NoBlock = () => {
  return h('div', { class: 'text-sm text-dust-400' }, 'No block');
}

const addBlock = (type = 'text', defaultValue) => {
    blocks.value = [
        ...content.value.blocks,
        {
            ...defaultBlock,
            block_type: type,
            content: defaultValue,
        },
    ];
};

const resolveBlock = (type) => {
    switch (type) {
        case BLOCK_TYPE.TEXT:
            return TextBlock;
        case BLOCK_TYPE.IMAGE:
            return ImageBlock;
        case BLOCK_TYPE.VIDEO:
            return VideoBlock;
        case BLOCK_TYPE.DIVIDER:
            return DividerBlock;
        default:
            return NoBlock;
    }
}

const removeBlock = (index) => {
    content.value.blocks.splice(index, 1);
}

useSortable(el, blocks, {
    draggable: '.content-block',
    direction: 'vertical',
});

watch(blocks, (newValue) => {
    content.value.blocks = newValue;
});
</script>

<script>
export default {
  name: 'ArticleEditor',
}
</script>

<template>
    <div class="">
		<slot name="toolbar" />
        <div v-show="previewing" class="bg-white border border-dust-300 rounded-md pl-[3.7rem] pr-[1.7rem] pt-[1.4rem] pb-[5rem]">
            <Display :modelValue="content" />
        </div>
        <div v-show="!previewing" class="flex flex-col gap-2 bg-white pt-6 pb-4 pl-4 pr-10 border border-dust-300 rounded-md">
            <!-- line -->
            <div ref="el" class="flex flex-col gap-2 relative">
                <div v-for="(block, i) of blocks" :key="`block-${i}-${block.block_type}`" class="content-block flex items-center border-2 border-transparent group py-2 -my-1 pr-1 -mr-1 rounded-lg bg-white hover:border-dashed sm:hover:border-gray-200">

                    <!-- action -->
                    <div class="flex items-center justify-center w-11">
                        <button aria-label="button" type="button" class="flex items-center justify-center h-8 w-8 rounded-full hover:bg-dust-100 invisible group-hover:visible group-focus:visible" @click="removeBlock(i)">
                            <NIcon as="close-circle" />
                        </button>
                    </div>

                    <!-- content -->
                    <div class="w-full">
                        <component
                            :id="`block-${block.id || i}`"
                            v-model:modelValue="block.content"
                            v-model:metadata="block.metadata"
                        
                            :is="resolveBlock(block.block_type)"
                            :autofocus="i === blocks.length - 1"
                        />
                    </div>

                </div>
            </div>

            <!-- add new line -->
            <div class="flex gap-2 divide-dust-500 divide-x items-center group border border-transparent rounded-lg hover:bg-dust-100 hover:shadow-active p-1 transition-all my-2">
                <button aria-label="button" type="button" class="flex items-center justify-center h-8 w-8 rounded-full">
                    <NIcon as="add-circle" />
                </button>
                <div class="flex items-center invisible group-hover:visible p-2 flex-wrap gap-2">
                    <button aria-label="button" type="button" class="flex items-center gap-2 px-2 py-1 -my-1 rounded-lg hover:bg-dust-200" @click="addBlock(BLOCK_TYPE.TEXT)">
                        <NIcon as="pencil-alt-solid" /> Text
                    </button>
                    <button aria-label="button" type="button" class="flex items-center gap-2 px-2 py-1 -my-1 rounded-lg hover:bg-dust-200" @click="addBlock(BLOCK_TYPE.IMAGE)">
                        <NIcon as="image-regular" /> Image
                    </button>
                    <button aria-label="button" type="button" class="flex items-center gap-2 px-2 py-1 -my-1 rounded-lg hover:bg-dust-200" @click="addBlock(BLOCK_TYPE.VIDEO)">
                        <NIcon as="play-circle-regular" /> Video
                    </button>
                    <button aria-label="button" type="button" class="flex items-center gap-2 px-2 py-1 -my-1 rounded-lg hover:bg-dust-200" @click="addBlock(BLOCK_TYPE.DIVIDER, '...')">
                        <NIcon as="ellipsis-h-solid" /> Divider
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>