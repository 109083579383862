import { Session } from '@/utils/Auth';

export const useShadowMode = () => {
    return {
        data () {
            return {
                hasShadowUser: false,
            };
        },
        bus: {
            'user:logged-in' () {
                this.hasShadowUser = Session.isShadow();
            }
        },
        methods: {
            stopShadowMode () {
                if (!this.hasShadowUser) {
                    return;
                }

                Session.stopShadow();

                window.location.href = '/';
            },
        }
    }
};
