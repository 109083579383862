<script setup>
import Assumptions from '@/components/challenge-show/Assumptions.vue';
import ChallengeTitle from '@/components/challenge/Title.vue';
import ChallengeDetails from '@/components/challenge/Details.vue';
import ChallengeImage from '@/components/challenge/Image.vue';
import ChallengePlaceholder from './Placeholder.vue';
import ReportContent from '@/components/ReportContent.vue';
import QQCoAuthor from '@/components/challenge/QQCoAuthor.vue';
import NPlayerDetails from '@/components/game/players/Details.vue';
import NParticipants from '@/components/game/players/Participants.vue';
import NSharingPopup from '@/components/sharing/Popup.vue';
import NTimeRemaining from '@/components/TimeRemaining.vue';
import NIcon from '@/components/NIcon.vue';
import { currency } from '@bignerve/ui-utils';
import { excerpt } from '@/utils/Helpers';
import { useDetails } from '@/composable/challenge';
import { useStats } from '@/composable/stats';
import { computed } from 'vue';

const props = defineProps({
    challenge: {
        type: Object,
        required: true
    },
});


const { isAMA } = useDetails(props.challenge);
const { stat } = useStats(
	'challenge', props.challenge.id, { immediate: true },
);

const totalUnlockedIdeas = stat('challenge.qty_idea_unlocked', 0);
const totalIdeasToRate = stat('user.qty_idea_rating_left');

const prizeTooltip = computed(() => {
    return {
        content: props.challenge?.idea_entry_fee > 0
            ? "<div style=\"max-width: 300px\">This challenge has an entry fee, a portion of which is added to the Prize Money total, for the crowd. The prize grows larger as more innovators participate.</div>" 
            : 'Prize Money',
        html: true,
    }
})
</script>

<template>
    <div>
        <div class="challenge-detail-card__cols">
            <div class="challenge-detail-card__col is-left">
                <ChallengeImage :challenge="challenge" :credits="true" />

                <div v-if="challenge.article" class="border-r border-b">
                    <div class="px-4 py-2 border-b border-t bg-director-100 text-nerve flex items-center leading-none">
                        <NIcon as="article" class="text-lg mr-2" />
                        <span class="italic text-sm">Linked Article</span>
                    </div>
                    <div class="p-4 bg-director-50">
                        <h3 class="text-lg text-dust-800">{{ challenge.article.title }}</h3>
                        <p v-if="challenge.article.description" class="text-sm text-dust-600">
                            {{ excerpt(challenge.article.description, 265) }}
                        </p>
                        <div>
                            <a :href="challenge.article.url" class="text-sm font-bold text-dust-600" target="_blank" rel="noopener noreferrer">
                                <NIcon as="link-solid" class="mr-2" />
                                <span class="underline">Read the full story</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="challenge-detail-card__personal-actions sm:mx-2 disabled hidden" non-functional>
                    <button aria-label="button" type="button" class="is-color-dark-green">
                        <span aria-hidden="true" class="icon icon-funding"></span>
                        <span class="caption">
                            <b>Fund this challenge</b>
                            <i>Be a Patron!</i>
                        </span>
                    </button>
                    <button aria-label="button" class="is-color-default">
                        <span aria-hidden="true" class="icon icon-retweet"></span>
                        <span class="caption">
                            <b>ReNerve</b>
                            <i>Add this to your tournament!</i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="challenge-detail-card__col is-right">
                <template v-if="challenge.id">
                    <div class="flex flex-col lg:flex-row challenge-detail-card__title-wrapper gap-4">
                        <div class="flex-grow">
                            <ChallengeTitle :challenge="challenge" tag="h1" class="text-xl font-bold text-dust-800 mb-0" />
                        </div>
                        <div class="flex flex-wrap justify-between sm:justify-start sm:flex-col gap-2">
                            <NPlayerDetails v-tooltip="'Author'" v-if="challenge.catalyst" :user="challenge.catalyst" :clamp="false" />
                            <QQCoAuthor :challenge="challenge" />
                        </div>
                    </div>
                    <div v-if="challenge.id" class="mt-3">
                        <ChallengeDetails :challenge="challenge" :unfurl="true" class="bg-dust-100 p-4" />

                        <Assumptions v-if="challenge.id" :challenge="challenge" class="border-t">
                            <template #actions>
                                <ReportContent
                                    :content-id="challenge.id"
                                    :reasons="['Spam', 'Obscene', 'Offensive', 'Plagiarized', 'Invalid Question', 'AI Slop']"
                                    menu-title="Report challenge"
                                    content-type="challenge"
                                />
                                <button aria-label="button" non-functional class="flex items-center border px-4 py-2 text-dust-700 hover:bg-dust-200 hover:text-dust-800 rounded-md leading-tight sm:border-none sm:px-2 sm:-mx-2 sm:py-1">
                                    <NIcon as="bookmark-regular" class="text-nerve mr-2" />
                                    Save for Later
                                </button>
                            </template>
                        </Assumptions>
                    </div>
                </template>

                <ChallengePlaceholder v-else />
            </div>
        </div>
        <div class="challenge-detail-card__footer flex items-center">
            <div class="challenge-detail-card__footer-meta">
                <span v-if="challenge.stats" v-tooltip="'Unlocked Ideas'" class="catalyst-card__footer-meta-item flex items-center">
                    <NIcon :as="totalIdeasToRate ? 'lightbulb' : 'lightbulb-o1'" :class="{ 'is-color-green': !!totalIdeasToRate && !challenge.is_closed }" />
                    Ideas ({{ totalUnlockedIdeas }})
                    <span v-if="challenge.user_stats.game_started && !challenge.is_closed && totalIdeasToRate > 0" class="text-white text-xs bg-innovator rounded-full ml-1 px-2 py-[1px] shadow -mb-[2px]">
                        {{ totalIdeasToRate }} new
                    </span>
                </span>
                <span v-if="challenge.prize_money || challenge.idea_entry_fee"
                      v-tooltip="prizeTooltip" 
                      :class="{ disabled: !currency(challenge.prize_money) && !challenge.idea_entry_fee }" 
                      class="catalyst-card__footer-meta-item">
                        <NIcon as="money-bill-alt-regular" />
                    ${{ currency(challenge.prize_money) }}
                </span>
                <span v-if="challenge.prize_type" v-tooltip="'Prize Type'" class="catalyst-card__footer-meta-item">
                    <span aria-hidden="true" class="icon icon-trophy-solid"></span>
                    {{ $t(`challenges.prize-type.${challenge.prize_type}`) }}
                </span>
                <button aria-label="button" v-tooltip="'Share'" class="btn has-no-style challenge-card__footer-item" @click="() => $refs.sharingPopup.open()">
                    <NIcon as="share-square-solid" left />Share
                </button>
                <NSharingPopup :item="challenge" ref="sharingPopup" />
            </div>

            <NParticipants
                v-if="challenge.participants"
                :class="{ '!hidden sm:!flex': isAMA }"
                :participants="challenge.participants"
                :anonymous="9"
                friends
            />

            <NTimeRemaining
                v-if="challenge.duration_end"
                :class="{ 'hidden sm:flex': isAMA }"
                :datetime="challenge.duration_end"
                :from="challenge.duration_start"
                show-closed-time
                auto-update
            />
        </div>
    </div>
</template>
