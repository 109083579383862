export default requester => {
    return {
        /**
         * Get content
         *
         * @return {object}
         */
        find (slug, handle) {
            return requester.get(`/content/authors/${handle}/contents/${slug}`);
        },

        /**
         * Create or update content
         * @return {object}
         */
        save (payload) {
            return requester.post(`/content/contents`, payload);
        },

        /**
         * Get suggestions
         * 
         * @return {object}
         */
        suggestions () {
            return requester.get(`/content/suggestions`);
        },
    };
}
