<template>
    <div class="py-2 flex flex-col">
        <div class="flex flex-col sm:flex-row sm:items-center sm:space-x-5 flex-wrap !w-full">
            <div class="animate-pulse flex-wrap flex gap-1 sm:gap-3 sm:flex-1 sm:items-center">
                <div class="h-5 bg-dust-200 rounded w-3/12"></div>
                <div class="h-5 bg-dust-200 rounded w-2/12"></div>
                <div class="h-5 bg-dust-200 rounded w-1/12"></div>
                <div class="h-5 bg-dust-200 rounded w-1/12"></div>
                <div class="h-5 bg-dust-200 rounded w-2/12"></div>
                <div class="h-5 bg-dust-200 rounded w-2/12"></div>
                <div class="h-5 bg-dust-200 rounded w-2/12"></div>
                <div class="h-5 bg-dust-200 rounded w-2/12"></div>
            </div>
            <div class="animate-pulse flex space-x-2 flex items-center">
                <div class="h-10 w-10 rounded-full bg-dust-200"></div>
                <div class="flex flex-col gap-1">
                    <div class="h-4 bg-dust-200 w-20"></div>
                    <div class="h-2 bg-dust-200 w-25"></div>
                </div>
            </div>
        </div>
        <div class="h-[7.5rem] bg-dust-200 rounded w-full mt-4"></div>
        <div class="h-8 bg-dust-200 rounded w-full mt-2"></div>
    </div>
</template>