<script>
import capitalize from 'lodash/capitalize';

export default {
    name: 'NEncouraging',

    props: {
        modelValue: {
            type: [String, Number],
            default: 0
        },
        tooltip: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        picked: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue', 'hovered'],

    data() {
        return {
            icons: {
                1: 'angry',
                2: 'sad',
                3: 'meh',
                4: 'smile',
                5: 'happy'
            }
        }
    },

    methods: {
        capitalize,
        
        update(value) {
            if (!this.disabled) {
                this.$emit('update:modelValue', value);
            }
        },
        hover(value) {
            if (!this.disabled) {
                this.$emit('hovered', value);
            }
        }
    }
}
</script>

<template>
    <span class="flex" @mouseout="hover(0)">
        <template v-if="!picked">
            <NIcon
                v-for="i in 5"
                v-tooltip="capitalize(icons[i])"
                class="font-size-1-5-rem text-dust"
                :key="i"
                :as="modelValue === i ? `${icons[i]}-solid` : icons[i]"
                :class="{
                    'cursor-pointer': !disabled,
                    'hover:text-rater': !disabled,
                    'text-rater': modelValue === i && !disabled,
                    'text-dust': modelValue === i && disabled,
                    'mr-3': i !== 5
                }"
                @click="update(i)"
                @mouseover="hover(i)"
            />
        </template>
        <NIcon v-else :as="icons[modelValue]" @mouseover="hover(modelValue)" />
    </span>
</template>
