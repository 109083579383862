import { useFeatureFlag } from '@/composable/feature-flag';
import { watchEffect } from 'vue';

export default {
  mounted(el, binding, vnode) {
    // The flag name comes after the colon (v-flag:flagName)
    const flag = binding.arg; 
    
    // Extract options - can be a string (just variant) or an object with options
    let variant = null;
    let strict = true;
    let expect = null;
    let transform = null;
    
    if (typeof binding.value === 'string') {
      variant = binding.value;
    } else if (binding.value && typeof binding.value === 'object') {
      variant = binding.value.variant;
      strict = binding.value.strict !== undefined ? binding.value.strict : true;
      expect = binding.value.expect !== undefined ? binding.value.expect : null;
      transform = binding.value.transform || null;
    }
    
    // Handle modifiers as options
    if (binding.modifiers.loose) strict = false;
    
    // Skip if no flag specified
    if (!flag) return;
    
    const { enabled, variantTo } = useFeatureFlag(flag);
    const variantTarget = variant ? variantTo(variant) : { value: null };
    
    // Create effect to handle visibility changes reactively
    const stop = watchEffect(() => {
      let shouldRender = true;
      
      // If flag isn't enabled and strict mode is on, hide element
      if (!enabled.value && strict) {
        shouldRender = false;
      } 
      // If variant is specified, check if it's available
      else if (variant) {
        if (!variantTarget.value) {
          shouldRender = false;
        } else if (expect !== null) {
          // Apply transform if provided
          const payload = transform 
            ? transform(variantTarget.value.payload) 
            : variantTarget.value.payload;
          
          shouldRender = expect === payload;
        }
      }
      
      // Apply visibility
      el.style.display = shouldRender ? '' : 'none';
    });
    
    // Store cleanup function
    el._flagCleanup = stop;
  },
  
  beforeUnmount(el) {
    // Clean up the watcher when element is removed
    if (el._flagCleanup) {
      el._flagCleanup();
      delete el._flagCleanup;
    }
  },
  
  updated(el, binding) {
    // Re-apply when directive value changes
    if (binding.arg !== binding.oldArg || binding.value !== binding.oldValue) {
      if (el._flagCleanup) el._flagCleanup();
      this.mounted(el, binding);
    }
  }
};