import { createSharedComposable } from '@vueuse/core';
import { useStore } from 'vuex';
import { computed, isRef } from 'vue';
import { orderBy } from 'lodash';

export const useCategory = createSharedComposable((key) => {
  const slug = isRef(key) ? key : computed(() => key);
  const store = useStore();
  const categories = computed(() => orderBy(store.state.app.categories, ['name']));
  const category = computed(() => categories.value.find(category => category.slug === slug.value));

  return { categories, category };
})