<script>
import { date as formatDate } from '@bignerve/ui-utils';

export default {
    name: 'NotificationHighlight',

    props: {
        detail: {
            type: Object,
        },
        simple: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        icon () {
            const icons = {
                'Catalyst.ChallengePublished': 'question-circle-solid text-catalyst',
                'Catalyst.ChallengeClosed': 'question-circle-solid text-catalyst',
                'Innovator.IdeaMentored': 'lightbulb-o1 text-innovator',
                'Analyst.UserBrainScoreUpdated': 'arrow-solid-circle-up text-analyst',
                'Innovator.UserBrainScoreUpdated': 'arrow-solid-circle-up text-innovator',
            };

            const key = Object.keys(icons).find(
                key => key.match(new RegExp(this.detail.highlight_type, 'i'))
            );

            return icons[key] ? `${icons[key]} text-2xl` : 'lightning text-3xl'
        },
    },

    methods: {
        formatDate,
    },
}
</script>

<template>
    <div class="flex items-center game-dash-list-item" :class="`is-${detail.role}`">
        <div class="min-w-9 flex items-center justify-center">
            <NIcon :as="icon" class="" />
        </div>
        <div class="flex-1 pl-2 game-dash-list-item__content">
            <NMarkdown
                :code="detail.statement || $t('gamedash.highlight.no-statement')"
                class="text-dust-800"
                inline
            />
            <div v-if="!simple" class="game-dash-list-item__meta">{{ formatDate(detail.notified_at * 1000, 'human') }}</div>
        </div>
    </div>
</template>