<template>
    <div class="tabs__content-helper">
        <p class="form-block-help-text">To promote your idea tournament, share your ReNerved and personal Challenges with your friends, colleagues, and followers. You earn <b>Scout</b> game Neurons (points) for sharing earn <b>Olympiad Director</b> neurons, too. And, best of all, you get more creative ideas by sharing!</p>
        <div class="form-section-wrapper">
            <h2 class="form-group__title mb-5">Customize your invite Tweet</h2>
            <div class="grid grid-cols-12 gap-4 mb-3">
                <div class="col-span-12 md:col-span-10 col-start-2">
                    <div class="form-groups">
                        <div class="form-group is-fixed-inline">
                            <label class="form-group__label">Subject:</label>
                            <NInput></NInput>
                        </div>
                        <div class="form-group is-fixed-inline">
                            <label class="form-group__label">Your Tweet:</label>
                            <div class="form-group__fix-helper">
                                <NTextarea size="small"></NTextarea>
                                <p class="form-block-help-text mt-3 mb-0"><i>Tweets can be up to 280 characters. If you customize this text, we’ll save it for next time.</i></p>
                            </div>
                        </div>
                        <div class="form-group is-fixed-inline">
                            <label class="form-group__label">Media Card:</label>
                            <div class="media-card-share-helper">
                                <div class="media-card-share">
                                    <div class="media-card-share-header">
                                        <img src="/img/brain.svg" alt="A Bignerve Platform Logo" class="media-card-share-header__logo">
                                        <h2 class="media-card-share-header__title">Put your imagination to the test!</h2>
                                    </div>
                                    <div class="media-card-share-body">
                                        <div class="media-card-share-body__col is-text">
                                            <h3>What could be new strategies to help Maine retain and attract more young people to live and work in Maine?</h3>
                                            <div class="discover-media-item is-vertically-centered mt-3">
                                                <img src="/img/placeholder/user-05.png" class="discover-media-item__avatar" alt="">
                                                <div class="discover-media-item__data">
                                                    <h4 class="discover-media-item__name">
                                                        <a href="#">User Name</a>
                                                    </h4>
                                                    <span class="discover-media-item__social">
                                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                                        <span class="discover-media-item__social-icons">
                                                            <a href="#">
                                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                                            </a>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="media-card-share-body__col is-image">
                                            <figure class="challenge-card__image-wrapper">
                                                <a href="#" class="challenge-card__category">Comedy</a>
                                                <a href="#">
                                                    <img src="/img/placeholder/artical-image-02.png" alt="" class="challenge-card__image">
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                                    <div class="media-card-share-footer">
                                        <div class="challenge-card__footer mt-0">
                                            <a href="#" class="challenge-card__footer-item mr-3">
                                                <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                                            </a>
                                            <span class="challenge-card__footer-item mr-3">
                                                <span aria-hidden="true" class="icon icon-money-bill-alt-regular"></span>
                                            </span>
                                            <a href="#" class="challenge-card__footer-item mr-3">
                                                <span aria-hidden="true" class="icon icon-trophy-solid"></span>
                                            </a>
                                            <a href="#" class="challenge-card__footer-item">
                                                <span aria-hidden="true" class="icon icon-share-square-solid"></span>
                                            </a>
                                        </div>
                                        <div class="challenge-card__countdown">
                                            Time remaining:
                                            <span class="in-day-countdown">
                                                <span class="in-day-countdown__digit">19</span>
                                                <span class="in-day-countdown__digit">05</span>
                                                <span class="in-day-countdown__digit">43</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <button aria-label="button" class="btn is-blue">
                Tweet Now
                <span aria-hidden="true" class="btn__icon is-right icon-paper-plane-regular"></span>
            </button>
        </div>
    </div>
</template>
