export default requester => {
    return {
        /**
         * Get challenge
         *
         * @param {sting} slug
         * @returns
         */
        challenge (challengeslug) {
            return requester.get(`/me/innovator/${challengeslug}`)
        },

        /**
         * Get challenge idea
         *
         * @param {string} challengeId
         * @param {string} ideaId
         * @returns
         */
        idea (challengeId, ideaId) {
            return requester.get(`/me/innovator/${challengeId}/ideas/${ideaId}`)
        },

        /**
         * Get challenges with ideas
         * 
         * @param {*} params 
         * @returns 
         */
        subscribedChallenges (params = {}) {
            return requester.get(`/me/innovator`, { params })
        },

        /**
         * Get mentor comments
         * 
         * @param {string} challengeId
         * @param {string} ideaId
         */
        mentorComments (challengeId, ideaId, params = {}) {
            return requester.get(`/me/innovator/${challengeId}/ideas/${ideaId}/mentor_comments`, { params })
        }
    };
}
