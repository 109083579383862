<script>
import NMenu from '@/components/NMenu.vue';
import { TYPE } from '@/constant/olympiad';

const icons = {
    [TYPE.PUBLIC]: 'globe',
    [TYPE.PRIVATE]: 'square'
}

export default {
    name: 'OlympiadsMenu',

    inject: {
        olympiads: {
            from: 'olympiads',
            default: { bases: [] },
        },
    },

    components: { NMenu },

    props: {
        placement: {
            type: String,
            default: 'bottom',
        },
        type: {
            type: String,
            required: true,
        },
        bypass: {
            type: Boolean,
            default: false,
        }
    },

    emits: ['loaded', 'selected'],

    computed: {
        placements () {
            return this.placement
                .split('-')
                .reduce((acc, key) => (acc[key] = true, acc), {})
        },
        menu () {
            return this.$refs.menu;
        },
        items () {
            return this.olympiads[this.type];
        },
    },

    data () {
        return {
            icons,
        };
    },

    methods: {
        open () {
            if (this.bypass && this.items.length === 1) {
                this.$emit('selected', this.items[0]);
            } else {
                this.menu.toggle(true);
            }
        },
    },
}
</script>

<template>
    <NMenu ref="menu" v-bind="placements">
        <template #activator>
            <slot name="activator" :on="{ click: open }" />
        </template>

        <div class="flex items-center p-3 text-dust-700 border-b font-semibold">
            <NIcon as="stream" class="mr-2" />
            <span>Choose which Tournament</span>
        </div>

        <div v-if="items.length" class="flex flex-col w-full">
            <button aria-label="button" v-for="olympiad of items" :key="olympiad.id" type="button" class="flex flex-grow items-center px-4 py-3 text-gray-700 hover:bg-dust-100 hover:text-dust-800" @click="$emit('selected', olympiad)">
                <NIcon :as="icons[olympiad.type]" class="mr-2" />
                <span>{{ olympiad.name }}</span>
            </button>
        </div>

        <div v-else class="py-10 px-4 w-full text-center">
            No Tournaments were found! This may have been caused by an error, <router-link :to="{ name: 'send-feedback', query: { about: 'No Tournaments were found!', feedback: `I am not able to create challenges\n\nUser handle: ${$auth.user.screen_name} ` } }">click here to report</router-link>.
        </div>
    </NMenu>
</template>
