import axios from 'axios';

export default requester => {
    const provider = axios.create();

    return {
        /**
         * Get url to upload file
         */
        async uploadImage (payload, params = {}, defaulHeaders = {}, onUpdateProgress = () => {}) {
            try {
                const { data: { url, headers } } = await requester.post('/files/image-upload', params);
                const normalizedHeaders = Object.assign(
                    defaulHeaders, Object.keys(headers).reduce((acc, header) => {
                    acc[header] = headers[header].join(', ')

                    return acc
                }, {}))
                const rawURL = (({ origin, pathname }) => [origin, pathname].join(''))

                await provider.put(
                    url,
                    payload,
                    {
                        headers: normalizedHeaders,
                        onUploadProgress: (event) => {
                            const progress = Math.round((event.loaded * 100) / event.total);
                            
                            onUpdateProgress(progress);
                        }
                    }
                );

                return {
                    data: { url: rawURL(new URL(url)) },
                };
            } catch (err) {
                throw err;
            }
        },

        /**
         * Generate meme from image on bucket
         * 
         * @param {source, setup, pushline, maxWidth, maxHeight} payload 
         * @returns 
         */
        async memeGenerator (payload) {
            return requester.post('/meme-generator', payload);
        }
    };
}
