import axios from 'axios';

const supportsAbort = typeof AbortController === 'function';

export const useCancelToken = () => {
    let cancelTokenSource = null;

    const abortPrevious = (message) => {
        if (supportsAbort && cancelTokenSource) {
            cancelTokenSource.cancel(message);
        }
    }

    const nextToken = () => {
        cancelTokenSource = axios.CancelToken.source()
        
        return cancelTokenSource.token;
    }

    return { nextToken, abortPrevious };
}
