<script setup>
import { useLinks as useAuthLinks } from '@/composable/auth';

const { signupURL } = useAuthLinks();
</script>

<template>
    <div class="info-text w-full sm:flex-wrap items-center p-10">
        <div class="flex-1 order-2 sm:order-none">
            <!-- Content -->
            <h2 class="text-3xl font-bold mb-3">
                Welcome to <span class="text-nerve">MaineNerve</span>, an online community platform where Maine's innovative  thinkers help businesses like yours innovate.</h2>


            <div class="flex" style="float:right;">
                <img src="/img/idea-bulb-innovate-mainenerve.svg" class="h-60 w-60" alt="MaineNerve idea lightbulb illustration">
            </div>

            <p class="mb-3">
                We are building a new way to harness the  “wisdom of the crowd”. Imagine having your own virtual think tank that attracts bright people to your organization, where they crowdsource unexpected new ideas and feedback for questions that you ask about your own products and services. Then we add in a fun, competitive tournament layer which provides incentives like reputation and money to attract participants. We provide the platform to manage innovation at scale. 
            </p>                
            <p>
                <b>Why do you need crowdsourcing?</b> There are several reasons. Attract useful consumer feedback & insights on your products & services, as well as feedback on your draft media campaigns and marketing materials. Collect fresh new ideas from your customers, followers, and employees. And, every organization should have its own “idea wind tunnel” to test their ideas before expensive outlays. Over time you'll incentivize a team of remote “idea workers” who enjoy providing novel feedback to your company’s various product and design challenges. As Benjamin Franklin demonstrated: when people  help you, they care about you. 
            </p>

            <p class="mb-3">
                Let us prove how our idea tournament system is a better tool than surveys for collecting useful feedback & suggestions from your existing customers and social media followers. It's more engaging, and participants are motivated to win prize money as well as build a reputation. Designate a small portion of your advertising budget as prize money on your platform, and you'll attract the time and attention of innovative thinkers far better than any advertisement. 
            </p>

            <p>And, you can use your challenge questions as tool to attract and vet job seekers. A challenge question is a 10x better interview question, as it assesses real-world creativity and applied thinking better than unstructured, unconscious bias-prone interviews.</p>


            <h3 class="text-lg font-bold mb-2">Compare Surveys versus Crowdsourcing</h3>
            <p class="mb-3">
                <a href="/files/Infosheet for bignerve - SMB v2.pdf" target="__blank">Download the free brochure</a> PDF (1mb) that explains the differences. 
            </p>

            <p class="mb-3">
                <a href="https://bignerve.com/" target="_blank">Browse the site</a> to see how the challenge tournament works. Find a challenge question that interests you, then enter an idea (or just rate other ideas). You'll see how other organizations use the platform to harvest insights from a diverse community of thinkers. Then try writing your own Challenge questions – it's free to use! 
            </p>

            <h3 class="text-lg font-bold mb-2">
                <em class="text-nerve">If you could get the most innovative people in one place, what would you do with them?</em>
            </h3>

            <div class="flex items-center justify-center space-x-3 mt-3">
                <a v-if="!$auth.authenticated" :href="signupURL" class="px-4 py-1 rounded-md bg-secondary text-white hover:text-white hover:bg-secondary-600">
                    Sign Up
                </a>
                
                <a v-else href="/" class="px-4 py-1 rounded-md bg-secondary text-white hover:text-white hover:bg-secondary-600">
                    Browse Questions
                </a>
            </div>
        </div>
    </div>
</template>
