<script setup>
import NMarkdown from '@/components/NMarkdown.vue';
import { useDetails } from '@/composable/challenge';

const props = defineProps({
    challenge: {
        type: Object,
        required: true,
    },
    unfurl: {
        type: Boolean,
        default: false,
    },
});

const { questionDetails } = useDetails(props.challenge);
</script>

<template>
    <NMarkdown
        v-if="questionDetails"
        :code="questionDetails"
        :unfurl="unfurl"
    />
</template>