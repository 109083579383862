<script>
import get from 'lodash/get';
import nprogress from 'nprogress';
import FeatureFlag from '@/components/FeatureFlag.vue';
import { useFeatureFlag } from '@/composable/feature-flag';
import { usePlatform } from '@/mixins/ShowsPlatform';

export default {
	mixins: [usePlatform()],
	components: { FeatureFlag },
	data() {
		return {
			loadingShadow: false,
			socialAccounts: [],
			defaultAvatar: '/img/user-profile-images/user-avatar-square-anon.svg',
			social: {
				facebook: {
					link: 'https://facebook.com/{user}',
					icon: 'facebook-f-brands text-blue-600',
				},
				twitter: {
					link: 'https://twitter.com/{user}',
					icon: 'twitter text-blue-400',
				},
				google: {
					link: 'https://google.com/{user}',
					icon: 'google',
				},
				github: {
					link: 'https://github.com/{user}',
					icon: 'github is-color-black',
				},
				linkedin: {
					link: 'https://linkedin.com/{user}',
					icon: 'linkedin',
				},
			},
			imageExpanded: false,
		};
	},
	watch: {
		account: {
			handler(newValue, oldValue) {
				const newHandle = get(newValue, 'user.screen_name');
				const oldHandle = get(oldValue, 'user.screen_name');
				if (newHandle !== oldHandle) {
					this.resolveSocialAccounts(newHandle);
				}
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		url() {
			return this.account.user.avatar_url || this.defaultAvatar;
		},
		isUploaded() {
			return /amazonaws\.com/.test(this.url);
		},
		interests() {
			const { bio } = this.account.profile || {};
			if (bio && bio.interests) {
				return bio.interests.slice(0, 3).join(', ');
			}
		},
	},
	methods: {
		async resolveSocialAccounts(handle) {
			try {
				if (!handle) {
					return;
				}
				const social = await this.$api.user.social(handle).then((r) => r.data.data);
				const accounts = Object.keys(social.accounts || {});
				this.socialAccounts = accounts.map((account) => {
					const { link, icon } = this.social[account];
					return {
						provider: account,
						icon,
						link: link.replace('{user}', social.accounts[account].handle),
					};
				});
			} catch (err) {
				console.error(err);
			}
		},
		async shadowUser(handle) {
			if (this.loadingShadow) return;
			try {
				nprogress.start();
				this.loadingShadow = true;
				const { data } = await this.$api.user.shadow({
					user_handle: handle,
				});
				location.replace(`${location.origin}/${handle}/my-dashboard?token=${data.token}&shadow=true`, '_blank');
			} catch (err) {
				console.error(err);
			} finally {
				nprogress.done();
				this.loadingShadow = false;
			}
		},
	},

	setup () {
		const { enabled: showShadowMode } = useFeatureFlag('shadow-mode');

		return {
			showShadowMode,
		}
	}
};
</script>

<template>
	<div
		v-if="account.user && account.user.id"
		class="flex user-info-block"
	>
		<div class="flex flex-nowrap w-full sm:w-auto">
			<div
				class="border-t border-b border-l rounded-l-[4px] border-gray-200 cursor-pointer overflow-hidden"
				:class="`user-info-block${isUploaded ? '__large' : '__image'}`"
				:style="`background-image: url(${url}); background-color: #e1e2e1;`"
				@click="() => $refs.avatar.open()"
			></div>
			<div class="user-info-block__content flex flex-col justify-between border-t border-b border-r border-gray-200 sm:min-w-[15rem]">
				<div class="flex items-center justify-between">
					<div class="flex items-center">
						<RouterLink
							:to="{
								name: 'home.user',
								params: { user: account.user.screen_name },
							}"
							class="user-info-block__social text-base"
							title="View this User's Platform Home"
						>
							@{{ account.user.screen_name }}
						</RouterLink>
						<a
							:href="`/${account.user.screen_name}`"
							class="-mt-0.5"
						>
							<img
								src="/img/brain.svg"
								alt="Bignerve"
								aria-hidden="true"
								class="h-3.5 ml-1"
							/>
						</a>
						<a
							v-for="account of socialAccounts"
							:key="account.provider"
							:href="account.link"
							target="__blank"
							class="ml-1.5"
						>
							<NIcon
								:as="account.icon"
								class="text-xs"
							/>
						</a>
					</div>
					<div class="flex items-center gap-2 -mt-2">
						<RouterLink
							v-if="viewingSelf"
							v-tooltip="{ content: 'Edit Masthead', placement: 'top-end' }"
							:to="{ name: 'account.my-account.masthead' }"
							class="hover:!bg-dust-300 hover:!text-dust-800 bg-dust-200 text-dust-700 rounded-full h-8 w-8 flex items-center justify-center"
						>
							<NIcon
								as="pencil-alt-solid"
								class="text-xs"
							/>
						</RouterLink>

						<button aria-label="button"
							v-if="showShadowMode && $auth.isAdmin && !viewingSelf"
							v-tooltip="{ content: 'Shadow User', placement: 'top-end' }"
							type="button"
							class="relative rounded-full h-8 w-8 flex items-center justify-center bg-red-100 hover:bg-red-200"
							@click="shadowUser(account.user.screen_name)"
						>
							<NIcon
								as="shadow-mode"
								class="text-xs text-red-500 z-[1]"
							/>
							<span
								v-if="loadingShadow"
								class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-200 opacity-75"
							></span>
						</button>
					</div>
				</div>

				<NMarkdown
					:title="account.user.bio"
					:code="account.user.bio"
					:inline="true"
					class="text-dust-800 text-sm leading-snug"
				/>
				
				<!-- <small class="user-info-block__bio hidden">{{ interests }}</small> -->
				<div non-functional class="flex flex-wrap justify-between items-center">
					<RouterLink
						v-tooltip="{
							content: 'View this User\'s Platform Home',
							placement: 'bottom-end',
						}"
						:to="{
							name: 'home.user',
							params: { user: account.user.screen_name },
						}"
						class="flex items-center text-dust-800 font-semibold hover:text-nerve px-2 py-1 -mx-2 -mb-2 rounded-md hover:bg-dust-300"
					>
						<NIcon
							as="user-avatar"
							class="mr-1"
						/>View User
					</RouterLink>
					<span :class="{ disabled: $auth.authenticated && $auth.user.id !== account.user.id }"
						class="flex items-center text-dust-800 font-semibold px-2 py-1 -mx-2 -mb-2 rounded-md"
					>
						<NIcon
							as="brain"
							class="mr-1"
						/>
						Level
						{{ $auth.authenticated && $auth.user.id === account.user.id ? $auth.current.game_levels.nerve_level : 'n/a' }}
					</span>
					<RouterLink
						v-if="currentUser.user.screen_name"
						v-tooltip="{ content: 'Fund this user', placement: 'bottom-end' }"
						:to="{
							name: 'dashboard.dashboard',
							params: { user: currentUser.user.screen_name },
						}"
						class="flex items-center text-dust-800 font-semibold hover:text-nerve px-2 py-1 -mx-2 -mb-2 rounded-md hover:bg-dust-300"
					>
						<NIcon
							as="funding"
							class="mr-1"
						/>n/a
					</RouterLink>
				</div>
			</div>
		</div>

		<div class="gap-2 flex flex-wrap w-full mt-3 sm:justify-end">
			<template v-if="viewingSelf">
				<button aria-label="button"
					type="button"
					title="Share this profile"
					class="btn !px-[0.8rem] is-small is-white justify-center"
					@click.prevent="() => $refs.popup.open()"
				>
					<NIcon
						as="share-square-solid"
						class="mr-2"
					/>
					Share
				</button>
				<FeatureFlag v-if="$auth.user?.id" flag="user-bio">
					<RouterLink
						:to="{ name: 'home.user.about-me', params: { user: $auth.user.screen_name } }"
						class="btn !px-[0.8rem] is-small is-white justify-center"
					>
						<NIcon
							as="user-avatar"
							class="mr-2"
						/>
						Bio
					</RouterLink>
				</FeatureFlag>
				<RouterLink
					:to="{ name: 'challenges.create' }"
					class="btn !px-[0.8rem] is-small is-red justify-center"
				>
					<NIcon
						as="question-circle"
						class="mr-2"
					/>
					Create Challenge
				</RouterLink>
				<RouterLink
					non-functional
					:to="{ name: 'challenges.create' }"
					class="btn !px-[0.8rem] is-small is-green justify-center"
				>
					<NIcon
						as="lightbulb-o1"
						class="mr-2"
					/>
					Test Idea
				</RouterLink>
				<a
					href="#"
					class="btn !px-[0.8rem] is-small is-orange justify-center"
					non-functional
					@click.prevent
				>
					<NIcon
						as="bulb-plus"
						class="mr-2"
					/>
					Save Idea
				</a>
			</template>
			<template v-else>
				<a	v-if="$auth.authenticated"
					href="#"
					class="btn !px-[0.8rem] is-small is-white leading-none justify-center"
					title="Share this profile"
					@click.prevent="() => $refs.popup.open()"
				>
					<span
						aria-hidden="true"
						class="btn__icon is-left icon-share-square-solid"
					></span>
					Share
				</a>
				<FeatureFlag
					v-if="account.user?.id"
					flag="user-bio"
				>
					<RouterLink
						:to="{ name: 'home.user.about-me', params: { user: account.user.screen_name } }"
						class="btn !px-[0.8rem] is-small is-white leading-none justify-center"
					>
						<NIcon
							as="user-avatar"
							class="mr-2"
						/>
						Bio
					</RouterLink>
				</FeatureFlag>
				<RouterLink
					:to="{ name: 'faqs.follow-user' }"
					class="btn !px-[0.8rem] is-small is-white leading-none justify-center"
					title="Follow this user and get alerts about their activity"
				>
					<span
						aria-hidden="true"
						class="btn__icon is-left icon-follow"
					></span>
					Follow
				</RouterLink>
				<RouterLink
					:to="{ name: 'faqs.friend-request' }"
					class="btn !px-[0.8rem] is-small is-white leading-none justify-center"
					title="Send a Friend request"
				>
					<span
						aria-hidden="true"
						class="btn__icon is-left icon-friends"
					></span>
					Friend
				</RouterLink>
				<RouterLink
					:to="{ name: 'faqs.fund-user' }"
					class="btn !px-[0.8rem] is-small is-white leading-none justify-center"
					title="Fund this user"
				>
					<span
						aria-hidden="true"
						class="btn__icon is-left icon-funding"
					></span>
					Fund
				</RouterLink>
			</template>

			<NSharingPopup
				ref="popup"
				:item="account"
			/>
		</div>

		<NModal
			ref="avatar"
			@close="imageExpanded = false"
		>
			<div class="overflow-auto relative sb-tight sb-dust-200 max-h-screen max-w-screen m-4">
				<img
					:src="url"
					:style="{ zoom: imageExpanded ? '2' : 'normal' }"
					:class="{ 'max-h-full': !imageExpanded }"
					alt=""
					@click="imageExpanded = !imageExpanded"
					class="cursor-pointer"
				/>
				<div class="top-3 right-3 absolute flex items-center justify-center gap-2">
					<button aria-label="button"
						v-if="!$responsive.sm"
						type="button"
						class="w-9 h-9 text-white flex items-center justify-center bg-black bg-opacity-60 hover:bg-opacity-80 rounded-md"
						@click="imageExpanded = !imageExpanded"
					>
						<svg
							v-if="imageExpanded"
							xmlns="http://www.w3.org/2000/svg"
							class="h-5 w-5"
							fill="currentColor"
							stroke="currentColor"
							viewBox="0 0 448 512"
						>
							<path
								d="M128 320H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32v-96C160 334.3 145.7 320 128 320zM416 320h-96c-17.69 0-32 14.31-32 32v96c0 17.69 14.31 32 32 32s32-14.31 32-32v-64h64c17.69 0 32-14.31 32-32S433.7 320 416 320zM320 192h96c17.69 0 32-14.31 32-32s-14.31-32-32-32h-64V64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96C288 177.7 302.3 192 320 192zM128 32C110.3 32 96 46.31 96 64v64H32C14.31 128 0 142.3 0 160s14.31 32 32 32h96c17.69 0 32-14.31 32-32V64C160 46.31 145.7 32 128 32z"
							/>
						</svg>
						<svg
							v-else
							xmlns="http://www.w3.org/2000/svg"
							class="h-5 w-5"
							fill="currentColor"
							stroke="currentColor"
							viewBox="0 0 448 512"
						>
							<path
								d="M128 32H32C14.31 32 0 46.31 0 64v96c0 17.69 14.31 32 32 32s32-14.31 32-32V96h64c17.69 0 32-14.31 32-32S145.7 32 128 32zM416 32h-96c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32V64C448 46.31 433.7 32 416 32zM128 416H64v-64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96c0 17.69 14.31 32 32 32h96c17.69 0 32-14.31 32-32S145.7 416 128 416zM416 320c-17.69 0-32 14.31-32 32v64h-64c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c17.69 0 32-14.31 32-32v-96C448 334.3 433.7 320 416 320z"
							/>
						</svg>
					</button>
					<button aria-label="button"
						type="button"
						class="w-9 h-9 text-white flex items-center justify-center bg-black bg-opacity-60 hover:bg-opacity-80 rounded-md"
						@click="$refs.avatar.close()"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
			</div>
		</NModal>
	</div>
</template>
