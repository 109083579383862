/** @module gamePoints */
export default requester => {
    return {
        /**
         * Retrieves the authenticated user’s friend’s neurons over time broken down by category.
         *
         * @param {string} pointCategory The point category to retrieve data for - one of (‘catalyst’,’innovator’,’rater’,’mentor’,’scout’,’oracle’,’director’)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        friendsNeuronsOverTime(pointCategory, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/game-points/friends-neurons-over-time', Object.assign({ pointCategory }, params), headers
            );
        },

        /**
         * Retrieves the authenticated user’s neurons over time broken down by category.
         *
         * @param {string} pointCategory The point category to retrieve data for - one of (‘catalyst’,’innovator’,’rater’,’mentor’,’scout’,’oracle’,’director’)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        userNeuronsOverTime(pointCategory, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/game-points/friends-neurons-over-time', Object.assign({ pointCategory }, params), headers
            );
        }
    }
}
