<script>
import Medal from "@/components/leaderboard/Medal.vue";
import RecruitDetailPopover from '@/components/home/RecruitDetailPopover.vue';
import { mapState } from 'vuex';
import { numeric } from '@bignerve/ui-utils';

export default {
    name: 'HomeTeams',

    components: { Medal, RecruitDetailPopover },

    data() {
        return {
            busy: false,
            teams: [],
        }
    },

    computed: {
        ...mapState('app', ['categories'])
    },

    mounted() {
        this.fetch();
    },

    methods: {
        numeric,

        async fetch() {
            this.busy = true;
            const { data: teams } = await this.$api.platform.teams(this.$platform.current.id);

            this.teams = teams.data;
            this.busy = false;
        }
    }
};
</script>

<template>
    <div>
        <div v-if="!teams.length" class="flex items-center justify-center text-dust-700">
            {{ $t('common.loading') }}
        </div>
        <div v-for="({ id, position, category, stats, recruits }) of teams" :key="id" class="is-background-white is-outlined is-rounded__medium mb-3">
            <div class="flex justify-between items-center px-3 py-2 border-b border-dust-200">
                <h3 class="flex items-center m-0">
                    <img class="is-filter-brack h-6 w-6 mr-3" height="25" :src="`/img/category-icons/${category.icon}`" />{{ category.name }}
                </h3>
                <div class="inline-flex items-center">
                    <strong class="mr-2 is-color-dark-gray">{{ $t('page.home.teams.category.team-score') }}:</strong>
                    <span class="is-color-medium-gray">{{ stats.team_score }}</span>
                </div>
            </div>
            <div class="p-3 flex flex-row">
                <div class="mr-3">
                    <NChip class="is-scout py-1" rounded>
                        #{{ position }}
                    </NChip>
                    <div class="is-color-medium-gray font-size-0-9-rem mt-1">
                        {{ $t('page.home.teams.category.of-teams', { length: stats.total_teams }) }}
                    </div>
                </div>
                <div>
                    <div class="flex flex-wrap">
                        <div v-for="(recruit, i) of recruits" :key="`${i}-recruit`">
                            <recruit-detail-popover :detail="recruit">
                                <div class="py-2 px-3 flex-col flex justify-center items-center is-touchable">
                                    <NAvatar :src="recruit.avatar_url" large />
                                    <strong class="mt-2 text-dust-800">
                                        {{ [recruit.first_name, recruit.last_name].join(' ') }}
                                    </strong>
                                    <span class="text-scout font-semibold">
                                        {{ numeric(recruit.metrics.neurons) }}
                                    </span>
                                </div>
                            </recruit-detail-popover>
                        </div>
                    </div>
                    <p class="is-italic is-color-medium-gray">
                        {{ $t('page.home.teams.category.note', { platform: $platform.current.title }) }}
                    </p>
                    <div class="flex">
                        <div v-if="stats.total_gold_medails" class="flex p-2 items-center">
                            <medal :position="1" class="mr-2" />
                            <span class="is-color-medium-gray">{{ stats.total_gold_medails }}x</span>
                        </div>
                        <div v-if="stats.total_silver_medals" class="flex p-2 items-center">
                            <medal :position="2" class="mr-2" />
                            <span class="is-color-medium-gray">{{ stats.total_silver_medals }}x</span>
                        </div>
                        <div v-if="stats.total_bronze_medals" class="flex p-2 items-center">
                            <medal :position="3" class="mr-2" />
                            <span class="is-color-medium-gray">{{ stats.total_bronze_medals }}x</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
