export default requester => {
    return {
        /**
         * Get follow widget.
         *
         * @param {object} params
         * @param {object} headers
         */
        follow(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/widgets/follow', {}, params, headers);
        }
    };
}
