export default requester => {
    return {
        /**
         * Search by locations.
         *
         * @param {string} params
         */
        search (params) {
            return requester.get(`/geolocation/search`, { params });
        },

        /**
         * Search by locations.
         *
         * @param {string} params
         */
        suggest (params) {
            return requester.get(`/geolocation/suggest`, { params });
        },
    };
}
