<script>
import capitalize from 'lodash/capitalize';
import { date } from '@bignerve/ui-utils';

export default {
    data () {
        return {
            socialIcons: {
                facebook: 'icon-facebook-f-brands',
                twitter: 'icon-twitter',
                google: 'icon-google',
                github: 'icon-github',
                linkedin: 'icon-linkedin',
            }
        }
    },

    computed: {
        profile () {
            return this.$store.getters['auth/currentUser'].profile || {};
        },
        shareLink () {
            return `${location.origin}/${this.profile.masthead.handle}?scout_id=${this.profile.masthead.handle}`;
        }
    },

    methods: {
        date,
        capitalize,

        update() {
            let user = this.$store.getters['auth/currentUser'];

            this.$store.commit('auth/setCurrentUser', user);
        },

        disconnect (account) {
            account.busy = true
            this.$nerve.users.disconnectSocialAccount(account.provider).then(() => {
                this.profile.social.accounts.splice(
                    this.profile.social.accounts.indexOf(account), 1
                );
            }).catch(() => {
                //
            }).finally(() => {
                account.busy = false
            })
        }
    }
}
</script>

<template>
    <article class="page">
        <NPageHeader
            title="Social Integrations"
            subtitle="Manage your social integrations"
            icon="heart"
        />
        <div class="page__content mb-4">
            <p class="mb-4">
                <!-- You can sign in via your other social accounts, like Twitter, Facebook, Linkedin and others (via OAUTH). -->
                This is a list of any of these accounts you've connected. You can revoke sign-in permissions for each of these at any time, but be sure you create a <router-link to="/my-account/security" title="Manage your password">password here</router-link> <em>before</em> disconnecting the last account.
            </p>
            <template v-if="profile.social">
                <div v-for="account of profile.social.accounts" :key="account.provider" class="flex gap-4 mb-3 items-center border rounded-md px-4 py-2">
                    <div class="flex-1 flex items-center">
                        <h3 class="m-0"><span aria-hidden="true" :class="socialIcons[account.provider]" class="mr-2"></span>
                        {{ capitalize(account.provider) }}
                    </h3>
                    </div>
                    <div class="flex-1 text-align--right is-color-green" >
                        Last used on {{ date(account.expires_at, 'YYYY-MM-DD') }}
                    </div>
                    <div class="flex-1 flex justify-end">
                        <button aria-label="button"
                            type="button"
                            class="btn"
                            @click="disconnect(account)"
                        >
                            {{ account.busy ? 'Disconnecting...' : 'Disconnect' }}
                        </button>
                    </div>
                </div>
            </template>
            <div v-if="profile.social && !profile.social.accounts.length" class="text-center is-color-medium-gray">
                No social account connected!
            </div>
        </div>

        <div v-if="profile.masthead" class="page__content">
            <p class="mb-4">
                This is a preview of how your profile will look when shared on social media.
            </p>

            <NCopy :content="shareLink" :source="shareLink" class="px-6 py-2 mb-4 rounded-md border bg-white inline-flex" />

            <img :src="`https://func.bignerve.com/og-image/${profile.masthead.handle}.png?refresh=true&v=${Date.now()}`" />
        </div>
    </article>
</template>
