<script>
    import Email from '@/components/sharing/Email.vue';
    import Imgur from '@/components/sharing/Imgur.vue';
    import Reddit from '@/components/sharing/Reddit.vue';
    import Twitter from '@/components/sharing/Twitter.vue';
    import Facebook from '@/components/sharing/Facebook.vue';

    export default {
        components: {
            Email,
            Imgur,
            Reddit,
            Twitter,
            Facebook
        },

        data() {
            return {
                tab: null
            };
        }
    }
</script>

<template>
    <div class="sharing-screen">
        <div class="sharing-heading">
            <h1 class="sharing-heading__title">
                Sharing Panel: <span class="sharing-heading__title-helper">Share this {Challenge}</span>
            </h1>
            <div class="sharing-heading-switch">
                <input class="sharing-heading-switch__input is-simple" type="radio" id="simple-panel" name="type-switch" value="simple-panel" checked/>
                <label for="simple-panel" class="sharing-heading-switch__label">
                    <span aria-hidden="true" class="icon icon-share-square-solid"></span>
                    Simple Panel
                </label>
                <input class="sharing-heading-switch__input is-advanced" type="radio" id="advanced-panel" name="type-switch" value="advanced-panel" />
                <label for="advanced-panel" class="sharing-heading-switch__label">
                    <span aria-hidden="true" class="icon icon-paper-plane-regular"></span>
                    Advanced Panel
                </label>
                <div class="sharing-heading-switch__highlight"></div>
            </div>
        </div>
        <NDataForm action="/sharing" :data="fields">
            <NTabs v-model="tab" class="is-secondary">
                <NTab title="Email" icon="envelope-regular">
                    <email></email>
                </NTab>
                <NTab title="Twitter" icon="twitter">
                    <twitter></twitter>
                </NTab>
                <NTab title="Facebook" icon="facebook-f-brands">
                    <facebook></facebook>
                </NTab>
                <NTab title="Imgur" icon="imgur">
                    <imgur></imgur>
                </NTab>
                <NTab title="Reddit" icon="reddit-alien-brands">
                    <reddit></reddit>
                </NTab>
            </NTabs>
        </NDataForm>
        <div class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <b>Note:</b> By sharing you will get your own personalized link (URL) to post on your favorite social platforms—if anyone clicks through this link, you will get game points (called Scout Neurons). In your <a href="#">Scout Dashboard</a>.
            </p>
        </div>
    </div>
</template>
