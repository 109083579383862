<script>
import MemeGenerator from '@/components/challenge-show/MemeGenerator.vue';

const baseURL = import.meta.env.VUE_APP_BASE_URL;

export default {
    name: 'IdeaTest',

    inject: ['form'],

    components: {
        MemeGenerator
    },

    computed: {
        image() {
            return this.$parent.form.fields.challenge.primary_image || `${baseURL}/img/placeholder/meme-03.png`;
        },
        title() {
            return this.$parent.form.fields.idea.title || 'This is the title';
        },
        description() {
            return this.$parent.form.fields.idea.description || 'This is the description';
        },
        isCaption () {
            return this.$parent.form.fields.challenge.caption_solution_format === 'caption';
        }
    },

    methods: {
        size(text) {
            return text.length < 60 ? (text.length < 30 ? '1.5rem' : '1.3rem') : '1rem';
        }
    },

    mounted () {
        const KEY = 'upload-idea-test';

        if (this.title) {
            this.form.middlewares.set(KEY, async (fields) => {
                const { url } = await this.$refs.memeGenerator.uploadImage();

                fields.idea.primary_image = url;
            })
        } else {
            this.form.middlewares.delete(KEY);
        }
    }
}
</script>

<template>
    <MemeGenerator
        v-if="isCaption"
        :punchline-text="title"
        :image-src="image"
        class="mx-auto max-w-full"
        ref="memeGenerator"
    />
    <MemeGenerator
        v-else
        :setup-text="title"
        :punchline-text="description"
        :image-src="image"
        class="mx-auto max-w-full"
        ref="memeGenerator"
    />
</template>
