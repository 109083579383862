export default requester => {
  return {
      /**
       * Gets all users from the user's staff
       *
       * @param {object} handle
       **/
      users (handle = {}) {
          return requester.get(`/users/${handle}/staff`);
      },
  };
}
