<script>
import ChallengePreview from './Preview.vue';
import SharingPanel from '@/components/sharing/Panel.vue';
import { usePlatform } from '@/mixins/ShowsPlatform';
import { usePreviewable } from '@/mixins/Previewable';

export default {
    name: 'ChallengeShare',

    mixins: [usePreviewable(), usePlatform()],

    components: { ChallengePreview, SharingPanel },

    data () {
        return {
            zoom: false
        };
    },

    bus: {
        'challenge:creation-flow:preview:share-on'(state) {
            this.zoom = state;
        }
    },

    methods: {
        leaderboardRoute (query = {}) {
            return {
                name: 'platform.olympiad.leaderboard',
                params: { platformSlug: this.isUserPlatform
                    ? (this.account.user.screen_name || 'platform')
                    : 'platform'
                },
                query,
            };
        }
    }
}
</script>

<template>
    <div class="flex flex-col justify-center items-center m-3">

        <h2 class="is-color-orange font-size-2-rem mb-5 flex items-center gap-4" style="text-align: center">
            <span><span class="font-bold mr-2">Success —</span> your challenge is now live!</span>

            <NExperiencePoints fqdn="catalyst.ChallengePublished.CreatedChallenge" #default="{ points }">
                <NBubble v-if="!!points" color="catalyst" :animated="true">
                    +{{ points }} Neurons
                </NBubble>
            </NExperiencePoints>
        </h2>
        <h1 class="flex flex-wrap justify-center is-color-secondary text-4xl sm:text-5xl text-center mb-4">
            <img src="/img/paper-airplane.svg" width="44" class="mr-1" />
            <span class="font-bold mr-2">Share</span> with your friends and followers.
        </h1>
        <SharingPanel
            v-if="form.fields.challenge.slug"
            class="zoom"
            :class="{ 'in': zoom }"
            :item="{
                ...form.fields.challenge,
                catalyst: $auth.user,
            }"
            data-info="Save the challenge before sharing"
        />
        <div class="flex flex-col sm:flex-row justify-center mt-5">
            <div class="w-full sm:w-4/12 sm:mr-8 mb-20">
                <ChallengePreview :challenge="form.fields.challenge" />
            </div>
            <div class="w-full sm:w-6/12">
                <h3 style="background: rgba(164, 76, 241, 0.1)" class="px-3 py-2 mb-3">Why share your new challenge question?</h3>

                <div class="flex">
                    <div class="mr-3">
                        <span class="btn is-fab is-scout">1</span>
                    </div>
                    <div>
                        <strong>Win more points</strong>
                        <ul class="pl-4">
                            <li>You’ll increase your score - watch your progress on the <router-link :to="leaderboardRoute()" class="is-text-underline is-color-black is-touchable">leaderboard</router-link>;</li>
                            <li>More players can rate and mentor the ideas in your challenges.</li>
                        </ul>
                    </div>
                </div>

                <div class="flex">
                    <div class="mr-3">
                        <span class="btn is-fab is-scout">2</span>
                    </div>
                    <div>
                        <strong>Help your friends and followers</strong>
                        <ul class="pl-4">
                            <li>They could win a share of the tournament prize money.</li>
                            <li>They can gain recognition for their best ideas.</li>
                        </ul>
                    </div>
                </div>

                <div class="flex">
                    <div class="mr-3">
                        <span class="btn is-fab is-scout">3</span>
                    </div>
                    <div>
                        <strong>Become a Talent Scout</strong>
                        <ul class="pl-4">
                            <li>By sharing challenges, you become a <router-link :to="{ name: 'dashboard.scouting', params: { user: 'me' }}" class="is-color-scout"><b>Scout</b><NIcon as="binoculars-solid" right/></router-link> for the community;</li>
                            <li>Your <b>recruits</b> are like your think tank </li>
                            <li>You also earn points and money from their activities – check out the
                                <router-link :to="leaderboardRoute({ tab: 'scout' })" class="is-text-underline is-color-black is-touchable">Scout Race</router-link>.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="my-10 w-full" v-if="form.fields.challenge.slug">
        <RouterLink :to="{ name: 'challenges.show', params: { challenge: form.fields.challenge.slug } }" class="w-full flex items-center justify-between bg-white shadow-active text-white hover:text-white p-1 rounded-lg">
            <div class="bg-secondary rounded-lg p-4 w-full hover:bg-secondary-600 transition-colors duration-300">
                <div class="flex items-center justify-between max-w-[55ch] mx-auto">
                    <span class="two-row-btn__caption">
                        <span class="two-row-btn__title mb-2">Now go to your Challenge Question’s detail view</span>
                        <span class="two-row-btn__subtitle">You can start a discussion and view your leaderboard.</span>
                    </span>
                    <NIcon as="arrow-circle-right" class="text-3xl"/>
                </div>
            </div>
        </RouterLink>
    </div>
</template>
