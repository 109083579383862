import { mapState } from 'vuex';
import { userPlatformRules } from '@/composable/platform';

const mixin = {
    computed: {
        ...mapState('app', {
            platformCategories: 'categories',
            platform: 'platform',
            contextUser: 'user'
        }),
        ...mapState('auth', ['currentUser']),
        contextPlatform() {
            return this.platform;
        },
        viewingSelf() {
            return this.profilePage || this.currentUser.user.screen_name === this.contextUser.user.screen_name;
        },
        user() {
            if (this.profilePage) {
                return this.currentUser;
            } else if (this.isUserPlatform) {
                return this.contextUser;
            }

            return {};
        },
        profilePage() {
            return /dashboard\.|account\./.test(this.$route.name);
        },
        account() {
            return this.user;
        },
        title() {
            if (this.isUserPlatform && this.account.user && this.account.user.id) {
                return `${this.account.user.first_name} ${this.account.user.last_name}`;
            }

            return this.platform.title;
        },
        subtitle() {
            const category = this.currentCategory;

            return category ? category.name : this.title;
        },
        slogan() {
            if (this.isUserPlatform && this.account.user && this.account.user.id) {
                return this.account.user.slogan;
            }

            return this.platform.slogan;
        },
        currentCategory () {
            const { category: slug = this.$route.query.category } = this.$route.params;
            return this.platformCategories?.find(c => c.slug === slug) || {};
        },
        isUserPlatform() {
            return userPlatformRules.some(rule => {
                return typeof rule === 'function' ? rule(this.$route) : rule.test(this.$route.name)
            });
        },
        isOrganization() {
            return this.account.is_organization;
        },
        isCategory() {
            return ['home.category'].includes(this.$route.name) || !!this.$route.query.category;
        },
        isBignerve () {
            return this.platform.name === 'bignerve';
        },
        isHomepage () {
            return ['home.user', 'home.challenges', 'home.challenges.search'].includes(this.$route.name);
        },
        viewingMyDashboard () {
            return this.$route.params.user === this.$auth.user.screen_name;
        },
    }
}

export const usePlatform = (props = {}) => ({ ...mixin, ...props });

export default mixin;
