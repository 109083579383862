<script>
  export default {
    name: 'NFilters',

    props: {
      modelValue: String,
      centered: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      router: {
        type: Boolean,
        default: false
      },
      headerClass: {
        type: String
      },
      color: {
        type: String,
        default: 'secondary'
      }
    },

    emits: ['update:modelValue', 'close', 'change'],

    data() {
      return {
        tabs: []
      }
    },

    computed: {
      hasActions() {
        return !!this.$slots.actions
      },
      active: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
          this.$emit('change', value)
        }
      }
    },

    methods: {
      prune() {
        throw new Error('NFilters: prune() is deprecated. Use remove() instead.')
      },
      selectTab(id) {
        this.active = id
      }
    }
  }
</script>

<template>
  <div>
    <div :class="headerClass" class="flex items-center justify-between flex-wrap">
      <ul class="filter-navigation !my-1">
        <li v-for="(tab, index) in tabs" :key="index">
            <a v-if="tab.disabled" href="#" class="flex items-center !bg-dust-50 !text-dust-400 !border-dust-300 cursor-not-allowed" @click.prevent >
                <NIcon v-if="tab.icon" :as="tab.icon" left /><span>{{ tab.label }}</span>
            </a>
            <router-link v-else-if="router" :to="tab.to" :class="`is-${tab.color || color}`" data-source="filters" active-class="is-active" class="flex items-center" >
                <NIcon v-if="tab.icon" :as="tab.icon" left /><span>{{ tab.label }}</span>
            </router-link>
            <a v-else href="#" :class="{ 'is-active': tab.isOpen && !tab.disabled, [`is-${tab.color || color}`]: true }" @click.prevent="selectTab(tab.id)" class="flex items-center" >
                <NIcon v-if="tab.icon" :as="tab.icon" left /><span>{{ tab.label }}</span>
            </a>
        </li>
      </ul>
      <div v-if="hasActions">
        <slot name="actions" />
      </div>
    </div>
    <div class="tabs__content">
      <slot />
    </div>
  </div>
</template>
