<script setup>
import getBy from 'lodash/get';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import NLoading from '@/components/NLoading.vue';
import NNotify from '@/components/NNotify.vue';
import { ref, computed } from 'vue';
import { clone } from '@/utils/Helpers';
import { on, bus } from '@/modules/bus';
import { api } from '@/modules/services';
import { store } from '@/store';
import { useRouteQuery } from '@vueuse/router';
import { breadcrumbs } from '@/utils/Categories';

const emit = defineEmits(['selected']);
const origin = ref('challenge-creation-flow');
const title = ref('Drafts');

const draftId = useRouteQuery('draft');
const loading = ref(false);
const show = ref(false);
const drafts = ref([]);
const hasDraft = ref(null);

const categories = computed(() => {
    return store.getters['app/challenge/categories'];
});

const groups = computed(() => 
    groupBy(drafts.value, 'origin')
)

const filteredDrafts = computed(() =>
    groups.value[origin.value] || []
);

const open = (opts = {}) => {
    show.value = true;
    origin.value = opts.origin;
    title.value = opts.title;
}

const close = () => {
    show.value = false;
}

const selectDraft = (draft) => {
    bus('challenge:creation-flow:draft-selected', { draft });
    emit('selected', { draft });

    close();
}

const loadDrafts = async () => {
    try {
        loading.value = true;
        const { data: response } = await api.drafts.find({
            draft_type: 'challenge',
            size: 200,
        });

        response.data.forEach(d => {
            d.removing = false;

            if (d._id === draftId.value) {
                selectDraft(d);

                draftId.value = null;
            }
        });

        drafts.value = response.data;
    } finally {
        loading.value = false;
    }
}

const removeDraft = async (target) => {
    try {
        target.removing = true;
        await api.drafts.remove(target._id);
        await loadDrafts();
    } finally {
        target.removing = false;
    }
}

const skipDraft = () => {
    bus('challenge:creation-flow:draft-skipped');
}

const resolveDraftByCategory = (category) => {
    const draft = drafts.value.find(draft =>
        getBy(draft, 'content.challenge.platform_category') === category.id
    );

    return draft ? clone(draft) : null;
}

const saveDraft = async (draft) => {
    // remove draft
    if (draft && draft._id && draft.content.challenge.slug) {
        if (draft._id) {
            await api.drafts.remove(draft._id);

            loadDrafts();
        }

        return;
    }

    if (draft && draft._id) {
        const { data: response } = await api.drafts.update(draft._id, draft);

        drafts.value.forEach(d => {
            if (d._id === draft._id && response.data) {
                d.content = clone(response.data?.content);
            }
        })

        return response.data;
    }

    const { data: response } = await api.drafts.create({
        ...draft,
        origin: 'challenge-creation-flow',
        draft_type: 'challenge',
    });

    drafts.value = [ ...drafts.value, response.data ];

    return response.data;
};

on('challenge:creation-flow:draft-updated', async ({ draft, onDone }) => {
    // TODO needs to be improved
    // const result = await saveDraft(draft);

    // onDone(result);
})

on('challenge:creation-flow:category-selected', debounce((category) => {
    if (category && !category.children.length) {
        const draft = resolveDraftByCategory(category);

        // TODO needs to be improved
        // if (draft) {
        //     nextTick(() => {
        //         hasDraft.value && hasDraft.value.open(draft);
        //     })
        // }
    }
}, 500))

loadDrafts();

defineExpose({
    open,
    close,
    selectDraft,
    removeDraft,
    skipDraft,
    resolveDraftByCategory,
    saveDraft,
})
</script>

<script>
export default {
    name: 'ChallengeDrafts',
}
</script>

<template>
    <slot :dirt="filteredDrafts.length" :open="open" :groups="groups" />

    <NModal v-model="show" @close="show = false">
        <div class="bg-white p-4 rounded-md flex flex-col m-4 w-full sm:w-[50rem]">
            <div class="flex items-center justify-between border-b pb-2">
                <div class="flex items-center text-dust-700 font-bold text-lg">
                    <NIcon as="bookmark-regular" class="text-lg mr-2" />
                    <span class="mr-1">{{ title }}</span>
                    <span v-if="filteredDrafts.length">({{ filteredDrafts.length }})</span>
                </div>
                <button aria-label="Close" type="button" class="text-dust-700 h-8 w-8 rounded-full flex items-center justify-center hover:bg-dust-100" @click="close">
                    <NIcon as="close" />
                </button>
            </div>
            <div class="py-6 sm:px-2 sb-tight sb-dust-400 overflow-y-auto h-[40vh]">
                <table class="w-full">
                    <tr v-for="(draft, i) of filteredDrafts" :key="i" class="hover:bg-dust-100 cursor-pointer flex items-center" >
                        <td class="py-1 px-2 flex items-center gap-3" @click="selectDraft(draft)">
                            <NIcon v-if="draft.origin == 'qq'" as="qq" class="text-innovator text-2xl" />
                            <NImage
                                v-if="draft.content"
                                :src="draft.content.challenge.primary_image"
                                :title="draft.content.challenge.question_title"
                                class="bg-dust-100 border-b w-20 h-12 rounded-sm aspect-image"
                            />
                            <span v-else>---</span>
                        </td>
                        <td class="py-2 px-3 sm:px-4 flex flex-col flex-1" @click="!draft.content.challenge.slug && selectDraft(draft)">
                            <template v-if="draft.content">
                                <div class="font-semibold text-dust-800 flex-1">
                                    {{ draft.content.challenge.question_title || '(No title)' }}
                                </div>
                                <div class="flex">

                                    <span v-for="(category, i) of breadcrumbs({ children: categories }, draft.content.challenge.platform_category).map(c => c.name)" :key="i" class="flex leading-none items-center text-sm text-dust-600 rounded-lg">
                                        <svg v-if="i" xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 mx-1" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                        </svg>
                                        {{ category }}
                                    </span>
                                </div>
                            </template>
                            <span v-else>---</span>
                        </td>
                        <td class="px-2 flex gap-3 items-center">
                            <NIcon v-if="draft.content.challenge.slug" v-tooltip="'Challenge sent'" as="check-circle-solid" class="text-green-500" />
                            <NLoading v-if="draft.removing" active />
                            <button aria-label="Remove" v-else type="button" class="text-dust-700 h-8 w-8 rounded-full flex items-center justify-center bg-dust-50 hover:bg-dust-200" @click="removeDraft(draft)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                </table>

                <div v-if="!filteredDrafts.length" class="text-center text-dust-500 h-full flex items-center justify-center">
                    No items saved yet.
                </div>
            </div>
        </div>
    </NModal>

    <NNotify
        ref="hasDraft"
        title="Recent Drafts"
        message="There is a draft created with this category, do you want to apply the changes to the flow?"
        @confirm="selectDraft"
        @cancel="skipDraft"
    />
</template>