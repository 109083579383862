import { surveyModeRedirection } from '@/utils/Route';

export default (to, _, next) => {
    if (to.name === 'challenges.survey') {
        surveyModeRedirection(to);

        return;
    }
    
    next();
}
