<script>
import { createPopper } from '@popperjs/core';
import { ref, onMounted, inject } from 'vue';

import merge from 'lodash/merge';

const useElement = (element) => {
    if (typeof element === 'string') {
        return document.querySelector(element);
    } else if (typeof element === 'function') {
        return element();
    }
    
    return element;
};

const defaultOptions = {
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 8]
            }
        }
    ],
    scrollToStep: {
        enabled: true,
        options: {
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        },
    },
};

export default {
    name: 'Step',

    props: {
        skippable: {
            type: Boolean,
            default: false,
        },
        textDone: {
            type: String,
            default: 'Done',
        },
    },

    setup () {
        const show = ref(false);
        const stepElement = ref(null);
        const api = inject('api')
        
        const attachElement = () => {
            const element = useElement(api.step.value.attachTo.element);
            const options = merge({}, defaultOptions, api.step.value && api.step.value.options || {});

            if (element && stepElement.value) {
                show.value = true;

                createPopper(element, stepElement.value, {
                    placement: api.step.value.attachTo.placement || 'bottom',
                    modifiers: options.modifiers,
                });

                if (options.scrollToStep.enabled) {
                    element.scrollIntoView(options.scrollToStep.options);
                }
            } else {
                api.toNextStep()
            }
        }

        const beforeStepStart = () => {
            attachElement();
        }

        const beforeStepEnd = async () => {
            await (api.step.value && api.step.value.afterStep && api.step.value.afterStep());
        }

        const onNext = async () => {
            show.value = false;
            await beforeStepEnd();
            api.toNextStep();
            attachElement();
        }

        const onPrevious = () => {
            beforeStepEnd();
            api.prevStep();
        }

        onMounted(() => {
            beforeStepStart();
        });

        return {
            show,
            stepElement,
            done: api.done,
            isLast: api.isLast,
            step: api.step,
            steps: api.steps,
            exit: api.exit,
            index: api.index,
            onNext,
            onPrevious,
        }
    }
}
</script>

<template>
    <div v-show="show">
        <div v-if="step" ref="stepElement" :x-placement="step.attachTo.placement || 'bottom'" class="onboarding-flow relative bg-white rounded-lg shadow-active border max-w-xl p-2 z-[900]">
            <div class="bg-nerve text-white px-3 pt-3 pb-1 rounded-md max-w-60">
                <slot v-bind="{ onNext }">
                    <h3>{{ step.content.title }}</h3>
                    <p class="mb-2">
                        {{ step.content.description }}
                    </p>
                    <div class="flex items-center justify-between gap-2 mb-2">
                        <div class="flex items-center justify-center gap-1 -mb-1">
                            <span 
                                v-for="({ attachTo }, i) of steps.length > 1 ? steps : []" :key="i" :class="attachTo.element === step.attachTo.element ? 'bg-nerve-300' : 'bg-nerve-400'" 
                                class="w-2 h-2 rounded-full  inline-flex items-center justify-center"
                            />
                        </div>
                        <div class="flex items-center gap-1">
                            <button aria-label="button" v-if="skippable && !isLast" class="px-3 py-1 inline-flex items-baseline border border-transparent rounded-md hover:bg-nerve-600" @click="onNext">
                                Skip
                            </button>
                            <button aria-label="button" v-if="!isLast" class="px-3 py-1 inline-flex items-baseline border border-transparent rounded-md hover:bg-nerve-600" @click="onNext">
                                Next
                            </button>
                            <button aria-label="button" v-if="isLast" class="px-3 py-1 inline-flex items-baseline border border-transparent rounded-md hover:bg-nerve-600" @click="exit">
                                {{ textDone }}
                            </button>
                        </div>
                    </div>
                </slot>
            </div>

            <div class="step-arrow" />
        </div>
    </div>
</template>

<style lang="scss">
.onboarding-flow .step-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    z-index: 1;

}

.onboarding-flow[x-placement^=bottom] .step-arrow {
    border-width: 0px 14px 10px 14px;
    top: -10px;
    left: calc(50% - 14px);
    margin-top: 0;
    margin-bottom: 0;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;

    &::before {
        content: "";
        @apply border-white;
        border-width: 15px 20px 15px 20px;
        height: 0;
        width: 0;
        border-style: solid;
        position: absolute;
        left: calc(50% - 20px);
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -14px;
    }

    &::after {
        content: "";
        @apply border-nerve;
        border-width: 19px 23px 16px 23px;
        height: 0;
        border-style: solid;
        position: absolute;
        width: 0;
        left: calc(50% - 23px);
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -10px;
    }
}

.onboarding-flow[x-placement^=top] .step-arrow {
    border-width: 10px 14px 10px 14px;
    bottom: -21px;
    left: calc(50% - 14px);
    margin-top: 0;
    margin-bottom: 0;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-top-color: #77777740 !important;

    &::before {
        content: "";
        @apply border-white;
        border-width: 15px 20px 15px 20px;
        height: 0;
        width: 0;
        border-style: solid;
        position: absolute;
        left: calc(50% - 20px);
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -14px;
    }

    &::after {
        content: "";
        @apply border-nerve;
        border-width: 19px 23px 16px 23px;
        height: 0;
        border-style: solid;
        position: absolute;
        width: 0;
        left: calc(50% - 23px);
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -10px;
    }
}

.onboarding-flow[x-placement^=left] .step-arrow {
    border-width: 14px 20px 14px 20px;
    right: -38px;
    margin-top: 0;
    margin-bottom: 0;
    top: calc(50% - 15px);
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;

    &::before {
        content: "";
        @apply border-white;
        border-width: 15px 20px 15px 20px;
        height: 0;
        width: 0;
        border-style: solid;
        position: absolute;
        top: calc(50% - 15px);
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -19px;
    }

    &::after {
        content: "";
        @apply border-nerve;
        border-width: 19px 23px 16px 23px;
        height: 0;
        border-style: solid;
        position: absolute;
        width: 0;
        top: calc(50% - 19px);
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -10px;
    }
}
</style>