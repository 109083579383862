<script setup>
import Button from '@/components/ui/button/index.vue';
import FeatureFlag from '@/components/FeatureFlag.vue';
import { api } from '@/modules/services';
import { ref } from 'vue';

const props = defineProps({
    tool: {
        type: String,
        required: true,
    },
    params: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['success']);
const loading = ref(false);

const runTask = async () => {
    loading.value = true;

    try {
        const { data: response } = await api.challenges.assistant(props.tool, props.params);

        emit('success', response.data);
    } catch (error) {
        console.error(error);
    } finally {
        loading.value = false;
    }
};
</script>

<template>
    <FeatureFlag flag="developer-challenge-assistant">
        <Button type="button" variant="icon" size="sm" :class="{ 'animate-pulse': loading }" @click="runTask">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" /></svg>
        </Button>
    </FeatureFlag>
</template>