<script setup>
import Button from '@/components/ui/button/index.vue';
import NIcon from '@/components/NIcon.vue';

import { api } from '@/modules/services';
import { cn } from '@/utils/Helpers';
import { computed, ref, h } from 'vue';
import { useAuth, useLinks as useAuthLinks } from '@/composable/auth';
import { useI18n } from 'vue-i18n';
import { get } from 'lodash';

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    icon: {
        type: String,
    },
    color: {
        type: String,
        default: 'primary',
    },
    discuss: {
        type: Object,
        required: true,
    },
    variant: {
        type: String,
        default: 'outline',
    },
});
const emit = defineEmits(['voted']);
const { signin } = useAuthLinks();
const { auth } = useAuth();
const { t } = useI18n();

const currentVote = props.discuss.user_votes?.find((v) => v.vote_type === props.type)

const vote = ref(currentVote?.voted);
const total = ref(get(props.discuss, `stats.total_${props.type}`, 0));

const voting = ref(false);
const isOwner = computed(() => auth.user && auth.user.id === props.discuss.author_id);
const hasVote = computed(() => vote.value);
const canVote = computed(() => !isOwner.value);

// const expired = computed(() => moment().isAfter(moment.unix(votes.voted_at).add(5, 'minutes')));
// const canInteract = computed(() => isOwner.value && !expired.value);
// const canVote = computed(() => !isOwner.value && (!hasVote.value || (hasVote.value && available.value)));

const handleVote = async () => {
	if (!auth.authenticated) {
		signin();
		return false;
	}

    if (voting.value || !canVote.value) return;

	const voted = vote.value;

	try {
        voting.value = true;
		
		// Update UI state immediately
		vote.value = !voted;
		if (voted) {
			total.value = Math.max(0, total.value - 1);
		} else {
			total.value += 1;
		}

		const { data } = await api.discussions.vote({
			object_id: props.discuss.id,
            object_type: 'discussion',
            vote: props.type,
			op: voted ? 'unvote' : 'vote',
		});

        emit('voted', data);
	} catch (e) {
		console.log(e);

		// Revert UI state on error
		vote.value = voted;
		if (voted) {
			total.value += 1;
		} else {
			total.value = Math.max(0, total.value - 1);
		}
	} finally {
        voting.value = false;
    }
};

const Unavailable = (props, { slots }) => h('span', { class: 'flex items-center gap-1 text-dust-700 px-2 py-1' }, slots);
</script>

<template>
    <component :is="canVote ? Button : Unavailable" type="button" :variant="variant" :disabled="voting" :class="cn('gap-1 px-2 sm:px-3', voting && 'animate-loading')" @click="handleVote">
        <NIcon :as="icon || type" :class="{ [`text-${color}-500`]: hasVote }" />
        <span class="leading-none" :class="{ 'hidden sm:inline': !!icon }">{{ t(`component.discussions.message.vote.${type}`) }}</span>
        <span v-if="total > 0" class="text-sm">({{ total }})</span>
    </component>
</template>