<script>
import Closable from '@/mixins/Closable';
import SharingPanel from './Panel.vue';
import { isEmpty } from 'lodash';

export default {
    mixins: [Closable],
    components: { SharingPanel },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        active: {
            type: Boolean,
            default: false
        },
        hideOverlay: {
            type: Boolean,
            default: false
        },
        query: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            isOpen: this.active,
        };
    },
    computed: {
        target () {
            return !isEmpty(this.payload) ? this.payload : this.item;
        },
        panel () {
            return this.$refs.panel;
        },
    },
    watch: {
        '$route' () {
            this.isOpen = false;
        },
    }

}
</script>

<template>
    <div v-if="isOpen" class="modal is-open" @click.self="close">
        <div :class="{ 'modal__background': !hideOverlay }" @click="close"></div>
        <div class="modal__content">
            <SharingPanel ref="panel" :item="target" :query="query" @done="close">
                <template #actions>
                    <button aria-label="button" type="button" class="sharing-popup-panel__close-btn" @click.prevent="close">
                        <span aria-hidden="true" class="icon icon-times-circle-regular"></span>
                    </button>
                </template>
            </SharingPanel>
        </div>
    </div>
</template>
