<script>
import { date } from '@bignerve/ui-utils'

export default {
    name: 'NotificationHelpfulTip',

    props: {
        detail: {
            type: Object,
        },
        simple: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        computedClass () {
            const role = this.detail.targetGameDash.split(',')[0];

            return `is-${role}`;
        }
    },

    methods: {
        date,
    },
}
</script>

<template>
    <div class="game-dash-list-item cursor-pointer text-left text-dust-700" :class="computedClass">
        <NIcon :as="detail.icon" class="game-dash-list-item__image my-0 mr-1 text-2xl" />
        <div class="game-dash-list-item__content">
            <span v-if="detail.title" class="game-dash-list-item__text font-semibold mr-1 text-dust-800">
                {{ detail.title }}
            </span>
            <component
                v-if="detail.actionTitle"
                v-html="detail.actionTitle"
                :is="detail.route ? 'router-link' : 'button'"
                :to="detail.route"
                class="game-dash-list-item__text text-left hover:text-nerve text-dust no-underline"
                @click="detail.action(item)"
            />
            <div v-if="!simple" class="game-dash-list-item__meta my-0">{{ date(detail.issuedAt, 'human') }}</div>
        </div>
    </div>
</template>