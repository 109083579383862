<script>
import LoadMore from '@/layouts/partials/LoadMore.vue';
import orderBy from 'lodash/orderBy';
import { sequence } from '@/utils/Helpers';
import { numeric } from '@bignerve/ui-utils';

export default {
    name: 'MentoringToRate',

    components: { LoadMore },

    props: {
        type: {
            type: String,
            default: 'mentoring_rate_flow'
        },
        idea: {
            type: Object,
        },
        challenge: {
            type: Object,
        },
        autoScroll: {
            type: Boolean,
            default: false
        },
    },

    emits: ['done', 'processing'],

    data () {
        return {
            mentoring: [],
            loading: false,
            processingRatings: false,
            query: {
                page: 0,
                size: 25,
            },
            total: 0,
        };
    },

    computed: {
        hasMore() {
            return this.total > this.mentoring.length;
        },
        totalPlaceholder () {
            return this.idea.stats.mentoring_to_rate || 1;
        },
        canSave () {
            return this.mentoring.some(comment => comment.rating);
        }
    },

    watch: {
        processingRatings (value) {
            this.$emit('processing', value)
        },
        type () {
            this.loadMentoring();
        }
    },

    methods: {
        numeric,
        loadMentoring () {
            this.total = 0;
            this.query.page = 0;
            this.mentoring = [];

            this.fetch();
        },

        async fetch () {
            try {
                this.loading = true;
                const { data: mentoring } = await this.$api.challenges.mentoringToRate({
                    challengeSlug: this.challenge.slug,
                    ideaId: this.idea.id,
                }, { ...this.query, sort: this.type });

                // adds props to react
                mentoring.data.forEach(mentoring => {
                    mentoring.rating = null,
                    mentoring.processing = false;
                    mentoring.rated_at = null;
                    mentoring.error = null;
                });

                this.mentoring = orderBy(
                    [...this.mentoring, ...mentoring.data],
                    ['stats.user_mentoring'], ['asc']
                );
                this.total = mentoring.meta.total;
            } finally {
                this.loading = false;
            }
        },

        nextPage () {
            this.query.page++;
            this.fetch();
        },

        async save () {
            this.processingRatings = true;
            const mentoring = this.mentoring.filter(
                comment => comment.rating && !comment.rated_at
            );

            // new state
            mentoring.forEach(comment => {
                comment.processing = true;
                comment.error = null;
            })

            await sequence(mentoring, async comment => {
                try {
                    await this.$api.game.rateMentoring(comment.id, {
                        rating: comment.rating,
                    });
                    comment.rated_at = new Date();
                } catch (err) {
                    comment.error = true;

                    throw err
                } finally {
                    comment.processing = false;
                }
            });

            this.processingRatings = false;

            this.$bus('challenge:mentoring:rated', { mentoring })
            this.$emit('done', { mentoring });
        },
        goToNext(comment) {
            if (!this.autoScroll) {
                return;
            }

            const index = this.mentoring.indexOf(comment);
            const nextEl = index !== -1 && index + 1 < this.mentoring.length
                ? this.$refs.comment[index + 1] : null;

            if (nextEl) {
                setTimeout(() => {
                    nextEl.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
                }, 700);
            }
        },
        rating(n) {
            return Number(numeric(n, 2)).toString();
        }
    },

    mounted() {
        this.loadMentoring();
    }
}
</script>

<template>
    <div>
        <slot name="header" :mentoring="mentoring" :processing="processingRatings" :save="save" :canSave="canSave" :loading="loading" />
        <div v-for="comment of mentoring" :key="comment.id" ref="comment" :class="comment.stats.user_mentoring ? 'border-mentor' : 'border-white'" class="mb-4 rounded-lg flex flex-col overflow-hidden border-4">
            <div class="px-4 py-1 font-bold text-white bg-mentor flex items-center">
                <div v-if="comment.stats.user_mentoring" class="py-1 flex items-center">
                    <NIcon as="mentor" class="mr-2 text-lg" />
                    Your Mentoring
                </div>
            </div>
            <div class="flex flex-col sm:flex-row bg-mentor-100 p-4 rounded-bl-lg rounded-br-lg gap-4">
                <div class="flex-grow flex flex-col gap-4 flex-2">
                    <div v-if="!!comment.what_liked">
                        <h3>{{ $t('component.idea.mentoring-to-rate.what-liked') }}</h3>
                        <NMarkdown :code="comment.what_liked" />
                    </div>
                    <div v-if="!!comment.what_wish">
                        <h3>{{ $t('component.idea.mentoring-to-rate.what-wish') }}</h3>
                        <NMarkdown :code="comment.what_wish" />
                    </div>
                </div>
                <div
                    v-if="!comment.stats.user_mentoring && !!comment.what_wish && !comment.stats.user_rating"
                    class="flex flex-col items-start justify-center space-y-2 sm:ml-8"
                    @click="() => comment.error = null"
                >
                    <span class="font-bold text-mentor whitespace-nowrap">
                        {{ $t('component.idea.mentoring-to-rate.feedback-helpful') }}
                    </span>
                    <NRating
                        v-model="comment.rating"
                        :disabled="comment.processing || !!comment.rated_at"
                        :increment="0.1"
                        :max="5"
                        :class="{ 'animate-pulse': comment.processing }"
                        inactive-color="#EDF4FC"
                        class="max-h-6 w-40"
                        color="mentor"
                        tooltip
                        @change="goToNext(comment)"
                    />
                    <span class="italic text-mentor font-semibold">
                        {{ $t(`component.rating.scale.${comment.rating ? Math.ceil(comment.rating) : 'rate' }`) }}
                    </span>
                    <small class="text-dust-600">
                        <span v-html="$t('component.idea.mentoring-to-rate.save-above')" />
                    </small>
                    <div class="h-8 block w-full">
                        <NAlert :open="comment.error" type="danger" dense>
                            {{ $t('component.idea.mentoring-to-rate.notify.error') }}
                        </NAlert>
                        <NAlert :open="!!comment.rated_at" type="success" dense>
                            {{ $t('component.idea.mentoring-to-rate.notify.success') }}
                        </NAlert>
                    </div>
                </div>
                <div v-else-if="!!comment.what_wish" class="p-4 shadow rounded-md bg-white italic text-base ml-4 min-w-72">
                    <h4 class="border-b border-mentor-400 text-mentor-400 pb-1 mb-2 whitespace-nowrap flex items-center">
                        <NIcon as="peer-mentor" class="mr-1 text-lg" />
                        Rated by other Mentors:
                    </h4>
                    <p class="font-semibold text-mentor whitespace-nowrap mb-2">
                        {{ $t(`component.rating.scale.${comment.stats.community_average ? Math.ceil(comment.stats.community_average) : 'no-peer-mentor-ratings' }`) }}
                    </p>
                    <p v-if="comment.stats.community_average" class="font-semibold text-mentor whitespace-nowrap mb-2">
                        Score: {{ rating(comment.stats.community_average) }} / {{ comment.stats.community_raters }} {{ $t('component.idea.mentoring-to-rate.ratings', comment.stats.community_raters) }}
                    </p>
                    <p v-if="comment.stats.user_rating" class="font-semibold text-dust-800 whitespace-nowrap m-0">
                        You rated: {{ rating(comment.stats.user_rating) }}
                    </p>
                </div>
            </div>
        </div>
        <template v-if="loading">
            <div v-for="i of totalPlaceholder" :key="`placeholder-${i}`" class="animate-pulse">
                <div class="flex-1">
                    <div class="h-60 mb-4">
                        <slot name="placeholder" />
                    </div>
                </div>
            </div>
        </template>
        <slot v-else-if="!mentoring.length" name="empty" :loadMentoring="loadMentoring">
            <div class="py-20 text-dust text-center">
                <span>{{ $t('component.idea.mentoring-to-rate.notify.empty') }}</span>
            </div>
        </slot>
        <div v-if="hasMore" class="flex justify-center mt-3">
            <button aria-label="button" @click.prevent="nextPage()" :disabled="loading" type="button"
                class="bg-dust text-white px-3 py-1 leading-1 rounded inline-flex items-center font-bold hover:bg-dust-600">
                {{ loading ? 'Loading...' : 'Load more' }}
                <NIcon as="caret-down-simple" class="ml-2" />
            </button>
        </div>
    </div>
</template>
