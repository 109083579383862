<script setup>
import FeatureFlag from "@/components/FeatureFlag.vue"
import { usePlatform, useUserPlatform } from '@/composable/platform'
import { useAuth } from '@/composable/auth'
import { computed } from 'vue'
import { createReusableTemplate } from "@vueuse/core"
import { cn } from '@/utils/Helpers'
import { useSlots } from 'vue';

const props = defineProps({
	class: {
		type: String,
		default: '',
	},
})

const { auth } = useAuth()
const { isUserPlatform, isViewingSelf, currentPlatform, isSubplatform } = usePlatform()
const { account } = useUserPlatform()
const slots = useSlots();

const hasSlot = computed(() => Object.keys(slots).length > 0)

const homeLink = computed(() => {
	if (isUserPlatform.value && account.value) {
		return {
			name: 'home.user',
			params: {
				user: account.value.user.screen_name,
			},
		}
	}

	return { name: 'home.challenges.widgets' }
})

const nerveBoardLink = computed(() => {
	if (currentPlatform.value?.subdomain === 'rpg') {
		return {
			name: 'nerve-boards',
			query: {
				role: 'overall',
				category: '1d045b47-d1de-4a7b-95c5-2c0bf334e1c2',
			}
		}
	}

	return { name: 'nerve-boards', query: { role: 'overall' } }
})

const [DefineMenuItem, MenuItem] = createReusableTemplate()

const leftCorner = 'border-x border-t'
</script>

<style lang="scss">
// https://play.tailwindcss.com/tTpf9dIABk?file=css
.header-menu {
	.with-slot {
		box-shadow: 0px -1px 1px 0 rgba(0, 0, 0, 0.1), 0px -3px 2px 0 rgba(0, 0, 0, 0.06);
	}

	.with-slot .internal-radius {
		&::after, &::before {
			width: 0.5rem;
			height: 0.5rem;
			position: absolute;
			content: "";
		}

		&:after {
			bottom: 0;
			right: -0.5rem;
			background-image:
				radial-gradient(circle at right top, transparent 0.5rem, white 0),
				radial-gradient(circle at right top, transparent 0.45rem, #e5e7eb 0);
		}
	}

	.with-slot:not(:first-child) .internal-radius {
		&::before {
			bottom: 0;
			left: -0.5rem;
			background-image:
				radial-gradient(circle at left top, transparent 0.5rem, white 0),
				radial-gradient(circle at left top, transparent 0.45rem, #e5e7eb 0);
		}
	}
}
</style>

<template>
	<DefineMenuItem #="{ label, route, icon, class: className, exact = true }">
		<RouterLink :to="route" :class="cn('relative inline-flex items-center gap-1 text-center text-dust-800 text-nowrap !-mb-[1.5px]', hasSlot && 'pb-2 pt-3 px-4', className)"
			v-bind="{ [exact ? 'exactActiveClass' : 'activeClass']: cn('rounded-t-lg', hasSlot ? ['with-slot bg-white ', leftCorner] : 'font-bold !text-nerve-500') }"
			>
			<NIcon :as="icon" class="text-lg -mb-1 -mt-1.5" />{{ label }}

			<div class="internal-radius"></div>
		</RouterLink>
	</DefineMenuItem>
	<div :class="cn('relative w-full', props.class)">
		<div :class="cn('header-menu w-full flex justify-start sm:space-y-0 sm:w-auto z-20 gap-2 max-w-[80dvw] sb-tight sb-dust-200 overflow-x-scroll sm:overflow-visible', hasSlot ? 'overflow-x-auto overflow-y-hidden' : 'gap-4 flex-wrap')">
			<MenuItem label="Challenges" :route="homeLink" icon="stack" :exact="false" />
			<!-- <MenuItem v-if="auth.authenticated && !isUserPlatform && !isSubplatform" label="Nerve Board" :route="nerveBoardLink" icon="nerve-board" /> -->
			<MenuItem v-if="auth.authenticated && isUserPlatform && $route.params.user"
				icon="medal"
				:label="$t('page.home.medals.heading-title')"
				:route="{
					name: 'home.user.medals',
					params: { user: $route.params.user },
				}"
			/>
			<MenuItem v-if="auth.authenticated && isViewingSelf" icon="brain" :label="'My Dashboard'" :route="{
				name: 'dashboard.dashboard',
				params: { user: auth.user.screen_name },
			}" />

			<FeatureFlag flag="discussion-forums">
				<MenuItem label="Discussions" :route="{
					name: 'discussion-forums',
					params: { user: $route.params.user || 'platform' },
				}" icon="discussion-icon" :exact="false"  />
			</FeatureFlag>
			
			<MenuItem v-if="!isUserPlatform" icon="stream" :label="'Tournaments'" :route="{
				name: 'home.olympiads',
				params: { user: $route.params.user || 'platform' },
				query: { filter: 'trending' },
				hash: '#heading',
			}" />

			<!-- <span
						v-if="isUserPlatform"
						:to="{ name: 'home.teams', params: { user: $route.params.user } }"
						exactActiveClass="font-weight-bold !text-nerve-500"
						class="px-2 !hover:bg-dust-300 py-2 rounded-lg text-center is-disabled"
					>
						<NIcon as="friends" /> Teams
					</span> -->
			<!-- <span
						v-if="isUserPlatform"
						:to="{ name: 'home.user.blog' }"
						exactActiveClass="font-weight-bold !text-nerve-500"
						class="discover-btn__link px-2 !hover:bg-dust-300 py-2 rounded-lg text-center disabled"
					>
						<NIcon as="pencil-alt-solid" /> Blog
					</span> -->
		</div>
		<div v-if="hasSlot" class="bg-white p-4 rounded-x-lg rounded-b-lg border-b border-t border-x shadow flex flex-col sm:flex-row gap-4 sm:items-center sm:justify-between flex-wrap">
			<slot />
		</div>
	</div>
</template>
