<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Errors from './Errors';
import { flatten } from '@/utils/Helpers';
import { sequence } from '@bignerve/ui-utils';

export default {
    name: 'NDataForm',
    
    props: {
        action: {
            type: [String, Function],
        },
        method: {
            type: String,
            default: 'POST'
        },
        data: {
            type: Object,
            default: () => {}
        },
        confirm: {
            type: Boolean,
            default: false
        },
        once: {
            type: Boolean,
            default: false
        },
        dirtable: {
            type: Array
        },
        prune: {
            type: Function,
            default: v => v
        },
        beforeSubmit: Function,
    },

    emits: ['change', 'before-send', 'after-send', 'submit', 'reset', 'error', 'success', 'modal-closed'],

    watch: {
        data: {
            handler(n, o) {
                this.fields = n;
            },
            deep: true
        },
        fields: {
            handler(n, o) {
                this.$emit('change', n);
            },
            deep: true
        }
    },

    provide() {
        return {
            form: this.form,
        };
    },

    data() {
        return {
            busy: false,
            success: false,
            errors: new Errors,
            fields: this.data || {},
            formFields: [],
            middlewares: new Map(),
        };
    },

    computed: {
        form() {
            return this;
        },
        dirty() {
            if (this.dirtable) {
                return this.dirtable.some(field => !isEmpty(
                    get(this.fields, field, null)
                ));
            }

            return Object.keys(flatten(this.fields)).length;
        }
    },

    methods: {
        submit() {
            if (this.once && (this.busy || this.success)) {
                return;
            }

            this.busy = false;
            this.success = false;
            this.errors.clear();

            if (this.confirm) {
                this.$refs.dialog.open();
                return
            }

            this.send();
        },
        async send () {
            if (this.confirm) {
                this.closeDialog()
            }

            if (!this.valid()) {
                return;
            }

            if (!this.action) {
                console.warn('No action provided');
                return;
            }

            this.busy = true;
            this.errors.clear();

            // triger middlewares
            await sequence(
                [...this.middlewares.values()],
                middleware => middleware(this.fields)
            );

            const candSend = this.beforeSubmit
                ? await this.beforeSubmit(this.fields) : true;
            if (!candSend) {
                return;
            }

            try {
                this.$emit('before-send', this.fields);

                const fields = this.prune(this.fields);

                const payload = await (typeof this.action === 'function'
                    ? this.action(fields)
                    : this.$nerve.requester.makeRequest(this.method, this.action, fields).then(r => r.data)
                );

                this.$emit('after-send', payload);

                this.onSuccess(payload);
            } catch (error) {
                this.onError(error);

                throw error;
            } finally {
                this.$emit('submit');
            }
        },
        reset() {
            this.busy = false;
            this.success = false;
            this.fields = this.data;
            this.errors.clear();

            this.formFields.forEach(field => {
                field.reset();
            });
            
            this.$emit('reset');
        },
        onSuccess(data) {
            this.busy = false;
            this.success = true;
            this.errors.clear();
            this.$emit('success', data, this.fields);
        },
        onError(error) {
            let errors = get(error, 'response.data.errors', []);

            this.busy = false;
            this.success = false;
            this.errors.record(errors);
            this.$emit('error', errors);
        },
        closeDialog() {
            this.$refs.dialog.close();
        },
        valid() {
            const errors = this.formFields.filter(field => field.required && !field.modelValue)

            this.errors.record(errors.map(field => ({
                field: field.name,
                error: this.$t(
                    'component.form.error.required',
                    field.label || field.fieldName ? 0 : 1, { field: field.label || field.fieldName }
                )
            })));

            return !errors.length;
        },
    },
}
</script>

<template>
    <form @submit.prevent="submit" @keypress.enter.prevent>
        <NNotify
            v-if="confirm"
            ref="dialog"
            title="Confirm?"
            message="Do you want to continue with this action?"
            @cancel="$emit('modal-closed')"
            @confirm="send"
        />
        <slot
            :fields="fields"
            :errors="errors"
            :dirty="dirty"
            :busy="busy"
            :success="success"
            :submit="submit"
            :reset="reset"
        />
    </form>
</template>
