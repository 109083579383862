<script setup>
import { useNotifier } from '@/composable/notifier';
import NNotify from '@/components/NNotify.vue';

const { title, message, opened, close, onConfirm, payload, textConfirm, textCancel, hideCancel, hideConfirm, hideOverlay } = useNotifier();
</script>

<template>
    <NNotify
        v-model="opened"
        :title="title"
        :text-cancel="textCancel"
        :text-confim="textConfirm"
        :hide-cancel="hideCancel"
        :hide-confirm="hideConfirm"
        :hide-overlay="hideOverlay"
        @confirm="() => onConfirm({ payload })" @close="close">
        <template #content>
            <div class="w-80">{{ message }}</div>
        </template>
    </NNotify>
</template>
