<script setup>
import { useI18n } from 'vue-i18n';

const props = defineProps({
    challenge: {
        type: Object,
    },
    payment_id: {
        type: String,
    },
});

const { t } = useI18n();
</script>

<template>
    <div class="relative">
        <div class="mb-10 border-2 border-secondary-400 rounded-lg shadow">
            <div class="px-4 py-2 bg-secondary-100 text-secondary rounded-t-lg font-bold flex items-center gap-2">
                <NIcon as="funding" /> <span>{{ t('component.challenge.idea-entry-fee.success-message.title', { total: challenge.user_stats.total_ideas }, challenge.user_stats.total_ideas) }}</span>
            </div>
            <div class="p-4">
                <p class="italic mb-1">
                    <NIcon as="envelope-regular" /> You will receive an email receipt soon.
                </p>

                <p v-if="payment_id">
                    Your payment confirmation number is: <NCopy :source="payment_id" class="inline font-bold">{{ payment_id }}</NCopy>
                </p>

                <p>
                    Next, we encourage you to rate and mentor a few of the other ideas to help {{ [challenge.catalyst.first_name, challenge.catalyst.last_name].join(' ') }} identify the best ideas for his question, and to earn more points. The more you participate well, the more points you earn. The Top 33% or Top 30 (whichever is greater) earn a share of the Prize Money. 
                </p>
            </div>
        </div>

        <div class="arrows-bottom-[20px] arrows-width-[1px] arrows-border-secondary-400 absolute arrows-bg-white !left-1/2 transform !-translate-x-5 !-translate-y-[1px]"/>
    </div>
</template>