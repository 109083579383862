<script setup>
import { ref, computed, watchEffect } from 'vue';
import { isMobile } from '@/composable/responsive';
import { usePlatform, useUserPlatform } from '@/composable/platform';
import { useRouteQuery } from '@vueuse/router';
import { useFeatureFlag } from '@/composable/feature-flag'

const DEFAULT_LENGTH = 4;

const props = defineProps({
    id: {
        type: String,
        default: 'filter-navigation',
    },
});

const filter = useRouteQuery('filter', 'all');
const { account } = useUserPlatform();
const { isUserPlatform } = usePlatform();
const { expect } = useFeatureFlag('suggestion-box')
const suggestionBoxEnabled = expect(() => account.value.user.id, 'TMTTRT51CX');

const filters = computed(() => ([
  { key: 'all', title: 'Default', show: true },
  { key: 'trending', title: 'Trending', show: true },
  { key: 'recents', title: 'Most Recent', show: true },
  { key: 'closing_24h', title: 'Closing < 24H', show: true },
  { key: 'prize_money', title: 'Most $ Money', show: true },
  { key: 'idea_entry_fee', title: 'With Idea Entry Fee', show: true },
  { key: 'suggestion_box', title: 'Suggestion Box', show: suggestionBoxEnabled.value },
  { key: 'closed', title: 'Closed', subtitle: '(see ideas)', show: true },
  { key: 'blue_sky', title: 'Blue Sky', show: false },
  { key: 'moonshot', title: 'Moonshot', show: false },
  { key: 'my_activity', title: 'My Activity', show: isUserPlatform.value },
  { key: 'my_saved', title: 'My Saved', show: isUserPlatform.value },
  { key: 'friends', title: 'My Friends', show: isUserPlatform.value },
  { key: 'recruits', title: 'My Recruits', show: isUserPlatform.value },
  { key: 'trending-weighted', title: 'Featured', show: false },
]))

const mobile = isMobile();
const length = ref(DEFAULT_LENGTH);

const availableFilters = computed(() => {
    return filters.value
        .filter(({ show }) => {
            return typeof show === 'function'
                ? show.apply(this)
                : show
        })
});

const position = computed(() => {
    const target = availableFilters.value.find(f => f.key === filter.value)
    const index = availableFilters.value.indexOf(target)

    return index + 1
});

const currentFilters = computed(() => {
    return availableFilters.value.slice(
        0, position.value > length.value
        ? availableFilters.value.length
        : length.value
    )
});

const active = computed(() => {
    return availableFilters.value.find(
        f => f.key === filter.value
    ) || {}
});

const select = (selected) => {
    if (selected.key === 'other') {
        showAll();

        return;
    }

    filter.value = selected.key
};

const showAll = () => {
    length.value = availableFilters.value.length
};

watchEffect(() => {
    length.value = mobile.value ? 3 : DEFAULT_LENGTH
});
</script>

<template>
    <ul :id="id" class="filter-navigation">
        <li v-for="(filter, key) in currentFilters" :key="key"
            :class="{ 'is-active': active.key === filter.key }">
            <a href="" @click.prevent="select(filter)" class="!py-1.3 px-6 sm:py-1 sm:!px-5 hover:!bg-nerve" :class="{ '!bg-nerve': active.key === filter.key }">
                {{ filter.title }} <i v-if="filter.subtitle" class="text-xs">{{ filter.subtitle }}</i>
            </a>
        </li>
        <li v-if="currentFilters.length !== availableFilters.length">
            <a href="" @click.prevent="showAll" class="!py-1.3 px-6 sm:py-1 sm:!px-5 hover:!bg-nerve">
                Other...
            </a>
        </li>
    </ul>
</template>
