<script setup>
import SendIdeaNote from '@/components/challenge-show/SendIdeaNote.vue';
import DirectMessageDialog from '@/components/challenge-show/DirectMessageDialog.vue';
import RatingIdeaDialog from '@/components/idea/RatingIdeaDialog.vue';
import Resolver from '@/components/Resolver.vue';
import NNotify from '@/components/NNotify.vue';
import { PAYMENT_STATUS } from '@/constant/idea';
import { CONTEXT } from '@/constant/discussion';
import { api } from '@/modules/services';
import { useNotyf } from '@/composable/notyf';
import { ref, inject, computed } from 'vue';
import { useDetails } from '@/composable/challenge';
import { date } from '@bignerve/ui-utils';
import { cn } from '@/utils/Helpers';
import { cva } from 'class-variance-authority';

const props = defineProps({
    idea: {
        type: Object,
        required: true,
    },
    challenge: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['onChange']);

const notyf = useNotyf();
const declineNotify = ref(null);
const ratingDialog = ref(null);
const { isAMA } = useDetails(props.challenge);

const { content, refetch, loading } = inject('ideaContent');

const loadIdeaContent = async () => {
    await refetch();

    emit('onChange', content);
}

const hideIdea = async (idea, status = true) => {
    try {
        await api.challenges.reactCatalystIdea(
            props.challenge.slug, {
                op: 'hide',
                data: status,
                idea: idea.id,
                challenge_id: idea.challenge_id,
            },
        );

        await loadIdeaContent();
    } catch (error) {
        notyf.error('Something went wrong. Please try again later.');
        
        console.log(error);
    }
}

const likeIdea = async (idea) => {
    try {
        const lastValue = content.value.stats.catalyst_like;
        
        await api.challenges.reactCatalystIdea(
            props.challenge.slug, {
                op: 'like',
                data: !lastValue,
                idea: idea.id,
                challenge_id: idea.challenge_id,
            },
        );

        await loadIdeaContent();
    } catch (error) {
        notyf.error('Something went wrong. Please try again later.');

        throw error;
    }
}

const declineInnovatorIdea = async () => {
    try {
        await api.challenges.catalystUpdateIdea(
            props.challenge.slug, {
            op: 'decline',
            challenge_id: props.idea.challenge_id,
            idea: props.idea.id,
        });

        await loadIdeaContent();
    } catch (error) {
        notyf.error('Something went wrong. Please try again later.');

        throw error;
    }
}

const btVariants = cva(
  [
    'inline-flex items-center gap-2 justify-center whitespace-nowrap rounded-lg ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  ],
  {
    variants: {
        mentoring: {
            default: 'border leading-none text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800',
            on_extension: 'border border-2 font-semibold text-innovator-500 border-innovator-400 hover:bg-innovator-50',
            mentored: 'bg-innovator-500 text-white font-bold border-innovator-500 hover:bg-innovator-600 hover:text-white',
            pending: 'bg-innovator-500 text-white font-bold border-innovator-500 hover:bg-innovator-600 hover:text-white',
      },
      size: {
        default: 'px-3 py-3 min-w-[6rem]',
      }
    },
    defaultVariants: {
        mentoring: 'default',
        variant: 'default',
        size: 'default',
    },
  },
);

const mentoringVariant = computed(() => {
    const { expiration, mentor_comment, prize } = content.value || {};

    if (prize?.status === 'DECLINED') {
        return 'default';
    }

    if (expiration?.expire_status === 'on_extension') {
        return 'on_extension';
    }

    if (expiration?.expire_status === 'expired') {
        return 'default';
    }

    if (expiration?.expire_status === 'pending') {
        return 'pending';
    }

    if (mentor_comment?.id) {
        return 'mentored';
    }

    return 'default';
});

defineOptions({
    inheritAttrs: false,
});

defineExpose({
    hideIdea,
    likeIdea,
    loadIdeaContent,
});
</script>
<template>
    <div class="w-full flex items-center justify-center">
        <slot name="rating-idea-dialog" :idea="{ ...idea, ...content }">
            <div v-if="loading" class="h-[2.8rem] bg-dust-200 min-w-[6rem] rounded-lg animate-loading"></div>
            <RatingIdeaDialog v-else-if="content" ref="ratingDialog" :challenge="challenge" :idea="{ ...idea, ...content }" :forcedRating="isAMA ? 10 : null" @success="loadIdeaContent">
                <template v-if="isAMA" #header>
                    <NIcon as="mentor" class="text-xl mr-1" />
                    <h2 class="text-dust-700 font-bold text-lg mb-0">
                        Mentor
                    </h2>
                </template>
                <template #activator="{ on }">
                    <div v-if="isAMA" class="flex flex-col gap-2 items-center">
                        <button aria-label="button" v-on="on" type="button" :class="cn(btVariants({ mentoring: mentoringVariant }))">
                            <NIcon as="mentor" class="text-xl" />
                            <span v-if="content?.mentor_comment?.id">Mentored ${{ content.user_stats.entry_fee_amount_paid }}</span>
                            <span v-else-if="content.prize?.status === 'DECLINED'">Declined</span>
                            <span v-else-if="content.expiration?.expire_status === 'expired'">Expired</span>
                            <span v-else>Mentor ${{ content.user_stats.entry_fee_amount_paid }}</span>
                            <NIcon v-if="content.expiration?.expire_status === 'on_extension'" as="pencil-alt-solid" class="text-xl" />
                        </button>

                        <div v-if="content?.expiration" class="flex items-center justify-center">
                            <div v-if="content.expiration.expire_status === 'on_extension'" class="flex items-center gap-2">
                                <img class="w-5 -mt-1" src="@/assets/icons/stopwatch.svg" alt="">
                                <span class="font-semibold text-red-500 leading-none">Extension to {{ date(content.expiration.expires_at, 'MMMM D, YYYY') }}</span>
                            </div>
                            <div v-else-if="!content?.mentor_comment?.id" class="text-innovator font-semibold">
                                {{ $t('component.idea.idea-tools.extension.status.expiration', content.expiration.expire_status === 'pending' ? 1 : 2) }}
                                {{ date(content.expiration.expires_at, 'MMMM D, YYYY') }}
                            </div>
                        </div>
                    </div>
                    <button aria-label="button" v-else-if="challenge.is_closed" v-on="on" type="button" :class="content.rating?.id && content.rating.rating !== 0 ? 'text-rater-500 border-rater-300 hover:bg-rater-50 hover:text-rater-600' : 'text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800'" class="flex items-center px-3 py-3 border rounded-lg leading-none">
                        <NIcon v-if="content.rating?.id && content.rating.rating !== 0" as="rater" class="text-xl mr-1" />
                        <NIcon v-else as="mentor" class="text-xl mr-1" />
                        <span v-if="content.rating?.id">{{ content.rating.rating !== 0 ? `${content.rating.rating} - Your Rating` : 'Mentor' }}</span>
                        <span v-else>Mentor</span>
                    </button>
                    <button aria-label="button" v-else v-on="on" type="button" :class="content.rating?.id ? 'text-rater-500 border-rater-300 hover:bg-rater-50 hover:text-rater-600' : 'text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800'" class="flex items-center px-3 py-3 border rounded-lg leading-none">
                        <NIcon as="rater" class="text-xl mr-1" />
                        <span v-if="content.rating?.id">{{ content.rating.rating !== 0 ? `${content.rating.rating} - ` : '' }} Your Rating</span>
                        <span v-else>Rate & Mentor</span>
                    </button>
                </template>
            </RatingIdeaDialog>
        </slot>
    </div>

    <slot v-if="isAMA" name="decline">
        <div v-if="loading" class="h-[2.8rem] bg-dust-200 min-w-[6rem] rounded-lg animate-loading"></div>
        <Resolver v-else-if="content" :promise="declineInnovatorIdea" :immediate="false" #="{ loading, trigger }">
            <button aria-label="button" v-if="content.user_stats?.entry_fee_status !== PAYMENT_STATUS.DECLINED" type="button" :class="{ disabled: !!content.rating?.id || loading }" :disabled="!!content.rating?.id || loading" class="flex items-center justify-center min-w-[6rem] px-3 py-3 border rounded-lg leading-none text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800" @click="() => declineNotify?.open()">
                <NLoading v-if="loading" active />
                <NIcon v-else as="close-circle" class="text-xl mr-1" />
                Decline
            </button>

            <NNotify
                ref="declineNotify"
                title="Decline Idea"
                message="Are you sure you want to decline this idea? This will refund the innovator's payment."
                @confirm="trigger"
            />
        </Resolver>
    </slot>

    <slot v-if="!challenge.is_closed && !isAMA" name="hide" :hideIdea="hideIdea">
        <div v-if="loading" class="h-[2.8rem] bg-dust-200 min-w-[6rem] rounded-lg animate-loading"></div>
        <template v-else-if="content?.idea">
            <button aria-label="button" v-if="!content.stats.catalyst_hidden" type="button" class="flex items-center px-3 py-3 border rounded-lg leading-none text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800" @click="hideIdea(content?.idea)">
                <NIcon as="close-circle" class="text-xl mr-1" /> Hide
            </button>
            
            <button aria-label="button" v-else type="button" class="relative flex items-center justify-center min-w-[6rem] px-3 py-3 border rounded-lg leading-none text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800" @click="hideIdea(content?.idea, false)">
                <NIcon as="exclamation-circle-solid" class="text-xl mr-1" />
                Undo
            </button>
        </template>
    </slot>

    <slot v-if="!isAMA" name="like" :on="{ click: () => likeIdea(content?.idea) }">
        <Resolver :promise="likeIdea" :immediate="false" #="{ trigger, loading: busy }">
            <div v-if="loading" class="h-[2.8rem] bg-dust-200 min-w-[6rem] rounded-lg animate-loading"></div>
            <button aria-label="button" v-else-if="content?.idea" :class="cn('flex items-center pl-3 pr-4 py-3 border leading-none rounded-lg', !!content.stats.catalyst_like ? 'text-innovator border-innovator font-bold' : 'text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800', busy && 'animate-loading disabled')" :disabled="busy" type="button" @click="trigger(content?.idea)">
                <NIcon :as="!!content.stats.catalyst_like ? 'star-solid' : 'star-regular'" class="text-xl mr-1" :class="{ 'text-innovator border-innovator': content.stats.catalyst_like }" />
                {{ !!content.stats.catalyst_like ? 'Liked' : 'Like' }}
            </button>
        </Resolver>
    </slot>

    <slot v-if="content?.user_stats?.entry_fee_status !== PAYMENT_STATUS.DECLINED" name="message-dialog">
        <div v-if="loading" class="h-[2.8rem] bg-dust-200 min-w-[6rem] rounded-lg animate-loading"></div>
        <DirectMessageDialog v-else-if="content" :context="CONTEXT.MENTOR_COMMENT" :id="content?.mentor_comment?.id">
            <template #activator="{ on, unread, hasMessages, total }">
                <button aria-label="button" v-on="on" type="button" :class="{ '!text-catalyst !border-catalyst': unread }" class="text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800 flex items-center justify-center px-3 py-3 border rounded-lg leading-none min-w-[6rem]">
                    <NIcon :as="hasMessages ? 'comments-solid' : 'comments-o'"  class="text-xl mr-1" />
                    Chat <span v-if="hasMessages" class="ml-1">({{ total }})</span>
                </button>
            </template>
            <template v-if="!content?.mentor_comment?.id" #body="{ close }">
                <div class="p-4">
                    <div v-if="isAMA" class="bg-white rounded-lg p-4">
                        <strong class="text-lg text-dust-800">Before you chat:</strong>
                        <p class="text-dust-800">
                            Before you can chat with this person, you must first <strong>Mentor</strong> their idea. They'll get a notice about your mentoring, too.
                        </p>
                        <button aria-label="button" type="button" class="flex  mx-auto items-center px-3 py-3 border rounded-lg leading-none text-mentor-500 border-mentor-300 hover:bg-mentor-50 hover:text-mentor-600" @click="() => (close(), ratingDialog?.open())">
                            <NIcon :as="isAMA ? 'mentor' : 'rater'" class="text-xl mr-1" /> Mentor First
                        </button>
                    </div>

                    <div v-else class="bg-white rounded-lg p-4">
                        <strong class="text-lg text-dust-800">Before you chat:</strong>
                        <p class="text-dust-800">
                            Before you can chat with this person, you must first <strong>{{ challenge.is_closed ? 'Mentor' : 'Rate & Mentor' }}</strong> their idea. They'll get a notice about your mentoring, too.
                        </p>
                        <button aria-label="button" type="button" :class="challenge.is_closed ? 'text-mentor-500 border-mentor-300 hover:bg-mentor-50 hover:text-mentor-600' : 'text-rater-500 border-rater-300 hover:bg-rater-50 hover:text-rater-600'" class="flex  mx-auto items-center px-3 py-3 border rounded-lg leading-none " @click="() => (close(), ratingDialog.open())">
                            <NIcon :as="challenge.is_closed ? 'mentor' : 'rater'" class="text-xl mr-1" /> {{ challenge.is_closed ? 'Mentor First' : 'Rate & Mentor' }}
                        </button>
                    </div>
                </div>
            </template>
        </DirectMessageDialog>
    </slot>

    <slot name="send-idea-note">
        <div v-if="loading" class="h-[2.8rem] bg-dust-200 min-w-[6rem] rounded-lg animate-loading"></div>
        <SendIdeaNote v-else-if="content" :challenge="challenge" :idea="{ ...content?.idea, ...content }" >
            <template #activator="{ on }">
                <button aria-label="button" v-on="on" type="button" :class="content.stats?.catalyst_notes ? 'text-secondary-500 border-secondary-300 hover:bg-secondary-50 hover:text-secondary-800' : 'text-dust-600 border-dust-300 hover:bg-dust-50 hover:text-dust-800'" class="flex items-center px-3 py-3 border rounded-lg leading-none">
                    <NIcon as="envelope-regular" class="text-xl mr-1" />
                    {{ $t('component.challenge.send-idea-note.action.send', content.stats?.catalyst_notes ? 0 : 1 ) }}
                </button>
            </template>
        </SendIdeaNote>
    </slot>
</template>