<script setup>
import ChallengeYourIdeas from '@/components/challenge-show/YourIdeas.vue';
import Button from '@/components/ui/button/index.vue';
import NNotify from '@/components/NNotify.vue';
import { gameSetting } from '@/utils/challenge';
import { computed, ref } from 'vue';
import { useIdeaCreationFlow, useStoredIdeaData } from '@/composable/idea';
import { useRouter, useRoute } from 'vue-router';

const props = defineProps({
    challenge: {
        type: Object,
        required: true,
    },
});

const removeIdeaEl = ref(null);
const router = useRouter();
const route = useRoute();
const { fields, totalUserIdeas, totalIdeasLeft, storedIdeasData } = useIdeaCreationFlow(props.challenge);
const { removeData } = useStoredIdeaData(props.challenge);

const ideaEntryFee = computed(() => {
    return props.challenge.idea_entry_fee * totalUserIdeas.value;
});

const handleRemoveIdea = (idea) => {
    removeData(idea.id);
}

const handleEditIdea = (idea) => {
    fields.value = idea;

    router.push({
        ...route,
        name: 'challenges.show',
    });
}
</script>

<template>
    <div class="flex justify-center mt-5 mb-5">
        <div class="interstitial-modal">
            <div class="interstitial-modal__heading is-green">
                <h1 class="interstitial-modal__title">
                    <NIcon as="funding" />
                    Your idea(s) are ready to do be entered…
                </h1>
            </div>
            <div class="interstitial-modal__content">

                <ChallengeYourIdeas :total="totalUserIdeas" :challenge="challenge" class="mb-6" />

                <div class="flex flex-col items-center justify-around gap-4 mb-8 sm:flex-row sm:items-start">
                    <div class="flex flex-col gap-2 items-center justify-center w-60">
                        <RouterLink :class="{ disabled: !totalIdeasLeft }" :to="{ name: 'challenges.show', params: { challenge: $route.params.challenge } }" class="mb-1 min-w-[10rem] text-center px-6 text-white bg-innovator-500 py-2 rounded-lg hover:text-white hover:bg-innovator-600">
                            <span aria-hidden="true" class="btn__icon is-left icon-lightbulb-o1"></span>
                            {{ totalIdeasLeft >= 1 ? 'Enter another idea?' : 'Enter an Idea' }}
                        </RouterLink>
                        <p class="text-innovator text-center mb-0 leading-snug">
                            Add up to {{ gameSetting(challenge, 'max_user_ideas') }} of your ideas. Once you pay and start rating, you can’t add more ideas in this challenge.
                        </p>
                    </div>
                    
                    <div class="flex flex-col gap-2 items-center justify-center w-60">
                        <RouterLink :to="{ name: 'challenges.idea-entry-fee', params: { challenge: $route.params.challenge } }" type="button" class="mb-1 min-w-[10rem] text-center px-6 text-white bg-secondary-600 py-2 rounded-lg hover:text-white hover:bg-secondary-700" :class="{ 'disabled pointer-events-none': !totalUserIdeas }">
                            <NIcon as="funding" left />
                            Pay ${{ ideaEntryFee }}
                        </RouterLink>
                        <p class="text-secondary text-center mb-0 leading-snug">
                            Then your idea(s) get unlocked and posted in the challenge. You can then rate & mentor other ideas.
                        </p>
                    </div>
                </div>

                <div v-if="totalUserIdeas" class="mb-6">
                    <div v-for="idea of storedIdeasData.filter(i => i.id)" :key="idea.title" class="mb-2 shadow rounded text-lg bg-white p-[1rem] flex items-center gap-4 sm:justify-between flex-col sm:flex-row">
                        <div class="flex items-center gap-2 font-weight-normal ">
                            <NIcon as="lightbulb-o1" />{{ idea.title }}
                        </div>
                        <div class="flex items-center gap-2">
                            <Button v-tooltip="'Edit Idea'" type="button" variant="icon" size="sm" @click="handleEditIdea(idea)">
                                <NIcon as="pencil-alt-solid" />
                            </Button>
                            <Button v-tooltip="'Remove Idea'" type="button" variant="icon" size="sm" @click="removeIdeaEl.open(idea)">
                                <NIcon as="delete" />
                            </Button>
                        </div>
                    </div>
                </div>

                <p class="text-dust-700">
                    Thank you for participating! A portion of your payment is added to the Prize Total, so the money grows as more people join. The Top 33% or Top 30 participants (whichever is greater) earn a share of the prize money by earning points through entering ideas, rating ideas, and mentoring each others ideas.
                </p>

                <p class="text-dust-700">
                    <b>This payment is non-refundable</b>. Read and accept our <RouterLink :to="{ name: 'tos' }">terms of service</RouterLink> before use.
                </p>
            </div>
        </div>
    </div>


    <NNotify
        ref="removeIdeaEl"
        title="Remove Idea"
        message="Do you really want to remove this idea?"
        @confirm="handleRemoveIdea"
    />
</template>
