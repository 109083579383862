<script>
import MemeGenerator from '@/components/challenge-show/MemeGenerator.vue';
import capitalize from 'lodash/capitalize';

const baseURL = import.meta.env.VUE_APP_BASE_URL;

export default {
    inject: ['form'],
    components: {
        MemeGenerator,
    },
    computed: {
        image() {
            return this.form.fields.challenge.primary_image || `${baseURL}/img/placeholder/meme-03.png`;
        },
        title() {
            return this.form.fields.idea.title || 'This is the title';
        },
        description() {
            return this.form.fields.idea.description || 'This is the description';
        }
    },
    methods: {
        capitalize,
        size(text) {
            return text.length < 60 ? (text.length < 30 ? '1.5rem' : '1.3rem') : '1rem';
        },
    }
}
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">
                <b><i>{{ form.fields.challenge.question_title }}</i></b>
            </h2>
            <p class="challenge-section-heading__description">
                Choose the {{ form.fields.challenge.caption_solution_format }} overlay format for the idea text:
            </p>
        </div>
        <div class="form-section-wrapper">
            <div class="meme-preview-wrapper">

                <div
                    class="meme-preview"
                    :class="{ 'is-selected': form.fields.challenge.caption_solution_format === 'caption' }"
                    @click="form.fields.challenge.caption_solution_format = 'caption'"
                >
                    <span class="meme-preview__title">Caption</span>
                    <MemeGenerator
                        :punchline-text="title"
                        :image-src="image"
                        class="w-full"
                        ref="caption"
                    />
                </div>
                <div
                    class="meme-preview"
                    :class="{ 'is-selected': form.fields.challenge.caption_solution_format === 'meme' }"
                    @click="form.fields.challenge.caption_solution_format = 'meme'"
                >
                    <span class="meme-preview__title">Meme</span>
                    <MemeGenerator
                        :setup-text="title"
                        :punchline-text="description"
                        :image-src="image"
                        class="w-full"
                    />
                </div>
            </div>
        </div>
        <div class="form-section-wrapper">
            <div class="form-groups">
                <h2 class="form-group__title">
                    <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                    Test your own {{ capitalize(form.fields.challenge.caption_solution_format) }}!
                </h2>
                <span>
                    Your idea here will be inserted secretly into the crowd's ideas, so you can get unbiased ratings and feedback on it.
                </span>
                <div class="form-group">
                    <label class="form-group__label" v-if="form.fields.challenge.caption_solution_format === 'meme'">
                        <b>Top</b> <i class="font-weight-normal ml-3">Optional, up to 90 characters</i>
                    </label>
                    <label class="form-group__label" v-else>
                        <b>Enter your caption</b> <i class="font-weight-normal ml-3">Up to 90 characters</i>
                    </label>
                    <NInput name="idea-title" v-model="form.fields.idea.title" maxlength="90"></NInput>
                </div>
                <div class="form-group" v-if="form.fields.challenge.caption_solution_format === 'meme'">
                    <label class="form-group__label">
                        <b>Bottom</b> <i class="font-weight-normal ml-3">Optional, up to 90 characters</i>
                    </label>
                    <NInput name="idea-description" v-model="form.fields.idea.description" maxlength="90"></NInput>
                </div>
            </div>
        </div>
        <div class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid mt-0 mr-2"></span>
            <p class="info-box__content">
                <b>Note:</b> Once posted, your challenge will appear in your
                <router-link :to="{ name: 'dashboard.catalyst', params: { user: $auth.user.screen_name || 'me' } }">Catalyst Dashboard</router-link> where you can see incoming ideas.
            </p>
        </div>
    </div>
</template>