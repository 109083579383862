import { orderBy } from 'lodash';

export const mapRoutesFromMd = (pages, { prefix = '', order = ['meta.index', 'meta.title'] } = {}) => {
    return orderBy(Object.values(pages)
        .map((page) => {
            if (page.disabled) {
                return
            }

            const pageComp = page.default;
            const pageName = page.name || pageComp.__name;

            return {
                path: page.path !== undefined ? page.path : pageName,
                name: [prefix, pageName].join('.'),
                component: pageComp,
                meta: {
                    title: page.title,
                    breadcrumb: page.title,
                    hidden: page.hidden,
                    index: page.index,
                    expanded: page.expand,
                },
            }
    }).filter(r => !!r), order);
}

export const isDifferentOrigin = (from = {}) => {
    const { origin } = location;
    const { referrer } = document;
    
    if (!referrer) {
        return true;
    }

    if (referrer.includes(origin)) {
        return false;
    }

    return !from.name;
}

export const surveyModeRedirection = (to) => {
    const account = import.meta.env.VUE_APP_ACCOUNT_URL;
    const survey = import.meta.env.VUE_APP_SURVEY_URL;
    const source = new URL(location ? location.href : '/');
    const url = new URL(`${account}/gateway`);

    url.searchParams.append('continue', encodeURIComponent(source.href.replace('/survey', '')));
    url.searchParams.append('follow', encodeURIComponent(`${survey}/challenge/${to.params.challenge}${source.searchParams.has('scout_id') ? `?scout_id=${source.searchParams.get('scout_id')}` : ''}`));
    url.searchParams.append('app', 'bignerve');

    location.replace(url.href);
}