import { store } from '@/store';
import { oldApi as nerve, api as newApi } from '@/modules/services';
import { isMobile } from '@/utils/Helpers';
import upperFirst from 'lodash/upperFirst';

// Middlewares
import authMiddleware from './middleware/auth';
import modeMiddleware from './middleware/mode';

// Primary Screens
// import Landing from '@/pages/Landing.vue';
import Join from '@/pages/Join.vue';
import Home from '@/pages/home/index.vue';
import HomeChallengesWrapper from '@/pages/home/challenges/index.vue';
import HomeChallengesWidgets from '@/pages/home/challenges/Widgets.vue';
import HomeChallengesSearch from '@/pages/home/challenges/Search.vue';
import Intro from '@/pages/Intro.vue';
import Reading from '@/pages/Reading.vue';
import LandingSMB from '@/pages/Landing-SMB.vue';


// Release Notes
// import ReleaseNotesRoutes from '@/pages/release-notes/routes';

// Generic
import Faq from '@/pages/faqs/index.vue';
import Sharing from '@/pages/Sharing.vue';
import Discover from '@/pages/Discover.vue';
import Support from '@/pages/Support.vue';
import IdeaRace from '@/pages/IdeaRace.vue';
import Scoreboard from '@/pages/Scoreboard.vue';
import SharingCenter from '@/pages/SharingCenter.vue';

const NerveBoards = () => import('@/pages/nerve-boards/index.vue');
const OlympiadLeaderboard = () => import('@/pages/leaderboard/index.vue');

// About
const Tos = () => import('@/pages/about/Tos.vue');
const Dmca = () => import('@/pages/about/Dmca.vue');
const Claremont = () => import('@/pages/about/claremont.vue');
const Gdpr = () => import('@/pages/about/Gdpr.vue');
const Policy = () => import('@/pages/about/Policy.vue');
const Accessibility = () => import('@/pages/about/Accessibility.vue');
const Contact = () => import('@/pages/about/Contact.vue');
const CodeOfConduct = () => import('@/pages/about/CodeOfConduct.vue');
const IdeaPerson = () => import('@/pages/about/IdeaPerson.vue');
const SendFeedback = () => import('@/pages/about/SendFeedback.vue');
const WhyInnervation = () => import('@/pages/about/WhyInnervation.vue');
const AboutPlatform = () => import('@/pages/about/AboutPlatform.vue');

// Home
import HomeTeams from '@/pages/home/Teams.vue';
import HomeMedals from '@/pages/home/Medals.vue';
import HomeBlog from '@/pages/home/Blog.vue';
import HomeAboutMe from '@/pages/home/AboutMe.vue';
import SnoozedSharings from '@/pages/home/SnoozedSharings.vue';
import HomeOlympiads from '@/pages/home/Olympiads.vue';
import PlatformMoved from '@/pages/Moved.vue';
import QuestionCollection from '@/pages/QuestionCollection.vue';

// Dashboard
const WrapperDashboard = () => import('@/pages/dashboard/index.vue');
const Stats = () => import('@/pages/dashboard/Stats.vue');

const Scout = () => import('@/pages/dashboard/scout/index.vue');
const ScoutChallenges = () => import('@/pages/dashboard/scout/challenges/index.vue');
const ScoutOlympiad = () => import('@/pages/dashboard/scout/olympiad/index.vue');
const ScoutRecruits = () => import('@/pages/dashboard/scout/recruits/index.vue');

const Rater = () => import('@/pages/dashboard/rater/index.vue');
const RaterOpen = () => import('@/pages/dashboard/rater/open/index.vue');
const RaterMyChallenges = () => import('@/pages/dashboard/rater/my-challenges/index.vue');
const RaterClosed = () => import('@/pages/dashboard/rater/closed/index.vue');

const Catalyst = () => import('@/pages/dashboard/catalyst/index.vue');
const CatalystDetail = () => import('@/pages/dashboard/catalyst/open/advanced/detail/index.vue');
const CatalystDetailIdeas = () => import('@/pages/dashboard/catalyst/open/advanced/detail/ideas/index.vue');
const CatalystDetailEdit = () => import('@/pages/dashboard/catalyst/open/advanced/detail/edit/index.vue');
const CatalystDetailMentoring = () => import('@/pages/dashboard/catalyst/open/advanced/detail/mentoring/index.vue');
const CatalystDetailMentoringDetail = () => import('@/pages/dashboard/catalyst/open/advanced/detail/mentoring-detail/index.vue');
const CatalystOpen = () => import('@/pages/dashboard/catalyst/open/index.vue');
const CatalystOpenAdvancedMode = () => import('@/pages/dashboard/catalyst/open/advanced/All.vue');
const CatalystOpenEasyModeChallenges = () => import('@/pages/dashboard/catalyst/open/easy/All.vue');
const CatalystOpenEasyModeDetails = () => import('@/pages/dashboard/catalyst/open/easy/Detail.vue');
const CatalystClosing = () => import('@/pages/dashboard/catalyst/closing/index.vue');
const CatalystDraft = () => import('@/pages/dashboard/catalyst/draft/index.vue');
const CatalystClosedChallenges = () => import('@/pages/dashboard/catalyst/closed/index.vue');

const CatalystDetailClosed = () => import('@/pages/dashboard/catalyst/detail-closed/index.vue');
const CatalystDetailClosedIdeas = () => import('@/pages/dashboard/catalyst/detail-closed/Ideas.vue');

// Discussion Forums
import DiscussionForums from '@/pages/discussion-forums/index.vue';

// Suggestion Box
const CatalystSuggestionBoxAll = () => import('@/pages/dashboard/catalyst/suggestion-box/All.vue');
const CatalystSuggestionBoxOpen = () => import('@/pages/dashboard/catalyst/suggestion-box/Open.vue');
const CatalystSuggestionBoxOpenDetail = () => import('@/pages/dashboard/catalyst/suggestion-box/easy/Detail.vue');

const Director = () => import('@/pages/dashboard/director/index.vue');
const GettingStarted = () => import('@/pages/dashboard/director/GettingStarted.vue');
const DirectorAdministration = () => import('@/pages/dashboard/director/administration/index.vue');
const DirectorCurrentOlympiad = () => import('@/pages/dashboard/director/olympiads/index.vue');
const DirectorPastOlympiad = () => import('@/pages/dashboard/director/PastOlympiad.vue');
const DirectorScoutRace = () => import('@/pages/dashboard/director/ScoutRace.vue');
const DirectorFunding = () => import('@/pages/dashboard/director/Funding.vue');

// Innovator Dashboard

const Innovator = () => import('@/pages/dashboard/innovator/index.vue');
const InnovatorOpen = () => import('@/pages/dashboard/innovator/open/index.vue');
const InnovatorClosed = () => import('@/pages/dashboard/innovator/closed/index.vue');
const InnovatorDetail = () => import('@/pages/dashboard/innovator/detail/index.vue');
const InnovatorDetailIdeas = () => import('@/pages/dashboard/innovator/detail/ideas/index.vue');
const InnovatorDetailIdea = () => import('@/pages/dashboard/innovator/detail/idea/index.vue');

const InnovatorSuggestionBox = () => import('@/pages/dashboard/innovator/suggestion-box/index.vue');
const InnovatorSuggestionBoxIdeas = () => import('@/pages/dashboard/innovator/suggestion-box/ideas/index.vue');
const InnovatorSuggestionBoxList = () => import('@/pages/dashboard/innovator/suggestion-box/ideas/List.vue');
const InnovatorSuggestionBoxDetail = () => import('@/pages/dashboard/innovator/suggestion-box/ideas/Detail.vue');

// My Progress Dashboard

const MyProgress = () => import('@/pages/dashboard/progress/index.vue');
const MyProgressScoreStats = () => import('@/pages/dashboard/progress/score-stats/index.vue');

// Mentor Dashboard
const Mentoring = () => import('@/pages/dashboard/mentor/index.vue');

const Awards = () => import('@/pages/dashboard/Awards.vue');
const Impact = () => import('@/pages/dashboard/Impact.vue');
const Resume = () => import('@/pages/dashboard/Resume.vue');
const MyLabs = () => import('@/pages/dashboard/MyLabs.vue');
const Friends = () => import('@/pages/dashboard/Friends.vue');
const IdeaLog = () => import('@/pages/dashboard/IdeaLog.vue');
const TestIdea = () => import('@/pages/dashboard/TestIdea.vue');
const Dashboard = () => import('@/pages/dashboard/Dashboard.vue');
const NerveOnAh = () => import('@/pages/dashboard/NerveOnAh.vue');
const Insightful = () => import('@/pages/dashboard/Insightful.vue');
const Leaderboards = () => import('@/pages/dashboard/Leaderboards.vue');
const SuggestionBox = () => import('@/pages/dashboard/SuggestionBox.vue');
const MoneyPrizes = () => import('@/pages/dashboard/MoneyPrizes.vue');

// Messages
const Messages = () => import('@/pages/dashboard/messages/index.vue');
const MessagesContext = () => import('@/pages/dashboard/messages/context/index.vue');

// Notifications
const Notifications = () => import('@/pages/dashboard/Notifications.vue');
const NotificationsHighlights = () => import('@/pages/dashboard/notifications/highlights/index.vue');
const NotificationsHelpfulTips = () => import('@/pages/dashboard/notifications/helpful-tips/index.vue');
const NotificationsNeuronsEarned = () => import('@/pages/dashboard/notifications/neurons-earned/index.vue');

// Account
import MyAccount from '@/pages/account/MyAccount.vue';
import EmailPreferences from '@/pages/account/EmailPreferences.vue';
import PlatformPreferences from '@/pages/account/PlatformPreferences.vue';
import ForumPreferences from '@/pages/account/ForumPreferences.vue';
import ForumTools from '@/pages/account/ForumTools.vue'
import Bio from '@/pages/account/Bio.vue';
import Social from '@/pages/account/Social.vue';
import PaymentWallets from '@/pages/account/payment-wallets/index.vue';
import Security from '@/pages/account/Security.vue';
import Masthead from '@/pages/account/Masthead.vue';
import Organization from '@/pages/account/Organization.vue';

// Challenges
import ChallengeWrapper from '@/pages/challenges/index.vue';
import ChallengeShow from '@/pages/challenges/Show.vue';
import ChallengeCreate from '@/pages/challenges/Create.vue';
import ChallengeIdeaRating from '@/pages/challenges/Rating.vue';
import RatingInit from '@/pages/challenges/RatingInit.vue';
import IdeaEntryFee from '@/pages/challenges/IdeaEntryFee.vue'
import RatingUnlock from '@/pages/challenges/RatingUnlock.vue';
import IdeaUnlock from '@/pages/challenges/IdeaUnlock.vue';
import RatingEnd from '@/pages/challenges/RatingEnd.vue';

import RouterViewOnly from '@/components/RouterViewOnly.vue';

// Suggestion Box AMA
const SuggestionBoxWrapper = () => import('@/pages/suggestion-box/index.vue');
const SuggestionBoxSignUp = () => import('@/pages/suggestion-box/sign-up/index.vue');
const SuggestionBoxAma = () => import('@/pages/suggestion-box/ama/index.vue');
const SuggestionBoxAmaDetail = () => import('@/pages/suggestion-box/ama/details/index.vue');
const SuggestionBoxAmaPayment = () => import('@/pages/suggestion-box/ama/payment/index.vue');
const SuggestionBoxAmaSuccess = () => import('@/pages/suggestion-box/ama/success/index.vue');

import { children as faqRoutes } from '@/pages/faqs/routes';

export const routes = [
	// Generic routes...
	{
		path: '/home',
		name: 'landing',
		redirect: {
			name: 'home.challenges',
		},
	},
	{
		path: '/moved',
		name: 'moved',
		component: PlatformMoved,
		meta: { splash: true },
	},
	{
		path: '/introduction',
		name: 'intro',
		meta: { layout: 'auth', scrollToTop: true, splash: true },
		component: Intro,
	},
	{
		path: '/join-the-community',
		name: 'join',
		meta: { layout: 'auth', scrollToTop: true, splash: true },
		component: Join,
	},
	{
		path: '/reading-list',
		name: 'reading',
		meta: { layout: 'auth', scrollToTop: true, splash: true },
		component: Reading,
	},
	{
		path: '/idea-race',
		name: 'idea-race',
		meta: { layout: 'wide', scrollToTop: true },
		component: IdeaRace,
	},
	{
		path: '/scores-stats',
		name: 'scores-stats',
		meta: { layout: 'wide', scrollToTop: true },
		component: Scoreboard,
	},
	{
		path: '/nerve-boards',
		name: 'nerve-boards',
		meta: { layout: 'wide', scrollToTop: false },
		component: NerveBoards,
	},
	{
		path: '/discovernervus',
		alias: '/discover-nervus',
		name: 'discover',
		meta: { layout: 'wide', scrollToTop: true },
		component: Discover,
	},
	{
		path: '/get-support',
		name: 'support',
		meta: { layout: 'wide', scrollToTop: true },
		component: Support,
		redirect() {
			return {
				name: 'send-feedback',
				query: {
					about: `Get Support - ${upperFirst(location.hostname.split('.')[0])}`,
				},
			};
		},
	},
	{
		path: '/faqs',
		name: 'faqs',
		meta: { layout: 'boxed', scrollToTop: 'FAQs', breadcrumb: 'FAQS' },
		component: Faq,
		redirect: { name: 'faqs.indice' },
		children: faqRoutes,
	},
	// Release Notes Routes,
	{
		path: '/terms-of-service',
		name: 'tos',
		meta: { layout: 'boxed', scrollToTop: true, expanded: true },
		component: Tos,
	},
	{
		path: '/gdpr',
		name: 'gdpr',
		meta: { layout: 'boxed', scrollToTop: true, expanded: true },
		component: Gdpr,
	},
	{
		path: '/dmca',
		name: 'dmca',
		meta: { layout: 'boxed', scrollToTop: true, expanded: true },
		component: Dmca,
	},
	{
		path: '/privacy-policy',
		name: 'policy',
		meta: { layout: 'boxed', scrollToTop: true, expanded: true },
		component: Policy,
	},
	{
		path: '/accessibility',
		name: 'accessibility',
		meta: { layout: 'boxed', scrollToTop: true, expanded: true },
		component: Accessibility,
	},
	{
		path: '/learn',
		name: 'landing-SMB',
		meta: { layout: 'auth', scrollToTop: true, splash: true },
		component: LandingSMB,
	},

	{
		path: '/question-collection',
		name: 'question-collection',
		component: QuestionCollection,
		meta: {
			splash: true,
			hideHeader: true,
			hideFooter: true,
		},
	},

	{
		path: '/:user/question-collection',
		name: 'user.question-collection',
		component: QuestionCollection,
		meta: {
			splash: true,
			hideHeader: true,
			hideFooter: true,
		},
	},

	// About routes...
	{
		path: '/about',
		redirect: '/about/about-bignerve',
		component: RouterViewOnly,
		meta: { layout: 'boxed', scrollToTop: true, breadcrumb: 'About' },
		children: [
			{
				path: 'about-bignerve',
				name: 'about',
				meta: { breadcrumb: 'About Bignerve' },
				component: AboutPlatform,
			},
			{
				path: 'send-feedback',
				alias: '/send-feedback',
				name: 'send-feedback',
				meta: { breadcrumb: 'Send Feedback' },
				component: SendFeedback,
			},
			{
				path: 'claremont',
				name: 'claremont',
				meta: { breadcrumb: 'Claremont' },
				component: Claremont,
			},
			{
				path: 'why-innervation',
				name: 'why-innervation',
				meta: { breadcrumb: 'Why Innervator' },
				component: WhyInnervation,
			},
			{
				path: 'idea-person',
				name: 'idea-person',
				meta: { breadcrumb: 'Being an Idea Person' },
				component: IdeaPerson,
			},
			{
				path: 'contact-us',
				name: 'contact',
				meta: { breadcrumb: 'Contact Us' },
				component: Contact,
			},
			{
				path: 'code-of-conduct',
				name: 'code-of-conduct',
				meta: { breadcrumb: 'Code of Conduct' },
				component: CodeOfConduct,
			},
		],
	},

	// User homepage route
	{
		path: '/me',
		name: 'auth.me',
		beforeEnter(to, _, next) {
			store
				.dispatch('auth/login')
				.then(({ user }) => {
					next({
						name: 'home.user',
						params: { user: user.screen_name },
						query: to.query,
					});
				})
				.catch(() => {
					next({
						name: 'home.challenges',
						query: to.query,
					});
				});
		},
	},

	// Alias for AMA challenges page if user is logged in
	{
		path: '/mentor',
		name: 'home.ama-challenge',
		beforeEnter(to, _, next) {
			store
				.dispatch('auth/login')
				.then(({ user }) => {
					next({
						name: 'suggestion-box.ama.detail',
						params: { user: user.screen_name },
						query: to.query,
					});
				})
				.catch(() => {
					next({
						name: 'home.challenges',
						query: to.query,
					});
				});
		},
	},

	// Redirect to medals page if user is logged in
	{
		path: '/medals',
		alias: '/medals-badges',
		name: 'medals',
		beforeEnter(to, _, next) {
			store
				.dispatch('auth/login')
				.then(({ user }) => {
					next({
						name: 'home.user.medals',
						params: { user: user.screen_name },
						query: to.query,
					});
				})
				.catch(() => {
					next({
						name: 'home.challenges',
						query: to.query,
					});
				});
		},
	},

	// This route redirects to the user's dashboard,
	// so changes to the dashboard path could reflect this route.
	{
		path: '/my-dashboard:all(.*)',
		name: 'dashboard.redirect',
		beforeEnter(to, _, next) {
			store
				.dispatch('auth/login')
				.then(({ user }) => {
					next({
						path: `/${user.screen_name}${to.fullPath}`,
						query: to.query,
					});
				})
				.catch(() => {
					const redirect = `${import.meta.env.VUE_APP_ACCOUNT_URL}/gateway`;
					const [, appName] = location.hostname.split('.').reverse();
					const continueURL = encodeURIComponent(location.href);

					return location.replace(`${redirect}?continue=${continueURL}&platform=${appName}`);
				});
		},
	},

	// Home routes
	{
		path: '',
		name: 'home',
		meta: { layout: 'wide', scrollToTop: true },
		component: Home,
		redirect: { name: 'home.challenges' },
		children: [
			{
				path: '',
				name: 'home.challenges',
				redirect: { name: 'home.challenges.widgets' },
				component: HomeChallengesWrapper,
				children: [
					{
						path: '',
						name: 'home.challenges.widgets',
						component: HomeChallengesWidgets,
						meta: { headingTitle: 'page.home.challenges.heading-title' },
					},
					{
						path: ':user?/search',
						alias: 'search',
						name: 'home.challenges.search',
						component: HomeChallengesSearch,
						meta: {
							headingTitle: 'page.home.search.heading-title',
							scrollToTop: () => ({ top: 40, behavior: 'smooth', el: '.app-main' }),
						},
					},
				]
			},
			{
				path: ':user/podcast',
				alias: ':user/podcasts',
				name: 'home.podcast',
				redirect(to) {
					return {
						name: 'home.user',
						params: to.params,
						query: {
							filter: 'idea_entry_fee'
						}
					};
				}
			},
			{
				path: ':user',
				name: 'home.user',
				component: HomeChallengesWrapper,
				beforeEnter: (to, _, next) => {
					if (to.params.user === 'skeptic') {
						return next({
							name: 'suggestion-box',
							params: { user: 'shermer' },
							query: { scout_id: 'shermer' },
						});
					}

					// Redirect to home page for these handles
					if (['bignerve'].includes(to.params.user)) {
						return next('/');
					}
					
					next();
				},
				meta: {
					headingTitle: 'page.home.challenges.heading-title',
					scrollToTop: (to) => {
						return isMobile() && !!to.query?.filter ? {
							top: 30,
							behavior: 'smooth',
							el: '.card-section'
						} : null;
					}
				},
				redirect: {
					name: 'home.user.challenges',
				},
				children: [
					{
						path: '',
						name: 'home.user.challenges',
						component: HomeChallengesWidgets,
					}
				]
			},
			{
				path: ':user/medals-badges',
				alias: ':user/medals',
				name: 'home.user.medals',
				component: HomeMedals,
				meta: { headingTitle: 'page.home.medals.heading-title' },
			},
			{
				path: ':user/blog',
				alias: ':user/bio',
				name: 'home.user.blog',
				component: HomeBlog,
				meta: { headingTitle: 'page.home.blog.heading-title' },
			},
			{
				path: ':user/about',
				alias: ':user/about-me',
				name: 'home.user.about-me',
				component: HomeAboutMe,
				meta: { headingTitle: 'page.home.about-me.heading-title' },
			},
			{
				path: ':user/teams',
				name: 'home.teams',
				component: HomeTeams,
				meta: { headingTitle: 'page.home.teams.heading-title' },
			},
			{
				path: 'category/:category',
				name: 'home.category',
				redirect: ({ params }) => ( {
					name: 'home.challenges',
					query: {
						category: params.category,
					},
				}),
				// meta: { headingTitle: 'page.home.category.heading-title' },
				// component: HomeChallenges,
			},
			{
				path: ':user/tournaments',
				name: 'home.olympiads',
				meta: { headingTitle: 'page.home.olympiads.heading-title' },
				component: HomeOlympiads,
				// beforeEnter: (to, from, next) => {
				// 	const url = import.meta.env.VUE_APP_BASE_URL;

				// 	if (location.origin !== url) {
				// 		location.assign(`${url}${to.fullPath}`);

				// 		return;
				// 	}

				// 	next();
				// },
			},
			{
				path: ':user/share',
				name: 'home.user.snoozed-sharings',
				component: SnoozedSharings,
				beforeEnter: authMiddleware,
				meta: {
					headingTitle: 'page.home.snoozed-sharings.title',
					headingSubtitle: 'page.home.snoozed-sharings.subtitle',
				},
			},
		],
	},

	// TODO - remove it
	// {
	// 	path: '/:user/mentor',
	// 	name: 'home.user.mentor',
	// 	beforeEnter: async (to, from, next) => {
	// 		try {
	// 			const { data } = await newApi.challenges.suggestion(to.params.user, 'ama').then((r) => r.data);

	// 			if (!data) {
	// 				next({
	// 					name: 'home.user',
	// 					params: to.params,
	// 				});
	// 			}

	// 			next({
	// 				name: 'challenges.show',
	// 				params: { challenge: data.slug },
	// 			});
	// 		} catch (e) {
	// 			next({
	// 				name: 'home.user',
	// 				params: to.params,
	// 			});
	// 		}
	// 	},
	// },


	{
		path: '/:user/mentor',
		name: 'suggestion-box',
		component: SuggestionBoxWrapper,
		meta: { layout: 'raw', splash: true, hideHeader: true, hideFooter: true, scrollToTop: (to, from) => (to?.name !== from?.name) },
		redirect: { name: 'suggestion-box.ama.detail' },
		children: [
			{
				path: '',
				name: 'suggestion-box.ama',
				component: SuggestionBoxAma,
				redirect: { name: 'suggestion-box.ama.detail' },
				children: [
					{
						path: '',
						name: 'suggestion-box.ama.detail',
						component: SuggestionBoxAmaDetail,
					},
					{
						path: 'pay',
						name: 'suggestion-box.ama.payment',
						component: SuggestionBoxAmaPayment,
					},
					{
						path: 'sent',
						name: 'suggestion-box.ama.sent',
						component: SuggestionBoxAmaSuccess,
					},
				]
			},
			{
				path: 'sign-up',
				name: 'suggestion-box.sign-up',
				component: SuggestionBoxSignUp,
			},
		],
		// beforeEnter: async (to, from, next) => {
		// 	try {
		// 		const { data } = await newApi.challenges.suggestion(to.params.user, 'ama').then((r) => r.data);

		// 		if (!data) {
		// 			next({
		// 				name: 'home.user',
		// 				params: to.params,
		// 			});
		// 		}

		// 		next({
		// 			name: 'challenges.show',
		// 			params: { challenge: data.slug },
		// 		});
		// 	} catch (e) {
		// 		next({
		// 			name: 'home.user',
		// 			params: to.params,
		// 		});
		// 	}
		// },
	},

	// Dashboard routes...
	{
		path: '/:user/my-dashboard',
		alias: '/:user/dashboard',
		name: 'dashboard',
		meta: {
			layout: 'boxed',
			scrollToTop: true,
			breadcrumb({ params }, _, { auth }) {
				const { screen_name } = auth.user;

				return {
					label: params.user !== screen_name ? `@${params.user}` : 'My Dashboard',
					to: {
						name: 'dashboard.dashboard',
						params: { user: params.user },
					},
				};
			},
		},
		component: WrapperDashboard,
		children: [
			{
				path: '',
				name: 'dashboard.dashboard',
				meta: { layout: 'boxed', scrollToTop: true, breadcrumb: false },
				component: Dashboard,
				beforeEnter: authMiddleware,
			},
			{
				path: 'messages',
				name: 'dashboard.messages',
				meta: { layout: 'boxed', scrollToTop: true, breadcrumb: 'Messages Center' },
				component: Messages,
				beforeEnter: authMiddleware,
				children: [
					{
						name: 'dashboard.messages.context',
						path: ':context/:id',
						meta: { scrollToTop: true },
						component: MessagesContext,	
					}
				],
			},
			{
				path: 'nerve-on-ah/private',
				name: 'dashboard.nerve-on-ah.private',
				meta: { layout: 'boxed', scrollToTop: true },
				component: NerveOnAh,
				beforeEnter: authMiddleware,
			},
			{
				path: 'nerve-on-ah/public',
				name: 'dashboard.nerve-on-ah.public',
				meta: { layout: 'boxed', scrollToTop: true },
				component: NerveOnAh,
				beforeEnter: authMiddleware,
			},
			{
				path: 'innovation-resume',
				name: 'dashboard.resume',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Resume,
				beforeEnter: authMiddleware,
			},
			{
				path: 'friends-followers',
				name: 'dashboard.friends',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Friends,
				beforeEnter: authMiddleware,
			},
			{
				path: 'scout',
				name: 'dashboard.scouting',
				meta: {
					layout: 'boxed',
					scrollToTop: false,
					breadcrumb: 'My Players',
				},
				component: Scout,
				beforeEnter: authMiddleware,
				redirect: { name: 'dashboard.scouting.recruits' },
				children: [
					{
						path: 'challenges',
						name: 'dashboard.scouting.challenges',
						component: ScoutChallenges,
						meta: { scrollToTop: false },
					},
					{
						path: 'olympiad',
						name: 'dashboard.scouting.olympiad',
						component: ScoutOlympiad,
						meta: { scrollToTop: false },
					},
					{
						path: 'recruits',
						name: 'dashboard.scouting.recruits',
						component: ScoutRecruits,
						meta: { scrollToTop: false },
					},
				],
			},
			{
				path: 'director',
				name: 'dashboard.director',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'Director',
				},
				component: Director,
				beforeEnter: authMiddleware,
				redirect: { name: 'dashboard.director.getting-started' },
				children: [
					{
						path: 'getting-started',
						name: 'dashboard.director.getting-started',
						component: GettingStarted,
						meta: { scrollToTop: false },
					},
					{
						path: 'current-olympiad',
						name: 'dashboard.director.current-olympiad',
						component: DirectorCurrentOlympiad,
						meta: { scrollToTop: false },
					},
					{
						path: 'past-olympiad',
						name: 'dashboard.director.past-olympiad',
						component: DirectorPastOlympiad,
						meta: { scrollToTop: false },
					},
					{
						path: 'scout-race',
						name: 'dashboard.director.scout-race',
						component: DirectorScoutRace,
						meta: { scrollToTop: false },
					},
					{
						path: 'funding',
						name: 'dashboard.director.funding',
						component: DirectorFunding,
						meta: { scrollToTop: false },
					},
					{
						path: 'administration',
						name: 'dashboard.director.administration',
						component: DirectorAdministration,
						meta: { scrollToTop: false },
					},
				],
			},
			{
				path: 'my-progress',
				name: 'dashboard.my-progress',
				meta: { layout: 'boxed', scrollToTop: true, breadcrumb: 'My Progress' },
				component: MyProgress,
				beforeEnter: authMiddleware,
				redirect: {
					name: 'dashboard.my-progress.score-stats',
					query: { tab: 'neurons-over-time' },
				},
				children: [
					{
						path: 'score-stats',
						name: 'dashboard.my-progress.score-stats',
						component: MyProgressScoreStats,
						meta: { scrollToTop: false, breadcrumb: 'Score & Stats' },
					},
				],
			},
			{
				path: 'innovator',
				name: 'dashboard.innovator',
				component: Innovator,
				beforeEnter: authMiddleware,
				redirect: { name: 'dashboard.innovator.challenges.open' },
				children: [
					{
						path: 'challenges',
						name: 'dashboard.innovator.challenges',
						component: RouterViewOnly,
						meta: {
							layout: 'boxed',
							scrollToTop: true,
							breadcrumb: 'My Ideas',
						},
						children: [
							{
								path: 'open',
								name: 'dashboard.innovator.challenges.open',
								component: RouterViewOnly,
								meta: { scrollToTop: false },
								redirect: {
									name: 'dashboard.innovator.challenges.open.all',
								},
								children: [
									{
										path: '',
										name: 'dashboard.innovator.challenges.open.all',
										component: InnovatorOpen,
										meta: {
											scrollToTop: (to, from) => to?.name !== from?.name,
										},
									},
									{
										path: 'detail/:challenge',
										name: 'dashboard.innovator.challenges.open.detail',
										component: InnovatorDetail,
										redirect: { name: 'dashboard.innovator.challenges.open.detail.ideas' },
										meta: { scrollToTop: () => ({ top: 40, behavior: 'smooth', el: '.app-main' }) },
										children: [
											{
												path: '',
												name: 'dashboard.innovator.challenges.open.detail.ideas',
												component: InnovatorDetailIdeas,
												meta: {
													breadcrumb: ['Ideas'],
												},
											},

											{
												path: ':idea',
												name: 'dashboard.innovator.challenges.open.detail.idea',
												component: InnovatorDetailIdea,
												meta: {
													breadcrumb: ['Idea'],
													scrollToTop: (to, from) => to?.name !== from?.name,
												},
											}
										]
									},
								],
							},
							{
								path: 'closed',
								name: 'dashboard.innovator.challenges.closed',
								component: InnovatorClosed,
								meta: { scrollToTop: false },
							},
							{
								path: 'resolve/:challenge/:idea?',
								name: 'dashboard.innovator.challenges.resolve',
								beforeEnter: async (to, from, next) => {
									const { data } = await nerve.challenges.get(to.params.challenge).then((r) => r.data);

									if (data.is_closed || data.status === 'closed') {
										return next({
											name: 'dashboard.innovator.challenges.closed',
											params: to.params,
										});
									}

									return next({
										name: 'dashboard.innovator.challenges.open.detail',
										params: to.params,
									});
								},
							},
						],
					},

					{
						path: 'suggestion-box',
						name: 'dashboard.innovator.suggestion-box',
						component: RouterViewOnly,
						meta: { scrollToTop: true, expanded: true },
						redirect: { name: 'dashboard.innovator.suggestion-box.all' },
						children: [
							{
								path: '',
								name: 'dashboard.innovator.suggestion-box.all',
								component: InnovatorSuggestionBox,
								meta: {
									breadcrumb: ['Suggestion Box AMA'],
									expanded: true,
								},
							},
							{
								path: ':challenge',
								name: 'dashboard.innovator.suggestion-box.ideas',
								component: InnovatorSuggestionBoxIdeas,
								meta: {
									breadcrumb: ['Suggestion Box AMA'],
									expanded: true,
								},
								redirect: { name: 'dashboard.innovator.suggestion-box.ideas.list' },
								children: [
									{
										path: '',
										name: 'dashboard.innovator.suggestion-box.ideas.list',
										component: InnovatorSuggestionBoxList,
										meta: {
											breadcrumb: ['Ideas'],
											expanded: true,
										},
									},
									{
										path: ':idea',
										name: 'dashboard.innovator.suggestion-box.ideas.detail',
										component: InnovatorSuggestionBoxDetail,
										meta: {
											breadcrumb: ['Idea'],
											expanded: true,
										},
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'idea-team-labs',
				name: 'dashboard.my-labs',
				meta: { layout: 'boxed', scrollToTop: true },
				component: MyLabs,
				beforeEnter: authMiddleware,
			},
			{
				path: 'insightful',
				name: 'dashboard.insightful',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Insightful,
				beforeEnter: authMiddleware,
			},
			{
				path: 'my-progress',
				name: 'dashboard.progress',
				meta: { layout: 'boxed', scrollToTop: true },
				component: MyProgress,
				beforeEnter: authMiddleware,
			},
			{
				path: 'my-progress/awards-badges',
				name: 'dashboard.awards',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Awards,
				beforeEnter: authMiddleware,
			},
			{
				path: 'my-progress/impact',
				name: 'dashboard.impact',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Impact,
				beforeEnter: authMiddleware,
			},
			{
				path: 'my-progress/leaderboards',
				name: 'dashboard.leaderboards',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Leaderboards,
				beforeEnter: authMiddleware,
			},
			{
				path: 'my-progress/scores-stats',
				name: 'dashboard.stats',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Stats,
				beforeEnter: authMiddleware,
			},
			{
				path: 'idea-log',
				name: 'dashboard.idea-log',
				meta: { layout: 'boxed', scrollToTop: true },
				component: IdeaLog,
				beforeEnter: authMiddleware,
			},
			{
				path: 'rater',
				name: 'dashboard.rater',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'My Ratings',
				},
				component: Rater,
				beforeEnter: authMiddleware,
				redirect: { name: 'dashboard.rater.open' },
				children: [
					{
						path: 'open-challenges',
						name: 'dashboard.rater.open',
						component: RaterOpen,
					},
					{
						path: 'my-challenges',
						name: 'dashboard.rater.my-challenges',
						component: RaterMyChallenges,
					},
					{
						path: 'closed-challenges',
						name: 'dashboard.rater.closed',
						component: RaterClosed,
					},
				],
			},
			{
				path: 'catalyst',
				name: 'dashboard.catalyst',
				meta: { layout: 'boxed', scrollToTop: false },
				component: RouterViewOnly,
				beforeEnter: authMiddleware,
				redirect: { name: 'dashboard.catalyst.open' },
				children: [
					{
						path: '',
						name: 'dashboard.catalyst.challenges',
						meta: {
							layout: 'boxed',
							scrollToTop: false,
							breadcrumb: {
								label: 'My Questions',
								to: { name: 'dashboard.catalyst.open', params: {} },
							},
						},
						redirect: { name: 'dashboard.catalyst.open' },
						component: Catalyst,
						children: [
							{
								path: 'open',
								name: 'dashboard.catalyst.open',
								component: CatalystOpen,
								redirect: { name: 'dashboard.catalyst.open.easy.challenges' },
								children: [
									{
										path: 'advanced-mode',
										name: 'dashboard.catalyst.open.advanced',
										component: RouterViewOnly,
										redirect: ({ params, query }) => {
											const { challenge, ...rest } = query;

											if (challenge) {
												return {
													name: 'dashboard.catalyst.detail',
													params: { ...params, challenge },
													query: rest,
												};
											}

											return { name: 'dashboard.catalyst.open.advanced.challenges' };
										},
										children: [
											{
												path: '',
												name: 'dashboard.catalyst.open.advanced.challenges',
												component: CatalystOpenAdvancedMode,
											},
											{
												path: 'detail/:challenge',
												name: 'dashboard.catalyst.detail',
												meta: {
													layout: 'boxed',
													scrollToTop: false,
													breadcrumb: [
														{
															label: 'My Questions',
															to: { name: 'dashboard.catalyst.open' },
														},
														({ name }) => {
															switch (name) {
																case 'dashboard.catalyst.detail.mentoring.idea':
																	return {
																		label: 'Question Details',
																		to: { name: 'dashboard.catalyst.detail.mentoring' },
																	};
																default:
																	return {
																		label: 'Question Details',
																		to: { name: 'dashboard.catalyst.detail.ideas' },
																	};
															}
														},
													],
												},
												component: CatalystDetail,
												redirect: { name: 'dashboard.catalyst.detail.ideas' },
												children: [
													{
														path: 'ideas',
														name: 'dashboard.catalyst.detail.ideas',
														component: CatalystDetailIdeas,
													},
													{
														path: 'edit',
														name: 'dashboard.catalyst.detail.edit',
														component: CatalystDetailEdit,
													},
													{
														path: 'mentoring',
														name: 'dashboard.catalyst.detail.mentoring',
														component: CatalystDetailMentoring,
													},
													{
														path: 'mentoring/:idea?',
														name: 'dashboard.catalyst.detail.mentoring.idea',
														component: CatalystDetailMentoringDetail,
														meta: { breadcrumb: 'Idea' },
													},
												],
											},
										],
									},
									{
										path: 'easy-mode',
										name: 'dashboard.catalyst.open.easy',
										meta: { scrollToTop: true, expanded: true },
										component: RouterViewOnly,
										redirect: ({ params, query }) => {
											const { challenge, ...rest } = query;

											if (challenge) {
												return {
													name: 'dashboard.catalyst.open.easy.details',
													params: { ...params, challenge },
													query: rest,
												};
											}

											return { name: 'dashboard.catalyst.open.easy.challenges' };
										},
										children: [
											{
												path: '',
												name: 'dashboard.catalyst.open.easy.challenges',
												component: CatalystOpenEasyModeChallenges,
												meta: { scrollToTop: true },
											},
											{
												path: 'detail/:challenge',
												name: 'dashboard.catalyst.open.easy.details',
												component: CatalystOpenEasyModeDetails,
												meta: { scrollToTop: true },
											},
										],
									},
								],
							},
							{
								path: 'closing',
								name: 'dashboard.catalyst.closing',
								component: CatalystClosing,
							},
							{
								path: 'drafts',
								name: 'dashboard.catalyst.drafts',
								component: CatalystDraft,
							},
							{
								path: 'suggestion-box',
								name: 'dashboard.catalyst.suggestion-box',
								component: RouterViewOnly,
								redirect: { name: 'dashboard.catalyst.suggestion-box.all' },
								meta: { scrollToTop: true, expanded: true },
								children: [
									{
										path: '',
										name: 'dashboard.catalyst.suggestion-box.all',
										component: CatalystSuggestionBoxAll,
									},
									{
										path: '',
										name: 'dashboard.catalyst.suggestion-box.open',
										component: CatalystSuggestionBoxOpen,
										children: [
											{
												path: ':challenge',
												name: 'dashboard.catalyst.suggestion-box.open.easy.details',
												component: CatalystSuggestionBoxOpenDetail,
												meta: { scrollToTop: (to, from) => to.name !== from.name, expanded: true },
											},

										]
									},
								],
							},
							{
								path: 'closed',
								name: 'dashboard.catalyst.closed',
								component: RouterViewOnly,
								redirect: { name: 'dashboard.catalyst.closed.all' },
								children: [
									{
										path: '',
										name: 'dashboard.catalyst.closed.all',
										component: CatalystClosedChallenges,
									},
									{
										path: 'detail/:challenge/closed',
										name: 'dashboard.catalyst.closed.detail',
										meta: {
											layout: 'boxed',
											scrollToTop: false,
											breadcrumb: [
												{
													label: 'My Questions',
													to: { name: 'dashboard.catalyst.closed', params: {} },
												},
												'Question Details',
											],
										},
										component: CatalystDetailClosed,
										redirect: { name: 'dashboard.catalyst.closed.detail.ideas' },
										children: [
											{
												path: 'ideas',
												name: 'dashboard.catalyst.closed.detail.ideas',
												component: CatalystDetailClosedIdeas,
											},
										],
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'mentor',
				name: 'dashboard.mentoring',
				meta: {
					layout: 'boxed',
					scrollToTop: false,
					breadcrumb: 'My Mentoring',
				},
				component: Mentoring,
				beforeEnter: authMiddleware,
			},
			{
				path: 'notifications',
				name: 'dashboard.notifications',
				meta: { layout: 'boxed', scrollToTop: true },
				component: Notifications,
				beforeEnter: authMiddleware,
				redirect: { name: 'dashboard.notifications.neurons-earned' },
				meta: { breadcrumb: ['My Progress', 'Activity'] },
				children: [
					{
						path: 'neurons-earned',
						name: 'dashboard.notifications.neurons-earned',
						component: NotificationsNeuronsEarned,
						meta: { breadcrumb: 'Neurons Earned' },
					},
					{
						path: 'highlights',
						name: 'dashboard.notifications.highlights',
						component: NotificationsHighlights,
						meta: { breadcrumb: 'Highlights' },
					},
					{
						path: 'helpful-tips',
						name: 'dashboard.notifications.helpful-tips',
						component: NotificationsHelpfulTips,
						meta: { breadcrumb: 'Helpful Tips' },
					},
				],
			},
			{
				path: 'test-idea',
				name: 'dashboard.test-idea',
				meta: { layout: 'boxed', scrollToTop: true },
				component: TestIdea,
				beforeEnter: authMiddleware,
			},
			{
				path: 'suggextion-box',
				name: 'dashboard.suggestion-box',
				meta: { layout: 'boxed', scrollToTop: true },
				component: SuggestionBox,
				beforeEnter: authMiddleware,
			},
			{
				path: 'money-prizes',
				name: 'dashboard.money-prizes',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'Money & Prizes',
				},
				component: MoneyPrizes,
				beforeEnter: authMiddleware,
			},
		],
	},

	// Discussions Forum routes
	{
		path: '/:user/discussion-forums',
		alias: '/:user/discussions',
		name: 'discussion-forums',
		meta: { layout: 'raw', scrollToTop: () => ({ el: '#discussion-forums-container', top: 60 }), breadcrumb: 'Forums' },
		component: DiscussionForums,
		redirect: { name: 'discussion-forums.topics' },
		children: [
			{
				path: 'highest-dope',
				name: 'discussion-forums.highest-dope',
				component: () => import('@/pages/discussion-forums/highest-dope/index.vue'),
			},
			{
				path: 'most-recent',
				name: 'discussion-forums.most-recent',
				component: () => import('@/pages/discussion-forums/most-recent/index.vue'),
			},
			{
				path: 'topics',
				name: 'discussion-forums.topics',
				component: RouterViewOnly,
				redirect: { name: 'discussion-forums.topics.all' },
				children: [
					{
						path: '',
						name: 'discussion-forums.topics.all',
						component: () => import('@/pages/discussion-forums/topics/index.vue'),
					},
					{
						path: 'new',
						name: 'discussion-forums.topics.new',
						component: () => import('@/pages/discussion-forums/topics/new/index.vue'),
						beforeEnter: authMiddleware,
					},
					{
						path: ':topicId',
						name: 'discussion-forums.topics.view',
						component: () => import('@/pages/discussion-forums/topics/view/index.vue'),
					},
				],
			},
			{
				path: 'in-challenges',
				name: 'discussion-forums.in-challenges',
				component: () => import('@/pages/discussion-forums/in-challenges/index.vue'),
			},
			{
				path: 'channel/:slug',
				name: 'discussion-forums.channel',
				component: () => import('@/pages/discussion-forums/channel/index.vue'),
				redirect: { name: 'discussion-forums.channel.topics.all' },
				children: [
					{
						path: '',
						name: 'discussion-forums.channel.topics.all',
						component: () => import('@/pages/discussion-forums/channel/topics/index.vue'),
					},
					{
						path: 'new',
						name: 'discussion-forums.channel.topics.new',
						component: () => import('@/pages/discussion-forums/channel/topics/new/index.vue'),
						beforeEnter: authMiddleware,
					},
					{
						path: ':topicId',
						name: 'discussion-forums.channel.topics.view',
						component: () => import('@/pages/discussion-forums/channel/topics/view/index.vue'),
					},
					{
						path: 'reply-queue',
						name: 'discussion-forums.channel.tasks',
						component: () => import('@/pages/discussion-forums/channel/tasks/index.vue'),
						beforeEnter: authMiddleware,
					},
				],
			},
			{
				path: 'category/:category',
				name: 'discussion-forums.category',
				component: () => import('@/pages/discussion-forums/category/index.vue'),
			},
			{
				path: 'my-frieds',
				name: 'discussion-forums.my-frieds',
				component: () => import('@/pages/discussion-forums/my-frieds/index.vue'),
			},
			{
				path: 'my-saved',
				name: 'discussion-forums.my-saved',
				component: () => import('@/pages/discussion-forums/my-saved/index.vue'),
			},
			{
				path: 'one-to-one',
				name: 'discussion-forums.one-to-one',
				component: () => import('@/pages/discussion-forums/one-to-one/index.vue'),
			},
			{
				path: 'mentions',
				name: 'discussion-forums.mentions',
				component: () => import('@/pages/discussion-forums/mentions/index.vue'),
				beforeEnter: authMiddleware,
			},
			{
				path: 'platform/:platformSlug',
				name: 'discussion-forums.platform',
				component: RouterViewOnly,
			},
		],
	},

	// Channel custom pages
	{
		path: '/:user/discussion-forums/member-invite',
		name: 'discussion-forums.channel.member-invite',
		meta: { layout: 'raw', scrollToTop: true },
		component: () => import('@/pages/discussion-forums/channel/member-invite/index.vue'),
		children: [
			{
				path: ':code',
				name: 'discussion-forums.channel.member-invite.invitation',
				component: () => import('@/pages/discussion-forums/channel/member-invite/Invitation.vue'),
			}
		],
	},

	{ // http://bignerve.local:4001/anteriovieira/discussion-forums/channel/aitoday/signup
		path: '/:user/discussion-forums/:slug/signup',
		name: 'discussion-forums.channel.signup',
		meta: { layout: 'raw', scrollToTop: true, hideHeader: true, hideFooter: true },
		component: () => import('@/pages/discussion-forums/channel/member-signup/index.vue'),
		children: [
			{
				path: '',
				name: 'discussion-forums.channel.signup.form',
				component: () => import('@/pages/discussion-forums/channel/member-signup/Signup.vue'),
			}
		],
	},

	// Platform routes
	{
		path: '/:platformSlug/olympiad/leaderboard/:race?',
		name: 'platform.olympiad.leaderboard',
		meta: {
			layout: 'wide',
			scrollToTop: (to, from) => {
				return from && from.name !== 'platform.olympiad.leaderboard'
			},
		},
		component: OlympiadLeaderboard,
	},
	{
		path: '/platform-leaderboard/all',
		name: 'platform.olympiad.leaderboard-old',
		meta: { layout: 'wide', scrollToTop: true },
		redirect: {
			name: 'platform.olympiad.leaderboard',
			params: { platformSlug: 'platform' },
		},
	},

	// Account routes...
	{
		path: '/my-account',
		component: RouterViewOnly,
		name: 'account.my-account',
		meta: { layout: 'boxed', scrollToTop: true, breadcrumb: 'My Account' },
		redirect: { name: 'account.my-account.main' },
		beforeEnter: authMiddleware,
		children: [
			{
				path: 'main',
				name: 'account.my-account.main',
				meta: { layout: 'boxed', scrollToTop: true, breadcrumb: 'My Account' },
				component: MyAccount,
				beforeEnter: authMiddleware,
			},
			{
				path: 'email-preferences',
				name: 'account.my-account.email-preferences',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: ['Email & Alert Preferences'],
				},
				component: EmailPreferences,
				beforeEnter: authMiddleware,
			},
			{
				path: 'platform-preferences',
				name: 'account.my-account.platform-preferences',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: ['Platform Preferences'],
				},
				component: PlatformPreferences,
				beforeEnter: authMiddleware,
			},
			{
				path: 'forum-preferences',
				name: 'account.my-account.forum-preferences',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: ['Forum Preferences'],
				},
				component: ForumPreferences,
				beforeEnter: authMiddleware,
			},
			{
				path: 'forum-tools',
				name: 'account.my-account.forum-tools',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: ['Forum Tools'],
				},
				component: ForumTools,
				beforeEnter: authMiddleware,
			},
			{
				path: 'security',
				name: 'account.my-account.security',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'Password & Security',
				},
				component: Security,
				beforeEnter: authMiddleware,
			},
			{
				path: 'social',
				name: 'account.my-account.social',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'Social Integrations',
				},
				component: Social,
				beforeEnter: authMiddleware,
			},
			{
				path: 'payment-wallets',
				name: 'account.my-account.payment-wallets',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'Payment Wallets',
				},
				component: PaymentWallets,
				beforeEnter: authMiddleware,
			},
			{
				path: 'custom-masthead',
				name: 'account.my-account.masthead',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'Your Masthead',
				},
				component: Masthead,
				beforeEnter: authMiddleware,
			},
			{
				path: 'organization',
				name: 'account.my-account.organization',
				meta: {
					layout: 'boxed',
					scrollToTop: true,
					breadcrumb: 'Your Organization',
				},
				component: Organization,
				beforeEnter: authMiddleware,
			},
			{
				path: 'profile',
				name: 'account.my-account.profile',
				meta: { layout: 'boxed', scrollToTop: true, breadcrumb: 'Your Bio & Profile' },
				component: Bio,
				beforeEnter: authMiddleware,
			},
		],
	},

	// Challenge routes...
	{
		path: '/challenges',
		redirect: {
			name: 'home.challenges',
		},
	},
	{
		path: '/challenges/create',
		name: 'challenges.create',
		meta: { layout: 'wide', scrollToTop: true },
		component: ChallengeCreate,
		beforeEnter: authMiddleware,
	},
	{
		path: '/challenges/test-idea',
		name: 'challenges.test-idea',
		meta: { layout: 'wide', scrollToTop: true },
		component: ChallengeCreate,
		beforeEnter: authMiddleware,
	},
	{
		path: '/challenges/:challenge',
		name: 'challenges.wrapper',
		meta: { layout: 'wide', scrollToTop: true },
		component: ChallengeWrapper,
		beforeEnter: modeMiddleware,
		children: [
			{
				path: '',
				name: 'challenges.show',
				meta: { layout: 'wide', scrollToTop: (to, from) => {
					return from && from.name !== 'challenges.show'
				}},
				component: ChallengeShow,
			},
			{
				path: 'survey',
				name: 'challenges.survey',
				meta: { layout: 'wide', scrollToTop: true },
				component: ChallengeShow,
			},
			{
				path: 'start-rate',
				name: 'challenges.start-rate',
				meta: { layout: 'wide', scrollToTop: true },
				component: RatingInit,
			},
			{
				path: 'idea-entry-fee',
				name: 'challenges.idea-entry-fee',
				meta: { layout: 'wide', scrollToTop: true },
				component: IdeaEntryFee,
			},
			{
				path: 'rate',
				name: 'challenges.rate',
				meta: { layout: 'wide', scrollToTop: true },
				component: ChallengeIdeaRating,
			},
			{
				path: 'rating-unlock',
				alias: 'unlock-rate',
				name: 'challenges.rating-unlock',
				meta: { layout: 'wide', scrollToTop: true },
				component: RatingUnlock,
			},
			{
				path: 'idea-unlock',
				name: 'challenges.idea-unlock',
				meta: { layout: 'wide', scrollToTop: true },
				component: IdeaUnlock,
			},
			{
				path: 'end',
				name: 'challenges.rating-end',
				meta: { layout: 'wide', scrollToTop: true },
				component: RatingEnd,
			},
			{
				path: 'idea/:id',
				name: 'challenges.show.idea',
				beforeEnter(to, from, next) {
					next({
						name: 'challenges.show',
						params: {
							challenge: to.params.challenge,
						},
						query: {
							...to.query,
							idea: to.params.id,
						},
					});
				},
			},
		],
	},

	// Sharing routes...
	{
		path: '/sharing',
		name: 'sharing',
		meta: { layout: 'wide', scrollToTop: true },
		component: Sharing,
		beforeEnter: authMiddleware,
	},
	{
		path: '/sharing-center',
		name: 'sharing-center',
		meta: { layout: 'wide', scrollToTop: true },
		component: SharingCenter,
		beforeEnter: authMiddleware,
	},

	// public routes

	// Wildcard routes
	{
		path: '/(.*)',
		redirect: ({ fullPath }) => {
			return {
				name: 'send-feedback',
				query: {
					about: 'Page not found',
					feedback: `Page: ${fullPath}`,
				},
			};
		},
		meta: { scrollToTop: true },
	},
];
