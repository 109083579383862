import { useQuery, useInfiniteQuery } from '@tanstack/vue-query';
import { computed, toValue } from 'vue';
import { api } from '@/modules/services';

export const useOlympiads = (slug, params = {}) => {
    const isEnabled = computed(() => !!toValue(slug));
    return useQuery({
        queryKey: ['widgets:olympiads', slug, params],
        queryFn: async () => {
            const { data } = await api.widgets.olympiads(
                'base_olympiad_id', {
                    platform_slug: slug.value !== 'platform' ? slug.value : 'bignerve',
                    ...toValue(params),
                }
            );

            return data;
        },
        enabled: isEnabled,
        refetchInterval: 1000 * 60, // 1 minute
    });
}

export const useChallenges = (platformSlug, params = { size: 50 }) => {
    const { data, ...passthrough } = useInfiniteQuery({
        queryKey: ['widgets:challenges', platformSlug, params],
        queryFn: async ({ page }) => {
            const { data: response } = await api.widgets.challenges(
                toValue(platformSlug) || 'bignerve',
                { ...toValue(params), page },
            );

            return response;
        },
        getNextPageParam: (lastResult) => {
            const { page, total } = lastResult.meta;

            if ((page + 1) * toValue(params).size >= total) {
                return undefined;
            }

            return page + 1;
        },
        initialPageParam: 0,
        shallow: true,
    });

    // reduce data to a single array
    const items = computed(() => data.value?.pages.reduce((acc, page) => {
        return acc.concat(page.data);
    }, []) || []);

    return {
        ...passthrough,
        data: items,
        meta: computed(() =>
            // get last page meta
            data.value?.pages[data.value.pages.length - 1]?.meta || {},
        ),
    };
}
