export default requester => {
    return {
        /**
         * Create survey.
         *
         * @param  {object}  params
         * @return {object}
         * @deprecated
         */
        create(payload = {}) {
            return requester.post('/surveys', payload);
        },
    };
}
