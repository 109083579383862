export default requester => {
    return {
        /**
         * Create feedback
         */
         feedback (resource, payload) {
             return requester.post(`/comments/suggestions/${resource}/feedback`, payload)
         }
    };
}
