<script setup>
import { shallowRef, ref, computed } from 'vue';
import { api } from '@/modules/services';

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    itemValue: {
        type: String,
        default: 'id',
    },
    itemLabel: {
        type: String,
        default: 'choice',
    },
    returnObject: {
        type: Boolean,
        default: false,
    },
    max: {
        type: Number,
        default: 3,
    },
});
const emit = defineEmits(['update:modelValue']);
const valueAccessor = (option) =>
    props.returnObject ? option : option[props.itemValue];

const loading = ref(false);
const options = shallowRef([]);
const selected = computed({
    get () {
        return props.modelValue;
    },
    set (value) {
        if (value.length > props.max) {
            return;
        }

        emit('update:modelValue', value);
    },
});

const fetchOptions = async () => {
    if (options.value.length) {
        return;
    }

    loading.value = true;

    const { data: response } = await api.game.mentoringChoices();

    options.value = response.data;
    loading.value = false;
}

fetchOptions();
</script>

<script>
export default {
    name: 'QuickFeedback',
}
</script>

<template>
    <div v-if="loading" class="mt-2 grid grid-cols-2 sm:grid-cols-3 gap-2 animate-loading">
        <div v-for="i of 9" :key="i" class="min-h-[2.5rem] bg-dust-200"></div>
    </div>
    <ul v-else class="mt-2 grid grid-cols-2 sm:grid-cols-3 gap-2 list-none p-0">
        <li v-for="(option, index) of options" :key="index" v-tooltip="{ content: `<div class='max-w-[20rem]'>${option.description}</div>`, html: true, delay: { show: 1000, hide: 0 } }">
            <input v-model="selected" :value="valueAccessor(option)" type="checkbox" class="peer hidden" :id="`feedback-option-${index}`">
            <div :class="selected.length >= max ? 'peer-[:not(:checked)]:disabled bg-dust-200' : 'bg-dust-50 hover:bg-mentor-100'" class="rounded-md peer-checked:bg-mentor-200 peer-checked:font-bold">
                <label :for="`feedback-option-${index}`" class="select-none min-h-[2.5rem] flex items-center justify-center hover:cursor-pointer">
                    {{ option[itemLabel] }}
                </label>
            </div>
        </li>
    </ul>
</template>
