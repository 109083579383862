<script>
export default {
    name: 'RolesPicker',

    props: {
        modelValue: {
            type: Object,
        },
        initialRole: {
            type: String,
        },
        hide: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    emits: ['update:modelValue', 'clear', 'change'],

    data () {
        return {
            menu: false,
            roles: [
                { icon: 'brain', label: 'Nerve Score', slug: 'overall', color: 'text-innovator' },
                { icon: 'flame', label: 'Catalyst', slug: 'catalyst', color: 'text-catalyst' },
                // { icon: 'lightbulb', label: 'Innovator', slug: 'innovator', color: 'text-innovator' },
                { icon: 'mentor', label: 'Mentor', slug: 'mentor', color: 'text-mentor' },
                { icon: 'binoculars-solid', label: 'Scout', slug: 'scout', color: 'text-scout' },
                { icon: 'alright', label: 'Analyst Rater', slug: 'analyst', color: 'text-rater' },
                { icon: 'director', label: 'Director', slug: 'director', color: 'text-director' },
            ]
        }
    },

    computed: {
        selected () {
            if (!this.modelValue) {
                return this.roles[0];
            }

            return this.roles.find(role => role.slug === this.modelValue.slug);
        },
        availableRoles () {
            return this.roles
                .filter(role => !this.hide.includes(role.slug))
                .filter(role => this.selected ? role.slug !== this.selected.slug : true);
        }
    },

    methods: {
        clear () {
            this.$emit('update:modelValue', null)
            this.$emit('change', null);
            this.$emit('clear', null)
        },
        select (role) {
            this.$emit('update:modelValue', role);
            this.$emit('change', role);

            this.menu = false;
        }
    },

    mounted() {
        if (this.initialRole) {
            const role = this.roles.find(
                role => role.slug === this.initialRole
            );

            this.$emit('update:modelValue', role);
            this.$emit('change', role);
        }
    }
}
</script>

<template>
    <NDropdownMenu v-model="menu" class="w-full p-0 mb-0 sm:min-h-[40px] sm:w-[230px]">
        <template #activator="{ on }">
            <button aria-label="button" :disabled="disabled" :class="{ 'cursor-not-allowed opacity-50': disabled }" class="flex items-center justify-between text-md overflow-hidden whitespace-nowrap" style="width: 100%" v-on="on">
                <transition
                    v-if="selected"
                        enter-active-class="transition-transform duration-300"
                        leave-active-class="transition-transform duration-300"
                        enter-from-class="transform translate-y-7"
                        enter-to-class="transform translate-y-0"
                        leave-from-class="transform translate-y-0"
                        leave-to-class="transform -translate-y-7"
                        mode="out-in"
                    >
                    <span :key="selected.slug" class="flex-1 flex items-center">
                        <span class="font-semibold mr-2">{{ $t('component.game.roles-piker.role') }}:</span>
                        <NIcon :as="selected.icon" :class="selected.color" class="text-lg w-8" />
                        <span v-if="selected.label" :class="selected.color" class="font-semibold" >
                            {{ selected.label }}
                        </span>
                    </span>
                </transition>
                
                <span v-else>
                    {{ $t('component.game.roles-piker.select') }}
                </span>
                <NIcon v-if="!disabled" v-tooltip="menu ? 'Close' : 'Select'" :as="menu ? 'arrow-circle-up' : 'arrow-circle-down'" right />
            </button>
        </template>

        <ul class="w-full p-0 mt-1">
            <li v-for="(role, index) of availableRoles" :key="index" :class="selected && selected.slug === role.slug ? `font-semibold ${role.color}` : 'text-dust-800 hover:bg-dust-100 hover:text-dust-800'" class="flex flex-grow items-center pl-10 pr-4 py-2 cursor-pointer text-md" @click="select(role)">
                <NIcon :as="role.icon" class="text-lg w-8 text-center" />
                <span>{{ role.label }}</span>
            </li>
        </ul>
    </NDropdownMenu>
</template>
