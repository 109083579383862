<script>
import useChallengeDetails from '@/mixins/ChallengeDetails';
import RatingBar from '@/components/idea/RatingBar.vue';
import RatingEnd from '@/components/idea/RatingEnd.vue';
import RatingCard from '@/components/idea/RatingCard.vue';
import RatingForm from '@/components/idea/RatingForm.vue';
import EntryFeeSuccessMessage from '@/components/idea/EntryFeeSuccessMessage.vue';
import RatingIdeaCard from '@/components/idea/RatingIdeaCard.vue';
import MentoringToRate from '@/components/idea/MentoringToRate.vue';
import ChallengeRating from '@/components/challenge/Rating.vue';
import NModal from '@/components/NModal.vue';
import Cookie from '@/utils/Cookie';
import { Loading as NNerveLoading } from '@bignerve/ui-core';
import { oldApi as nerve } from '@/modules/services';
import { crossPlatformState } from '@/utils/App';

const tourState = crossPlatformState('bignerve.tour');
const accountURL = import.meta.env.VUE_APP_ACCOUNT_URL;

export default {
    mixins: [useChallengeDetails()],

    props: {
        challenge: Object,
        default: () => ({}),
    },

    components: {
        RatingBar,
        RatingEnd,
        RatingCard,
        RatingForm,
        RatingIdeaCard,
        MentoringToRate,
        ChallengeRating,
        NModal,
        NNerveLoading,
        EntryFeeSuccessMessage,
    },

    async beforeRouteEnter ({ params, fullPath, query }, _ , next) {
        try {
            const { data: flow } = await nerve.challenges.startRating(params.challenge);
            const { data: idea } = await nerve.challenges.ideaToRate(params.challenge)

            next((vm) => {
                vm.flow = flow;
                vm.idea = idea.data;
            });
        } catch (err) {
            if (err.response && err.response.status === 401 && !query.token) {
                const continueURL = encodeURIComponent(`${location.origin}${fullPath}`);
                const redirect = `${accountURL}/gateway?continue=${continueURL}`;
                
                location.replace(redirect)

                next('/')
                
                return 
            }

            let feedback = `\n\nchallenge: ${params.challenge};\n`;
            const { errors } = (err.response && err.response.data) || { errors: [] };

            if (errors.length) {
                feedback += `${errors.map(c => c.error).join(';\n')}`;
            }

            next({
                name: 'send-feedback',
                query: { about: 'Challenge - Rating Flow', feedback }
            });
        }
    },

    data() {
        return {
            rating: {},
            loading: false,
            busy: false,
            idea: null,
            flow: {},
            interval: null,
            ratedIdeas: 0,
            mentoring: false,
            processingMentoringRating: false,
            autoScroll: Cookie.get('mentoring:auto-scroll') === 'false' ? false : true,
            entryFeeSuccessMessage: null,
        };
    },

    computed: {
        status () {
            const ideas = this.rating.ideas || this.flow.ideas || [];
            const totalUserRating = this.flow.total_rating;
            const leftRating = this.rating.left_ratings !== undefined ? this.rating.left_ratings : this.flow.left_ratings;
            const totalUserIdeas = this.flow.total_ideas;
            const unlockedIdeas = ideas.reduce((t, i) => (i.unlocked && t++, t), 0)
            const lockedIdeas = ideas.length - unlockedIdeas;
            const challengeIdeas = this.flow.total_challenge_ideas;
            const ratableIdeas = challengeIdeas - unlockedIdeas;
            const availableToRate = ratableIdeas - this.flow.total_rating;

            return {
                leftRating,
                totalUserRating,
                totalUserIdeas,
                lockedIdeas,
                unlockedIdeas,
                challengeIdeas,
                ratableIdeas,
                availableToRate,
                ratingIdeas: this.flow.total_rating,
            }
        },
        showChallengeRating () {
            if (!this.status) {
                return false;
            }

            const { user_stats, catalyst } = this.challenge;

            // all checks should be true
            const checks = [
                // check if the user is not the owner of the challenge
                catalyst.id !== this.$auth.user.id,
                // check if it is not a caption
                !this.isCaption,
                // if the user can't rate, don't show
                user_stats.can_rate_challenge,
                // if the user has not rated the challenge
                !user_stats.rated,
                // all the rater's ideas need to be unlocked
                !this.status.lockedIdeas,
                // if the challenge has less than 3 ideas to rate, don't show
                // if ideas = 3 to rate, put after last idea
                this.status.ratableIdeas >= 3,
                // If user put in no ideas, after they rated 3+ ideas, or insert after the last idea.
                !user_stats.total_ideas ? user_stats.total_rating > 3 : true,
                // if challenge has > 20 ideas, show after the 20th idea (position 21)
                this.status.challengeIdeas > 20
                    ? this.status.ratingIdeas === 21 : true,
            ]

            if (checks.some(c => !c)) {
                return false;
            }

            return true;
        },
    },

    watch: {
        autoScroll (value) {
            Cookie.set('mentoring:auto-scroll', value, true, 365);
        },
        status (status) {
            if (tourState['onboarding-guide']) {
                return;
            }

            const checks = [
                !status.lockedIdeas && !!status.totalUserIdeas,
                // If user put in no ideas, after they rated 3+ ideas, or insert after the last idea.
                !status.totalUserIdeas && status.totalUserRating >= 5,
                // if the user rated all ideas
                !status.leftRating,
            ];

            if (checks.some(ok => ok)) {
                this.$bus('tour:next', {
                    guide: 'start-guide',
                    scrollToTop: true,
                    delay: 2000,
                    payload: this.challenge
                });
            }
        },
        challenge ({ catalyst }) {
            this.$store.dispatch('app/user', {
                user: catalyst.handle,
            });
        },
    },

    methods: {
        async nextIdea() {
            this.$bus('challenge:rating:next-idea');

            if (this.idea) {
                this.ratedIdeas++;
            }

            try {
                this.loading = true;

                const { data: idea } = await this.$nerve.challenges.ideaToRate(
                    this.challenge.slug
                );

                this.changeIdea(idea.data);
            } catch (err) {
                this.idea = null;
            } finally {
                this.loading = false;
            }
        },
        changeIdea (idea, force = false) {
            if (idea || force) {
                this.idea = idea;
            } else {
                // await ratings animations
                setTimeout(() => {
                    this.idea = null;
                }, 2500)
            }
        },
        async updateFlow () {
            this.loading = true;

            const { data: flow } = await this.$nerve.challenges.getRatingFlow(
                this.challenge.slug
            );

            this.flow.total_rating = flow.total_rating;
            this.flow.total_challenge_ideas = flow.total_challenge_ideas;
            this.challenge.stats.total_ideas = flow.total_challenge_ideas;
            this.challenge.user_stats.total_rating = this.challenge.user_stats.total_rating + 1;
            this.loading = false;
        },
        async rated ({ data, commented }) {
            // update flow data
            this.flow.total_rating++;
            this.flow.ideas = data.ideas;

            await this.updateFlow();

            this.rating = data;
            this.scrollToIdeaRating();

            if (commented && this.idea.stats.mentoring_to_rate) {
                this.$refs.modalMentoring.open();

                return;
            }

            this.checkNextIdea();
        },
        checkNextIdea () {
            if (!this.status.availableToRate) {
                this.changeIdea(null, true);
                return;
            }

            this.nextIdea();
        },
        scrollToIdeaRating () {
            const el = this.$refs.ideaScrollPosition;

            el?.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        },
        scrollToEntryFeeSuccessMessage () {
            const comp = this.$refs.entryFeeSuccessMessage;

            comp?.$el?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        },

        mentoringRatingDone () {
            this.processingMentoringRating = false;
            this.checkNextIdea();
            this.$bus('challenge:mentoring-rating:done');
        },
    },

    mounted () {
        setTimeout(() => {
            if(this.$route.query.payment_id) {
                this.scrollToEntryFeeSuccessMessage();
            } else {
                this.scrollToIdeaRating();
            }
        }, 300);
    },
}
</script>

<template>
    <div>
        <div v-if="!idea">
            <EntryFeeSuccessMessage v-if="$route.query.payment_id" :payment_id="$route.query.payment_id" :challenge="challenge" ref="entryFeeSuccessMessage" class="mb-5 sm:w-[80%] sm:mx-auto" />
            
            <RatingEnd :challenge="challenge" :flow="flow" />
        </div>
        
        <div v-else-if="idea && flow.can_rate_idea" >
            <RatingCard :challenge="challenge" class="mb-5" />

            <EntryFeeSuccessMessage v-if="$route.query.payment_id" :payment_id="$route.query.payment_id"  :challenge="challenge" ref="entryFeeSuccessMessage" class="mb-5 sm:w-[80%] sm:mx-auto" />

            <RatingBar v-if="!($responsive.sm && ratedIdeas >= 2)" :flow="flow" :rating="rating" :status="status" />

            <div ref="ideaScrollPosition" class="pb-14 -mt-14"></div>

            <div v-if="showChallengeRating" class="flex flex-col items-center gap-10 sm:flex-row sm:items-start sm:gap-20 justify-center bg-rater-100 p-8 rounded-sm">
                <div class="">
                    <h2 class="text-rater mb-8">You can help our community ask better questions!</h2>
                    <p>Is this Challenge question:</p>
                    <ul type="" class="pl-4">
                        <li>Relevant and interesting?</li>
                        <li>Analyzed well, identifies the core issue, and uses accurate assumptions?</li>
                        <li>Is it clear, well-written and engaging to you?</li>
                    </ul>
                </div>
                <ChallengeRating :challenge="challenge" class="w-full sm:w-80 shadow-lg" />
            </div>

            <template v-else-if="flow.total_rating <= flow.total_challenge_ideas">
                <div class="grid grid-cols-12 gap-4 relative">
                    <div v-show="loading || mentoring" class="absolute inset-0 flex items-center justify-center z-10">
                        <NNerveLoading large pulse />
                    </div>
                    <div class="col-span-12 sm:col-span-5 lg:col-span-6" :class="{ 'is-disabled': loading || mentoring }">
                        <Transition name="entered" mode="out-in">
                            <RatingIdeaCard
                                :key="idea.id"
                                :idea="idea"
                                :challenge="challenge"
                            />
                        </Transition>
                    </div>
                    <div class="col-span-12 sm:col-span-7 lg:col-span-6 mt-3 sm:mt-0" :class="{ 'is-disabled': loading || mentoring }">
                        <RatingForm :flow="flow" :idea="idea" save-text="Save & Rate Next Idea" @success="rated">
                            <template #footer>
                                <p v-if="status.availableToRate > 0" class="text-dust-700 text-center">
                                    <b>{{ status.availableToRate }} {{ status.availableToRate > 1 ? 'ideas' : 'idea' }} to go!</b>
                                    <br><i @click="scrollToIdeaRating">You can rate more if you’d like.</i>
                                </p>
                            </template>
                        </RatingForm>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-4 mt-12">
                    <div class="col-span-12 sm:col-span-10 sm:col-start-2 sm:col-end-12 lg:col-span-6 lg:col-start-2 lg:col-end-12">
                        <div class="info-box">
                            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
                            <div class="info-box__content">
                                <p>
                                    <b>Tip: </b> This is a double-blind rating &amp; mentoring system; you won’t know who posted an idea you're rating until the challenge ends, to avoid bias or group think. Your own idea(s) is going to be rated in this same way, and you can get valuable mentor feedback to hone your thinking. "Rating well" means judging whether the idea fulfills the Catalyst's criteria, not just your personal opinion.
                                </p>
                             <!--
                             {{ platform+title }} 
                                <p>
                                Coming soon: know which Innovators to spend the most time mentoring by checking their "responsiveness meter" above. (Users who don’t have a scale yet are earning their way to a rating, so help them out with mentoring.)
                                </p>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div v-else class="h-[10vh] flex items-center justify-center rounded-lg text-analyst bg-analyst-100 text-md">
                <p class="m-0"> <NIcon as="exclamation-circle-solid" /> We are processing the challenge ideas and soon you will be able to start rating them</p>
            </div>
        </div>

        <div v-else>
            <div class="animate-pulse">
                <div class="flex-1">
                    <div class="h-40 bg-dust-400 mb-6"></div>
                    <div class="h-40 bg-dust-700 mb-12"></div>
                    <div class="h-4 bg-rater w-2/6 mb-6"></div>
                </div>
            </div>
            <div class="animate-pulse flex space-x-8">
                <div class="flex-1">
                    <div class="h-40 bg-dust-400"></div>
                </div>
                <div class="flex-1">
                    <div class="h-64 bg-dust-400"></div>
                </div>
            </div>
        </div>

        <NModal ref="modalMentoring" #default="{ close }" @close="mentoringRatingDone">
            <div class="border-2 border-mentor rounded-lg overflow-hidden m-5 bg-mentor max-w-5xl max-h-screen">
                <div class="h-4"></div>
                <div class="bg-white p-2 p-sm-4">
                    <div class="flex flex-col sm:flex-row p-2 mb-4">
                        <div>
                            <h3 class="rating-form__help-text text-mentor font-bold">
                                {{ $t('component.idea.mentoring-to-rate.title') }}
                            </h3>
                            <p class="text-mentor-400">
                                {{ $t('component.idea.mentoring-to-rate.subtitle') }}
                            </p>
                        </div>
                        <div class="flex items-center sm:items-end space-x-2 space-y-2 sm:flex-col ml-4">
                            <button aria-label="button"
                                type="button"
                                class="hover:bg-dust-100 shadow-sm transition ease-in-out duration-100 self-end whitespace-nowrap py-2 px-3 flex items-center bg-white border border-gray-300 text-dust-700 font-semibold rounded-md"
                                @click="close"
                            >
                                <NIcon as="times-circle-regular" left />
                                {{ $t('component.idea.mentoring-to-rate.action.skip') }}
                            </button>
                            <button aria-label="button"
                                type="button"
                                class="hover:bg-mentor-600 shadow-sm transition ease-in-out duration-100 self-end whitespace-nowrap py-2 px-3 flex-items-center bg-mentor border-mentor text-white font-semibold rounded-md"
                                :disabled="processingMentoringRating"
                                @click="() => $refs.mentoringToRate.save()">
                                <NIcon as="mentor" left />
                                {{ !processingMentoringRating ? $t('component.idea.mentoring-to-rate.action.save') : $t('common.processing') }}
                            </button>
                        </div>
                    </div>
                    <div class="overflow-x-hidden overflow-y-auto sb-snug sb-dust-400 sb-rounded pr-2 max-h-[50vh]">
                        <MentoringToRate
                            ref="mentoringToRate"
                            :idea="idea"
                            :challenge="challenge"
                            :auto-scroll="autoScroll"
                            @done="() => $refs.modalMentoring.close()"
                            @processing="s => processingMentoringRating = s" />
                    </div>
                    <div class="flex justify-end pt-3 pr-2">
                        <NSwitch v-model="autoScroll" active-color="mentor" label="Auto scroll" icon />
                    </div>
                </div>
            </div>
        </NModal>
    </div>
</template>
