<script setup>
import CategoriesPicker from '@/components/game/CategoriesPicker.vue';
import Button from '@/components/ui/button/index.vue';
import NNote from '@/components/NNote.vue';
import NLoadingNerve from '@/components/NLoadingNerve.vue';
import { omit } from 'lodash';
import { Icon } from '@bignerve/ui-core';
import { api } from '@/modules/services';
import { ref, watch } from 'vue';
import { useUserPlatform } from '@/composable/platform';
import ChannelItem from '@/components/forum-tools/ChannelItem.vue';

const CHANNEL_MODE = {
    PRIVATE: 'private',
    PUBLIC: 'public'
}

const CHANNEL_SCOPE = {
    MEMBERS_ONLY_FREE: 'members_only_free',
    MEMBERS_ONLY_PAID: 'members_only_paid',
    BIGNERVE_SUBSCRIBERS: 'bignerve_subscribers',
    MINIMUM_COMMENTATOR_SCORE: 'minimum_commentator_score',
}

const SOURCE_TYPE = {
    CATEGORY: 'category',
}

const { platform } = useUserPlatform();
const channels = ref([]);
const category = ref(null);
const loading = ref(true);
const creating = ref(false);

const fetchChannels = async () => {
    try {
        loading.value = true;
        const { data: result } = await api.channel.list(platform.value?.slug);

        // replace current list with new data
        channels.value = result.data;
    } finally {
        loading.value = false;
    }
}

const handleAddChannel = async (target) => {
    if (!target) return;

    const newChannel = {
        id: null,
        channel_mode: CHANNEL_MODE.PUBLIC,
        channel_scope: CHANNEL_SCOPE.MEMBERS_ONLY_FREE,
        platform: platform.value?.slug,
        source_type: SOURCE_TYPE.CATEGORY,
        source_id: target.reference,
        source: omit(target, ['children', 'parent']),
        name: target.name,
        icon: target.icon,
        invitation_data: {
            subject: '',
            message: '',
            reply_to: '',
        },
    };

    creating.value = true;

    const { data: result } = await api.channel.create(platform.value.slug, newChannel)

    channels.value = [...channels.value, result.data];
    creating.value = false;
}

watch(platform, () => {
    if (!platform.value) {
        return;
    }

    fetchChannels();
}, { immediate: true });
</script>

<template>
    <div class="page pb-40">
        <NPageHeader
            title="Forum Tools"
            subtitle="Manage your forum content and members"
            icon="discussion-icon"
        />

        <p>
            The BigNerve forum software gives you options for creating <b>categories</b> of content that can be locked to different types of members-only access. Here you can customize your content for your forums.
        </p>

        <NDataForm class="form-groups">
            <div class="form-groups">
                <div class="page__content mb-6">
                    <h3 class="text-xl mb-2"><Icon as="th-solid" class="mr-2" />Custom Channels</h3>

                    <div class="form-group">
                        <div class="flex items-start justify-between space-x-8 w-full">
                            <p class="form-block-help-text">
                                You can designate a few custom channels in your forum for specific <b>categories</b> of topics. You can also attach an RSS podcast or article feed to a channel.
                            </p>
                        </div>

                        <div v-if="loading" class="w-full items-center justify-center flex h-40">
                            <NLoadingNerve />
                        </div>

                        <template v-else>
                            <div class="grid divide-y mb-4 w-full">
                                <ChannelItem
                                    v-for="(channel, i) in channels"
                                    :key="channel.id"
                                    :channel="channel"
                                    :platform="platform"
                                    :class="i !== 0 ? 'py-8' : 'pb-8'"
                                />
                            </div>

                            <div v-if="channels.length > 0" class="border-b w-full my-8"></div>

                            <CategoriesPicker v-model="category" @closed="handleAddChannel">
                                <template #activator="{ on }">
                                    <Button v-on="on" class="h-10 gap-2 font-bold shadow-active rounded-xl min-w-[180px]" type="button">
                                        <Icon :as="channels.length === 0 ? 'th-solid' : 'add-circle'" />
                                        {{ creating ? ('Processing...') : (channels.length === 0 ? 'Choose Category' : 'Add Category') }}
                                    </Button>
                                </template>
                            </CategoriesPicker>
                        </template>

                    </div>
                </div>
            </div>
        </NDataForm>

        <NNote title="">
            <p>Community discussions are an important part of exploring problems, sparking imagination, and discovering innovative solutions. BigNerve gives you two areas to discuss: a <b>general forum</b> with channels and topics, and idea-focused <b>discussion boards</b> attached to specific challenge questions.</p>

            <p>A challenge's discussion board can be public or private/gated, depending on the challenge. Your public forum has public channels and can also have private/gated channels.  A custom channel is dedicated to specific groups or topic categories (e.g.  only your followers, subscribers, a special offline club,  a specific idea tournament, etc.) and can be private or public. </p>

            <p>You have full control over who to mute or ban from your forums and who can create topics or not. You can also restrict participation by their Nerve Score. <a href="/faq" class="italic underline text-inherit">[FAQ link]</a></p>

            <p>Also, all public discussions "roll up" to the main BigNerve <RouterLink :to="{ name: 'discussion-forums.most-recent', params: { user: platform?.slug || 'platform' } }" class="underline text-inherit">forum front page</RouterLink> to help everyone discover great conversations across all users and topics. </p>
        </NNote>
    </div>
</template>
