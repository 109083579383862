export default requester => {
    return {
        /**
         * Send email
         *
         * @param {object} handle
         **/
         send (body, params = {}) {
            return requester.post('/admin/mailer/message', body, { params });
        }
    };
}
