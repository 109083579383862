<script setup>
import UploadFile from '@/components/form/File.vue';
import ImagePreview from './Display.vue';
import { useVModel } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: null,
  },
  metadata: {
    type: Object,
    required: false,
    default: () => ({}),
  },
})

const emit = defineEmits(['update:modelValue', 'update:metadata'])

const image = useVModel(props, 'modelValue', emit, {
  passive: true,
  defaultValue: props.modelValue,
});

const updateMetadata = (key, value) => {
  props.metadata[key] = value;

  emit('update:metadata', props.metadata);
}
</script>

<template>
  <div>
    <div v-if="image">
      <ImagePreview :modelValue="image" :metadata="metadata" class="!aspect-image" />
      <input
        :value="metadata.alt"
        type="text" class="w-full py-1 text-sm placeholder:text-dust-400"
        placeholder="Write a brief description of this image for readers with visual impairments (optional)"
        @input="(e) => (updateMetadata('alt', e.target.value))"
      />
    </div>
    <UploadFile
      v-else
      v-model="image"
      :truncate="10"
      :border="false"
      :accept="['image/jpg', 'image/png', 'iamge/gif']"
      name="block-image"
      class="bg-dust-50 rounded-lg"
    />
  </div>
</template>