<script>
import moment from 'moment';
import random from 'lodash/random';
import RolesPicker from '@/components/game/RolesPicker.vue';
import { useInterval } from '@/mixins/Interval';
import { ProgressLineChart } from '@bignerve/ui-charts';
import { rankColors, roleColors, series } from './neurons-earned-data';
import { scaleLinear } from 'd3';

const yes = (p = 0.5) => Math.random() < p
const mockPoints = serie => {
    const previewSerie = serie.history[serie.history.length - 1] || {points: 0 };

    return yes()
        ? previewSerie.points + Math.floor(Math.random() * 1000)
        : previewSerie.points;
}

export default {
    name: 'NeuronsEarnedDemo',
    mixins: [
        useInterval({
            handler: 'nextTick',
            immediate: false,
            ms: 100,
        }),
    ],
    props: {
        control: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ProgressLineChart,
        RolesPicker,
    },
    data () {
        return {
            role: null,
            step: 0,
            series,
            rankColors,
            roleColors,
        }
    },
    watch: {
        '$auth.user': {
            handler(newUser, oldUser) {
                if(newUser) {
                    this.series[0].user = newUser;
                }

                if (!newUser || newUser !== oldUser) {
                    this.reset();
                }
            },
            immediate: true,
        },
    },
    computed: {
        chartOptions () {
            const role = this.role ? this.role.slug : 'overall';

            return {
                colors: rankColors,
                marks: {
                    lineColor: roleColors[role],
                },
                xLabels: {
                    format: (d, x) => {
                        return moment(d).format('MMMM');
                    },
                },
                height: this.$responsive.sm && 500,
            };
        },
        historical () {
            return this.series.map((d) => ({
                ...d,
                highlighted: d.user.screen_name === this.$auth.user.screen_name,
            }))
        }
    },
    methods: {
        nextTick (step) {
            const serieScale = scaleLinear()
                .domain([0, 100])
                .range([0, 10])
                .nice();
                
            this.step = this.step + 1;

            // code
            this.series = this.series.map((serie, i) => {
                const mockedPoints = mockPoints(serie);
                let level = yes(0.08) ? random(serie.level, this.step) : serie.level;

                if (i === 0 && this.step > 92) {
                    level = 100;
                }

                serie.level = level;
                serie.rank = serieScale(this.step);
                serie.score = mockedPoints;
                serie.history.push({
                    date: moment().utc().add(this.step, 'days').unix(),
                    points: mockedPoints,
                    level: level,
                });

                return serie;
            })

            if (this.step >= 100) {
                this.intervalStopUpdater();
            }
        },
        clear () {
            this.step = 0;
            this.series = this.series.map(serie => {
                serie.history = [{
                    date: moment().utc().subtract(1, 'day').unix(),
                    points: 0,
                    level: 0,
                }, {
                    date: moment().utc().unix(),
                    points: 0,
                    level: 0,
                }];
                serie.rank = 0;
                serie.level = 0;

                return serie;
            })
        },
        reset () {
            this.clear();

            this.intervalStopUpdater();
            this.instervalStartUpdater();
        }
    },
}
</script>

<template>
    <div>
        <RolesPicker v-model="role" @input="reset" />
        
        <ProgressLineChart
            :series="historical"
            :options="chartOptions"
            class="progress-line-chart w-full h-150"
        />

        <div v-if="control" class="flex items-center ml-7">
            <button aria-label="button" v-if="step === 100" type="button" class="border rounded-md w-10 h-8 flex items-center justify-center text-dust-700 hover:bg-dust-200" @click="reset">
                <svg class="w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="redo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"></path></svg>
            </button>
            <button aria-label="button" v-else-if="!intervalUpdater" type="button" class="border rounded-md w-10 h-8 flex items-center justify-center text-dust-700 hover:bg-dust-200" @click="$bus('game-dash:state', 'closed'); instervalStartUpdater()">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/></svg>
            </button>
            <button aria-label="button" v-else type="button" class="border rounded-md w-10 h-8 flex items-center justify-center text-dust-700 hover:bg-dust-200" @click="intervalStopUpdater">
                <svg  class="w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pause" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z"></path></svg>
            </button>
            <input :value="step" type="range" id="level" name="level" min="0" max="100" class="rounded-lg appearance-none bg-dust-200 h-2 min-w-40 outline-none range-progre mx-3 w-full disabled" @input="e => nextTick(e.target.value)" >
        </div>
    </div>
</template>

<style lang="scss">
  input[type='range'].range-progre::-webkit-slider-thumb {
    width: 15px;
    height: 15px;
    @apply bg-dust-600;
    @apply border-2;
    @apply border-white;
    @apply z-10;
    @apply cursor-pointer;
    @apply appearance-none;
    @apply rounded-md;
  }
</style>