<script>
export default {
    name: 'NNavbarNotification',

    props: {
        immediate: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            isOpen: false,
            resolve: null,
            payload: null,
        };
    },

    methods: {
        open (payload) {
            this.isOpen = true;
            this.payload = payload;

            return new Promise((resolve) => {
                this.resolve = resolve;
            })
        },

        async close () {
            this.isOpen = false;

            if (this.resolve) {
                this.resolve(this.payload);
            }
        },
    },

    mounted () {
        if (this.immediate) {
            this.open();
        }
    }
}
</script>
<template>
    <Teleport v-if="isOpen" to="#navbar-notifications">
        <slot :close="close" />
    </Teleport>
</template>