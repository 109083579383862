<script>
import getTweetLength from 'twitter-text/dist/getTweetLength';
import NLazyImage from '@/components/NLazyImage.vue';

export default {
    name: 'TwitterPreview',

    props: {
        content: {
            type: Object,
            required: true,
        },
    },

    components: { NLazyImage },

    emits: ['done', 'cancel', 'success'],

    data () {
        return {
            shared: false,
            hasError: false,
        };
    },

    computed: {
        charactersRemaining () {
            return 280 - getTweetLength(this.content.text);
        }
    },

    methods: {
        onDone (e) {
            if (!e.data.shared) {
                this.hasError = true;

                return;
            }
            
            this.shared = true;

            this.$emit('success', e);
            
            setTimeout(() => {
                this.$emit('done', e);
            }, 3000);
        },
        onError () {
            this.hasError = true
        },
    },

    mounted () {
        this.shared = false;
    }
}
</script>

<template>
    <div class="overflow-y-scroll overflow-x-hidden max-h-180">
        <NDataForm :action="`/sharing/share/twitter`" :data="content" method="POST" #default="{ busy }" @error="onError" @success="onDone">
            <div class="flex flex-col gap-3 relative">
                <div class="flex gap-3 min-w-[90vw] sm:min-w-[40rem]">
                    <div v-if="content.thread.length" class="h-full w-0.5 bg-dust-200 absolute left-5 hidden sm:block"></div>
                    <NAvatar :src="$auth.user.image" large class="z-1 hidden sm:block" />
                    <div class="flex-1">
                        <label class="form-group__label text-dust-700">Tweet Text</label>
                        <NTextarea
                            v-model="content.text"
                            :force-error="charactersRemaining < 0"
                            rows="5" size="small"
                            class="rounded-md !outline-none !ring-0"
                            @change="hasError = false"
                        />
                        <div class="flex items-center justify-between py-1 px-1">
                            <small class="text-dust-700">
                                You can edit the text before sharing.
                            </small>
                            <small :class="{ '!text-orange-400': charactersRemaining < 10 && charactersRemaining > 0, '!text-red-400': charactersRemaining < 0 }" class="font-semibold text-dust-600">
                                {{ charactersRemaining }}
                            </small>
                        </div>
                    </div>
                </div>
                <div v-if="content.image" class="sm:ml-6 px-7 mb-2 w-full">
                    <NLazyImage :src="[content.image]" class="!aspect-image border-dust-100 sm:border-r border-b" />
                </div>
                <div v-if="content.thread.length">
                    <label class="form-group__label text-dust-700 ml-10 mb-3">
                        The {{ content.thread.filter(t => t.metadata && t.metadata.position ).length }} Top rated ideas will be auto-tweeted as replies:
                    </label>
                </div>
            </div>

            <ul v-if="content.thread.length" class="list-none divide">
                <li v-for="(message, i) of content.thread" :key="i">
                    <div class="flex gap-3 relative pb-4 ml-1" >
                        <NAvatar :src="$auth.user.image" class="z-1"/>
                        <div :class="i + 1 < content.thread.length ? 'h-full' : 'h-4'" class="w-0.5 bg-dust-200 absolute -left-4"></div>
                        <div class="w-5 h-0.5 bg-dust-200 absolute top-4 -left-4"></div>
                        <div class="flex-1 border py-3 px-3 rounded-md">{{ message.text }}</div>
                    </div>
                </li>
            </ul>

            <NAlert v-if="hasError" type="danger" class="mt-3 mx-6" open>
                Unable to finish sharing, please try again.
            </NAlert>

            <NAlert v-if="shared" type="success" class="mt-3 mx-6" open>
                Challenge has been shared successfully.
            </NAlert>

            <div v-else class="flex items-center justify-center gap-4 py-3">
                <button aria-label="button" type="button" class="px-6 py-2 leading-5 text-dust-600 border rounded-full" @click="$emit('cancel')">
                    Cancel
                </button>
                <button aria-label="button" :disabled="charactersRemaining < 0" :class="charactersRemaining < 0 ? 'bg-blue-300' : 'bg-blue'" type="submit" class="px-6 py-2 leading-5  text-white rounded-full">
                    {{ busy ? 'Tweeting...' : 'Tweet now' }}
                </button>
            </div>
        </NDataForm>
    </div>
</template>