import Cookie from '@/utils/Cookie';
import { useComputedProperty } from '@/utils/Helpers';
import Pusher from 'pusher-js';

export const pusher = new Pusher(
    import.meta.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: import.meta.env.VUE_APP_PUSHER_CLUSTER,
    authEndpoint: import.meta.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
    auth: {
        headers: useComputedProperty({
            'Authorization': () => `Bearer ${Cookie.get('notification_token')}`
        }),
    }
});

export const install = ({ app }) => {
    app.mixin({
        beforeCreate() {
            this.$pusher = pusher;
        },
    });
};
