<script setup>
import bus from '@/modules/bus';
import OlympiadsProvider from './OlympiadsProvider.vue';
import PreferencesProvider from './PreferencesProvider.vue';
import GameDashProvider from '@/components/game-dash/Provider.vue';
import { onKeyStroke } from '@vueuse/core';

onKeyStroke('Escape', (e) => {
    bus.emit('key:escape', e)
});
</script>

<template>
    <PreferencesProvider>
        <OlympiadsProvider :platform="$auth.user.screen_name">
            <GameDashProvider>
                <div class="app-wrapper">
                    <slot />
                </div>
            </GameDashProvider>
        </OlympiadsProvider>
    </PreferencesProvider>
</template>