import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

const _notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'right',
    y: 'bottom',
  },
})

export const notyf = {
    success: (message) => {
      _notyf.success(message)
    },
    error: (message) => {
      _notyf.error(message)
    },
    info: (message) => {
      _notyf.open({
        type: 'info',
        message,
        background: '#1e1e1e',
      })
    },
    warning: (message) => {
      _notyf.open({
        type: 'warning',
        message,
      })
    },
    primary: (message) => {
      _notyf.open({
        type: 'primary',
        message,
      })
    },
    purple: (message) => {
      _notyf.open({
        type: 'purple',
        message,
      })
    },
    blue: (message) => {
      _notyf.open({
        type: 'blue',
        message,
      })
    },
    green: (message) => {
      _notyf.open({
        type: 'green',
        message,
      })
    },
    orange: (message) => {
      _notyf.open({
        type: 'orange',
        message,
      })
    },
  }

export const useNotyf = () => _notyf
