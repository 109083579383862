<script setup>
import Categories from './Categories.vue';
import { ref, watch } from 'vue';
import { nanoid } from 'nanoid';
import { Icon } from '@bignerve/ui-core';
import { useVModel } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: Object,
        default: null
    },
    initialValue: {
        type: Object,
        default: null
    },
    clearable: {
        type: Boolean,
        default: false
    },
    allowAll: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    select: {
        type: Function,
        default: (v) => v
    },
    uid: {
        type: String,
        default: () => `menu-${nanoid()}`
    }
});

const emit = defineEmits(['update:modelValue', 'closed', 'change']);
const opened = ref(false);
const category = useVModel(props, 'modelValue', emit);

const closed = () => {
    emit('closed', category.value);
    opened.value = false;
};

const handleReset = () => {
    category.value = null;
}

watch(category, () => {
    emit('change', category.value);
})
</script>

<template>
    <VDropdown
        :shown="opened"
        :class="{ 'w-full': $responsive.sm }"
        :triggers="[]"
		:autoHide="false"
        :distance="15"
        :inert="opened"
        placement="bottom"
        theme="menu"
        @hide="closed"
    >
         <slot name="activator" :on="{ click: () => opened = true }" :category="category" :opened="opened" :disabled="disabled" :handleReset="handleReset">
            <div :class="{ 'shadow-active': opened, disabled }" type="button" class="flex items-center w-full min-w-[13rem] leading-9 rounded-lg px-4 min-h-9 border border-dust-300 bg-white" @keyup.esc="closed()">
                <button 
                    aria-label="button" 
                    class="flex items-center justify-between whitespace-nowrap w-full font-semibold overflow-hidden" 
                    type="button"
                    @click="opened = !opened"
                >
                        <transition
                            enter-active-class="transition-transform duration-300"
                            leave-active-class="transition-transform duration-300"
                            enter-from-class="transform translate-y-7"
                            enter-to-class="transform translate-y-0"
                            leave-from-class="transform translate-y-0"
                            leave-to-class="transform -translate-y-7"
                            mode="out-in"
                        >
                            <span v-if="category" :key="category.id" class="inline-flex items-center">
                                <img 
                                    :src="`/img/category-icons/${category.icon}`" 
                                    :alt="category.name" 
                                    class="h-6 w-6 invert-[70%] mr-2" 
                                    @error="e => e.target.src = `/img/category-icons/article-draft-icon.svg`"
                                >
                                {{ category.name }}
                            </span>
                            <span v-else key="empty-option">
                                <Icon as="th-solid" class="mr-2" />{{ $t('component.game.category-piker.all-categories') }}
                            </span>
                        </transition>
                        <Icon 
                            v-if="!category" 
                            v-tooltip="opened ? 'Close' : 'Open'" 
                            :as="opened ? 'arrow-circle-up' : 'arrow-circle-down'" 
                            right 
                        />
                    </button>
                    <button 
                        aria-label="button" 
                        type="button"
                        v-if="clearable && category" 
                        v-tooltip="'Clear'" 
                        class="px-3 -mr-3 -mb-1" 
                        @click="handleReset()"
                    >
                        <Icon as="times-circle-regular" />
                    </button>
            </div>
        </slot>

        <template #popper>
            <div class="bg-white rounded-md border sm:mx-20">
                <Categories v-model="category" :initialValue="initialValue" :allow-all="allowAll" />
            </div>
        </template>
    </VDropdown>
</template>
