<script>
import { usePlatform } from '@/mixins/ShowsPlatform';
import AdminSidebar from './AdminSidebar.vue';
import GenericSidebar from './GenericSidebar.vue';
import AccountSidebar from './AccountSidebar.vue';
import DashboardSidebar from './DashboardSidebar.vue';
import FaqSidebar from './FaqSidebar.vue';

export default {
    components: {
        AdminSidebar,
        GenericSidebar,
        AccountSidebar,
        DashboardSidebar,
        FaqSidebar,
    },

    mixins: [usePlatform()],

    emits: ['change'],

    watch: {
        collapsed(state) {
            this.$emit('change', state);
            this.$store.dispatch('app/toggleSidebarCollapsed', state);
        },
        '$responsive.sm' (state) {
            this.collapsed = state;
        },
        '$route' () {
            if (this.$responsive.sm) {
                this.collapsed = true;
            }
        }
    },

    data() {
        return {
            collapsed: this.$responsive.sm
        };
    },

    computed: {
        sidebar() {
            switch (this.$route.name && this.$route.name.split('.')[0]) {
                case 'dashboard':
                    return 'DashboardSidebar';
                case 'account':
                    return 'AccountSidebar';
                case 'faqs':
                    return 'FaqSidebar'
                case 'admin':
                    return 'AdminSidebar';
                default:
                    return 'GenericSidebar';
            }
        },
        dashboard() {
            switch (this.sidebar) {
                case 'GenericSidebar':
                    return {
                        title: 'About',
                        route: { name: 'about' }
                    };
                case 'AccountSidebar':
                    return {
                        title: 'My Account',
                        route: { name: 'account.my-account' }
                    };
                case 'FaqSidebar':
                    return {
                        title: 'FAQs',
                        route: { name: 'faqs' }
                    };
                case 'AdminSidebar':
                    return {
                        title: 'Admin Dashboards',
                        route: { name: 'admin.dashboards' }
                    };
                default:
                    return {
                        title: this.$auth.authenticated ? 'My Dashboard' : 'Dashboard',
                        route: {
                            name: 'dashboard.dashboard',
                            params: { user: this.$auth.user.screen_name || this.$route.params.user }
                        },
                    };
            }
        }
    },

    methods: {
        toggle() {
            this.collapsed = ! this.collapsed;
        }
    }
}
</script>

<template>
    <aside class="page__sidebar sm:min-w-[12rem]" :class="{ 'is-collapsed': collapsed }">
        <button aria-label="button" v-if="$responsive.sm" type="button" class="tracking-wider shadow border-2 px-6 text-white flex items-center justify-between py-1 border-nerve bg-nerve rounded-md w-full" @click.prevent="toggle">
            <span class="flex items-center whitespace-nowrap">
                <span class="uppercase">{{ dashboard.title }}</span>
                <small class="italic ml-2">(Click to open the menu)</small>
            </span>
            <NIcon :as="collapsed ? 'caret-square-down' : 'caret-square-up'" class="ml-2" />
        </button>
        <div v-else class="page__sidebar-title">
            <router-link :to="dashboard.route">
                {{ dashboard.title }}
            </router-link>
            <button aria-label="button" type="button" @click.prevent="toggle">
                <NIcon :as="collapsed ? 'caret-square-down' : 'caret-square-up'" />
            </button>
        </div>
        <component :is="sidebar"></component>
        <span v-if="$responsive.sm" ref="targetScroll"></span>
    </aside>
</template>
