<script>
import axios from 'axios';
import upperFirst from 'lodash/upperFirst';
import sampleSize from 'lodash/sampleSize';
import { randomImages } from '@/services/functions';

export default {
    name: 'RandomImages',
    
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        tags: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        prefetch: {
            type: Boolean,
            default: false,
        },
        format: {
            type: String,
            default: 'jpg'
        }
    },

    emits: ['update:modelValue', 'prefetch', 'change'],

    data() {
        return {
            busy: false,
            totalChoice: 6,
            images: [],
            loaded: false,
            takeKeyword: 0,
        };
    },

    computed: {
        keywords() {
            return this.tags
                .filter(tag => tag)
                .map(tag => tag.toLocaleLowerCase());
        },
        gallery() {
            return this.images;
        }
    },

    watch: {
        tags: {
            handler() {
                if (this.prefetch) {
                    this.fetch();
                }
            },
            immediate: true,
        },
    },

    bus: {
        'challenge:creation-flow:edition-started' () {
            this.images = [];

            setTimeout(() => {
                this.fetch();
            }, 500)
        },
    },

    methods: {
        async fetch() {
            this.busy = true;
            this.loaded = false;

            const nextKeywords = this.keywords.slice(0, this.keywords.length - this.takeKeyword).join(', ');
            const { data } = await randomImages({
                keywords: nextKeywords,
                format: this.format,
                total: 20,
            });

            data.forEach(img => {
                img.applying = false;
            });

            this.images = sampleSize(data, this.totalChoice);

            // updates takeKeyword
            this.takeKeyword = this.takeKeyword < this.keywords.length -1
                ? this.takeKeyword + 1 : 0;

            this.busy = false;
            this.loaded = true;
        },
        async scrape(image) {
            if (image.error) {
                return;
            }

            try {
                image.applying = true;

                this.$emit('prefetch', image);

                const { data } = await axios.get(image.full, {
                    responseType: 'arraybuffer'
                });

                const { data: { url } } = await this.$api.resource.uploadImage(
                    data, { type: this.format }, this.headers
                );

                const link = new URL(url)

                link.searchParams.set('s', upperFirst(image.source));
                link.searchParams.set('u', upperFirst(image.user.name));
                link.searchParams.set('l', image.user.link);

                this.$emit('update:modelValue', link.toString());
                this.$emit('change', { url: link.toString() });

            } catch (err) {
                image.error = true;
                console.log(err)
            } finally {
                image.applying = false;
            }
        }
    },
}
</script>

<template>
    <div class="w-full">
        <div class="image-preview flex gap-3 flex-col sm:flex-row">
            <div class="image-preview__info flex-none">
                <button aria-label="button"
                    type="button"
                    class="flex items-center py-2 px-6 rounded-md shadow hover:bg-nerve-600 border bg-nerve text-white transition ease-in-out duration-300"
                    :class="{ 'shadow-none cursor-not-allowed': disabled || busy }"
                    :disabled="disabled || busy"
                    @click.prevent="fetch">
                    {{ busy ? 'Fetching image..' : 'Fetch a new set' }}
                    <svg v-if="busy" class="animate-spin h-5 w-5 ml-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <NIcon v-else as="images" class="ml-2" />
                </button>
            </div>
            <div class="ml-4 flex items-center gap-3 mt-4 sm:mt-0 flex-1">
                <span class="font-semibold text-dust-700">Tags:</span>
                <slot name="tags" :tags="tags" :fetch="fetch">
                    <span v-for="tag of tags" :key="tag" class="shadow border py-1 px-4 rounded-full bg-white text-dust-700">
                        {{ tag }}
                    </span>
                </slot>
            </div>
        </div>
        <div class="grid grid-cols-2 grid-rows-3 sm:grid-cols-3 sm:grid-rows-2 gap-4 mt-8 w-full">
            <div v-for="(image, i) of gallery" :key="i" class="rounded-sm hover:shadow-lg cursor-pointer overflow-hidden relative bg-dust-500">
                <div v-if="image.applying" class="z-[1] absolute inset-0 text-white flex items-center justify-center text-xl bg-nerve bg-opacity-40 text-center">
                    <span>Processing...</span>
                </div>
                <div v-if="image.error" class="absolute inset-0 text-white flex items-center justify-center text-xl bg-nerve bg-opacity-40 p-10 text-center">
                    <span>An error occurred, please try another image.</span>
                </div>
                <NImage :src="image.small" class="!aspect-image" @click="scrape(image)" />
                <div class="text-white text-xs text-center absolute bottom-0 w-full bg-gradient-to-t bg-opacity-10 bg-black py-1">
                    Image by <a :href="image.user.link" target="_blank" rel="noopener noreferrer" class="text-white underline hover:text-white hover:underline">{{ image.user.name }}</a> on <a :href="image.source" class="text-white underline hover:text-white hover:underline" target="_blank" rel="noopener noreferrer">{{ image.source }}</a>
                </div>
            </div>

            <template v-if="busy && !gallery.length">
                <div v-for="i of 6" :key="i" class="rounded-sm bg-dust-500 aspect-image relative animate-pulse"></div>
            </template>
        </div>

        <div v-if="loaded && !gallery.length" class="text-dust-700 p-4 border rounded-sm w-full text-center">
            No images found
        </div>
    </div>
</template>
