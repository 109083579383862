<template>
    <div class="border border-dust-300 rounded-md sheet shadow mb-6">
        <div class="px-3 py-2 border-b bg-dust-200 flex justify-between flex-wrap gap-4">
            <h3 class="m-0 flex items-center text-lg space-x-2 whitespace-nowrap">
                {{ $t('page.home.blog.title') }}
            </h3>
        </div>
        <div class="bg-white p-10">
            <p>Soon</p>
        </div>
    </div>
</template>