<script setup>
import debounce from 'lodash/debounce';
import { api } from '@/modules/services';
import { ref } from 'vue';

const props = defineProps({
    id: String,
    name: String,
    label: String,
    hint: String,
    modelValue: String,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    autofocus: Boolean,
    prependIcon: String,
    itemsType: String,
    returnObject: Boolean,
    entityTypes: {
        type: String,
        default: 'place',
    },
});

defineEmits(['update:modelValue']);

const field = ref(null);
const busy = ref(false);
const items = ref([]);

const searchLocation = debounce(async function (q) {
    if (!q || q.length < 3) {
        items.value = [];
        return;
    }

    try {
        busy.value = true;

        const { data: response } = await api.geolocation.suggest({
            query: q, types: props.entityTypes,
        });

        items.value = response.data;

        field.value.show();
    } catch (e) {
        // ignore
        console.log(e);
    } finally {
        busy.value = false
    }
}, 500)

const icons = {
    'place': 'map-marker',
    'business': 'bookmark-regular',
    'address': 'map-marker',
}
</script>

<template>
    <NAutocomplete
        :modelValue="modelValue"
        :label="label"
        :hint="hint"
        :name="name"
        :id="id"
        :placeholder="placeholder"
        :disabled="disabled"
        :loading="busy"
        :items="items"
        :clearable="!!modelValue"
        :query-input="modelValue"
        :prepend-icon="prependIcon"
        :items-type="itemsType"
        :autofocus="autofocus"
        :return-object="returnObject"
        item-value="label"
        ref="field"
        no-filter
        @update:model-value="$emit('update:modelValue', $event)"
        @update:query-input="searchLocation"
    >
        <template #item="{ item }">
            <div v-if="item" class="flex items-center justify-start px-4 py-2 text-gray-700">
                <NIcon :as="icons[item.type]" class="text-lg mr-2 text-dust-700" />
                <span class="flex leading-tight">
                    <span class="text-md mr-2 text-gray-700">{{ item.label }}</span>
                    <span v-if="item.formatted" class="text-gray-600">({{ item.formatted }})</span>
                </span>
            </div>
        </template>
    </NAutocomplete>
</template>