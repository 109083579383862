import { usePreferences } from '@/composable/preferences';
import { useUserPlatform, usePlatform } from '@/composable/platform';
import { computed, defineComponent, inject, provide } from 'vue';
import { useAuth } from '@/composable/auth';
import { useRouteQuery } from '@vueuse/router';
import { TYPE } from '@/constant/platform';
import { useGameDash } from '@/composable/gamedash';

export const ForumContext = defineComponent({
    name: 'ForumContext',
    setup(_, { slots }) {
        const { auth } = useAuth();
        const { sync } = usePreferences();
        const { isViewingSelfPlatform } =  useUserPlatform();
        const { contextPlatform } = usePlatform();
        const allowTopicCreation = sync('platform.forum.allow_topic_creation', false);
        const minLevel = sync('platform.forum.allow_topic_creation_min_level', 0);
        const authorFragments = useRouteQuery('author', null, { transform: (str) => str?.split('@') });
        const { brain } = useGameDash();

        const author = computed(() => {
            const [id, name] = authorFragments.value || [];

            if (id && name) {
                return {
                    id,
                    name,
                }
            }
        });

        const resetAuthor = () => {
            authorFragments.value = null;
        }

        const topicCreationEnabled = computed(() => {
            return allowTopicCreation.value && brain.value?.brain_level >= minLevel.value
        })

        const canCreateTopic = computed(() => {
            return auth.authenticated && (topicCreationEnabled.value || isViewingSelfPlatform.value || contextPlatform.value?.type === TYPE.SYSTEM);
        });

        provide('forumContext', { canCreateTopic, author, resetAuthor });

        return () => slots.default({ canCreateTopic, author, resetAuthor });
    },
});

export const useForumContext = () => {
    return inject('forumContext', {});
}