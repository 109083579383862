<script>
import orderBy from 'lodash/orderBy';
import NFireworks from '@/components/NFireworks.vue';
import { numeric } from '@bignerve/ui-utils';
import { BrainLevelChart } from '@bignerve/ui-charts';
import { scaleLinear} from 'd3';

const SHOW_NUMBERING = 'overall-nerve-score:show-numering';
const COLORS = {
    1: '#CADA2A',
    2: '#B8529E',
    3: '#CB8623',
    4: '#6FCBDC',
    5: '#FF9F0A',
    6: '#F9EC23',
    7: '#3AB44A',
    8: '#C0272C',
    9: '#0D71B9',
    10: '#EC1F78'
};

const defaultOptions = {
    bar: {
        speed: 'slow',
    }
}

export default {
    name: 'BrainLevelBoard',
    props: {
        brain: {
            type: Object,
            required: true
        },
        ranks: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => defaultOptions,
        },
    },
    components: {
        BrainLevelChart,
        NFireworks,
    },
    data () {
        return {
            showNumbering: Storage.get(SHOW_NUMBERING, true),
            showProgressInfo: false,
            progress: 0,
        };
    },
    computed: {
        level () {
            return this.brain.brain_level || 0;
        },
        scoreGap () {
            return Math.round(this.brain.next_level_score - this.brain.score);
        },
        currentLegend () {
            return this.legends.find(
                legend => legend.position === this.brain.rank_position
            ) || { color: '#ececec', position: 0, label: 'waiting progress' };
        },
        legends () {
            if (!this.brain.role) return [];

            const ranks = this.ranks.filter(rank => rank.role === this.brain.role);

            return orderBy(ranks.map(rank => ({
                label: rank.label,
                position: rank.rank_position,
                color: COLORS[rank.rank_position],
                start: rank.start_level,
                end: rank.end_level,
            })), ['position'], ['desc'])
        },
    },


    watch: {
        showNumbering (value) {
            Storage.set(SHOW_NUMBERING, value)
        },
        brain: {
            handler () {
                this.$nextTick(() => {
                    const progress = scaleLinear()
                        .domain([this.brain.start_level_score, this.brain.next_level_score])
                        .range([0, 100])
                        .nice();

                    this.progress = this.brain.brain_level === 100 ? 100 : progress(this.brain.score).toFixed(0);
                })
            },
            deep: true,
        }
    },

    methods: {
        numeric,
    }
}
</script>

<template>
    <div class="flex flex-col sm:flex-row sm:space-x-4 mb-6 items-center">
        <div class="w-full flex flex-col">
            <div class="flex flex-col sm:flex-row sm:space-x-4 mb-6">
                <div class="sm:w-2/3 flex items-center justify-center sm:px-10">
                    <BrainLevelChart
                        :level="brain.brain_level"
                        :numbers="showNumbering"
                        :transition-time="options.bar.speed === 'fast' ? .8 : null"
                        class="w-4/5 !duration-[0.1s]"
                    />
                </div>
                <div class="sm:w-1/3 font-narrow -mt-36 ml-6 p-8 sm:p-0 sm:m-0">
                    <div class="mb-3">
                        Ranks:
                    </div>
                    <ul v-if="legends.length" class="list-none p-0 font-narrow mb-3">
                        <li v-for="legend of legends" :key="legend.position" :style="{ backgroundColor: brain.rank_position === legend.position ? legend.color : '' }" :class="{'text-white font-bold px-2 -mx-2 leading-relaxed rounded-sm shadow animate-evince': brain.rank_position === legend.position }" class="flex relative items-center mb-1 max-w-48">
                            <NFireworks :fire="legend.position > 1 && brain.rank_position === legend.position" />

                            <span class="w-8 font-bold" :style="{ color: brain.rank_position !== legend.position ? legend.color : '' }">{{ legend.position }}</span>
                            <span :class="brain.rank_position !== legend.position ? 'text-dust-700' : 'text-white'" class="font-semibold text-sm">{{ legend.label }}</span>
                        </li>
                    </ul>
                    <div v-else class="flex flex-col gap-3 mb-3 animate-pulse">
                        <div v-for="i of 10" :key="i" class="flex flex-row items-center gap-2">
                            <div class="w-4 h-[16px] bg-dust-200"></div>
                            <div :class="i % 2 ? 'w-1/2' : 'w-1/4'" class="h-[16px] bg-dust-200"></div>
                        </div>
                    </div>
                    <label class="flex gap-1">
                        <input v-model="showNumbering" type="checkbox" class="mt-1" />
                        <p class="text-sm text-dust-400 cursor-pointer">
                            Show numbering on Brain. There's 10 levels per rank. There's 100 levels total in the game.
                        </p>
                    </label>
                </div>
            </div>
            <div class="flex flex-col items-center gap-2 mb-6 select-none">
                <span>Currently at <b :style="{ color: currentLegend.color }">{{ currentLegend.label }}</b> - Level {{ brain.brain_level }} ({{ numeric(brain.score, 2) }} neurons)</span>
                <div v-tooltip="`${progress}%`" class="border-2 border-dust-400 rounded-md h-6 w-full overflow-hidden flex relative" @dblclick="showProgressInfo = !showProgressInfo">
                    <div
                        :style="{ backgroundColor: currentLegend.color, width: `${progress}%` }"
                        :class="options.bar.speed === 'fast' ? 'duration-[0.1s]' : 'duration-2s'"
                        class="transition-xy ease-in-out h-full border-r-2 border-dust-400"
                    ></div>
                    <div class="h-full flex-1 opacity-25" :style="{ backgroundColor: currentLegend.color }"></div>

                    <span v-if="showProgressInfo" class="absolute text-sm left-2 text-dust-600">from <b>{{ brain.start_level_score }}</b></span>
                    <span v-if="showProgressInfo" class="absolute text-sm right-2 text-dust-600">to <b>{{ brain.next_level_score }}</b></span>
                    <span v-if="showProgressInfo" class="absolute text-sm right-[50%] text-dust-600">current <b>{{ brain.score.toFixed(0) }}</b></span>
                </div>
                <span v-if="brain.brain_level < 100" class="text-sm">
                    <b>{{ scoreGap }} neurons</b> away from level <b>{{ brain.brain_level < 100 ? brain.brain_level + 1 : 100 }}</b>
                </span>
                <span v-else class="text-sm">
                    <NFireworks tag="b" class="relative" :fire="currentLegend.position > 1">Congratulations!</NFireworks> You've reached level <b>{{ brain.brain_level }}</b>
                </span>
            </div>
        </div>
        <!-- <div class="sm:w-1/3 flex flex-col mb-6 p-4 items-center">
            <div class="mb-3">
                <button aria-label="button" class="font-narrow text-white bg-nerve px-3 py-1 font-bold rounded-md mr-3" type="button">
                    Show me where I'm awesome!
                </button>
            </div>
            <p class="text-sm italic font-narrow text-dust-600 w-48">
                where I'm going well; load 5 sets of the best performance, across these criteria: <b>role, time, category, olympiad</b>
            </p>
        </div> -->
    </div>
</template>