import { useQuery } from '@tanstack/vue-query';
import { api } from '@/modules/services';
import { computed, toValue } from 'vue'

export const usePlatform = (slug) => {
    return useQuery({
        queryKey: ['platform:by-slug', slug],
        queryFn: async () => {
            const { data: response } = await api.platform.find(toValue(slug));

            return response.data;
        },
        enabled: computed(() => !!toValue(slug)),
        refetchInterval: 0,
    });
}
