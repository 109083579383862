<script setup>
import NAlert from '@/components/NAlert.vue';
import { loadStripe } from '@stripe/stripe-js';
import { useFeatureFlag } from '@/composable/feature-flag';
import { ref } from 'vue';
import { tryOnMounted } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useAuth } from '@/composable/auth';
import { api } from '@/modules/services';
import { sentry } from '@/modules/sentry';
import { uuid } from "@/utils/Helpers";

const props = defineProps({
	amount: {
		type: Number,
		required: true,
	},
	prizeType: {
		type: String,
		default: 'idea',
	},
    prizeParams: {
        type: Object,
		required: true,
		// as example
		default: () => ({
			challenge_id: null,
		}),
    },
    enableCustomAmount: {
        type: Boolean,
        default: false,
    },
	disabled: {
		type: Boolean,
		default: false,
	},
});

const id = uuid();
const emit = defineEmits(['success']);

const route = useRoute();
const client = ref(null);
const loading = ref(false);
const errorMessage = ref(null);
const { enabled: isStripeTestModeEnabled } = useFeatureFlag('stripe-testmode');
const customAmount = ref(props.amount);
const pi = ref(null);

const createPaymentItent = async () => {
	const { data: response } = await api.prizes.charge(
		props.prizeType, props.prizeParams,
	);

	return response.data;
};

const { auth, loadMe } = useAuth();

await loadMe();

const { payment, prize } = await createPaymentItent();

const stripe = await loadStripe(
	isStripeTestModeEnabled.value
		? import.meta.env.VUE_APP_STRIPE_PUBLIC_KEY_TESTMODE
		: import.meta.env.VUE_APP_STRIPE_PUBLIC_KEY_LIVEMODE
);
const appearance = { theme: 'stripe' };
const elements = stripe.elements({ appearance, clientSecret: payment.client_secret_id });

// handle the form submission
client.value = stripe;

async function handleSubmit(e) {
	try {
		e.preventDefault();
		errorMessage.value = null;

		if (customAmount.value < props.amount) {
			errorMessage.value = `The minimum amount is $${props.amount}`;
			return;
		}

		loading.value = true;

		// update the payment value
		if (customAmount.value > props.amount) {
			await api.prizes.update('idea', prize.id, {
				Op: 'PAYMENT',
				Data: { amount: customAmount.value },
			});
		}

		const { error, paymentIntent } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: window.location.href,
			},
			redirect: 'if_required',
		});

		if (error) {
			console.log(error)
			
			errorMessage.value = error.message || 'An unexpected error occurred';
			loading.value = false;
			return;
		}

		if (paymentIntent.status === 'requires_action') {
			pi.value = null;

			const { error, paymentIntent: newPaymentIntent } = await stripe.handleNextAction({
				clientSecret: paymentIntent.client_secret,
			});

			if (error) {
				errorMessage.value = error.message || 'An unexpected error occurred';
				loading.value = false;
				return;
			}

			pi.value = newPaymentIntent;

			emit('success', { prize, newPaymentIntent });
		} else {
			pi.value = paymentIntent;
			emit('success', { prize, paymentIntent })
		}
	} catch (e) {
		errorMessage.value = 'An error occurred while processing your payment. Please check your payment details and try again.';

		const scope = sentry.getCurrentScope()

		if (scope) {
			scope.setExtra('error', e);
			scope.setContext('challenge', {
				challenge: props.challenge_id,
			});
			scope.setContext('payment', {
				payment, prize,
			});
		}

		sentry.captureException(e);

		throw e;
	} finally {
		loading.value = false;
	}
}

tryOnMounted(() => {
	const paymentElement = elements.create('payment', {
		defaultValues: {
			billingDetails: {
				email: route.query.email || auth.user?.email,
			},
		},
	});
	const linkAuthElement = elements.create('linkAuthentication', {
		defaultValues: {
			email: route.query.email || auth.user?.email,
		},
	});

	linkAuthElement.mount(`#link-auth-element-${id}`);
	paymentElement.mount(`#payment-element-${id}`);

	sentry.addBreadcrumb({
		category: "challenge:payout",
		message: "Payout flow started",
		level: "info",
		data: {
			challenge_id: props.challenge_id,
		},
	});
});
</script>

<template>
    <div class="p-4">
        <h2>Payment method</h2>
        <p class="text-dust-700">
            Please enter your card payment information below. Your card will be securely charged and a confirmation number shown on
            the next screen.
        </p>
        <form id="payment-form" class="py-5" :class="{'pointer-events-none': loading || disabled }" @submit="handleSubmit" >
            <div :id="`link-auth-element-${id}`" class="mb-2"></div>
            <div :id="`payment-element-${id}`" class="min-h-[14rem] mb-4"></div>

            <NAlert
                :open="!!errorMessage"
                type="danger"
                class="mt-6"
            >
                {{ errorMessage }}
            </NAlert>

            <div v-if="enableCustomAmount" class="grid gap-2">
                <label for="customAmount" class="block font-medium text-dust-700">Donation Amount</label>
                <div class="flex flex-col gap-1">
                    <div class="relative border rounded-md flex items-center px-10 w-60">
                        <span class="absolute top-2 left-4 text-lg">$</span>
                        <input v-model="customAmount" type="number" :min="amount" name="customAmount" class="rounded-md py-2 text-lg" value="0" />
                    </div>
                    <p :class="{ 'text-red-500': customAmount < amount }" class="text-sm text-dust-600">
                        You can enter a custom amount above ${{ amount }} as a donation to this nonprofit (consult your tax advisor).
                    </p>
                </div>
            </div>

            <button aria-label="button"
                id="submit"
                type="submit"
                :disabled="!amount || customAmount < amount || loading || disabled || pi"
                :class="{ disabled: !amount || customAmount < amount || loading || disabled || pi }"
                class="px-4 py-2 rounded-md bg-secondary text-white w-full mt-2"
            >
                {{ loading ? 'Processing...' : `Pay $${customAmount} and enter` }}
            </button>
        </form>
    </div>
</template>