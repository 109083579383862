export function steps (key) {
    return {
        'leaderboard-1': [
            {
                attachTo: { element: `#leaderboard-current-user`, placement: 'top' },
                content: {
                    title: 'Your current rank',
                    description: 'You earn neurons in different roles.',
                },
            },
            {
                attachTo: { element: `#leaderboard-sorting-col`, placement: 'top' },
                content: {
                    title: 'Sorting',
                    description: 'Sort by Game Roles.',
                },
            },
        ],
        'leaderboard-2': [
        ],
        'home-page': [
            { 
                attachTo: {
                    element: '#widget-gamedash', 
                    placement: this.$responsive.mobile ? 'top' : 'left'
                },
                content: {
                    title: this.$t(`onboarding.home-page.widget-gamedash.title`),
                    description: this.$t(`onboarding.home-page.widget-gamedash.description`),
                },
            },
            { 
                attachTo: {
                    element: '#open-challenges', 
                    placement: 'top',
                },
                content: {
                    title: this.$t(`onboarding.home-page.open-challenges.title`),
                    description: this.$t(`onboarding.home-page.open-challenges.description`),
                },
                options: {
                    modifiers: [ { name: 'offset', options: { offset: [0, -60] } }],
                },
            },
            {
                attachTo: {
                    element: `#challenge-filters`,
                    placement: 'top'
                },
                content: {
                    title: this.$t(`onboarding.home-page.challenge-filters.title`),
                    description: this.$t(`onboarding.home-page.challenge-filters.description`),
                },
            },
            { 
                attachTo: {
                    element: '#categories-picker', 
                    placement: this.$responsive.mobile ? 'top' : 'bottom'
                },
                content: {
                    title: this.$t(`onboarding.home-page.categories-picker.title`),
                    description: this.$t(`onboarding.home-page.categories-picker.description`),
                },
            },
            { 
                attachTo: {
                    element: '#widget-olympiads', 
                    placement: this.$responsive.mobile ? 'top' : 'left'
                },
                content: {
                    title: this.$t(`onboarding.home-page.widget-olympiads.title`),
                    description: this.$t(`onboarding.home-page.widget-olympiads.description`),
                },
            },
        ],
        'innovator-dashboard': [
            {
                attachTo: { element: `#your-current-rank`, placement: 'top' },
                content: {
                    title: 'Your current rank',
                    description: 'As the crowd rates your ideas your ranking will change.',
                },
            },
            {
                attachTo: { element: `#your-rating-rank`, placement: 'top' },
                content: {
                    title: 'Your Rating Rank',
                    description: 'Rate more in your Analyst Dashboard.',
                },
            },
            { 
                attachTo: {
                    element: '#app-navbar', 
                    placement: this.$responsive.mobile ? 'top' : 'bottom'
                },
                content: {
                    title: this.$t(`onboarding.home-page.app-navbar.title`),
                    description: this.$t(`onboarding.home-page.app-navbar.description`),
                },
            },
        ],
    }[key];
}
