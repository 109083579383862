<script>
import TitleField from './TitleField.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import WordsRestriction from './WordsRestriction.vue';
import AssistantButton from '@/components/challenge/AssistantButton.vue';
import get from 'lodash/get';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';
import { categoriesWithRestrictWords } from '@/composable/challenge';
import { restrictedWords } from '@/mixins/ChallengeCreateFlow';
import { useFeatureFlag } from '@/composable/feature-flag';

export default {
    inject: ['form', 'context', 'stepper'],

    mixins: [
        useChallengeDetails(),
    ],

    components: {
        TitleField,
        TextEditor,
        WordsRestriction,
        AssistantButton,
    },

    data () {
        return {
            hasRestrictedWords: null,
            categoriesWithRestrictWords,
            restrictedWords,
        }
    },

    computed: {
        categoriesBreadcrumbs () {
            return this.form.$parent.categoryTree?.map(category => category.name) || [];
        },
        suffix() {
            const category = this.form.$parent.categoryTree[0];
            let suffix = category ? category.slug : '*';

            suffix = this.isCaption ? (this.challenge.caption_solution_format || 'caption' ) : '*';
            suffix = ['social-issues', 'validate', 'make-better'].find(c => suffix.includes(c)) || suffix;

            return suffix;
        },
        challenge () {
            return this.form.fields.challenge;
        },
        title() {
            let category = this.form.$parent.category;

            return this.$t(`challenges.subheads.question-title.${this.suffix}`, {
                category: category && category.name !== 'Caption'  ? category.name : 'Funny'
            });
        },
        description() {
            return this.$t(`challenges.intros.question-description.${this.suffix}`);
        },
        shouldCheckRestricWords () {
            return !!this.challenge.solution_format || this.categoriesWithRestrictWords.some(category => 
                this.context.categoryMain && this.context.categoryMain.reference.includes(category),
            );
        },
    },

    watch: {
        hasRestrictedWords (yes) {
            if (yes === false) {
                this.form.errors.set(
                    'question_title',
                    `Consider using one of the words listed below, or similar!`,
                );
            } else {
                this.form.errors.clear('question_title');
            }
        },
    },

    bus: {
        'challenge:creation-flow:reseted' () {
            this.setInitialTitle();
        },
        'challenge:creation-flow:edition-started' () {
            this.setInitialTitle();
        },
    },

    methods: {
        setInitialTitle(force) {
            this.$nextTick(() => {
                const hasCaption = get(this.context, 'categoryMain.slug', '')
                    .includes('caption');

                if (force || !this.challenge.question_title) {
                    this.challenge.question_title = 'What could';
                }

                if (this.isCaption || hasCaption) {
                    this.challenge.question_title = this.title;
                    this.challenge.question_detail = null;
                }
            })
        },
        questionTitleChanged() {
            if(this.shouldCheckRestricWords) {
                this.$refs.wordsRestriction && this.$refs.wordsRestriction.notify();
            }


            // featureFlag.ready(async ({ enabled }) => {
            //     if (!enabled('categorify-challenge')) return;

            //     const { data: response } = await this.$api.challenges.categorify({
            //         title: this.challenge.question_title,
            //     });

            //     const { secondary_category } = response.data;

            //     if (secondary_category) {
            //         this.challenge.platform_category = secondary_category.id;
            //     }

            //     this.$nextTick(() => {
            //         if (this.isCaption && this.stepper) {
            //             this.stepper.to('image');
            //         }
            //     });
            // });
        },
        handleIdeaSuggestion(data) {
            this.challenge.question_title = data.question_title;
            this.challenge.question_detail = data.question_details;
            this.challenge.tags = [
                ...this.challenge.tags,
                ...(data.tags || []),
            ];
        }
    }
}
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">{{ title }}</h2>
            <div class="flex gap-4 items-center">
                <p class="challenge-section-heading__description">{{ description }}</p>

				<AssistantButton
					tool="suggest-challenge"
					:params="{
						categories: categoriesBreadcrumbs.filter(Boolean).join(','),
                        mandatory_keywords: restrictedWords.join(','),
					}"
					@success="handleIdeaSuggestion"
				/>
            </div>
        </div>
        <div class="form-section-wrapper">
            <div class="form-groups">
                <TitleField
                    v-model="challenge.question_title"
                    :initial-value="challenge.question_title"
                    :force-error="shouldCheckRestricWords && hasRestrictedWords === false"
                    :disabled="isCaption"
                    @blur="questionTitleChanged"
                    @update:model-value="hasRestrictedWords = null"
                />

                <WordsRestriction
                    v-if="!challenge.co_author && shouldCheckRestricWords"
                    :modelValue="challenge.question_title"
                    :challenge="challenge"
                    v-model:valid="hasRestrictedWords"
                    ref="wordsRestriction"
                    class="mt-0"
                />
                
                <div class="form-group relative">

                    <div class="absolute z-[1] -top-10 sm:left-80 sm:-top-0.5 md:left-110">
                        <NExperiencePoints fqdn="catalyst.ChallengePublished.CreatedChallenge" #default="{ points }">
                            <NBubble v-if="!!points && challenge.question_detail" color="catalyst" :animated="true">
                                +{{ points }} Neurons
                            </NBubble>
                        </NExperiencePoints>
                    </div>

                    <div class="quill-editor-wrapper placeholder-gray-100">
                        <div class="quill-editor-title">Describe the details of your Question</div>
                        <TextEditor
                            name="question_detail"
                            v-model="challenge.question_detail"
                            :defaultValue="challenge.question_detail"
                            :disabled="isCaption"
                            placeholder="Type or paste here. You can add styling with the formatting buttons above."
                            tabindex="2"
                        />
                    </div>
                    <div class="flex items-center justify-between w-full py-2">
                        <p class="form-block-help-text has-margin-top has-no-margin-bottom">
                            Be descriptive and concise.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="! isCaption" class="form-section-wrapper">
            <div class="form-groups">
                <div class="form-group">
                    <h2 v-if="context && context.categoryMain" class="form-group__title">
                        Main Category:
                        <span class="tag is-white">{{ context.categoryMain.name }}</span>
                    </h2>
                    <label class="form-group__label">
                        <b>Secondary Tags</b>
                        <i class="font-weight-normal">(optional)</i>
                    </label>
                    <NTags
                        v-model="form.fields.challenge.tags"
                        tabindex="3"
                    />
                    <p class="form-block-help-text mt-3 mb-0">
                        The more categories/keywords you add, the easier your challenge can be discovered across BigNerve. Separate keywords by "comma" or "return".
                    </p>
                </div>
            </div>
        </div>
        <div class="info-box">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <b>Why do we use “could”?</b>  We use “What could...” or “How could...” in all questions to help focus the innovators on new ideas and future options. Our site is a possibility generator, not a “question &amp; answer” site for expertise or proven solutions, or for polls. That's why we use the subjunctive tense. Ideas in our community are about <b>unexpected options</b>, not "what is" or "how can I".
            </p>
        </div>
    </div>
</template>
