<script>
import { usePlatform } from '@/mixins/ShowsPlatform';
import { useShadowMode } from '@/mixins/ShadowMode';

export default {
    mixins: [
        usePlatform(),
        useShadowMode(),
    ],
}
</script>

<script setup>
import bus from '@/modules/bus';
import Button from '@/components/ui/button/index.vue';
import GamedashDialog from '@/components/game-dash/Dialog.vue';
import { usePlatform } from '@/mixins/ShowsPlatform';
import { useShadowMode } from '@/mixins/ShadowMode';
import { useRoute } from 'vue-router';
import { useLinks as useAuthLinks } from '@/composable/auth';
import { ref } from 'vue';

const bignerveUrl = import.meta.env.VUE_APP_BASE_URL;

const {
    signupURL,
    loginURL,
    signout,
    isHome,
} = useAuthLinks();
const route = useRoute();
const opened = ref(false);

const toggle = () => {
    opened.value = !opened.value;
};

const close = () => {
    opened.value = false;
};

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

bus.on('key:escape', close);
</script>

<template>
    <div id="app-navbar" class="app-navbar shadow-strong z-[800] !fixed w-full">
        <div class="container mx-auto">
            <div class="app-navbar__inside min-h-[42px]">
                <ul class="breadcrumbs">
                    <li v-if="!isBignerve">
                        <a :href="bignerveUrl" class="breadcrumb-item has-image flex items-center">
                            <img src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true" class="!mb-0">
                            Big<span class="font-normal">Nerve</span>
                        </a>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'home.challenges' }" class="breadcrumb-item has-image flex items-center" @click="scrollToTop">
                            <img src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true" class="!mb-0">
                            <span v-html="platform.title.replace('Nerve', '<span class=\'font-normal\'>Nerve</span>')"></span>
                        </RouterLink>
                    </li>
                    <li v-if="isHome"><span class="breadcrumb-item">Home</span></li>
                    <li v-else-if="platform.title !== title">
                        <RouterLink :to="{ name: 'home.user', params: { user: contextUser.user.screen_name || account.user.screen_name } }" class="breadcrumb-item !text-secondary">
                            {{ title }}
                        </RouterLink>
                    </li>
                </ul>
                <div class="app-navbar__nav-wrapper" v-click-outside="close" :class="{ 'is-open': opened }">
                    <button aria-label="button" class="app-navbar__toggle-btn leading-none" @click.prevent="toggle">
                        Menu
                        <span class="icon icon-caret-square-down" aria-hidden="true"></span>
                    </button>
                    <ul class="app-navbar__nav">
                        <li class="non-functional is-highlighted">
                            <a href="/nerv-on-ah" title="The place where all closed challenges can be browsed"><span class="icon icon-nerv" aria-hidden="true"></span> Nerv On Ah</a>
                        </li>
                        <li non-functional class="is-highlighted">
                            <RouterLink :to="{ name: 'home.challenges' }">
                                <NIcon as="stack" class="!text-xl" left />Challenges (all)
                            </RouterLink>
                        </li>
                        <li >
                            <RouterLink :to="{ name: 'about' }">About</RouterLink>
                        </li>
                        <li class="is-highlighted">
                            <RouterLink :to="{ name: 'discussion-forums', params: { user: $route.params.user || 'platform' } }">
                                <NIcon as="discussion-icon" left />Forums
                            </RouterLink>
                        </li>
                        <li non-functional class="is-highlighted">
                            <a :href="`${bignerveUrl}/nerve-boards`">
                                <NIcon as="nerve-board" left />Nerve Boards
                            </a>
                        </li>
                        <li class="is-highlighted">
                            <a :href="`${bignerveUrl}/platform/olympiad/leaderboard`">
                                <NIcon as="stream" left />Leaderboards
                            </a>
                        </li>
                        <li>
                            <RouterLink :to="{ name: 'challenges.create' }">
                                <NIcon as="question-circle" class="!text-lg" />
                                Create Challenge
                            </RouterLink>
                        </li>
                        <li non-functional>
                            <RouterLink :to="{ name: 'challenges.test-idea' }">
                                <NIcon as="lightbulb" class="!text-lg" />
                                Test Idea
                            </RouterLink>
                        </li>
                        <li v-if="$auth.authenticated && currentUser.user.screen_name">
                            <RouterLink :to="{ name: 'home.user', params: { user: currentUser.user.screen_name } }">
                                <NAvatar :src="currentUser.user.avatar_url" :alt="currentUser.user.screen_name" :small="true" class="inline-flex -mb-2"/>
                                {{ currentUser.user.screen_name }}
                            </RouterLink>
                        </li>
                        <li v-if="$auth.authenticated && currentUser.user.screen_name">
                            <RouterLink :to="{ name: 'dashboard.dashboard', params: { user: currentUser.user.screen_name } }">
                                <NIcon as="brain" left />My Dashboard
                            </RouterLink>
                        </li>
                        <li v-if="$auth.authenticated && currentUser.user.screen_name">
                            <RouterLink :to="{ name: 'account.my-account.main' }">
                                My Account
                            </RouterLink>
                        </li>

                        <li>
                            <GamedashDialog v-if="$auth.authenticated" #activator="{ on, hasUpdates }" @show="close">
                                <NTip :open="$auth.authenticated" title="Gamedash Notifications" target="gamedash:notifications" placement="bottom" class="inline-block">
                                    <template #description>
                                        <p>We've moved "My Game Dash" to the top of the page to enhance your experience. Now you can quickly access your score information, helpful tips, and current tournament details.</p>
                                    </template>
                                    <Button variant="icon" class="border border-dust-300 relative hover:bg-dust-100 hidden sm:inline" size="xs" v-on="on">
                                        <NIcon as="bell" class="text-lg" />
                                        <span v-if="hasUpdates" class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-nerve-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-3 w-3 bg-nerve-500"></span>
                                        </span>
                                    </Button>

                                    <button class="relative flex gap-2 sm:hidden" v-on="on">
                                        <NIcon as="bell" class="text-lg" />
                                        <span>Game Dash</span>
                                        <span v-if="hasUpdates" class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-nerve-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full h-3 w-3 bg-nerve-500"></span>
                                        </span>
                                    </button>
                                </NTip>
                            </GamedashDialog>
                        </li>
                        <li v-if="$auth.authenticated && !hasShadowUser">
                            <a href="#" @click.prevent="signout">Sign Out</a>
                        </li>
                        <template v-if="!$auth.authenticated">
                            <li v-if="!$auth.authenticating">
                                <a :href="signupURL" class="relative">
                                    Create Account
                                </a>
                            </li>
                            <li>
                                <a :href="loginURL" class="relative">
                                    <NIcon as="user-avatar" class="!text-lg inline-block" :class="{ 'opacity-0': $auth.authenticating }" />
                                    <NLoading v-if="$auth.authenticating" class="inline-block absolute -left-[1px] -bottom-[1px]" active />
                                    <span class="ml-1">Sign In</span>
                                </a>
                            </li>
                        </template>

                        <li v-if="hasShadowUser">
                            <button aria-label="button"  type="button" class="flex items-center font-bold px-4 py-1 text-sm -mb-3 bg-red-500 text-white rounded-full hover:bg-red-600 shadow" @click.prevent="stopShadowMode">
                                <svg class="h-4 w-4 mr-2" viewBox="0 0 102 76" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Group-4" fill="#FFFFFF" fill-rule="nonzero">
                                            <path d="M38.2548238,31.1852235 C43.2266951,28.1685643 46.2298887,22.7578081 46.1525939,16.9560681 C46.1525937,7.59149021 38.5374052,0 29.1435943,0 C19.7497833,0 12.1345948,7.59149021 12.1345946,16.9560681 C12.0572999,22.7578081 15.0604935,28.1685643 20.0323647,31.1852235 C8.79856101,32.2313379 0.161200072,41.5490463 0,52.795497 L0,70.83823 L1.23402657,71.1252735 C10.8405827,74.245814 20.870746,75.8777311 30.974067,75.9640065 C39.8241355,76.2703739 48.6323383,74.6178484 56.7652224,71.1252735 L57.999249,70.5101803 L57.999249,70.5101803 L57.999249,52.795497 C58.0923567,41.5182946 49.517195,32.0437335 38.2548238,30.9801924 L38.2548238,31.1852235 Z" id="Path"></path>
                                            <g id="Group" opacity="0.5" transform="translate(44.000000, 0.000000)">
                                                <path d="M38.2548238,31.1852234 C43.2266951,28.1685642 46.2298887,22.757808 46.1525939,16.956068 C46.1525936,7.59149014 38.5374052,0 29.1435943,0 C19.7497834,0 12.1345949,7.59149014 12.1345946,16.956068 C12.0572999,22.757808 15.0604935,28.1685642 20.0323647,31.1852234 C8.79856101,32.2313378 0.161200072,41.5490462 0,52.7954969 L0,70.83823 L1.23402657,71.1252735 C10.8405827,74.245814 20.870746,75.8777311 30.974067,75.9640065 C39.8241355,76.2703739 48.6323383,74.6178484 56.7652224,71.1252735 L57.999249,70.5101803 L57.999249,70.5101803 L57.999249,52.7954969 C58.0923567,41.5182946 49.517195,32.0437334 38.2548238,30.9801924 L38.2548238,31.1852234 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                
                                Stop
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
