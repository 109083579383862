<script setup>
import NIcon from '@/components/NIcon.vue';
import FlexTable from '@/components/FlexTable.vue';
import NMedal from '@/components/leaderboard/Medal.vue';
import MemeGenerator from '@/components/challenge-show/MemeGenerator.vue';
import NLoadingNerve from '@/components/NLoadingNerve.vue';
import IdeaTools from '../idea/IdeaTools.vue';
import IdeaContentProvider from '@/components/providers/IdeaContentProvider.vue';
import { truncate } from 'lodash';
import { api } from '@/modules/services';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { MEDAL } from '@/constant/idea';
import { toRaw } from '@/mixins/Markdown';
import { useDetails } from '@/composable/challenge';
import { useResponsive } from '@/composable/responsive';
import { useAuth } from '@/composable/auth';

const props = defineProps({
    ideas: {
        type: Array,
        default: () => ([])
    },
    challenge: Object,
    showPrizeMoney: Boolean,
    showPosition: Boolean,
    loading: Boolean,
    starMedal: Boolean,
    emptyMessage: {
        type: String,
        default: 'No ideas!'
    }
});

const medals = {
    [MEDAL.CATALYST_GOLD]: 'catalyst-gold-medal',
    [MEDAL.CATALYST_SILVER]: 'catalyst-silver-medal',
    [MEDAL.CATALYST_BRONZE]: 'catalyst-bronze-medal',
    [MEDAL.COMMUNITY_GOLD]: 'community-gold-medal',
    [MEDAL.COMMUNITY_SILVER]: 'community-silver-medal',
    [MEDAL.COMMUNITY_BRONZE]: 'community-bronze-medal',
}

const { t } = useI18n();
const { auth } = useAuth();
const { isOwner, isCaption } = useDetails(props.challenge);
const responsive = useResponsive();

const header = computed(() => {
    return [
        {
            name: 'position',
            text: responsive.sm
                ? t('component.leaderboard.idea-table.idea')
                : t('component.leaderboard.idea-table.rank'),
            grow: 0.5,
            hidden: !props.showPosition,
        },
        {
            name: 'title',
            text: responsive.sm ? '' : t('component.leaderboard.idea-table.title'),
            grow: 10,
        },
        {
            name: 'solver',
            text: responsive.sm ? '' : t('component.leaderboard.idea-table.innovator'),
            grow: 2,
        },
    ]
})

const payment = (idea) => {
    return {
        challenge_id: idea.challenge_id,
        idea_id: idea.id,
        innovator_id: idea.solver.id,
        amount: idea.stats.prize_winner_money,
        name: props.challenge.question_title
    }
};

const likeIdea = async (idea) => {
    const lastValue = idea.stats.catalyst_like;
    try {
        idea.stats.catalyst_like = !lastValue;
        await api.challenges.reactCatalystIdea(
            props.challenge.slug, {
            hide: !!idea.stats.catalyst_hidden,
            like: !lastValue,
            idea: idea.id,
            challenge_id: props.challenge.id,
        });
    } catch (error) {
        idea.stats.catalyst_like = lastValue;

        throw error;
    }
}

const resumeDetail = (item) => {
    const text = toRaw(item.detail)

    if (text.length > 500) {
        return truncate(text, {
            length: 500,
            separator: /,? +/,
        });
    }

    return text;
}
</script>

<script>
export default {
    name: 'IdeaTable',
}
</script>

<template>
    <FlexTable
        :header="header.filter(h => !h.hidden)"
        :items="ideas"
        :highlight="({ item }) => item.highlight"
        active-class="bg-blue-100 hover:bg-blue-100 rounded-md"
        header-class="border-b-2 pb-3 px-2"
        row-class="border-b border-dashed border-dust-300 !items-start"
        class="bg-white border rounded-lg px-2 py-4">
        
        <template #col:position="{ item }">
            <img
                v-if="item.medal && medals[item.medal]"
                class="community-card-user-placement-list__rank-medal min-w-7 w-7"
                :src="`/img/medals/${medals[item.medal]}.svg`"
                alt="medal"
            />
            <NMedal
                v-else
                :medals="{}"
                :position="item.position"
                :star="item.medal === MEDAL.BLUE"
                class="w-7"
            />
        </template>

        <template #col:title="{ item }">
            <NExpansionPanel :data-idea-id="item.id" :model-value="isCaption || item.expand" class="pr-3">
                <template #header="{ on, active }">
                    <div class="flex cursor-pointer overflow-hidden" v-on="on" >
                        <h4 class="ml-0 mb-2 font-semibold flex-grow text-lg">
                            {{ item.title }}
                        </h4>
                    </div>

                    <div v-if="!active && !!item.detail">
                        <p class="break-words">{{ resumeDetail(item) }}</p>
                    </div>
                </template>
                <div class="overflow-hidden">
                    <span v-if="!item.primary_image && !item.detail" class="is-color-medium-gray">
                        {{ $t('common.no-detail') }}
                    </span>
                    <NMarkdown :code="item.detail" :unfurl="true" class="w-full" />
                    <div v-if="item.primary_image" class="mb-2">
                        <a href="#" @click.prevent="() => ($refs.showImage.open({ source: item.primary_image, alt: item.title }))"
                        >
                            <MemeGenerator
                                v-if="isCaption"
                                :setup-text="!!item.detail ? item.title : null"
                                :punchline-text="!!item.detail ? item.detail : item.title"
                                :image-src="challenge.primary_image"
                                class="bg-dust-100 mt-1 w-full !max-w-[25rem] rounded-sm"
                            />
                            <img v-else :src="item.primary_image" :alt="item.title" class="bg-dust-100 mt-1 max-w-[17.5rem] rounded-sm">
                        </a>
                    </div>
                </div>
                <template #footer="{ on, active }">
                    <div v-if="!active" class="flex justify-end">
                        <button aria-label="button" v-on="on" type="button" class="flex items-center gap-1 px-2 py-1">
                            <NIcon :as="active ? 'contract' : 'expand'" class="text-dust-700" />
                            <span>{{ active ? 'contract' : 'expand' }}</span>
                        </button>
                    </div>
                </template>
            </NExpansionPanel>

            <div v-if="isOwner" class="flex items-center justify-center flex-wrap gap-2 my-4">
                <IdeaContentProvider :ideaId="item.id" :challengeSlug="challenge.slug">
                    <IdeaTools :challenge="challenge" :idea="item" />
                </IdeaContentProvider>
            </div>
        </template>

        <template #col:solver="{ item }">
            <div class="flex flex-wrap sm:flex-col gap-3 items-end justify-between min-w-[12rem]">
                <NPlayerDetails :user="item.solver" simple medium class="mr-auto" />
            
                <div v-if="showPrizeMoney && item.stats.prize_winner_money" class="flex items-center space-x-1">
                    <span class="bg-dust-100 py-3 px-4 rounded-md flex items-center gap-2 leading-none min-w-25 font-bold">
                        <NIcon as="money-bill-alt-regular" class="mr-1" />
                        <span class="whitespace-nowrap">$ {{ item.stats.prize_winner_money }}</span>
                    </span>
                    <template v-if="$auth.isAdmin">
                        <NDataForm v-if="item.stats.payment_wallet_type && item.stats.prize_winner_money > 0" action="admin/payout/challenge" :data="payment(item)" confirm @success="item.stats.is_payed = true">
                            <template #default="form">
                                <button aria-label="button"
                                    type="submit"
                                    class="btn is-elevated py-2.6 px-4 justify-center is-color-white min-w-30"
                                    :class="{ 'is-blue': ! form.errors.any(), 'is-blue': ! item.stats.is_payed  }"
                                    :disabled="form.busy || item.stats.is_payed"
                                >
                                    <NIcon v-if="item.stats.payment_wallet_type" :as="item.stats.payment_wallet_type.toLowerCase()" class="mr-2" />
                                    {{ (form.success || item.stats.is_payed) ? 'Paid' : (form.busy ? 'Paying' : (form.errors.any() ? 'Error!' : 'Payment')) }}
                                </button>
                            </template>
                        </NDataForm>
                        <span v-if="(!item.stats.payment_wallet_type && item.stats.prize_winner_money > 0)" class="min-w-30 bg-orange-100 py-4 px-4 rounded-md flex items-center gap-2 leading-none min-w-10 whitespace-nowrap">
                            <NIcon as="exclamation-circle-solid" class="text-orange-500" />
                            <span class="text-orange-500">No wallet</span>
                        </span>
                    </template>
                </div>
            </div>
        </template>

        <template #no-results>
            <div class="py-8 w-full flex items-center justify-center">
                <NLoadingNerve v-if="loading" />
                <slot v-else name="empty">{{ emptyMessage }}</slot>
            </div>
        </template>

        <template #before>
            <NModal ref="showImage" #default="{ visible, source, alt }">
                <img v-if="visible" class="img-responsive max-h-[80vh]" :src="source" :alt="alt">
            </NModal>
        </template>
    </FlexTable>
</template>
