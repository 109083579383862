import { useResponsive } from '@/composable/responsive';

export const install = ({ app }) => {
  app.mixin({
    beforeCreate() {
      const responsive = useResponsive();

      this.$responsive = responsive;
    }
  });
};
