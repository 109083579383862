<script>
import NTabsMenu from './TabsMenu.vue';
import { kebabCase } from 'lodash'
import { cn } from '@/utils/Helpers';

export default {
    name: 'Tabs',

    provide () {
        return {
            tabsContext: this,
        };
    },

    props: {
        modelValue: String,
        align: {
            type: String,
            default: 'between',
            validator (value) {
                return ['start', 'center', 'end', 'between'].includes(value);
            }
        },
        outlined: {
            type: Boolean,
            default: false
        },
        subheader: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'full',
            validator (value) {
                return ['icons', 'full', 'menu'].includes(value)
            },
        },
        activeClass: {
            type: String,
            default: 'is-active'
        },
        tabClass: {
            type: String,
            default: ''
        },
    },

    emits: ['change', 'update:modelValue'],

    components: { NTabsMenu },

    mounted() {
        this.prune();
    },

    watch: {
        active: {
            handler (newId) {
                this.$emit('change', newId);

                this.scrollToActiveTab();
            },
            immediate: true,
        },
        tabs: {
            handler() {
                this.$nextTick(() => {
                    this.prune();
                });
            },
            deep: true
        }
    },

    data() {
        return {
            tabs: [],
            menu: false
        };
    },

    computed: {
        active: {
            get() {
                return kebabCase(this.modelValue);
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.$emit('change', value);
            }
        },
        asMenu () {
            return this.$responsive.sm && this.mode === 'menu'
        },
        activeTabs () {
            const tabs = this.tabs.filter(t => !t.hidden);
            if (this.asMenu) {
                return tabs.filter(tab => tab.isOpen)
            }

            return tabs;
        },
        inactiveTabs () {
            const tabs = this.tabs.filter(t => !t.hidden);
            return this.asMenu ? tabs.filter(t => !t.hidden).filter(tab => !tab.isOpen) : [];
        },
        hasActions () {
            return !!this.$slots.actions;
        }
    },

    methods: {
        prune () {
            if (!this.active && this.tabs.length) {
                const [tab] = this.tabs;

                this.selectTab(tab.computedId);
            }
        },
        selectTab(id, menuStatus = false) {
            this.active = id;
            this.menu = menuStatus;
        },
        scrollToActiveTab () {
            this.$nextTick(() => {
                if (!this.$responsive.sm) {
                    return;
                }

                const el = this.$el.querySelector('.is-active');

                if (el) {
                    setTimeout(() => {
                        el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'start'
                        });
                        el.focus();
                    }, 300)
                }
            });
        }
    },
    beforeUnmount() {
        this.tabs = [];
    },
}
</script>

<script setup>
import { h, defineComponent } from 'vue';
import { useElementVisibility, useCurrentElement } from '@vueuse/core';

const el = useCurrentElement()
const isVisible = useElementVisibility(el)

const NTabButton = defineComponent({
    name: 'NTabButton',
    props: { target: Object },
    setup: (props, { slots, attrs }) => {
        return () => h('li', {
            ...attrs,
            class: [
                'whitespace-nowrap min-w-[60%] sm:min-w-[10px] snap-start',
                attrs.class,
                props.target.class,
            ],
        }, slots && slots.default() );
    }
});
</script>

<template>
    <div class="tabs" :class="{ 'is-bordered': outlined }">
        <div
            class="tabs__header flex flex-row"
            :class="{
                'tabs__content-helper py-2 px-0': subheader,
                'items-end': asMenu,
                [`justify-${align}`]: !$responsive.sm,
            }">
            <ul class="flex overflow-x-auto sb-tight sb-dust-200 snap-x w-full sm:w-auto">
                <NTabButton v-for="(tab, index) in activeTabs" :id="tab.computedId" :data-tab-id="tab.computedId" :target="tab" :key="index" :class="{'w-full sm:w-auto' :  asMenu }">
                    <a v-if="tab.disabled" :class="cn('!flex items-center justify-center py-4 px-4 sm:py-3 sm:px-4', tabClass, tab.class, tab.disabled && 'is-disabled')">
                        <NIcon v-if="tab.icon" :as="tab.icon" left />
                        <span v-if="mode !== 'icons' || tab.isOpen">{{ tab.title }}</span>
                    </a>

                    <router-link v-else-if="tab.to" :to="tab.to" :class="cn('!flex items-center justify-center py-4 px-4 sm:py-3 sm:px-4', tabClass, tab.class, tab.isOpen && activeClass)" :active-class="activeClass">
                        <NIcon v-if="tab.icon" :as="tab.icon" left />
                        <span v-if="mode !== 'icons' || tab.isOpen">{{ tab.title }}</span>
                    </router-link>

                    <a v-else :href="`#${tab.computedId}`" :class="cn('!flex items-center justify-center py-4 px-4 sm:py-3 sm:px-4', tabClass, tab.class, tab.isOpen && [activeClass, tab.activeClass])" @click.prevent="selectTab(tab.computedId)">
                        <NIcon v-if="tab.icon" :as="tab.icon" left />
                        <span v-if="mode !== 'icons' || tab.isOpen">{{ tab.title }}</span>
                    </a>
                </NTabButton>
            </ul>

            <NTabsMenu v-if="asMenu" v-model="menu" :width="50" class="ml-2 w-1/3 flex items-end justify-center">
                <template #activator="{ on }">
                    <button aria-label="button" v-on="on" class="btn has-no-style text-center">
                        <NIcon as="ellipsis-h-solid" class="font-size-1-2-rem" />
                    </button>
                </template>

                <div class="p-2 tab-menu min-w-60">
                    <ul>
                        <NTabButton v-for="(tab, index) in inactiveTabs" :target="tab" :data-tab-id="tab.computedId" :id="tab.computedId" :key="index" >
                            <a v-if="tab.disabled" :class="{ 'is-disabled': tab.disabled }" class="flex items-center">
                                <slot :name="`header:${tab.computedId}`" v-bind="tab">
                                    <NIcon v-if="tab.icon" :as="tab.icon" left />{{ tab.title }}
                                </slot>
                            </a>

                            <router-link v-else-if="tab.to" :to="tab.to" class="flex items-center" :active-class="activeClass">
                                <slot :name="`header:${tab.computedId}`" v-bind="tab">
                                    <NIcon v-if="tab.icon" :as="tab.icon" left />{{ tab.title }}
                                </slot>
                            </router-link>

                            <a v-else :href="`#${tab.computedId}`" :class="{ [activeClass]: tab.isOpen }" class="flex items-center" @click.prevent="selectTab(tab.computedId)">
                                <slot :name="`header:${tab.computedId}`" v-bind="tab">
                                    <NIcon v-if="tab.icon" :as="tab.icon" left />{{ tab.title }}
                                </slot>
                            </a>
                        </NTabButton>
                    </ul>
                    <div v-if="hasActions" class="ml-3 mt-1">
                        <slot name="actions" />
                    </div>
                </div>
            </NTabsMenu>

            <div v-else-if="hasActions" class="ml-3">
                <slot name="actions" />
            </div>
        </div>
        <div class="tabs__content">
            <slot />
        </div>
    </div>
</template>
