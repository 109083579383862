import Cookie from './Cookie';

const authKeyName = import.meta.env.VUE_APP_AUTH_COOKIE_NAME;
const authKeyNameShadow = `${authKeyName}_shadow`;

export const Session = {
    setToken (token) {
        return Cookie.set(authKeyName, token, true, 7);
    },
    getToken () {
        return Cookie.get(authKeyNameShadow) || Cookie.get(authKeyName);
    },
    isAuthenticated () {
        return Cookie.has(authKeyName) || Cookie.has(authKeyNameShadow);
    },
    exists () {
        return Cookie.has(authKeyName) || Cookie.has(authKeyNameShadow);
    },
    remove () {
        Cookie.delete(authKeyName, true);
        Cookie.delete(authKeyName, false);
        Cookie.delete(authKeyNameShadow);
    },
    isShadow () {
        return Cookie.has(authKeyNameShadow);
    },
    startShadow (token) {
        return Cookie.set(authKeyNameShadow, token);
    },
    stopShadow () {
        return Cookie.delete(authKeyNameShadow);
    },
}