<script setup>
import TitleField from '@/components/challenge/TitleField.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import Categories from '@/components/game/Categories.vue';
import { inject } from 'vue';

const form = inject('form');
</script>

<template>
    <div class="form-groups">
        <div class="form-group">
            <label class="form-group__label mb-3">
                Tap either "What could" or "How could" to begin your question:
            </label>
            <TitleField
                v-model="form.fields.challenge.question_title"
                :initial-value="form.fields.challenge.question_title"
                name="question_title"
                required
            />
        </div>
        
        <div class="form-group">
            <div class="quill-editor-wrapper">
                <span class="quill-editor-title">
                    Describe your idea for a new question
                </span>
                <TextEditor
                    name="idea_description"
                    v-model="form.fields.challenge.question_detail"
                    placeholder="Write as much as you need to describe your idea for a question"
                    required
                />
            </div>
        </div>

        <div class="form-group">
            <label class="form-group__label">
                Choose a Category for your Question Topic:
            </label>
                
            <NField name="platform_category" :modelValue="form.fields.challenge.platform_category" required #default="{ invalid, clearErrors }">
                <Categories
                    :modelValue="{ id: form.fields.challenge.platform_category }"
                    :class="{ 'border-red-500': invalid }"
                    :select="c => !c.id.includes('caption')"
                    class="w-full bg-white rounded-md border-dust-400 border"
                    required
                    @update:modelValue="({ id }) => (form.fields.challenge.platform_category = id, clearErrors())"
                />
            </NField>
        </div>

        <NExpansionPanel :model-value="false">
            <template #header="{ on, active }">
                <button aria-label="button" type="button" class="flex items-center mb-6 px-3 py-3 rounded-lg cursor-pointer w-full hover:bg-director-100 " v-on="on">
                    <div class="mr-2">
                        <img class="w-9" src="/img/misc-ui-icons/deep-dive.svg" alt="deep dive">
                    </div>
                    <div>
                        <label class="form-group__label m-0">
                            Describe your three Core Assumptions

                            <span class="form-inline-help-text ml-1">(Optional)</span>
                        </label>
                        <span class="flex items-center italic text-dust-800">
                            Tap to {{ active ? 'collapse' : 'expand' }}
                            <NIcon :as="active ? 'caret-square-up-solid' : 'caret-square-down-solid'" class="ml-2" />
                        </span>
                    </div>
                </button>
            </template>

            <div class="form-groups">
                 <div class="form-group">
                    <label class="form-group__label">
                        {{ $t('component.challenge.assumptions.core-cause.what.label') }}
                    </label>
                    <div class="w-full">
                        <NTextarea name="assumption_what" v-model="form.fields.challenge.assumption_what" :placeholder="$t('component.challenge.assumptions.core-cause.what.placeholder')" />
                        <div class="textarea-with-info__more">
                            <span class="textarea-with-info__more-caption">
                                <NIcon as="check-circle-solid" class="text-dust-600" :class="{ 'text-nerve': form.fields.challenge.assumption_what }" left />
                                <span class="question-word">
                                    {{ $t('component.challenge.assumptions.core-cause.what.word') }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-group__label">
                        {{ $t('component.challenge.assumptions.core-cause.who.label') }}
                    </label>
                    <div class="w-full">
                        <NTextarea name="assumption_who" v-model="form.fields.challenge.assumption_who" :placeholder="$t('component.challenge.assumptions.core-cause.who.placeholder')" />
                        <div class="textarea-with-info__more">
                            <span class="textarea-with-info__more-caption">
                                <NIcon as="check-circle-solid" class="text-dust-600" :class="{ 'text-nerve': form.fields.challenge.assumption_who }" left />
                                <span class="question-word">
                                    {{ $t('component.challenge.assumptions.core-cause.who.word') }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-group__label">
                        {{ $t('component.challenge.assumptions.core-cause.why.label') }}
                    </label>
                    <div class="w-full">
                        <NTextarea name="assumption_why" v-model="form.fields.challenge.assumption_why" :placeholder="$t('component.challenge.assumptions.core-cause.why.placeholder')" />
                        <div class="textarea-with-info__more">
                            <span class="textarea-with-info__more-caption">
                                <NIcon as="check-circle-solid" class="text-dust-600" :class="{ 'text-nerve': form.fields.challenge.assumption_why }" left />
                                <span class="question-word">
                                    {{ $t('component.challenge.assumptions.core-cause.why.word') }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </NExpansionPanel>
    </div>
</template>
