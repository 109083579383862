export default requester => {
    return {
        /**
         * Get all catalyst picked ideas.
         *
         * @param  {object}  params
         * @return {object}
         */
        catalystPickedIdeas(slug, params) {
            return requester.get(`/challenges/${slug}/catalyst/picker`, { params });
        },

        /**
         * Update the position of the catalyst picked ideas.
         *
         * @param  {object}  params
         * @return {object}
         */
        catalystUpdatePickedIdeas(slug, payload) {
            return requester.put(`/challenges/${slug}/catalyst/picker`, payload);
        },

        /**
         * Get all ideas of the catalyst challenge.
         *
         * @param  {object}  params
         * @return {object}
         */
        catalystIdeas(slug, params) {
            return requester.get(`/challenges/${slug}/catalyst/ideas`, { params });
        },

        /**
         * Get catalyst idea stats.
         *
         * @param  {object}  params
         * @return {object}
         */
        catalystIdeaContent(challengeSlug, ideaId) {
            return requester.get(`/challenges/${challengeSlug}/catalyst/ideas/${ideaId}/contents`);
        },
        
        /**
         * React to a catalyst idea.
         *
         * @param  {object}  payload
         * @return {object}
         */
        reactCatalystIdea(slug, payload) {
            return requester.post(`/challenges/${slug}/catalyst/ideas`, payload);
        },

        /**
         * Get all mentorings to rate
         *
         * @param  {object}  params
         * @return {object}
         */
        mentoringToRate({ challengeSlug, ideaId }, params) {
            return requester.get(`/challenges/${challengeSlug}/mentoring-to-rate/${ideaId}`, { params });
        },

        /**
         * Get challenge idea
         *
         * @param {string} ideaId
         * @return {object}
         */
        idea(ideaId) {
            return requester.get(`/challenges/ideas/${ideaId}`)
        },

        /**
         * Get challenge ideas
         *
         * @param {string} slug
         * @return {object}
         */
        ideas(slug) {
            return requester.get(`/challenges/${slug}/ideas`)
        },

        /**
         * Rate the challenge
         *
         * @param {string} slug
         * @return {object}
         */
        rate(slug, payload = {}) {
            return requester.post(`/challenges/${slug}/ratings`, payload)
        },

        /**
         * Duplicate challenge
         *
         * @param {string} slug
         * @return {object}
         */
        duplicate(slug, payload = {}) {
            return requester.post(`/challenges/${slug}/duplicate`, payload)
        },

        /**
         * Get challenge promoted ideas
         *
         * @return {object}
         */
         promotions(slug) {
            return requester.get(`/challenges/${slug}/promoted`)
        },

        /**
         * Get challenge prizes ideas
         * 
         * @param {string} slug
         */
        prizes (slug) {
            return requester.get(`/prizes/challenge/${slug}`)
        },

        /**
         * Send challenge via email
         *
         * @param {string} slug
         * @return {object}
         */
        send(slug, payload = {}) {
            return requester.post(`/challenges/${slug}/send`, payload)
        },

        /**
         * Categorify challenge
         *
         * @param {string} slug
         * @return {object}
         */
        categorify(params = {}) {
            return requester.get(`/challenges/categorify`, { params });
        },

        /**
         * Get suggestion
         */
        suggestion(platformSlug, type, params = {}) {
            return requester.get(`/${platformSlug}/suggestions/${type}`, { params });
        },

        /**
         * Get priors suggestion
         */
        priorSuggestions(platformSlug, type, params = {}) {
            return requester.get(`/${platformSlug}/suggestions/${type}/priors`, { params });
        },

        /**
         * Search challenges
         */
        search(params = {}) {
            return requester.get(`search/challenges`, { params });
        },

        /**
         * Search challenges
         */
        searchScroll(params = {}) {
            return requester.get(`search/challenges`, { params });
        },

        /**
         * Update challenge
         */
        update (slug, payload = {}) {
            return requester.put(`challenges/${slug}/catalyst/updates`, payload);
        },

        /**
         * Catalyst update idea
         */
        catalystUpdateIdea (slug, payload = {}) {
            return requester.post(`challenges/${slug}/catalyst/ideas`, payload);
        },

        /**
         * Use challenge assistant
         */
        assistant (tool, params = {}) {
            return requester.get(`challenges/assistant/${tool}`, { params });
        }
    };
}
