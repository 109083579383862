export default {
    idea: {
        title: null,
        purpose: null,
        description: null,
        primary_image: null
    },
    challenge: {
        tags: [],
        slug: null,
        question_title: null,
        question_detail: null,
        article: null,
        primary_image: null,
        duration_end: null,
        duration_start: null,
        // platform_category: 'TMTTRT51CX-social-issues--social-issues-a8fe4127-10000',
        platform_category: null,
        innovation_scale: 'blue-sky',
        test_idea_format: null,
        improve_purpose_idea: null,
        massaging_improve_effect: null,
        topic_domain_space: null,
        caption_reaction_type: null,
        caption_solution_format: null,
        assumption_what: null,
        assumption_who: null,
        assumption_why: null,
        prize_type: 'points',
        prize_money: '0',
        prize_description: null,
        solution_format: null,
    },
    deep_dives: {
        who: {
            demograph_gender: [],
            demograph_age: { to: null, from: null },
            media_consumed: null,
            who_interests_hobbies: [],
            occupation: [],
            location: [],
            psychographics: [],
            jobs_to_be_done: null
        },
        what: {
            core_cause_1st: {
                string: null,
                supporting: null
            },
            core_cause_2nd: {
                string: null,
                supporting: null
            },
            core_cause_3rd: {
                string: null,
                supporting: null
            },
            feasibility_choice: null,
            impact_steps: [{ text: null, selected: true }]
        },
        why: {
            anthropology_experiments: null,
            personal_reasons: [{ reason: null, strength: 5 }],
            stakeholder_reasons: [{ reason: null, strength: 0 }]
        }
    },
    prize: {
        prize_type: 'points',
        prize_money: '0',
        prize_title: null,
        prize_description: null
    },
    idea_entry_process: {
        idea_entry_fee: 0,
        idea_entry_type: "idea_entry_fee",
    },
    solution_format: null,
    platform_domain: null,
    article_skiped: false,
}
