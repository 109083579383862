<script>
import DropdownMenu from '@/components/NDropdownMenu.vue';

export default {
    name: 'TabsMenu',

    extends: DropdownMenu
}
</script>

<template>
    <div class="tabs-menu relative" @keyup.esc="close()">
        <div v-click-outside="close" class="tabs-menu__wrapper w-full flex items-end justify-center py-2" :class="{ 'is-active': active }">
            <slot name="activator" as="one-test" :on="{ click: toggle, 'click.outside': toggle }" />
            <div v-show="active" class="tabs-menu__wrapper-content z-20">
                <slot />
            </div>
        </div>
    </div>
</template>

