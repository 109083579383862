<script>
import ShareNetwork from '@/components/ShareNetwork.vue';
import TwitterPreview from './TwitterPreview.vue';
import { SOLUTION_FORMAT } from '@/constant/challenge';
import { stripHtml, currency } from '@bignerve/ui-utils';
import { get } from 'lodash';
import { mapState } from 'vuex';
import { isAMA, isCaption } from '@/utils/challenge';
import { useFeatureFlag } from '@/composable/feature-flag';
import { useAuth } from '@/composable/auth';

const isChallenge = (target) => target.hasOwnProperty('solution_format');
const handleAccessor = (user, fallback) => user?.handle || user?.screen_name || fallback;

export default {
    components: {
        ShareNetwork,
        TwitterPreview,
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        query: {
            type: Object,
            default: () => ({})
        },
        defaultContent: {
            type: String,
            default: ''
        },
    },
    emits: ['done'],
    data() {
        return {
            snoozing: false,
            preparing: false,
            copied: false,
            surveyMode: true,
            error: null,
            content: this.defaultContent,
            networks: [
                { network: 'twitter', name: 'Twitter', icon: 'twitter', enabled: true, sharing: false },
                { network: 'facebook', name: 'Facebook', icon: 'facebook-f-brands', enabled: true, sharing: false },
                { network: 'email', name: 'email', icon: 'envelope-regular', enabled: true, sharing: false },
                { network: 'imgur', name: 'Imgur', icon: 'imgur', enabled: false, sharing: false },
                { network: 'reddit', name: 'Reddit', icon: 'reddit-alien-brands', enabled: true, sharing: false },
            ],
            sharing: {
                url: '',
                subject : '',
                title: 'BigNerve',
                description: '',
                quote: '',
                hashtags: '',
                credentials: [],
            },
        };
    },
    computed: {
        ...mapState('app/sharing', ['snoozeds']),
        target () {
            return this.item;
        },
        challenge () {
            return isChallenge(this.target) ? this.target : {};
        },
        user() {
            return this.$store.getters['auth/currentUser'].user;
        },
        route() {
            let route = {};
            if (isChallenge(this.target)) {
                route = this.resolveChallengeRoute();
            } else if (handleAccessor(this.target.user)) {
                route = this.resolveUserRoute();
            }

            const target = this.$router.resolve(Object.assign({}, route, { query: this.queryString }));

            return target ? target.href : '/';
        },
        queryString() {
            const q = {
                scout_id: null,
                ...this.$route.query,
                ...this.query,
            };

            if (this.user) {
                q.scout_id = handleAccessor(this.user);
            }

            return q;
        },
        url() {
            return window.location.origin + this.route;
        },
        canCopy () {
            return Boolean(navigator && 'clipboard' in navigator)
        },
        totalSnoozed () {
            return this.snoozeds.length;
        },
        snoozed () {
            return this.snoozeds.some((snoozed) =>
                snoozed.payload.metadata.target === this.target.slug
            );
        },
        isOwner () {
            return this.$auth.authenticated
                && this.challenge.catalyst
                && this.challenge.catalyst.id === this.$auth.user.id;
        },
        surveyModeAllowed () {
            return this.challenge.solution_format === SOLUTION_FORMAT.CHALLENGE
                && !this.challenge.is_closed
                && !isCaption(this.challenge)
                && !this.challenge.idea_entry_fee
                && this.surveyModeEnabled;
        },
    },

    watch: {
        target: {
            handler: 'prepareSharing',
            deep: true,
        },
        surveyMode: {
            handler: 'prepareSharing',
        },
    },

    methods: {
        isCaption,
        handleAccessor,
        async copy() {
            this.$refs.input.select();
            this.$refs.input.setSelectionRange(0, 99999);
            
            await window.navigator.clipboard.writeText(this.url)
            
            this.copied = true;
            setTimeout(() => this.copied = false, 5000);
        },
        async prepareSharing () {
            this.preparing = true;

            if (isChallenge(this.target)) {
                const content = await this.resolveChallengeData(this.target);

                if (!content) {
                    this.preparing = false;
                    return;
                }

                this.sharing.title = content.text;
                this.sharing.description = content.description;
                this.sharing.hashtags = content.hashtags.join(',');
                this.sharing.credentials = content.metadata.credentials || [];
                this.sharing.subject = 'challenge';
                this.sharing.url = this.url;

                this.content = content;
            }
            if (handleAccessor(this.target.user)) {
                await this.resolveUserData(this.target);

                this.sharing.title = [
                    `Join in the ${this.target.user.first_name} ${this.target.user.last_name} #ideatournament`,
                    this.target.deep_prize && `- overall prize $${this.target.deep_prize}.`,
                    this.target.twitter && `@${this.target.twitter}`,
                ].filter(v => v).join(' ');
                this.sharing.description = stripHtml(this.target.user.slogan);
                this.sharing.hashtags = ['bignerve'].join(',');
                this.sharing.url = this.url;
                this.sharing.subject = 'user';
            }

            this.preparing = false;
        },

        async resolveChallengeData ({ slug }) {
            try {
                const { data: response } = await this.$api.sharing.intent({
                    options: { ...this.query, skip_ideas: false, survey_mode: this.surveyMode },
                    subject: 'challenge',
                    target: slug,
                });

                return response.data;
            } catch (err) {
                console.log(err)

                this.$sentry.withScope(scope => {
                    scope.setTag('event', 'sharing-user');
                    scope.setExtra('target', this.target);
                    scope.setExtra('sharing', this.sharing);
                    
                    this.$sentry.captureException(err);
                });
            }
        },

        async resolveUserData () {
            try {
                const targetUserHandle = handleAccessor(this.target.user);
                const currentUserHandle = handleAccessor(this.$auth.user);
                const useTournament = slug => this.$api.widgets.olympiads(
                    'base_olympiad_id', { platform_slug: slug }
                ).then(r => r.data);

                if (!this.$auth.authenticated || currentUserHandle !== targetUserHandle) {
                    const social = await this.$api.user.social(targetUserHandle).then(r => r.data.data);
                    const twitter = get(social, 'accounts.twitter.handle', null);
                    
                    this.target.twitter = twitter;
                }

                const userTournament = await useTournament(targetUserHandle)
                let prize = get(userTournament, 'current.olympiad.prize_total', 0);

                // if not user tournament prize, try to get from bignerve prize
                if (!prize) {
                    const platformTournament = await useTournament('bignerve');
                    prize = get(platformTournament, 'current.olympiad.prize_total', 0);
                }

                this.target.deep_prize = prize && currency(prize);
            } catch (err) {
                console.log(err)

                this.$sentry.withScope(scope => {
                    scope.setTag('event', 'sharing-user');
                    scope.setExtra('target', this.target);
                    scope.setExtra('sharing', this.sharing);
                    
                    this.$sentry.captureException(err);
                });
            }
        },

        beforeShare (next, provider) {
            const checks = [
                ['twitter'].includes(provider),
                ['challenge'].includes(this.sharing.subject),
                get(this.content, 'metadata.credentials', []).includes('twitter'),
            ]

            if ( checks.every(v => v) ) {
                this.$emit('done');

                this.$bus('sharer:share', {
                    provider, payload: this.content,
                });

                return;
            }

            next();
        },

        async snoozeSharing () {
            try {
                this.snoozing = true;
                const { data: response } = await this.$api.sharing.snooze({
                    payload: this.content,
                });

                this.$store.commit('app/sharing/setSnoozeds', response.data.items);
            } finally {
                this.snoozing = false;
            }
        },

        countSnoozedSharings () {
            if (this.$auth.authenticated) {
                this.$store.dispatch('app/sharing/getSnoozed');
            }
        },

        resolveChallengeRoute () {
            if (isAMA(this.challenge)) {
                return {
                    name: 'suggestion-box.ama',
                    params: { user: handleAccessor(this.target.catalyst) }
                }
            }
            
            return { name: this.surveyMode && this.surveyModeAllowed ? 'challenges.survey' : 'challenges.show', params: { challenge: this.target.slug } };
        },
        resolveUserRoute () {
            return { name: 'home.user', params: { user: handleAccessor(this.target.user) } };
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.prepareSharing();
            this.countSnoozedSharings();
        });
    },

    setup () {
        const { auth } = useAuth();
        const { expect } = useFeatureFlag('survey-mode-link');
        const surveyModeEnabled = expect(() => auth.user.id);

        return {
            surveyModeEnabled
        }
    }
}
</script>

<template>
    <div class="sharing-popup-panel m-3 transition-xy transition-transform duration-500 ease-in-out">
        <div class="sharing-popup-panel__heade flex items-center justify-between bg-nerve text-white  rounded-t-lg px-4 py-2">
            <div class="flex items-center">
                <NIcon as="share-square-solid" class="mr-2 -mt-1" />
                <h2 class="m-0 leading-none">Share</h2>
            </div>
            <slot name="actions" />
        </div>

        <div class="sharing-popup-panel__body">
            <label class="form-group__label font-family-heading">
                Here is your personalized sharing “scout” URL:
            </label>
            <div class="flex items-center">
                <input ref="input" type="url" readonly class="form-group__input is-gray border" :value="url">
                <button aria-label="button" v-if="canCopy" type="button" class="btn is-orange ml-2 w-25 flex justify-center focus:!outline-none" @click.prevent="copy">
                    {{ copied ? 'Copied!' : 'Copy' }}
                </button>
            </div>

            <div v-if="(!isCaption(challenge) && challenge.catalyst && isOwner && surveyModeAllowed)" class="flex items-center gap-2 py-4 leading-none text-sm px-1 border-bottom-2 border-dust-400 border-dashed">
                <label for="check-survey-mode" class="font-semibold">Enable Survey Mode?</label>
                <input v-model="surveyMode" type="checkbox" name="check-survey-mode" class="h-4 w-4" />
                <span><b>Yes</b> (allows anon replies)</span>
                <NIcon v-tooltip="'Survey mode opens your Question up to a wider group of people; <br/>respondents don\'t need to create an account, and the idea collection <br/>and rating screens are simplified. It\'s best to do this for your <br/>newsletter readers, who are more invested in helping you.'" as="question-circle-solid" class="text-nerve" />
            </div>

            <div class="mt-4">
                <p class="font-family-heading"><b>Post directly to your social channels</b>:</p>

                <NAlert v-if="error" type="danger" open>
                    {{ error }}
                </NAlert>

                <div class="sharing-popup-panel__btns relative">
                    <div v-if="preparing" class="absolute text-gray-700 font-bold inset-0 bg-white bg-opacity-80 flex items-center justify-center">Composing message...</div>

                    <ShareNetwork
                        v-for="network in networks"
                        :network="network.network"
                        :key="network.network"
                        :url="sharing.url"
                        :title="sharing.title"
                        :description="sharing.description"
                        :quote="sharing.quote"
                        :hashtags="sharing.hashtags"
                        :twitterUser="sharing.twitterUser"
                        :disabled="!network.enabled"
                        :before-share="beforeShare"
                        class="sharing-btn"
                        tag="button"
                        @open="$emit('done')"
                        @failure="error = $event"
                    >
                        <span class="min-w-8 -mr-1 flex justify-center items-center">
                            <NLoading v-if="network.sharing" class="text-secondary" loading />
                            <NIcon v-else :as="network.icon" />
                        </span>
                        <span>{{ network.name }}</span>
                    </ShareNetwork>

                    <button aria-label="button" type="button" class="sharing-btn is-recruit" disabled>
                        <span aria-hidden="true" class="icon icon-binoculars-solid"></span>
                        Your Recruits
                    </button>
                </div>
            </div>
        </div>
        <div class="sharing-popup-panel__footer border-dust-400">
            <NAlert v-if="snoozed" type="success" open>
                This challenge was saved to share later.
            </NAlert>

            <div class="flex justify-between mb-2">
                <div class="text-center">
                    <div class="flex items-center">
                        <div :class="{ disabled: snoozed  || !content || !$auth.authenticated }">
                            <button aria-label="button" type="button" :class="{ 'animate animate-pulse': snoozing }" :disabled="snoozing || !content || !$auth.authenticated" class="font-bold px-3 py-2 rounded-md bg-nerve text-white hover:bg-nerve-600" @click="snoozeSharing">
                                {{ snoozing ? 'Saving...' : 'Share Later' }} 
                            </button>
                        </div>
                        <RouterLink v-if="$auth.authenticated && totalSnoozed" :to="{ name: 'home.user.snoozed-sharings', params: { user: handleAccessor($auth.user, 'me') } }" class="ml-3 !text-nerve hover:bg-nerve-200 font-bold bg-nerve-100 w-8 h-8 flex items-center justify-center rounded-full leading-none !underline-transparent">{{ totalSnoozed }}</RouterLink>
                    </div>
                    <p class="mt-1 mb-0 font-size-0-9-rem"><i>{{ snoozed ? 'Saved' : 'Save' }} to queue</i></p>
                </div>
                <div class="text-center">
                    <div :class="{ disabled: !$auth.authenticated }" class="flex items-center">
                        <RouterLink :to="{ name: 'home.user.snoozed-sharings', params: { user: handleAccessor($auth.user, 'me') } }" type="button" class="font-bold px-3 py-2 rounded-md bg-secondary !text-white hover:bg-secondary-600 hover:!text-white !no-underline">
                            Share Panel
                        </RouterLink>
                        <span aria-hidden="true" class="icon icon-paper-plane-solid is-color-secondary ml-2 font-size-1-4-rem" style="color: #999;"></span>
                    </div>
                    <p class="mt-1 mb-0 font-size-0-9-rem"><i>Check progress</i></p>
                </div>
            </div>
            <p class="mb-0 font-family-heading disabled">
                <b>Automate!</b> Set up your social integrations &amp; automation in the
                <a href="#">Advanced Sharing Panel</a>.
            </p>
        </div>
    </div>
</template>
