import { inject, computed } from "vue";
import { get, set } from "lodash";
import { createSharedComposable } from "@vueuse/core"

export const usePreferences = createSharedComposable(() => {
    const preferences = inject('preferences');

    const sync = (key, defaultValue) => {
        return computed({
            get () {
                return get(preferences, key, defaultValue);
            },
            set (value) {
                set(preferences, key, value);
            },
        })
    }

    return { preferences, sync };
})
