import { ref } from 'vue';
import { api } from '@/modules/services';

const drafts = ref([]);
const loading = ref(false);

export const useDrafts = (filter = {}) => {
    const fetch = async () => {
        if (loading.value) {
            return;
        }

        try {
            loading.value = true;
            const { data: response } = await api.drafts.find(filter);

            drafts.value = response.data;
        } finally {
            loading.value = false;
        }
    }

    const remove = async (id) => {
        await api.drafts.remove(id)
    }

    return {
        fetch,
        drafts,
        remove,
    };
};
