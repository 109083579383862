<script setup>
import { nanoid } from 'nanoid';
import { watch, computed } from 'vue';
import { useFloating } from '@/composable/floating';
import { useCrossPlatformState } from '@/composable/app';

const props = defineProps({
    placement: {
        type: String,
        default: 'bottom'
    },
    open: {
        type: Boolean,
        default: false
    },
    target: {
        type: String
    },
    title: {
        type: String
    },
    description: {
        type: String
    },
});

const viewed = useCrossPlatformState('bignerve.advice')(props.target);
const emits = defineEmits(['closed']);
const groupId = nanoid();
const { target, ...tooltip } = useFloating();

const showing = computed(() => {
    return props.target ? !viewed.value : props.open;
})

watch(
    () => props.open,
    (opended) => {
        if (!props.target) {
            opended ? tooltip.show() : tooltip.hide();
        }
    }
);

const hide = () => {
    tooltip.hide();

    viewed.value = true;
};
</script>

<template>
    <VTooltip
        :shown="showing"
        :placement="placement"
        :open-group="`advice-${groupId}`"
        :auto-hide="false"
        :triggers="[]"
        theme="advice"
        ref="target"
        @hide="$emit('closed')"
    >
        <slot />

        <template #popper>
            <div class="bg-white rounded-lg shadow-lg border max-w-xl p-2">
                <div class="bg-catalyst text-white px-3 pt-3 pb-1">
                    <slot name="content">
                        <h3>{{ title }}</h3>
                        <slot name="description">
                            <p class="mb-2">
                                {{ description }}
                            </p>
                        </slot>
                    </slot>
                    <div class="flex justify-end">
                        <button aria-label="button" class="p-2 inline-flex items-baseline" @click="hide">
                            {{ $t('common.go-it') }}<NIcon as="times-circle-regular" class="ml-2" />
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </VTooltip>
</template>
