export default requester => {
    return {
        /**
         * Get the rater dashboard.
         *
         * @param  {object}  params
         * @return {object}
         */
        rater(user, params = {}) {
            return requester.get(`/${user}/dashboards/rater`, { params });
        },

        /**
         * Get the scout dashboard.
         *
         * @param  {object}  params
         * @return {object}
         */
        scout(user, params = {}) {
            return requester.get(`/${user}/dashboards/scout`, { params });
        },

        /**
         * Get the innovator dashboard.
         *
         * @param  {object}  params
         * @return {object}
         */
        innovator(user, params = {}) {
            return requester.get(`/${user}/dashboards/innovator`, { params });
        },

        /**
         * Get the rater dashboard.
         *
         * @param  {object}  params
         * @return {object}
         */
        rater(user, params = {}) {
            return requester.get(`/${user}/dashboards/rater`, { params });
        },

        /**
         * Get the mentor dashboard.
         *
         * @param  {object}  params
         * @return {object}
         */
        mentor(user, params = {}) {
            return requester.get(`/${user}/dashboards/mentor`, { params });
        },

        /**
         * Get the catalyst dashboard.
         *
         * @param  {object}  params
         * @return {object}
         */
        catalyst(user, params = {}) {
            return requester.get(`/${user}/dashboards/catalyst`, { params });
        },

        /**
         * Retrives the dashboard metadata to fillup the frontend.
         *
         * @param {string} user
         * @param {object} params
         */
        meta (user, params = {}) {
            return requester.get(`/${user}/dashboards/meta`, { params });
        },

        /**
         * Get the prizes dashborad of the user.
         *
         * @param {string} user
         * @param {object} params
         */
        prizes (user, params = {}) {
            return requester.get(`/${user}/dashboards/prizes`, { params });
        }
    };
}
