import { pusher } from '@/modules/pusher';
import { tryOnScopeDispose } from '@vueuse/core';

export const useChannel = (channelName) => {
    const channel = pusher.subscribe(channelName);

    const listen = (event, callback) => {
        // unbind existing event before binding
        channel.unbind(event, callback);
        channel.bind(event, callback);
    };

    tryOnScopeDispose(() => {
        channel.unbind_all();
        pusher.unsubscribe(channel);
    });

    return {
        listen,
        pusher,
    };
}