<script>
import kebabCase from 'lodash/kebabCase';

export default {
    name: 'NTab',

    inject: {
        tabsContext: {
            default: null
        }
    },
    
    props: {
        title: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        icon: {
            type: String,
            required: false
        },
        activeClass: {
            type: String,
            required: false
        },
        to: {
            type: [String, Object],
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hidden: {
            type: Boolean,
            default: false
        },
        class: {
            type: String,
        }
    },

    emits: ['open', 'close'],

    watch: {
        isOpen(opened) {
            opened ? this.$emit("open") : this.$emit("close");
        }
    },

    computed: {
        computedId () {
            return kebabCase(this.to ? this.to.name : (this.id || this.title));
        },
        isOpen() {
            return this.tabsContext.active === this.computedId;
        }
    },

    created () {
        if (this.tabsContext) {
            this.tabsContext.tabs.push(this);
        }
    },

    beforeUnmount() {
        if (this.tabsContext) {
            const index = this.tabsContext.tabs.indexOf(this);
            if (index > -1) {
                this.tabsContext.tabs.splice(index, 1);
            }
        }
    },
}
</script>

<template>
    <div v-show="isOpen" class="tab">
        <slot :to="to" :id="id" :title="title" />
    </div>
</template>
