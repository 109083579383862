<script setup>
import { UseElementVisibility } from '@vueuse/components';
import { useInfiniteScroll, unrefElement } from '@vueuse/core';
import { ref, computed, watch, nextTick, h } from 'vue';
import { groupBy as groupItems, size as countItems, map as mapItems } from 'lodash';
import { cn } from '@/utils/Helpers';

const props = defineProps({
    header: {
        type: Array,
        default: () => []
    },
    items: {
        type: [Object, Array],
        default: () => []
    },
    headerClass: {
        type: [String, Array, Object],
        default: '',
    },
    bodyClass: {
        type: [String, Array, Object],
        default: '',
    },
    colClass: {
        type: [String, Array, Object],
        default: '',
    },
    rowClass: {
        type: [String, Array, Object],
        default: 'is-outlined',
    },
    hideHeader: {
        type: Boolean,
        default: false
    },
    groupBy: {
        type: [String, Function],
        default: null,
    },
    noResultsText: {
        type: String,
        default: 'No results found',
    },
    grouped: {
        type: Boolean,
        default: false
    },
    activeClass: {
        type: String,
        default: 'bg-blue-100 border-blue'
    },
    highlight: {
        type: Function,
        default: () => false,
    },
    infinity: {
        type: Boolean,
        default: false
    },
    buffer: {
        type: Number,
        default: 20
    },
    virtual: {
        type: Boolean,
        default: false
    },
    lineProvider: {
        type: [Object, Function],
        default: () => (_, { slots }) => slots.default()
    }
});

const emit = defineEmits(['selected']);

const flexTableEl = ref(null);
const size = ref(props.buffer);
const list = computed(() => props.items.slice(0, size.value));
const groups = computed(() => {
    if (!list.value?.length) {
        return [{
            title: null,
            items: [],
        }];
    }

    if (props.groupBy) {
        const grouped = groupItems(list.value, props.groupBy)
        
        return mapItems(grouped, ( items, title ) => ({ items, title }));
    }

    return props.grouped ? list.value : [{ items: list.value }]
});
const hasMore = computed(() => props.items.length > size.value);

const setSize = (n) => {
    size.value = n;
}

const scrollTo = async (index = 0) => {
    if (size.value < index) {
        setSize(index + props.buffer);
    }

    await nextTick();

    const tableEl = unrefElement(flexTableEl);
    const rowEl = tableEl.querySelector(`#row-${index}`);

    if (rowEl) {
        rowEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        rowEl.classList.add('bg-blue-100');

        setTimeout(() => {
            rowEl.classList.remove('bg-blue-100');
        }, 5000);
    }
}

watch(() => props.items, () => {
    size.value = props.infinity ? props.buffer : countItems(props.items);
}, { immediate: true });

if (props.infinity) {
    useInfiniteScroll(document, () => {
        if (!hasMore.value) return;

        setSize(size.value + props.buffer);
    }, { distance: 2000 });
}

function UseElementReady (_, { slots, attrs }) {
    return h('div', { ...attrs }, slots.default({ isVisible: true }))
}

defineExpose({ scrollTo, setSize });
</script>

<template>
   <div ref="flexTableEl">
        <slot name="header">
            <div v-if="!hideHeader" :class="cn('hidden justify-between text-dust-800 font-bold leading-5 mb-4 items-start line sm:flex', headerClass)">
                <div
                    v-for="(col, i) of header"
                    :key="`header-${i}`"
                    :style="!col.calss && { flex: col.grow || 1, 'text-align': col.align || 'left' }"
                    :class="cn('px-1', { 'cursor-pointer hover:underline': col.selectable }, col.class)"
                    @click="col.selectable && $emit(`selected`, col)"
                >
                    <slot :name="`header:${col.value || col.name}`" v-bind="col">
                        <span class="inline-flex items-center">
                            <NIcon v-if="col.icon" :as="col.icon" class="mr-2" /> {{ col.text }}
                        </span>
                        <template v-if="col.subtext">
                            <br>
                            <small class="font-normal">{{ col.subtext }}</small>
                        </template>
                    </slot>
                </div>
            </div>
        </slot>

        <slot name="before" />

        <div v-for="(group, i) of groups" :key="`group-${i}`" :class="bodyClass">
            <slot v-if="(grouped || groupBy) && group.title" name="group-header" :group="group" :index="i">
                <h3 class="text-dust-800 px-1 mt-4 mb-2">
                    {{ group.title }}
                </h3>
            </slot>
            <template v-if="$responsive.sm">
                <Component :is="virtual ? UseElementVisibility : UseElementReady" v-for="(item, i) of group.items" :id="`row-${i}`" :class="cn('flex flex-col mb-3 py-3 px-4', highlight({ item, group }) && activeClass, rowClass)"  #="{ isVisible }">
                    <Component :is="lineProvider" :item="item" #="provider">
                        <div v-for="(col, i) of header" :key="`col-${i}`" :class="cn('flex gap-4 items-center mb-3 w-full', colClass)">
                            <div v-if="col.text && ((col.value || col.name) !== 'user')" class="flex-1">
                                <strong>{{ col.text }}</strong>
                            </div>
                            <div>
                                <slot :name="`col:${col.value || col.name}`" :item="item" :header="col" :group="group" :highlight="highlight({ item, group })" :index="i" v-bind="provider"></slot>
                            </div>
                        </div>
                        <slot :name="`footer`" :item="item" :headers="header" :group="group" :highlight="highlight({ item, group })" :index="i" :slots="$slots" v-bind="provider"></slot>
                    </Component>
                </Component>
            </template>
            <template v-else>
                <Component :is="virtual ? UseElementVisibility : UseElementReady" v-for="(item, index) of group.items" :id="`row-${index}`" :class="{ 'min-h-[50px]': virtual }" #="{ isVisible }">
                    <Component :is="lineProvider" :item="item" #="provider">
                        <div v-if="virtual ? isVisible : true" :class="cn('p-2 flex justify-between items-center mb-3 hover:bg-dust-50', highlight({ item, group }) && activeClass, rowClass)">
                            <div v-for="(col, i) of header" :key="`col-${i}`" :class="cn('px-1', colClass, col.class)" :style="!col.class && { flex: col.grow || 1, 'text-align': col.align || 'left' }">
                                <slot :name="`col:${col.value || col.name}`" :item="item" :header="col" :group="group" :highlight="highlight({ item, group })" :index="i" v-bind="provider"></slot>
                            </div>
                        </div>
                    </Component>
                </Component>
                <div v-if="hasMore" class="animate-loading">
                    <div v-for="i of 5" :key="i" class="h-[50px] bg-dust-100 rounded mb-3"></div>
                </div>
            </template>
            <template v-if="!group.items?.length">
                <slot name="no-results" :group="group">
                    <div :class="rowClass" class="flex flex-row justify-center text-dust mb-3 py-4">
                        <span>{{ noResultsText }}</span>
                    </div>
                </slot>
            </template>
        </div>
  </div>
</template>
