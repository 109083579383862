<script>
import { nanoid } from 'nanoid';

export default {
    name: 'NSwitch',

    props: {
        label: String,
        modelValue: Boolean,
        activeColor: {
            type: String,
            default: 'nerve'
        },
        inactiveColor: {
            type: String,
            default: 'dust'
        },
        icon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            id: `switch-${nanoid()}`,
        };
    },

    emits: ['update:modelValue', 'change'],

    methods: {
        toggle() {
            if (!this.disabled) {
                this.$emit('update:modelValue', !this.modelValue);
                this.$emit('change', !this.modelValue);
            }
        }
    },
}
</script>

<template>
  <div class="flex items-center space-x-4">
    <slot name="prepend" />
    <label v-if="label" :for="id" class="m-0">
        {{ label }}
    </label>
    <button aria-label="button"
        :class="{ [`bg-${activeColor}`]: modelValue, [`bg-${inactiveColor}`]: !modelValue, 'cursor-not-allowed': disabled }"
        :id="id"
        :name="id"
        modelvalue="false"
        class="relative inline-flex items-center flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline"
        aria-labelledby="headlessui-switch-label-1"
        aria-checked="false"
        role="switch"
        tabindex="0"
        type="button"
        @click="toggle"
    >
      <span
        class="inline-flex w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full items-center justify-center"
        :class="{ 'translate-x-5': modelValue, 'translate-x-0': !modelValue }"
      >
        <NIcon
            v-if="icon" class="text-xs"
            :class="{
                [`text-${activeColor} leading-tight`]: modelValue,
                [`text-${inactiveColor}`]: !modelValue
            }"
            :as="modelValue ? 'check-solid' : 'close'"
        />
      </span>
    </button>
    <slot name="append" />
  </div>
</template>
