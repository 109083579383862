<template>
    <div class="tabs__content-helper">
        <p class="form-block-help-text">To promote your idea tournament, share your ReNerved and personal Challenges with your friends, colleagues, and followers. You earn <b>Scout</b> game Neurons (points) for sharing earn <b>Olympiad Director</b> neurons, too. And, best of all, you get more creative ideas by sharing!</p>
        <div class="form-section-wrapper">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 lg:col-span-8">
                    <h2 class="form-group__title is-orange mb-3">Success! Your Email/Tweet has been sent.</h2>
                    <p><b>Now discover more content to ReNerve <span aria-hidden="true" class="icon icon-retweet"></span> and build your tournament — tap an option:</b></p>
                </div>
                <div class="col-span-12 lg:col-span-4 flex items-start lg:justify-end">
                    <a href="#" class="two-row-btn is-blue">
                        <img src="/img/brain-flat.svg" class="two-row-btn__image" alt="">
                        <span class="two-row-btn__caption">
                            <span class="two-row-btn__title">Enter your own idea</span>
                            <span class="two-row-btn__subtitle">then view & rate other ideas</span>
                        </span>
                    </a>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-5">
                <div class="col-span-12 lg:col-span-3 mb-3 lg:mb-0">
                    <a href="#" class="btn is-large is-suggestions">
                        <span aria-hidden="true" class="btn__icon is-left icon-nerve-shape-solid"></span>
                        Suggestions
                    </a>
                </div>
                <div class="col-span-12 lg:col-span-3">
                    <p><i>This leads to your personal platform.</i></p>
                </div>
                <div class="col-span-12 lg:col-span-6">
                    <p>Your Suggestion Engine at the bottom of your platform is the first place to look for <b>challenges to Renerve</b>. You can customize the suggestions, too, based on your categories of interest. ReNerve the best questions and continue building your own tournament.</p>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-3">
                <div class="col-span-12 lg:col-span-3 mb-3 lg:mb-0">
                    <a href="#" class="btn is-medium">
                        <span aria-hidden="true" class="btn__icon is-left icon-nerve-shape-solid"></span>
                        Create Challenge
                    </a>
                </div>
                <div class="col-span-12 lg:col-span-3">
                    <p><i>This button is on every platform, and takes you to the <b>Nerv Us System</b> where you can find more players and more questions.</i></p>
                </div>
                <div class="col-span-12 lg:col-span-6">
                    <p>At the same time, to attract more “idea gamers” to your game (we also call it your <b>Idea Olympiad</b>), you can Search  and Discover other interesting questions to “reNerve”. </p>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-3">
                <div class="col-span-12 lg:col-span-3 mb-3 lg:mb-0">
                    <a href="#" class="btn is-red">
                        <span aria-hidden="true" class="btn__icon is-left icon-question-circle"></span>
                        Create Challenge
                    </a>
                </div>
                <div class="col-span-12 lg:col-span-3">
                    <p><i>Look for this at the top of any platform that allows questions to be posted, like your own, BigNerve and DailyNerve.</i></p>
                </div>
                <div class="col-span-12 lg:col-span-6">
                    <p>If you enjoyed the process of “design thinking” to ask insightful Challenge Questions, you can design more questions. This practice helps you become a better Catalyst. You can also <b>test</b> your own ideas, and <b>save</b> ideas to your idea log to muse on later.</p>
                </div>
            </div>
        </div>
        <ul class="mb-0">
            <li>Your sharing history is saved in the <a href="#">Advanced Sharing Panel</a> (coming soon).</li>
            <li>You can also see which players have come in via your shares in your <a href="#">Scout Dashboard</a>.</li>
        </ul>
    </div>
</template>
