import Api from '@/services/api';
import OldApi from '@/services/old-api';
import Pooling from '@/services/pooling';

export const api = new Api({
  baseURL: import.meta.env.VUE_APP_API_BASE_URL,
  timeout: 50000,
});
export const oldApi = new OldApi({
  baseURL: import.meta.env.VUE_APP_API_BASE_URL,
  timeout: 50000,
});
export const pooling = new Pooling({
  baseURL: import.meta.env.VUE_APP_API_BASE_URL,
  timeout: 50000,
});

export const fetcher = async (...args) => {
  const response = await api.requester.get(...args);
  return response.data;
}

export const install = ({ app }) => {
  app.mixin({
    beforeCreate () {
        this.$api = api;
        this.$nerve = oldApi;
        this.$pooling = pooling;
    }
  });
};
