// import { store } from '@/store';
import Cookie from '@/utils/Cookie';

const urlIn = config => path => config.url.startsWith(path);

export const applyIdentity = axios => {
    axios.interceptors.request.use(config => {
        config.params = config.params || {};

        // remove for now, as it's not used in the backend
        // config.params['platformID'] = store.getters['app/platform'].id;
        
        config.params['fingerprint'] = Cookie.get('fingerprint');

        if (config.method === 'post') {
            const scouted = ['/oauth2/token', '/account/signup', '/ideas', '/challenges'];
            const scoutId = Cookie.get('scout_id');

            if (scouted.some(urlIn(config)) && scoutId && scoutId !== 'null') {
                config.params['scout_id'] = scoutId;
            }
        }

        return config;
    });
};
