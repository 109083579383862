<script>
import { nanoid } from 'nanoid';
import Validatable from '@/mixins/Validatable';

export default {
    mixins: [Validatable],

    props: {
        modelValue: {
            type: [String, Number],
            default: ''
        },
        uid: {
            type: String,
            default: () => `range-${nanoid()}`
        },
        min: {
            type: [String, Number],
            default: 0
        },
        max: {
            type: [String, Number],
            default: 100
        },
        step: {
            type: [String, Number],
            default: 1
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        }
    },
    
    emits: ['update:modelValue'],

    methods: {
        update(value) {
            this.clearErrors();
            this.$emit('update:modelValue', value);
        }
    }
}
</script>

<template>
    <div class="form-group__helper">
        <label v-if="label" :for="name" class="form-group__label" style="display: block;">{{ label }}</label>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <input
            v-bind="attrs"
            type="range"
            class="custom-range-slider"
            :class="{ 'is-error': invalid }"
            :id="uid"
            :name="uid"
            :value="modelValue"
            :disabled="form.busy || disabled"
            :min="min"
            :max="max"
            @input="update($event.target.value)"
            @change="form.errors.clear(uid)"
        >
        <div class="custom-range-slider__caption-wrapper">
            <div class="custom-range-slider__caption">{{ min }}</div>
            <div class="font-bold px-2 py-1 bg-white -mt-1 leading-tight shadow">{{ modelValue }}</div>
            <div class="custom-range-slider__caption">{{ max }}</div>
        </div>
        <span v-if="invalid" class="form-group__error">{{ error }}</span>
    </div>
</template>
