<script>
import { usePlatform } from '@/mixins/ShowsPlatform';
import { mapGetters, mapState } from 'vuex';
import { date } from '@bignerve/ui-utils';

export default {
    name: 'CurrentTournament',

    mixins: [usePlatform()],

    computed: {
        ...mapGetters('olympiad', ['currentOlympiad']),
        ...mapState('olympiad', ['fetchingOlympiads']),
    },

    methods: { date }
}
</script>

<template>
    <section class="game-dash-section">
        <div class="game-dash-section__content mt-0">
            <div class="game-dash-list">
                <router-link
                    :to="{
                        name: 'platform.olympiad.leaderboard',
                        params: {
                            platformSlug: isUserPlatform
                                ? (account.user.screen_name || 'platform')
                                : 'platform'
                            }
                    }"
                    class="game-dash-list-item text-dust-700 is-innovator"
                >
                    <svg class="game-dash-list-item__image" aria-hidden="true" role="img">
                        <use href="#role-innovator" xlink:href="#role-innovator"></use>
                    </svg>
                    <div class="game-dash-list-item__content">
                        <div class="game-dash-list-item__text">
                            {{ $t('component.game-dash.current-olympiad.title') }}
                        </div>
                        <div class="game-dash-list-item__meta">
                            <p>{{ $t('component.game-dash.current-olympiad.subtitle') }}</p>
                            <p v-if="!fetchingOlympiads && currentOlympiad">
                                {{ $t('component.game-dash.current-olympiad.description', {
                                    start_time: date(currentOlympiad.start_time, 'MMMM D'),
                                    end_time: date(currentOlympiad.end_time, 'MMM D'),
                                    prizes: currentOlympiad.races.map(race => `${race.name} ($${race.prize})`).join(' and the ')
                                }) }}
                            </p>
                            <div v-else-if="fetchingOlympiads" class="text-dust flex flex-col">
                                <div class="h-3 w-2/3 bg-dust-200 mb-1 animate-pulse"></div>
                                <div class="h-3 w-1/3 bg-dust-200 animate-pulse"></div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>
