export default class Storage {
    static driver () {
        return self.localStorage || new Proxy({}, { get: () => () => null });
    }

    /**
     * Set the value of the given key.
     *
     * @param  {string}  key
     * @param  {mixed}  value
     * @return {void}
     */
    static set(key, value)
    {
        this.driver().setItem(key, JSON.stringify(value));
    }

    /**
     * Get the value of the given key.
     *
     * @param  {string}  key
     * @param  {mixed}  value
     * @return {mixed}
     */
    static get(key, value = null)
    {
        return JSON.parse(this.driver().getItem(key)) || value;
    }

    /**
     * Determine if the key exists.
     *
     * @param  {string}  key
     * @return {bool}
     */
    static has(key)
    {
        return !! this.get(key);
    }

    /**
     * Remove the value of the given key.
     *
     * @param  {string}  key
     * @return {void}
     */
    static remove(key)
    {
        this.driver().removeItem(key);
    }

    /**
     * Flush all the data.
     *
     * @return {void}
     */
    static clear()
    {
        this.driver().clear();
    }
}
