<script setup>
import Papa from "papaparse";
import Button from '@/components/ui/button/index.vue';
import MemberList from './MemberList.vue';
import ManageSignups from "./ManageSignups.vue";
import FlexTable from '@/components/FlexTable.vue';
import { middleTruncate } from '@bignerve/ui-utils';
import { Icon } from '@bignerve/ui-core';
import { ref, computed } from 'vue';

const props = defineProps({
    channel: {
        type: Object,
        required: true,
    },
    platform: {
        type: Object,
        required: true,
    },
});

const file = ref(null);
const fileInput = ref(null);
const error = ref(null);
const recipientsImportedModal = ref(null);
const recipientsImported = ref([]);
const invalidRows = computed(() => {
    return recipientsImported.value.filter(i => !i.valid).length
});
const validRows = computed(() => {
    return recipientsImported.value.filter(i => i.valid).length
});

const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}

const handleSelectedFile = async (event) => {
    error.value = null;
    const [target] = event.target.files;
 
    if (!target || target.type !== 'text/csv') {
        error.value = 'Invalid file format';
        return
    };

    file.value = target;
}

const handleUploadFile = () => {
    Papa.parse(file.value, {
      worker: false,
      skipEmptyLines: true,
      header: false,
      complete: ({ data, meta }) => {
        if (!data || data.length < 1) {
          error.value = "The file should have at least 2 rows.";

          return;
        }

        const result = data.reduce((acc, row) => {
            const [firstName, lastName, refEmail] = row;
            const invalid = !firstName || !lastName || !refEmail || !isValidEmail(refEmail.trim());

            acc.push({
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                email: refEmail.trim(),
                valid: !invalid,
            });

            return acc;
        }, []);

        recipientsImported.value = result;

        // reset the file input
        file.value = null;
        fileInput.value.value = null;
      },
      error: (e) => {
        console.error(e);
        error.value = "An error occurred while parsing the file.";
      },
    });
}

const handleDownloadTemplate = (format) => {
    // In a real app, this would trigger an API call to get the template
    const templates = {
      csv: "data:text/csv;charset=utf-8,First Name;Last Name;Email\n",
      excel:
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      json:
        "data:application/json;charset=utf-8," +
        encodeURIComponent(
          JSON.stringify(
            [
              {
                'First Name': "",
                'Last Name': "",
                'Email': "",
              },
            ],
            null,
            2,
          ),
        ),
    };

    const link = document.createElement("a");
    link.href = templates[format];
    link.download = `forum-recipients.${format === "csv" ? "csv" : format}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
</script>

<template>
    <div class="grid gap-4">
        <div class="flex flex-col sm:flex-row gap-6">
            <div class="grid gap-4">
                <div>
                    <h2 class="font-bold m-0">Import your members CSV</h2>
                    <div class="flex sm:items-center justify-between flex-col sm:flex-row gap-4">
                        <p class="italic text-dust-700 m-0">FORMAT: first name | last name | email</p>
                        <button class="h-6 gap-1 text-sm flex items-center text-dust-400 hover:text-dust-800" type="button" @click="handleDownloadTemplate('csv')">
                            <Icon as="arrow-circle-down"/>Template
                        </button>
                    </div>
                </div>

                <div class="grid gap-4 bg-white p-4 rounded-md border border-dust-300 w-full sm:max-w-fit">
                    <label class="flex-1 flex items-center relative">
                        <div class="flex items-center border border-dust-300 h-9 px-4 bg-white mr-2 rounded-md overflow-hidden whitespace-nowrap w-full sm:w-[250px]">
                            <span v-if="!file" class="text-gray-400 italic">
                                Upload...
                            </span>
                            <span v-else>
                                {{ middleTruncate(file.name, 30) }}
                            </span>
                        </div>
                        <div class="relative inline-flex overflow-hidden h-9 items-center justify-center px-4 rounded-md shadow border bg-nerve text-white transition ease-in-out duration-300 cursor-pointer whitespace-nowrap">
                            Browse
                        </div>
                        <input type="file" ref="fileInput" class="hidden" :accept="'csv'" @change="handleSelectedFile" />
                    </label>
                    <div class="flex gap-2">
                        <Button variant="solid" size="sm" type="button" class="relative overflow-hidden gap-2 px-4" :disabled="!file" @click="handleUploadFile">
                            Upload
                        </Button>
                    </div>
                </div>

                <NAlert type="danger" :open="!!error" >
                    {{  error  }}
                </NAlert>
            </div>

            <div class="grid gap-4 sm:w-full ">
                <div>
                    <h2 class="font-bold m-0">Collection Form Sign-ups</h2>
                    <div class="flex sm:items-center justify-between flex-col sm:flex-row gap-4">
                        <RouterLink :to="{ name: 'discussion-forums.channel.signup.form', params: { user: platform.slug, slug: channel.slug } }" target="_blank" class="italic text-dust-700 m-0 hover:underline">Collection Form Sign-ups <Icon as="external-link" class="text-sm ml-1" /></RouterLink>
                    </div>
                </div>

                <ManageSignups :platform="platform" :channel="channel" />
            </div>
        </div>

        <div v-if="recipientsImported.length" class="px-3 pt-3 pb-2 flex gap-2 rounded-lg border text-secondary bg-white">
            <Icon as="user-avatar" class="text-xl" />
            <div class="grid">
                <p class="m-0">
                    <b>RESULTS: {{ validRows }} valid rows.</b> <b v-if="invalidRows" class="text-red-500">{{ invalidRows }} invalid rows</b>. <button type="button" class="underline text-inherit" @click="() => recipientsImportedModal = true">View data in overlay.</button></p>
            </div>
        </div>

        <NModal v-model="recipientsImportedModal" #="{ close }">
            <div class="overflow-hidden rounded-md flex flex-col w-full max-w-[95dvw] sm:w-[40rem] bg-white">
                <div class="flex justify-between items-center px-3 py-2 bg-secondary text-white">
                    <div class="flex items-center">
                        <h3 class="m-0">RESULTS</h3>
                    </div>

                    <button aria-label="button" type="button" @click="close">
                        <NIcon as="times-circle-regular" />
                    </button>
                </div>

                <div class="h-[75vh] overflow-y-auto sb-snug sb-dust-300">
                    <FlexTable
                        :header="[
                            { text: 'First Name', value: 'first_name', grow: 1 },
                            { text: 'Last Name', value: 'last_name', grow: 1 },
                            { text: 'Email', value: 'email', grow: 2 },
                            { text: 'Valid', value: 'valid', grow: 0.5 },
                        ]"
                        :items="recipientsImported"
                        header-class="border-b-2 py-3 sticky top-0 bg-white"
                        class="bg-white px-4 pb-4"
                    >
                        <template #col:first_name="{ item }">{{ item.first_name }}</template>
                        <template #col:last_name="{ item }">{{ item.last_name }}</template>
                        <template #col:email="{ item }">{{ item.email }}</template>
                        <template #col:valid="{ item }">
                            <span class="text-center" :class="item.valid ? 'text-green-500' : 'text-red-500'">
                                {{ item.valid ? 'Yes' : 'No' }}
                            </span>
                        </template>
                    </FlexTable>
                </div>
            </div>
        </NModal>

        <MemberList :platform="platform" :channel="channel" :data-imported="recipientsImported" />
    </div>
</template>