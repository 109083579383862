<script>
import OlympiadCard from './OlympiadCard.vue';
import LoadMore from '@/layouts/partials/LoadMore.vue';
import CardPlaceholder from '@/components/CardPlaceholder.vue';

export default {
    name: 'ListOlympiads',

    components: {
        OlympiadCard,
        LoadMore,
        CardPlaceholder,
    },

    props: {
        filter: {
            type: String,
            default: 'all'
        },
        status: {
            type: String,
            default: 'open'
        },
        title: {
            type: String
        },
        platformSlug: {
            type: String
        }
    },

    data() {
        return {
            busy: false,
            offset: 3,
            error: false,
            olympiads: [],
            total: 0,
            query: {
                page: 0,
                size: 20,
                active: true,
                status: this.status,
                sort: this.filter,
                platform: this.platformSlug,
                reference: 'renerve',
            }
        };
    },

    computed: {
        hasMore() {
            return (this.total || 0) > this.olympiads.length;
        },
        isEmpty() {
            return this.olympiads.length === 0;
        },
    },

    mounted() {
        this.fetch();
    },

    watch: {
        filter (newValue) {
            this.reset();

            this.fetch({
                page: 0,
                size: 18,
                sort: newValue,
                status: this.status,
            });
        },
        platformSlug (newValue) {
            this.reset();

            this.fetch({
                page: 0,
                size: 18,
                platform: newValue,
            });
        }
    },

    methods: {
        async fetch(query = {}) {
            try {
                this.busy = true;
                const { data } = await this.$api.olympiad.query(
                    { ...this.query, ...query }
                );

                this.merge(data);
            } catch (err) {
                this.error = true;
            } finally {
                this.busy = false;
            }
        },
        nextPage({ size } = { }) {
            if (this.query.size !== this.offset){
                this.query.page++;
            }

            this.query.size = size || this.query.size;

            this.fetch();
        },
        reset() {
            this.olympiads = [];
            this.total = 0;
        },
        merge({ data, meta }) {
            this.olympiads = [...this.olympiads, ...data];
            this.total = meta.total;
        }
    },
}
</script>

<script setup>
import { usePlatform } from '@/composable/platform';

const currentPlatform = usePlatform();
</script>

<template>
    <section class="card-section">
        <h2 class="mb-4 text-xl font-narrow text-secondary">
            {{ title }}
        </h2>
        <div v-show="!isEmpty">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <OlympiadCard
                    v-for="(olympiad, index) in olympiads"
                    :key="`${index}-open-${olympiad.id}`"
                    :detail="olympiad"
                />
            </div>
            <div v-if="hasMore" class="flex justify-center">
                <LoadMore :loading="busy" :handler="() => nextPage({ size: 18 })" />
            </div>
        </div>
        <div v-if="busy && isEmpty">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
                <CardPlaceholder v-for="i of 6" :key="`open=placeholder-${i}`">
                    <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
                </CardPlaceholder>
            </div>
        </div>
        <div v-if="! busy && isEmpty" class="italic py-20 text-center">
            <p class="mb-1">No user tournaments started on {{ currentPlatform.title }} yet.</p>
            <p><RouterLink :to="{ name: 'challenges.create' }" class="text-inherit underline">Create a Challenge</RouterLink> to start your tounament!</p>
        </div>
    </section>
</template>
