<script>
import { create } from 'maska';
import { onUnmounted, ref, onMounted, defineComponent } from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: [String, Number],
            required: false,
        },
        raw: {
            type: String,
            required: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        modelModifiers: { default: () => ({ raw: false }) },
    },

    emits: ['update:modelValue', 'update:raw', 'change'],

    setup(props, { emit }) {
        const inputElement = ref(null)
        const inputMask = ref(null)

        onMounted(() => {
            inputMask.value = create(inputElement.value, {
            ...props.options,
            mask:
                typeof props.options.mask === 'string'
                ? props.options.mask
                : JSON.stringify(props.options.mask),
            })
        })

        onUnmounted(() => {
            inputMask.value?.destroy()
        })

        const emitValue = (e) => {
            emit(
                'update:modelValue',
                props.modelModifiers.raw
                    ? e.target.dataset.maskRawValue
                    : e.target.value
            ),
            emit('update:raw', e.target.dataset.maskRawValue),
            emit('change', e.target.value)
        }

        return {
            inputElement,
            inputMask,
            emitValue,
            emit,
        }
    },
})
</script>

<template>
    <input
        ref="inputElement"
        :value="modelValue"
        :autofocus="autofocus"
        type="text"
        @input="emitValue"
    />
</template>
