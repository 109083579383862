<script>
import NMenu from '@/components/NMenu.vue';

export default {
    name: 'Autocomplete',

    components: { NMenu },

    props: {
        id: String,
        name: String,
        label: String,
        hint: String,
        modelValue: [String, Number],
        placeholder: String,
        disabled: Boolean,
        clearable: Boolean,
        loading: Boolean,
        prependIcon: String,
        noFilter: Boolean,
        autofocus: Boolean,
        returnObject: Boolean,
        filter: {
            type: Function,
            default: (item, queryText, itemText) => {
                return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            },
        },
        itemText: {
            type: [String, Function],
            default: 'name',
        },
        itemValue: {
            type: [String, Function],
            default: 'value',
        },
        items: {
            type: Array,
            default: () => [],
        },
        queryInput: String,
        itemsType: {
            type: String,
            default: 'dropdown',
            validator(value) {
                return ['dropdown', 'list'].indexOf(value) > -1
            },
        },
    },

    emits: ['update:modelValue', 'update:query-input'],

    data () {
        return {
            query: '',
            highlightedIndex: -1,
        }
    },

    computed: {
        input() {
          return this.$refs.input;  
        },
        availabeItems () {
            return this.items.filter(
                this.noFilter
                    ? () => true
                    : (item) => this.filter(item, this.query, this.getValue(item)),
            );
        },
    },

    watch: {
        queryInput: {
            immediate: true,
            handler (value) {
                this.query = value;
            },
        },
    },

    methods: {
        highlight (index) {
            this.$refs.dropdown.open();

            this.highlightedIndex = index;

            if (this.$refs.items && this.$refs.items.length) {
                this.scrollToHighlight();
            }
        },

        scrollToHighlight () {
            if (this.highlightedIndex < 0) {
                return;
            }

            this.$nextTick(() => {
                const el = this.$refs.items[this.highlightedIndex];
                el && el.scrollIntoView({ block: 'nearest' });
            });
        },

        highlightNext () {
            const next = this.highlightedIndex + 1;
            const index = next >= this.availabeItems.length ? 0 : next;

            this.highlight(index);
        },

        highlightPrev () {
            const prev = this.highlightedIndex - 1;
            const index = prev < 0 ? this.availabeItems.length - 1 : prev;

            this.highlight(index);
        },

        select (index) {
            this.highlight(index);

            const item = this.availabeItems[index];

            if (item) {
                this.query = this.getText(item);
                this.$emit('update:modelValue', this.getValue(item));
                this.$refs.dropdown.close();
            }
        },

        clear () {
            this.$refs.dropdown.close();
            this.$refs.input.focus();

            this.query = null;
            this.$emit('update:modelValue', null);
        },

        show () {
            this.$refs.dropdown.open();
        },

        getText (item) {
            if (!item) {
                return;
            }

            return typeof this.itemText === 'function'
                ? this.itemText(item)
                : item[this.itemText];
        },

        getValue (item) {
            if (!item) {
                return;
            }

            if (this.returnObject) {
                return item;
            }

            return typeof this.itemValue === 'function'
                ? this.itemValue(item)
                : item[this.itemValue];
        },
    }
}
</script>

<template>
    <NMenu ref="dropdown" :floating="itemsType ? itemsType === 'dropdown' : true" @open="scrollToHighlight">
        <template #activator="{ open, close, active }">
            <NInput
                v-model.lazy="query"
                :label="label"
                :hint="hint"
                :placeholder="placeholder"
                :name="name"
                :id="id"
                :disabled="disabled"
                :autofocus="autofocus"
                :min="4"
                :max="45"
                autocomplete="off"
                ref="input"
                maxlength="40"
                class="w-full"
                @focus="availabeItems.length > 0 && open()"
                @keydown.esc="close"
                @input="$emit('update:query-input', query)"
                @keydown.tab="(e) => active && (e.preventDefault(), highlightNext())"
                @keydown.up.prevent="highlightPrev"
                @keydown.down.prevent="highlightNext"
                @keydown.enter.prevent="active && select(highlightedIndex)"
            >
                <template v-if="prependIcon" #prepend>
                    <NIcon :as="prependIcon" class="mr-2"/>
                </template>
                <template #append>
                    <NLoading v-if="loading" class="-mr-2" active />

                    <button aria-label="button" v-else-if="clearable" v-tooltip="'Clear'" type="button" @click="clear" class="cursor-pointer flex items-center justify-center h-6 w-6 text-dust-600 hover:bg-dust-100 hover:text-dust-700 rounded-full -mr-1">
                        <NIcon as="close" />
                    </button>
                </template>
            </NInput>
        </template>

        <NLoading v-if="loading" loading class="py-20 flex justify-center w-full" />

        <ul v-else-if="availabeItems.length" class="flex flex-col justify-start w-full p-0 m-0 snap snap-y overflow-y-auto max-h-60 sb-snug sb-dust-200">
            <li v-for="(item, index) of availabeItems" :key="item.id" ref="items" :class="{ 'bg-dust-100': highlightedIndex === index }" class="cursor-pointer hover:bg-dust-100 hover:text-dust-800" @click="select(index)">
                <slot name="item" :index="index" :item="item">
                    <span class="text-sm px-3 py-1 block">{{ item[itemText] }}</span>
                </slot>
            </li>
        </ul>

        <div v-else class="py-10 px-4 w-full text-center">
            <slot name="no-data">
                No items were found!
            </slot>
        </div>
    </NMenu>
</template>