<script setup>
import NModal from '@/components/NModal.vue';
import FeatureFlag from '@/components/FeatureFlag.vue';
import SelectStaffUser from '@/components/staff/SelectUser.vue';
import { useFeatureFlag } from '@/composable/feature-flag';
import { ref } from 'vue';

defineProps({
    challenge: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const { enabled: isEmailChallengeIdeasEnabled } = useFeatureFlag('email-challenge-ideas');
const emailSent = ref(false);

const onSuccess = () => {
    emailSent.value = true;

    setTimeout(() => {
        emailSent.value = false;
    }, 5000);
};
</script>

<template>
    <NModal v-if="isEmailChallengeIdeasEnabled && challenge.catalyst_id === $auth.user.id">
        <template #activator="{ on }">
            <div class="flex items-center">
                <button aria-label="button" v-if="!emailSent" v-on="on" type="button" :disabled="disabled" :class="disabled ? 'bg-secondary-400' : 'bg-secondary-500 hover:bg-secondary-600'" class="px-3 py-1 text-white rounded-md flex items-center">
                    <n-icon as="envelope-regular" class="mb-.8 mr-2 text-lg" />
                    Send ideas via email
                </button>

                <span v-else class="px-4 py-1 bg-green-100 rounded-md text-green-500 font-bold">
                    <n-icon as="check-solid" class="mb-.8 mr-1" />
                    Email sent
                </span>
            </div>
        </template>

        <template #default="{ visible, close }">
            <div class="bg-white w-full h-full md:rounded-md md:w-md overflow-hidden">
                <div class="flex justify-between items-center mb-4 px-3 py-2 bg-secondary text-white">
                    <div class="flex items-center">
                        <NIcon as="envelope-regular" class="text-xl mb-1 mr-2" />
                        <h3 class="m-0">{{ $t('page.dashboard.catalyst.detail.send-email.title') }}</h3>
                    </div>

                    <button aria-label="button" type="button" @click="close">
                        <NIcon as="times-circle-regular" />
                    </button>
                </div>

                <div class="px-8 pb-4">
                    <NDataForm v-if="visible" method="post" :action="`/challenges/${challenge.slug}/send`" :data="{ to: $auth.user.email, cc: [], staff_id: null }" @success="onSuccess(); close()">
                        <template #default="form">
                            <NAlert type="danger" closable :open="form.errors.any()">
                                <span>{{ form.errors.get('*') || $t('page.dashboard.catalyst.detail.send-email.default-error-message') }}</span>
                            </NAlert>
                            <NAlert type="success" closable :open="form.success">
                                <span>{{ $t('page.dashboard.catalyst.detail.success-message') }}</span>
                            </NAlert>
                            <div class="form-groups mb-6 flex flex-col">
                                <p class="mb-0">
                                    {{ $t('page.dashboard.catalyst.detail.send-email.description') }}
                                </p>
                                <FeatureFlag flag="email-challenge-ideas" variant="reply-to" #default="{ enabled }">
                                    <SelectStaffUser v-if="enabled" v-model="form.fields.staff_id" />
                                </FeatureFlag>
                                <div class="form-group my-0">
                                    <NInput v-model="form.fields.to" label="To:" type="email" :required="true" />
                                </div>
                                <FeatureFlag flag="email-challenge-ideas" variant="cc" #default="{ enabled }">
                                    <div v-if="enabled" class="form-group my-0">
                                        <NTags v-model="form.fields.cc" label="CC other people:" :max="5" />
                                        <small class="mt-1 text-dust-600">
                                            {{ $t('page.dashboard.catalyst.detail.send-email.reply-to-help') }}
                                        </small>
                                    </div>
                                </FeatureFlag>
                            </div>
                            <div class="flex justify-end items-center gap-4" :class="{ disabled: !form.fields.to }">
                                <button aria-label="button" type="button" class="px-4 py-2 rounded-md flex hover:bg-dust-100" @click="close">
                                    Cancel
                                </button>
                                <button aria-label="button" type="submit" class="px-4 py-2 rounded-md leading-5 flex bg-secondary-500 text-white hover:bg-secondary-600" :class="{ '!bg-secondary-400 cursor-not-allowed': !form.fields.to || form.busy }" :disabled="!form.fields.to || form.busy">
                                    <NLoading v-if="form.busy" class="text-white" loading />
                                    {{ form.busy ? 'Sending...' : 'Send email' }}
                                </button>
                            </div>
                        </template>
                    </NDataForm>
                </div>

            </div>
        </template>
    </NModal>
</template>
