import { computed } from 'vue';
import { api } from '@/modules/services';
import { get, set } from 'lodash';
import { createSharedComposable, useStorage } from '@vueuse/core';

const preferences = useStorage('app:user:preferences', {}, undefined, { deep: true });

export const usePreferences = createSharedComposable(() => {

    const fetchPreferences = async () => {
        const { data: response } = await api.me.preferences();

        preferences.value = response.data;
    }

    const save = async (key, value) => {
        set(preferences.value, key, value);

        api.me.savePreferences(preferences.value)
    }

    const read = (key, fallback) => {
        return get(preferences.value, key, fallback);
    }

    const preference = (key) => {
        return computed({
            get () {
                return get(preferences.value, key);
            },
            set (value) {
                save(key, value)
            },
        })
    }

    fetchPreferences();

    return {
        preferences,
        preference,
        update: fetchPreferences,
        read,
        save,
    }
});
