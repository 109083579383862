<script setup>
import { useAuth } from '@/composable/auth';
import { pusher } from '@/modules/pusher';
import { useRoute } from 'vue-router';
import { on } from '@/modules/bus';
import { useBrain } from '@/queries/gamedash'; 
import { syncRef } from '@vueuse/core'
import { ref, computed, provide } from 'vue';

const route = useRoute();
const { auth } = useAuth();
const handle = computed(() => auth.user.screen_name);

const roles = {
    scout: 'scout',
    rater: 'analyst',
    mentor: 'mentor',
    patron: 'patron',
    director: 'director',
    catalyst: 'catalyst',
    insightful: 'insightful',
    innovator: 'overall',
    olympiads: 'director',
};
const brain = ref(null);
const role = computed(() => {
    let path = route.path.split('/').filter(segment => segment);

    return path[2] ? (roles[path[2]] || 'overall') : 'overall';
});
const params = computed(() => ({
    role: role.value,
}))

const { data } = useBrain(handle, params);

syncRef(data, brain, { direction: 'ltr' })

provide('gamedash', {
    brain, role,
});

const updateBrainScore = (data) => {
    if (['overall', data.role].includes(role.value)) {
        brain.value = {
            ...brain.value,
            ...data,
        };
    }
}

on('user:logged-in@once', (user) => {
    const channelName = `private-gamedash-${user.id}`;
    const channels = pusher.allChannels();
    if (channels.find(channel => channel.name === channelName)) {
        return;
    }

    // subscribe to user's channels
    const channel = pusher.subscribe(channelName);

    console.log('[pusher] User subscribed to game-dash channel');

    // listen for events
    channel.bind('gamedash-highlights', toEmit('game-dash:highlight'));
    channel.bind('brain__neuron-earned', toEmit('brain:neuron-earned'));
    channel.bind('brain__score-updated', updateBrainScore);
});

defineOptions({
    name: 'GameDashProvider',
})
</script>

<template>
    <slot />
</template>