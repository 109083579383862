<script>
import { h } from 'vue';

const TYPES = ['iframe', 'embed', 'video', 'object', 'img'];

const providers = [
    {
        //reg: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/i,
        //reg: /^.*(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i,
        reg:/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i,
        url: 'https://www.youtube.com/embed/$5',
        params: {
            'picture-in-picture': 1,
            accelerometer: 1,
            gyroscope: 1
        }
    },
    {
        reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
        url: 'https://player.vimeo.com/video/$1',
        params: {
            title: 0,
            byline: 0,
            portrait: 0
        },
    },

    {
        reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
        url: 'https://www.dailymotion.com/embed/video/$1',
        params: {
            autoplay: 0
        }
    },
    {
        reg: /^.*coub.com\/(?:embed|view)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
        url: 'https://coub.com/embed/$1',
        params: {
            autoplay: 0
        }
    },
]

const parse = (src) => {
    for (let i = 0; i < providers.length; i++) {
        const provider = providers[i];
        const match = src.match(provider.reg);
        if (match) {
            return {
                src: src.replace(provider.reg, provider.url),
                params: provider.params,
            };
        }
    }
    return null;
}

export default function Embed(props, { attrs, listeners, slots }) {
    const { type = 'iframe' } = props;

    if (!TYPES.includes(type)) {
        throw new Error(`Invalid embed type: ${type}`);
    }

    const { src, params } = parse(props.src);

    return h(type, {
        ...attrs,
        ...listeners,
        ...params,
        src,
    }, slots.default && slots.default());
}
</script>