<script setup>
import NLazyImage from '@/components/NLazyImage.vue';
import { useSlots } from 'vue';

const props = defineProps({
    article: {
        type: Object,
        required: true,
    },
    hideCover: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();
</script>

<template>
    <div :class="'bg-director-50'">
        <slot name="cover">
            <a v-if="!hideCover" :href="article.url" target="_blank" rel="noopener noreferrer">
                <NLazyImage :src="article.image_url" class="!aspect-image border-dust-100 sm:border-r border-b" />
            </a> 
        </slot>
        <div :class="{ 'border-t': !slots.cover && !hideCover }" class="px-4 py-2 border-b text-nerve flex items-center leading-none">
            <NIcon as="article" class="text-lg mr-2" />
            <span class="italic text-sm">Linked Article</span>
        </div>
        <div class="p-4 bg-director-50">
            <h3 v-tooltip="article.title" class="text-lg text-dust-800 line-clamp-2">{{ article.title }}</h3>
            <p v-if="!!article.description" :title="article.description" class="text-sm text-dust-600 line-clamp-3">
                {{ article.description }}
            </p>
            <div>
                <a :href="article.url" class="text-sm font-bold text-dust-600" target="_blank" rel="noopener noreferrer">
                    <NIcon as="link-solid" class="mr-2" />
                    <span class="underline">Read the full story</span>
                </a>
            </div>
        </div>
    </div>
</template>