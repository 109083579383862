<script setup>
import CategoriesPicker from '@/components/game/CategoriesPicker.vue';
import Button from '@/components/ui/button/index.vue';
import Select from '@/components/form/Select.vue';
import NNote from '@/components/NNote.vue';
import { useAuth } from '@/composable/auth';
import { Icon } from '@bignerve/ui-core';
import { usePreferences } from '@/composable/preferences';
import { usePlatform } from '@/composable/platform';
import { api } from '@/modules/services';
import { ref, reactive } from 'vue';

const { currentPlatform } = usePlatform();
const { sync, preferences } = usePreferences();
const { auth } = useAuth();

const allowTopic = sync('platform.forum.allow_topic_creation', false);
const enableDailyDigest = sync('user.forum_activity.daily_digest', false);
const minimumNerveScoreLevel = sync('platform.forum.allow_topic_creation_min_level', 0);

const updatePlatformPreferences = () => {
    api.platform.savePreferences(
        currentPlatform.value.id,
        preferences.platform,
    );
}

const updateUserPreferences = () => {
    api.me.savePreferences(
        preferences.user,
    );
}
</script>

<template>
    <div class="page">
        <NPageHeader
            title="Forum Preferences"
            subtitle="Customize your discussion boards"
            icon="discussion-icon"
        />

        <p>
            BigNerve gives everyone their own discussion forum for free. There are two types: (1) every <b>challenge question</b> you ask has its own dedicated discussion board, to discuss the question, and (2) an <b>overall discussion forum</b> for any topic you create. Here you can customize your settings for <RouterLink :to="{ name: 'discussion-forums.topics', params: { user: auth.user.screen_name || 'me' } }" class="underline text-inherit">your forums <Icon as="external-link" class="text-xs underline-none" /></RouterLink> .
        </p>

        <NDataForm class="form-groups">
            <div class="form-groups">
                <div class="page__content mb-6">
                    <h3 class="text-xl mb-2"><Icon as="pencil-alt-solid" class="mr-2" />New Topic Creation</h3>

                    <div class="form-group">
                        <div class="flex items-start justify-between space-x-8 space-y-8 w-full flex-col sm:flex-row">
                            <div class="flex flex-col  gap-4 sm:border-r sm:border-dust-300 sm:pr-20">
                                <p class="form-block-help-text">Allow <b>other users</b> to create new topics on your main forum. If you do not allow this, then only you can create new topics for others to post in.</p>

                                <div class="flex items-center w-full flex-col gap-4 sm:flex-row">
                                    <p class="mb-0">
                                        <strong>Optional</strong>: require a minimum <span class="italic">Nerve Score</span>
                                    </p>
                                    <div class="sm:w-[120px]" :class="{ 'disabled': !allowTopic }">
                                        <Select
                                            v-model="minimumNerveScoreLevel"
                                            :options="[
                                                { label: 'All Users', value: 0 },
                                                { label: 'Level 1', value: 1 },
                                                { label: 'Level 2', value: 2 },
                                                { label: 'Level 3', value: 3 },
                                                { label: 'Level 4', value: 4 },
                                                { label: 'Level 5', value: 5 },
                                            ]"
                                            placeholder="Select Level"
                                            name="min_level"
                                            @change="updatePlatformPreferences"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="flex items-center sm:flex-col sm:w-40 gap-2">
                                <NSwitch
                                    v-model="allowTopic"
                                    active-color="secondary" icon 
                                    name="allow_topic_creation"
                                    @change="updatePlatformPreferences"
                                />
                                <span class="text-dust-700 italic text-sm ">{{ allowTopic ? 'Allowed' : 'Not Allowed' }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page__content mb-6">
                    <h3 class="text-xl mb-2">
                        <Icon as="envelope-regular" class="mr-2" />Notifications
                    </h3>

                    <div class="form-group">
                        <div class="flex items-start justify-between space-x-8 space-y-8 w-full flex-col sm:flex-row">
                            <div class="flex flex-col  gap-4 sm:border-r sm:border-dust-300 sm:pr-20">
                                <p class="form-block-help-text">
                                    We can send you a “Foum daily digest” at about 6pm eastern each day that includes any <b>@mentions</b> and replies to your posts and new topics you created. 
                                </p>

                                <p class="form-block-help-text">
                                    Toggle it on or off whenever you like. Changes take effect at the next sending time.
                                </p>
                            </div>

                            <div class="flex items-center sm:flex-col sm:w-40 gap-2">
                                <NSwitch
                                    v-model="enableDailyDigest"
                                    active-color="secondary" icon 
                                    name="forum_daily_digest"
                                    @change="updateUserPreferences"
                                />
                                <span class="text-dust-700 italic text-sm ">{{ enableDailyDigest ? 'On' : 'Off' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NDataForm>

        <NNote title="">
            <p>Community discussions are an important part of exploring problems, sparking imagination, and discovering innovative solutions. A discussion board can be public or private (if the challenge question is private).  And every type of collection (aka “club” or “tournament” or “olympiad”) has its own discussion forum. You have full control over who to mute or ban from your forums and who can create topics or not. </p>

            <p>Also, all public discussions “roll up” to the main BigNerve <RouterLink :to="{ name: 'discussion-forums.most-recent', params: { user: 'platform' } }" class="underline text-inherit">forum front page</RouterLink> to help everyone discover great conversations across all users and topics.</p>
        </NNote>
    </div>
</template>
