<script>
import Validatable from "@/mixins/Validatable";
import { middleTruncate } from '@bignerve/ui-utils';
import { cn } from '@/utils/Helpers';

export default {
    mixins: [Validatable],

    inheritAttrs: false,

    props: {
        modelValue: {
            type: String,
            default: ""
        },
        accept: {
            type: Array,
            default: () => []
        },
        maxWidth: {
            type: Number,
            default: 8000
        },
        maxHeight: {
            type: Number,
            default: 8000
        },
        buttonText: {
            type: String,
            default: "Browse"
        },
        truncate: {
            type: Number,
            default: 30
        },
        border: {
            type: Boolean,
            default: true
        },
        maxSize: {
            type: Number,
            default: 1024 * 1024 * 10
        },
        draggable: {
            type: Boolean,
            default: true
        },
        class: {
            type: String,
            default: ""
        }
    },

    emits: ['update:modelValue', 'loaded', 'change'],

    data() {
        return {
            file: null,
            busy: false,
            image: {
                bytes: null,
                width: null,
                height: null
            },
            hasError: false,
            imageLoaded: false,
            draging: false,
            progress: 0,
        };
    },

    computed: {
        headers() {
            return {
                "Content-Type": this.file.type
            };
        },
        title() {
            return this.file ? this.file.name : 'Upload';
        },
        validDimension() {
            return [
                this.image.width <= this.maxWidth,
                this.image.height <= this.maxHeight,
            ].every(Boolean);
        },
        validSize () {
            return this.image.bytes <= this.maxSize;
        },
    },

    methods: {
        cn,
        middleTruncate,
        handle(event) {
            this.hasError = false;
            this.file = event.target.files.item(0);

            this.loadImage(this.file);
        },
        async upload(file) {
            this.busy = true;

            try {
                const { data: { url } } = await this.$api.resource.uploadImage(
                    this.file, { type: this.file.type }, this.headers, (p) => {
                        this.progress = p;
                    }
                );

                this.$emit("update:modelValue", url);
                this.$emit('change', { url });

                this.file = null;
                this.$refs.fileUpload.value = null;
                this.progress = 0;
            } catch (err) {
                this.busy = false;
                this.hasError = true;
                this.$sentry.withScope(scope => {
                    scope.setTag("event", "upload-image");
                    scope.setExtra("image", {
                        size: file.size,
                        type: file.type,
                        name: file.name,
                        width: this.image.width,
                        height: this.image.height
                    });
                    this.$sentry.captureMessage("Upload image", "info");
                });
            } finally {
                this.busy = false;
            }
        },
        loadImage(file) {
            if (!file || file.type.indexOf("image/") !== 0) {
                return;
            }

            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = e => {
                let img = new Image();
                img.onload = () => {
                    this.image.width = img.width;
                    this.image.height = img.height;
                    this.image.bytes = file.size;
                    this.imageLoaded = true;

                    this.$emit("loaded", { img });

                    if (this.validDimension && this.validSize) {
                        this.upload(file);
                    }
                };
                img.src = e.target.result;
            };

            reader.onerror = e => {
                console.error(e);
            };
        },
        dragover(event) {
            event.preventDefault();
            this.draging = true;
        },
        dragleave(event) {
            event.preventDefault();
            this.draging = false;
        },
        drop(event) {
            event.preventDefault();

            this.file = event.dataTransfer.files[0];
            this.loadImage(this.file);
            this.draging = false;
        },
        paste(event) {
            event.preventDefault();
            const items = event.clipboardData.items;

            for (let i = 0; i < items.length; i++) {
                const file = items[i].getAsFile()

                this.file = file;
                this.loadImage(file);
            }
        }
    },
};
</script>

<template>
    <slot />
    <NAlert :open="hasError" type="danger" class="mt-3 mb-0" closable>
        Error processing the image.
    </NAlert>
    <NAlert :open="!validDimension" type="danger" class="mt-3 mb-0" closable>
        Maximum width and height dimension for upload image is {{ maxWidth }} x {{ maxHeight }}.
    </NAlert>
    <NAlert :open="!validSize" type="danger" class="mt-3 mb-0" closable>
        Maximum file size for upload image is {{ maxSize / 1024 / 1024 }} MB.
    </NAlert>
    <div :class="cn('flex w-full px-4 md:px-6 py-4 my-6 md:rounded-lg overflow-hidden context:animation-bounce', border ? 'md:border-dashed md:border-2 focus:border-gray-300' : null, $props.class)" @dragover="dragover" @dragleave="dragleave" @drop="drop" @paste="paste" >
        <div class="flex w-full flex-col sm:flex-row sm:items-center">
            <label class="flex-1 flex items-center relative flex-col sm:flex-row gap-4">
                <div class="flex w-full items-center border border-dust-300 py-2 px-4 bg-white mr-2 rounded-md overflow-hidden whitespace-nowrap max-w-90" :title="title">
                    <NIcon as="image-regular" class="text-dust-600 text-lg mr-3" />
                    <span class="" :class="{ 'text-gray-400': !file }">
                        {{ middleTruncate(title, truncate) }}
                    </span>
                </div>
                <div :class="{ 'hover:bg-nerve-600': !busy }" class="relative inline-flex overflow-hidden sm:max-w-40 items-center justify-center p-2 px-6 rounded-md shadow border bg-nerve text-white transition ease-in-out duration-300 cursor-pointer whitespace-nowrap w-full">
                    <div class="absolute h-full bg-nerve-600 bottom-0 left-0 transition-all duration-500 easy-in-out" :style="{ width: `${progress}%`,  }"></div>
                    <span class="z-[1]">{{ busy ? "Uploading" : buttonText }}</span>
                    <svg v-if="busy" class="animate-spin !h-5 !w-5 ml-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <input
                    v-bind="attrs"
                    type="file"
                    class="custom-file-upload__input absolute"
                    ref="fileUpload"
                    :accept="accept.join(', ')"
                    :disabled="form.busy || busy"
                    @change="handle"
                />
            </label>
            <slot v-if="draggable" name="draging" :draging="draging">
                <div class="flex-1 text-dust items-center whitespace-nowrap justify-center flex-col space-y-3 py-8 px-2 m-6 w-full hidden md:flex text-center">
                    <span v-if="draging">drop your image here</span>
                    <span v-else>Drag or paste your image here</span>
                    <svg :class="draging ? 'animation-bounce' : 'target:animation-bounce'" class="w-6 h-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                    </svg>
                </div>
            </slot>
        </div>
    </div>
</template>

<style lang="scss">
.context\:animation-bounce {
    .none\:animation-bounce {
        &:hover .target\:animation-bounce {
            @apply animate-bounce;
        }
    }
    &:hover .target\:animation-bounce {
        @apply animate-bounce;
    }
}
</style>
