<script>
export default {
    name: 'Placeholder'
}
</script>

<template>
    <div>
        <div class="animate-pulse flex flex-col mt-2">
            <div class="bg-dust-200 h-6 mb-2 w-2/4"></div>
            <div class="flex-1 flex space-x-4 items-center">
                <div class="h-4 bg-dust-200 rounded w-1/4"></div>
                <div class="h-4 bg-dust-200 rounded w-1/4"></div>
            </div>
        </div>
        <div class="animate-pulse flex space-x-4 mt-3">
            <div class="rounded-full bg-dust-200 h-8 w-8"></div>
            <div class="flex-1 flex justify-between items-center">
                <div class="h-4 bg-dust-200 rounded w-2/5"></div>
                <div class="h-4 bg-dust-200 rounded w-1/5"></div>
            </div>
        </div>
        <div class="animate-pulse flex space-x-4 mt-3">
            <div class="rounded-full bg-dust-200 h-8 w-8"></div>
            <div class="flex-1 flex justify-between items-center">
                <div class="h-4 bg-dust-200 rounded w-2/5"></div>
                <div class="h-4 bg-dust-200 rounded w-1/5"></div>
            </div>
        </div>
        <div class="animate-pulse flex space-x-4 mt-3">
            <div class="rounded-full bg-dust-200 h-8 w-8"></div>
            <div class="flex-1 flex justify-between items-center">
                <div class="h-4 bg-dust-200 rounded w-3/5"></div>
                <div class="h-4 bg-dust-200 rounded w-1/5"></div>
            </div>
        </div>
        <div class="animate-pulse flex space-x-4 mt-3">
            <div class="rounded-full bg-dust-200 h-8 w-8"></div>
            <div class="flex-1 flex justify-between items-center">
                <div class="h-4 bg-dust-200 rounded w-2/5"></div>
                <div class="h-4 bg-dust-200 rounded w-1/5"></div>
            </div>
        </div>
        <div class="animate-pulse flex space-x-4 mt-3">
            <div class="rounded-full bg-dust-200 h-8 w-8"></div>
            <div class="flex-1 flex justify-between items-center">
                <div class="h-4 bg-dust-200 rounded w-3/5"></div>
                <div class="h-4 bg-dust-200 rounded w-1/5"></div>
            </div>
        </div>
    </div>
</template>
