export default requester => {
    return {
        /**
         * Track actions
         *
         * @param {object} params
         */
        track (event, properties = {}) {
            return requester.post('/nerves/trait', {
                fingerprint: Cookie.get('fingerprint'),
                type: 'track',
                event: event,
                context: {
                  version: import.meta.env.VUE_APP_VERSION,
                  page: {
                    path: location.pathname,
                    referrer: document.referrer,
                    origin: location.href
                  }
                },
                properties,
                sent_at: new Date()
            });
        }
    };
}
