import TurndownService from 'turndown';
import DOMPurify from 'dompurify';
import { gfm, tables, strikethrough } from 'turndown-plugin-gfm';
import { stripHtml } from '@bignerve/ui-utils';
import { marked } from 'marked';
import { truncate, memoize } from 'lodash';

const providers = [
	{
		//reg: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/i,
		//reg: /^.*(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i,
		reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i,
		url: 'https://www.youtube.com/embed/$5',
		params: {
			'picture-in-picture': 1,
			accelerometer: 1,
			gyroscope: 1,
		},
	},
	{
		reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
		url: 'https://player.vimeo.com/video/$1',
		params: {
			title: 0,
			byline: 0,
			portrait: 0,
		},
	},

	{
		reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
		url: 'https://www.dailymotion.com/embed/video/$1',
		params: {
			autoplay: 0,
		},
	},
	{
		reg: /^.*coub.com\/(?:embed|view)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
		url: 'https://coub.com/embed/$1',
		params: {
			autoplay: 0,
		},
	},
];

const parse = (src) => {
	for (let i = 0; i < providers.length; i++) {
		const provider = providers[i];
		const match = src.match(provider.reg);
		if (match) {
			return {
				src: src.replace(provider.reg, provider.url),
				params: provider.params,
			};
		}
	}
	return null;
};

const mentionModule = {
	extensions: [
		{
			name: 'mention',
			level: 'inline',
			start (line) {
				const index = line.match(/\B@\w/)?.index;

				return index !== undefined ? index : -1;
			},
			tokenizer(line, tokens) {
				const [current] = tokens;

				if (!this.lexer.options.mention || !current || current.is_link) {
					return;
				}

				const match = line.match(/^@[a-zA-Z0-9]*[a-zA-Z0-9_.-\\]*/);

				if (match) {
					const [mention] = match;
					const handle = mention.slice(1).replaceAll(/\\/g, '');

					return {
						type: 'mention',
						raw: mention,
						handle: handle,
						href: `/${handle}`,
						text: `@${handle}`,
					}
				}

				return false;
			},
			renderer(token) {
				if (token.is_link) {
					return;
				}

				return `<a href="${token.href}" title="${token.raw}" class="not-prose font-semibold" rel="noopener noreferrer" target="_blank">${token.text}</a>`;
			},
		}
	],

	walkTokens(token) {
		if (token.type === 'link') {
			token?.tokens.forEach((t) => {
				t.is_link = true
			})
		}
	},						
};

const unfurlModule = {
	extensions: [
		{
			name: 'unfurl',
			level: 'inline',
			start(line) {
				return line.indexOf('https://') || 0;
			},
			tokenizer(line) {
				if (!this.lexer.options.unfurl) {
					return;
				  }
				
				  if (!line.startsWith('http')) {
					return;
				  }
				
				  const [href] = line.split(' ');
				  const parsed = parse(href); // Assuming this function parses the URL
				
				  if (parsed) {
					const wrapper = document.createElement('div');
					wrapper.classList.add(
						'rounded-md', 'overflow-hidden', 'shadow-md', 'bg-black',
						'aspect-image', 'w-full',
					);
				
					const iframe = document.createElement('iframe');
					iframe.src = parsed.src;
					iframe.frameborder = 0;
					iframe.width = '100%';
					iframe.height = '100%';
					iframe.style.display = 'inline';
					iframe.allowfullscreen = true;
				
					wrapper.appendChild(iframe);
				
					this.lexer.tokens.push({
					  block: true,
					  pre: '', // No pre tag needed
					  type: 'html', // Use container type
					  raw: wrapper.outerHTML, // Add the wrapper's outerHTML
					  text: wrapper.outerHTML, // Add the wrapper's outerHTML
					});
				  }
			},
		},
	],
};

marked.use(unfurlModule);
marked.use(mentionModule);

const renderer = new marked.Renderer();
const turndownService = new TurndownService();

renderer.link = function ({ href, tokens }) {
	const text = this.parser.parseInline(tokens);
	const link = href.replace(/\\/g, '');
	const target = link.startsWith('http') ? '_blank' : '';
  	const title = link.startsWith('http') ? text.replace(/\\/g, '') : text;

	// if the title is a link, then we need to truncate the text
	const label = title.startsWith('http') ? truncate(text, { length: 80, separator: /\//, omission: '...' }) : title;

	return target
		? ` <a href="${link}" target="${target}" title="${title}" rel="noopener noreferrer">${label}</a> `
		: ` <a href="${link}" title="${title}">${label}</a> `;
};


turndownService.use(gfm);
turndownService.use(tables);
turndownService.use(strikethrough);

turndownService.addRule('strikethrough', {
	filter: ['del', 's', 'strike'],
	replacement(content) {
		return `~~${content}~~`;
	},
});

turndownService.addRule('heading', {
	filter: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
	replacement(content, node) {
		const level = Number(node.nodeName.charAt(1));

		return `${'#'.repeat(level === 1 ? 2 : level)} ${content}`;
	},
});

// DOMPurify configuration
const SANITIZER_CONFIG = {
    ALLOWED_TAGS: ['br', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'strong', 'em', 'del', 'a', 'blockquote'],
    ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'title'],
};

// Initialize DOMPurify with config
DOMPurify.setConfig(SANITIZER_CONFIG);

// Create a memoized sanitizer function
const sanitize = memoize((content) => {
    if (!content) return '';
    return DOMPurify.sanitize(content, SANITIZER_CONFIG).trim();
});

// Helper function to preprocess HTML content
const preprocessHtml = (content) => {
    if (!content) return '';
    return content
        .replace(/\s{2,}\n{2}/g, '\n\n<br>\n\n')
        .replace(/^>\s(.+)$/gm, '<blockquote>$1</blockquote>')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/&lt;(br|p|h[1-6]|ul|ol|li|strong|em|del|a|blockquote)( [^>]*)?&gt;/gi, '<$1$2>')
        .replace(/&lt;\/(br|p|h[1-6]|ul|ol|li|strong|em|del|a|blockquote)&gt;/gi, '</$1>')
};

// Main conversion functions
export const toHTML = memoize((content, { inline, ...options } = {}) => {
    const preprocessed = preprocessHtml(content);
    if (!preprocessed) return '';

    const htmlCode = marked[inline ? 'parseInline' : 'parse'](preprocessed, {
        ...options,
        gfm: true,
        breaks: true
    }).replaceAll(/\n/g, '');

    return sanitize(htmlCode);
}, (...args) => JSON.stringify(args));

export const toMarkdown = (content) => {
    if (!content) return '';
    return turndownService.turndown(sanitize(content));
};

export const toRaw = (content) => {
    return stripHtml(toHTML(content, { sanitize: true })).trim();
};

export const useMarkdown = () => ({
    methods: { toHTML, toMarkdown, toRaw, stripHtml }
});
