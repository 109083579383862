<script>
import OlympiadsDropdown from '@/components/olympiad/OlympiadsMenu.vue';

export default {
    name: 'ReNerve',

    components: {
        OlympiadsDropdown
    },

    methods: {
        selectOlympiad () {
            //
        }
    }
}
</script>

<template>
    <OlympiadsDropdown type="bases" @selected="selectOlympiad">
        <template #activator>
            <button aria-label="button" type="button" class="disabled flex items-center font-semibold text-nerve hover:bg-nerve-100 rounded-md px-2 py-1 -my-1" @click.prevent>
                <NIcon as="retweet" class="mr-1 text-xl -mt-1" />
                <span>ReNerve</span>
            </button>
        </template>
    </OlympiadsDropdown>
</template>
