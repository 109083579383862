import replace from 'lodash/replace'
import { reactive, h, computed, onMounted, Transition } from 'vue';

const storage = reactive({});

export const useReveal = (to) => {
    const key = typeof to === 'string' ? replace(to, /\./g, ':') : 'main';
    
    const show = () => {
        storage[key] = true;
    }

    const hide = () => {
        storage[key] = false;
    }

    const toggle = () => {
        storage[key] = !storage[key];
    }

    const active = computed({
        get () {
            return storage[key];
        },
        set (value) {
            storage[key] = value;
        }
    })

    return { storage, show, hide, toggle, key, active };
}

export const RevealToggle = {
    name: 'RevealToggle',

    props: {
        label: {
            type: String,
        },
        to: {
            type: String,
        },
        once: {
            type: Boolean,
            default: false,
        },
    },

    setup (props, { slots, attrs }) {
        const { active, show, toggle, hide } = useReveal(props.to);

        onMounted(() => {
            if (active.value) {
                hide();
            }
        })

        return () => {
            if (props.once && active.value) {
                return null;
            }

            return slots.default ? slots.default({ active: active.value, show, toggle, hide }) : h('button', {
                ...attrs,
                onClick: toggle,
                class: ['px-3 py-1 rounded-lg bg-rater-100 text-rater hover:bg-rater-200 hover:text-rater-600', attrs.class],
            },  [
                h('NIcon', { props: { as: 'exclamation-circle-solid' }, class: 'mr-1' }),
                props.label,
            ]);
        };
    }
}

export const Reveal = {
    name: 'Reveal',

    props: {
        to: {
            type: String,
        }
    },

    setup (props, { slots }) {
        const { active } = useReveal(props.to);

        return () => {
            const [element, ...elements] = slots.default ? slots.default() : [];

            if (elements.length) {
                throw new Error('Reveal component can only have one child');
            }

            return h(Transition, { name: 'fade', mode: 'out-in' }, active.value ? [element] : null);
        };
    }
}

