import { injectHead, createHead } from '@vueuse/head';

const head = createHead();

function generateHead () {
    const meta = this.$options.metaInfo;

    if (meta) {
        const head = injectHead();
        const options = typeof meta === 'function'
            ? meta.call(this) : meta;

        const removeHead = head.addHeadObjs(options);
        head.updateDOM();

        this._removeHead = () => {
            removeHead();
            head.updateDOM();
        }
    }
}

export const install = ({ app }) => {
    app.use(head);

    app.mixin({
        updated: generateHead,
        mounted: generateHead,
        beforeUnmount () {
            if (this._removeHead) {
                this._removeHead();
            }
        }
    });
};