<script setup>
import ChallengeQuestion from '@/components/challenge-show/Question.vue';
import ChallengeLearn from '@/components/challenge-show/Learn.vue';
import ChallengeRating from '@/components/challenge/Rating.vue';
import ChallengePromotionsTip from '@/components/challenge/Promotions.vue';
import ChallengeIdeaForm from '@/components/challenge-show/IdeaForm.vue';
import PriorQQIdeas from '@/components/challenge-show/PriorQQIdeas.vue';
import QuestionIdeasLeaderboard from '@/components/challenge-show/QuestionIdeasLeaderboard.vue';
import QuestionsQuestionsLeaderbaord from '@/components/challenge-show/QuestionsQuestionsLeaderbaord.vue';
import ChallengePromotedIdeas from '@/components/challenge-show/PromotedIdeas.vue';
import DiscussionBoard from '@/components/discussions/DiscussionBoard.vue';
import WidgetTournaments from '@/components/widget/tournaments/index.vue';
import NMarkdown from '@/components/NMarkdown.vue';
import FeatureFlag from '@/components/FeatureFlag.vue';
import Button from '@/components/ui/button/index.vue';
import { CONTEXT } from '@/constant/discussion';
import { useFeatureFlag } from '@/composable/feature-flag';
import { bus } from '@/modules/bus';
import { isCaption } from '@/utils/challenge';
import { useRouteQuery } from '@vueuse/router';
import { RevealToggle, Reveal } from '@/components/Reveal';
import { useDetails } from '@/composable/challenge';
import { provide, ref, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useAuth } from '@/composable/auth';
import { api } from '@/modules/services';
import { useDiscussionStats } from '@/composable/discussion';
import { useIdeaCreationFlow } from '@/composable/idea';
import { crossPlatformState } from '@/utils/App';

const tourState = crossPlatformState('bignerve.tour');
const props = defineProps({
	challenge: {
		type: Object,
		required: true,
	},
});
const { auth } = useAuth();
const { maxUserIdeas, allowChallengeRating, totalUserIdeas, totalIdeasLeft } = useIdeaCreationFlow(props.challenge);
const { questionTitle, isSuggestion, isAMA, isQQ, isClosed } = useDetails(props.challenge);
const activeTab = useRouteQuery('tab', isClosed.value ? 'ideas' : 'enter-your-idea');
const store = useStore();
const tabs = ref(null);
const showQQTip = ref(true);
const userIsCatalyst = computed(() => props.challenge.catalyst && props.challenge.catalyst.id === currentUser.value.id);
const hasIdeas = computed(() => props.challenge.stats && props.challenge.stats.total_ideas > 0);

const hasUserIdeas = computed(() => totalUserIdeas.value > 0);
const currentUser = computed(() => store.getters['auth/currentUser'].user);
const canAddIdea = computed(() => {
	return (
		!isClosed.value &&
		props.challenge &&
		(!auth.authenticated ||
			(!userIsCatalyst.value &&
				totalIdeasLeft.value > 0 &&
				!props.challenge.user_stats.game_started))
	);
});

const { total: totalDiscussions } = useDiscussionStats({
	context: 'challenge',
	id: props.challenge.id,
	immediate: true
});

const showTournamentGuidePrize = async ({ catalyst }) => {
	const { data } = await api.widgets.olympiads('base_olympiad_id', { platform_slug: catalyst.handle });
	const { current } = data;

	if (!current || !current.olympiad || !current.olympiad.prize_total) {
		return;
	}

	const key = `tour-guide-olympiad-${current.olympiad.id}`;
	if (!tourState[key]) {
		bus('tour:next', {
			guide: 'challenge-detail-welcome',
			scrollToTop: true,
			payload: {
				olympiad: current.olympiad,
				storeKey: key,
				catalyst,
			},
		});
		tourState[key] = true;
	}
};

const { variantTo } = useFeatureFlag('challenge-tournament-guide-prize');
const variant = variantTo();

watch(() => props.challenge, ({ catalyst, user_stats } = {}) => {
	if (activeTab.value === 'discussions') {
		return;
	}
	
	if (!user_stats.game_started && !isClosed.value) {
		activeTab.value = 'enter-your-idea';
	} else if (!isClosed.value && activeTab.value === 'ideas') {
		activeTab.value = 'enter-your-idea';
	} else if (isClosed.value && !['discussions'].includes(activeTab.value)) {
		nextTick(() => {
			activeTab.value = isQQ.value ? 'promoted-ideas' : 'ideas';
		});
	} else if (catalyst) {
		if (!variant.value || !catalyst) {
			return;
		}

		if (auth.authenticated || variant.value.payload) {
			showTournamentGuidePrize({ catalyst });
		}
	}
}, { immediate: true });

provide('isCaption', isCaption(props.challenge));
</script>

<template>
	<div
		ref="challenge"
		id="challenge-header"
		class="home-grid challenge-view pt-3"
	>
		<div class="home-grid__top-content space-y-6 mb-4">
			<NBreadcrumbs
				:prepend="[
					{
						title: challenge.catalyst ? `${challenge.catalyst.first_name} ${challenge.catalyst.last_name}` : $platform.current.title,
						path: challenge.catalyst ? challenge.catalyst.handle : '/',
					},
					{
						title: questionTitle,
						path: challenge.catalyst ? challenge.catalyst.handle : '/',
					},
				]"
				disable-auto-crumbs
				class="!m-0"
			/>

			<div
				v-if="challenge.id"
				class="challenge-detail-card"
			>
				<ChallengeQuestion :challenge="challenge" />

				<FeatureFlag
					flag="shortcuts"
					variant="link-challenge-public-view-to-dashboard"
					:expect="true"
				>
					<div
						v-if="userIsCatalyst"
						class="p-2 border-t"
					>
						<RouterLink
							:to="{
								name: isClosed ? 'dashboard.catalyst.closed.detail.ideas' : 'dashboard.catalyst.detail.ideas',
								params: { challenge: challenge.slug, user: challenge.catalyst.handle },
							}"
							class="hover:!bg-dust-200 hover:!text-dust-800 bg-dust-100 text-dust-700 rounded-md inline-flex py-2.5 px-4 gap-3 leading-none items-center justify-center"
						>
							<NIcon as="sliders-h" /> View on dashboard
						</RouterLink>
					</div>
				</FeatureFlag>
			</div>

			<NTabs
				v-model="activeTab"
				ref="tabs"
				id="ideas"
				name="ideas"
				class="is-tertiary"
				active-class="is-active sm:shadow-lg border-l border-r"
				tab-class="!border-t-0"
			>
				<span
					v-if="isSuggestion && isQQ && !isClosed"
					class="block max-w-140"
				>
					<NTip
						:open="showQQTip"
						title="New Feature! QQs"
						description=""
						target="challenge:promoted-qq-ideas"
					>
						<template #description>
							<p class="max-w-55 m-0">
								QQs allow you to enter up to {{ maxUserIdeas }} ideas. You can come back any time to enter them,
								even after rating.
							</p>
						</template>
					</NTip>
				</span>

				<NTab
					v-if="isQQ"
					title="Promoted QQ Ideas"
					id="promoted-ideas"
					icon="qq text-innovator"
				>
					<div class="tabs__content-helper border -mt-[2px]">
						<ChallengePromotedIdeas
							v-if="challenge.id && activeTab === 'promoted-ideas'"
							:challenge="challenge"
							@loaded="({ ideas }) => {
								if (!ideas.length && activeTab === 'promoted-ideas') {
									activeTab = 'prior-qq-ideas'
								}
							}"
						/>
					</div>
				</NTab>

				<NTab
					title="Enter Your Ideas"
					id="enter-your-idea"
					:hidden="isClosed"
					icon="lightbulb-o1"
				>
					<div
						v-if="canAddIdea"
						class="tabs__content-helper border -mt-[2px]"
					>
						<ChallengeIdeaForm
							v-if="challenge"
							:challenge="challenge"
						/>
					</div>
					<div
						v-else-if="challenge.id"
						class="tabs__content-helper border -mt-[2px] flex items-center justify-center"
					>
						<div class="text-center p-4 w-full text-dust-800 border-dust-200 sm:max-w-[40rem] sm:my-20 sm:px-6 sm:py-10">
							<span v-if="isClosed">
								This challenge has closed. Check out the winning ideas in the <strong>Idea tab</strong>.
							</span>
							<span v-else-if="challenge.user_stats.can_rate_idea && totalIdeasLeft < maxUserIdeas">
								You already added one or more ideas, and you also started the game. See if there's ideas to rate via the RATE button.
							</span>
							<span v-else-if="challenge.user_stats.game_started && userIsCatalyst">
								You are the Catalyst author of this question. You can rate and mentor the ideas here in this Rating flow, or in your <RouterLink
									:to="{
										name: 'dashboard.catalyst.detail.ideas',
										params: { user: $auth.user.screen_name, challenge: challenge.slug },
									}"
									class="underline"
									><NIcon as="flame" /> Catalyst Dashboard.
								</RouterLink> (you can also start a chat with the innovator there).<br><br>You can't add more ideas yourself.
							</span>
							<span v-else-if="challenge.user_stats.game_started">
								You already started rating, so you can't enter your own ideas in this challenge, for fairness.
							</span>
							<span v-else-if="$auth.authenticated && totalIdeasLeft === 0">
								You already entered {{ maxUserIdeas }} ideas. So dive in and start rating other people's ideas!
							</span>
							<span v-else>
								You can't add ideas in this challenge because you are the Catalyst. But you can rate the incoming ideas here or in
								<RouterLink
									:to="{
										name: 'dashboard.catalyst.detail.ideas',
										params: { user: $auth.user.screen_name, challenge: challenge.slug },
									}"
									class="underline"
									>your <n-icon as="flame" /> Catalyst Dashboard.
								</RouterLink>
							</span>
						</div>
					</div>
				</NTab>

				<NTab
					title="Assumption Challenges"
					id="assumption-challenges"
					icon="eye"
					hidden
				>
					<div class="tabs__content-helper">Coming soon...</div>
				</NTab>

				<NTab
					v-if="isClosed && !isSuggestion"
					title="Winning Ideas"
					id="ideas"
					icon="lightbulb-o1"
				>
					<div class="tabs__content-helper border -mt-[2px]">
						<QuestionIdeasLeaderboard v-if="activeTab === 'ideas'" :challenge="challenge" />
					</div>
				</NTab>

				<NTab
					:hidden="!isQQ || !isClosed"
					title="QQ ideas"
					id="prior-qq-ideas"
					icon="qq"
				>
					<div class="tabs__content-helper">
						<PriorQQIdeas v-if="activeTab === 'prior-qq-ideas'" :challenge="challenge" />
					</div>
				</NTab>

				<NTab
					:hidden="isAMA"
					:title="`Discussions (${totalDiscussions || 0})`"
					id="discussions"
					icon="discussion-icon"
				>
					<div class="tabs__content-helper border">
						<DiscussionBoard :context="CONTEXT.CHALLENGE" :id="challenge.id" #header="{ scrollToCreatePost }">
							<p>
								Join in or start a discussion about this challenge question. Keep comments tight and focused on adding value. Community Guidelines apply.
								Earn neurons for insightful contributions!
							</p>

							<div class="flex items-center gap-2 mb-6">
								<strong>What are your thoughts?</strong>
								<button aria-label="button"
									type="button"
									class="flex items-center px-2 py-1 bg-dust-700 rounded-md hover:bg-dust-600 text-white"
									@click="scrollToCreatePost"
								>
									<NIcon
										as="discussion-icon"
										class="mr-2"
									/>
									Post
								</button>
							</div>
						</DiscussionBoard>

						<Button variant="link" class="text-dust-700 border" asChild>
							<RouterLink :to="{ name: 'discussion-forums.most-recent', params: { user: challenge.catalyst.handle } }" class="leading-none">
								View {{ challenge.catalyst.first_name }}'s Forum for all discussions <NIcon as="chevron-right" class="ml-1" />
							</RouterLink>
						</Button>
					</div>
				</NTab>

				<NTab
					v-if="$auth.authenticated && isQQ && userIsCatalyst"
					title="Director View"
					id="director-view"
					icon="director"
				>
					<div class="tabs__content-helper border -mt-[2px] relative">
						<ChallengePromotionsTip
							v-if="activeTab === 'director-view'"
							:challenge="challenge"
						>
							<QuestionsQuestionsLeaderbaord v-if="activeTab === 'director-view'" :challenge="challenge" />
						</ChallengePromotionsTip>
					</div>
				</NTab>
			</NTabs>

			<!-- Rate ideas -->
			<div v-if="!isAMA && !isClosed" class="flex items-center flex-wrap gap-4 justify-center">
				<RouterLink
					v-if="challenge.idea_entry_fee > 0 && hasUserIdeas && !challenge.user_stats.game_started"
					:to="{
						name: 'challenges.idea-unlock',
						params: { challenge: $route.params.challenge },
					}"
					class="bg-white text-white hover:text-white focus:text-white shadow-active rounded-md overflow-hidden p-0.5 max-w-[20rem]"
					aria-label="Rate ideas"
				>
					<span class="flex justify-center gap-2 bg-secondary-500 hover:bg-secondary-600 p-4 rounded-md">
						<NIcon
							as="funding"
							class="text-2xl"
						/>
						<span class="flex flex-col leading-4 justify-start items-start">
							<span class="font-semibold">
								Checkout &amp; Unlock Your Idea(s)
							</span>
							
							<small class="italic">
								To post your idea(s) in the challenge
							</small>
						</span>
					</span>
				</RouterLink>

				<RouterLink
					v-else
					:to="{
						name: challenge.user_stats.game_started || userIsCatalyst || hasUserIdeas ? 'challenges.rate' : 'challenges.start-rate',
						params: { challenge: $route.params.challenge },
						query: !hasIdeas ? { ideas: 'none' } : null,
					}"
					class="bg-white text-white hover:text-white focus:text-white shadow-active rounded-md overflow-hidden p-0.5 w-[17.5rem]"
					aria-label="Rate ideas"
				>
					<span class="flex justify-center gap-2 bg-rater-500 hover:bg-rater-600 py-4 rounded-md">
						<NIcon
							as="rater"
							class="text-2xl"
						/>
						<span class="flex flex-col leading-4 justify-start items-start">
							<span class="font-semibold">View the Ideas &amp; Rate</span>
							<small
								v-if="!hasIdeas || challenge.stats.total_ideas === challenge.user_stats.total_ideas"
								class="italic"
							>
								No ideas to rate yet
							</small>
							<small v-else-if="challenge.user_stats.total_ideas > 0">
								Earn neurons & give feedback!
							</small>
							<small
								v-else
								class="italic"
							>
								Without entering your own idea
							</small>
						</span>
					</span>
				</RouterLink>
			</div>

			<RevealToggle
				:to="$route.name"
				:once="true"
				label="Explain More"
			/>

			<Reveal
				:to="$route.name"
				label="Explain More"
			>
				<NNote :title="activeTab === 'enter-your-idea' ? '' : 'Note'">
					<template v-if="!isClosed && activeTab === 'enter-your-idea'">
						<template v-if="isAMA">
							<h3>
								This Suggestion Box question is a special kind of "Ask Me Anything", where
								{{ challenge.catalyst.first_name }} will mentor your own ideas directly (sometimes for a fee to support their
								work).
							</h3>
							<p>We're a place for crowdsourcing, and a place to get great mentoring.</p>
							<p>
								You can set your idea to "private" and only {{ challenge.catalyst.first_name }}
								{{ challenge.catalyst.last_name }} will ever see it. If they can help your idea along or improve it, they will
								rate and mentor it. If they don't think they can contribute, they might pass on it, and you don't pay the entry
								fee.
							</p>
							<p>
								If you set your idea to "Public" (option coming soon), then other participants can rate and mentor your idea, too.
								Any entry fee amount you pay to enter is shared by {{ challenge.catalyst.first_name }} and other mentors, yes,
								even you if help other people. The “explain more” can re-use the same text from the 1st IDEA INPUT tab
							</p>
						</template>
						<template v-else-if="challenge.idea_entry_fee > 0">
							<p><b>Note:</b> this challenge question is a special type of challenge question. It requires a one-time entry fee to enter up to 3 ideas for {{ challenge.catalyst.first_name }} to receive. You could also get valuable feedback on your ideas from the crowd via ratings and mentoring. Plus the Question asker (Catalyst) may give you feedback as well. </p>

							This payment is non-refundable. A portion of your payment is added to the Prize Total, so the money grows as more people join. The Top 33% or Top 30 participants (whichever is greater) earn a share of the prize money by earning points through entering ideas, rating ideas, and mentoring each others ideas. 
						</template>
						<template v-else>
							<h3>
								This is an open challenge, where ideas are rated in a double-blind flow. Here's how you can view all the current
								ideas in it:
							</h3>
							<p>
								(a) Enter up to 3 of your own ideas first, then you can see, rate and mentor other players’ ideas. <br />
								(b) Or just start rating (and mentoring) the other player’s ideas, <em>without</em> entering your own.
							</p>
							<p>
								If you start rating ideas in this challenge, you cannot come back and enter your own ideas later. This is to
								prevent you being influenced by other ideas.
							</p>
							<p>In either case, you can return and rate as many new ideas as you want any time, until the challenge closes.</p>
							<p>
								For each idea you enter, you may need to rate a few other players’ ideas to "unlock" yours. But, if you are the
								first innovator to enter, your idea will get published immediately. You can come back later and rate and mentor as
								many new ideas as you want.
							</p>
						</template>
					</template>
					<NMarkdown
						:code="
							$t(`page.challenge-detail.tab.${activeTab}.${challenge.solution_format}.${challenge.is_closed ? 'closed' : 'open'}.note`, {
								username: `${challenge.catalyst.first_name} ${challenge.catalyst.last_name}`,
								handle: challenge.catalyst.handle,
							})
						"
						tag="span"
						inline
					/>
				</NNote>
			</Reveal>
		</div>
		<div class="home-grid__sidebar flex flex-col gap-6">
			<ChallengeLearn />

			<ChallengeRating
				v-if="$auth.authenticated && challenge.id && !isClosed && allowChallengeRating"
				:challenge="challenge"
				class="shadow-md"
			/>

			<div v-if="!($responsive.sm && isAMA)" class="sm:sticky sm:top-[4rem]">
				<WidgetTournaments
					:platform-name="challenge.catalyst.first_name"
					:platform-slug="challenge.catalyst.handle"
				/>
			</div>
		</div>
	</div>
</template>
