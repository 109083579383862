import { useQuery } from '@tanstack/vue-query';
import { api } from '@/modules/services';
import { computed, toValue, isRef } from 'vue';
import { relate } from '@/utils/Relationships';
import { useInfiniteQuery } from '@tanstack/vue-query';

export const useChannels = (platformSlug) => {
    const isEndbled = computed(() => !!toValue(platformSlug));
    return useQuery({
        queryKey: ['forum-tools', 'channels', platformSlug],
        queryFn: async () => {
            const { data: response } = await api.channel.all(toValue(platformSlug));

            return response.data;
        },
        enabled: isEndbled,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}

export const useChannelMemberList = (platformSlug, channelSlug) => {
    const isEndbled = computed(() => !!toValue(platformSlug) && !!toValue(channelSlug));
    return useQuery({
        queryKey: ['forum-tools', 'channels', 'member-list', platformSlug, channelSlug],
        queryFn: async () => {
            const { data: response } = await api.channel.memberList(
                toValue(platformSlug), toValue(channelSlug)
            ).then(relate([
                ['user_id', 'user'],
            ]));

            return response.data.sort((a, b) => {
                if (a.status === 'active' && b.status !== 'active') return -1;
                if (a.status !== 'active' && b.status === 'active') return 1;
                return 0;
            });
        },
        enabled: isEndbled,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 60, // 1 minute
    });
};

export const useChannelSignupList = (platformSlug, params) => {
    const isEndbled = computed(() => !!toValue(platformSlug) && !!toValue(params));
    return useQuery({
        queryKey: ['forum-tools', 'channels', 'signup-list', platformSlug, params],
        queryFn: async () => {
            const { data: response } = await api.channel.signupList(
                toValue(platformSlug), toValue(params)
            ).then(relate([
                ['user_id', 'user'],
            ]));

            return response.data.sort((a, b) => {
                if (a.status === 'active' && b.status !== 'active') return -1;
                if (a.status !== 'active' && b.status === 'active') return 1;
                return 0;
            });
        },
        enabled: isEndbled,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 60, // 1 minute
    });
};

export const useForumTasksQueue = (platformSlug, channelSlug, params = {}) => {
    const computedParams = isRef(params) ? params : computed(() => params);
    const isEnabled = computed(() => !!toValue(platformSlug) && !!toValue(channelSlug));

    const { data, ...passthrough } = useInfiniteQuery({
        queryKey: ['forum-tools', 'tasks-queue', 7, platformSlug, channelSlug, params],
        queryFn: async ({ pageParam }) => {
            const { data: response } = await api.forum.tasksQueue(toValue(platformSlug), toValue(channelSlug), {
                ...toValue(params),
                page: pageParam
            }).then(relate([
                ['source.author_id', 'source.author'],
            ]));

            return response;
        },
        getNextPageParam: (lastResult) => {
            const { page, total } = lastResult.meta;

            if ((page + 1) * computedParams.value.size >= total) {
                return undefined;
            }

            return page + 1;
        },
        initialPageParam: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 60, // 1 minute
        enabled: isEnabled,
    });

    // reduce data to a single array
    const items = computed(() => data.value?.pages.reduce((acc, page) => {
        return acc.concat(page.data);
    }, []) || []);

    const page = computed(() => data.value?.pages[data.value.pages.length - 1]);
    const meta = computed(() => page.value?.meta || {});
    const relationships = computed(() => page.value?.relationships || {});

    return {
        ...passthrough,
        data: items,
        meta,
        relationships,
    };
};
