<script>
import moment from 'moment';
import { date } from '@bignerve/ui-utils';

export default {
    props: {
        label: {
            type: String,
            default: 'Challenge',
        },
        closed: {
            type: Boolean,
            default: false
        },
        iconLeft: {
            type: Boolean,
            default: false
        },
        showClosedTime: {
            type: Boolean,
            default: false
        },
        datetime: {
            type: [String, Date, Number, Object],
            default: () => new Date(),
        },
        from: {
            type: [String, Date, Number, Object],
        },
        to: {
            type: [String, Date, Number, Object],
        },
        autoUpdate: {
            type: [ Number, Boolean ],
            required: false
        }
    },

    data () {
        return {
            duration: this.getDuration()
        }
    },

    watch: {
        autoUpdate (newValue) {
            this.stopUpdater();

            if (newValue) {
                this.startUpdater();
            }
        },
        datetime () {
            this.updateDuration();
        },
    },

    computed: {
        days () {
            return moment().diff(moment(this.datetime), 'days');
        },
        isClosed () {
            return this.closed || moment(this.datetime).isBefore()
        },
        tooltip () {
            return [
                this.from && `Published ${date(this.from, 'YYYY-MM-DD @ HH:mm')}`,
                this.datetime && `Closes: ${date(this.datetime, 'YYYY-MM-DD @ HH:mm')}`,
            ].filter(v => v).join('<br>');
        }
    },

    mounted() {
        if (this.duration.days === 0 && this.autoUpdate) {
            this.startUpdater();
        }
    },

    beforeDestroy() {
        this.stopUpdater();
    },

    methods: {
        date,
        zLeft: v => (v < 10 ? `0${v}` : v),
        getDuration() {
            const now = moment(new Date());
            const end = moment(this.datetime);
            const duration = moment.duration(end.diff(now));

            return {
                days: duration.days(),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds()
            };
        },
        updateDuration() {
            this.duration = this.getDuration();

            if (this.duration.seconds <= 0) {
                this.stopUpdater();
            }
        },
        startUpdater() {
            if (this.autoUpdate) {
                const autoUpdate = this.autoUpdate === true ? 30 : this.autoUpdate;

                this.updater = setInterval(() => {
                    this.updateDuration();
                }, autoUpdate * 1000);
            }
        },
        stopUpdater() {
            if (this.updater) {
                clearInterval(this.updater);
                this.updater = null;
            }
        },
    }
}
</script>

<template>
    <div v-tooltip="{ html: true, content: tooltip }" class="flex">
        <div v-if="isClosed" class="flex flex-col items-center justify-center text-sm text-nerve">
            <span class="flex items-center flex-nowrap leading-tight font-bold">
                <span :class="iconLeft ? 'order-2' : 'order-1'">{{ label }} closed</span>
                <img :class="iconLeft ? 'order-1 mr-1' : 'order-2 ml-1'" class="icon" src="@/assets/icons/stopwatch.svg" alt="">
            </span>
            <small v-if="showClosedTime">
                <i>{{ date(datetime, 'YYYY-MM-DD @ HH:mm') }}</i>
            </small>
        </div>
        <span v-else-if="duration.days > 0" class="text-dust-700">
            <b class="mr-1">{{ label }} closes</b>{{ date(datetime, 'human') }}
        </span>
        <template v-else>
            <span class="catalyst-card__footer-meta-item">Time remaining:</span>
            <span class="in-day-countdown">
                <span class="in-day-countdown__digit">{{ zLeft(duration.hours) }}</span>
                <span class="in-day-countdown__digit">{{ zLeft(duration.minutes) }}</span>
                <span class="in-day-countdown__digit">{{ zLeft(duration.seconds) }}</span>
            </span>
        </template>
    </div>
</template>
