import axios from 'axios';
import game from './endpoints/game';
import widgets from './endpoints/widgets';
import analytics from './endpoints/analytics';
import dashboards from './endpoints/dashboards';
import challenges from './endpoints/challenges';
import resource from './endpoints/resource';
import platform from './endpoints/platform';
import olympiad from './endpoints/olympiad';
import stats from './endpoints/stats';
import comment from './endpoints/comment';
import innovator from './endpoints/innovator';
import brain from './endpoints/brain';
import auth from './endpoints/auth';
import user from './endpoints/user';
import me from './endpoints/me';
import email from './endpoints/email';
import sharing from './endpoints/sharing';
import geolocation from './endpoints/geolocation';
import survey from './endpoints/survey';
import drafts from './endpoints/drafts';
import idea from './endpoints/idea';
import staff from './endpoints/staff';
import content from './endpoints/content';
import discussions from './endpoints/discussions';
import prizes from './endpoints/prizes';
import topics from './endpoints/topics';
import forum from './endpoints/forum';
import channel from './endpoints/channel';
import invitation from './endpoints/invitation';

import { captureException, authorization, unauthorized, retryRequest, applyIdentity, relationships } from '@/services/middlewares';

export default class Api {
    /**
     * Create a new API instance.
     *
     * @param  {object}  config
     * @return {void}
     */
    constructor(config)
    {
        this.requester = axios.create(config);

        retryRequest(this.requester, {
            retries: 5,
            statusCodes: [102], // processing
            middleware (config) {
                // ...strategy

                return config;
            },
        });

        retryRequest(this.requester, {
            statusCodes: [504],
            isError: true,
            retries: 4
        });

        authorization(this.requester);
        unauthorized(this.requester);
        captureException(this.requester);
        applyIdentity(this.requester);
        relationships(this.requester, {
            fields: [
                // remote it after update mentoring endpoint
                [ 'challenge', 'challenge' ],
                [ 'mentor', 'mentor' ],
                //
                [ 'catalyst_id', 'catalyst' ],
                [ 'co_author_id', 'co_author' ],
                [ 'user_stats', 'user_stats' ],
                [ 'challenge_id', 'challenge' ],
                [ 'idea_id', 'idea' ],
                [ 'author_id', 'author' ],
                [ 'catalyst_earn_id', 'earnings' ],
                [ o => `user:${o.platform}`, 'user' ],
                [ o => `platform:${o.platform}`, 'platform' ],
                //
                [ 'context_id', 'context' ],

            ],
        });
    }

    /**
     * The dashboard endpoints.
     *
     * @return {object}
     */
    get dashboards()
    {
        return dashboards(this.requester);
    }

    /**
     * The widget endpoints.
     *
     * @return {object}
     */
    get widgets()
    {
        return widgets(this.requester);
    }

    /**
     * The analytics endpoints.
     *
     * @return {object}
     */
    get analytics()
    {
        return analytics(this.requester);
    }

    /**
     * The game endpoints.
     *
     * @return {object}
     */
    get game() {
        return game(this.requester);
    }

    /**
     * The challenges endpoints.
     *
     * @return {object}
     */
    get challenges() {
        return challenges(this.requester);
    }

    /**
     * Platform resources.
     *
     * @return {object}
     */
    get resource() {
        return resource(this.requester);
    }

    /**
     * Platform endpoints.
     *
     * @return {object}
     */
    get platform() {
        return platform(this.requester);
    }

    /**
     * Olympiad endpoints.
     *
     * @return {object}
     */
    get olympiad() {
        return olympiad(this.requester);
    }

    /**
     * Stats endpoints.
     *
     * @return {object}
     */
    get stats() {
        return stats(this.requester);
    }

    /**
     * Comment endpoints.
     *
     * @return {object}
     */
    get comment() {
        return comment(this.requester);
    }

    /**
     * Innovator endpoints.
     *
     * @return {object}
     */
    get innovator() {
        return innovator(this.requester);
    }

    /**
     * Brain endpoints.
     *
     * @return {object}
     */
    get brain() {
        return brain(this.requester);
    }

    /**
     * auth endpoints.
     *
     * @return {object}
     */
     get auth() {
        return auth(this.requester);
    }

    /**
     * User endpoints.
     *
     * @return {object}
     */
     get user() {
        return user(this.requester);
    }

    /**
     * Me endpoints.
     *
     * @return {object}
     */
    get me() {
        return me(this.requester);
    }

    /**
     * Sharing endpoints.
     *
     * @return {object}
     */
    get sharing() {
        return sharing(this.requester);
    }

    /**
     * Email endpoints.
     *
     * @return {object}
     */
    get email() {
        return email(this.requester);
    }

    /**
     * Feature Flags endpoints.
     *
     * @return {object}
     */
    get flags() {
        return (config = {}) => this.requester.get('/flags', config)
    }

    /**
     * Geolocation endpoints.
     * 
     * @return {object}
     */
    get geolocation() {
        return geolocation(this.requester);
    }

    /**
     * Survey endpoints.
     * 
     * @return {object}
     */
    get survey() {
        return survey(this.requester);
    }

    /**
     * Drafts endpoints.
     * 
     * @return {object}
     */
    get drafts() {
        return drafts(this.requester);
    }

    /**
     * Idea endpoints.
     * 
     * @return {object}
     */
    get idea() {
        return idea(this.requester);
    }

    /**
     * Staff endpoints.
     * 
     * @return {object}
     */
    get staff() {
        return staff(this.requester);
    }

    /**
     * Content endpoints.
     * 
     * @return {object}
     */
    get content() {
        return content(this.requester);
    }

    /**
     * Discussion endpoints.
     * 
     * @return {object}
     */
    get discussions() {
        return discussions(this.requester);
    }

    /**
     * Prizes endpoints.
     * 
     * @return {object}
     */
    get prizes () {
        return prizes(this.requester);
    }

    /**
     * Topics endpoints.
     * 
     * @return {object}
     */
    get topics () {
        return topics(this.requester);
    }

    /**
     * Forum endpoints.
     * 
     * @return {object}
     */
    get forum () {
        return forum(this.requester);
    }

    /**
     * Channel endpoints.
     * 
     * @return {object}
     */
    get channel () {
        return channel(this.requester);
    }

    /**
     * Invitation endpoints.
     * 
     * @return {object}
     */
    get invitation () {
        return invitation(this.requester);
    }
}
