<script setup>
import get from 'lodash/get';
import { ref, computed, onMounted } from 'vue';
import { nanoid } from 'nanoid';
import { on } from '@/modules/bus';
import { useDrafts } from '@/composable/drafts.js';
import { syncRef } from '@vueuse/core';
import { useFloating } from '@/composable/floating';

const { drafts, fetch } = useDrafts({
    draft_type: 'challenge',
    origin: 'challenge-promotion-flow',
});
const { target, ...tooltip } = useFloating();
const promotions = ref([]);
const uid = nanoid();

syncRef(promotions, drafts, { direction: 'rtl' })

on('challenge:draft:promotion:added', () => {
    fetch();
    showPromotions();
});

const showPromotions = () => {
    if (promotions.value.length) {
        tooltip.show();
    } else {
        tooltip.hide();
    }
}

// pre-load drafts
fetch();

onMounted(() => {
    showPromotions();
})
</script>

<template>
    <VTooltip
        :placement="$responsive.sm ? 'top' : 'right'"
        :auto-hide="false"
        :open-group="`promotions-${uid}`"
        :container="false"
        :triggers="[]"
        theme="raw"
        ref="target"
    >
        <slot></slot>

        <template #popper>
            <div class="bg-white rounded-lg shadow-lg border min-w-[20rem] max-w-xl p-2">
                <div class="bg-mentor text-white px-3 pt-3 pb-1">
                    <div class="flex flex-col">
                        <div class="px-3 py-2 text-lg font-semibold text-center">
                            QQ Ideas to Promote
                        </div>
                        <div class="p-4">
                            <p v-if="promotions.length" class="text-center">
                                <NIcon as="heart-solid" class="mr-2" />
                                <b>{{ promotions.length }}</b> QQ {{ $t('common.concept.idea', promotions.length) }} to Promote 
                            </p>
                            <p v-else class="text-center flex items-center">
                                <NIcon as="heart" left class="text-xl"/>
                                <span>No ideas to promote yet!</span>
                            </p>

                            <div :class="{ disabled: !promotions.length }" class="flex flex-col gap-2 items-center justify-center">
                                <router-link :to="{ name: 'challenges.create', query: { start: 'promotions'} }" class="inline-flex items-baseline shadow-active p-0.5 rounded-md bg-white">
                                    <span class="text-dust-800 bg-dust-100 font-semibold leading-none rounded-md px-3 py-1 hover:bg-dust-200">Edit Drafts</span>
                                </router-link>
                                <span class="text-mentor-200 italic">
                                    and Publish
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </VTooltip>
</template>

<style lang="scss">
._v-popper--theme-tip .v-popper__arrow-inner {
    &::before {
        content: "";
        @apply border-mentor absolute -left-[14px] -top-[5px];
        border-width: 15px;
        border-style: solid;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
    }
}
</style>
