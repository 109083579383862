<script>
import offsets from '@/store/modules/app/offsets.json';
import { get, set } from 'lodash'
import { usePlatform } from '@/mixins/ShowsPlatform';
import { debounce } from 'lodash';

export default {
    metaInfo: {
        title: 'EmailPreferences'
    },

    mixins: [usePlatform()],

    data () {
        return {
            timezones: {},
            userPreferences: {},
        };
    },

    computed: {
        preference () {
            const { preference } = this.$store.getters['auth/currentUser'].profile || {}

            return preference;
        }
    },

    methods: {
        from (key, defaultValue) {
            return get(this.userPreferences, key, defaultValue);
        },
        save (key, value) {
            set(this.userPreferences, key, value);
            this.saveUserPreferences();
        },
        async getTimezones () {
            this.timezones = offsets.reduce((acc, timezone) => {
                acc[timezone.name] = `${timezone.name} (UTC ${Number(timezone.utc) > 0 ? '+' : '' }${timezone.utc})`

                return acc;
            }, {})
        },
        async getUserPreferences () {
            const { data: response } = await this.$api.me.preferences();

            this.userPreferences = response.data;
        },
        saveUserPreferences: debounce(async function () {
            const { data: response } = await this.$api.me.savePreferences(
                this.userPreferences,
            );

            this.userPreferences = response.data;
        }, 1000),
    },

    mounted() {
        this.getTimezones();
        this.getUserPreferences();
    }
}
</script>

<template>
    <div class="page">
        <NPageHeader
            title="Email &amp; Alert Preferences"
            subtitle="Customize your email preferences"
            icon="envelope-regular"
        />

        <div class="page__content mb-6">
            <NDataForm method="post" action="" class="form-groups mb-8">
                <div class="form-group">
                    <label for="user-email" class="form-group__label">Your Email</label>
                    <p class="form-block-help-text">
                        We use your email to send you different kinds of notifications (see below). You can set what kinds of notifications you receive. <span class="form-inline-help-text">If you change your email address here, we'll send you an email with a verification link within 5 minutes to verify the change, for security.</span>
                    </p>
                    <div class="form-group__helper flex space-x-2" id="first-name">
                        <NInput type="text" id="user-email" name="user-email" placeholder="Your Email" class="max-w-sm disabled" :modelValue="currentUser.user.email" :disabled="true">
                            <template #prepend>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1 -ml-1 text-dust" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </template>
                        </NInput>
                        <button aria-label="button" type="submit" class="btn is-primary leading-none px-6" disabled>Save</button>
                    </div>
                </div>
            </NDataForm>
            <NDataForm :data="preference" method="post" action="/me/profiles/preference" class="form-groups mb-8" #default="form">
                <div class="form-group">
                    <label for="user-email" class="form-group__label">Your Time Zone</label>
                    <p class="form-block-help-text">
                        Set your preferred time zone. If you live in one timezone but prefer to receive communications in another timezone, you can set it here.
                    </p>
                    <div class="form-group__helper flex items-center" id="first-name">
                        <NSelect
                            v-if="preference"
                            v-model="preference.timezone"
                            :options="timezones"
                            id="timezone"
                            name="timezone"
                            placeholder="Timezone"
                            @change="form.submit"
                        />
                        <NLoading v-if="form.busy" class="ml-3" loading />
                    </div>
                </div>
                <NAlert :open="form.errors.any()" type="danger" class="mt-3 mb-0" closable>
                    Error processing your profile.
                </NAlert>
                <NAlert :open="form.success" type="success" class="mt-3 mb-0" closable>
                    Your profile has been updated.
                </NAlert>
            </NDataForm>
        </div>

        <div class="page__content mb-6">
        	<h2>Close your Account?</h2>
        	<p>If you want to remove your account, please <a href="/send-feedback">let us know here</a>.<br>
            <em>Your name and email will be removed but your contributed content will remain.</em> </p>
        </div>


        <div class="page__content mb-6">
            <NDataForm :data="preference" method="post" action="/me/profiles/preference" #default="form">
                <div class="flex items-center justify-between mb-4">
                    <h3 class="text-xl m-0">Mandatory – System Emails</h3>
                    <NSwitch
                        :modelValue="from('email.system', true)"
                        :disabled="true"
                        active-color="blue-300"
                        :icon="true"
                        @change="value => save('email.system', value)"
                    >
                        <template #prepend>
                            <span class="px-3 py-0.5 rounded-full bg-nerve-500 text-white text-sm">mandatory</span>
                        </template>
                    </NSwitch>
                </div>
                <p class="mb-2">
                    The notifications below are part of the mandatory emails:
                </p>
                <NAlert :open="form.errors.any()" type="danger" class="mt-3 mb-0" closable>
                    Error processing the preferences.
                </NAlert>
                <NAlert :open="form.success" type="success" class="mt-3 mb-0" closable>
                    Your profile has been updated.
                </NAlert>
                <ul class="mb-6">
                    <li>Welcome emails</li>
                    <li>Sign-up email verifications</li>
                    <li>Password reminders</li>
                    <li>Prize money won {this phase: paypal or us via stripe}</li>
                    <li>Add a Payment Wallet</li>
                    <li>Tech Support Email</li>
                    <li>Content Moderation Notices</li>
                    <li>Cheating notices</li>
                </ul>
            </NDataForm>
        </div>

        <div class="page__content mb-6">
            <h3 class="text-xl mb-4">Optional - Your Tournament Activity Digests</h3>
            <p class="mb-2">
                The follow email notifications are included in the Tournament Activity Summaries:
            </p>
            <ul class="mb-6">
                <li>Prize money notices – Prize descriptions e.g. Video AMA</li>
                <li>Helpful Tips</li>
                <li>Neurons</li>
                <li>Highlights</li>
                <li>Roles (Catalyst, Mentor, Analyst)</li>
            </ul>
            <NDataForm :data="preference" method="post" action="/me/profiles/preference" class="form-groups" #default="form">
                <div class="form-group">
                    <label for="user-email" class="form-group__label">Daily Digest</label>
                    <div class="flex items-start space-x-8">
                        <p class="form-block-help-text">
                            We'll email you a <strong>daily</strong> digest around 6pm Eastern, of activities that may be of interest to you, including new ideas posted, ideas to rate and mentor, and your other challenge activities.
                        </p>

                        <NSwitch
                            :modelValue="from('tournament_activity.daily_digest', true)"
                            active-color="secondary" icon 
                            @change="value => save('tournament_activity.daily_digest', value)"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label for="user-email" class="form-group__label">Weekly Digest</label>
                    <div class="flex items-start space-x-8">
                        <p class="form-block-help-text">
                            We'll email you a <strong>weekly</strong> digest every Friday, of activities that may be of interest to you, including new ideas posted, ideas to rate and mentor, and your other challenge activities.
                        </p>

                        <NSwitch
                            :modelValue="from('tournament_activity.weekly_digest', false)"
                            active-color="secondary" icon
                            @change="value => save('tournament_activity.weekly_digest', value)"
                        />
                    </div>
                </div>
                <NAlert :open="form.errors.any()" type="danger" class="mt-3 mb-0" closable>
                    Error processing the preferences.
                </NAlert>
                <NAlert :open="form.success" type="success" class="mt-3 mb-0" closable>
                    Your profile has been updated.
                </NAlert>
            </NDataForm>
        </div>

        <div class="page__content mb-6" non-functional>
            <h3 class="text-xl mb-4">Digest Settings</h3>
            <p class="mb-6">Customize how you receive updates from the BigNerve community</p>

            <NDataForm class="form-groups" >
                <div class="form-group">
                    <label for="digest_type" class="form-group__label">Email Format</label>
                    <div class="flex items-start justify-between space-x-8  w-full">
                        <p class="form-block-help-text">
                            Choose how your daily and weekly digests are presented. Customize the layout to focus on what matters most to you, whether it's concise summaries or detailed breakdowns of innovative ideas and challenges.
                        </p>

                        <div>
                            <NSelect
                                :modelValue="from('digest_settings.email_type', 'compact')"
                                :options="['full', 'compact', 'custom'].map(x => ({ label: x, value: x }))"
                                id="digest_type"
                                name="digest_type"
                                placeholder="Digest Type"
                                @change="value => save('digest_settings.email_type', value)"
                            />
                        </div>
                    </div>
                </div>
                
                <template v-if="from('digest_settings.email_type', 'compact') === 'custom'">
                    <div class="form-group">
                        <label for="include_images" class="form-group__label">Include Images</label>
                        <div class="flex items-start justify-between space-x-8  w-full">
                            <p class="form-block-help-text">
                                Enhance your digests with visual representations of activities, challenges, and innovative solutions. Images can provide quick insights and make your digest more engaging and memorable.
                            </p>

                            <NSwitch
                                :modelValue="from('digest_settings.include_images', true)"
                                active-color="secondary" icon 
                                name="include_images"
                                @change="value => save('digest_settings.include_images', value)"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="include_icons" class="form-group__label">Include icons</label>
                        <div class="flex items-start justify-between space-x-8 w-full">
                            <p class="form-block-help-text">
                                Add intuitive icons to your digests to quickly identify different types of activities, challenges, or idea categories. Icons can help you navigate through the content more efficiently and highlight key areas of interest.
                            </p>

                            <NSwitch
                                :modelValue="from('digest_settings.include_icons', true)"
                                active-color="secondary" icon 
                                name="include_icons"
                                @change="value => save('digest_settings.include_icons', value)"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="character_limit" class="form-group__label">Character limit</label>
                        <div class="flex items-start justify-between space-x-8  w-full">
                            <p class="form-block-help-text">
                                Set the maximum length for idea summaries and challenge descriptions in your digest. This helps in maintaining concise, focused content while ensuring you get the essence of each innovative concept. Adjust this to balance between brevity and detail.
                            </p>

                            <div>
                                <NInput :modelValue="from('digest_settings.character_limit', 300)" name="character_limit" :min="50" :max="999999" @change="value => save('digest_settings.character_limit', Number(value))" type="number" />
                            </div>
                        </div>
                    </div>
                </template>
            </NDataForm>
        </div>

        <div class="page__content mb-6">
            Set your <RouterLink :to="{ name: 'account.my-account.forum-preferences' }" class="underline text-inherit">Forum Digest Prefences</RouterLink> here in the Account section.
        </div>
    </div>
</template>
