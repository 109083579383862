<script>
    export default {
        inject: ['form']
    }
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title has-icon">
                <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                <b>How do you want it better?</b>
            </h2>
        </div>
        <div class="form-section-wrapper">
            <div class="form-groups">
                <div class="form-group">
                    <label class="form-group__label">What is the purpose of your idea:</label>
                    <NTextarea
                        name="purpose"
                        v-model="form.fields.idea.purpose"
                        placeholder="Describe your purpose; optional."
                    ></NTextarea>
                    <p class="form-block-help-text has-margin-top has-no-margin-bottom">
                        Optional; you probably need to fill this in. Why? In your mind you have a tacit purpose, and often it’s not obvious. To get the best solutions, clarify your purpose... Make it more fun? efficient? cheaper? more fluffy?
                    </p>
                </div>
            </div>
        </div>
        <div class="info-box">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <strong>IMPROVE: This is an open “Make it Better” Challenge.</strong> Your idea will be posted so that people know that this is your idea (open challenge). They will make it better by offering suggestions, and the best suggestions will be ranked by the crowd for you to choose. (By showing them your idea, people will know where you’re heading with it, so you might get less divergent options.) You have an idea, but is it the best it could be? Let our community of innovators make this idea better. You know what you want, you don’t need more ideas, you just need a quick way to make an idea better. This assumes you’ve reached a local maximum. You’re not trying to hide your idea in the crowd’s.
            </p>
        </div>
    </div>
</template>
