export const ranks = [
    {
        id: "analyst-10",
        rank_position: 1,
        role: "analyst",
        label: "Simon Cowell",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "analyst-1000",
        rank_position: 10,
        role: "analyst",
        label: "Solomon",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "analyst-20",
        rank_position: 2,
        role: "analyst",
        label: "Barbara Walters",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "analyst-30",
        rank_position: 3,
        role: "analyst",
        label: "Bernard Brodie",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "analyst-40",
        rank_position: 4,
        role: "analyst",
        label: "Alfred Adler",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "analyst-50",
        rank_position: 5,
        role: "analyst",
        label: "Jürgen Habermas",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "analyst-60",
        rank_position: 6,
        role: "analyst",
        label: "Hypatia",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "analyst-70",
        rank_position: 7,
        role: "analyst",
        label: "Earl Warren",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "analyst-80",
        rank_position: 8,
        role: "analyst",
        label: "Daniel Ellsberg",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "analyst-90",
        rank_position: 9,
        role: "analyst",
        label: "Carl Jung",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "catalyst-10",
        rank_position: 1,
        role: "catalyst",
        label: "Enrico Fermi",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "catalyst-1000",
        rank_position: 10,
        role: "catalyst",
        label: "Socrates",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "catalyst-20",
        rank_position: 2,
        role: "catalyst",
        label: "Ida Tarbell",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "catalyst-30",
        rank_position: 3,
        role: "catalyst",
        label: "Descartes",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "catalyst-40",
        rank_position: 4,
        role: "catalyst",
        label: "Feynman",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "catalyst-50",
        rank_position: 5,
        role: "catalyst",
        label: "Feuerbach",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "catalyst-60",
        rank_position: 6,
        role: "catalyst",
        label: "Marie Curie",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "catalyst-70",
        rank_position: 7,
        role: "catalyst",
        label: "Kant",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "catalyst-80",
        rank_position: 8,
        role: "catalyst",
        label: "Aquinas",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "catalyst-90",
        rank_position: 9,
        role: "catalyst",
        label: "Hegel",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "director-10",
        rank_position: 1,
        role: "director",
        label: "Christopher Nolan",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "director-1000",
        rank_position: 10,
        role: "director",
        label: "Steven Spielberg",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "director-20",
        rank_position: 2,
        role: "director",
        label: "Peter Jackson",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "director-30",
        rank_position: 3,
        role: "director",
        label: "Spike Lee",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "director-40",
        rank_position: 4,
        role: "director",
        label: "The Russo Brothers",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "director-50",
        rank_position: 5,
        role: "director",
        label: "Oprah Winfrey",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "director-60",
        rank_position: 6,
        role: "director",
        label: "Ridley Scott",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "director-70",
        rank_position: 7,
        role: "director",
        label: "Martin Scorcese",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "director-80",
        rank_position: 8,
        role: "director",
        label: "George Lucas",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "director-90",
        rank_position: 9,
        role: "director",
        label: "James Cameron",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "innovator-10",
        rank_position: 1,
        role: "innovator",
        label: "Innovator 1",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "innovator-1000",
        rank_position: 10,
        role: "innovator",
        label: "Innovator 10",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "innovator-20",
        rank_position: 2,
        role: "innovator",
        label: "Innovator 2",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "innovator-30",
        rank_position: 3,
        role: "innovator",
        label: "Innovator 3",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "innovator-40",
        rank_position: 4,
        role: "innovator",
        label: "Innovator 4",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "innovator-50",
        rank_position: 5,
        role: "innovator",
        label: "Innovator 5",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "innovator-60",
        rank_position: 6,
        role: "innovator",
        label: "Innovator 6",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "innovator-70",
        rank_position: 7,
        role: "innovator",
        label: "Innovator 7",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "innovator-80",
        rank_position: 8,
        role: "innovator",
        label: "Innovator 8",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "innovator-90",
        rank_position: 9,
        role: "innovator",
        label: "Innovator 9",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "insight-10",
        rank_position: 1,
        role: "insight",
        label: "F. A. Hayek",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "insight-1000",
        rank_position: 10,
        role: "insight",
        label: "Albert Einstein",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "insight-20",
        rank_position: 2,
        role: "insight",
        label: "Ada Lovelace",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "insight-30",
        rank_position: 3,
        role: "insight",
        label: "Richard Feinman",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "insight-40",
        rank_position: 4,
        role: "insight",
        label: "James Madison",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "insight-50",
        rank_position: 5,
        role: "insight",
        label: "John von Neumann",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "insight-60",
        rank_position: 6,
        role: "insight",
        label: "Niels Bohr",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "insight-70",
        rank_position: 7,
        role: "insight",
        label: "Adam Smith",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "insight-80",
        rank_position: 8,
        role: "insight",
        label: "Sir Isaac Newton",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "insight-90",
        rank_position: 9,
        role: "insight",
        label: "Charles Darwin",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "mentor-10",
        rank_position: 1,
        role: "mentor",
        label: "Diogenes",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "mentor-1000",
        rank_position: 10,
        role: "mentor",
        label: "Buddha",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "mentor-20",
        rank_position: 2,
        role: "mentor",
        label: "Pythagoras",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "mentor-30",
        rank_position: 3,
        role: "mentor",
        label: "Laozi",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "mentor-40",
        rank_position: 4,
        role: "mentor",
        label: "Voltaire",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "mentor-50",
        rank_position: 5,
        role: "mentor",
        label: "Maria Montessori",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "mentor-60",
        rank_position: 6,
        role: "mentor",
        label: "Gandhi",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "mentor-70",
        rank_position: 7,
        role: "mentor",
        label: "Confucius",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "mentor-80",
        rank_position: 8,
        role: "mentor",
        label: "Plato",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "mentor-90",
        rank_position: 9,
        role: "mentor",
        label: "Aristotle",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "overall-10",
        rank_position: 1,
        role: "overall",
        label: "Archimedes",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "overall-1000",
        rank_position: 10,
        role: "overall",
        label: "Da Vinci",
        description: "",
        start_level: 90,
        end_level: 100,
        type: "",
        next_level_score: 100
    },
    {
        id: "overall-20",
        rank_position: 2,
        role: "overall",
        label: "Morse",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "overall-30",
        rank_position: 3,
        role: "overall",
        label: "Page",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "overall-40",
        rank_position: 4,
        role: "overall",
        label: "Bell",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "overall-50",
        rank_position: 5,
        role: "overall",
        label: "Franklin",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "overall-60",
        rank_position: 6,
        role: "overall",
        label: "Jobs",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "overall-70",
        rank_position: 7,
        role: "overall",
        label: "Disney",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "overall-80",
        rank_position: 8,
        role: "overall",
        label: "Tesla",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "overall-90",
        rank_position: 9,
        role: "overall",
        label: "Edison",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "patron-10",
        rank_position: 1,
        role: "patron",
        label: "Andreesen",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "patron-1000",
        rank_position: 10,
        role: "patron",
        label: "Medici",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "patron-20",
        rank_position: 2,
        role: "patron",
        label: "Stanford",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "patron-30",
        rank_position: 3,
        role: "patron",
        label: "Warburg",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "patron-40",
        rank_position: 4,
        role: "patron",
        label: "Bardi",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "patron-50",
        rank_position: 5,
        role: "patron",
        label: "Bill Gates",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "patron-60",
        rank_position: 6,
        role: "patron",
        label: "Warren Buffett",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "patron-70",
        rank_position: 7,
        role: "patron",
        label: "Mellon",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "patron-80",
        rank_position: 8,
        role: "patron",
        label: "Rothschild",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "patron-90",
        rank_position: 9,
        role: "patron",
        label: "Carnegie",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    },
    {
        id: "scout-10",
        rank_position: 1,
        role: "scout",
        label: "Lewis & Clark",
        description: "",
        start_level: 0,
        end_level: 9,
        type: "",
        next_level_score: 10
    },
    {
        id: "scout-1000",
        rank_position: 10,
        role: "scout",
        label: "Magellan",
        description: "",
        start_level: 990,
        end_level: 999,
        type: "",
        next_level_score: 1000
    },
    {
        id: "scout-20",
        rank_position: 2,
        role: "scout",
        label: "Humbolt",
        description: "",
        start_level: 10,
        end_level: 19,
        type: "",
        next_level_score: 20
    },
    {
        id: "scout-30",
        rank_position: 3,
        role: "scout",
        label: "Balboa",
        description: "",
        start_level: 20,
        end_level: 29,
        type: "",
        next_level_score: 30
    },
    {
        id: "scout-40",
        rank_position: 4,
        role: "scout",
        label: "Amerigo Vespucci",
        description: "",
        start_level: 30,
        end_level: 39,
        type: "",
        next_level_score: 40
    },
    {
        id: "scout-50",
        rank_position: 5,
        role: "scout",
        label: "Jacques Cousteau",
        description: "",
        start_level: 40,
        end_level: 49,
        type: "",
        next_level_score: 50
    },
    {
        id: "scout-60",
        rank_position: 6,
        role: "scout",
        label: "Leif Ericson",
        description: "",
        start_level: 50,
        end_level: 59,
        type: "",
        next_level_score: 60
    },
    {
        id: "scout-70",
        rank_position: 7,
        role: "scout",
        label: "Amelia Earhart",
        description: "",
        start_level: 60,
        end_level: 69,
        type: "",
        next_level_score: 70
    },
    {
        id: "scout-80",
        rank_position: 8,
        role: "scout",
        label: "Armstrong",
        description: "",
        start_level: 70,
        end_level: 79,
        type: "",
        next_level_score: 80
    },
    {
        id: "scout-90",
        rank_position: 9,
        role: "scout",
        label: "Drake",
        description: "",
        start_level: 80,
        end_level: 89,
        type: "",
        next_level_score: 90
    }
]

export const thresholds = {
    1:	{ start: 0, end: 51 },
    2:	{ start: 51, end: 208 },
    3:	{ start: 208, end: 477 },
    4:	{ start: 477, end: 864 },
    5:	{ start: 864, end: 1375 },
    6:	{ start: 1375, end: 2016 },
    7:	{ start: 2016, end: 2793 },
    8:	{ start: 2793, end: 3712 },
    9:	{ start: 3712, end: 4779 },
    10:	{ start: 4779, end: 6000 },
    11:	{ start: 6000, end: 7381 },
    12:	{ start: 7381, end: 8928 },
    13:	{ start: 8928, end: 10647 },
    14:	{ start: 10647, end: 12544 },
    15:	{ start: 12544, end: 14625 },
    16:	{ start: 14625, end: 16896 },
    17:	{ start: 16896, end: 19363 },
    18:	{ start: 19363, end: 22032 },
    19:	{ start: 22032, end: 24909 },
    20:	{ start: 24909, end: 28000 },
    21:	{ start: 28000, end: 31311 },
    22:	{ start: 31311, end: 34848 },
    23:	{ start: 34848, end: 38617 },
    24:	{ start: 38617, end: 42624 },
    25:	{ start: 42624, end: 46875 },
    26:	{ start: 46875, end: 51376 },
    27:	{ start: 51376, end: 56133 },
    28:	{ start: 56133, end: 61152 },
    29:	{ start: 61152, end: 66439 },
    30:	{ start: 66439, end: 72000 },
    31:	{ start: 72000, end: 77841 },
    32:	{ start: 77841, end: 83968 },
    33:	{ start: 83968, end: 90387 },
    34:	{ start: 90387, end: 97104 },
    35:	{ start: 97104, end: 104125 },
    36:	{ start: 104125, end: 111456 },
    37:	{ start: 111456, end: 119103 },
    38:	{ start: 119103, end: 127072 },
    39:	{ start: 127072, end: 135369 },
    40:	{ start: 135369, end: 144000 },
    41:	{ start: 144000, end: 152971 },
    42:	{ start: 152971, end: 162288 },
    43:	{ start: 162288, end: 171957 },
    44:	{ start: 171957, end: 181984 },
    45:	{ start: 181984, end: 192375 },
    46:	{ start: 192375, end: 203136 },
    47:	{ start: 203136, end: 214273 },
    48:	{ start: 214273, end: 225792 },
    49:	{ start: 225792, end: 237699 },
    50:	{ start: 237699, end: 250000 },
    51:	{ start: 250000, end: 262701 },
    52:	{ start: 262701, end: 275808 },
    53:	{ start: 275808, end: 289327 },
    54:	{ start: 289327, end: 303264 },
    55:	{ start: 303264, end: 317625 },
    56:	{ start: 317625, end: 332416 },
    57:	{ start: 332416, end: 347643 },
    58:	{ start: 347643, end: 363312 },
    59:	{ start: 363312, end: 379429 },
    60:	{ start: 379429, end: 396000 },
    61:	{ start: 396000, end: 413031 },
    62:	{ start: 413031, end: 430528 },
    63:	{ start: 430528, end: 448497 },
    64:	{ start: 448497, end: 466944 },
    65:	{ start: 466944, end: 485875 },
    66:	{ start: 485875, end: 505296 },
    67:	{ start: 505296, end: 525213 },
    68:	{ start: 525213, end: 545632 },
    69:	{ start: 545632, end: 566559 },
    70:	{ start: 566559, end: 588000 },
    71:	{ start: 588000, end: 609961 },
    72:	{ start: 609961, end: 632448 },
    73:	{ start: 632448, end: 655467 },
    74:	{ start: 655467, end: 679024 },
    75:	{ start: 679024, end: 703125 },
    76:	{ start: 703125, end: 727776 },
    77:	{ start: 727776, end: 752983 },
    78:	{ start: 752983, end: 778752 },
    79:	{ start: 778752, end: 805089 },
    80:	{ start: 805089, end: 832000 },
    81:	{ start: 832000, end: 859491 },
    82:	{ start: 859491, end: 887568 },
    83:	{ start: 887568, end: 916237 },
    84:	{ start: 916237, end: 945504 },
    85:	{ start: 945504, end: 975375 },
    86:	{ start: 975375, end: 1005856 },
    87:	{ start: 1005856, end: 1036953 },
    88:	{ start: 1036953, end: 1068672 },
    89:	{ start: 1068672, end: 1101019 },
    90:	{ start: 1101019, end: 1134000 },
    91:	{ start: 1134000, end: 1167621 },
    92:	{ start: 1167621, end: 1201888 },
    93:	{ start: 1201888, end: 1236807 },
    94:	{ start: 1236807, end: 1272384 },
    95:	{ start: 1272384, end: 1308625 },
    96:	{ start: 1308625, end: 1345536 },
    97:	{ start: 1345536, end: 1383123 },
    98:	{ start: 1383123, end: 1421392 },
    99:	{ start: 1421392, end: 1460349 },
    100:{ start: 1460349, end: 1460349 },
}
