<script setup>
import VoteButton from './VoteButton.vue';
import Button from '@/components/ui/button/index.vue';
import { computed, reactive } from 'vue';
import { useFloating } from '@/composable/floating';
import { useResponsive } from '@/composable/responsive';
import { get } from 'lodash';
import { ref } from 'vue';

const props = defineProps({
	label: {
		type: String,
	},

	discussion: {
		type: Object,
		required: true,
	},
	
	placement: {
		type: String,
		default: 'left',
	},
});


// labels on lang file
const availableVotes = ['ai-slop', 'off-topic', 'antisocial', 'uncivil'];

const { target, ...refDropdown } = useFloating();
const responsive = useResponsive();
const dropdown = reactive(refDropdown);
const currentVotes = ref(get(props.discussion, 'user_votes', []));

const total = computed(() =>
	availableVotes.reduce((acc, key) => acc + get(props.discussion, `stats.total_${key}`, 0), 0)
);

const hasVote = computed(() =>
	currentVotes.value
		.some((v) => availableVotes.includes(v.vote_type) && v.voted)
);

const hide = () => {
	dropdown.hide();
};

const hasVoted = (type) => {
	return currentVotes.value
		.some((v) => v.vote_type === type && v.voted);
}

const updateVotes = (vote) => {
	// Initialize stats if needed
	props.discussion.stats ??= {};

	// Find existing vote
	const existingVoteIndex = currentVotes.value.findIndex(v => v.vote_type === vote.vote_type);
	const previousVote = currentVotes.value[existingVoteIndex];

	// Update stats
	const statsKey = `total_${vote.vote_type}`;
	if (previousVote?.voted) {
		props.discussion.stats[`total_${previousVote.vote_type}`]--;
	}
	props.discussion.stats[statsKey] = Math.max(0, (props.discussion.stats[statsKey] || 0) + (vote.voted ? 1 : -1));

	// Update votes list
	if (existingVoteIndex >= 0) {
		currentVotes.value[existingVoteIndex] = vote;
	} else {
		currentVotes.value.push(vote);
	}
	currentVotes.value = [...currentVotes.value];
}
</script>

<template>
	<VDropdown
		:placement="responsive.sm ? 'bottom' : placement"
		ref="target"
		theme="report"
		container="body"
		auto-hide
	>
		<slot name="activator" :hide="hide" :showing="dropdown.showing" :total="total" :hasVote="hasVote">
		<button aria-label="button"
			v-tooltip="{ content: title, html: true, disabled: dropdown.showing }"
			type="button"
			v-bind="$attrs"
			class="text-lg text-dust-700 hover:text-red-500 focus:text-red-500 flex items-center leading-tight"
			:class="{ 'text-red-500': hasVote }"
			@keydown.esc="hide"
		>
			<NIcon as="frown-regular" :left="!!label" />
			<span v-if="label">{{ label }}</span>
			<span v-if="total">({{ total }})</span>
		</button>
    </slot>
		<template #popper>
			<div class="font-normal bg-white rounded-md min-w-40 h-full flex flex-col border-red-500 shadow-lg mx-4 sm:mx-0">
				<div class="inline-flex flex-row flex-wrap items-start justify-start p-2 gap-1 border border-red-500 rounded-lg">
					<Button variant="outline" disabled :class="'gap-1 hover:text-white hover:bg-red-500 hover:border-red-500'">
						<NIcon as="microphone-slash" /> Mute
					</Button>
					<VoteButton v-for="voteType in availableVotes"
						:key="voteType"
						:discuss="discussion"
						:type="voteType"
						:class="'hover:text-white hover:bg-red-500 hover:border-red-500'"
						:variant="hasVoted(voteType) ? 'destructive' : 'outline'"
						@voted="updateVotes"
					/>
				</div>
			</div>
		</template>
	</VDropdown>
</template>

<style lang="scss">
.v-popper--theme-report .v-popper__arrow-outer {
	visibility: hidden;
}

.v-popper--theme-report .v-popper__arrow-inner {
	visibility: hidden;
}
</style>
