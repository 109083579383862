<script>
    export default {
        inject: ['form'],

        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            implicit: {
                type: Boolean,
                default: false
            },
        },

        emits: ['select'],
    }
</script>

<template>
   <div>
        <div class="tabs__content-helper">
            <div class="form-groups">
                <div class="form-group">
                    <label class="form-group__label">
                        {{ $t('component.challenge.assumptions.core-cause.what.label') }}
                    </label>
                    <div class="textarea-with-info">
                        <div class="non-functional textarea-with-info__neurons">+12 Neurons</div>
                        <NTextarea :disabled="disabled" name="assumption_what" v-model="form.fields.challenge.assumption_what" :placeholder="$t('component.challenge.assumptions.core-cause.what.placeholder')" />
                        <div class="textarea-with-info__more">
                            <span class="textarea-with-info__more-caption">
                                <NIcon as="check-circle-solid" class="text-dust-600" :class="{ 'text-nerve': form.fields.challenge.assumption_what }" left />
                                <span class="question-word">
                                    {{ $t('component.challenge.assumptions.core-cause.what.word') }}
                                </span>
                            </span>
                            <button aria-label="assumption" type="button" :class="{ 'bg-nerve': form.fields.challenge.assumption_what }" class="bg-dust-400 text-white py-1 px-3 rounded-b-md leading-tight hover:bg-nerve" @click="$emit('select', 'what')">
                                <span>{{ $t('component.challenge.assumptions.core-cause.action.deep-dive') }}</span>
                                <NIcon as="arrow-alt-circle-right-regular" right />
                            </button>
                        </div>
                    </div>
                    <p class="form-block-help-text has-margin-top has-no-margin-bottom">
                        {{ $t('component.challenge.assumptions.core-cause.what.note') }}
                    </p>
                </div>
                <div class="form-group">
                    <label class="form-group__label">
                        {{ $t('component.challenge.assumptions.core-cause.who.label') }}
                    </label>
                    <div class="textarea-with-info">
                        <div class="non-functional textarea-with-info__neurons">+12 Neurons</div>
                        <NTextarea :disabled="disabled" name="assumption_who" v-model="form.fields.challenge.assumption_who" :placeholder="$t('component.challenge.assumptions.core-cause.who.placeholder')" />
                        <div class="textarea-with-info__more">
                            <span class="textarea-with-info__more-caption">
                                <NIcon as="check-circle-solid" class="text-dust-600" :class="{ 'text-nerve': form.fields.challenge.assumption_who }" left />
                                <span class="question-word">
                                    {{ $t('component.challenge.assumptions.core-cause.who.word') }}
                                </span>
                            </span>
                            <button aria-label="assumption" type="button" :class="{ 'bg-nerve': form.fields.challenge.assumption_who }" class="bg-dust-400 text-white py-1 px-3 rounded-b-md leading-tight hover:bg-nerve" @click="$emit('select', 'who')">
                                <span>{{ $t('component.challenge.assumptions.core-cause.action.deep-dive') }}</span>
                                <NIcon as="arrow-alt-circle-right-regular" right />
                            </button>
                        </div>
                    </div>
                    <p class="form-block-help-text has-margin-top has-no-margin-bottom">
                        {{ $t('component.challenge.assumptions.core-cause.who.note') }}
                    </p>
                </div>
                <div class="form-group">
                    <label class="form-group__label">
                        {{ $t('component.challenge.assumptions.core-cause.why.label') }}
                    </label>
                    <div class="textarea-with-info">
                        <div class="non-functional textarea-with-info__neurons">+12 Neurons</div>
                        <NTextarea :disabled="disabled" name="assumption_why" v-model="form.fields.challenge.assumption_why" :placeholder="$t('component.challenge.assumptions.core-cause.why.placeholder')" />
                        <div class="textarea-with-info__more">
                            <span class="textarea-with-info__more-caption">
                                <NIcon as="check-circle-solid" class="text-dust-600" :class="{ 'text-nerve': form.fields.challenge.assumption_why }" left />
                                <span class="question-word">
                                    {{ $t('component.challenge.assumptions.core-cause.why.word') }}
                                </span>
                            </span>
                            <button aria-label="assumption" type="button" :class="{ 'bg-nerve': form.fields.challenge.assumption_why }" class="bg-dust-400 text-white py-1 px-3 rounded-b-md leading-tight hover:bg-nerve" @click="$emit('select', 'why')">
                                <span>{{ $t('component.challenge.assumptions.core-cause.action.deep-dive') }}</span>
                                <NIcon as="arrow-alt-circle-right-regular" right />
                            </button>
                        </div>
                    </div>
                    <p class="form-block-help-text has-margin-top has-no-margin-bottom">
                        {{ $t('component.challenge.assumptions.core-cause.why.note') }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="!implicit" class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <b>Note:</b> The point of the deep dives is to drill down to the unexpected question that is the right one to start with. If you discover that the answer is self-evident (or could be answered by an expert), congratulations, don’t ask this question here; go to Quora or Stack Overflow. But if you are looking for an unexpected idea, then launch it! We want our community to be about possibility. The reason we’re interested in the unexpected is that the skillset we are trying to hone is innovativeness. Often the greatest change the world needs comes from the unexpected and unknown.
            </p>
        </div>
   </div>
</template>