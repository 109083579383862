<script setup>
import FeatureFlag from '@/components/FeatureFlag.vue';
import SelectStaffUser from '@/components/staff/SelectUser.vue';
import { useFeatureFlag } from '@/composable/feature-flag';

import { useStorage } from '@vueuse/core';

const props = defineProps({
    challenge: {
        type: Object,
        required: true,
    },
    idea: {
        type: Object,
        required: true,
    },
});

const { enabled } = useFeatureFlag('catalyst-note-email');
const notes = useStorage(`challenge:idea:${props.idea.id}:notes`, '');

const onSuccess = (_, { notes }) => {
    props.idea.stats.catalyst_notes = notes;

    notes.value = null;
}
</script>

<template>
    <NModal v-if="enabled">
        <template #activator="{ on }">
            <slot name="activator" :on="on" />
        </template>

        <template #default="{ visible, close }">
            <div v-if="visible" class="bg-white w-[90vw] md:rounded-md md:w-md overflow-hidden">
                <div class="flex justify-between items-center mb-4 px-3 py-2 bg-secondary text-white">
                    <div class="flex items-center">
                        <NIcon as="envelope-regular" class="text-xl mb-[2px] mr-2" />
                        <h3 class="m-0">{{ $t('component.challenge.send-idea-note.title', { innovator_name: !challenge.is_closed ? 'Innovator' : `${idea.solver.first_name} ${idea.solver.last_name}` } ) }}</h3>
                    </div>

                    <button aria-label="button" type="button" @click="close">
                        <NIcon as="times-circle-regular" />
                    </button>
                </div>

                <NDataForm method="post" class="px-6 py-2 pb-4" :action="`/challenges/${challenge.slug}/catalyst/notes`" :data="{ notes: notes, idea: idea.id, staff_id: null }" @success="onSuccess">
                    <template #default="form">
                        <p v-if="idea.stats.catalyst_notes">{{ idea.stats.catalyst_notes }}</p>
                        
                        <NAlert type="danger" closable :open="form.errors.any()">
                            <span>{{ form.errors.get('*') || $t('page.dashboard.catalyst.detail.send-email.default-error-message') }}</span>
                        </NAlert>
                        <NAlert type="success" closable :open="form.success">
                            <span>{{ $t('page.dashboard.catalyst.detail.success-message') }}</span>
                        </NAlert>

                        <div v-if="idea.stats.catalyst_notes" class="flex justify-end">
                            <button aria-label="button" type="button" class="px-6 py-2 bg-dust-50 rounded-md hover:bg-dust-100" @click="close">
                                Close
                            </button>
                        </div>
                        
                        <div v-else class="flex flex-col gap-4">
                            <FeatureFlag flag="catalyst-note-email" variant="reply-to" #default="{ variant }">
                                <SelectStaffUser v-if="variant.payload" v-model="form.fields.staff_id" label="Reply-To" />
                            </FeatureFlag>
                            <NTextarea v-model="notes" label="Note" placeholder="Type your note about this idea" />
                            <p class="text-dust-700">
                                They will receive an email from you so they can  reply directly to you.
                            </p>
                            <div class="flex justify-end items-center gap-4">
                                <button aria-label="button" type="button" class="px-4 py-2 rounded-md flex hover:bg-dust-100" @click="close">
                                    Cancel
                                </button>
                                <button aria-label="button" type="submit" class="px-4 py-2 rounded-md flex bg-secondary-500 text-white hover:bg-secondary-600" :class="{ '!bg-secondary-400 cursor-not-allowed': !notes || form.busy }" :disabled="!notes || form.busy">
                                    <NLoading v-if="form.busy" class="text-white -my-1" loading />
                                    {{ form.busy ? 'Sending...' : 'Send email' }}
                                </button>
                            </div>
                        </div>
                    </template>
                </NDataForm>
            </div>
        </template>
    </NModal>
</template>