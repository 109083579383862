/** @module organizations */
export default requester => {
    return {
        /**
         * Creates an organization.
         *
         * @param {string} name The name of the organization
         * @param {string} inviteType The type of invitation structure that the organization will use. One of: open, request, manual
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(name, inviteType, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/org', {}, Object.assign({ inviteType, name }, params), headers);
        },

        /**
         * Gets an organization by its id.
         *
         * @param {string} organizationId ID of organization to get
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        get(organizationId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/org/${organizationId}`, {}, params, headers);
        },

        /**
         * Updates an organization.
         *
         * @param {string} id ID of organization to update
         * @param {Object} org Organization object to overwrite the organization with
         * @param {string} inviteType The type of invitation structure that the organization will use. One of: open, request, manual
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        update(id, org, inviteType, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/org/${id}`, { org }, Object.assign({ inviteType }, params), headers);
        },

        /**
         * Grants organization admin to a new user.
         *
         * @param {string} id ID of organization
         * @param {string} userId The ID of the user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        grantAdmin(id, userId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/org/${id}/grant-admin`, {}, Object.assign({ userId }, params), headers);
        },

        /**
         * Grants organization ownership to a new user.
         *
         * @param {string} id ID of organization
         * @param {string} userId The ID of the user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        grantOwnership(id, userId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/org/${id}/grant-ownership`, {}, Object.assign({ userId }, params), headers);
        },

        /**
         * Invites a user to join an organization given its id (if the organization has an inviteType of type manual).
         *
         * @param {string} id ID of organization
         * @param {string} userId The ID of the user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        inviteUser(id, userId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/org/${id}/invite`, {}, Object.assign({ userId }, params), headers);
        },

        /**
         * Joins an organization given its id (if the organization has an inviteType of type open).
         *
         * @param {string} id ID of organization
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        join(id, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/org/${id}/join`, {}, params, headers);
        },

        /**
         * Kicks a user from an organization.
         *
         * @param {string} id ID of organization
         * @param {string} userId The ID of the user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        kickUser(id, userId, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/org/${id}/kick`, {}, Object.assign({ userId }, params), headers);
        },
        /**
         * Joins an organization given its id (if the organization has an inviteType of type open).
         *
         * @param {string} id ID of organization
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        leave(id, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/org/${id}/leave`, {}, params, headers);
        },

        /**
         * Requests to join an organization given its id (if the organization has an inviteType of type request).
         *
         * @param {string} id ID of organization
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        requestAccess(id, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/org/${id}/request`, {}, params, headers);
        },

        /**
         * Revokes organization admin rights from a user.
         *
         * @param {string} id ID of organization
         * @param {string} userId The ID of the user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        revokeAdmin(id, userId, params = {}, headers = {}) {
            return requester.makeRequest(
                'DELETE', `/org/${id}/revoke-admin`, {}, Object.assign({ userId }, params), headers
            );
        },

        /**
         * Responds to an organization invitation request
         * (i.e. this is the endpoint that the user calls to accept or decline an invitation to join).
         *
         * @param {string} orgRelationId The id of the relevant organization relationship (i.e. the invitation to join)
         * @param {string} response Whether or not to accept membership into the organization. one of: accept, decline
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        respondToInvitation(orgRelationId, response, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/social/org-invites/${orgRelationId}/respond`, {}, Object.assign({ response }, params), headers
            );
        },

        /**
         * Responds to an organization join request (i.e. this is the endpoint that
         * the organization admin calls to accept or decline a request to join).
         *
         * @param {string} orgRelationId The id of the relevant organization relationship (i.e. the request to join)
         * @param {string} response Whether or not to accept membership into the organization. one of: accept, decline
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        respondToRequest(orgRelationId, response, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/social/org-requests/${orgRelationId}/respond`, {}, Object.assign({ response }, params), headers
            );
        },

        /**
         * Gets a list of outstanding organization requests.
         *
         * @param {string} orgId The ID of the organization
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        requests(orgId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/social/org/${orgId}/requests`, params, headers);
        }
    }
}
