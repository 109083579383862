<script>
	export default {
		name: 'NMenu',

        props: {
            modelValue: {
                type: Boolean,
                default: false
            },
            closeOnClick: {
                type: Boolean,
                default: true
            },
            closeOnContentClick: {
                type: Boolean,
                default: true
            },
            overlay: {
                type: Boolean,
                default: false
            },
            left: {
                type: Boolean,
                default: false
            },
            right: {
                type: Boolean,
                default: false
            },
            top: {
                type: Boolean,
                default: false
            },
            center: {
                type: Boolean,
                default: false
            },
            custom: {
                type: Boolean,
                default: false
            },
            floating: {
                type: Boolean,
                default: true
            },
        },

        emits: ['update:modelValue', 'open', 'close'],

		data() {
			return {
				dropdownOpen: false
			}
		},

		methods: {
			toggle(state = null) {
				this.dropdownOpen = state !== null ? state : !this.dropdownOpen;

                this.$emit('update:modelValue', this.dropdownOpen);

                if (this.dropdownOpen) {
                    this.$emit('open');
                } else {
                    this.$emit('close');
                }
			},
            open () {
                this.toggle(true);
            },
            close (close = true) {
                if(close) {
                    this.toggle(false);
                }
            },
		}
	}
</script>

<template>
	<div class="relative" @keyup.esc="close()">
		<slot name="activator" :on="{ click: () => toggle() }" :active="dropdownOpen" :open="open" :close="close">
			<button aria-label="button" @click="dropdownOpen = !dropdownOpen" class="relative z-[20] block rounded-md bg-white p-2 focus:outline-none">
				<svg class="h-5 w-5 text-dust-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
				</svg>
			</button>
		</slot>

		<div
            v-if="floating && closeOnClick && dropdownOpen"
            class="fixed inset-0 h-full w-full z-[10]"
            :class="{ 'bg-black bg-opacity-30': overlay }"
            @click="close(closeOnClick)"
        />

        <div
            v-if="dropdownOpen"
            class="w-full"
            :class="{
                'absolute z-[20]': floating,
                'transform -translate-x-[13rem] mt-2': left,
                'transform translate-x-full mt-2': right,
                'bottom-full mb-2': top,
                '-left-1/2': center,
                'py-2': !custom,
                'shadow-active bg-white rounded-md border border-dust-100 min-w-[17rem]': !custom && floating,
            }"
            @click="close(closeOnContentClick)">
            <slot :close="close" />
        </div>
	</div>
</template>
