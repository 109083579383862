<script>
import ShowsPlatform from '@/mixins/ShowsPlatform';
import Placeholder from './Placeholder.vue';
import get from 'lodash/get';
import { uniqueBy } from '@/utils/Helpers';
import { numeric, date } from '@bignerve/ui-utils';
import { STATUS } from '@/constant/olympiad';
import { useFeatureFlag } from '@/composable/feature-flag';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    mixins: [ShowsPlatform],

    components: { Placeholder },

    props: {
        length: {
            type: [Number, String],
            default: 3
        },
        modelValue: {
            type: Object,
            required: true
        },
        finished: {
            type: Boolean,
            default: false
        },
        raceType: {
            type: String,
        }
    },

    data () {
        return {
            medals: {
                1: 'gold',
                2: 'silver',
                3: 'bronze',
            }
        }
    },

    computed: {
        participants () {
            return this.modelValue && this.modelValue.result.participants || [];
        },
        olympiad () {
            return this.modelValue && this.modelValue.olympiad || {};
        },
        leaderboard () {
            return this.participants.slice(0, this.length);
        },
        currentUser () {
            if (this.$auth.authenticated && this.participants.length) {
                return this.modelValue.user_result;
            }
        },
        racePrice () {
            return this.modelValue?.result.prize || this.olympiad.prize_total;
        },
        decimal () {
            const n = numeric;
            const participants = this.leaderboard.map(({ score }) => score.total_score);
            const isDec = x => x % 1 != 0;

            for (let i = 0; i <= 3; i++) {
                const uniq = uniqueBy(participants, (a, b) =>
                    (isDec(a) || isDec(b)) && (n(a, i) === n(b, i)));
                if (uniq.length === this.leaderboard.length) {
                    return i;
                }
            }
        },
        isCurrent () {
            return this.olympiad.status === STATUS.OPEN;
        },
    },

    methods: {
        date,
        numeric,
        getRouteForLeaderboard () {
            const screen_name = get(this.account, 'user.screen_name');

            return {
                name: 'platform.olympiad.leaderboard',
                params: {
                    race: this.raceType,
                    platformSlug: this.isUserPlatform && screen_name
                        ? screen_name
                        : 'platform'
                },
                query: {
                    olympiad: this.olympiad.id
                }
            };
        }
    },


    setup () {
        const route = useRoute();
        const { variantTo } = useFeatureFlag('tournament-extra-prize');
        const variant = variantTo(() => route.params.platformSlug);

        const extraPrize = computed(() => {
            if (!variant.value) {
                return null;
            }
            return Array.isArray(variant.value.payload)
                ? variant.value.payload.join(' ')
                : variant.value.payload;
        })

        return {
            extraPrize,
        }
    },
}
</script>

<template>
    <div>
        <RouterLink :to="getRouteForLeaderboard()" >
            <h3 class="flex text-center font-narrow items-center text-black mb-4 text-xl">
                <NIcon as="stream" class="mr-2" />
                {{ $t(`widget.tournament.${isCurrent ? 'current' : 'prior'}.title`) }}
            </h3>
        </RouterLink>

        <div class="community-card__meta mb-3">
            <span v-if="extraPrize" class="community-card__meta-item flex items-center">
                <NIcon as="trophy-solid" />
                <span class="leading-none">{{ extraPrize }}</span>
            </span>
            <span :class="{ disabled: !racePrice }" class="community-card__meta-item flex gap-1 items-center">
                <NIcon as="money-bill-alt-regular" class="text-xl -mt-1" />
                <span class="leading-5">${{ racePrice }}</span>
            </span>
        </div>

        <div v-if="leaderboard.length" class="community-card-user-placement-list">
            <div v-for="({ user, score }, i) of leaderboard" :key="`past-olypiad-player-${i}`" class="flex items-center">
                <div class="flex flex-1 items-center">
                    <img v-if="finished" class="community-card-user-placement-list__rank-medal" :src="`/img/medals/${medals[score.position]}-medal-position.svg`" alt="">
                    <strong v-else class="text-black min-w-8 ml-1">{{ score.position }}.</strong>
                    <NPlayerDetails
                        :user="user"
                        :medium="!$responsive.sm"
                        :class="{ 'font-semibold bg-nerve-100 pr-4 overflow-hidden border-nerve-200 hover:border-nerve-300 border rounded-full': $auth.user.screen_name === user.handle }"
                        simple
                    />
                </div>
                <div v-if="!finished" class="px-3">
                    <strong>{{ numeric(score.total_score, decimal) }}</strong>
                </div>
            </div>
            <div v-if="currentUser && currentUser.score.total_score && currentUser.score.position > length" class="community-card-user-placement-list__row flex items-center">
                <span class="community-card-user-placement-list__own-rank rounded-md">
                    {{ currentUser.score.position }}
                </span>
                <NPlayerDetails
                    :user="currentUser.user"
                    :medium="!$responsive.sm"
                    class="flex-1 font-semibold"
                    simple
                />
                <div v-if="!finished" class="px-3">
                    <strong>{{ numeric(currentUser.score.total_score, decimal) }}</strong>
                </div>
            </div>
        </div>
        <div v-else-if="!leaderboard.length" class="py-5 is-color-gray text-center">
            {{ $t(`widget.tournament.${isCurrent ? 'current' : 'prior'}.no-participants`) }}
        </div>
        <div class="flex items-center justify-center mt-3">
            <RouterLink
                :to="getRouteForLeaderboard()"
                class="text-gray-800 bg-gray-200 flex py-2 min-w-60 justify-center rounded-md hover:bg-gray-300 hover:!text-gray-900 border border-gray-300"
            >
                <NIcon as="race-flag-double" class="text-xl" left />
                {{ $t(`widget.tournament.${isCurrent ? 'current' : 'prior'}.action.view`) }}
            </RouterLink>
        </div>
        <span class="tertiary-heading-section__counter">
            <strong class="mr-2">
                {{ $t(`widget.tournament.status.${finished ? 'closed' : 'open'}`) }}
            </strong>
            {{ date(olympiad.start_time, 'MMMM D') }} to {{ date(olympiad.end_time, 'MMM D') }}
        </span>
    </div>
</template>
