<script setup>
import { computed } from 'vue';

import TextBlock from './block-types/text/Display.vue';
import ImageBlock from './block-types/image/Display.vue';
import VideoBlock from './block-types/video/Display.vue';
import DividerBlock from './block-types/divider/Divider.vue';

const BLOCK_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  HEADING: 'heading',
  EMBED: 'embed',
  QUOTE: 'quote',
  DIVIDER: 'divider',
}

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      title: 'Bio',
      image_cover: null,
      blocks: [
        { order: 1, block_type: 'text', content: null },
      ],
    }),
  },
});

const resolveBlock = (type) => {
  switch (type) {
    case BLOCK_TYPE.TEXT:
      return TextBlock;
    case BLOCK_TYPE.IMAGE:
      return ImageBlock;
    case BLOCK_TYPE.VIDEO:
      return VideoBlock;
    case BLOCK_TYPE.DIVIDER:
      return DividerBlock;
    default:
      return NoBlock;
  }
}

const bloks = computed(() => (props.modelValue.blocks || []).filter(
    (block) => block.block_type && block.content
));
</script>

<template>
    <div class="relative">
            <!-- line -->
        <div v-for="(block, i) of bloks" :key="i" class="w-full py-2">
            
            <component
                :modelValue="block.content"
                :metadata="block.metadata"

                :is="resolveBlock(block.block_type)"
            />
        
        </div>
    </div>
</template>