export default requester => {
    return {
        /**
         * Get resources
         */
        resource (type, params = {}) {
            return requester.get(`/stats/resources/${type}`, {
                params,
                paramsSerializer: params => {
                    return Object.keys(params).map(key => {
                        const val = params[key];

                        if (val === undefined) {
                            return null;
                        }

                        if (val === null) {
                            return val;
                        }

                        if (Array.isArray(val)) {
                            const result = []
                            val.forEach(val2 => {
                                if (val2 === undefined) {
                                    return null
                                }

                                if (val2 === null) {
                                    result.push(key)
                                } else {
                                    result.push(`${key}=${val2}`)
                                }
                            });
                            return result.join('&');
                        }

                        return `${key}=${val}`;
                    }).filter(p => p).join('&')
                }
            });
        },

        /**
         * Get stats for a context
         * 
         * @param {string} type
         * @param {string} id
         * @param {object} params
         **/
        context (type, id, params) {
            return requester.get(`/stats/context/${type}/${id}/stats`, { params });
        },

        /**
         * Get stats details
         * 
         * @param {string} stats_id
         * @param {object} params
         **/
        details (stats_id, params) {
            return requester.get(`/stats/${stats_id}/details`, { params });
        }
    };
}
