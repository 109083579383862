<script setup>
import get from 'lodash/get';
import bus from '@/modules/bus';
import ShareNetwork from '@/components/ShareNetwork.vue';
import NLazyImage from '@/components/NLazyImage.vue';
import { Loading as NNerveLoading } from '@bignerve/ui-core';
import { date } from '@bignerve/ui-utils';
import { api } from '@/modules/services';
import { shallowRef, ref } from 'vue';

const emit = defineEmits(['done']);
const loading = ref(false);
const sharings = shallowRef([]);

const fetch = async () => {
    try {
        loading.value = true;
        const { data: response } = await api.sharing.snoozed()

        response.data.forEach(item => item.removing = ref(false));

        sharings.value = response.data;
    } catch (err) {
        // err
        console.log(err)
    } finally {
        loading.value = false;
    }
}

const removeSnoozedSharing = async (sharing) => {
    try {
        sharing.removing.value = true;

        await api.sharing.removeSnoozed(sharing._id);
    } catch (e) {
        sharing.removing.value = false;
    } finally {
        fetch();
    }
}

const beforeShare = (next, provider, sharing) => {
    const checks = [
        ['twitter'].includes(provider),
        get(sharing, 'payload.metadata.credentials', []).includes('twitter'),
    ]

    if (checks.every(v => v)) {
        bus('sharer:share', {
            provider, payload: sharing.payload,
            onShared: () => {
                removeSnoozedSharing(sharing);

                emit('done')
            },
            onClosed: () => emit('done'),
        });

        return;
    }

    next();
}

const shared = (sharing) => {
    removeSnoozedSharing(sharing);
}

fetch();
</script>

<script>
export default {
    name: 'SnoozedSharings',
}
</script>

<template>
    <div class="border border-dust-300 rounded-md sheet shadow mb-6">
        <div class="px-3 py-2 border-b bg-dust-200 flex justify-between flex-wrap gap-4">
            <h3 class="m-0 flex items-center text-lg space-x-2 whitespace-nowrap text-secondary-500">
                <NIcon as="twitter"/>
                <span>{{ $t('page.snoozed-sharings.title') }}</span>
            </h3>
        </div>
        <div class="bg-white p-4">
            <p class="text-dust-800">
                {{ $t('page.snoozed-sharings.description') }}
            </p>

            <div v-if="sharings.length" class="divide divide-y">
                <div v-for="(sharing, i) of sharings" :key="i" class="flex flex-col md:flex-row gap-4 py-4">
                    <div class="min-w-[20rem]">
                        <NLazyImage
                            :src="sharing.payload.image"
                            :title="sharing.payload.title"
                            :alt="sharing.payload.title"
                            class="!aspect-4/8 w-full border rounded-md shadow overflow-hidden"
                        />
                    </div>
                    <div class="text-dust-800">
                        <p>{{ sharing.payload.text }}</p>
                        <div v-if="sharing.payload.metadata.survey_mode" class="flex items-center gap-2">
                            <input type="checkbox" value="true" checked="checked" readonly="readonly" class="!w-4 !h-4" onclick="return false;">
                            <span class="leading-0 text-sm -mb-1">Survey mode enabled</span>
                        </div>
                    </div>
                    <div class="flex flex-col items-end gap-3">
                        <ShareNetwork
                            network="twitter"
                            :disabled="!sharing.payload.metadata.url"
                            :url="sharing.payload.metadata.url || ''"
                            :title="sharing.payload.title"
                            :description="sharing.payload.description"
                            :quote="sharing.payload.quote"
                            :before-share="(next, provider) => beforeShare(next, provider, sharing)"
                            class="px-3 py-1 flex items-center text-secondary hover:bg-dust-100 rounded-md font-bold whitespace-nowrap"
                            tag="button"
                            @close="shared(sharing)"
                        >
                            <NIcon as="twitter" left/> Tweet Now
                        </ShareNetwork>

                        <span class="text-sm text-dust-600 px-3">
                            {{ date(sharing.created_at, 'MMM D, YYYY') }}
                        </span>

                        <button aria-label="button" type="button" class="w-10 h-10 flex items-center justify-center rounded-full" :class="{ 'hover:bg-dust-100': !sharing.removing.value }" @click="removeSnoozedSharing(sharing)">
                            <NLoading v-if="sharing.removing.value" loading />
                            <NIcon v-else as="close-circle" class="text-dust-800 text-2xl"/>
                        </button>
                    </div>
                </div>
            </div>

            <div v-else-if="loading" class="h-80 flex items-center">
                <NNerveLoading />
            </div>

            <div v-else class="flex items-center justify-center py-20">
                <p class="text-dust-500">You have no scheduled shares yet!</p>
            </div>
        </div>
    </div>
</template>