<script>
import { currency } from '@bignerve/ui-utils';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';
import ChallengeTitle from '@/components/challenge/Title.vue';
import ChallengeImage from '@/components/challenge/Image.vue';

export default {
    inject: ['form', 'context'],

    mixins: [useChallengeDetails()],

    components: { ChallengeTitle, ChallengeImage },

    props: {
        challenge: {
            type: Object,
            required: true,
        },
    },

    computed: {
        routeToDetail () {
            return this.challenge.slug ? {
                name: 'challenges.show',
                params: { challenge: this.challenge.slug },
            } : null;
        },
    },

    methods: {
        currency,
        shareOn (state) {
            this.$bus('challenge:creation-flow:preview:share-on', state);
        }
    }
}
</script>

<template>
    <div >
        <article class="challenge-card is-preview">
            <ChallengeImage
                :challenge="challenge"
                :category="context.categorySelected && context.categorySelected.name"
                :credits="true"
                :to="routeToDetail"
                :show-loading="true"
                :prefetch="true"
                class="challenge-card__image-wrapper"
            />
            
            <div class="challenge-card__body">
                <NPlayerDetails :user="$auth.user" class="mb-2" />
                
                <ChallengeTitle
                    :challenge="challenge"
                    :to="routeToDetail"
                    class="text-xl font-semibold leading-6 text-dust-800"
                />
                
                <div v-if="challenge.co_author" v-tooltip="'Co-Author'" class="flex items-center space-x-2">
                    <NIcon as="qq" class="text-innovator text-2xl" />
                    <NPlayerDetails :user="challenge.co_author" :clamp="false" />
                </div>
                    
                <NTimeRemaining
                    v-if="form.fields.challenge.duration_end"
                    :datetime="form.fields.challenge.duration_end"
                    style="opacity: 0.7"
                />
            </div>

            <div v-if="form.fields.challenge.innovation_scale && ! form.$parent.isCaption && ! form.$parent.isQQ" class="p-3 flex justify-center">
                <img class="h-7" :src="`/img/innovative-level/${form.fields.challenge.innovation_scale}.png`" :alt="form.fields.challenge.innovation_scale">
            </div>
            <div  class="ml-3 mr-3 mt-2 mb-2 flex flex-wrap items-center justify-between gap-2">
                <a href="#" class="challenge-card__footer-item whitespace-nowrap">
                    <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                    Ideas (0)
                </a>
                <span v-if="form.fields.prize?.prize_money" :class="{ disabled: !currency(form.fields.prize?.prize_money || 0) }" class="challenge-card__footer-item whitespace-nowrap">
                    <span aria-hidden="true" class="icon icon-money-bill-alt-regular"></span>
                    ${{ currency(form.fields.prize?.prize_money || 0) }}
                </span>
                <span v-if="form.fields.prize?.prize_type" class="challenge-card__footer-item whitespace-nowrap">
                    <span aria-hidden="true" class="icon icon-trophy-solid"></span>
                    {{ $t(`challenges.prize-type.${form.fields.prize.prize_type}`) }}
                </span>
                <div v-if="form.fields.challenge.slug" class="relative challenge-card__footer-item pulsating-circle is-touchable whitespace-nowrap"
                    data-info="This is the Share button to help you promote your challenges" @mouseover="shareOn(true)" @mouseleave="shareOn(false)">
                    <span aria-hidden="true" class="icon icon-share-square-solid"></span>
                </div>
            </div>
        </article>
    </div>
</template>
