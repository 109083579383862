<script>
import get from 'lodash/get';

export default {
    props: {
        challenge: {
            type: Object,
            required: true,
        },
        challengeable: {
            type: Boolean,
            default: false,
        },
    },
    
    data() {
        return {
            isExpanded: false,
        };
    },

    computed: {
        what() {
            return get(this.challenge, 'deep_dives.what', null);
        },
        hasFeasibility() {
            if (! this.what) {
                return false;
            }
            
            return ['core_cause_1st', 'core_cause_2nd', 'core_cause_3rd'].some(cause => {
                return this.what[cause].string || this.what[cause].supporting;
            });
        },
        hasImpact() {
            return this.what && this.what.impact_steps.some(impact => !! impact.text);
        },
        hasContent() {
            return this.hasFeasibility && this.hasImpact;
        }
    },

    methods: {
        toggle() {
            this.isExpanded = ! this.isExpanded;
        }
    },
    
    mounted() {
        this.isExpanded = this.hasContent;
    },
}
</script>

<template>
    <div class="challenge-deep-dive-card mt-4 border-2 border-director-100">
        <div class="challenge-deep-dive-card__content">
            <h2 class="challenge-deep-dive-card__title">What is the core cause(s) of the problem?</h2>
            <p class="challenge-deep-dive-card__description m-0">
                {{ challenge.assumption_what }}
            </p>
            <div v-if="what" class="challenge-deep-dive-card__collapsed mt-4 mb-3" v-show="isExpanded">
                <div class="form-groups">
                    <div v-if="what.core_cause_1st.string || what.core_cause_1st.supporting" class="form-groups__numbered-section">
                        <div class="form-group">
                            <h3 class="form-group__title is-blue">{{ what.core_cause_1st.string }}</h3>
                            <p class="mb-2">{{ what.core_cause_1st.supporting }}</p>
                        </div>
                    </div>
                    <div v-if="what.core_cause_2nd.string || what.core_cause_2nd.supporting" class="form-groups__numbered-section">
                        <div class="form-group">
                            <h3 class="form-group__title is-blue">{{ what.core_cause_2nd.string }}</h3>
                            <p class="mb-2">{{ what.core_cause_2nd.supporting }}</p>
                        </div>
                    </div>
                    <div v-if="what.core_cause_3rd.string || what.core_cause_3rd.supporting" class="form-groups__numbered-section">
                        <div class="form-group">
                            <h3 class="form-group__title is-blue">{{ what.core_cause_3rd.string }}</h3>
                            <p class="mb-2">{{ what.core_cause_3rd.supporting }}</p>
                        </div>
                    </div>
                </div>
                <h3 v-if="hasFeasibility" class="form-group__title is-blue mt-4">Feasibility</h3>
                <div v-if="what.feasibility_choice === 'first' && what.core_cause_1st.string" class="feasibility-bit">
                    <div class="feasibility-bit__number">1</div>
                    <div class="feasibility-bit__content">
                        {{ what.core_cause_1st.string }}
                    </div>
                </div>
                <div v-else-if="what.feasibility_choice === 'second' && what.core_cause_2nd.string" class="feasibility-bit">
                    <div class="feasibility-bit__number">2</div>
                    <div class="feasibility-bit__content">
                        {{ what.core_cause_2nd.string }}
                    </div>
                </div>
                <div v-else-if="what.feasibility_choice === 'third' && what.core_cause_3rd.string" class="feasibility-bit">
                    <div class="feasibility-bit__number">3</div>
                    <div class="feasibility-bit__content">
                        {{ what.core_cause_3rd.string }}
                    </div>
                </div>
                <h3 v-if="hasImpact" class="form-group__title is-blue mt-4">Impact</h3>
                <div v-for="(impact, index) in what.impact_steps.filter(i => !! i.text)" :key="index" class="flex items-center mr-4 ml-4">
                    <span class="fake-input-field d-block width-[25rem] mr-3">{{ impact.text }}</span>
                    <img v-if="impact.selected" src="/img/monkey-icon.svg" alt="" class="ml-2" style="margin-top: -3px;">
                    <span v-else class="ml-2" style="width: 30px;"></span>
                </div>
            </div>
            <button aria-label="button" v-if="hasContent" :class="{ 'is-disabled': !hasContent }" type="button" class="challenge-deep-dive-card__toggle flex items-center" @click.prevent="toggle" :disabled="! hasContent">
                <span>{{ isExpanded ? 'Hide Full Details' : 'View Full Details' }}</span>
                <NIcon :as="isExpanded ? 'caret-square-up-solid' : 'caret-square-down-solid'" class="-mb-1" right />
            </button>
        </div>
        <div class="challenge-deep-dive-card__footer flex flex-wrap items-center justify-between">
            <span
                class="challenge-deep-dive-card__question"
                :class="challenge.assumption_what || hasContent ? 'is-active' : 'is-disabled'"
            >
                <span aria-hidden="true" class="icon icon-check-circle-solid"></span>
                <b class="mr-1">What</b>
                <span v-if="hasContent">with Deep Dive</span>
            </span>
            <div v-if="challengeable" class="flex flex-wrap items-center">
                <button aria-label="button" :class="{ 'is-disabled': !hasContent }" type="button" class="btn is-small mr-2" :disabled="! hasContent">
                    <NIcon as="eye" left />
                    Challenge this Assumption
                </button>
                <span :class="{ 'is-color-default': hasContent, 'is-color-gray': ! hasContent }">(0)</span>
            </div>
        </div>
    </div>
</template>