<script setup>
import FlexTable from '@/components/FlexTable.vue';
import PlayerDetails from '@/components/game/players/Details.vue';
import LoadingNerve from '@/components/NLoadingNerve.vue';
import { useAuth } from '@/composable/auth';
import { api } from '@/modules/services';
import { ref, watch } from 'vue';

const { auth } = useAuth();
const members = ref([]);
const loading = ref(false);

const fethUsersStaff = async () => {
    try {
        loading.value = true;

        const { data: response } = await api.staff.users(auth.user.id);

        members.value = response.data;
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}

watch(() => auth.user.id, () => {
    if (auth.user.id) {
        fethUsersStaff();
    }
}, { immediate: true });
</script>

<template>
    <div>
        <h2>Manage your Staff Admin(s)</h2>
        <p>You can select user(s) from the community to help manage your platform or organization. You can revoke their access any time. If they created a challenge(s) for your platform, it will remain part of your Olympiad; you own these challenges they create under your org.</p>

        <FlexTable
            :header="[
                { name: 'user', text: 'Member', grow: 3 },
                { name: 'last_login', text: 'Last Login', align: 'center', grow: 2, hide: true },
                { name: 'total_challenges', text: 'Total Challenges', align: 'center', grow: 2, hide: true },
                { name: 'Actions', text: '', grow: 1, hide: true },
            ]"
            :loading="loading"
            :items="members"
            active-class="bg-blue-100 hover:bg-blue-100 rounded-md"
            header-class="border-b-2 pb-3 px-2"
            class="bg-white border rounded-lg px-2 py-4"
        >
            <template #col:user="{ item }">
                <PlayerDetails :user="item" />
            </template>

            <template #col:last_login>
                <span>-</span>
            </template>

            <template #col:total_challenges>
                <span>-</span>
            </template>

            <template #no-results>
                <div class="py-8 w-full flex items-center justify-center">
                    <LoadingNerve v-if="loading" />
                    <slot v-else name="empty">No members yet!</slot>
                </div>
            </template>
        </FlexTable>
    </div>
</template>