export const CONTEXT = {
    ITERATION: 'iteration',
    MENTOR_COMMENT: 'mentor_comment',
    CHALLENGE: 'challenge',
    TOPIC: 'topic',
}

export const MODERATION_TYPE = {
    HIDDEN: 'moderated_hidden',
    REMOVED: 'moderated_removed',
    REJECTED: 'moderated_rejected',
}