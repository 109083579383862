import { isRef, computed } from 'vue';
import { toRef, reactify } from '@vueuse/core';
import { get, has } from 'lodash';
import { useStats as useStatsQuery } from '@/queries/stats';

export const useStats = (context, key, options = {}) => {
    const { immediate = false } = options;
    const target = isRef(key) ? key : toRef(key);
    const ctx = isRef(context) ? context : toRef(context);

    const { data: stats, isLoading: loading, refetch } = useStatsQuery(
        ctx, target, {
            immediate,
            enable: computed(() => !!target.value),
            refetchInterval: 1000 * 60, // 1 minutes
        },
    );

    const hasStats = computed(() => !!stats.value)
    const statsFrom = (k, fallback = 0) => get(stats.value, k, fallback)
    const hasStat = (k) => has(stats.value, k)

    const stat = reactify(statsFrom);

    return {
        fetch: refetch,
        statsFrom,
        hasStats,
        hasStat,
        loading,
        stats,
        stat,
    }
}
