<script>
    export default {
        props: {
            rating: {
                type: Object,
                required: true
            },
            hideAvg: {
                type: Boolean,
                default: () => false
            }
        },

        computed: {
            level() {
                return (this.rating.rating_user || 0).toFixed(1);
            },
            avg() {
                return (this.rating.rating_community || 0).toFixed(1);
            },
            lastPointer () {
                return {
                    transform: `rotate(${this.level * 180 / 10}deg)`
                }
            },
            avgPointer () {
                return {
                    transform: `rotate(${this.avg * 180 / 10}deg)`
                }
            }
        }
    }
</script>

<template>
    <div class="rating-meter-wrapper" :data-last-point="level" :data-avg-point="!hideAvg ? avg : 0">
        <div class="rating-meter__average-point text-lg font-semibold" v-if="avg > 0 && !hideAvg">{{ avg }}</div>
        <span v-else class="text-xl font-semibold absolute top-0 right-0 mr-1 -mt-4 text-white">?</span>
        <svg width="100%" height="100%" viewBox="0 0 547 274" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" class="rating-meter">
            <path d="M104.234,273.38l-104.234,0c0,-150.883 122.497,-273.38 273.38,-273.38c150.882,0 273.38,122.497 273.38,273.38l-104.234,0c0,-93.354 -75.792,-169.146 -169.146,-169.146c-93.354,0 -169.145,75.791 -169.146,169.146Z" style="fill:#bbb;"/>
            <path :style="lastPointer" d="M442.526,273.38l104.234,0c0,150.883 -122.497,273.38 -273.38,273.38c-150.882,0 -273.38,-122.497 -273.38,-273.38l104.234,0c0,93.354 75.792,169.146 169.146,169.146c93.354,0 169.145,-75.791 169.146,-169.146Z" style="fill:#f5a623;" class="rating-meter__my-last-rating"/>
            <clipPath id="_clip1">
                <path d="M104.234,273.38l-104.234,0c0,-150.883 122.497,-273.38 273.38,-273.38c150.882,0 273.38,122.497 273.38,273.38l-104.234,0c0,-93.354 -75.792,-169.146 -169.146,-169.146c-93.354,0 -169.145,75.791 -169.146,169.146Z"/>
            </clipPath>
            <g v-if="!hideAvg" clip-path="url(#_clip1)">
                <rect :style="avgPointer" x="-12.701" y="273.546" width="286.081" height="10" style="fill:#fff;" class="rating-meter__average-rating"/>
            </g>
        </svg>
        <div class="rating-meter__point">{{ level > 0 ? level : '-' }}</div>
    </div>
</template>
