<script setup>
import { useHead } from "@vueuse/head";

useHead({
	title: "Community for Maine’s clever",
});
</script>

<template>
	<div class="info-text landing-page w-full my-10">
		<div class="text-box">
			<h2 id="youve-found-maine-nerve-a-new-community-for-maines-clever-imaginative-thinkers" tabindex="-1">
				You’ve found <a href="https://bignerve.com" target="_blank" rel="noopener">MaineNerve</a>, a new community for Maine’s clever,
				imaginative thinkers
			</h2>
			<h3 id="if-you-could-get-the-most-innovative-people-in-maine-in-one-place-what-would-you-do-with-them" tabindex="-1">
				<em>If you could get the most innovative people in Maine in one place, what would you do with them?</em>
			</h3>
			<p>
				We’re building a think tank for Maine, made up of <em>idea people</em> who are clever, witty, imaginative and insightful, who love
				exploring and solving challenges across all spheres of life. Innovation does not belong to just entrepreneurs, so this unique social
				network will support a new profession we call being an <em><a href="#">innervator</a></em
				>, a professional problem explorer.
			</p>
			<p>
				Our community is sharpening their thinking skills in hundreds of ongoing <strong>idea challenges</strong> — some entertaining, some
				serious, some with major impact, some very local and personal. By practicing in these idea games (asking insightful questions, testing
				your ideas, and mentoring each other), we can all get better at creative thinking, and build a personal reputation for innovativeness.
				By doing this, we can earn recognition and possibly patron funding to explore problems and launch new projects and services.
			</p>
			<p>We believe Maine, and the world, needs a think tank of people like you. Join us.</p>
			<h3 id="fall-2021-our-ongoing-idea-tournaments-continue" tabindex="-1">Fall 2021: our ongoing Idea Tournaments continue!</h3>
			<p>
				Join us as we beta test the platform. Go to <a href="https://BigNerve.com" target="_blank" rel="noopener">BigNerve.com</a> to start
				browsing challenge questions.
			</p>
			<p>
				You can also head over to our
				<a href="https://www.facebook.com/pg/mainenerve" target="_blank" rel="noopener">official Facebook page</a>
			</p>
		</div>
	</div>
</template>
