<script setup>
import TastingOlympiads from '@/components/home/TastingOlympiads.vue';
import ListOlympiads from '@/components/home/ListOlympiads.vue';
import toUpper from 'lodash/toUpper'
import Filters from '@/components/filters/Filters.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import HeaderLinks from "@/layouts/partials/HeaderLinks.vue";
import { usePlatform } from '@/composable/platform';
import { useRouteQuery } from '@vueuse/router';

const { contextPlatform, isSubplatform } = usePlatform();
const filter = useRouteQuery('filter', 'all')

const filters = [
    { key: 'trending', title: 'Trending', show: true },
    { key: 'money', title: 'Most Money', show: true },
    { key: 'players', title: 'Most players', show: true },
    { key: 'challenges', title: 'Most challenges', show: true },
    { key: 'most_recent', title: 'Most recent', show: true },
    { key: 'closing_soon', title: 'Closing soon', show: true },
    { key: 'closed', title: 'Closed', show: true },
    { key: 'all', title: 'All', show: true },
].filter(f => f.show);
</script>

<template>
    <div class="relative">
        <HeaderLinks class="mb-4">
            <Filters v-model="filter">
                <FilterItem v-for="f in filters" :key="f.key"
                    :label="f.title"
                    :id="f.key"
                />
            </Filters>
        </HeaderLinks>

        <TastingOlympiads
            v-if="['all'].includes(filter)"
            :platform-slug="isSubplatform ? contextPlatform.slug : null"
            title="OPEN TOURNAMENTS: TRENDING"
            filter="trending"
        />

        <TastingOlympiads
            v-if="['all'].includes(filter)"
            :platform-slug="isSubplatform ? contextPlatform.slug : null"
            title="OPEN TOURNAMENTS: MOST RECENT"
            filter="most_recent"
        />

        <TastingOlympiads
            v-if="['all'].includes(filter)"
            :platform-slug="isSubplatform ? contextPlatform.slug : null"
            :q="{ status: 'closed' }"
            :route="{ name: 'home.olympiads', params: $route.params, query: { filter: 'closed' }, hash: '#heading' }"
            title="CLOSED TOURNAMENTS: VIEW WINNERS!"
            filter="all"
        />

        <ListOlympiads
            v-if="!['all'].includes(filter)"
            :platform-slug="isSubplatform ? contextPlatform.slug : null"
            :title="`TOURNAMENTS: ${toUpper(filter)}`"
            :filter="filter === 'closed' ? 'most_recent' : filter"
            :status="filter === 'closed' ? 'closed' : 'open'"
        />
    </div>
</template>
