import { computed, toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { api } from '@/modules/services';

export const useInvitation = (platformSlug, sourceType, sourceId, recipientId) => {
    return useQuery({
        queryKey: ['invitations', platformSlug, sourceType, sourceId, recipientId],
        queryFn: async () => {
            const { data: response } = await api.invitation.find(
                toValue(platformSlug),
                toValue(sourceType),
                toValue(sourceId),
                toValue(recipientId),
            );

            return response.data;
        },
        retry: 0,
    });
}
