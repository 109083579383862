<script>
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { flatten } from '@/utils/Helpers';

export default {
    props: {
        challenge: {
            type: Object,
            required: true,
        },
        challengeable: {
            type: Boolean,
            default: false,
        },
    },
    
    data() {
        return {
            isExpanded: true,
        };
    },

    computed: {
        who() {
            return get(this.challenge, 'deep_dives.who', null);
        },
        hasContent() {
            if (! this.who) {
                return false;
            }
            
            return Object.keys(flatten(this.who))
                .some(key => !! this.who[key]);
        }
    },

    methods: {
        capitalize,
        toggle() {
            this.isExpanded = ! this.isExpanded;
        }
    },

    mounted() {
        this.isExpanded = this.hasContent;
    }
}
</script>

<template>
    <div class="challenge-deep-dive-card border-2 border-director-100">
        <div class="challenge-deep-dive-card__content">
            <h2 class="challenge-deep-dive-card__title">Who is this challenge for?</h2>
            <p class="challenge-deep-dive-card__description m-0">
                {{ challenge.assumption_who }}
            </p>
            <div v-if="who" class="challenge-deep-dive-card__collapsed mt-4 mb-3" v-show="isExpanded">
                <h3 class="form-group__title is-blue">Demographics – Who is the idea or solution for?</h3>
                <div v-if="who.demograph_age.to || who.demograph_age.from" class="flex flex-wrap items-center mb-3">
                    <b class="mr-2">Age</b>
                    <span v-if="who.demograph_age.from" class="fake-input-field mr-2">{{ who.demograph_age.from }}</span>
                    <span v-if="who.demograph_age.to" class="mr-2">to</span>
                    <span v-if="who.demograph_age.to" class="fake-input-field">{{ who.demograph_age.to }}</span>
                </div>
                <div v-if="who.demograph_gender" class="flex flex-wrap items-center mb-3">
                    <b class="mr-2">Gender</b>
                    <span v-for="(gender, index) in who.demograph_gender.filter(v => v)" :key="index" class="fake-input-field mr-2">
                        {{ capitalize(gender) }}
                    </span>
                </div>
                <div v-if="who.occupation.length > 0" class="flex flex-wrap items-center mb-3">
                    <b class="mr-1">Occupation</b>
                    <span v-for="(o, index) in who.occupation" :key="index" class="fake-input-field mr-2">
                        {{ o }}
                    </span>
                </div>
                <div v-if="who.location.length > 0" class="flex flex-wrap items-center mb-3">
                    <b class="mr-1">Location</b><span class="mr-2">(geography: country, state/region, city)</span>
                    <span v-for="(location, index) in who.location" :key="index" class="fake-input-field mr-2">
                        {{ location }}
                    </span>
                </div>
                <div v-if="who.media_consumed" class="flex flex-wrap items-center mb-3">
                    <b class="mr-1">Media Consumed</b> <span class="mr-2">(magazines, TV shows, social media outlets)</span>
                    <span class="fake-input-field">{{ who.media_consumed }}</span>
                </div>
                <div v-if="who.who_interests_hobbies.length > 0" class="mb-3">
                    <b class="mr-2">Creative Interests/Hobbies</b>
                    <span v-for="(hobby, index) in who.who_interests_hobbies" :key="index" class="fake-input-field mr-2">
                        {{ hobby }}
                    </span>
                </div>
                <h3 v-if="who.psychographics.length > 0" class="form-group__title is-blue">Psychographics</h3>
                <div v-if="who.psychographics.length > 0" class="mb-3">
                    <div class="temperament-bit is-highlighted">
                        Temperaments selected: <span>{{ who.psychographics.join('') }}</span>
                    </div>
                </div>
                <div v-if="who.psychographics.length > 0" class="mb-3">
                    <div v-for="p in who.psychographics" :key="p">
                        <div class="temperament-bit">
                            <b>{{ p }}</b> - {{ $t(`challenges.psychographics.${p}`) }}
                        </div>
                    </div>
                </div>
                <h3 v-if="who.jobs_to_be_done" class="form-group__title is-blue mt-4">Job To Be Done Theory</h3>
                <p v-if="who.jobs_to_be_done" class="fake-input-field">{{ who.jobs_to_be_done }}</p>
            </div>
            <button aria-label="button" v-if="hasContent" :class="{ 'is-disabled': !hasContent }" type="button" class="challenge-deep-dive-card__toggle flex items-center" @click.prevent="toggle" :disabled="! hasContent">
                <span>{{ isExpanded ? 'Hide Full Details' : 'View Full Details' }}</span>
                <NIcon :as="isExpanded ? 'caret-square-up-solid' : 'caret-square-down-solid'" class="-mb-1" right />
            </button>
        </div>
        <div class="challenge-deep-dive-card__footer flex flex-wrap items-center justify-between" >
            <span
                class="challenge-deep-dive-card__question"
                :class="challenge.assumption_who || hasContent ? 'is-active' : 'is-disabled'"
            >
                <span aria-hidden="true" class="icon icon-check-circle-solid"></span>
                <b class="mr-1">Who</b>
                <span v-if="hasContent">with Deep Dive</span>
            </span>
            <div v-if="challengeable" class="flex flex-wrap items-center">
                <button aria-label="button" type="button" class="btn is-small mr-2" :class="{ 'is-disabled': !hasContent }" :disabled="! hasContent">
                    <NIcon as="eye" left />
                    Challenge this Assumption
                </button>
                <span :class="{ 'is-color-default': hasContent, 'is-color-gray': ! hasContent }">(0)</span>
            </div>
        </div>
    </div>
</template>