<script setup>
import Quill from 'quill';
import MarkdownShortcuts from 'quill-markdown-shortcuts';
import { toHTML, toMarkdown } from '@/mixins/Markdown';
import { computed, ref, watch } from 'vue';
import { tryOnMounted, tryOnScopeDispose } from '@vueuse/core';
import { OnClickOutside } from '@vueuse/components';

if (!Quill.imports['modules/markdownShortcuts']) {
    Quill.register('modules/markdownShortcuts', MarkdownShortcuts)
}

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: 'Just start typing! This is a place to describe about you.'
    },
    autofocus: {
        type: Boolean,
        default: false
    },
    tabindex: {
        type: [String, Number],
    },
    required: {
        type: Boolean,
        default: false
    },
    invalid: {
        type: Boolean,
        default: false
    },
    id: {
        type: String,
        default: ''
    },
})

const emit = defineEmits(['update:modelValue', 'blur', 'focus', 'change'])

const config = {
    tabIndex: props.tabindex,
    modules: {
        toolbar: [
            ['bold', 'italic', 'strike', 'link'],
            [{ header: [2, 3, 4, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ],
        markdownShortcuts: {}
    },
    placeholder: props.placeholder,
    theme: 'snow',
    newlineAndIndent: false,
}
const editorEl = ref(null);
const editor = ref(null);

const content = computed({
    get() {
        const text = toHTML(props.modelValue);

        return text.endsWith('\n') ? text.slice(0, -1) : text
    },
    set(value) {
        emit('update:modelValue', toMarkdown(value))
    }
})

const write = (value) => {
    if (editor.value) {
        editor.value.root.innerHTML = value
    }
}

watch(() => props.disabled, (n) => {
    if (editor.value) {
        editor.value.enable(!n)
    }
}, { immediate: true })

watch(() => props.id, () => {
    write(content.value)
})

const reset = () => {
    editor && editor.setText('')
}

const clearErrors = () => { }

const setEditorTabIndex = (editor, tabIndex) => {
    if (editor.editor && editor.editor.scroll && editor.editor.scroll.domNode) {
        editor.editor.scroll.domNode.tabIndex = tabIndex
    }
}

const update = () => {
    const html = editor.value.root.innerHTML

    content.value = html
    emit('change', { html, editor: editor.value })
    clearErrors()
}

const toolbar = computed(() => {
    const container = editor.value.container;

    return container.parentNode.querySelector('.ql-toolbar');
})

const init = () => {
    if (!editorEl.value) {
        console.warn('[block:init]: Editor ref is not defined yet.');

        return;
    }

    editor.value = new Quill(editorEl.value, config)

    if (config.tabIndex !== undefined) {
        setEditorTabIndex(editor.value, config.tabIndex)
    }

    // Set editor content
    if (content.value) {
        editor.value.root.innerHTML = content.value
    }

    if (editor.value) {
        editor.value.enable(!props.disabled)
    }

    if (editor.value && props.autofocus && !props.disabled) {
        editor.value.root.focus()
    }

    // Mark model as touched if editor lost focus
    editor.value.on('selection-change', (range) => {
        if (range) {
            toolbar.value.style.display = 'block'

            emit('focus', editor.value)
        }
    });

    editor.value.on('text-change', update)
}

tryOnMounted(init)

tryOnScopeDispose(() => {
    if (editor.value) {
        editor.value.off('selection-change')
        editor.value.off('text-change')
        editor.value.disable()
        editor.value = null
    }
})

const onClickOutside = () => {
    toolbar.value.style.display = 'none';
}

defineExpose({
    editor,
    reset,
    clearErrors,
    init,
    update,
    content,
    editorEl,
    config,
    props,
    emit,
})
</script>

<template>
    <OnClickOutside @trigger="onClickOutside" class="block-editor block-editor--text">
        <div ref="editorEl"></div>
    </OnClickOutside>
</template>

<style lang="scss">
.block-editor.block-editor--text {
    position: relative;

    .ql-snow {
        &.ql-container {
            border: none;
            font-size: 16px;
            font-family: PT Sans, sans-serif;
            line-height: inherit;

            .ql-editor {
                padding: 0;
                line-height: inherit;

                &.ql-blank::before {
                    left: 0;
                    color: #0000007d;
                }
            }
        }

        &.ql-toolbar {
            display: none;
            background-color: white;
            z-index: 100;
            box-shadow: 0 2px 4px #00000047;
            position: absolute;
            border-radius: 10px;
            top: -42px;
            right: 0;

            @media (max-width: 768px) {
                top: -80px;
            }

            .ql-picker.ql-expanded .ql-picker-label {
                border: none;
            }
        }
    }
}
</style>
