export default requester => {
    return {
        /**
         * Get idea feedback
         *
         * @param {sting} id
         * @returns
         */
        feedback (id) {
            return requester.get(`ideas/${id}/feedbacks`)
        },

        /**
         * Create new idea
         *
         * @param {object} data
         * @returns
         */
        create (data = {}) {
            return requester.post('ideas/v2', data)
        },

        /**
         * Decline idea
         **/
        decline (id) {
            return requester.post(`ideas/${id}/decline`)
        },
    };
}


