<script>
import axios from 'axios';
import SkipButton from './SkipButton.vue';
import normalizeUrl from 'normalize-url';
import { excerpt } from '@/utils/Helpers';

export default {
    inject: {
        form: { default: null },
        stepper: { default: null },
    },

    components: {
        SkipButton,
    },

    data() {
        return {
            busy: false,
            url: this.form.fields.challenge.article ? this.form.fields.challenge.article.url : '',
            emptyImage: false,
            errorMessage: null,
        };
    },

    computed: {
        isDailyNews() {
            return false;
        },
        normalizedURL () {
            return normalizeUrl(this.url);
        },
        hasArticle() {
            return !!this.form.fields.challenge.article;
        },
        isValidUrl() {
            return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(this.url);
        },
        canUseChallengeImage() {
            if (!this.form.fields.challenge.primary_image) {
                return false;
            }

            if (this.hasArticle) {
                return this.form.fields.challenge.article.image_url !== this.form.fields.challenge.primary_image;
            }

            return false;
        },
        canReplaceChallengeImage() {
            if (this.emptyImage) {
                return false;
            }

            if (this.hasArticle) {
                return this.form.fields.challenge.article.image_url &&
                    this.form.fields.challenge.article.image_url !== this.form.fields.challenge.primary_image;
            }

            return false;
        },
    },

    methods: {
        excerpt,
        async scrape() {
            this.form.fields.challenge.article = null;

            if (!this.url || !this.isValidUrl) {
                this.errorMessage = 'The provided URL is either missing or invalid. Please enter a valid URL.'
                return;
            }

            try {
                this.busy = true;
                this.errorMessage = null;

                const { data } = await this.$nerve.utils.scrapeArticle({
                    uri: this.normalizedURL
                });
                const { title, description, image_url, url, keywords } = data;

                this.form.fields.challenge.article = {
                    title, description: description, image_url: null, url, keywords,
                };
                
                this.emptyImage = !image_url;

                if (!this.emptyImage) {
                    await this.storeImage(image_url);
                }
            } catch (err) {
                this.emptyImage = true;

                this.errorMessage = 'Unable to fetch metadata to the provided URL. Please check the URL and try again.'
            } finally {
                this.busy = false;
            }
        },
        async storeImage (imageUrl) {
            try {
                const { data } = await axios.get(
                    `${import.meta.env.VUE_APP_FUNC_URL}/use-image?source=${imageUrl}`,
                    { responseType: 'arraybuffer' },
                );

                const { data: { url } } = await this.$api.resource.uploadImage(
                    data, { type: 'png' }, this.headers
                );

                if (!this.form.fields.challenge.primary_image) {
                    this.form.fields.challenge.primary_image = url;
                }
                
                this.form.fields.challenge.article.image_url = url;
            } catch (err) {
                this.emptyImage = true;
            }
        },
        skip() {
            this.form.fields.article_skiped = true;
            this.$parent.$parent.next();
        },
        clear() {
            this.form.fields.challenge.article = null;
            this.url = null;
        },
        useChallengeImage () {
           this.form.fields.challenge.article.image_url = this.form.fields.challenge.primary_image;
           this.emptyImage = false;
        },
        replaceChallengeImage() {
            this.form.fields.challenge.primary_image = this.form.fields.challenge.article.image_url;
        },
    }
}
</script>

<template>
    <div>
        <div class="challenge-section-heading has-optional-tag">
            <div>
                <h2 class="challenge-section-heading__title">
                    <b>Enter your News Article’s link here</b>
                </h2>
                <p class="challenge-section-heading__description">
                    You can link to an interesting or supporting article. A summary of the article will appear inside your challenge detail view.
                </p>
            </div>
            <SkipButton />
        </div>
        <div class="form-section-wrapper">
            <div class="form-groups">
                <div class="form-group is-inline mb-4" @keydown.enter="scrape">
                    <NInput placeholder="Paste in the full URL" v-model="url">
                        <template #prepend>
                            <NIcon as="link-solid" class="mr-4" />
                        </template>
                    </NInput>
                    <button aria-label="Retrieve" :disabled="busy || ! isValidUrl"  type="button" class="flex items-center py-2 px-6 leading-snug rounded-md hover:bg-nerve-600 shadow border bg-nerve text-white transition ease-in-out duration-300 cursor-pointer" @click.prevent="scrape">
                        {{ busy ? 'Retrieving' : 'Retrieve' }}
                        <svg v-if="busy" class="animate-spin h-5 w-5 ml-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                </div>

                <span v-if="errorMessage" class="form-group__error">{{ errorMessage }}</span>

                <p class="form-block-help-text mb-6 mt-0 px-1">
                    <i>We’ll automatically pull in the story’s title, image and the intro paragraph (when possible). If necessary you can customize the image in the next step.</i>
                </p>
                <div v-if="hasArticle" class="news-item-wrapper is-center mb-8">
                    <h3 v-show="isDailyNews" class="form-group__title">
                        <span aria-hidden="true" class="icon icon-link-solid"></span>
                        Daily News topic
                    </h3>
                    <div class="news-item shadow-high rounded-md overflow-hidden mb-6">
                        <div class="news-item__image-wrapper min-h-80 flex bg-dust-100">
                            <a v-show="isDailyNews" href="#" class="news-item__category">Topic Placeholder</a>
                            <NImage
                                v-if="!emptyImage"
                                :srcs="[form.fields.challenge.article.image_url]"
                                class="!aspect-image border-b border-dust-100"
                            />
                            <div v-else-if="(emptyImage || !form.fields.challenge.primary_image) && stepper" class="flex flex-col items-center justify-center bg-orange-50 p-20">
                                <p class="text-orange-400 mb-3 text-center">The image failed to parse. But don't worry, you can upload your own image, graphic or illustration.</p>
                                <button aria-label="Upload your own image" type="button" class="px-4 py-3 leading-none bg-orange-400 hover:bg-orange-500 text-white rounded-md" @click="stepper.to('image')">
                                    Upload your own image
                                </button>
                            </div>
                            <div v-else class="flex flex-col items-center justify-center p-20 w-full">
                                <span class="text-dust-600">No image!</span>
                            </div>
                        </div>
                        <div v-show="isDailyNews" class="news-item__meta">
                            <span aria-hidden="true" class="icon icon-newspaper-solid"></span>
                            <b class="platform">DailyNerve</b> Solve the News
                        </div>
                        <div class="news-item__content p-4 bg-white">
                            <h3 class="news-item__title">{{ form.fields.challenge.article.title }}</h3>
                            <p  class="news-item__description text-dust-700">
                                {{ excerpt(form.fields.challenge.article.description, 250) }}
                            </p>
                            <a :href="form.fields.challenge.article.url" class="news-item__link" target="_blank">
                                {{ form.fields.challenge.article.url }}
                            </a>
                            <div v-show="isDailyNews">
                                <a :href="form.fields.challenge.article.url" class="btn has-no-style" target="_blank">
                                    <span aria-hidden="true" class="btn__icon is-left icon-link-solid"></span>
                                    Read the full story
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-show="isDailyNews" class="news-item__footer mt-4 mb-6">
                        <span aria-hidden="true" class="icon icon-image-regular"></span>
                        <b>Don’t like this one? <a href="#">Replace the image here.</a></b>
                    </div>
                    <div class="flex items-center justify-center gap-2">
                        <button aria-label="Remove article" class="flex-none flex items-center px-4 py-2 border rounded-md text-white bg-red-500 hover:bg-red-600" @click="clear">
                            <NIcon as="remove" left />
                            Remove article
                        </button>
                        <button aria-label="Use challenge image" v-if="canUseChallengeImage" class="flex-none flex items-center px-4 py-2 border border-nerve-300 rounded-md text-nerve-500 bg-nerve-100 hover:bg-nerve-200 hover:text-nerve-600" @click="useChallengeImage">
                            Use challenge image
                        </button>
                        <button aria-label="Replace challenge image" v-if="canReplaceChallengeImage" class="flex-none flex items-center px-4 py-2 border border-nerve-300 rounded-md text-nerve-500 bg-nerve-100 hover:bg-nerve-200 hover:text-nerve-600" @click="replaceChallengeImage">
                            Replace challenge image
                        </button>
                    </div>
                </div>
                <div v-else class="flex items-center justify-center px-2 sm:p-10">
                    <slot name="placeholder">
                        <div class="w-full flex items-center justify-center max-w-140 min-h-[40rem] h-[35rem] border-2 border-dust-300 border-dashed rounded-md">
                            <p class="text-dust-600">
                                Enter a URL to an article you’d like to share with your challenge participants.
                            </p>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>
