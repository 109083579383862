<script setup>
import LearnMore from '@/components/challenge-show/Learn.vue';
import NFireworks from '@/components/NFireworks.vue';
import { isAMA } from '@/utils/challenge';
import { ref, onMounted, nextTick } from 'vue';

defineProps({
	challenge: {
		type: Object,
		required: true,
	},
	flow: {
		type: Object,
		required: false,
	},
});

const messageBox = ref(null);

onMounted(() => {
	nextTick(() => {
		messageBox.value &&
			messageBox.value.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
	});
});
</script>

<template>
	<div class="flex flex-col items-center gap-8 pb-10">
		<div
			ref="messageBox"
			class="pt-3 w-full"
		>
			<div class="bg-rater text-white flex justify-center flex-col items-center relative py-6 w-full text-center px-4">
				<NFireworks
					:fire="!!flow.total_rating"
					class="flex w-full"
					fire-class="animate-delay-[2s]"
				/>

				<NFireworks
					:fire="!!flow.total_rating"
					tag="h1"
					class="idea-rating-message__title relative"
				>
					<NIcon
						as="race-flag"
						class="mr-2"
					/>
					<span v-if="flow.total_ideas && flow.total_rating === 0">Congratulations, you entered the first idea in this challenge!</span>
					<span v-else-if="flow.total_rating">Congratulations, you’ve rated all the ideas in this challenge!</span>
					<span v-else>There are no ideas to be rated at the moment!</span>
					<NIcon
						as="race-flag"
						class="ml-2 is-right"
					/>
				</NFireworks>

				<p class="idea-rating-message__description max-w-[25rem] text-center italic mb-0">
					Your rating and mentoring helps the community improve and builds your
					<RouterLink
						:to="{ name: 'dashboard.my-progress.score-stats', params: { user: 'me' }, query: { tab: 'nerve-score' } }"
						class="text-white font-bold hover:underline hover:text-white"
						>Nerve scores</RouterLink
					>.
				</p>
				<div
					class="arrows-bottom-[20px] arrows-border-rater-500 absolute arrows-bg-rater !left-1/2 transform !-translate-x-5 !-translate-y-1"
				/>
			</div>
		</div>

		<NModal
			ref="learnMore"
			#default="{ close }"
		>
			<div class="relative">
				<button aria-label="button"
					class="icon-times-circle-regular absolute top-0 right-0 -mt-8 text-white text-xl hover:text-gray-200"
					@click.prevent="close"
				></button>
				<div class="bg-black w-full h-[25rem] sm:w-[52rem] sm:h-[26.25rem]">
					<iframe
						class="w-full h-full"
						src="https://www.youtube-nocookie.com/embed/bsSy3p5lvgo"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</div>
		</NModal>

		<template v-if="isAMA(challenge)">
			<div class="border-innovator border-2 flex flex-col sm:w-[52rem] rounded-lg overflow-hidden">
				<div class="flex items-center px-3 py-2 w-full bg-innovator text-white">
					<NIcon
						as="idea-given"
						class="text-2xl mr-2"
					/>
					<span class="font-bold">Your idea has been entered in {{ challenge.catalyst.first_name }}’s AMA Question.</span>
				</div>
				<div class="p-4 bg-dust-100">
					<p>
						While we can’t guarantee that {{challenge.catalyst.first_name}} will be able to give you feedback and mentoring on your idea(s), they will receive
						a notice that new ideas are ready to mentor.
					</p>
					<p>
						You will receive an email alert when your idea has been mentored, and if there was an entry price, your payment method will be
						charged. You can view all the incoming comments here in your
						<RouterLink
							class="font-bold text-dust-900 underline hover:underline"
							:to="{ name: 'dashboard.mentoring', params: { user: $auth.user.screen_name } }"
							>Mentor Dashboard</RouterLink
						>
					</p>

					<div
						v-if="challenge.catalyst"
						class="flex justify-center my-6"
					>
						<RouterLink
							:to="{ name: 'dashboard.mentoring', params: { user: $auth.user.screen_name } }"
							class="border-mentor-400 border-2 rounded-lg shadow-active overflow-hidden flex flex-col bg-white max-w-[12rem]"
						>
							<div
								class="flex items-center px-3 py-2 justify-start border-b-2 border-mentor-400 bg-mentor-100 text-mentor w-full leading-none"
							>
								<NIcon
									as="mentor"
									class="text-2xl mr-2"
								/>
								<span class="font-bold">Mentor Dashboard</span>
							</div>
							<div class="flex items-ceter justify-center py-2 px-6 text-mentor">
								<span class="text-center font-semibold">See feedback on your Idea!</span>
							</div>
						</RouterLink>
					</div>
				</div>
			</div>

			<h2 class="">Here’s where to go next:</h2>

			<div
				v-if="$auth.authenticated"
				class="grid grid-cols-1 sm:grid-cols-3 gap-8 sm:w-[52rem]"
			>
				<RouterLink
					:to="{ name: 'dashboard.innovator', params: { user: $auth.user.screen_name } }"
					class="border-innovator-400 border-2 rounded-lg hover:shadow overflow-hidden flex flex-col bg-white"
				>
					<div
						class="flex items-center px-3 py-2 justify-start border-b-2 border-innovator-400 bg-innovator-100 text-innovator w-full leading-none"
					>
						<NIcon
							as="innovator"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">Innovator Dashboard</span>
					</div>
					<div class="flex items-ceter justify-center py-3 px-4 w-full">
						<span class="text-center font-semibold text-innovator">Your central Hub <br />All your ideas and stats</span>
					</div>
				</RouterLink>
				<RouterLink
					:to="{ name: 'home.user', params: { user: challenge.catalyst.handle } }"
					class="border-catalyst-400 border-2 rounded-lg hover:shadow overflow-hidden w-full sm:w-auto"
				>
					<div class="flex items-center px-3 py-2 justify-center border-b-2 border-catalyst-400 bg-catalyst-100 text-catalyst-600">
						<NIcon
							as="catalyst"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">Enter other Challenges from</span>
					</div>
					<div class="flex items-ceter justify-center py-3 px-3 text-catalyst-600 bg-white">
						<NPlayerDetails :user="challenge.catalyst" />
					</div>
				</RouterLink>
				<RouterLink
					:to="{ name: 'platform.olympiad.leaderboard', params: { platformSlug: 'platform' } }"
					class="border-secondary-400 border-2 rounded-lg hover:shadow overflow-hidden flex flex-col bg-white"
				>
					<div
						class="flex items-center px-3 py-2 justify-start border-b-2 border-secondary-400 bg-secondary-100 text-secondary w-full leading-none"
					>
						<NIcon
							as="stream"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">View the Leaderboards</span>
					</div>
					<div class="flex items-ceter justify-center py-2 px-6 text-secondary">
						<span class="text-center font-semibold">Check your rankings in each game role</span>
					</div>
				</RouterLink>
			</div>

			<div class="sm:w-[52rem]">
				<div class="border-secondary-400 border-2 rounded-lg hover:shadow overflow-hidden w-full">
					<div class="flex items-center px-3 py-2 justify-center border-b-2 border-secondary-400 bg-secondary-100 text-secondary-600">
						<NIcon
							as="brain"
							class="text-2xl mr-2 text-nerve"
						/>
						<span class="font-bold">Browse more Questions on BigNerve’s Home feed</span>
					</div>
					<div class="flex flex-col sm:flex-row sm:items-center sm:justify-center gap-6 p-4 bg-white">
						<RouterLink
							:to="{ name: 'home.challenges', query: { filter: 'trending' } }"
							class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
						>
							<NIcon
								as="question-circle"
								class="text-xl"
								left
							/>Trending
						</RouterLink>
						<RouterLink
							:to="{ name: 'home.challenges', query: { filter: 'recents' } }"
							class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
						>
							<NIcon
								as="question-circle"
								class="text-xl"
								left
							/>Most Recent
						</RouterLink>
						<RouterLink
							:to="{ name: 'home.challenges', query: { filter: 'closing_24h' } }"
							class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
						>
							<NIcon
								as="question-circle"
								class="text-xl"
								left
							/>Closing &gt; 24H
						</RouterLink>
						<RouterLink
							:to="{ name: 'home.challenges', query: { filter: 'prize_money' } }"
							class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
						>
							<NIcon
								as="question-circle"
								class="text-xl"
								left
							/>Most Prizes/Money
						</RouterLink>
					</div>
				</div>
			</div>

			<div class="flex justify-center gap-8 w-full sm:w-[52rem] overflow-hidden">
				<button aria-label="button"
					type="button"
					class="border-director-400 border-2 rounded-lg hover:shadow overflow-hidden max-w-[15rem]"
					@click="() => $refs.learnMore.open()"
				>
					<div class="flex items-center px-3 py-2 justify-center border-b-2 border-director-400 bg-director-100 text-director">
						<NIcon
							as="play-circle-regular"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">Watch Video</span>
					</div>
					<div class="flex items-ceter justify-center py-2 px-3 text-director bg-white">
						<span class="text-center font-semibold">Learn about Idea Tournaments and how to play</span>
					</div>
				</button>
				<button aria-label="button"
					type="button"
					class="border-dust-400 border-2 rounded-lg hover:shadow overflow-hidden max-w-[15rem]"
					@click="() => $bus('tour:next', { guide: 'start-guide', payload: challenge })"
				>
					<div class="flex items-center px-3 py-2 justify-center border-b-2 border-dust-400 bg-dust-200">
						<NIcon
							as="onboarding-flow"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">Take A Tour</span>
					</div>
					<div class="flex items-ceter justify-center py-2 px-3 bg-white">
						<span class="text-center font-semibold text-dust-700">Learn more about the idea game</span>
					</div>
				</button>
			</div>
		</template>

		<template v-else>
			<div class="grid grid-cols-1 sm:grid-cols-3 gap-8 w-full sm:w-[52rem] overflow-hidden">
				<button aria-label="button"
					type="button"
					class="border-dust-400 border-2 rounded-lg hover:shadow overflow-hidden"
					@click="() => $bus('tour:next', { guide: 'start-guide', payload: challenge })"
				>
					<div class="flex items-center px-3 py-2 justify-center border-b-2 border-dust-400 bg-dust-200">
						<NIcon
							as="onboarding-flow"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">Take A Tour</span>
					</div>
					<div class="flex items-ceter justify-center py-2 px-3 bg-white">
						<span class="text-center font-semibold text-dust-700">A guided walk-through of the site's many features</span>
					</div>
				</button>
				<RouterLink
					:to="{ name: 'platform.olympiad.leaderboard', params: { platformSlug: 'platform' } }"
					class="border-secondary-400 border-2 rounded-lg hover:shadow overflow-hidden"
				>
					<div class="flex items-center px-3 py-2 justify-center border-b-2 border-secondary-400 bg-secondary-100 text-secondary">
						<NIcon
							as="stream"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">View the Leaderboards</span>
					</div>
					<div class="flex items-ceter justify-center py-2 px-3 text-secondary bg-white">
						<span class="text-center font-semibold">Check your rankings and progress</span>
					</div>
				</RouterLink>
				<button aria-label="button"
					type="button"
					class="border-director-400 border-2 rounded-lg hover:shadow overflow-hidden"
					@click="() => $refs.learnMore.open()"
				>
					<div class="flex items-center px-3 py-2 justify-center border-b-2 border-director-400 bg-director-100 text-director">
						<NIcon
							as="play-circle-regular"
							class="text-2xl mr-2"
						/>
						<span class="font-bold">Watch Welcome Video</span>
					</div>
					<div class="flex items-ceter justify-center py-2 px-3 text-director bg-white">
						<span class="text-center font-semibold">Cam talks about Idea Tournaments and why</span>
					</div>
				</button>
			</div>

			<div class="border-rater border-2 flex flex-col sm:w-[52rem] rounded-lg overflow-hidden">
				<div class="flex items-center px-3 py-2 w-full bg-rater text-white">
					<NIcon
						as="brain"
						class="text-2xl mr-2"
					/>
					<span class="font-bold">You’re now part of the BigNerve Idea Tournament!</span>
				</div>
				<div class="p-4 bg-dust-100">
					<p>
						The <b>Idea Tournament</b> is an ongoing series of challenge questions where creative thinkers brainstorm ideas and solve
						interesting problems together.
					</p>
					<p>
						Anyone (yes, you!) can post challenge questions for us all to enter. There's all kinds of interesting problems to explore
						here, big and small. You'll meet interesting people from around the world, all striving to become more innovative.
					</p>

					<p>
						Play to build your reputation, earn money, receive peer feedback, and help others! Invite your friends to have fun together
						doing something important.
					</p>

					<div
						v-if="challenge.catalyst"
						class="flex justify-center mb-6"
					>
						<RouterLink
							:to="{ name: 'home.user', params: { user: challenge.catalyst.handle } }"
							class="border-catalyst-400 border-2 rounded-lg hover:shadow overflow-hidden w-full sm:w-auto"
						>
							<div class="flex items-center px-3 py-2 justify-center border-b-2 border-catalyst-400 bg-catalyst-100 text-catalyst-600">
								<NIcon
									as="catalyst"
									class="text-2xl mr-2"
								/>
								<span class="font-bold">Enter other Challenges from</span>
							</div>
							<div class="flex items-ceter justify-center py-3 px-3 text-catalyst-600 bg-white">
								<NPlayerDetails :user="challenge.catalyst" />
							</div>
						</RouterLink>
					</div>

					<div class="mb-6">
						<div class="border-secondary-400 border-2 rounded-lg hover:shadow overflow-hidden w-full">
							<div
								class="flex items-center px-3 py-2 justify-center border-b-2 border-secondary-400 bg-secondary-100 text-secondary-600"
							>
								<NIcon
									as="brain"
									class="text-2xl mr-2 text-nerve"
								/>
								<span class="font-bold">Browse more Questions on BigNerve’s Home feed</span>
							</div>
							<div class="flex flex-col sm:flex-row sm:items-center sm:justify-center gap-6 p-4 bg-white">
								<RouterLink
									:to="{ name: 'home.challenges', query: { filter: 'trending' } }"
									class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
								>
									<NIcon
										as="question-circle"
										class="text-xl"
										left
									/>Trending
								</RouterLink>
								<RouterLink
									:to="{ name: 'home.challenges', query: { filter: 'recents' } }"
									class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
								>
									<NIcon
										as="question-circle"
										class="text-xl"
										left
									/>Most Recent
								</RouterLink>
								<RouterLink
									:to="{ name: 'home.challenges', query: { filter: 'closing_24h' } }"
									class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
								>
									<NIcon
										as="question-circle"
										class="text-xl"
										left
									/>Closing &gt; 24H
								</RouterLink>
								<RouterLink
									:to="{ name: 'home.challenges', query: { filter: 'prize_money' } }"
									class="flex font-bold cursor-pointer items-center leading-none px-3 py-2 text-secondary bg-secondary-100 rounded-lg hover:bg-secondary-200 hover:text-secondary-600"
								>
									<NIcon
										as="question-circle"
										class="text-xl"
										left
									/>Most Prizes/Money
								</RouterLink>
							</div>
						</div>
					</div>

					<div
						v-if="$auth.authenticated"
						class="grid grid-cols-1 sm:grid-cols-3 gap-8 w-full"
					>
						<RouterLink
							:to="{ name: 'dashboard.innovator', params: { user: $auth.user.screen_name } }"
							class="border-innovator-400 border-2 rounded-lg hover:shadow overflow-hidden flex flex-col bg-white"
						>
							<div
								class="flex items-center px-3 py-2 justify-start border-b-2 border-innovator-400 bg-innovator-100 text-innovator w-full leading-none"
							>
								<NIcon
									as="innovator"
									class="text-2xl mr-2"
								/>
								<span class="font-bold">Innovator Dashboard</span>
							</div>
							<div class="flex items-ceter justify-center p-3 w-full">
								<span class="text-center font-semibold text-innovator">Your central hub for your ideas and stats</span>
							</div>
						</RouterLink>
						<RouterLink
							:to="{ name: 'dashboard.mentoring', params: { user: $auth.user.screen_name } }"
							class="border-mentor-400 border-2 rounded-lg hover:shadow overflow-hidden flex flex-col bg-white"
						>
							<div
								class="flex items-center px-3 py-2 justify-start border-b-2 border-mentor-400 bg-mentor-100 text-mentor w-full leading-none"
							>
								<NIcon
									as="mentor"
									class="text-2xl mr-2"
								/>
								<span class="font-bold">Mentor Dashboard</span>
							</div>
							<div class="flex items-ceter justify-center py-2 px-3 text-mentor">
								<span class="text-center font-semibold">See responses to your Mentor comments</span>
							</div>
						</RouterLink>
						<RouterLink
							:to="{ name: 'dashboard.rater.open', params: { user: $auth.user.screen_name }, query: { set: 'new' } }"
							class="border-rater-400 border-2 rounded-lg hover:shadow overflow-hidden flex flex-col bg-white"
						>
							<div
								class="flex items-center px-3 py-2 justify-start border-b-2 border-rater-400 bg-rater-100 text-rater w-full leading-none"
							>
								<NIcon
									as="rater"
									class="text-2xl mr-2"
								/>
								<span class="font-bold">Analyst Dashboard</span>
							</div>
							<div class="flex items-ceter justify-center py-2 px-3 text-rater w-full">
								<span class="text-center font-semibold">Rate more ideas in challenges you entered</span>
							</div>
						</RouterLink>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
