<script setup>
import { inject } from 'vue';

const stepper = inject('stepper', null);
</script>

<template>
    <span v-if="stepper" class="challenge-section-heading__optional-tag">
        <span>Optional</span>
        <button aria-label="button" type="button" @click.prevent="stepper.next()">Skip</button>
    </span>
</template>