import { oldApi as $nerve } from '@/modules/services';
import { useAuth } from '@/composable/auth';
import { Session } from '@/utils/Auth';

const { setAccount, setAuthenticating } = useAuth();

export default {
    namespaced: true,

    state: {
        currentUser: { user: {} },
        authenticated: false,
        authenticating: false,
    },

    getters: {
        currentUser(state) {
            return state.currentUser;
        },
        authenticated(state) {
            return state.authenticated;
        },
        authenticating(state) {
            return state.authenticating;
        },
    },

    mutations: {
        setCurrentUser(state, payload) {
            state.currentUser = Object.assign({ user: {} }, payload);
        },
        setAuthenticated(state, payload) {
            state.authenticated = payload;
        }
    },

    actions: {
        login({ commit, dispatch, state }) {
            state.authenticating = true;

            setAuthenticating(true);
            return new Promise((resolve, reject) => {
                $nerve.users.detailedMe().then(response => {
                    commit('setCurrentUser', response.data.data);
                    commit('setAuthenticated', Session.isAuthenticated());

                    setAccount(response.data.data);

                    resolve(response.data.data);
                }).catch(error => {
                    dispatch('logout');
                    
                    reject(error);
                }).finally(() => {
                    state.authenticating = false;

                    setAuthenticating(false);
                });
            });
        },
        logout(context) {
            Session.remove();
            context.commit('setCurrentUser', {});
            context.commit('setAuthenticated', false);
        }
    }
}
