<script setup>
import SearchChallenges from "@/components/search/Challenges.vue"
import CategoriesPicker from "@/layouts/partials/CategoriesPicker.vue"
import HeaderLinks from "@/layouts/partials/HeaderLinks.vue"
import HomeFilters from "@/components/home/Filters.vue"
import { ref } from 'vue'
import { useRouteQuery } from '@vueuse/router'
import { RouterView } from "vue-router"

const category = useRouteQuery('category', '')
const searchChallenge = ref(null);
</script>

<template>
    <div class="relative">
        <div id="heading" class="primary-heading-section home-grid__heading flex flex-wrap sm:items-center gap-3">
            <HeaderLinks class="mb-4">
                <HomeFilters id="challenge-filters" />

                <div class="flex items-center space-x-2 sm:ml-auto">
                    <NTip :open="$auth.authenticated" title="New Feature! Search Challenges"
                        target="challenge:search:new-feature" placement="bottom" class="inline-block">
                        <template #description>
                            <p class="mb-2">
                                This search tool will retrieve open and closed challenges that match your keywords. The
                                more keywords you
                                type the more selective the results will be.
                            </p>
                            <p class="mb-2">
                                Typing <kbd class="border rounded-[3px] px-[1px]">/</kbd> will open the search box.
                            </p>
                        </template>
                        <template #default>
                            <button aria-label="button"
                                class="flex items-center bg-white rounded-lg pl-4 pr-5 py-2 border space-x-2 border-dust-500 text-dust-500 leading-none w-full"
                                type="button" @click="() => $refs.searchChallenge.open()">
                                <NIcon as="search-solid" />
                                <span class="text-xs italic">Search Challenges</span>
                                <!-- <span class="ml-2 font-thin text-dust-400">
                              Type <kbd class="border rounded-[3px] px-[1px]">/</kbd> to search
                          </span> -->
                            </button>
                        </template>
                    </NTip>

                    <CategoriesPicker v-model="category" />
                </div>
            </HeaderLinks>

            <SearchChallenges ref="searchChallenge" />
        </div>

        <RouterView />
    </div>
</template>