<script>
import { h } from 'vue';

export default function NIcon (props, { attrs, listeners }) {
  const classes = [`icon-${props.as}`, 'icon', attrs.class]

  if (props.hasOwnProperty('left')) {
    classes.push('mr-1')
  }

  if (props.hasOwnProperty('right')) {
    classes.push('ml-1')
  }

  return h('span', {
    ...listeners,
    ...attrs,
    class: classes,
    role: 'presentation',
  })
}
</script>
