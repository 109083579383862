import mitt from 'mitt';

export const Emitter = mitt();

export const bus = (type, ...args) => {
    Emitter.emit(type, ...args);
};

export const on = (type, handler) => {
    Emitter.on(type, handler);
};

export const once = (type, handler) => {
    Emitter.on(type, handler);
    Emitter.on(type, Emitter.off.bind(Emitter, type, handler));
};

export const bindEmit = (...args) => Emitter.emit.bind(Emitter, ...args);

export const install = ({ app }) => {
    app.mixin({
        beforeCreate() {
            this.$bus = bus;

            const busOptions = this.$options.bus;
            if (busOptions) {
                this.$_bus = [];

                const addListeners = (map) => {
                    for (const target in map) {
                        const handler = map[target].bind(this);

                        const [event, modifier] = target.split('@');

                        if (modifier === 'once') {
                            once(event, handler);
                        } else {
                            on(event, handler);
                        }

                        this.$_bus.push({
                            event,
                            handler,
                        });
                    }
                };

                if (Array.isArray(busOptions)) {
                    busOptions.forEach(addListeners);
                } else {
                    addListeners(busOptions);
                }
            }
        },

        beforeDestroy() {
            if (this.$_bus) {
                for (const listener of this.$_bus) {
                    Emitter.off(listener.event, listener.handler);
                }
            }
        },
    });

    app.config.optionMergeStrategies.bus = (parent, child, vm) => {
        if (Array.isArray(parent)) {
            if (Array.isArray(child)) {
                return parent.concat(child);
            } else {
                parent.push(child);
                return parent;
            }
        } else if (Array.isArray(child)) {
            child.push(parent);
            return child;
        } else if (parent && child) {
            return [parent, child];
        } else if (parent) {
            return parent;
        }
        return child;
    };
};

export default Emitter;
