<script>
    export default {
        computed: {
            user() {
                return this.$store.getters['auth/currentUser'].user;
            }
        }
    }
</script>

<template>
    <div class="page__sidebar-inside">
        <ul>
            <li><strong>--- People ---</strong></li>
            <li>
                <router-link :to="{ name: 'admin.dashboards.users' }">
                    Users
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'admin.dashboards.feedbacks' }">
                    Feedback Forms
                </router-link>
            </li>
            <li><a href="#">Ambassadors</a></li>
            <li><a href="#">Comment Moderation</a></li>
            <li><a href="#">Scout Refferals</a></li>
            <li><a><strong>--- Content ---</strong></a></li>
            <li><a href="#">Challenges</a></li>
            <li><a href="#">Category Manager</a></li>
            <li><a href="#">Game Analytics KPIs</a></li>
            <li><a><strong>--- Money ---</strong></a></li>
            <li><a href="#">Banking Ledger</a></li>
        </ul>
    </div>
</template>
