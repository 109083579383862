<script>
import { numeric } from '@/utils/Helpers';
import VStarRating from '@/components/externals/star-rating/index.vue';

export default {
    name: 'NRating',

    components: { VStarRating },

    props: {
        modelValue: {
            type: [String, Number],
            default: 0
        },
        max: {
            type: Number,
            default: 5
        },
        tooltip: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        increment: {
            type: Number,
            default: 1,
        },
        color: {
            type: String,
            default: 'rater'
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        inactiveColor: {
            type: String,
            default: 'white'
        },
        disabledColor: {
            type: String,
            default: 'dust'
        },
        formatTooltip: {
            type: Function,
            default: v => v,
        },
    },

    emits: ['update:modelValue', 'change', 'hovered'],

    data () {
        return {
            hovered: 0,
            readOnly: this.disabled,
        }
    },

    watch: {
        disabled (value) {
            this.$nextTick(() => {
                this.readOnly = value;
            })
        }
    },

    computed: {
        currentColor () {
            return this.disabled
                ? `text-${this.disabledColor}`
                : `text-${this.color}`;
        },
        computedTooltip () {
            return {
                content: this.formatTooltip(String(this.disabled ? this.modelValue : this.hovered)),
                showTriggers: ['hover'],
                hideTriggers: ['hover'],
                placement: 'top',
                disabled: !this.tooltip,
            };
        }
    },

    methods: {
        update(value) {
            if (!this.disabled) {
                this.$emit('update:modelValue', value);
                this.$emit('change', value);
            }
        },
        hover(value) {
            this.hovered = Number(numeric(value, 1));
            this.$emit('hovered', value);
        },
    },
}
</script>

<template>
    <div
        v-tooltip="computedTooltip"
        class="vue-star-rating"
        @mouseleave="hover(modelValue || 0)">

        <VStarRating
            :rating="modelValue"
            :show-rating="false"
            :rounded-corners="true"
            :increment="increment"
            :read-only="readOnly"
            :max-rating="max"
            :clearable="clearable"
            :fixed-points="1"
            :class="currentColor"
            :border-width="5"
            :padding="0"
            :inactive-color="inactiveColor"
            :starSize="25"
            active-color="currentColor"
            active-border-color="currentColor"
            ref="start"
            @hover:rating="hover"
            @update:rating="update"
        />
    </div>
</template>

<style lang="scss">
.vue-star-rating {
    * {
        @apply w-full;
        @apply h-full;
    }

    .vue-star-rating-star svg {
        @apply fill-current;
    }
}
</style>
