<script>
import { restrictedWordsCategories, restrictedWords } from '@/mixins/ChallengeCreateFlow';

export default {
    inject: ['form'],

    props: {
        modelValue: {
            type: String,
            default: '',
        },
        challenge: {
            type: Object,
            default: () => ({}),
        },
        valid: {
            type: Boolean,
            default: false,
        },
        words: {
            type: Array,
            default: () => restrictedWords,
        },
    },

    emits: ['update:valid'],

    data () {
        return {
            shouldContainWords: false,
            wordsRestriction: this.words,
        }
    },
    computed: {
        hasWordsRestriction () {
            return !!this.challenge.solution_format || restrictedWordsCategories.some(category => 
                this.context.categoryMain && this.context.categoryMain.reference.includes(category),
            );
        },
        isValidQuestionTitle () {
            return this.wordsRestriction.some(
                word => (this.modelValue || '').match(new RegExp(word, 'i'))
            )
        },
    },
    watch: {
        isValidQuestionTitle (valid) {
            if (this.shouldContainWords && valid) {
                this.$emit('update:valid', valid);
            }
        },
        shouldContainWords () {
            this.$emit('update:valid', this.isValidQuestionTitle);
        }
    },
    methods: {
        notify () {
            this.shouldContainWords = false
            if (!this.isValidQuestionTitle) {
                setTimeout(() => {
                    this.shouldContainWords = true
                }, 100)
            }
        },
        isSelected (word) {
            return String(this.challenge.question_title).match(new RegExp(word, 'i'))
        }
    }
}
</script>


<template>
    <div>
        <p class="form-block-help-texthas-no-margin-bottom mb-2">
            Consider using these words in your question... This site is not for "<b>good advice</b>" — it is for generating innovative thinking:
        </p>
        <div class="flex flex-wrap gap-2" :class="{ 'animate-shake': shouldContainWords }">
            <span
                v-for="(word, i) of wordsRestriction" :key="i"
                class="px-3 py-1 rounded-md leading-tight font-body"
                :class="
                    isSelected(word)
                    ? 'bg-blue-600 text-white'
                    : 'bg-blue-200 text-blue-800'
                ">
                {{ word }}
            </span>
        </div>
    </div>
</template>
