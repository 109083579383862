import pick from 'lodash/pick';
import { stripHtml } from '@bignerve/ui-utils';
import { gameSetting, isSuggestion, isAMA, isQQ, isTopic } from '@/utils/challenge';
import moment from 'moment'

const IMAGE_BASE_URL = `https://images.bignerve.com`;

export const useChallengeDetails = ({ resources } = {}) => {
    const mixin = {
        inject: {
            _catalyst: { from: 'catalyst', default: null },
        },

        computed: {
            article () {
                return this.challenge.article;
            },
            isSuggestion () {
                return isSuggestion(this.challenge);
            },
            isQQ() {
                return isQQ(this.challenge);
            },
            isAMA() {
                return isAMA(this.challenge);
            },
            isTopic() {
                return isTopic(this.challenge);
            },
            isCaption() {
                const categories = ['Funny', 'Inspiring', 'Insightful', 'Shocking'];
                return this.challenge.caption_solution_format === 'caption'
                    || categories.includes(this.challenge.main_category);
            },
            isComment() {
                this.challenge.caption_solution_format === 'comment'
            },
            isMeme() {
                return this.challenge.caption_solution_format === 'meme';
            },
            isClosed () {
                return this.challenge.is_closed || moment().isAfter(this.challenge.duration_end);
            },
            image() {
                if (this.isSuggestion) {
                    return `${import.meta.env.VUE_APP_BASE_URL}/img/suggestion-box/${this.challenge.solution_format}.png`;
                }

                const [imageId] = String(this.challenge.primary_image).split('/').slice(-1);

                return `${IMAGE_BASE_URL}/cover/${imageId}`;
            },
            preview() {
                if (this.isSuggestion) {
                    return `${import.meta.env.VUE_APP_BASE_URL}/img/suggestion-box/${this.challenge.solution_format}.png`;
                }

                const [imageId] = String(this.challenge.primary_image).split('/').slice(-1);

                return `${IMAGE_BASE_URL}/hero/${imageId}`;
            },
            thumbnail () {
                if (this.isSuggestion) {
                    return `${import.meta.env.VUE_APP_BASE_URL}/img/suggestion-box/${this.challenge.solution_format}.png`;
                }

                const [imageId] = String(this.challenge.primary_image).split('/').slice(-1);

                return `${IMAGE_BASE_URL}/thumbnail/${imageId}`;
            },
            imageCredits () {
                if (!this.challenge.primary_image) {
                    return;
                }
    
                const url = new URL(this.challenge.primary_image);
    
                return url.searchParams.has('s')
                    && {
                        source: url.searchParams.get('s'),
                        user: url.searchParams.get('u'),
                        link: url.searchParams.get('l'),
                    };
            },
            // @deprecated
            hasDescription() {
                return this.hasQuestionDetail;
            },
            hasQuestionDetail () {
                return !!stripHtml(this.questionDetails).trim();
            },
            questionTitle () {
                const catalyst = this._catalyst || this.challenge.catalyst || {};

                if (this.isSuggestion) {
                    return this.$t(
                        `component.challenge.solution-format.${this.challenge.solution_format}.title`,
                        { catalyst_name: catalyst.id ? `${catalyst.first_name} ${catalyst.last_name}` : 'Catalyst' }
                    );
                }

                return this.challenge.question_title;
            },
            questionDetails () {
                const catalyst = this._catalyst || this.challenge.catalyst || {};

                if (this.challenge.question_detail) {
                    return this.challenge.question_detail;
                }

                if (this.isSuggestion) {
                    return this.$t(`component.challenge.solution-format.${this.challenge.solution_format}.description`);
                }

                if (!stripHtml(this.challenge.question_detail) && !this.isCaption) {
                    return this.$t('challenges.intros.question-description.fallback', {
                        catalyst_name: `${catalyst.first_name} ${catalyst.last_name}`,
                    });
                }

                return 'No description';
            },
        },

        methods: {
            useGameSetting (key, defaultValue) {
                return gameSetting(this.challenge, key, defaultValue);
            }
        }
    }

    return resources ? pick(mixin, resources) : mixin;
}

export default useChallengeDetails
