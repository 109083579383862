<script>
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            type: 'wide'
        };
    }
})
</script>

<template>
    <div class="container mx-auto pt-3">
        <RouterView v-slot="{ Component }">
            <template v-if="Component">
                <component :is="Component" />
            </template>
            <div v-else class="w-full h-screen bg-gray-100 rounded-md flex items-center justify-center animate-pulse"></div>
        </RouterView>
    </div>
</template>