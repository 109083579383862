<script>
import IdeaTest from './IdeaTest.vue'
import Previewable from '@/mixins/Previewable'
import { date, currency } from '@bignerve/ui-utils'
import { excerpt } from '@/utils/Helpers'

export default {
    components: {
        IdeaTest,
    },

    mixins: [Previewable],

    computed: {
        isDailyNerve() {
            return this.$store.getters['app/platform'].name === 'dailynerve'
        },
        thumbnail() {
            const url = this.form.fields.challenge.primary_image || ''

            // temp solution to avoid erros when previewing a challenge with no thubmnail
            return url.replace('/images/', '/thumbnail/')
        },
        imageCredits() {
            if (!this.form.fields.challenge.primary_image) {
                return
            }

            const url = new URL(this.form.fields.challenge.primary_image)

            return (
                url.searchParams.has('s') && {
                    source: url.searchParams.get('s'),
                    user: url.searchParams.get('u'),
                    link: url.searchParams.get('l'),
                }
            )
        },
    },

    methods: {
        date,
        currency,
        excerpt,
    },
}
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">
                <b><i>Your Question Preview</i></b>
            </h2>
        </div>
        <div class="challenge-final-preview overflow-hidden">
            <div class="challenge-final-preview__heading">
                <div class="challenge-final-preview__heading-col w-full sm:w-[25rem]">
                    <figure class="challenge-card__image-wrapper">
                        <a href="#" class="challenge-card__category">{{ category }}</a>
                        <NImage :srcs="[thumbnail, image]" class="!aspect-image border-b border-r border-dust-100" />
                        <div v-if="imageCredits" class="!text-dust-600 text-xs font-body text-center py-2 bg-dust-50">
                            Image by
                            <a v-if="imageCredits.user" :href="imageCredits.link" class="text-dust-600 underline"
                                target="_blank" rel="noopener noreferrer">{{ imageCredits.user }}</a>
                            <span v-if="imageCredits.user">on</span>
                            <a :href="imageCredits.link" class="text-dust-600 underline" target="_blank"
                                rel="noopener noreferrer">{{ imageCredits.source }}</a>
                        </div>
                    </figure>
                </div>
                <div class="challenge-final-preview__heading-col flex p-4 gap-4">
                    <h3 class="challenge-card__title text-dust-800 mb-3">
                        <span v-if="form.$parent.isQQ" class="qq-tag">QQ</span>
                        <span v-if="form.$parent.isQQ" class="is-color-default">
                            What could be a good question regarding:
                        </span>
                        {{ title }}
                    </h3>
                    <div class="flex flex-col gap-2">
                        <NPlayerDetails v-tooltip="'Author'" v-if="$auth.user" :user="$auth.user" :clamp="false" />
                        <div v-if="form.fields.challenge.co_author" v-tooltip="'Co-Author'"
                            class="flex items-center space-x-2">
                            <NIcon as="qq" class="text-innovator text-2xl" />
                            <NPlayerDetails :user="form.fields.challenge.co_author" :clamp="false" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="challenge-final-preview__footer flex items-center">
                <div class="challenge-card__footer mt-0 mr-3 flex items-center">
                    <a href="#" class="challenge-card__footer-item">
                        <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                        Ideas (0)
                    </a>
                    <span v-if="form.fields.prize?.prize_money"
                        :class="{ disabled: !currency(form.fields.prize?.prize_money || 0) }"
                        class="challenge-card__footer-item">
                        <span aria-hidden="true" class="icon icon-money-bill-alt-regular"></span>
                        ${{ currency(form.fields.prize.prize_money) }}
                    </span>
                    <span v-if="form.fields.prize?.prize_type" class="challenge-card__footer-item">
                        <span aria-hidden="true" class="icon icon-trophy-solid"></span>
                        {{ $t(`challenges.prize-type.${form.fields.prize.prize_type}`) }}
                    </span>
                    <span v-if="form.fields.challenge.innovation_scale && !form.$parent.isCaption && !form.$parent.isQQ"
                        class="">
                        <img class="h-6" :src="`/img/innovative-level/${form.fields.challenge.innovation_scale}.png`"
                            :alt="form.fields.challenge.innovation_scale" />
                    </span>
                    <button aria-label="button" v-if="form.fields.challenge.slug"
                        class="btn has-no-style challenge-card__footer-item" @click="() => $refs.sharingPopup.open()">
                        <NIcon as="share-square-solid" left />Share
                    </button>
                    <NSharingPopup :item="form.fields.challenge" ref="sharingPopup" />
                </div>
                <div class="challenge-final-preview__countdown mt-0" v-if="form.fields.challenge.duration_end">
                    <b>Challenge closes</b> {{ date(form.fields.challenge.duration_end, 'human') }}
                </div>
            </div>
            <div v-if="form.fields.challenge.question_detail || form.fields.challenge.article"
                class="challenge-final-preview__content">
                <NMarkdown :code="form.fields.challenge.question_detail" :unfurl="true" />
                <div v-if="form.fields.challenge?.article?.title" class="news-item-wrapper mt-4 mb-3">
                    <div class="news-item shadow-high rounded-md overflow-hidden">
                        <div class="news-item__image-wrapper">
                            <NImage v-if="form.fields.challenge.article.image_url"
                                :srcs="[form.fields.challenge.article.image_url]"
                                class="!aspect-image border-b border-dust-100" />
                        </div>
                        <div v-if="isDailyNerve" class="news-item__meta">
                            <span aria-hidden="true" class="icon icon-newspaper-solid"></span>
                            <b class="platform">DailyNerve</b> Solve the News
                        </div>
                        <div class="news-item__content p-4">
                            <h3 class="news-item__title">{{ form.fields.challenge.article.title }}</h3>
                            <p class="news-item__description">
                                {{ excerpt(form.fields.challenge.article.description, 300) }}
                            </p>
                            <a :href="form.fields.challenge.article.url" class="news-item__link">
                                {{ form.fields.challenge.article.url }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="
                !form.$parent.isQQ &&
                (form.fields.challenge.assumption_who || form.fields.challenge.assumption_what || form.fields.challenge.assumption_why)
            " class="challenge-final-preview__cards mt-6">
                <div v-if="form.fields.challenge.assumption_who" class="deep-dive-card">
                    <h3 class="deep-dive-card__title">Who is this challenge for?</h3>
                    <p class="deep-dive-card__description">
                        {{ form.fields.challenge.assumption_who }}
                    </p>
                    <div class="deep-dive-card__icon">
                        <span aria-hidden="true" class="icon icon-check-circle-solid"></span>
                        <span class="question-word">Who</span>
                    </div>
                </div>
                <div v-if="form.fields.challenge.assumption_what" class="deep-dive-card">
                    <h3 class="deep-dive-card__title">What is the core cause(s) of the problem?</h3>
                    <p class="deep-dive-card__description">
                        {{ form.fields.challenge.assumption_what }}
                    </p>
                    <div class="deep-dive-card__icon">
                        <span aria-hidden="true" class="icon icon-check-circle-solid"></span>
                        <span class="question-word">What</span>
                    </div>
                </div>
                <div v-if="form.fields.challenge.assumption_why" class="deep-dive-card">
                    <h3 class="deep-dive-card__title">Why is your question interesting?</h3>
                    <p class="deep-dive-card__description">
                        {{ form.fields.challenge.assumption_why }}
                    </p>
                    <div class="deep-dive-card__icon">
                        <span aria-hidden="true" class="icon icon-check-circle-solid"></span>
                        <span class="question-word">Why</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="(form.fields.idea.title || form.fields.idea.description) && !form.$parent.isQQ"
            class="your-idea-to-test mt-5">
            <div class="challenge-section-heading is-green">
                <h2 class="challenge-section-heading__title has-icon">
                    <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                    <b>Your idea to test.</b>
                </h2>
                <p class="challenge-section-heading__description">
                    This is your own personal wind-tunnel to test your ideas! It will be inserted anonymously among the
                    crowd's ideas once there are
                    enough other ideas.
                </p>
            </div>
            <div v-if="!form.$parent.isCaption" class="form-section-wrapper">
                <div class="form-groups">
                    <div class="form-group">
                        <label class="form-group__title mb-3">{{ form.fields.idea.title }}</label>
                    </div>
                    <div class="form-group">
                        <NMarkdown :code="form.fields.idea.description" />
                    </div>
                    <div class="form-group">
                        <div v-if="form.fields.idea.primary_image" class="image-preview__file mr-0 mb-0">
                            <img :src="form.fields.idea.primary_image" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <IdeaTest v-else class="max-w-120" />
        </div>
        <div class="info-box my-5">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <div class="info-box__content">
                <p>
                    <b>Remember:</b> This question is on your public tournament and will be published under the Creative
                    Commons "CC BY 0" copyright
                    license.
                    <a href="https://creativecommons.org/publicdomain/zero/1.0/" class="text-nerve"
                        target="_blank">Learn more.</a>
                    It gives everyone in the world the right to share, remix, transform, and build upon this content for
                    any purpose, even
                    commercially. This includes the ideas you or other users enter in this challenge, and comments you
                    make as part of discussions,
                    mentoring, and iterations.
                    <!-- This content is your gift to the world's open source idea repository we call <a href="https://nervonah.org/about" target="_blank">NervOnAh</a> -->.
                </p>
                <p>
                    <b>Tips for your challenges:</b> after publishing, you can edit the <b>money/prize</b>, and you can
                    edit the <b>duration</b> if
                    you add money. You could also add more <b>pictures</b> and <b>supporting links</b>. You can’t change
                    the question title or
                    description, or edit the assumptions; instead, you have to close and write a new question. You can’t
                    edit the
                    <b>innovation scale</b> because it affects the kinds of ideas people submit.
                </p>
            </div>
        </div>
    </div>
</template>
