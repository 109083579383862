import Cookie from "@/utils/Cookie";

export const crossPlatformState = (key) => new Proxy({}, {
	get: (_, prop) => {
		const raw = Cookie.get(key, '');
		const query = new URLSearchParams(raw);
		
		return query.get(prop);
	},
	set (_, prop, value) {
		const raw = Cookie.get(key, '');	
		const query = new URLSearchParams(raw);

		query.set(prop, value);

		Cookie.set(key, query.toString(), true, Infinity);

		return true;
	},
})