<script>
import { STATUS as OLYMPIAD_STATUS, PLATFORM_TYPE } from '@/constant/olympiad';
import { date, currency } from '@bignerve/ui-utils';
import NLazyImage from '@/components/NLazyImage.vue';
import OlympiadLink from '@/components/olympiad/OlympiadLink.vue';

export default {
    name: 'OlympiadCard',
    
    props: {
        detail: {
            type: Object
        },
    },

    data () {
        return {
            icons: {
                'public': 'community',
                'private': 'lock',
                'place': 'globe',
                'system-platform': 'community',
                'geographic': 'globe',
            }
        }
    },

    components: { OlympiadLink, NLazyImage },

    computed: {
        thumbnail () {
            const url = this.detail.cover_image_url || '';

            // temp solution to avoid erros when previewing a challenge with no thubmnail
            if (moment(this.detail.start_time).isAfter('2022-02-22')) {
                return url.replace('/preview/images/', '/thumbnail/');
            }

            return url;
        },
        image () {
            return this.detail.cover_image_url;
        },
        closed () {
            return this.detail.status === OLYMPIAD_STATUS.CLOSED;
        },
        isUserPlatform () {
            return this.detail.user && this.detail.platform_type === PLATFORM_TYPE.USER;
        },
        homepageRoute () {
            if (this.isUserPlatform) {
                return {
                    name: 'home.user',
                    params: {
                        user: this.detail.user.handle
                    }
                };
            }

            return { name: 'home.challenges' };
        },
        userHomeRoute () {
            return this.detail.user ? {
                name: 'home.user',
                params: {
                    user: this.detail.user.handle
                },
                hash: '#heading',
            } : { name: 'home.challenges' };
        },
    },

    methods: {
        date, currency
    }
}
</script>

<template>
	<div class="flex flex-col shadow border rounded-md overflow-hidden border-director bg-white mb-1">
		<div class="bg-director flex items-center justify-between text-white px-3 py-1">
			<span class="font-semibold font-narrow text-md flex items-center">
				<NIcon as="stream" class="mr-2" />
				<span>{{ $t('tournament.label') }}</span>
			</span>
			<span class="flex items-center">
				<small class="font-narrow text-md">
                    {{ $t(`tournament.type.${detail.type}`) }}
                </small>
				<NIcon :as="icons[detail.type]" class="ml-2 text-lg" />
			</span>
		</div>
		<div class="relative no-swiping">
            <div v-if="closed" class="absolute p-1 rounded-lg text-white bg-white right-3 top-3">
                <span class="block bg-malachite-500 rounded-lg px-2 leading-3">
                    <NIcon as="race-flag-double" class="text-4xl" />
                </span>
            </div>
			<OlympiadLink :olympiad="detail">
				<NLazyImage
                    :src="[thumbnail, image]"
                    :title="detail.name"
                    :alt="detail.name"
                    class="!aspect-image border-b border-dust-100"
                />
			</OlympiadLink>
		</div>
        <div class="p-3">
            <NPlayerDetails
                v-if="isUserPlatform"
                :user="detail.user"
                medium
            />

            <template v-else-if="!!detail.platform">
                <a :href="detail.platform.url" class="flex items-center rounded-md">
                    <img class="w-6" src="/img/brain.svg" alt="Bing Nerve" />
                    <div class="flex flex-col leading-tight ml-2">
                        <span class="mb-0 text-secondary line-clamp-1">
                            {{ detail.platform.title }}
                        </span>
                        <span class="text-dust-700 text-sm leading-none">
                            {{ detail.platform.slogan }}
                        </span>
                    </div>
                </a>            
            </template>
        </div>
		<div class="px-4 h-full flex flex-col mb-3">
			<OlympiadLink :olympiad="detail" type="leaderboard">
				<p class="font-bold text-lg leading-snug no-swiping text-dust-800">
					{{ detail.name }}
				</p>
			</OlympiadLink>


            <div class="flex justify-center">
                <NTimeRemaining
                    :datetime="detail.end_time"
                    label="Tournament"
                    show-closed-time
                    auto-update
                    icon-left
                />
            </div>
		</div>
        <div class="flex items-center gap-3 px-4 py-2 bg-dust-100">
            <OlympiadLink :olympiad="detail" class="hover:text-nerve flex items-center text-sm" :class="!currency(detail.prize_total) ? 'text-dust-400' : 'text-green-700'">
                <NIcon as="money-bill-alt-regular" class="text-lg" left />
                <span class="leading-none">${{ currency(detail.prize_total) }}</span>
            </OlympiadLink>
            <OlympiadLink :olympiad="detail" type="leaderboard" class="hover:text-nerve flex justify-center items-center text-sm text-dust-700 whitespace-nowrap ml-auto">
                <NIcon as="user-avatar" class="mr-1 text-md" />
                <small class="leading-none">{{ detail.stats.total_players }} {{ $t('tournament.players') }}</small>
            </OlympiadLink>
            <OlympiadLink :olympiad="detail" class="flex justify-end items-center text-dust-700 text-sm whitespace-nowrap">
                <NIcon as="stack" class="mr-1 text-md" />
                <small class="leading-none">{{ detail.stats.total_challenge }} {{ $t('tournament.challenges') }}</small>
            </OlympiadLink>
            <!-- <span class="flex justify-end items-center text-dust-700 text-sm whitespace-nowrap disabled">
                <NIcon as="friends" class="mr-1 text-blue text-md" />
                <small>0 {{ $t('tournament.friends') }}</small>
            </span> -->
        </div>
	</div>
</template>
