import { computed, isRef, toValue } from 'vue';
import { reactify } from '@vueuse/core';
import { useFeatureFlag as useFeatureFlagQuery } from '@/queries/feature-flag';

export const useFeatureFlag = (key) => {
    const flagKey = isRef(key) ? key : computed(() => key)
    const { data, isSuccess } = useFeatureFlagQuery();
    const flags = computed(() => data.value || {});

    const flag = computed(() => {
        return flags.value[flagKey.value];
    });
    const enabled = computed(() => flag.value?.enabled ?? false);
    const variantTo = reactify((target) => {
        const variant = flag.value?.variant;

        if (!variant) return null;

        if (!target) return variant;

        return flag.value.variants.find(v => v.value === target);
    });
    const equalsTo = reactify((target, fallback) => {
        const variant = toValue(variantTo(target));

        if (!variant) return false;

        return target !== undefined
            ? variant.value === target
            : variant.value === fallback;
    });
    const expect = (value = true, fallback) => {
        return equalsTo(value, fallback);
    }

    return {
        isSuccess,
        flags,
        flag,
        enabled,

        variantTo,
        equalsTo,
        expect,
    };
}