import { api } from '@/modules/services';
import { ref } from 'vue';

const cache = new Map();

export const useExperiencePoints = (key) => {
    const loading = ref(null);
    const points = ref(cache.get(key));

    // Get experience points
    // @param  {string} fqdn (Full Qualified Domain Name)
    // role.event.meaningfulname
    const fetch = async (fqdn) => {
        try {
            loading.value = true;
            const { data: response } = await api.brain.experiencePoints(fqdn);

            if (!response.data.points) {
                return;
            }

            points.value = response.data.points;
            cache.set(key, response.data.points);

            return response.data;
        } catch (e) {
            console.log(e)

            cache.value = null;
        } finally {
            loading.value = false;
        }
    }

    if (!cache.has(key)) {
        fetch(key);
    }

    return {
        points,
        fetch,
        loading,
    }
}
