<script>
    import { RANK_CRITERIA } from '@/constant/challenge'
    import { MEDAL } from '@/constant/idea';
    import NMedal from '@/components/leaderboard/Medal.vue';
    import QuestionIdeaTable from './QuestionIdeaTable.vue';
    import orderBy from 'lodash/orderBy';
    
    const communityMedals = {
        1: MEDAL.COMMUNITY_GOLD,
        2: MEDAL.COMMUNITY_SILVER,
        3: MEDAL.COMMUNITY_BRONZE,
        4: MEDAL.BLUE,
    }
    
    export default {
        props: {
            challenge: Object
        },
    
        components: {
            NMedal,
            QuestionIdeaTable,
        },
    
        mounted() {
            this.fetch();
        },
    
        data() {
            return {
                loading: true,
                response: {},
                innevatorDashboardRoute: {
                    name: 'dashboard.innovator',
                    params: {
                        user: this.$auth.authenticated
                            ? this.$auth.user.screen_name
                            : '_me_' // fallback
                    },
                    query: { tab: 'closed-challenges' }
                },
                neccessaryRatings: -1
            };
        },
    
        computed: {
            computedIdeas () {
                const { picked = [], community = [], insufficient_score = [] } = this.groupIdeas()
    
                return {
                    picked: orderBy(picked, ['stats.catalyst_position']).map(
                        idea => ({
                            ...idea,
                            position: idea.stats.catalyst_position,
                            medal: idea.stats.catalyst_medal
                        }),
                    ),
                    community: orderBy(community, ['stats.ranked_position']).map(
                        (idea, index) => ({
                            ...idea,
                            position: idea.stats.ranked_position,
                            medal: index < 3 ? communityMedals[index + 1] : communityMedals[4]
                        })
                    ),
                    insufficient_score: orderBy(insufficient_score, ['stats.ranked_position']),
                }
            },
            showInsufficientScore() {
                return this.response.rank_criteria_to_rank !== RANK_CRITERIA.MINIMAL_QUANTITY
                    && this.computedIdeas.insufficient_score.length > 0;
            }
        },
    
        methods: {
            payment(idea) {
                return {
                    challenge_id: idea.challenge_id,
                    idea_id: idea.id,
                    innovator_id: idea.solver.id,
                    amount: idea.stats.prize_winner_money,
                    name: this.challenge.question_title
                }
            },
            groupIdeas() {
                return this.response.ideas ? {
                    picked: this.response.ideas.filter(({ stats }) =>
                        stats.catalyst_medal && stats.catalyst_position
                    ),
                    community: this.response.ideas.filter(({ stats }) =>
                        stats.ranked_position
                    ),
                    insufficient_score: this.response.ideas.filter(({ stats }) =>
                        !stats.ranked_position
                    ),
                } : {};
            },
            async fetch() {
                try {
                    const query = this.$route.query;
    
                    this.loading = true;
                    const { data: response } = await this.$nerve.challenges.ideas(
                        this.challenge.slug
                    );
    
                    this.response = response.data;
    
                    this.response.ideas.forEach((idea) => {
                        if (query.idea === idea.id) {
                            idea.expand = true;
                            idea.highlight = true;
    
                            return
                        }
    
                        if (String(idea.detail).length < 500) {
                            idea.expand = true;
                        }
                    });
    
                    if (query.idea) {
                        // scroll to the idea
                        this.$nextTick(() => {
                            const idea = this.$el.querySelector(
                                `[data-idea-id="${query.idea}"]`
                            );
                            if (idea) {
                                idea.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                });
                            }
                        });
                    }
                } catch(e) {
                    this.response = {};
                } finally {
                    this.loading = false;
                }
            }
        }
    }
    </script>
    
    <template>
        <div>
            <h3>
                Check out the crowd-sourced ideas, players and rankings in this Suggestion QQ Challenge.
            </h3>
            <p>
                Kudos to every participant! This challenge is a recurring <b>Suggestion QQ</b> which asks the community to come up with interesting questions. It re-spawns every tournament(s) cycle, and you can view all the earlier iterations and ideas here. Ideas for questions can be <b>promoted</b> by Director into actual questions on their platform. <i>Top a title or QQ icon below to view and enter the promoted questions.</i>
            </p>
    
            <!-- <div class="flex flex-wrap items-center justify-between sm:flex-nowrap gap-3">
                <div>
                    <h3>Promoted</h3>
                </div>
                <div :class="{ disabled: !computedIdeas.picked.length }" class="mb-4">
                    <button aria-label="button" :class="{ 'shadow-active': computedIdeas.picked.length }" type="button" class="relative flex items-center whitespace-nowrap font-medium px-1 py-1 leading-none rounded-md border border-nerve text-nerve bg-white hover:bg-dust-100" @click="() => $refs.sharingCatalystPopup.open()">
                        <NIcon as="lightbulb-o1" class="mr-1" />
                        Share Ideas
                        <NIcon as="forward" class="absolute -right-3 filter drop-shadow -top-3 text-xl" />
                    </button>
    
                    <NSharingPopup :item="challenge" :query="{ winners: 'picked' }" ref="sharingCatalystPopup" />
                </div>
            </div>
    
            <QuestionIdeaTable
                :ideas="computedIdeas.picked"
                :challenge="challenge"
                :loading="loading"
                empty-message="This Catalyst did not choose any Top 3 picks"
                class="mb-8"
                show-position
            /> -->
    
            <div class="flex flex-wrap items-center justify-between sm:flex-nowrap gap-3">
                <div>
                    <h3>Rated by the Community</h3>
                </div>
                <div :class="{ disabled: !computedIdeas.community.length }" class="mb-4">
                    <button aria-label="button" :class="{ 'shadow-active': computedIdeas.community.length }" type="button" class="relative flex items-center whitespace-nowrap font-medium px-1 py-1 leading-none rounded-md border border-nerve text-nerve bg-white shadow-active hover:bg-dust-100" @click="() => $refs.sharingCommuniyPopup.open()">
                        <NIcon as="lightbulb-o1" class="mr-1" />
                        Share Ideas
                        <NIcon as="forward" class="absolute -right-3 filter drop-shadow -top-3 text-xl" />
                    </button>
    
                    <NSharingPopup :item="challenge" :query="{ winners: 'community' }" ref="sharingCommuniyPopup" />
                </div>
            </div>
    
            <QuestionIdeaTable
                :ideas="computedIdeas.community"
                :challenge="challenge"
                :loading="loading"
                class="mb-8"
                show-prize-money
                show-position
                star-medal
            />
    
            <template v-if="showInsufficientScore">
                <h3 class="mt-5">These ideas received insufficient ratings</h3>
                <p>These ideas are still ranked but their crowd rating scores lack statistical validity.</p>
    
                <QuestionIdeaTable
                    :ideas="computedIdeas.insufficient_score"
                    :challenge="challenge"
                    class="mb-4"
                />
            </template>
        </div>
    </template>
    