import { api } from '@/modules/services';
import { useQuery } from '@tanstack/vue-query';

export const TYPE = {
    CHALLENGE: 'CHALLENGE',
    OLYMPIAD: 'OLYMPIAD',
    USER: 'USER',
    IDEA: 'IDEA',
}

export const useStats = (context, target, options = {}) => {
    return useQuery({
        queryKey: ['stats', context, target],
        queryFn: async () => {
            const { data: response } = await api.stats.context(context.value, target.value, {
                page: 0, size: -1
            });

            const statsMapped = response.data.reduce((acc, stat) => {
                switch (stat.owner_type) {
                    case TYPE.CHALLENGE:
                        acc.challenge[stat.stats_type] = stat.value;
                        break;
                    case TYPE.IDEA:
                        acc.idea[stat.stats_type] = stat.value;
                        break;
                    case TYPE.USER:
                        acc.user[stat.stats_type] = stat.value;
                        break;
                }

                return acc;
            }, { challenge: {}, user: {}, idea: {} });

            return statsMapped;
        },
        ...options,
    });
};