<script>
    import Validatable from '@/mixins/Validatable';
    import { cn } from '@/utils/Helpers';

    export default {
        mixins: [Validatable],

        props: {
            modelValue: {
                type: [String, Number, Boolean],
                default: ''
            },
            class: {
                type: [String, Array, Object],
                default: ''
            }
        },

        emits: ['update:modelValue', 'change'],

        data() {
            return {
                busy: this.form.busy
            };
        },

        methods: {
            cn,
            update(value) {
                this.$emit('update:modelValue', value);
                this.$emit('change', value);
            }
        },
    }
</script>

<template>
    <div :class="cn(invalid && 'is-error', $attrs.class)">
        <span v-if="label" class="form-group__label" style="display: block;">{{ label }}</span>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <slot></slot>
        <span v-if="invalid" class="form-group__error">{{ error }}</span>
    </div>
</template>
