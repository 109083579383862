<script>
    export default {
        name: 'NAlert',

        props: {
            closable: {
                type: Boolean,
                default: false
            },
            open: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'success'
            },
            duration: {
                type: Number,
                default: 1000 * 10
            },
            dense: {
                type: Boolean,
                default: false
            },
        },

        emits: ['update:open'],

        watch: {
            open(opened) {
                this.isOpen = opened;

                if (this.closable && opened) {
                    this.timeoutId = setTimeout(() => {
                        this.isOpen = false;
                        this.$emit('update:open', false)
                    }, this.duration)
                }

                if (this.closable && !opened) {
                    this.timeoutId && clearTimeout(this.timeoutId);
                }
            }
        },

        data() {
            return {
                isOpen: this.open,
                timeoutId : null,
            };
        },

        methods: {
            close() {
                this.isOpen = false;
            }
        },

        destroyed() {
            this.timeoutId && clearTimeout(this.timeoutId);
        }
    }
</script>

<template>
    <Transition name="fade">
        <div v-if="isOpen" class="alert" :class="{ 'py-1': dense, [`is-${type}`]: true }">
            <slot></slot>
            <button aria-label="button" v-if="closable" type="button" aria-hidden="true" class="alert__close-btn icon-times-circle-regular" @click="close"></button>
        </div>
    </Transition>
</template>
