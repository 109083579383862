<script>
    import Help from './Help.vue';
    import Step from './Step.vue';
    import Idea from './Idea.vue';
    import Media from './Media.vue';
    import Prize from './Prize.vue';
    import Share from './Share.vue';
    import Purpose from './Purpose.vue';
    import Stepper from './Stepper.vue';
    import Preview from './Preview.vue';
    import Publish from './Publish.vue';
    import Duration from './Duration.vue';
    import Overview from './Overview.vue';
    import Question from './Question.vue';
    import MediaArticle from './Article.vue';
    import Innovation from './Innovation.vue';
    import Categories from './Categories.vue';
    import Assumptions from './Assumptions.vue';
    import useChallengeCreateFlow from '@/mixins/ChallengeCreateFlow';

    export default {
        mixins: [useChallengeCreateFlow],

        components: {
            Help,
            Step,
            Idea,
            Prize,
            Share,
            Media,
            Purpose,
            Stepper,
            Preview,
            Publish,
            Duration,
            Overview,
            Question,
            Innovation,
            Categories,
            Assumptions,
            MediaArticle
        },

        computed: {
            stepper() {
                return this.$refs.stepper;
            }
        }
    }
</script>

<template>
    <stepper ref="stepper">
        <template #left>
            <div class="order-2 lg:order-1 col-span-12 sm:col-span-5 lg:col-span-4 xl:col-span-3 mt-5 lg:mt-0">
                <div class="challenge-section-heading is-center">
                    <p class="challenge-section-heading__title">
                        <i>Your Question Preview</i>
                    </p>
                    <p class="challenge-section-heading__description"><i>In progress</i></p>
                </div>
                <Preview :challenge="$parent.fields.challenge" />
            </div>
        </template>
        <step class="col-span-12" title="Category" :complete="completedCategory" :disabled="hasSlug" hide-actions>
            <categories
                v-model:categoryTree="$parent.$parent.categoryTree"
                v-model="$parent.fields.challenge.platform_category"
                @change="() => stepper.number(2)"
            ></categories>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Your Idea" :disabled="hasSlug" :complete="completedIdea" show-left>
            <idea></idea>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Purpose" :disabled="hasSlug" :complete="completedPurpose" show-left>
            <purpose></purpose>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Question &amp; Description" :disabled="hasSlug" :complete="completedDescription" show-left>
            <question></question>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Assumptions" :disabled="hasSlug || !completedDescription" :complete="completedAssumptions" show-left>
            <assumptions :challenge="$parent.fields.challenge" />
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Image" :disabled="hasSlug || !completedDescription" :complete="completedImage" show-left>
            <media></media>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Innovation Scale" :disabled="hasSlug || !completedDescription" :complete="completedInnovation" show-left>
            <innovation></innovation>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Article Source" :disabled="hasSlug || !completedDescription" :complete="completedArticle" show-left>
            <media-article></media-article>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Duration" :disabled="hasSlug || !completedDescription" :complete="completedDuration" show-left>
            <duration></duration>
        </step>
        <step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Money &amp; Prizes" :disabled="hasSlug || !completedDescription" :complete="completedPrize" show-left>
            <prize></prize>
        </step>
        <step class="col-span-12" title="Preview, Edit &amp; Publish" :disabled="hasSlug || !completedDescription" :complete="hasSlug" activator>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-8">
                    <publish></publish>
                </div>

                <div class="col-span-12 sm:col-span-4">
                    <overview></overview>
                </div>
            </div>
        </step>
        <step class="col-span-12" :disabled="!hasSlug" title="Share" hide-actions>
            <share></share>
        </step>
    </stepper>
</template>
