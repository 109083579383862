<script setup>
import FlexTable from '@/components/FlexTable.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import Button from '@/components/ui/button/index.vue';
import Loading from '@/components/NLoading.vue';
import { api } from '@/modules/services';
import { ref } from 'vue';
import { date as formatDate } from '@bignerve/ui-utils';
import { useAuth } from '@/composable/auth';

const emit = defineEmits(['published', 'change', 'previewd']);

const props = defineProps({
    channel: {
        type: Object,
        required: true,
    },
    platform: {
        type: Object,
        required: true,
    },
});

const { auth } = useAuth();
const readyToPublish = ref(false);
const forceSend = ref(false);
const publishing = ref(false);
const previewing = ref(false);
const totalInvitations = ref(null);

const handlePublish = async () => {
    try {
        publishing.value = true;
        totalInvitations.value = null;
        
        const { data: result } = await api.channel.invite(props.platform.slug, {
            source_type: 'member',
            source_id: props.channel.id,
            platform: props.platform.slug,
            scout_id: auth.user.handle || auth.user.screen_name,
            force_send: forceSend.value,
        });

        totalInvitations.value = result.data.length

        emit('published');
    } finally {
        publishing.value = false;
        readyToPublish.value = false;
        forceSend.value = false;
    }
}

const handlePreview = async () => {
    try {
        previewing.value = true;
        
        const { data } = await api.channel.previewInvite(props.platform.slug, {
            channel: props.channel.id,
            platform: props.platform.id,
        });
    } finally {
        previewing.value = false;
    }
}
</script>

<template>
    <div class="grid gap-4">
        <h2 class="font-bold">Compose your Invite email and PUBLISH</h2>

        <input v-model="channel.invitation_data.subject" name="invitation-subject" id="invitation-subject" placeholder="Enter your Subject line , max 180 max characters" class="placeholder:italic border border-dust-300 rounded-md px-4 py-2" @blur="emit('change', channel)">
        <TextEditor
            v-model="channel.invitation_data.message"
            name="invitation-message" id="invitation-message"
            maxlength="2000"
            :autofocus="false"
            :toolbar="false"
            :placeholder="'Enter your Message line ,max 2000 max characters'"
            @blur="emit('change', channel)"
            required
        />

        <div>
            <b>Notes:</b>
            <ul class="pl-4 m-0 text-dust-800">
                <li>The <i>From</i> will be <a href="mailto:notifications@bignerve.com">notifications@bignerve.com</a></li>
                <li>The <i>Reply-to</i>:  will be your email: <input v-model="channel.invitation_data.reply_to" class="px-4 py-1 ml-1 rounded-md border" @blur="emit('change', channel)" /></li>
                <li>If you <b>republish</b> this email, only users who did <b>not</b> yet create an account   will receive another email.</li>
            </ul>
        </div>

        <div class="grid gap-4">
            <div class="flex gap-4 items-center">
                <Button variant="inverted" class="min-w-[8rem] gap-2" :disabled="previewing" @click="handlePreview">
                    <Loading v-if="previewing" class="text-white" active />
                    Preview
                </Button>
                <span>Email will be sent to: <i class="font-bold">{{ auth?.user?.email }}</i>.</span>
            </div>
            <div class="flex gap-4 items-start">
                <Button variant="primary" class="min-w-[8rem] gap-2" :disabled="!readyToPublish || publishing" @click="handlePublish">
                    <Loading v-if="publishing" class="text-white" active />
                    {{ forceSend ? 'Resend Invites' : 'Publish' }}
                </Button>
                <div class="grid gap-2 mt-1">
                    <label>
                        <input v-model="readyToPublish" type="checkbox" />
                        Check this box to confirm.
                    </label>

                    <label class="flex gap-1 items-start">
                        <input v-model="forceSend" type="checkbox" class="mt-1" />
                        <span>Check this box to <b>re-invite</b> people who have not joined yet.<br />
                            <i>Customize your message above</i>
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div v-if="totalInvitations !== null" class="text-nerve">
            <p v-if="totalInvitations > 0" class="m-0 font-bold">
                RESULTS: {{ totalInvitations }} invitations sent.
            </p>
            <p v-else class="m-0 font-bold">
                RESULTS: No invitations sent.
            </p>
        </div>
    </div>
</template>