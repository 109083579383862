<template>
    <div class="sharing-screen">
        <div class="sharing-heading">
            <h1 class="sharing-heading__title">
                Sharing Center: <span class="sharing-heading__title-helper">5 Questions / Your Tournament / Closed Challenges</span>
            </h1>
        </div>
        <NTabs class="is-secondary">
            <NTab title="Share Challenges" icon="share-square-solid">
                <div class="tabs__content-helper">
                    <p class="form-block-help-text"><span class="is-color-red"><i>[Catalyst]</i> Your challenge is now published!</span> Catalyst/Director, share your newly created/renerved Challenge with your friends, colleagues, and followers. You earn Scout game points for sharing and recruiting the most innovative minds. You also earn Olympiad point, too. And, you get more creative ideas! <span class="is-color-scout">[Scout] Share challenges with the best minds you know to earn neurons from their success.</span></p>
                </div>
            </NTab>
            <NTab title="Share Ideas (Manual)" icon="share-square-solid"></NTab>
            <NTab title="Auto-Sharing Queue" icon="paper-plane-regular"></NTab>
            <NTab title="History Log" icon="paper-plane-solid"></NTab>
        </NTabs>
    </div>
</template>
