<script>
import PlayerDetails from '@/components/game/players/Details.vue'
import ChallengeTitle from '@/components/challenge/Title.vue';
import ChallengeDetails from '@/components/challenge/Details.vue';
import ChallengeImage from '@/components/challenge/Image.vue';
import ReportContent from '@/components/ReportContent.vue';
import Assumptions from '@/components/challenge-show/Assumptions.vue';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';
import { currency, date } from '@bignerve/ui-utils';
import { excerpt } from '@/utils/Helpers';

export default {
    mixins: [useChallengeDetails()],

    props: {
        challenge: Object,
    },

    components: {
        Assumptions,
        PlayerDetails,
        ChallengeTitle,
        ChallengeDetails,
        ChallengeImage,
        ReportContent,
    },

    methods: {
        date,
        currency,
        excerpt,
    },
}
</script>

<template>
    <div class="rating-card">
        <div class="rating-card__body">
            <div class="flex flex-col sm:w-[25rem]">
                <ChallengeImage
                    :challenge="challenge"
                    :credits="true"
                    class="cursor-pointer relative w-full"
                />

                <div v-if="challenge.article" class="border-r border-b">
                    <div class="px-4 py-2 border-b border-t bg-director-100 text-nerve flex items-center leading-none">
                        <NIcon as="article" class="text-lg mr-2" />
                        <span class="italic text-sm">Linked Article</span>
                    </div>
                    <div class="p-4 bg-director-50">
                        <h3 class="text-lg text-dust-800">{{ challenge.article.title }}</h3>
                        <p v-if="challenge.article.description" class="text-sm text-dust-600">
                            {{ excerpt(challenge.article.description, 265) }}
                        </p>
                        <div>
                            <a :href="challenge.article.url" class="text-sm font-bold text-dust-600" target="_blank" rel="noopener noreferrer">
                                <NIcon as="link-solid" class="mr-2" />
                                <span class="underline">Read the full story</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full p-4">
                <div class="flex flex-col lg:flex-row w-full">
                    <div class="flex-grow mb-3">
                        <ChallengeTitle :challenge="challenge" tag="h1" class="text-xl font-bold text-dust-800 mb-3" />
                        <ChallengeDetails :challenge="challenge" class="bg-dust-100 p-4" />
                        <Assumptions v-if="challenge.id" :challenge="challenge" class="border-t">
                            <template #actions>
                                <ReportContent
                                    v-if="$auth.authenticated"
                                    :content-id="challenge.id"
                                    :reasons="['Spam', 'Obscene', 'Offensive', 'Duplicate', 'Survey Question', 'AI Slop']"
                                    menu-title="Report challenge"
                                    content-type="challenge"
                                    class="mr-2 px-4 py-3 sm:p-0"
                                />
                                <button aria-label="button" class="hidden flex items-center border px-4 py-2 text-dust-700 hover:bg-dust-200 hover:text-dust-800 rounded-md leading-tight sm:border-none sm:px-2 sm:-mx-2 sm:py-1">
                                    <NIcon as="bookmark-regular" class="text-nerve mr-2" />
                                    Save for Later
                                </button>
                            </template>
                        </Assumptions>
                    </div>
                    <div v-if="challenge.catalyst" class="flex items-start min-w-[13rem] p-4">
                        <NPlayerDetails :user="challenge.catalyst" />
                    </div>
                </div>
            </div>
        </div>
        <div class="catalyst-card__footer">
            <div class="catalyst-card__footer-helper">
                <div class="catalyst-card__footer-meta">
                    <span v-if="challenge.stats" class="catalyst-card__footer-meta-item">
                        <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                        Ideas ({{ challenge.stats.total_ideas }})
                    </span>
                    <span v-if="challenge.prize_money" :class="{ disabled: !currency(challenge.prize_money) }" class="catalyst-card__footer-meta-item">
                        <span aria-hidden="true" class="icon icon-money-bill-alt-regular"></span>
                        ${{ currency(challenge.prize_money) }}
                    </span>
                    <span v-if="challenge.prize_type" class="catalyst-card__footer-meta-item">
                        <span aria-hidden="true" class="icon icon-trophy-solid"></span>
                        {{ $t(`challenges.prize-type.${challenge.prize_type}`) }}
                    </span>
                    <span class="catalyst-card__footer-meta-item" @click="() => $refs.popup.open()">
                        <span aria-hidden="true" class="icon icon-share-square-solid"></span>
                    </span>
                    <NSharingPopup ref="popup" :item="challenge"></NSharingPopup>
                </div>
            </div>
            <NPlayers
                class="m-0"
                :participants="challenge.participants"
                :anonymous="9"
                friends
            />
            <span class="catalyst-card__footer-meta-item">
                <b class="mr-1">Challenge closes</b> {{ date(challenge.duration_end, 'human') }}
            </span>
        </div>
    </div>
</template>
