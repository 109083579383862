<script>
import ChallengeProvider from '@/components/providers/ChallengeProvider.vue';
import { get } from 'lodash';
import { isAMA } from '@/utils/challenge';
import { oldApi as nerve } from '@/modules/services';
import { isDifferentOrigin, surveyModeRedirection } from '@/utils/Route';
import { useChallengeDetails } from '@/mixins/ChallengeDetails.js';
import { Session } from '@/utils/Auth';
import { useFeatureFlag } from '@/composable/feature-flag';

export default {
    name: 'ChallengeWrapper',

    data () {
        return {
            challenge: null,
        };
    },

    components: {
        ChallengeProvider,
    },

    mixins: [useChallengeDetails()],
    
    async beforeRouteEnter ({ params, fullPath }, _, next) {
        try {
            const { data: response } = await nerve.challenges.get(params.challenge);
            const { data: challenge } = response;

            if (isAMA(challenge)) {
                next({
                    name: 'suggestion-box.ama',
                    params: { user: challenge.catalyst.handle },
                });

                return;
            }

            next((vm) => {
                vm.challenge = challenge;
            });
        } catch (err) {
            console.log(err)

            if (err.response && err.response.status === 401) {
                const continueURL = encodeURIComponent(`${location.origin}${fullPath}`);
                const redirect = `${accountURL}/gateway?continue=${continueURL}`;

                location.replace(redirect)

                next('/')
                
                return 
            }

            let feedback = `\n\nchallenge: ${params.challenge};\n`;
            const errors = get(err, 'response.data', []);

            if (errors.length) {
                feedback += `${errors.map(c => c.error).join(';\n')}`;
            }

            next({
                name: 'send-feedback',
                query: { about: 'Challenge - Rating Flow', feedback }
            });
        }
    },

    metaInfo () {
        return {
            title: this.challenge ? this.questionTitle : 'Challenge',
        }
    },

    methods: {
        async fetchCurrentUser () {
            if (this.challenge && this.challenge.catalyst) {
                this.$store.dispatch('app/user', {
                    user: this.challenge.catalyst.handle,
                });
            }
        },
    },

    mounted () {
        const hasBack = get(this.$router, 'options.history.state.back', false);

        // If the user is not logged in, and the challenge is not from the same origin,
        // and the user is not coming back from the survey mode, and the challenge is not an AMA,
        // then redirect to the survey mode
        const canRedirect = !Session.exists()
            && isDifferentOrigin()
            && !hasBack
            && !this.isAMA
            && !this.challenge.is_closed
            && !this.challenge.idea_entry_fee;
        if (canRedirect) {
            const userId = get(this.challenge, 'catalyst.id', 'none');
            
            if (this.isEnabledFor(userId)) {
                surveyModeRedirection(this.$route);
            }
        }
    },

    setup () {
        const { variantTo } = useFeatureFlag('survey-mode-link');

        const isEnabledFor = (userId) => {
            const variant = variantTo(userId);

            return get(variant.value, 'payload.auto_survey')
        }

        return {
            isEnabledFor,
        }
    },

    updated () {
        this.fetchCurrentUser();
    },
};
</script>

<template>
    <ChallengeProvider :challenge="challenge">
        <RouterView v-if="challenge" :challenge="challenge" />
    </ChallengeProvider>
</template>
