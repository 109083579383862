import { isRef, computed, shallowRef, toValue, inject } from 'vue';
import { useAuth } from '@/composable/auth';
import { isCaption, isSuggestion as handleIsSuggestion, isAMA as isAMAHelper, isQQ } from '@/utils/challenge.js';
import { useI18n } from 'vue-i18n';
import { gameSetting } from '@/utils/challenge';
import { reactify } from '@vueuse/core';

const APP_BASE_URL = import.meta.env.VUE_APP_BASE_URL;
const APP_FUNC_URL = import.meta.env.VUE_APP_FUNC_URL;

export const categoriesWithRestrictWords = [
    'entertainment-4bc2525b',
    'messaging-25b464dd',
    'science-technology-40f1b28c',
]

export const useDetails = (target) => {
    const challenge = isRef(target) ? target : shallowRef(target);
    const { auth } = useAuth();
    const { t } = useI18n();

    const isSuggestion = computed(() => !!challenge.value && handleIsSuggestion(challenge.value));
    const isAMA = computed(() => !!challenge.value && isAMAHelper(challenge.value));

    const questionTitle = computed(() => {
        if (!challenge.value) return '';

        const catalyst = challenge.value.catalyst || {};

        if (isSuggestion.value) {
            return t(
                `component.challenge.solution-format.${challenge.value.solution_format}.title`,
                { catalyst_name: `${catalyst.first_name} ${catalyst.last_name}` }
            );
        }

        return challenge.value.question_title;
    });

    const questionDetails = computed(() => {
        if (!challenge.value) return '';

        const catalyst = challenge.value.catalyst || {};

        if (isSuggestion.value) {
            return t(
                `component.challenge.solution-format.${challenge.value.solution_format}.description`,
                { catalyst_name: `${catalyst.first_name} ${catalyst.last_name}` }
            );
        }

        return challenge.value.question_detail;
    });


    const isClosed = computed(() => challenge.value && (
        challenge.value.is_closed
        || challenge.value.state === 'closed'
        || moment().isAfter(challenge.value.duration_end)
    ));

    const image = computed(() => {
        if (!challenge.value) {
            return '';
        }

        if (isAMA.value && challenge.value?.catalyst) {
            return `${APP_FUNC_URL}/og-image/bignerve/sb-ama/${challenge.value.catalyst.handle}?v4`;
        }

        if (isSuggestion.value) {
            return `${APP_BASE_URL}/img/suggestion-box/${challenge.value.solution_format}.png`;
        }

        return challenge.value.primary_image;
    });

    const coverImage = computed(() => {
        const src = toValue(image.value);

        if (isSuggestion.value) {
            return src;
        }

        return src;
    });

    const previewImage = computed(() => {
        const src = toValue(image.value);

        if (isSuggestion.value) {
            return src;
        }

        return src;
    });

    const thumbnailImage = computed(() => {
        const src = toValue(image.value);

        if (isSuggestion.value) {
            return src;
        }

        return src?.replace('/preview/images/', '/thumbnail/');
    });

    const imageCredits = computed(() => {
        if (!challenge.value.primary_image) {
            return;
        }

        const url = new URL(challenge.value.primary_image);

        return url.searchParams.has('s')
            && {
                source: url.searchParams.get('s'),
                user: url.searchParams.get('u'),
                link: url.searchParams.get('l'),
            };
    });

    return {
        isCaption: computed(() => isCaption(challenge.value)),
        isQQ: computed(() => isQQ(challenge.value)),
        isOwner: computed(() => challenge.value.catalyst_id === auth.user.id),
        isAMA,
        isClosed,
        isSuggestion,
        questionTitle,
        questionDetails,
        image,
        coverImage,
        previewImage,
        thumbnailImage,
        imageCredits,
    }
}

export const useChallengeContext = () => {
    const data = inject('challengeContext');

    return data;
}

export const useChallengeSetting = (target) => {
    const from = reactify(gameSetting)
    const setting = (key, defaultValue) => from(target, key, defaultValue);

    return {
        setting,
    }
}
