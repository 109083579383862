/** @module attachments */
export default requester => {
    return {
        /**
         * Scrape an article.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request.
         */
        scrapeArticle(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/scrape-article', {}, params, headers);
        },

        /**
         * Scrape the image.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request.
         */
        scrapeImage(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/scrape-image', {}, params, headers);
        },

        /**
         * Scrape the image.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request.
         */
        uploadImage(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/files/image', data, params, headers);
        }
    }
}
