<script setup>
import { useVModel } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    defaultValue: Boolean,
    disabled: Boolean,
    closeOutside: {
        type: Boolean,
        default: false
    }
});

const emits = defineEmits(['update:modelValue']);

const active = useVModel(props, 'modelValue', emits, { defaultValue: props.defaultValue, passive: true });

const close = () => {
    if (props.closeOutside) {
        active.value = false;
    }
}

const toggle = () => {
    active.value = !active.value;
}

const open = () => {
    active.value = true;
}

defineExpose({
    active,
    close,
    toggle,
    open,
});
</script>

<script>
export default {
    name: 'NExpansionPanel',
};
</script>

<template>
    <div v-click-outside="{ handler: close }" :class="{ disabled }">
        <slot name="header" :on="{ click: toggle }" :toggle="toggle" :active="active" />
        <slot v-if="active"  />
        <slot name="footer" :on="{ click: toggle }" :toggle="toggle" :active="active" />
    </div>
</template>
