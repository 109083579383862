import { useInfiniteQuery, useQuery } from '@tanstack/vue-query';
import { api } from '@/modules/services';
import { computed, toValue } from 'vue';

export const useBrain = (handle, params) => {
    const enabled = computed(() => !!toValue(handle));

    return useQuery({
        queryKey: ['gamedash', 'brain', handle, params],
        queryFn: async () => {
            const { data: response } = await api.brain.neurons(
                toValue(handle), { ...toValue(params), size: 1 },
            );

            const brain = {
                ...response.relationships,
                total_notifications: response.meta.total,
            }

            return brain;
        },
        // refetchInterval: 1000 * 60, // 1 minute
        enabled,
    });
}

export const useNeurons = (handle, params = {}) => {
    const enabled = computed(() => !!toValue(handle));

    const { data, ...passthrough } = useInfiniteQuery({
        queryKey: ['gamedash', 'neurons', handle, params],
        queryFn: async ({ pageParam }) => {
            const { data: response } = await api.brain.neurons(
                toValue(handle), { size: 20, page: pageParam, ...toValue(params) },
            );

            return response;
        },
        getNextPageParam: (lastResult) => {
            const { page, total } = lastResult.meta;

            if ((page + 1) * toValue(params).size >= total) {
                return undefined;
            }

            return page + 1;
        },
        initialPageParam: 0,
        enabled,
    });

     // reduce data to a single array
     const items = computed(() => data.value?.pages.reduce((acc, page) => {
        return acc.concat(page.data);
    }, []) || []);

    const meta = computed(() => page.value?.meta || {});
    const relationships = computed(() => page.value?.relationships || {});

    return {
        ...passthrough,
        data: items,
        meta,
        relationships,
    };
}

