import { useQuery } from '@tanstack/vue-query';
import { api } from '@/modules/services';

export const useFeatureFlag = () => {
    return useQuery({
        queryKey: ['app', 'feature-flag'],
        queryFn: async () => {
            const { data: response } = await api.flags();
            
            return response.data;
        },
        
    });
}