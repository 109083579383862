<script setup>
import { usePreferences } from '@/composable/preferences';
import { usePlatform } from '@/composable/platform';
import { api } from '@/modules/services';

const { currentPlatform } = usePlatform();
const { sync, preferences } = usePreferences();

const allowTopic = sync('platform.forum.allow_topic_creation', false);

const updatePreferences = () => {
    api.platform.savePreferences(
        currentPlatform.value.id,
        preferences.platform,
    );
}
</script>

<template>
    <div class="page">
        <NPageHeader
            title="Platform Preferences"
            subtitle="Customize how community contributions are managed"
            icon="cog"
        />

        <div class="page__content mb-6">
            <h3 class="text-xl mb-4">Platform Settings</h3>
            <p class="mb-6">Customize how community contributions are managed</p>

            <NDataForm class="form-groups">
                <div class="form-group">
                    <!-- <label for="allow_topic_creation" class="form-group__label">Allow Topic Creation</label>
                    <div class="flex items-start justify-between space-x-8  w-full">
                        <p class="form-block-help-text">
                            Enable your community members to initiate new topics. This fosters a collaborative environment where diverse perspectives can emerge and creative solutions can be explored.
                        </p>

                        <NSwitch
                            v-model="allowTopic"
                            active-color="secondary" icon 
                            name="allow_topic_creation"
                            @change="updatePreferences"
                        /> 
                    </div>-->
                </div>
            </NDataForm>
        </div>
    </div>
</template>
