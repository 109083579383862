<script>
import QQCoAuthor from '@/components/challenge/QQCoAuthor.vue';
import ChallengeTitle from '@/components/challenge/Title.vue';
import ChallengeImage from '@/components/challenge/Image.vue';
import ReNerve from '@/components/challenge/ReNerve.vue';
import NTimeRemaining from '@/components/TimeRemaining.vue';
import NPlayers from '@/components/game/players/Participants.vue';
import get from 'lodash/get';
import { date, currency } from '@bignerve/ui-utils';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';
import { mapState } from 'vuex'

export default {
    name: 'OpenChallengeCard',
    
    mixins: [useChallengeDetails()],

    props: {
        challenge: {
            type: Object,
            required: true
        }
    },

    components: { ReNerve, QQCoAuthor, ChallengeTitle, ChallengeImage, NTimeRemaining, NPlayers },

    computed: {
        ...mapState('app/sharing', ['snoozeds']),
    },

    methods: {
        date,
        currency,

        snoozedToShare (target) {
            return this.snoozeds.find(s => get(s, 'payload.metadata.target') === target);
        },
    },
}
</script>

<template>
    <article class="challenge-card is-full-height">
        <ChallengeImage
            :challenge="challenge"
            :to="{ name: 'challenges.show', params: { challenge: challenge.slug } }"
        />

        <div class="challenge-card__body">
            <!-- <div class="hidden challenge-card__renerve-link-wrapper">
                <ReNerve :challenge="challenge" />
            </div> -->

            <NPlayerDetails
                v-if="challenge.catalyst"
                v-tooltip="'Author'"
                :user="challenge.catalyst"
                :clamp="false"
            />

            <router-link :to="{ name: 'challenges.show', params: { challenge: challenge.slug } }">
                <ChallengeTitle
                    :challenge="challenge"
                    class="font-bold text-lg leading-snug no-swiping text-dust-800"
                    tag="p"
                />
            </router-link>

            <QQCoAuthor :challenge="challenge" />

            <div class="py-2 !mt-auto">
                <NTimeRemaining :datetime="challenge.duration_end" />

                <NPlayers
                    class="my-2"
                    :participants="challenge.participants"
                    :anonymous="9"
                    friends
                />
            </div>
            <div class="challenge-card__footer !mt-0">
                <router-link
                    :to="{ name: 'challenges.show', params: { challenge: challenge.slug }, query: { tab: 'ideas' } }"
                    class="challenge-card__footer-item"
                >
                    <NIcon as="lightbulb-o1" left />
                    Ideas ({{ challenge.unlocked_ideas }})
                </router-link>
                <span :class="{ disabled: !currency(challenge.prize_money) }" class="challenge-card__footer-item leading-none">
                    <NIcon as="money-bill-alt-regular" class="text-lg" left />
                    ${{ currency(challenge.prize_money) }}
                </span>
                <span v-if="challenge.prize_type" class="challenge-card__footer-item">
                    <NIcon as="trophy-solid" left />
                    {{ $t(`challenges.prize-type.${challenge.prize_type}`) }}
                </span>
                <button aria-label="button" v-tooltip="'Share challenge'" class="w-8 h-8 rounded-md flex items-center justify-center hover:bg-dust-200" @click.prevent="$bus('sharing:popup', challenge)" :class="{ '!text-secondary bg-secondary-100': snoozedToShare(challenge.slug) }">
                    <NIcon as="share-square-solid" />
                </button>
            </div>
        </div>
    </article>
</template>
