<script>
import sampleData from '@/utils/sampleGameDashData';
import FormatsNotifications from '@/mixins/FormatsNotifications';
import NotificationHelpfulTip from './notification/HelpfulTip.vue';

export default {
    name: 'TipsHelpful',

    mixins: [FormatsNotifications],

    components: {
        NotificationHelpfulTip,
    },

    props: {
        role: String
    },

    data() {
        return {
            items: sampleData,
        }
    },


    watch: {
        tasks: {
            handler (items) {
                if (items.length) {
                    const n = Math.floor(Math.random() * items.length);
                    this.$bus('latest-notifications:new', {
                        type: 'helpful-tip',
                        detail: items[n],
                    })
                }
            },
            immediate: true,
        }
    },
}
</script>

<template>
    <div>
        <NotificationHelpfulTip v-for="(item, key) in tasks" :detail="item" :key="key" class="mb-2" />
        <p v-if="!tasks.length" class="p-4 text-dust text-center">
            {{ $t('common.empty') }}
        </p>
    </div>
</template>
