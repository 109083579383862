/** @module challenges */
export default requester => {
    return {
        /**
         * Gets an array of all valid challenge categories along with a link to the icon for that category.
         *
         * @param {String} platformId
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        categories(platformId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/${platformId}/categories`, params, headers);
        },

        /**
         * Gets an array of all valid challenge categories along with a link to the icon for that category.
         *
         * @param {String} platformId
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        allCategories(platformId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/${platformId}/categories/all`, params, headers);
        },

        /**
         * Gets a default category image for the given category.
          *
         * @param {string} category The category for which to return a default image url in the case of no challenge image
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        defaultCategoryImage(category, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/default-category-image', Object.assign({ category }, params), headers
            );
        },

        /**
         * Queries for challenges based on query parameters.
         *
         * @param {string} solvedStatus Whether to return only solved challenges, only open challenges, or both. One or multiple of (solved, open)
         * @param {string} orderBy The ordering of the returned challenges (currently only created_at, but will eventually support popularity and money)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        all(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/challenges', params, headers);
        },

        /**
         * Creates a challenge.
         *
         * @param {Object} data The challenge to create
         * @param {Object} params Any additional parameters. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/challenges', data, params, headers);
        },

        /**
         * Gets a set of challenges based on a list of tags/interests/causes.
         *
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        byInterests(start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/challenges/by-interests', Object.assign({ start, num }, params), headers
            );
        },

        /**
         * Queries for challenges based on query parameters.
         *
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        homepage(platformId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `${platformId}/challenges-homepage`, params, headers);
        },

        /**
         * Queries for suggestions challenges based on query parameters.
         *
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        suggestions(platformId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `${platformId}/suggestions-homepage`, params, headers);
        },

        /**
         * Queries for challenges based on query parameters.
         *
         * @param {string} solvedStatus Whether to return only solved challenges, only open challenges, or both. One or multiple of (solved, open)
         * @param {string} orderBy The ordering of the returned challenges (currently only created_at, but will eventually support popularity and money)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        homepageAuth(solvedStatus, orderBy, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/challenges/homepage/auth', Object.assign({ solvedStatus, orderBy, start, num }, params), headers
            );
        },

        /**
         * Appends complete user information to a supplied array of challenges.
         *
         * @param {Array} challengesArray A json body containing an array of challenge objects (the objects for which to add complete user information).
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        appendWithUsers(challengesArray, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/challenges/with-users', { challengesArray }, params, headers);
        },

        /**
         * Gets a challenge by its id.
         *
         * @param {(string|number)} challengeId ID of challenge to get
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        get(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}`, {}, params, headers);
        },

        /**
         * Updates a challenge.
         *
         * @param {(string|number)} challengeId ID of challenge to update
         * @param {Object} challenge Challenge object to overwrite the challenge with
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        update(challengeId, challenge, params = {}, headers = {}) {
            return requester.makeRequest('PUT', `/challenges/${challengeId}`, { challenge }, params, headers);
        },

        /**
         * Returns the sets of solutions required for the catalyst dashboard. This includes: solutions catalyst has rated (and the rating given),
         * solutions not by the catalyst which have not been rated by the catalyst yet, and all other solutions rated by others or by the catalyst with points and average ratings
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        catalystDashboard(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/catalyst-dashboard`, params, headers);
        },

        /**
         * Sets the catalyst's top 3 solution picks.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} solutionPicks The solution picks to create
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        catalystPicks(challengeId, solutionPicks, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/${challengeId}`, { solutionPicks }, params, headers);
        },

        /**
         * Insight system - Retrieve existing proposed assumptions for a challenge
         * while it is running (sorted by number of votes desc, catalyst desc, recent desc).
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {string} orderBy the ordering criterion - one of 'votes’, 'catalyst’, ‘recent’
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        assumptions(challengeId, orderBy, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', `/challenges/${challengeId}/assumptions`, Object.assign({ orderBy }, params), headers
            );
        },

        /**
         * Insight system - submit a new who what why independently (up to 3 of each).
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} assumptionChallenge The assumption challenge to create
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        addAssumption(challengeId, assumptionChallenge, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/challenges/${challengeId}/assumptions`, { assumptionChallenge }, params, headers
            );
        },

        /**
         * Closes a challenge.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        close(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/${challengeId}/close`, {}, params, headers);
        },

        /**
         * Gets the catalyst’s deep dive worksheet.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        deepDive(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/deep-dive`, params, headers);
        },

        /**
         * Sets the catalyst’s challenge deep dive.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} deepDive The deep dive to create
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        setDeepDive(challengeId, deepDive, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/challenges/${challengeId}/deep-dive`, { deepDive }, params, headers
            );
        },

        /**
         * Lets a catalyst mark an iteration as a favorite iteration.
         * This is the version of the solution that should be displayed on the contest page.
         * By default the original solution is displayed.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {string} iterationId The id of the iteration to favorite
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        setFavoriteIteration(challengeId, iterationId, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/challenges/${challengeId}/favorite-iteration`, {}, Object.assign({ iterationId }, params), headers
            );
        },

        /**
         * Gets all children of a challenge given a challengeId.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        children(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/get-children`, params, headers);
        },

        /**
         * Creates a new iteration.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} iteration The iteration to create
         * @param {string} originalSolutionId The id of the original solution
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        iterate(challengeId, iteration, originalSolutionId, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/challenges/${challengeId}/iterate`, { iteration }, Object.assign({ originalSolutionId }, params), headers
            );
        },

        /**
         * Creates a new iteration draft.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} iteration The iteration to create
         * @param {string} originalSolutionId The id of the original solution
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        draftIteration(challengeId, iteration, originalSolutionId, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/challenges/${challengeId}/iterate-draft`, { iteration }, Object.assign({ originalSolutionId }, params), headers
            );
        },

        /**
         * Retrieves iteration drafts.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {string} originalSolutionId The id of the original solution
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        iterationDrafts(challengeId, originalSolutionId, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', `/challenges/${challengeId}/iteration-drafts`, Object.assign({ originalSolutionId }, params), headers
            );
        },

        /**
         * Retrieves iterations.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {string} originalSolutionId The id of the original solution
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        iterations(challengeId, originalSolutionId, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', `/challenges/${challengeId}/iterations`, Object.assign({ originalSolutionId }, params), headers
            );
        },

        /**
         * Returns true if the authorized user has any pending requests for iterations on the specified challenge, false otherwise.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        hasPendingIterationRequests(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/iterations/has-pending`, params, headers);
        },

        /**
         * Sends a request for the solver to iterate on their solution.
         * The challenge owner must have already rated & commented on the solution before this is allowed.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {string} originalSolutionId The id of the original solution
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        requestIteration(challengeId, originalSolutionId, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/challenges/${challengeId}/iterations/request`, {}, Object.assign({ originalSolutionId }, params), headers
            );
        },

        /**
         * Allows a solver to accept or decline a request to iterate on a solution.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {string} response Accept or decline
         * @param {string} solutionId The id of the original solution
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        respondToIterationRequest(challengeId, response, solutionId, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/challenges/${challengeId}/iterations/request`, {}, Object.assign({ solutionId, response }, params), headers
            );
        },

        /**
         * Insight system - retrieve user’s own proposed assumptions.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        myAssumptions(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/my-challenged-assumptions`, params, headers);
        },

        /**
         * Publishes a challenge. For recurring contests, this will create new copies of the parent template.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        publish(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/${challengeId}/publish`, {}, params, headers);
        },

        /**
         * Returns 5 random solution titles and descriptions (all other data omitted) for the wordpress blog widget.
         * This is a publicly accessible, non-authenticated API so I am trying to limit the amount of visible data.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        randomBlogWidgetSolutions(challengeId, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', `/challenges/${challengeId}/random-blog-widget-solutions`, params, headers
            );
        },

        /**
         * Renerves a challenge.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        renerve(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/${challengeId}/renerve`, {}, params, headers);
        },

        /**
         * Gets the number of ratings required for the next solution to unlock within this challenge.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        requiredRatings(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/required-ratings`, params, headers);
        },

        /**
         * Insight system - Allow a catalyst to select a new set of assumptions [1 who, 1 what, 1 why],
         * given the ids - (closes challenge too).
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        selectAssumptions(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/${challengeId}/renerve`, {}, params, headers);
        },

        /**
         * Gets the catalyst’s solution picks.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        solutionPicks(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/solution-picks`, params, headers);
        },

        /**
         * Gets a solution to rate for the rating workflow.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        ideaToRate(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/solution-to-rate`, params, headers);
        },

        /**
         * Creates a new solution.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} solution The solution to create
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        solve(challengeId, solution, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/${challengeId}/solve`, { solution }, params, headers);
        },

        /**
         * Enters the authenticated user into the rating flow within this challenge.
         * They can no longer post solutions within this challenge.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        startRating(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/${challengeId}/start-rating`, {}, params, headers);
        },

        /**
         * Enters the authenticated user into the rating flow within this challenge.
         * They can no longer post solutions within this challenge.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        getRatingFlow(...args) {
            return this.startRating(...args);
        },

        /**
         * Un-Renerves a challenge.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        unRenerve(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/challenges/${challengeId}/unrenerve`, {}, params, headers);
        },

        /**
         * Queries for challenge drafts belonging to the user.
         *
         * @param {string} orderBy The ordering of the returned challenges (currently only created_at, but will eventually support popularity and money)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        drafts(orderBy, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', `/challenges/drafts`, Object.assign({ orderBy, start, num }, params), headers
            );
        },

        /**
         * Creates a challenge draft.
         *
         * @param {Object} challenge A challenge object (the object which is to be created)
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        createDraft(challenge, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/challenges/drafts`, { challenge }, params, headers);
        },

        /**
         * Provides a rudimentary healthcheck system for the challenge API.
         *
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        health(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/challenges/health', {}, params, headers);
        },

        /**
         * Get the challenge ideas.
         *
         * @param {string} challengeId The ID of the relevant challenge
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request.
         */
        ideas(challengeId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/challenges/${challengeId}/ideas`, params, headers);
        },
    }
}
