<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: null
        }
    },
    
    emits: ['update:modelValue'],

    methods: {
        changeType(type) {
            this.$emit('update:modelValue', type);
        }
    }
}
</script>

<template>
    <div class="flex flex-col items-center">
        <h2 class="category-section-title w-full">
        	Or, try these Innervation Tools
        </h2>
        <div class="grid grid-cols-2 gap-4 mb-5 sm:grid-cols-3 sm:w-4/5">
            <button aria-label="button" type="button" class="category-tile is-in-grid is-innervation-blue transition duration-500" @click.prevent="changeType('test-an-idea')">
                <img src="/img/category-icons/test-idea-category-icon.svg" alt="" class="category-tile__icon">
                <span class="category-tile__name">Test my Idea</span>
            </button>
            <button aria-label="button" type="button" class="category-tile is-in-grid is-innervation-blue transition duration-500" @click.prevent="changeType('improve-an-idea')">
                <img src="/img/category-icons/improve-category-icon.svg" alt="" class="category-tile__icon">
                <span class="category-tile__name">Improve my Idea</span>
            </button>
            <button aria-label="button" type="button" class="category-tile is-in-grid is-innervation-blue transition duration-500">
                <img src="/img/misc-ui-icons/save-idea-icon.svg" alt="" class="category-tile__icon">
                <span class="category-tile__name">Save my Idea</span>
            </button>
            <button aria-label="button" type="button" class="category-tile is-in-grid is-innervation-blue transition duration-500" @click.prevent="changeType('qq')">
                <img src="/img/category-icons/qq-question-question-category-icon.svg" alt="" class="category-tile__icon">
                <span class="category-tile__name">QQ: Question for a Question</span>
            </button>
            <button aria-label="button" type="button" class="category-tile is-in-grid is-innervation-blue transition duration-500">
                <img src="/img/category-icons/simplify-icon.svg" alt="" class="category-tile__icon">
                <span class="category-tile__name">Simplify a Problem</span>
            </button>
            <button aria-label="button" type="button" class="category-tile is-in-grid is-innervation-blue transition duration-500">
                <img src="/img/category-icons/find-a-use-icon.svg" alt="" class="category-tile__icon">
                <span class="category-tile__name">Find a Use for my Idea</span>
            </button>
        </div>
    </div>
</template>
