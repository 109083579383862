import { CHALLENGE_FORMAT, SOLUTION_FORMAT } from '../constant/challenge';
import { useAuth } from '@/composable/auth';
import { notyf } from '@/composable/notyf';
import { defu } from 'defu';
import { get } from 'lodash';

const defaultGameSettings = {
	max_user_ideas: 3,
};

export const stripHtml = (html) => String(html).replace(/<[^>]*>?/gm, '');

export const gameSetting = (challenge, key, defaultValue) => {
	return get(defu(challenge.stats, challenge.game, defaultGameSettings), key, defaultValue);
};

export const hasQuestionDetail = (challenge) => {
	return !!stripHtml(challenge.question_detail).trim();
};

export const isCaption = (challenge) => {
	const categories = ['Funny', 'Inspiring', 'Insightful', 'Shocking'];

	return challenge.caption_solution_format === 'caption' || categories.includes(challenge.main_category);
};

export const isSuggestion = (challenge) => {
	return challenge.challenge_format === CHALLENGE_FORMAT.SUGGESTION;
};

export const isQQ = (challenge) => {
	return challenge.solution_format === SOLUTION_FORMAT.QQ;
};

export const isAMA = (challenge) => {
	return challenge.solution_format === SOLUTION_FORMAT.AMA;
};

export const isTopic = (challenge) => {
	return challenge.solution_format === SOLUTION_FORMAT.TOPIC;
};

export const resolveCoverImage = (challenge) => {
	return isSuggestion(challenge)
		? `${import.meta.env.VUE_APP_BASE_URL}/img/suggestion-box/${challenge.solution_format}.png`
		: challenge.primary_image;
};

export const resolvePreviewImage = (challenge) => {
	return isQQ ? challenge.primary_image : challenge.primary_image && challenge.primary_image.replace('/images/', '/preview/images/');
};

export const resolveQuestionDetails = (challenge, fallback = '') => {
	if (!hasQuestionDetail(challenge) && !isCaption(challenge)) {
		return fallback;
	}

	return challenge.question_detail;
};

export const assertOwner = (challenge) => {
	const { auth } = useAuth();

	if (auth.user.id !== challenge.catalyst_id) {
		notyf.error({
			message: 'Current user is not the owner of this challenge. Redirecting to dashboard...',
			duration: 5000,
			position: {
				x: 'center',
				y: 'bottom',
			},
		});

		throw new Error('User is not the owner of this challenge');
	}

	return true;
};
