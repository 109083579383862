<script>
import NCropper from '@/components/form/Cropper.vue';
import NPlayerDetails from '@/components/game/players/Details.vue';
import upperFirst from 'lodash/upperFirst';
import debounce from 'lodash/debounce';
import { useAuth } from '@/composable/auth';
import { usePlatform } from '@/queries/platform';
import { computed } from 'vue';

export default {
    components: {
        NCropper,
        NPlayerDetails,
    },

    data () {
        return {
            oldHandle: null,
            newHandle: null,
            checkingHandle: false,
            availbeHandle: null,
            successMessage: false,
            errorMessage: false,
            croppingAvatar: false,
        }
    },

    computed: {
        fields() {
            return this.$store.getters['auth/currentUser'].user;
        },
        handleIndicator () {
            switch (this.availbeHandle) {
                case true:
                    return 'check-solid';
                case false:
                    return 'remove';
                default:
                    return null;
            }
        },
        previewElement () {
            return this.$refs.previewEl;
        },
    },

    watch : {
        fields: {
            handler ({ screen_name } = {}) {
                this.newHandle = screen_name;
            },
            immediate: true,
        }
    },

    methods: {
        upperFirst,
        update(profile) {
            let user = this.$store.getters['auth/currentUser'];
            this.successMessage = true;
            this.errorMessage = false;
            this.availbeHandle = null;
            this.oldHandle = null;
            this.$store.commit('auth/setCurrentUser', { ...user, profile });

            this.refetch();
            this.updateOgImageCache(profile);
        },
        updateOgImageCache (profile) {
            const img = new Image();

            img.src = `https://func.bignerve.com/og-image/${profile.masthead.handle}.png?refresh=true&v=${Date.now()}`;
        },
        fetchHandle (value) {
            this.availbeHandle = null;
            this.checkingHandle = true;
            this.$nerve.users.handlers({ handle: value }).then(({ data }) => {
                this.availbeHandle = data.availability;

                if (this.availbeHandle) {
                    this.oldHandle = this.fields.screen_name;
                }
            })
            .catch(() => {
                this.availbeHandle = false;
            })
            .finally(() => {
                this.checkingHandle = false;
            })
        },
        checkHandle: debounce(function (value) {
            if (value === this.$auth.profile.masthead.handle) {
                this.availbeHandle = true;
                return;
            }

            this.fetchHandle(value);
        }, 500),
        fail () {
            this.errorMessage = true;

            if (this.oldHandle) {
                this.availbeHandle = null;
                this.newHandle = this.oldHandle;
                this.fields.screen_name = this.oldHandle;
                this.oldHandle = null;
            }
        },
        async beforeSave () {
            await this.$refs.mastheadCropper.cropAndUpload();

            console.log('uploaded')

            this.fields.screen_name = this.newHandle;

            return true;
        },
    },

    setup () {
        const { auth } = useAuth();
        const slug = computed(() => auth.user.screen_name)
        const { data: platform, refetch } = usePlatform(slug);

        return {
            platform, auth, refetch
        }
    }
}
</script>

<template>
    <article class="page">
        <NPageHeader
            title="Your Masthead"
            subtitle="Customize your masthead"
            icon="address-card-regular"
        />
        <div class="page__content mb-10">
            <NDataForm action="/me/profiles/masthead" :data="{ ...fields, masthead_background_image: platform?.masthead_background_image }" :beforeSubmit="beforeSave" @success="update" @error="fail">
                <template #default="form">
                    <p>This Account screen lets you customize your <strong>platform masthead</strong> (shown at the top of your home and dashboard screens), including your face photo, unique username, bio and interests. <em>Note: you can only change your username once every 6 months, so use care in choosing a name.</em></p>
                    <div class="form-groups">
                        <div class="form-group">
                            <label class="form-group__label" for="first-name">Your Full Name</label>
                            <p class="form-block-help-text">
                                We ask and encourage you to use your real name in our community, to increase trust. We strive to be a respectful community, and enforce our community guidelines vigorously. <router-link :to="{ name: 'code-of-conduct' }">Our Code of Conduct</router-link>.
                            </p>
                            <div class="flex flex-col sm:flex-row w-full items-center gap-4">
                                <NInput
                                    name="first_name"
                                    v-model="form.fields.first_name"
                                    placeholder="First Name"
                                    id="first-name"
                                    class="flex-1"
                                    required
                                    #default="{ invalid }"
                                >
                                    <small v-if="!invalid" class="px-1">25 characters max</small>
                                </NInput>
                                <NInput
                                    name="last_name"
                                    v-model="form.fields.last_name"
                                    placeholder="Last Name"
                                    id="last-name"
                                    class="flex-1"
                                    required
                                    #default="{ invalid }"
                                >
                                    <small v-if="!invalid" class="px-1">25 characters max</small>
                                </NInput>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-group__label" for="screen-name">Username</label>
                            <p class="form-block-help-text">
                                This is your unique BigNerve username ("screen name" or "handle"). It is used in certain places where your full name may not fit. If you link a social account, we’ll use that same username, if it’s available in our system (you can change it later).
                            </p>
                            <div class="flex flex-col sm:flex-row gap-4">
                                <div class="flex-1">
                                    <NInput
                                        v-model="newHandle"
                                        :class="{
                                            'is-success': availbeHandle === true,
                                            'is-error': availbeHandle === false
                                        }"
                                        name="screen_name"
                                        autocomplete="off"
                                        pattern="[A-Za-z0-9_]{1,25}"
                                        id="screen-name"
                                        @update:modelValue="checkHandle"
                                        required
                                    >
                                        <template #prepend>
                                            <span class="mr-1 text-dust-600">@</span>
                                        </template>
                                        <template #append>
                                            <div class="flex items-center justify-center w-8 h-5 -mr-1">
                                                <NLoading :loading="checkingHandle && !handleIndicator" />
                                                <NIcon
                                                    v-if="handleIndicator"
                                                    v-tooltip="'Availability'"
                                                    :as="handleIndicator"
                                                    :class="{ 'is-color-green': availbeHandle === true, 'is-color-red': availbeHandle === false }"
                                                />
                                            </div>
                                        </template>
                                    </NInput>
                                </div>
                                <div class="flex-1 py-1">
                                    <span class="form-block-help-text has-low-line-height !text-sm">
                                        <em>Your username can only contain letters, numbers and '_', 25 characters max.</em>
                                        <!-- Pulled from Twitter "screen_name" or other SOCIAL-API-INTEGRATION VALUE -->
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-group__label" for="bio">
                                Brief blurb for your masthead
                                <span class="form-inline-help-text">
                                    Optional, 180 characters max.
                                    <!-- Pulled from  Twitter "description" or SOCIAL-API-INTEGRATION VALUE -->
                                </span>
                            </label>
                            <NTextarea name="bio" v-model="form.fields.bio" maxlength="180" id="bio"></NTextarea>
                        </div>
                        <div class="form-group">
                            <label class="form-group__label" for="slogan">
                                Your Slogan
                                <span class="form-inline-help-text">Optional, 40 characters max</span>
                            </label>
                            <p class="form-block-help-text">
                                Think of a fun slogan or saying for your personal platform. It appears beneath the logo and your name.
                            </p>
                            <NInput name="slogan" v-model="form.fields.slogan" maxlength="40" id="slogan"></NInput>
                        </div>
                        <div class="form-group">
                            <label class="form-group__label" for="avatar_url">Your Face Photo </label>
                            <p class="form-block-help-text">
                                Adding a real photo of you helps other people trust you more, research shows. <!-- If you set a "platform" photo in your <a href="/my-account/organization" title="View your Organization panel">Organization</a> panel, it will replace your face photo in the masthead, but your face icon will still appear in leaderboards and in your challenges and ideas. --> 
                            </p>
                            <NCropper v-model="form.fields.avatar_url" :previewEl="previewElement" :ratio="1.833" name="avatar_url" class="mt-2" @cropping="croppingAvatar = true" @cropped="croppingAvatar = false">
                                <template #default>
                                    <div class="grid mb-3 sm:grid-cols-2 divide-x divide-white">
                                        <figure class="border bg-dust-200 w-full min-h-[12rem] sm:w-96">
                                            <img :src="form.fields.avatar_url" />
                                        </figure>
                                        <div class="w-full flex items-center justify-center border-gray-200 bg-dust-200">
                                            <NPlayerDetails :user="{ id: 'temp', ...form.fields, handle: newHandle }" class="sm:min-w-60 my-2" />
                                        </div>
                                    </div>
                                </template>

                                <template #preview="{ src }">
                                    <div class="flex  items-center justify-center border-gray-200 bg-dust-200 w-full">
                                        <NPlayerDetails :user="{ id: 'temp', ...form.fields, handle: newHandle, avatar_url: src }" class="sm:min-w-60 my-2" />
                                    </div>
                                </template>
                            </NCropper>
                        </div>
                        <div class="form-group">
                            <label class="form-group__label" for="avatar_url">Custom masthead background </label>
                            <p class="form-block-help-text">
                                You can add an image. Upload size less 10mb, idea with 2000px width and 500px height.
                            </p>
                            <NCropper v-model="form.fields.masthead_background_image" ref="mastheadCropper" :quality="1" :ratio="4" name="masthead_background_image" class="mt-2">
                                <template #default="{ loading }">
                                    <figure v-if="form.fields.masthead_background_image" :class="{ 'animate-loading': loading }" class="relative h-40 w-full overflow-hidden bg-dust-200 rounded-lg">
                                        <img v-if="platform" class="absolute inset-0 h-full w-full object-cover" :src="form.fields.masthead_background_image" alt="Masthead Background Image" />
                                    </figure>
                                    <div v-else class="h-40 mb-3 w-full border-2 border-dashed border-dust-300 rounded-lg flex items-center justify-center">
                                        <span class="italic text-dust-300">No custom image uploaded yet!</span>
                                    </div>
                                </template>
                            </NCropper>
                        </div>
                        <div>
                            <NAlert type="danger" v-model:open="errorMessage" class="upperfirst" closable>
                                <span>{{ upperFirst(form.errors.get('*') || 'One of the form fields was invalid, please check your changes again?') }}</span>
                            </NAlert>
                            <NAlert type="success" v-model:open="successMessage" closable>
                                <span>Your profile has been updated!</span>
                            </NAlert>
                            <button aria-label="button" type="submit" class="btn is-primary !px-[2rem]" :disabled="form.busy || croppingAvatar">
                                {{ form.busy ? 'Saving...' : (successMessage ? 'Saved - Success!' : 'Save changes' ) }}
                            </button>
                        </div>
                    </div>
                </template>
            </NDataForm>
        </div>
    </article>
</template>
