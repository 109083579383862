import axios from 'axios';
import { captureException, authorization, unauthorized, retryRequest, applyIdentity, askShadowUser, relationships } from '@/services/middlewares';

export default class Requester {
    /**
     * Create a new requester instance.
     *
     * @param {Object} config Configuration options for the requester
     */
    constructor(config) {
        const axiosConfig = Object.assign(
            { baseURL: config.baseURL, headers: { Accept: 'application/json' } }, config
        );

        this.axios = axios.create(axiosConfig);

        retryRequest(this.axios, {
            retries: 5,
            statusCodes: [102], // processing
            middleware (config) {
                // ...strategy

                return config;
            },
        });

        retryRequest(this.axios, {
            statusCodes: [504],
            isError: true,
            retries: 4
        });

        authorization(this.axios);
        unauthorized(this.axios);
        captureException(this.axios);
        applyIdentity(this.axios);
        askShadowUser(this.axios);
        relationships(this.axios, {
            fields: [
                [ 'catalyst_id', 'catalyst' ],
                [ 'co_author_id', 'co_author' ],
                [ 'user_stats', 'user_stats' ],
                [ 'catalyst_earn_id', 'earnings' ],
            ]
        });

        this.authCookieName = config['authCookieName'];
    }

    /**
     * Make an AXIOS request.
     *
     * @param {string} method HTTP method to use in the request
     * @param {string} url URL to send the request to
     * @param {Object} data Payload to send along with the request
     * @param {Object} params Additional query parameters to append to the URL
     * @param {Object} headers Additional headers to add to the request
     * @param {Object} options Additional configuration to add to the request
     * @returns {Promise} Axios request return
     */
    makeRequest(method, url, data = {}, params = {}, headers = {}, options = {}) {
        // Build headers
        headers = this.buildHeaders(headers);

        // Set the basics for the request configuration
        let config = { method, url, headers, ...options };

        // Add data/params to our request config
        config = this.addDataToConfig(config, method, data, params);

        // Make the request and return the promise
        return this.axios.request(config);
    }

    /**
     * Add data to our request config.
     *
     * @param {Object} config Current config to add data to
     * @param {string} method HTTP Method
     * @param {Object} data Payload to send along with the request
     * @param {Object} params Additional query parameters to append to the URL
     */
    addDataToConfig(config, method, data, params) {
        if (method === 'GET') {
            return Object.assign({}, config, { params: Object.assign({}, data, params) });
        }

        return Object.assign({}, config, { params, data });
    }

    /**
     * Build the headers for the request config.
     *
     * @param {Object} headers Current headers for the request
     */
    buildHeaders(headers) {
        // ...

        return headers;
    }

    /**
     * Serialize parameters to work.
     *
     * @param {Object} parameters Parameters to serialize
     */
    serialize(parameters) {
        return Object.keys(parameters).map(key => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}&`;
        });
    }
}
