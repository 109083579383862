<script setup>
import ChallengeCard from './OpenChallengeCard.vue';
import CardPlaceholder from '@/components/CardPlaceholder.vue';
import { usePlatform } from '@/composable/platform';
import { ref, computed, watch, shallowRef } from 'vue';
import { api } from '@/modules/services';
import { useCancelToken } from '@/composable/requester';
import { useRouteParams, useRouteQuery } from '@vueuse/router';
import { useChallenges } from '@/queries/widgets';

const props = defineProps({
    title: {
        type: String
    },
    filter: {
        type: String,
        required: true
    },
    status: {
        type: String,
        default: 'open'
    },
    platformDomain: {
        type: Boolean,
    },
    options: {
        type: Object,
        default: () => ({})
    }
});

const { currentPlatform, platform } = usePlatform();

const catalyst = useRouteParams('user');
const category = useRouteQuery('category');
const size = ref(props.options.size || 9);

const params = computed(() => ({
    size: size.value,
    page: 0,
    status: props.status,
    sort: props.filter,
    category: category.value,
    platform_domain: props.platformDomain ? platform.value?.id : undefined,
}));

const platformSlug = computed(() => catalyst.value || currentPlatform.value?.slug || 'bignerve');

const {
    data: challenges,
    hasNextPage: hasMore,
    isFetching: busy,
    loadMore: fetchNextPage,
    refetch,
} = useChallenges(platformSlug, params);
const total = computed(() => challenges.value?.meta?.total || 0);
const isEmpty = computed(() => challenges.value.length === 0);
</script>


<template>
    <section v-if="busy || !isEmpty" class="mb-4">
        <div class="flex items-center justify-between mb-4">
            <slot name="header">
                <h2 class="m-0 text-xl font-narrow text-secondary flex-1">
                    {{ title }}
                </h2>
            </slot>
        </div>

        <div v-if="!busy && isEmpty" class="py-20">
            <p v-if="filter.includes('trending')" class="font-bold text-center mb-0">
                No trending yet! Check for most recent challenges.
            </p>
            <p class="italic text-center">
                No challenges found
            </p>
        </div>

        <div class="overflow-hidden">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <template v-if="! isEmpty">
                    <ChallengeCard
                        v-for="(challenge, index) in challenges"
                        :key="index"
                        :challenge="challenge"
                        class="h-auto"
                    />
                </template>

                <template v-if="busy">
                    <CardPlaceholder v-for="i of 9" :key="`tease-placeholder-${i}`">
                        <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
                    </CardPlaceholder>
                </template>
            </div>
        </div>

        <div v-if="hasMore" class="flex justify-center">
            <div class="load-more-wrapper">
                <router-link :to="{ query: { filter, domain: platformDomain ? 'true' : undefined }, hash: '#heading' }" tag="button" class="load-more-btn px-2">
                    <span class="load-more-btn__helper">
                        Load more
                    </span>
                    <span aria-hidden="true" class="icon icon-caret-down-simple"></span>
                </router-link>
            </div>
        </div>

    </section>
</template>
