<script setup>
import debounce from 'lodash/debounce';
import ReportContent from '@/components/ReportContent.vue';
import MentoringQuickFeedback from '@/components/mentoring/QuickFeedback.vue';
import RatingBrain from '@/components/idea/RatingBrain.vue';
import ExpansionPanel from '@/components/NExpansionPanel.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import { useStorage } from '@vueuse/core';
import { reactive, ref, computed, watch, nextTick } from 'vue';
import { stringSimilarity } from 'string-similarity-js';
import { toRaw } from '@/mixins/Markdown';
import { isUseless } from '@/utils/Helpers';

const props = defineProps({
	idea: Object,
	saveText: {
		type: String,
		default: 'Save',
	},
	rating: {
		type: Number,
		default: 0,
	},
	beforeRating: Function,
	disabled: {
		type: Boolean,
		default: false,
	},
	disableRating: {
		type: Boolean,
		default: false,
	},
	mentored: {
		type: Boolean,
		default: false,
	},
	defaultMentoring: {
		type: Boolean,
		default: false,
	},
	hasQuickFeedback: {
		type: Boolean,
		default: true,
	},
	store: {
		type: Boolean,
		default: true,
	},
	mentorComment: {
		type: Object,
		default: () => ({}),
	},
	forceRating: {
		type: Boolean,
		default: false,
	},
	editable: {
		type: Boolean,
		default: false,
	},
	defaultFields: {
		type: Object,
		default: () => ({}),
	},
});

const emit = defineEmits(['success']);
const encouragementTextEditor = ref(null);
const improvementTextEditor = ref(null);

const fields = props.store && !props.rating ? useStorage(
	`challenge:rating-flow:idea:${props.idea.id}:fields`,
	{
		rating: 0,
		mentor_comment: {
			improvement: null,
			encouragement: null,
		},
		feedbacks: [],
		...props.defaultFields,
	},
	null, { deep: true }
) : ref({
	rating: props.rating || 0,
	mentor_comment: {
		improvement: null,
		encouragement: null,
	},
	feedbacks: [],
	...props.defaultFields,
});
const showTip = ref(true);
const forceMentoring = ref(false);
const expandMentoringPanel = ref(props.defaultMentoring);
const showAdvice = reactive({
	encouragement: false,
	improvement: false,
});

const minLength = {
	encouragement: 30,
	improvement: 50,
};

const action = computed(() => `/ideas/${props.idea.id}/ratingv2`);
const validate = computed(() => ({
	rating: fields.value.rating > 0,
	encouragement: fields.value.mentor_comment.encouragement
		? toRaw(fields.value.mentor_comment.encouragement).length >= minLength.encouragement && !useless.encouragement
		: true,
	improvement: fields.value.mentor_comment.improvement
		? toRaw(fields.value.mentor_comment.improvement).length >= minLength.improvement && !useless.improvement
		: true,
	similarComments: stringSimilarity(toRaw(fields.value.mentor_comment.encouragement), toRaw(fields.value.mentor_comment.improvement)) >= 0.8,
}));

const useless = computed(() => ({
	encouragement: fields.value.mentor_comment.encouragement ? isUseless(fields.value.mentor_comment.encouragement) : false,
	improvement: fields.value.mentor_comment.improvement ? isUseless(fields.value.mentor_comment.improvement) : false,
}));
const encouragementFilled = computed(() => toRaw(fields.value.mentor_comment.encouragement).length >= minLength.encouragement);
const improvementFilled = computed(() => toRaw(fields.value.mentor_comment.improvement).length >= minLength.improvement);
const mentoringToRate = computed(() => {
	if (!props.idea.stats) {
		return -1;
	}

	return props.idea.stats.mentoring_to_rate || 0;
});

const reset = () => {
	fields.value.rating = props.rating || 0;
	fields.value.mentor_comment.improvement = null;
	fields.value.mentor_comment.encouragement = null;
	fields.value.feedbacks = [];

	expandMentoringPanel.value = props.defaultMentoring;
}

const onSuccess = (data, formFields) => {
	emit('success', {
		data,
		fields: { ...formFields },
		commented: !!(fields.value.mentor_comment.improvement || fields.value.mentor_comment.encouragement),
	});

	nextTick(reset);
};

const toggleAdivice = debounce(function ({ improvement, encouragement }) {
	showAdvice.improvement = improvement && fields.value.mentor_comment.improvement;
	showAdvice.encouragement = encouragement && fields.value.mentor_comment.encouragement;
}, 2000);

const toggleVisibleTip = debounce(function () {
	showTip.value = false;
}, 5000);

watch(
	() => fields.value.rating,
	(newRating, oldRating) => {
		if (newRating < 5 && oldRating >= 5) {
			fields.value.feedbacks = [];
		}

		if (newRating >= 5) {
			expandMentoringPanel.value = true;
		}
	}
);

watch(useless, (newValue) => {
    toggleAdivice(newValue);
});

const handleForceMentoring = () => {
	expandMentoringPanel.value = true;
	forceMentoring.value = true;
};

const ideaEditorToolbar = [
	['bold', 'italic', 'underline', 'strike'],
	['link', { list: 'ordered' }, { list: 'bullet' }],
];

defineExpose({ fields, validate, useless, encouragementFilled, improvementFilled, mentoringToRate, toggleAdivice, reset });
</script>

<script>
export default {
	name: 'RatingForm',
};
</script>

<template>
	<div
		class="rating-form bg-dust-100 rounded-lg shadow-md xs:p-2 sm:p-4"
		:class="{ disabled }"
	>
		<NDataForm
			:action="action"
			:data="fields"
			:before-send="beforeRating"
			@before-send="(e) => ($bus('challenge:idea:rating', e))"
			@success="onSuccess"
			class="flex flex-col gap-4"
		>
			<template #default="form">
				<NAlert
					type="danger"
					closable
					:open="form.errors.any()"
				>
					{{ form.errors.get('*') || 'An error occured, please check your fields and try again.' }}
				</NAlert>

				<RatingBrain
					v-if="!forceRating"
					v-model="fields.rating"
					:disabled="form.busy"
					:is-read-only="disableRating || !!rating"
					class="mb-0"
				>
					<template v-if="!rating" #rating-overlay>
						<span class="font-bold text-rater">Rating unavailable!</span>
					</template>
				</RatingBrain>

				<div v-if="mentored" class="border-4 bg-mentor border-mentor rounded-lg mb-4" >
					<div class="h-4"></div>
					<div class="p-8 bg-white rounded-lg">
						<div v-if="mentorComment.id" class="flex flex-col gap-6">
							<div>
								<h2 class="text-mentor mb-0">
									<NIcon
										as="mentor"
										class="text-mentor text-xl mr-2"
									/>
									<span class="font-bold text-mentor">Your mentoring</span>
								</h2>

								<p class="text-mentor-400 mb-0">
									Help the innovator get better at thinking through their ideas. Mentor only the ideas you think you can help improve. {{ editable ? 'You can edit your comments up until the innovator replies.' : '' }}
								</p>
							</div>

							<div v-if="mentorComment.what_liked" class="p-4 rounded-lg border">
								<label class="font-bold mb-0 text-dust-800">1. What I like about this idea</label>

								<p class="text-dust-700 mb-0">
									{{ mentorComment.what_liked }}
								</p>
							</div>

							<div v-if="mentorComment.what_wish" class="p-4 rounded-lg border">
								<label class="font-bold mb-0 text-dust-800">2. What could be improved...</label>

								<p class="text-dust-700 mb-0">
									{{ mentorComment.what_wish }}
								</p>
							</div>
						</div>
						<div v-else class="flex flex-col items-center py-20 ">
							<h2>You have mentored this idea</h2>
							<p class="text-dust-600 mb-0">
								View your mentorings on
								<RouterLink
									:to="{ name: 'dashboard.mentoring' }"
									class="text-dust-800 underline hover:text-dust-900 hover:underline"
									>Mentor Dashboard</RouterLink
								>
							</p>
						</div>
					</div>
				</div>

				<template v-else>
					<div
						v-show="hasQuickFeedback && fields.rating && fields.rating <= 4.9"
						class="border-4 bg-mentor border-mentor rounded-lg"
					>
						<div class="h-4"></div>
						<div class="px-6 py-4 bg-white rounded-lg">
							<h2 class="text-mentor mb-3">
								<NIcon
									as="mentor"
									class="text-mentor text-xl mr-2"
								/>
								<span class="font-bold text-mentor">Mentor this person to help them improve</span>
								<span class="italic text-dust text-sm ml-2 font-medium">Optional</span>
							</h2>

							<MentoringQuickFeedback
								v-model="fields.feedbacks"
								class="pt-2 mb-6"
							/>

							<h3 class="mb-0">Tap up to 3 choices that explain why you are giving a low rating.</h3>
							<p class="italic text-dust-700">Long-press to see a definition of each choice.</p>

							<p>The innovator will see these in their idea Dashboard. This mentoring is anonymous, so be honest.</p>

							<button aria-label="button"
								v-if="!forceMentoring"
								type="button"
								class="flex w-full items-center justify-center bg-mentor-100 font-semibold py-2 px-4 text-dust-700 rounded-md mt-4 hover:bg-mentor-200"
								@click="handleForceMentoring"
							>
								Let me give a comment...
								<NIcon
									as="arrow-circle-down"
									class="ml-2"
								/>
							</button>
						</div>
					</div>

					<div
						v-show="!hasQuickFeedback || !fields.rating || fields.rating > 4.9 || forceMentoring"
						class="border-4 bg-mentor border-mentor rounded-lg"
					>
						<div class="h-4"></div>
						<div class="px-6 py-4 bg-white rounded-lg">
							<h2 class="text-mentor mb-3">
								<NIcon
									as="mentor"
									class="text-mentor text-xl mr-2"
								/>
								<span class="font-bold text-mentor">Mentor this idea to earn bonus Neurons!</span>
								<span class="italic text-dust text-sm ml-2 font-medium">Optional</span>
							</h2>

							<p class="text-mentor-400 mb-4">
								Help the innovator get better at thinking through their ideas. Mentor only the ideas you can help improve. Your star
								rating of their idea always stays anonymous.
							</p>
							<p
								v-if="mentoringToRate > 0"
								class="text-mentor-400 m-0 border-4 border-dashed rounded border-dust-300 leading-none p-2"
							>
								<b>Bonus</b>: if you mentor now, you can read and rate the other
								<b>{{ mentoringToRate }} Mentor comment{{ mentoringToRate > 1 ? 's' : '' }}</b
								>!
							</p>

							<ExpansionPanel v-model="expandMentoringPanel">
								<template #header="{ active, on }">
									<button aria-label="button"
										v-if="!active"
										v-on="on"
										type="button"
										class="flex w-full items-center justify-center bg-mentor-100 font-semibold py-2 px-4 text-dust-700 rounded-md mt-4 hover:bg-mentor-200"
									>
										Expand to give feedback
										<NIcon
											as="arrow-circle-down"
											class="ml-2"
										/>
									</button>
								</template>

								<div class="form-group is-rating mt-4">
									<div class="flex flex-wrap sm:flex-nowrap justify-between items-center mb-2 w-full">
										<label class="font-bold text-lg mb-0">1. What do you like about this Idea?</label>

										<NExperiencePoints
											fqdn="mentor.IdeaMentored.MentoredIdeaLike"
											#="{ points }"
										>
											<NBubble
												v-if="encouragementFilled && points"
												color="mentor"
												class="-mb-2"
												:animated="true"
											>
												+{{ points }} Neurons
											</NBubble>
										</NExperiencePoints>
									</div>
									<NAdvice
										:open="!!showAdvice.encouragement"
										:placement="$responsive.sm ? 'bottom' : 'left'"
										class="w-full"
										name="encouragement"
										@closed="() => (encouragementTextEditor.focus(), (showAdvice.encouragement = false))"
									>
										<template #content>
											<h3 class="mb-3">A word of advice!</h3>
											<p>
												This comment must be encouraging; you used words that do not appear to be positive. Do not write “You
												tried” or a negative comment here.
											</p>
											<p class="mb-0">
												Innovators and other mentors will down-rate low-quality mentor comments, affecting your points.
											</p>
										</template>
										<div class="quill-editor-wrapper">
											<span class="quill-editor-title">
												<span
													:class="
														form.fields.mentor_comment.encouragement && !encouragementFilled
															? 'text-red-500'
															: 'text-dust-600'
													"
													class="font-normal text-sm ml-1 inline-block"
												>
													({{ minLength.encouragement }} chars min)
												</span>
											</span>
											<TextEditor
												v-model="form.fields.mentor_comment.encouragement"
												v-visible="toggleVisibleTip"
												:invalid="useless.encouragement || validate.similarComments"
												:toolbar="ideaEditorToolbar"
												ref="encouragementTextEditor"
												name="encouragement"
												classContainer="mb-3 !bg-mentor-200"
												placeholder="Be encouraging..."
											/>
										</div>
									</NAdvice>
									<NTip
										:open="showTip"
										placement="bottom"
										title="New Feature! Mentor Rating Game"
										description="If you mentor this innovator's idea, you can join in a Mentor Rating Game. You will see all the Mentoring comments on this idea, and rate them on helpfulness — earn more points and help others improve, too!"
										target="challenge:rating-flow:encouragement"
									>
										<div class="w-[50dvw] sm:w-[25rem]"></div>
									</NTip>
								</div>
								<div class="form-group is-rating mt-4">
									<div class="flex flex-wrap sm:flex-nowrap justify-between items-center mb-1 w-full">
										<label class="font-bold text-lg mb-0">2. How would you nurture this idea along?</label>
										<NExperiencePoints
											fqdn="mentor.IdeaMentored.MentoredIdeaImprove"
											#="{ points }"
										>
											<NBubble
												v-if="form.fields.mentor_comment.improvement && improvementFilled && points"
												color="mentor"
												:animated="true"
											>
												+{{ points }} Neurons
											</NBubble>
										</NExperiencePoints>
									</div>
									<p class="is-italic text-dust-600">
										You must give an encouraging comment above before you can give a constructive comment here.
									</p>
									<NAdvice
										:open="!!showAdvice.improvement"
										:placement="$responsive.sm ? 'bottom' : 'left'"
										class="w-full"
										name="improvement"
										@closed="() => (improvementTextEditor.focus(), (showAdvice.improvement = false))"
									>
										<template #content>
											<h3 class="mb-3">A word of advice!</h3>
											<p>
												This comment must be constructive; you used words that do not appear to help improve the idea. Do not
												write “Nothing to improve” or similar!
											</p>
											<p class="mb-0">
												Innovators and other mentors will down-rate low-quality mentor comments, affecting your points.
											</p>
										</template>
										<div class="quill-editor-wrapper">
											<span class="quill-editor-title">
												<span
													:class="
														form.fields.mentor_comment.improvement && !improvementFilled
															? 'text-red-500'
															: 'text-dust-600'
													"
													class="font-normal text-sm ml-1 inline-block"
												>
													({{ minLength.improvement }} chars min)
												</span>
											</span>
											<TextEditor
												v-model="form.fields.mentor_comment.improvement"
												:disabled="!encouragementFilled"
												:invalid="useless.improvement || validate.similarComments"
												:toolbar="ideaEditorToolbar"
												classContainer="mb-3 bg-mentor-200"
												name="improvement"
												ref="improvementTextEditor"
												size="left"
												placeholder="Be specific… this is the place for constructive feedback, if you have any."
											/>
										</div>
									</NAdvice>

									<p class="font-normal text-dust-600">
										Make sure your “helpful” comment is <b>truly constructive</b> or you'll get a low rating on this comment by
										the Innovator or other Mentors.
									</p>
								</div>
							</ExpansionPanel>
						</div>
					</div>

					<NAlert
						:open="validate.similarComments"
						type="danger"
					>
						Your mentoring comments are too similar. Please make them more unique.
					</NAlert>
				</template>

				<div
					v-if="!mentored || !rating"
					class="pt-4 mb-5 flex flex-col items-center"
				>
					<button aria-label="button"
						v-if="!forceRating && (!mentored || !disableRating)"
						type="submit"
						:class="disableRating ? 'is-mentor' : 'is-yellow'"
						class="btn inline-flex items-baseline justify-center leading-tingh  px-6 py-2 mb-3 min-w-[10rem]"
						:disabled="validate.similarComments || (!validate.rating && !disableRating) || !validate.improvement || !validate.encouragement || form.busy"
					>
						<template v-if="!validate.rating && !disableRating">
							<NIcon
								as="star-regular"
								left
							/>Choose your rating
						</template>
						<template v-else>
							{{ form.busy ? 'Saving...' : saveText }}
						</template>
					</button>

					<button aria-label="button"
						v-if="forceRating"
						type="submit"
						class="btn inline-flex items-baseline justify-center leading-tingh is-mentor px-4 py-2 mb-3 min-w-[10rem]"
						:disabled="validate.similarComments || (!validate.rating && !disableRating) || !validate.improvement || !validate.encouragement || form.busy"
					>
						{{ form.busy ? 'Saving...' : 'Save and Send' }}
					</button>
					<slot name="footer" :fields="fields" />
				</div>
			</template>
		</NDataForm>

		<div class="flex justify-end px-4 py-2">
			<ReportContent
				:content-id="idea.id"
				:related-object="idea.challenge_id"
				:reasons="['Spam', 'Obscene', 'Offensive', { label: 'Plagiarized', auto: false }, { label: 'Duplicate', auto: true }, 'AI Slop']"
				:context="(c) => (c.replace('/rate', ''))"
				content-type="idea"
				placement="left-end"
				label="Report idea"
			>
				<template #details="{ reason, hide, updateEvidence, report }">
					<!-- <div v-if="reason && reason.slug === 'duplicate'" class="bg-white p-4 mx-5 mb-5 mt-2 rounded-md max-w-140">
                        <h3>Please note: Duplicate ideas are not reportable. </h3>
                        <p>
                            It will be quite common that many players will think of similar or identical ideas. It’s not a sign of cheating.
                        </p>
                        <div class="flex items-end">
                            <p class="mb-0">
                                Just give this idea the <b>same rating</b> you gave on the earlier similar idea. You can close this window and return to rating.
                            </p>
                            <button aria-label="button" type="button" class="p-2" @click="hide">
                                <NIcon as="times-circle-regular" class="text-xl" />
                            </button>
                        </div>
                    </div> -->
					<div
						v-if="reason && reason.slug === 'plagiarized'"
						class="bg-white px-4 pt-4 pb-2 mx-5 mb-5 mt-2 rounded-md max-w-140"
					>
						<h3>To report an idea as plagiarized:</h3>
						<p>Please tell us why you think so, and what is the original source:</p>
						<textarea
							class="p-2 mb-2 w-full rounded-lg border-2 bg-dust-100 form-group__input"
							placeholder="Paste in any supporting evidence (e.g. link to 3rd party website)"
							maxlength="100"
							resize="none"
							@input="(e) => updateEvidence(e.target.value)"
						/>
						<div class="flex justify-end mb-3">
							<button aria-label="button"
								type="button"
								class="px-4 py-1 bg-catalyst text-white rounded-md hover:bg-catalyst-600"
								@click="report"
							>
								Send Report
							</button>
						</div>
						<div class="flex items-center">
							<p class="mb-2 italic">You can close this window and return to rating.</p>
							<button aria-label="button"
								type="button"
								class=""
								@click="hide"
							>
								<NIcon
									as="times-circle-regular"
									class="text-xl ml-2"
								/>
							</button>
						</div>
					</div>
				</template>
			</ReportContent>
		</div>
	</div>
</template>
