export default requester => {
    return {
        /**
         * Rate mentoring
         *
         * @param  {string} mentoringId
         * @param  {object} payload
         * @return {object}
         */
        rateMentoring(mentoringId, payload) {
            return requester.post(`/game/mentoring/${mentoringId}/rates`, payload);
        },

        /**
         * Retrives award ideas.
         *
         * @param {string} handle
         */
        awardIdeas (handle, params) {
            return requester.get(`/award/ideas/${handle}`, { params });
        },

        /**
         * Retrives award medals.
         *
         * @param {string} handle
         */
        awardMedals (handle, params) {
            return requester.get(`/award/medals/${handle}`, { params });
        },

        /**
         * Retrives award medals meta.
         *
         * @param {string} handle
         */
        awardMedalsMeta (handle, params) {
            return requester.get(`/award/medals/${handle}/meta`, { params });
        },

        /**
         * Retrives user highlights
         * 
         * @param {string} handle
         */
        highlights (handle, params) {
            return requester.get(`/gamedash/${handle}/highlights`, { params });
        },

        /**
         * Idea rating flow - one-tap Mentoring choices
         */
        mentoringChoices () {
            return requester.get('/mentoring/feedback-choices');
        },
    };
}
