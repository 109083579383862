
/**
 * Mounts the breadcrumbs for the category
 * 
 * @param {object} element 
 * @param {string} value 
 * @param {string} key 
 * @returns 
 */
export const breadcrumbs = (element, value, key = 'id') => {
  if (element[key] === value) {
      return [element]
  }

 let result = []
  if (element.children.length) {
     result = element.children.reduce((acc, el) => {
         const x = breadcrumbs(el, value, key);

         return x.length ? x : acc;
     }, [])
  }

 return result.length ? [ element, ...result ].filter(Boolean) : [];
}
