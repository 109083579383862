import { Session } from '@/utils/Auth';
import Axios from 'axios';

export const askShadowUser = axios => {
    axios.interceptors.request.use(async config => {
        const methods = ['post', 'put', 'path'];

        if (methods.includes(config.method) && Session.isShadow()) {
            const bypass = confirm('You are sending a change request in shadow mode. These changes will normally be sent as the user you are accessing.')

            if (!bypass) {
                throw new Axios.Cancel('Request canceled by user in shadow mode');
            }
        }

        return config;
    }, err => Promise.reject(err));
}