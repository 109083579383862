import Cookie from "@/utils/Cookie";
import { oldApi } from '@/modules/services';
import { createSharedComposable } from '@vueuse/core';
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { usePlatform } from '@/composable/platform';
import { Session } from '@/utils/Auth';

const auth = reactive({
    current: { user: {} },
    authenticating: false,
    user: {},
    profile: {},
    isAdmin: false,
    isAmbassador: false,
    authenticated: false,
});

export const useAuth = createSharedComposable(() => {
    const setAuthenticating = (value) => {
        auth.authenticating = value;
    }
    const setAccount = (account) => {
        auth.current = account;
        auth.user = account.user;
        auth.profile = account.profile || {};
        auth.isAdmin = account.admin_roles && account.admin_roles.global_admin;
        auth.isAmbassador = account.admin_roles && account.admin_roles.platform_ambassador;
        auth.authenticated = Session.exists();
    };

    const loadMe = async () => {
        if (auth.authenticated || auth.authenticating) {
            return;
        }

        const { data: response } = await oldApi.users.detailedMe();

        setAccount(response.data);
    }

    const user = computed(() => auth.current.user);

    return {
        setAuthenticating,
        setAccount,
        loadMe,
        auth,
        user,
    };
});

export const useUser = createSharedComposable(() => {
    const { loadMe, user } = useAuth();

    return {
        refetch: loadMe,
        user,
    };
});

export const useLinks = createSharedComposable(() => {
    const { platform } = usePlatform();
    const accountURL = import.meta.env.VUE_APP_ACCOUNT_URL;
    const route = useRoute();
    const router = useRouter();

    const isHome = computed(() => route.name === 'home.challenges');
    const scoutId = computed(() => {
        const id = Cookie.get('scout_id')

        if (id && id !== 'undefined' && id !== 'null') {
            return id;
        }
    });
    const homeURL = computed(() => {
        const to = router.resolve({ name: 'home.challenges' });
        return encodeURIComponent(`${location.origin}${to ? to.href : ''}`);
    });
    const continueURL = computed(() => {
        let fullPath = route.fullPath;

        if (isHome.value) {
            fullPath = '/me/my-dashboard'
        }

        const path = `${location.origin}${fullPath}`;

        return new URL(path)
    });
    const createLoginUrl = (params = {}) => {
        const url = new URL(`${accountURL}/gateway`);

        Object.keys(params).forEach(key => {
            url.searchParams.append(key, params[key]);
        });

        return url.toString();
    }
    const createConnectUrl = (params = {}) => {
        const url = new URL(`${accountURL}/connect`);

        if (!params.code) {
            throw new Error('Code is required');
        }

        Object.keys(params).forEach(key => {
            url.searchParams.append(key, params[key]);
        });

        return url.toString();
    }
    const loginURL = computed(() => {
        const url = new URL(`${accountURL}/gateway`);

        url.searchParams.append('continue', continueURL.value);
        url.searchParams.append('platform', platform.value.id);

        if (scoutId.value) {
            url.searchParams.append('scout_id', scoutId.value);
        }

        return url.toString();
    });
    const signupURL = computed(() => {
        const url = new URL(`${accountURL}/signup`);

        url.searchParams.append('continue', encodeURIComponent(continueURL.value.toString()));
        url.searchParams.append('platform', platform.value.id);

        if (scoutId.value) {
            url.searchParams.append('scout_id', scoutId.value);
        }

        return url.toString();
    });

    const signout = () => {
        const query = new URLSearchParams();
        
        query.append('continue', homeURL.value);
        query.append('platform', platform.value.id);

        const url = new URL(`${accountURL}/logout`);
        url.search = query.toString();

        // Remove all cookies
        Session.remove();

        location.replace(url.toString());
    }

    const signin = () => {
        location.replace(loginURL.value);
    }

    return {
        isHome,
        scoutId,
        homeURL,
        continueURL,
        loginURL,
        signupURL,
        signout,
        signin,
        createLoginUrl,
        createConnectUrl,
    }
});