<script>
import Init from '@/components/challenge/Init.vue';
import QqForm from '@/components/challenge/QQForm.vue';
import TestIdeaForm from '@/components/challenge/TestIdeaForm.vue';
import ChallengeForm from '@/components/challenge/ChallengeForm.vue';
import ImproveIdeaForm from '@/components/challenge/ImproveIdeaForm.vue';
import InnervationTools from '@/components/challenge/InnervationTools.vue';
import Schema from '@/utils/ChallengeSchema';
import { usePlatform } from '@/mixins/ShowsPlatform';
import { clone } from '@/utils/Helpers';
import { set, get } from 'lodash';

const CHALLENGE_DRAFT = 'challenge:creation-flow:draft';
const defaultDraft = { content: clone(Schema, {}) };

export default {
    components: {
        Init,
        QqForm,
        TestIdeaForm,
        ChallengeForm,
        ImproveIdeaForm,
        InnervationTools,
    },

    mixins: [
        usePlatform(), // using platform.id
    ],

    provide () {
        return {
            context: this.context,
            board: this,
        }
    },

    created() {
        this.init();
    },

    data() {
        const draft = Storage.get(CHALLENGE_DRAFT, defaultDraft);

        return {
            fields: clone(draft.content || {}, Schema),
            categoryTree: [],
            context: {
                categoryMain: null,
                categorySelected: null,
            },
            dirtable: [
                'challenge.assumption_what',
                'challenge.assumption_who',
                'challenge.assumption_why',
                'challenge.primary_image',
                'idea.description',
                'challenge.tags',
                'idea.title'
            ]
        };
    },

    computed: {
        isQQ() {
            return this.usingTool('qq');
        },
        isTestIdea() {
            return this.usingTool('test-an-idea');
        },
        isCaption() {
            return this.category && this.category.id.includes('caption');
        },
        category() {
            return this.categoryTree[this.categoryTree.length - 1];
        },
    },

    watch: {
        fields: {
            handler (newVal) {
                const hasCategory = !!newVal.challenge.platform_category;
                const draft = Storage.get(CHALLENGE_DRAFT, defaultDraft);

                if (hasCategory) {

                    // update local draft
                    draft.content = newVal;

                    // TODO should improve this draft resource
                    // this.$bus('challenge:creation-flow:draft-updated', { draft, onDone: (draft) => {
                    //     draft && Storage.set(CHALLENGE_DRAFT, draft);
                    // }});

                    Storage.set(CHALLENGE_DRAFT, draft)
                }
            },
            deep: true,
        },
        isCaption(yes) {
            this.fields.challenge.caption_solution_format = (yes ? 'caption' : null);
        },
        category(category) {
            this.context.categorySelected = category;
        },
        '$route': {
            handler () {
                this.init();
            },
            deep: true,
        }
    },

    bus: {
        'challenge:creation-flow:step' (step) {
            if (this.$refs.target && this.$refs.target.stepper) {
                this.$refs.target.stepper.to(step);
            }
        },
        'challenge:creation-flow:restart' (step = 'category') {
            if (this.$refs.target && this.$refs.target.stepper) {
                this.$refs.target.stepper.to(step);
            }

            this.init();
        },
        'challenge:creation-flow:draft-selected' ({ draft } = {}) {
            if (draft) {
                // update draft content
                draft.content = clone(draft.content, Schema);

                // update fields
                this.fields = draft.content;

                // update local draft
                Storage.set(CHALLENGE_DRAFT, draft);

                // start edition
                this.$refs.target && this.$refs.target.stepper.number(2);
            }
        },
        'challenge:creation-flow:category-main' (category) {
            this.context.categoryMain = category;
        },
        'challenge:creation-flow:start-over' () {
            this.reset({ form: true, store: true });
        },
        'challenge:creation-flow:stepper:changed' ({ active }) {
            set(this.fields, `steps.${active}`, true)
        },
        'challenge:creation-flow:recovered' () {
            //
        },
    },

    methods: {
        init() {
            this.reset({ form: true });
            this.recoverLastDraft();

            setTimeout(() => {
                if (this.$route.name === 'challenges.test-idea' && this.fields.solution_format !== 'test-an-idea') {
                    this.fields.solution_format = 'test-an-idea';
                } else if (this.fields.solution_format === 'test-an-idea') {
                    this.fields.solution_format = null;
                }
            }, 300);
        },
        reset({ store, form, fields = true } = {}) {
            // reset store
            if (store) {
                Storage.remove(CHALLENGE_DRAFT);
            }
            
            // reset form
            if (form && this.$refs.form) {
                this.$refs.form.reset();
            }

            // reset fields
            if (fields) {
                this.fields = clone(Schema);
            }

            // notify reset
            this.$bus('challenge:creation-flow:reseted', this.fields);
        },
        recoverLastDraft(fn = () => {}) {
            if (Storage.has(CHALLENGE_DRAFT)) {
                this.$nextTick(() => {
                    const draft = Storage.get(CHALLENGE_DRAFT, defaultDraft);

                    this.fields = draft.content;

                    fn(this.fields);

                    this.$bus('challenge:creation-flow:recovered', this.fields);
                });

                return;
            }

            fn(this.fields);
        },
        removeDraft (id) {
            try {
                this.$api.drafts.remove(id);
            } catch (e) {
                console.error(e);
            }
        },
        redirect(data) {
            this.fields.challenge.slug = data.slug;

            setTimeout(() => {
                const draft = Storage.get(CHALLENGE_DRAFT, defaultDraft);

                if (draft._id) {
                    this.removeDraft(draft._id);
                }

                Storage.remove(CHALLENGE_DRAFT);
            }, 1000);

            this.$refs.target.stepper.to('share');
        },
        usingTool(tool = null) {
            if (! tool) {
                return this.fields.challenge && this.fields.solution_format !== null;
            }

            return this.fields.challenge && this.fields.solution_format === tool;
        },
        normalize({ challenge: { innovation_scale }}) {
            this.fields.platform_domain = this.platform.id;
            this.fields.challenge.innovation_scale = innovation_scale || 'blue-sky';

            if (!String(this.fields.challenge.question_title).includes('?')) {
                this.fields.challenge.question_title = this.fields.challenge.question_title.trim() + '?';
            }

            // check if is a caption challenge
            if (this.isCaption) {
               return;
            }

            // updates article image
            if (!get(this.fields, 'challenge.article.image_url')) {
                set(this.fields, 'challenge.article.image_url', this.fields.challenge.primary_image);
            }

            // updates challenge image
            if (!this.fields.challenge.primary_image && get(this.fields, 'challenge.article.image_url')) {
                set(this.fields, 'challenge.primary_image', this.fields.challenge.article.image_url);
            }
        },
        markAsSent(response) {
            if (this.fields.sent) {
                this.$sentry.withScope(scope => {
                    scope.setTag('step', 'challenge:creation-flow');
                    scope.setExtra('payload', this.fields);
                    scope.setExtra('response', response);
                    this.$sentry.captureMessage('Possible duplicate challenge submission', 'info');
                });
            }

            this.fields.sent = true;
        }
    },
    
    setup () {
        // small fix to old drafts
        const old = Storage.get(CHALLENGE_DRAFT, {})
        if (!old.content) {
            Storage.remove(CHALLENGE_DRAFT);
        }
    },
}
</script>

<template>
    <div>
        <div class="flex items-center justify-center bg-secondary-500 rounded-md px-4 py-2">
            <h1 class="m-0 text-white">
                <NIcon as="question-circle" class="mr-2" />Post your own Challenge Question
            </h1>
        </div>

        <!-- <init></init> -->
        <NDataForm ref="form" action="/challenges" :data="fields" :dirtable="dirtable" once @before-send="normalize" @after-send="markAsSent" @success="redirect">   
            <QqForm v-if="usingTool('qq')" ref="target" />
            <TestIdeaForm v-else-if="usingTool('test-an-idea')" ref="target" />
            <ImproveIdeaForm v-else-if="usingTool('improve-an-idea')" ref="target" />
            <ChallengeForm v-else ref="target" />
        </NDataForm>
    </div>
</template>
