<script>
import { excerpt } from '@/utils/Helpers';

export default {
    inject: ['form'],

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        implicit: {
            type: Boolean,
            default: false
        },
    },

    methods: {
        excerpt,
        selectHardest(index) {
            this.form.fields.deep_dives.what.impact_steps.forEach((impact, i) => {
                impact.selected = i === index;
            });
        }
    }
}
</script>

<template>
    <div>
        <div class="tabs__content-helper">
            <p class="form-block-help-text">
                Every problem has two axes: how many levels down should I drill in to identify a core cause, and which cause do I start with based on feasibility. Then the best problem solvers take the identified cause and break it into actual components or steps, to then choose the “hardest or most impactful” to solve first. You’ll learn a lot and earn more game points for going deep here.
            </p>
            <div class="form-groups">
                <div class="form-group">
                    <h2 class="form-group__title is-blue">Core Cause(s) — Now think it through&hellip; you first wrote this:</h2>
                    <NTextarea size="small" v-model="form.fields.challenge.assumption_what" :disabled="disabled || !!form.fields.challenge.slug" />
                </div>
            </div>
            <div class="form-groups mt-5">
                <div class="form-groups__numbered-section">
                    <div class="form-group">
                        <label class="form-group__label is-blue">What’s your first impression of why this is the core cause?</label>
                        <NTextarea size="small" v-model="form.fields.deep_dives.what.core_cause_1st.string" :disabled="disabled" />
                    </div>
                    <div class="form-group">
                        <label class="form-group__label">Defend it with 1-3 supporting arguments.</label>
                        <NTextarea v-model="form.fields.deep_dives.what.core_cause_1st.supporting" :disabled="disabled" />
                    </div>
                </div>
                <div class="form-groups__numbered-section">
                    <div class="form-group">
                        <label class="form-group__label is-blue">Now, what is the secondary cause behind this cause? (Keep asking why)</label>
                        <NTextarea size="small" v-model="form.fields.deep_dives.what.core_cause_2nd.string" :disabled="disabled" />
                    </div>
                    <div class="form-group">
                        <label class="form-group__label">Defend it with 1-3 supporting arguments.</label>
                        <NTextarea v-model="form.fields.deep_dives.what.core_cause_2nd.supporting" :disabled="disabled" />
                    </div>
                </div>
                <div class="form-groups__numbered-section">
                    <div class="form-group">
                        <label class="form-group__label is-blue">One more time: what is the tertiary cause of this cause?</label>
                        <NTextarea size="small" v-model="form.fields.deep_dives.what.core_cause_3rd.string" :disabled="disabled" />
                    </div>
                    <div class="form-group">
                        <label class="form-group__label">Defend it with 1-3 supporting arguments.</label>
                        <NTextarea v-model="form.fields.deep_dives.what.core_cause_3rd.supporting" :disabled="disabled" />
                    </div>
                </div>
            </div>
            <div class="form-group mt-5">
                <h2 class="form-group__title is-blue">Feasibility</h2>
                <p class="form-block-help-text">
                    Even if all 3 core causes are correct, <u>which one</u> is most likely to be solved with the time and resources available (to your organization, to your audience, in this problem space)? In general, try to pick the deepest cause that’s possibly feasible.
                </p>
                <div class="numbered-custom-radio-wrapper" >
                    <div class="numbered-custom-radio">
                        <input
                            class="numbered-custom-radio__input"
                            type="radio"
                            id="feasibility-1"
                            v-model="form.fields.deep_dives.what.feasibility_choice"
                            value="first"
                            :disabled="disabled"
                        >
                        <label for="feasibility-1" class="numbered-custom-radio__label">
                            <span class="numbered-custom-radio__info"></span>
                            <div class="numbered-custom-radio__content">
                                {{ excerpt(form.fields.deep_dives.what.core_cause_1st.string || 'Please fill the cause', 50) }}
                            </div>
                        </label>
                    </div>
                    <div class="numbered-custom-radio">
                        <input
                            class="numbered-custom-radio__input"
                            type="radio"
                            id="feasibility-2"
                            v-model="form.fields.deep_dives.what.feasibility_choice"
                            value="second"
                            :disabled="disabled"
                        >
                        <label for="feasibility-2" class="numbered-custom-radio__label">
                            <span class="numbered-custom-radio__info"></span>
                            <div class="numbered-custom-radio__content">
                                {{ excerpt(form.fields.deep_dives.what.core_cause_2nd.string || 'Please fill the cause', 50) }}
                            </div>
                        </label>
                    </div>
                    <div class="numbered-custom-radio">
                        <input
                            class="numbered-custom-radio__input"
                            type="radio"
                            id="feasibility-3"
                            v-model="form.fields.deep_dives.what.feasibility_choice"
                            value="third"
                            :disabled="disabled"
                        >
                        <label for="feasibility-3" class="numbered-custom-radio__label">
                            <span class="numbered-custom-radio__info"></span>
                            <div class="numbered-custom-radio__content">
                                {{ excerpt(form.fields.deep_dives.what.core_cause_3rd.string || 'Please fill the cause', 50) }}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group mt-5">
                <h2 class="form-group__title is-blue">Impact</h2>
                <p class="form-block-help-text">List the possible steps or components in addressing <b><i>this core cause:</i></b></p>
                <div class="repeater-form-group mb-4 has-counter">
                    <div class="two-col-repeater-row__label">Hardest?</div>
                    <div
                        class="repeater-form-group__row"
                        v-for="(impact, index) in form.fields.deep_dives.what.impact_steps"
                        :key="index"
                    >
                        <div class="two-col-repeater-row">
                            <div class="two-col-repeater-row__col">
                                <NInput type="text" v-model="form.fields.deep_dives.what.impact_steps[index].text" :disabled="disabled"></NInput>
                            </div>
                            <div class="two-col-repeater-row__col">
                                <label class="custom-radio" @click.prevent="selectHardest(index)">
                                    <input type="radio" name="hardest" :checked="impact.selected">
                                    <span class="custom-radio__label"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div v-if="!disabled" class="repeater-form-group__add-more">
                        <button aria-label="button"
                            type="button"
                            class="btn is-small is-white"
                            @click.prevent="form.fields.deep_dives.what.impact_steps.push({ text: null, selected: false })"
                        >
                            <span aria-hidden="true" class="btn__icon is-left icon-plus-square-solid"></span>
                            Add another
                        </button>
                    </div>
                </div>
                <p class="form-block-help-text mt-3">
                    <b>Then pick the “hardest” step or the one with the most impact — Start with the Monkey first!</b>
                    <img src="/img/monkey-icon.svg" class="ml-2" style="margin-top: -3px;" alt="">
                </p>
                <p class="form-block-help-text">
                    <b>The Payoff:</b> once you’ve identified the “needle in the haystack” component (e.g. not depression, but compliance in sleep apnea treatment), your original question can now be honed... increasing your chances of getting the <b>right</b> solution from the community.
                </p>
            </div>
        </div>
        <div v-if="!implicit" class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <b>Note:</b> these worksheets are helpful to the community of innovators because they can understand your thought process and your   point of view. They can see your initial “exploratory” question and your honed final question.
            </p>
        </div>
    </div>
</template>
