import { Session } from '@/utils/Auth';

export const authorization = axios => {
    axios.interceptors.request.use(config => {
        const token = Session.getToken();
        if (!token) return config;

        config.headers.set('Authorization', `Bearer ${token}`);

        return config;
    });
};

export const unauthorized = axios => {
    axios.interceptors.response.use(response => response, error => {
        const { response, config } = error;

        if (response && response.status === 401 && !['/me/detailed', '/me/preferences'].includes(config.url)) {
            const redirect = `${import.meta.env.VUE_APP_ACCOUNT_URL}/logout`;
            const [, appName] = location.hostname.split('.').reverse();
            const continueURL = encodeURIComponent(location.href);

            setTimeout(() => {
                location.replace(`${redirect}?continue=${continueURL}&platform=${appName}`);
            }, 2000)
        }
    
        return Promise.reject(error);
    });
}
