<script setup>
import { computed, nextTick, watch } from 'vue';
import { useCurrentElement } from '@vueuse/core';
import { debounce } from 'lodash'

const props = defineProps({
    simple: {
        type: Boolean,
        default: false
    },
    level: {
        type: Number,
        default: 0
    },
    colorful: {
        type: Boolean,
        default: false
    },
    animationTiming: {
        type: String,
        default: '0.96s'
    },
});

defineEmits(['selected']);

const el = useCurrentElement();

const sectionColor = computed(() => {
    return props.simple ? 'transparent' : '#eee';
})

const reset = () => {
    const regions = el.value.querySelectorAll(
        props.level > 0
            ? `path.fill-section:is(:nth-child(n+${props.level+1}))`
            : 'path.fill-section'
    );

    regions.forEach((region) => {
        region.style.fill = region.style.stroke = '#eee';
    });
}


const fill = debounce(() => {
    reset();

    const regions = el.value.querySelectorAll(`path.fill-section:not(:nth-child(n+${props.level+1}))`);

    regions.forEach((region, index) => {
        setTimeout(() => {
            region.style.fill = region.style.stroke = 'currentColor';
        }, index * 10);
    });
}, 300)

watch(() => ([props.level, el.value]), () => {
    if (!el.value) {
        return;
    }

    nextTick(() => {
        fill();
    });
}, { immediate: true });
</script>

<template>
    <svg class="brain" viewBox="20 123 363 136">
        <g fill="none" fill-rule="evenodd">
            <g class="fill-wrapper" :class="{ colorful }">
                <path class="fill-section" d="M289 368h-36l-2-13z" @click="$emit('selected', 1)"/>
                <path class="fill-section" d="M284 337l5 31-38-13z" @click="$emit('selected', 2)"/>
                <path class="fill-section" d="M244 323l40 14-33 18z" @click="$emit('selected', 3)"/>
                <path class="fill-section" d="M278 304l-34 19 40 14z" @click="$emit('selected', 4)"/>
                <path class="fill-section" d="M239 297l39 7-34 19z" @click="$emit('selected', 5)"/>
                <path class="fill-section" d="M256 290l22 14-39-7z" @click="$emit('selected', 6)"/>
                <path class="fill-section" d="M213 274l43 16-17 7z" @click="$emit('selected', 7)"/>
                <path class="fill-section" d="M237 263l19 27-43-16z" @click="$emit('selected', 8)"/>
                <path class="fill-section" d="M196 258l41 5-24 11z" @click="$emit('selected', 9)"/>
                <path class="fill-section" d="M224 243l13 20-41-5z" @click="$emit('selected', 10)"/>

                <path class="fill-section" d="M257 290l22 14-41-41z" @click="$emit('selected', 11)"/>
                <path class="fill-section" d="M278 304l-34-36 48 34z" @click="$emit('selected', 12)"/>
                <path class="fill-section" d="M302 302h-10l-55-39 71 34z" @click="$emit('selected', 13)"/>
                <path class="fill-section" d="M308 297l14-12-85-22z" @click="$emit('selected', 14)"/>
                <path class="fill-section" d="M322 285l11-9-96-13z" @click="$emit('selected', 15)"/>
                <path class="fill-section" d="M333 276l14-15-110 2z" @click="$emit('selected', 16)"/>
                <path class="fill-section" d="M347 261v-21l-110 23z" @click="$emit('selected', 17)"/>
                <path class="fill-section" d="M237 263l52-29 58 6z" @click="$emit('selected', 18)"/>
                <path class="fill-section" d="M237 263l52-29-30 9z" @click="$emit('selected', 19)"/>
                <path class="fill-section" d="M224 243h35l-22 20z" @click="$emit('selected', 20)"/>

                <path class="fill-section" d="M224 243l-49-3 21 18z" @click="$emit('selected', 21)"/>
                <path class="fill-section" d="M175 240l49 3-28-18-6-19z" @click="$emit('selected', 22)"/>
                <path class="fill-section" d="M164 264l32-6-21-18z" @click="$emit('selected', 23)"/>
                <path class="fill-section" d="M120 253l55-13-11 24z" @click="$emit('selected', 24)"/>
                <path class="fill-section" d="M120 253l70-47-15 34z" @click="$emit('selected', 25)"/>
                <path class="fill-section" d="M138 206l-18 47 70-47z" @click="$emit('selected', 26)"/>
                <path class="fill-section" d="M177 169l-39 37h52l-18-10z" @click="$emit('selected', 27)"/>
                <path class="fill-section" d="M120 253l-17-34 35-13z" @click="$emit('selected', 28)"/>
                <path class="fill-section" d="M103 219l35-13-3-25-19 16z" @click="$emit('selected', 29)"/>
                <path class="fill-section" d="M135 181l3 25 39-37h-15z" @click="$emit('selected', 30)"/>

                <path class="fill-section" d="M224 243l16-27 18 27z" @click="$emit('selected', 31)"/>
                <path class="fill-section" d="M287 234l-48-19 18 27z" @click="$emit('selected', 32)"/>
                <path class="fill-section" d="M285 211l-44 5 48 18z" @click="$emit('selected', 33)"/>
                <path class="fill-section" d="M257 184l-16 32 26-3 8-25z" @click="$emit('selected', 34)"/>
                <path class="fill-section" d="M190 206l51 10-17 27-28-18z" @click="$emit('selected', 35)"/>
                <path class="fill-section" d="M257 184l-67 22 51 10z" @click="$emit('selected', 36)"/>
                <path class="fill-section" d="M210 169h28l19 15-23 7z" @click="$emit('selected', 37)"/>
                <path class="fill-section" d="M190 206l44-15-24-22z" @click="$emit('selected', 38)"/>
                <path class="fill-section" d="M172 196l5-27h33l-20 37z" @click="$emit('selected', 39)"/>
                <path class="fill-section" d="M177 169l12-14 24-3 30-13-5 30z" @click="$emit('selected', 40)"/>

                <path class="fill-section" d="M268 212l7-24-19-5 28-12 5 35-4 5z" @click="$emit('selected', 41)"/>
                <path class="fill-section" d="M289 206l-5-35 18 20z" @click="$emit('selected', 42)"/>
                <path class="fill-section" d="M285 147l-1 24 18 20v-31z" @click="$emit('selected', 43)"/>
                <path class="fill-section" d="M243 139l41 32-28 12-18-14z" @click="$emit('selected', 44)"/>
                <path class="fill-section" d="M285 147l-42-8 41 32z" @click="$emit('selected', 45)"/>
                <path class="fill-section" d="M280 115l-37 24 42 8z" @click="$emit('selected', 46)"/>
                <path class="fill-section" d="M302 108l-22 7 5 32z" @click="$emit('selected', 47)"/>
                <path class="fill-section" d="M265 91l-22 48 37-24z" @click="$emit('selected', 48)"/>
                <path class="fill-section" d="M285 74l-5 41 22-7z" @click="$emit('selected', 49)"/>
                <path class="fill-section" d="M280 115l5-41h-20v16z" @click="$emit('selected', 50)"/>

                <path class="fill-section" d="M333 203l-44 31 58 6z" @click="$emit('selected', 51)"/>
                <path class="fill-section" d="M333 203l-31-12-17 20 4 23z" @click="$emit('selected', 52)"/>
                <path class="fill-section" d="M375 192l-42 11 14 37 25-13 9-25z" @click="$emit('selected', 53)"/>
                <path class="fill-section" d="M324 169l-22 7v15l31 12z" @click="$emit('selected', 54)"/>
                <path class="fill-section" d="M375 192l-51-23 9 34z" @click="$emit('selected', 55)"/>
                <path class="fill-section" d="M357 169h-33l51 23z" @click="$emit('selected', 56)"/>
                <path class="fill-section" d="M381 179l-24-10 18 23z" @click="$emit('selected', 57)"/>
                <path class="fill-section" d="M370 146l-13 23 24 10z" @click="$emit('selected', 58)"/>
                <path class="fill-section" d="M324 169l17-18 16 18z" @click="$emit('selected', 59)"/>
                <path class="fill-section" d="M341 151l16 18 13-23z" @click="$emit('selected', 60)"/>

                <path class="fill-section" d="M302 176l22-40 46 10-29 5-17 18z" @click="$emit('selected', 61)"/>
                <path class="fill-section" d="M302 176l22-40-39 11 17 13z" @click="$emit('selected', 62)"/>
                <path class="fill-section" d="M370 122l-46 14 46 10z" @click="$emit('selected', 63)"/>
                <path class="fill-section" d="M285 147l39-11-22-28z" @click="$emit('selected', 64)"/>
                <path class="fill-section" d="M302 108l68 14-46 14z" @click="$emit('selected', 65)"/>
                <path class="fill-section" d="M328 89l-26 19 68 14z" @click="$emit('selected', 66)"/>
                <path class="fill-section" d="M370 122l-31-48-11 15z" @click="$emit('selected', 67)"/>
                <path class="fill-section" d="M285 74l43 15-26 19z" @click="$emit('selected', 68)"/>
                <path class="fill-section" d="M304 45l24 44-43-15 17-11z" @click="$emit('selected', 69)"/>
                <path class="fill-section" d="M339 74l-37-30 26 45z" @click="$emit('selected', 70)"/>

                <path class="fill-section" d="M156 110l53-7-33 66h-14l3-30z" @click="$emit('selected', 71)"/>
                <path class="fill-section" d="M209 103l34 36-30 13-24 3-12 13z" @click="$emit('selected', 72)"/>
                <path class="fill-section" d="M265 74v18l-22 47-34-36z" @click="$emit('selected', 73)"/>
                <path class="fill-section" d="M190 44l19 59-53 7 16-12 15-24-5-18z" @click="$emit('selected', 74)"/>
                <path class="fill-section" d="M265 74l-75-30 19 59z" @click="$emit('selected', 75)"/>
                <path class="fill-section" d="M190 44l54-9 21 39z" @click="$emit('selected', 76)"/>
                <path class="fill-section" d="M265 74l-21-39 58 28-17 11z" @click="$emit('selected', 77)"/>
                <path class="fill-section" d="M302 44l-16-10-42 1 58 28z" @click="$emit('selected', 78)"/>
                <path class="fill-section" d="M244 35l-41-19 83 19z" @click="$emit('selected', 79)"/>
                <path class="fill-section" d="M174 26l29-10 41 19-54 9z" @click="$emit('selected', 80)"/>

                <path class="fill-section" d="M134 98l22 12 9 29-3 30-27 12-1-12z" @click="$emit('selected', 81)"/>
                <path class="fill-section" d="M87 121l47-23v71l-19-8-4-27z" @click="$emit('selected', 82)"/>
                <path class="fill-section" d="M182 56l-48 42 22 12 16-12 15-24z" @click="$emit('selected', 83)"/>
                <path class="fill-section" d="M98 87l36 11-47 23z" @click="$emit('selected', 84)"/>
                <path class="fill-section" d="M182 56L98 87l36 11z" @click="$emit('selected', 85)"/>
                <path class="fill-section" d="M140 44L98 87l84-31z" @click="$emit('selected', 86)"/>
                <path class="fill-section" d="M98 87L79 77l61-33z" @click="$emit('selected', 87)"/>
                <path class="fill-section" d="M79 77l61-33-42 3-19 14z" @click="$emit('selected', 88)"/>
                <path class="fill-section" d="M175 27l15 17-8 12-42-12z" @click="$emit('selected', 89)"/>
                <path class="fill-section" d="M146 26l29 1-35 17-40 2 28-5z" @click="$emit('selected', 90)"/>

                <path class="fill-section" d="M79 172l56 9-19 16-13 22-34-2z" @click="$emit('selected', 91)"/>
                <path class="fill-section" d="M31 187l48-15-10 45z" @click="$emit('selected', 92)"/>
                <path class="fill-section" d="M22 145l57 27-48 15z" @click="$emit('selected', 93)"/>
                <path class="fill-section" d="M111 134l-32 38 56 9-1-12-19-8z" @click="$emit('selected', 94)"/>
                <path class="fill-section" d="M22 145l89-11-32 38z" @click="$emit('selected', 95)"/>
                <path class="fill-section" d="M87 121l-25-13-40 37 89-11z" @click="$emit('selected', 96)"/>
                <path class="fill-section" d="M22 145l40-37-23-5-10 5z" @click="$emit('selected', 97)"/>
                <path class="fill-section" d="M98 87l-36 21 25 13z" @click="$emit('selected', 98)"/>
                <path class="fill-section" d="M39 103l23 5 3-37z" @click="$emit('selected', 99)"/>
                <path class="fill-section" d="M65 71l-3 37 36-21-19-10V61z" @click="$emit('selected', 100)"/>
            </g>

            <g class="section-strokes">
                <path d="M289 368h-36l-2-13z"/>
                <path d="M284 337l5 31-38-13z"/>
                <path d="M244 323l40 14-33 18z"/>
                <path d="M278 304l-34 19 40 14z"/>
                <path d="M239 297l39 7-34 19z"/>
                <path d="M256 290l22 14-39-7z"/>
                <path d="M213 274l43 16-17 7z"/>
                <path d="M237 263l19 27-43-16z"/>
                <path d="M196 258l41 5-24 11z"/>
                <path d="M224 243l13 20-41-5z"/>

                <path stroke-linecap="square" stroke-linejoin="round" d="M256 290l22 14-41-41z"/>
                <path d="M278 304l-36-37 50 35z"/>
                <path stroke-linejoin="round" d="M302 302h-10l-55-39 71 34z"/>
                <path d="M308 297l14-12-85-22z"/>
                <path d="M322 285l11-9-96-13z"/>
                <path d="M333 276l14-15-110 2z"/>
                <path d="M347 261v-21l-110 23z"/>
                <path d="M237 263l52-29 58 6z"/>
                <path stroke-linejoin="round" d="M237 263l52-29-30 9z"/>
                <path d="M224 243h35l-22 20z"/>

                <path d="M224 243l-49-3 21 18z"/>
                <path d="M175 240l49 3-28-18-6-19z"/>
                <path d="M164 264l32-6-21-18z"/>
                <path d="M120 253l55-13-11 24z"/>
                <path stroke-linejoin="round" d="M120 253l70-47-15 34z"/>
                <path d="M138 206l-18 47 70-47z"/>
                <path d="M177 169l-39 37h52l-18-10z"/>
                <path d="M120 253l-17-34 35-13z"/>
                <path d="M103 219l35-13-3-25-19 16z"/>
                <path d="M135 181l3 25 39-37h-15z"/>

                <path d="M224 243l17-27 18 27z"/>
                <path d="M289 234l-48-18 18 27z"/>
                <path d="M285 211l-44 5 48 18z"/>
                <path d="M257 184l-16 32 26-3 8-25z"/>
                <path d="M190 206l51 10-17 27-28-18z"/>
                <path d="M257 184l-67 22 51 10z"/>
                <path d="M210 169h28l19 15-23 7z"/>
                <path d="M190 206l44-15-24-22z"/>
                <path d="M172 196l5-27h33l-20 37z"/>
                <path d="M177 169l12-14 24-3 30-13-5 30z"/>

                <path d="M268 212l7-24-19-5 28-12 5 35-4 5z"/>
                <path d="M289 206l-5-35 18 20z"/>
                <path d="M285 147l-1 24 18 20v-31z"/>
                <path d="M243 139l41 32-28 12-18-14z"/>
                <path d="M285 147l-42-8 41 32z"/>
                <path d="M280 115l-37 24 42 8z"/>
                <path d="M302 108l-22 7 5 32z"/>
                <path d="M265 91l-22 48 37-24z"/>
                <path d="M285 74l-5 41 22-7z"/>
                <path d="M280 115l5-41h-20v16z"/>

                <path d="M333 203l-44 31 58 6z"/>
                <path d="M333 203l-31-12-17 20 4 23z"/>
                <path d="M375 192l-42 11 14 37 25-13 9-25z"/>
                <path d="M324 169l-22 7v15l31 12z"/>
                <path d="M375 192l-51-23 9 34z"/>
                <path d="M357 169h-33l51 23z"/>
                <path d="M381 179l-24-10 18 23z"/>
                <path d="M370 146l-13 23 24 10z"/>
                <path d="M324 169l17-18 16 18z"/>
                <path d="M341 151l16 18 13-23z"/>

                <path stroke-linejoin="round" d="M302 176l22-40 46 10-29 5-17 18z"/>
                <path stroke-linejoin="round" d="M302 176l22-40-39 11 17 13z"/>
                <path stroke-linejoin="round" d="M370 122l-46 14 46 10z"/>
                <path stroke-linejoin="round" d="M285 147l39-11-22-28z"/>
                <path stroke-linejoin="round" d="M302 108l68 14-46 14z"/>
                <path stroke-linejoin="round" d="M328 89l-26 19 68 14z"/>
                <path stroke-linejoin="round" d="M370 122l-31-48-11 15z"/>
                <path stroke-linejoin="round" d="M285 74l43 15-26 19z"/>
                <path stroke-linejoin="round" d="M304 45l24 44-43-15 17-11z"/>
                <path stroke-linejoin="round" d="M339 74l-37-30 26 45z"/>

                <path d="M156 110l53-7-33 66h-14l3-30z"/>
                <path stroke-linejoin="round" d="M209 103l34 36-30 13-24 3-12 13z"/>
                <path d="M265 74v18l-22 47-34-36z"/>
                <path d="M190 44l19 59-53 7 16-12 15-24-5-18z"/>
                <path d="M265 74l-75-30 19 59z"/>
                <path d="M190 44l54-9 21 39z"/>
                <path d="M265 74l-21-39 58 28-17 11z"/>
                <path d="M302 44l-16-10-42 1 58 28z"/>
                <path stroke-linejoin="round" d="M244 35l-41-19 83 19z"/>
                <path d="M174 26l29-10 41 19-54 9z"/>

                <path d="M134 98l22 12 9 29-3 30-27 12-1-12z"/>
                <path d="M87 121l47-23v71l-19-8-4-27z"/>
                <path d="M182 56l-48 42 22 12 16-12 15-24z"/>
                <path d="M98 87l36 11-47 23z"/>
                <path d="M182 56L98 87l36 11z"/>
                <path d="M140 44L98 87l84-31z"/>
                <path d="M98 87L79 77l61-33z"/>
                <path d="M79 77l61-33-42 3-19 14z"/>
                <path d="M175 27l15 17-8 12-42-12z"/>
                <path d="M146 26l29 1-35 17-40 2 28-5z"/>

                <path d="M79 172l56 9-19 16-13 22-34-2z"/>
                <path d="M31 187l48-15-10 45z"/>
                <path d="M22 145l57 27-48 15z"/>
                <path d="M111 134l-32 38 56 9-1-12-19-8z"/>
                <path d="M22 145l89-11-32 38z"/>
                <path d="M87 121l-25-13-40 37 89-11z"/>
                <path d="M22 145l40-37-23-5-10 5z"/>
                <path d="M98 87l-36 21 25 13z"/>
                <path d="M40 103l22 5 3-37z"/>
                <path d="M65 71l-3 37 36-21-19-10V61z"/>
            </g>

            <g style="stroke: currentColor;" stroke-width="6">
                <path d="M253 368h36l-11-64 11-2h13l20-17 11-9 14-15v-21l25-13 9-25-6-10 6-13-9-25-2-8v-24l-31-48-35-29-18-11-41-11-39-8-32 11h-28l-18 15-28 5-18 13-17 12-20 28-16 9-7 37 9 42 38 30 34 2 17 34 44 11 33-5 42 38z"/>
            </g>

            <g stroke="#fff" stroke-width="2">
                <path d="M224 243h31l34-9 58 6v21l-45 41h-13l-11 2-22-14zm0 0l-28-18-6-19-18-10 5-27h-15l-27 12-19 16-13 22 17 34 44 11 32-6z"/>
                <path d="M289 234l-4-23-17 1 7-24-18-4-19-15 5-30-30 13-24 3-12 14-5 27 18 10 6 19 28 18h31z"/>
                <path d="M285 211l17-20v-31l-17-13 17-39-17-34h-20v16l-22 49-5 30 19 15 18 4-7 24z"/>
                <path d="M370 146l-29 5-17 18-22 7v15l-17 20 4 23 58 6 25-13 9-25-6-10 6-13z"/>
                <path d="M302 44v19l-17 11 17 34-17 39 17 13v16l22-7 17-18 29-5v-24l-31-48z"/>
                <path d="M174 26l16 18-8 12 5 18-15 24-16 12 9 29-3 30h15l12-14 24-3 30-13 22-49V74h20l17-11V44l-16-10-80-19z"/>
                <path d="M79 61v16l19 10-11 34 24 13 4 27 19 8 1 12 27-12 3-30-9-29 16-12 15-24-5-18 8-12-16-18h-28l-18 15-28 5z"/>
                <path d="M103 219l13-22 19-16-1-12-19-8-4-27-24-13 11-34-19-10V61L65 71 45 99l-16 9-7 37 9 42 38 30z"/>
            </g>
        </g>
    </svg>
</template>

<style scoped>
.brain {
    width: 100%;
    height: 100%;
}

.colorful.fill-wrapper path:not(:nth-child(1n + 101)) {
    fill: rgb(236, 43, 132);
    stroke: rgb(236, 43, 132);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 91)) {
    fill: rgb(25, 125, 191);
    stroke: rgb(25, 125, 191);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 81)) {
    fill: rgb(197, 47, 54);
    stroke: rgb(197, 47, 54);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 71)) {
    fill: rgb(72, 187, 89);
    stroke: rgb(72, 187, 89);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 61)) {
    fill: rgb(249, 237, 65);
    stroke: rgb(249, 237, 65);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 51)) {
    fill: rgb(253, 168, 42);
    stroke: rgb(253, 168, 42);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 41)) {
    fill: rgb(126, 209, 223);
    stroke: rgb(126, 209, 223);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 31)) {
    fill: rgb(208, 145, 52);
    stroke: rgb(208, 145, 52);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 21)) {
    fill: rgb(189, 95, 140);
    stroke: rgb(189, 95, 140);
}

.colorful.fill-wrapper path:not(:nth-child(1n + 11)) {
    fill: rgb(208, 221, 66);
    stroke: rgb(208, 221, 66);
}

.fill-wrapper path.fill-section {
    fill: #eee;
    stroke: #eee;
}

.fill-wrapper path.fill-section,
.section-strokes {
    transition: fill v-bind(animationTiming) ease-in-out, stroke v-bind(animationTiming) ease-in-out
}

.section-strokes {
    stroke: v-bind(sectionColor);
}
</style>
