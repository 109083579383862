<script>
import FlexTable from '@/components/FlexTable.vue';
import { date as formatDate } from '@bignerve/ui-utils';

export default {
    props: {
        challenge: Object
    },

    components: {
        FlexTable,
    },

    data () {
        return {
            busy: false,
            ideas: []
        };
    },

    computed: {
        catalyst () {
            return this.challenge.catalyst;
        },
        header () {
            return [
                {
                    text: this.$responsive.sm ? 'Idea' : '',
                    name: 'position',
                    grow: .5,
                },
                {
                    text: this.$responsive.sm ? '' : 'Idea Title (tap to expand to view idea detail)',
                    name: 'title',
                    grow: 3
                },
                {
                    text: 'Date',
                    align: 'center',
                    name: 'created_at',
                },
                {
                    text: 'Innovator',
                    name: 'solver',
                },
            ]
        },
    },

    methods: {
        formatDate,
        async fetchIdeas () {
            this.busy = true;

            try {
                const { data: response } = await this.$api.challenges.promotions(this.challenge.slug);

                this.ideas = response.data.ideas;

                this.$emit('loaded', response.data);
            } finally {
                this.busy = false;
            }
        },
    },

    mounted () {
        this.fetchIdeas();
    },
}
</script>

<template>
    <div>
        <h3>These ideas for new Questions were promoted by {{ `${catalyst.first_name} ${catalyst.last_name}` }} into full Questions</h3>
        <p class="italic text-dust-700">Kudos to these players who helped generate interesting new questions for <a :href="`/${catalyst.handle}`" target="_blank" class="text-dust-700"><span class="underline">{{ `${catalyst.first_name} ${catalyst.last_name}` }}’s platform</span><NIcon as="external-link" class="text-sm" right /></a></p>

        <FlexTable
            :header="header"
            :items="ideas"
            header-class="border-b-2 pb-3"
            row-class="border-b py-0 border-dashed border-dust-300"
            class="bg-white border rounded-lg px-2 py-4">
            
            <template #col:position="{ item }">
                <div class="py-4 pr-3 flex items-center gap-4">
                    <NIcon as="qq" class="text-xl text-innovator-500" />

                    <span v-if="item.ranked_position" class="h-8 w-8 flex bg-dust-100 items-center justify-center font-semibold rounded-full text-dust-700">
                        {{ item.ranked_position }}
                    </span>
                </div>
            </template>

            <template #col:title="{ item }">
                <router-link :to="{ name: 'challenges.show', params: { challenge: item.promotion_challenge_slug} }" target="_blank" class="font-bold text-dust-700">
                    {{ item.title }} <NIcon as="arrow-circle-right" right />
                </router-link>
            </template>

            <template #col:created_at="{ item }">
                <span class="text-dust-800">
                    {{ formatDate(item.created_at) }}
                </span>
            </template>

            <template #col:solver="{ item }">
                <NPlayerDetails :user="item.solver" simple medium />
            </template>

            <template #col:actions="{ item }">
                <div>
                    <button aria-label="button" type="button" class="flex items-center justify-center min-w-33 bg-dust-100 px-4 py-1 rounded-md hover:bg-dust-200" @click="!savedToPromote(idea) && saveToPromote(idea)">
                            <NLoading v-if="item.promoting" class="text-dust-700 -my-1" loading />
                            <NIcon v-else :as="!savedToPromote(item) ? 'heart' : 'heart-solid'" class="mr-2" />
                        {{ item.promoting ? 'Loading...' : 'Promote' }}
                    </button>
                </div>
            </template>
        </FlexTable>
    </div>
</template>