<script>
    export default {
        inject: ['form'],

        mounted() {
            this.form.fields.challenge.question_title = this.form.fields.challenge.question_title.replace(/^(What could|How could)/i, '');
        }
    }
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">
                <span class="challenge-section-heading__qq-tag">QQ</span>
                <b>What could be a good question regarding:</b>
            </h2>
            <p class="challenge-section-heading__description">
                In this Challenge type, you are asking the Innovators to do the work of a Catalyst by designing insightful questions for you.
            </p>
        </div>
        <div class="form-section-wrapper">
            <div class="form-groups">
                <div class="form-group">
                    <label class="form-group__label" for="question_detail">
                        Describe the topic or <u>problem space</u> that you need a good question for:
                        <span class="form-inline-help-text">Upto 1000 chars max</span>
                    </label>
                    <NTextarea
                        name="question_detail"
                        v-model="form.fields.challenge.question_detail"
                        maxlength="1000"
                        id="question_detail"
                    ></NTextarea>
                </div>
                <div class="form-group">
                    <label class="form-group__label" for="question_title">
                        Summarize it to make a Title for your QQ challenge
                        <span class="form-inline-help-text">Upto 140 chars max</span>
                    </label>
                    <NInput
                        name="question_title"
                        v-model="form.fields.challenge.question_title"
                        maxlength="140"
                        id="question_title"
                    ></NInput>
                    <p class="mt-1 mb-0">
                        <em>We will add "What could be a good question regarding:" for you, so summarize your topic succinctly.</em>
                    </p>
                </div>
            </div>
        </div>
        <div class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <div class="info-box__content">
                <p>
                    <b>Note:</b> in this Challenge type, you are asking the Innovators to do the work of a Catalyst by designing a better question for. Your challenge will appear on your Home Platform (as a house challenge) and on bignerve.com under QQ filter. We are excited about this challenge type because insightful questions are at the core of good ideas.
                </p>
            </div>
        </div>
    </div>
</template>
