<script>
import random from 'lodash/random';
import BrainLevelBoard from '@/pages/dashboard/progress/score-stats/nerve-score/BrainLevelBoard.vue';
import { useInterval } from '@/mixins/Interval';
import { scaleLinear } from 'd3';
import { ranks, thresholds } from './brain-level-data';

export default {
    name: 'BrainLevelDemo',
    mixins: [
        useInterval({
            handler: 'autoNextLevel',
            immediate: true,
            ms: 50,
        }),
    ],
    props: {
        control: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BrainLevelBoard
    },
    data () {
        return {
            step: 0,
            currentBrain: {
                id: "278afb49-ae3e-4e13-8214-49c7fefd7b5c",
                user_id: "ND58KFC7WF",
                role: "overall",
                score: 0,
                brain_level: 0,
                rank_id: "overall-10",
                rank_position: 0,
                rank_description: "",
                rank_label: "Archimedes",
                start_level_score: 0,
                next_level_score: 51,
                next_rank_level: 10
            },
            ranks,
        }
    },
    methods: {
        autoNextLevel () {
            this.step = this.step + 1;

            this.nextLevel(this.step)

            if (this.step >= 100) {
                this.intervalStopUpdater();
            }
        },
        nextLevel (step = 0) {
            const levelScale = scaleLinear()
                .domain([0, 100])
                .range([0, 100])
            const level = Number(Math.floor(levelScale(step)).toFixed(0))
            const rankScale = scaleLinear()
                .domain([0, 100])
                .range([1, 10])
            const rankPosition = Number(Math.floor(rankScale(level)).toFixed(0))
            const threshold = thresholds[level]
            const nextThreshold = thresholds[level + 1]

            if (step) {
                this.currentBrain = {
                    ...this.currentBrain,
                    rank_position: rankPosition,
                    start_level_score: threshold.start,
                    next_level_score: nextThreshold ? nextThreshold.start : threshold.end,
                    score: level < 100 ? random(threshold.start, threshold.end) : threshold.end,
                    brain_level: level,
                };
            } else {
                this.clear();
            }
        },
        clear () {
            this.step = 0;

            this.currentBrain.brain_level = 0;
            this.currentBrain.rank_position = 0;
            this.currentBrain.start_level_score = 0;
            this.currentBrain.next_level_score = 51;
            this.currentBrain.score = 0;
        },
        resetLevel () {
            this.clear();

            setTimeout(() => {
                this.intervalStopUpdater();
                this.instervalStartUpdater();
            }, 2000)
        }
    }
}
</script>

<template>
    <div>
        <BrainLevelBoard :brain="currentBrain" :ranks="ranks" :options="{ bar: { speed: step === 0 ? 'slow' : 'fast' } }" />

        <div v-if="control" class="flex items-center">
            <button aria-label="button" v-if="currentBrain.brain_level === 100" type="button" class="border rounded-md w-10 h-8 flex items-center justify-center text-dust-700 hover:bg-dust-200" @click="resetLevel">
                <svg class="w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="redo-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"></path></svg>
            </button>
            <button aria-label="button" v-else-if="!intervalUpdater" type="button" class="border rounded-md w-10 h-8 flex items-center justify-center text-dust-700 hover:bg-dust-200" @click="instervalStartUpdater">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/></svg>
            </button>
            <button aria-label="button" v-else type="button" class="border rounded-md w-10 h-8 flex items-center justify-center text-dust-700 hover:bg-dust-200" @click="intervalStopUpdater">
                <svg  class="w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pause" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z"></path></svg>
            </button>
            <input v-model.number="step" type="range" id="level" name="level" min="0" max="100" class=" rounded-lg appearance-none bg-dust-200 h-2 min-w-40 outline-none range-progre mx-3 w-full" @input="nextLevel(step)" >
        </div>
    </div>
</template>

<style lang="scss">
  input[type='range'].range-progre::-webkit-slider-thumb {
    width: 15px;
    height: 15px;
    @apply bg-gray-600;
    @apply border-2;
    @apply border-white;
    @apply z-10;
    @apply cursor-pointer;
    @apply appearance-none;
    @apply rounded-md;
  }
</style>