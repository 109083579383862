export default requester => {
    return {
        /**
         * Retrives platform teams.
         *
         * @param {string} user
         * @param {object} params
         */
        teams (platform) {
            return requester.get(`/${platform}/teams`);
        },

        /**
         * Creates a content report
         *
         * @param {object} payload
         **/
        reportContent (payload = {}) {
            return requester.post('/immunity/reports', payload);
        },

        /**
         * Update platform
         * 
         * @param {string} slug 
         * @param {object} payload
         */
        save (slug, payload = {}) {
            return requester.put(`/platforms/${slug}`, payload);
        },

        /**
         * Retrives platform
         * 
         * @param {string} slug
         */
        find (slug) {
            return requester.get(`/platforms/${slug}`);
        },

        /**
         * Retrives platform preferences
         * 
         * @param {string} platformId
         */
        preferences (platformId) {
            return requester.get(`/platforms/${platformId}/preferences`);
        },

        /**
         * Save platform preferences
         * 
         * @param {string} platformId
         * @param {object} payload
         */
        savePreferences (platformId, payload = {}) {
            return requester.put(`/platforms/${platformId}/preferences`, payload);
        }
    };
}
