<script setup>
import Button from '@/components/ui/button/index.vue';
import Loading from '@/components/NLoading.vue';
import { api } from '@/modules/services';
import { ref } from 'vue';

const props = defineProps({
    label: {
        type: String,
        default: 'Delete All',
    },
    class: {
        type: String,
        default: '',
    },
});
const loading = ref(false);
const handleDelete = () => {
    loading.value = true;
    //
}

// defineOptions({
//     inheritAttrs: false,
// });
</script>

<template>
    <VDropdown  class="inline-block" placement="top" @hide="loading = false">
        <!-- This will be the popover reference (for the events and position) -->
        <button type="button" class="inline-block" :class="props.class">
            <slot>{{ props.label }}</slot>
        </button>

        <!-- This will be the content of the popover -->
        <template #popper="{ hide }">
            <div class="p-4">
                <p>Are you sure you want to delete all members?</p>
                <div class="flex justify-end gap-4 mt-4">
                    <Button variant="ghost" type="button" class="gap-1" @click="hide">
                        Cancel
                    </Button>
                    <Button variant="destructive" type="button" class="gap-1" :disabled="loading" @click="handleDelete">
                        <Loading v-if="loading" class="text-white" active />Delete
                    </Button>
                </div>
            </div>
        </template>
    </VDropdown>
</template>