/** @module ideaLogs */
export default requester => {
    return {
        /**
         * Gets an idea log by id.
         *
         * @param {string} ideaLogId The ID of the idea log
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        get(ideaLogId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/idea-log-by-id/${ideaLogId}`, params, headers);
        },

        /**
         * Creates a new idea log entry.
         *
         * @param {Object} ideaLog The idea log to create
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(ideaLog, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/idea-logs', { ideaLog }, params, headers);
        },

        /**
         * Gets all idea logs submitted by this user.
         *
         * @param {string} userId The ID of the user whose logs should be retrieved
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        byUser(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/idea-log-by-user/${userId}`, params, headers);
        }
    }
}
