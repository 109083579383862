export const ROLE = {
    ANALYST: 'analyst',
    SCOUT: 'scout',
    INNOVATOR: 'innovator',
    CATALYST: 'catalyst',
    MENTOR: 'mentor',
    DIRECTOR: 'director',
    PATRON: 'patron',
    INSIGHTFUL: 'insightful',
}

export const TYPE = {
    SYSTEM: 'system-platform',
    USER: 'user-platform',
}
