<script setup>
import NMarkdown from '@/components/NMarkdown.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import { ref, computed } from 'vue';
import { api as Bignerve } from '@/modules/services';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useFeatureFlag } from '@/composable/feature-flag';
import { useHead } from '@vueuse/head';

const route = useRoute();
const router = useRouter();
const loading = ref(false);
const checking = ref(true);
const store = useStore();
const { expect } = useFeatureFlag('question-collection-form');
const platform = ref(null);

const details = ref({
	company_name: '',
	email: '',
});

const questions = ref([
	{
		question_title: '',
		question_description: '',
		sending: false,
		sent: false,
	},
]);

const contextUser = computed(() => store.state.app.user.user);
const contextPlatform = computed(() => store.state.app.platform);

const isValid = computed(() => {
	return details.value.company_name && details.value.email;
});

useHead({
	title: 'Question Collector',
});

const scrollToQuestion = (index) => {
	setTimeout(() => {
		const el = document.getElementById(`question-${index}`);
		el.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}, 300);
};

const addQuestion = () => {
	if (questions.value.length >= 10) {
		return;
	}

	questions.value.push({
		question_title: '',
		question_description: '',
	});

	scrollToQuestion(questions.value.length - 1);
};

const removeQuestion = (index) => {
	questions.value.splice(index, 1);
};

const save = () => {
	questions.value
		.filter((question) => !question.sent)
		.forEach(async (question, i) => {
			try {
				if (!question.question_title || !question.question_description) {
					throw new Error('Please fill out all questions');
				}

				loading.value = true;
				question.sending = true;

				const { data } = await Bignerve.survey.create({
					content: {
						company_name: details.value.company_name,
						question_title: question.question_title,
						question_description: question.question_description,
						platform_slug: route.params.user || contextPlatform.value.slug,
						email: details.value.email,
					},
					survey_type: 'question_collection',
					collector_id: contextUser.value.id,
					collector_type: 'user',
					domain_id: contextPlatform.value.id,
					domain_type: 'platform',
				});

				loading.value = false;

				question.sending = false;
				question.sent = true;
			} catch (e) {
				loading.value = false;
				question.sent = false;
				question.error = e.message;

				console.error(e);
			}
		});
};

const fetch = async (slug) => {
	const { data: response } = await Bignerve.platform.find(slug);

	platform.value = response.data;
};

fetch(route.params.user || contextPlatform.value.slug);

const isAllowed = expect(() => route.params.user);

if (!!route.params.user) {
	if (!isAllowed.value) {
		router.push({ name: 'home.challenges' });
	} else {
		checking.value = false;
	}
} else {
	checking.value = false;
}
</script>

<template>
	<div
		v-if="checking"
		class="max-w-4xl mx-auto py-10 animate-loading"
	>
		<div class="w-full flex flex-col items-center">
			<div class="bg-dust-200 rounded-md h-[3.75rem] w-[10rem] mb-[5rem]"></div>
			<div class="w-full bg-dust-200 rounded-md h-[13rem] mb-10"></div>
			<div class="w-[5rem] self-start bg-dust-200 rounded-md h-6 mb-2"></div>
			<div class="w-full bg-dust-200 rounded-md h-[13rem] mb-10"></div>
			<div class="w-[5rem] self-start bg-dust-200 rounded-md h-6 mb-2"></div>
			<div class="w-full bg-dust-200 rounded-md h-[13rem]"></div>
		</div>
	</div>
	<div
		v-else
		class="landing-page w-full my-10"
	>
		<div
			v-if="platform && platform.organization_image"
			class="flex items-center justify-center mb-10"
		>
			<img
				:src="platform.organization_image"
				:alt="platform.title"
				class="max-h-[200px]"
			/>
		</div>
		<div class="max-w-4xl mx-auto">
			<NMarkdown
				v-if="platform"
				:code="platform.organization_detail"
				class="text-box mb-10 text-lg"
			/>
			<NDataForm action="">
				<h3>Your Details</h3>
				<div class="form-groups px-4 py-6 bg-dust-100 rounded-lg shadow mb-5">
					<div class="form-group">
						<label class="form-group__label"> Company Name </label>

						<NInput
							v-model="details.company_name"
							name="company_name"
							maxlength="180"
							field-name="company_name"
							aria-label="Company Name"
							required
						/>
					</div>
					<div class="form-group">
						<label class="form-group__label"> Email </label>

						<NInput
							v-model="details.email"
							name="email"
							type="email"
							maxlength="100"
							field-name="email"
							aria-label="Email"
							required
						/>
					</div>
				</div>

				<template
					v-for="(question, i) of questions"
					:key="i"
				>
					<NAlert
						v-if="question.sent"
						type="success"
						closable
						:open="true"
					>
						<div class="pt-3">
							<h3>
								<NIcon
									as="check-circle-solid"
									class="mr-2"
								/>
								Success! Your question has been sent
							</h3>
							<p class="!m-0">
								{{ question.question_title }}
							</p>
						</div>
					</NAlert>

					<div
						v-else
						:id="`question-${i}`"
						class="py-5"
					>
						<div class="flex justify-between items-center mb-3">
							<h3 class="!mb-0">Question {{ i + 1 }}</h3>

							<button aria-label="button"
								v-if="i !== 0"
								type="button"
								class="flex items-center px-3 py-1 rounded-md bg-dust-50 hover:bg-dust-100 focus:outline-transparent"
								@click="removeQuestion(i)"
							>
								<NIcon
									as="remove"
									class="mr-1"
								/>
								Remove
							</button>
						</div>
						<div class="form-groups px-4 py-6 bg-director-50 shadow rounded-lg">
							<div class="form-group">
								<label class="form-group__label"> Title of your question </label>

								<NTextarea
									v-model="question.question_title"
									name="question_title"
									maxlength="180"
									field-name="question_title"
									aria-label="Question Title"
									class="!min-h-[auto]"
									:rows="2"
									required
								/>

								<p class="mt-2 italic text-dust-700 text-sm">Use “What could / How could” 180 characters max</p>
							</div>

							<div class="form-group">
								<div class="quill-editor-wrapper">
									<span class="quill-editor-title"> Description </span>
									<TextEditor
										name="question_description"
										v-model="question.question_description"
										placeholder="Write as much as you need to describe your idea for a question"
										maxlength="1000"
										required
									/>
									<p class="mt-2 italic text-dust-700 text-sm">1000 characters max</p>
								</div>
							</div>
						</div>
					</div>

					<NAlert
						v-if="question.error"
						type="danger"
						closable
						:open="true"
					>
						<div class="pt-3">
							<h3>
								<NIcon
									as="check-circle-solid"
									class="mr-2"
								/>
								Error!
							</h3>
							<p class="!m-0">
								{{ question.error }}
							</p>
						</div>
					</NAlert>
				</template>

				<div class="mb-10">
					<button aria-label="button"
						type="button"
						class="font-bold flex items-center"
						@click="addQuestion"
					>
						<NIcon
							as="plus-square-solid"
							class="mr-1"
						/>
						Add another Question
					</button>
				</div>

				<div class="p-4 flex items-center justify-center flex-col gap-6">
					<button aria-label="button"
						:disabled="!isValid"
						:class="{ disabled: !isValid }"
						type="button"
						class="btn btn-primary px-10"
						@click="save"
					>
						{{ loading ? 'Loading...' : 'Save' }}
					</button>

					<div class="max-w-[25rem]">
						<p class="text-center text-lg">
							Your questions will be submitted to us and an account created on MaineNerve with your email. You’ll receive monthly notice
							of new ideas to use. You can opt out any time, or set it to daily notice.
						</p>
					</div>

					<div class="flex flex-col items-center">
						<div class="py-3">
							<router-link
								:to="{ name: 'home.challenges' }"
								title="Bignerve Platform Home"
								class="flex items-center space-x-1.5"
							>
								<img
									src="/img/brain.svg"
									alt="A Bignerve Platform Logo"
									class="w-6 -mt-1"
									aria-hidden="true"
								/>
								<span class="text-black text-2xl leading-none"> <b>Maine</b>Nerve </span>
							</router-link>
						</div>
						<p>Collect useful ideas from your audience!</p>
					</div>
				</div>
			</NDataForm>
		</div>
	</div>
</template>
