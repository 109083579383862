<script setup>
import IdeaCaptionFields from './IdeaCaptionFields.vue';
import IdeaQuestionFields from './IdeaQuestionFields.vue';
import IdeaQQFields from './IdeaQQFields.vue';
import ChallengeYourIdeas from '@/components/challenge-show/YourIdeas.vue';
import AssistantButton from '@/components/challenge/AssistantButton.vue';
import NDataForm from '@/components/form/Form.vue';
import { Reveal } from '@/components/Reveal';
import { ref, watch, toValue, nextTick } from 'vue';
import { useBroadcastChannel } from '@vueuse/core';
import { useDetails } from '@/composable/challenge';
import { useRouter } from 'vue-router';
import { useAuth, useLinks as useAuthLinks } from '@/composable/auth';
import { gameSetting } from '@/utils/challenge';
import { defu } from 'defu';
import { useStoredIdeaData, useIdeaCreationFlow } from '@/composable/idea';
import { api } from '@/modules/services';

const props = defineProps({
	challenge: {
		type: Object,
		required: true,
	},
});

const form = ref(null);
const router = useRouter();
const ideaFields = ref(null);

const { fields, totalIdeasLeft, createPayload } = useIdeaCreationFlow(props.challenge);
const { isCaption, isAMA, isQQ } = useDetails(props.challenge);
const { storeData } = useStoredIdeaData(props.challenge);

const { post, data: step, isClosed } = useBroadcastChannel({ name: `challenge:${props.challenge.id}:broadcast` })
const { auth } = useAuth();
const { signin } = useAuthLinks();

const submit = async (payload) => {
	if (props.challenge.idea_entry_fee > 0) {
		storeData(fields.value);

		form.value.reset();
		fields.value = {};

		await router.push({
			name: 'challenges.idea-unlock',
			params: { challenge: props.challenge.slug },
		});

		return;
	}
	
	return api.idea.create(createPayload({
		ideas: [payload],
	}));
}

const beforeSubmit = async (payload) => {
	if (!auth.authenticated) {
		signin();

		return false;
	}

	if (ideaFields.value.isCaption) {
		const data = await ideaFields.value.upload();

		payload.image = data && data.url ? data.url : props.challenge.primary_image;
	}

	return true;
};

const afterSubmit = async ({ ideas_left }) => {
	storeData(defu({
		idea: {
			title: isQQ.value
				? toValue(fields.value.question_title)
				: toValue(fields.value.title),
		}
	}, toValue(fields.value)));

	form.value.reset();
	fields.value = {};

	if (!isClosed.value) {
		post('rating-unlock');
	}

	await router.push({
		name: !!props.challenge.idea_entry_fee ? 'challenges.idea-unlock' : 'challenges.rating-unlock',
		params: { challenge: props.challenge.slug },
	});

	nextTick(() => {
		props.challenge.user_stats.total_ideas = props.challenge.user_stats.total_ideas + 1;
		props.challenge.user_stats.left_ideas = ideas_left;
	});
};

watch(step, (target) => {
	if (target === 'rating-unlock') {
		router.push({
			name: 'challenges.rating-unlock',
			params: { challenge: props.challenge.slug },
		});
	}
})

const handleIdeaSuggestion = (data) => {
	if (isQQ.value) {
		fields.value.challenge.question_title = data.title;
		fields.value.challenge.question_detail = data.description;
	} else {
		fields.value.title = data.title;
		fields.value.detail = data.details;
	}

	nextTick(() => {
		fields.value.image = null;
	});
} 
</script>

<template>
	<NDataForm
		:action="submit"
		:data="fields"
		:beforeSubmit="beforeSubmit"
		ref="form"
		@success="afterSubmit"
	>
		<template #default="form">
			<NAlert
				v-if="$auth.authenticated && totalIdeasLeft === 0"
				type="danger"
				open
			>
				You can't add more ideas for this challenge.
			</NAlert>
			<NAlert
				type="danger"
				closable
				:open="form.errors.any()"
			>
				<span>{{ form.errors.get('*') || 'Please check over your Idea, something is not quite right.' }}</span>
			</NAlert>
			<NAlert
				type="success"
				closable
				:open="form.success"
			>
				<span>Your idea has been saved!</span>
			</NAlert>

			<div class="flex items-center justify-between">
				<ChallengeYourIdeas
					:total="({ max }) => max - totalIdeasLeft"
					:challenge="challenge"
					class="mb-6"
				/>

				<AssistantButton
					tool="suggest-idea"
					:params="{
						question_title: challenge.question_title,
						question_details: challenge.question_detail,
						solution_format: isCaption ? 'caption' : 'default',
						primary_image: challenge.primary_image,
					}"
					@success="handleIdeaSuggestion"
				/>
			</div>

			<div :class="{ disabled: $auth.authenticated && totalIdeasLeft === 0 }">
				<IdeaQQFields
					v-if="isQQ"
					:challenge="challenge"
					ref="ideaFields"
				/>
				<IdeaCaptionFields
					v-else-if="isCaption"
					:challenge="challenge"
					ref="ideaFields"
				/>
				<IdeaQuestionFields
					v-else
					:challenge="challenge"
					ref="ideaFields"
				/>
				<div
					v-if="isAMA"
					class="flex flex-col items-center justify-center mt-5 gap-3"
				>
					<div class="flex items-center gap-6">
						<button
							type="submit"
							class="bg-innovator text-white shadow rounded-md overflow-hidden py-1 px-3"
							aria-label="Post your Idea"
							:class="form.busy ? 'animate-pulse cursor-not-allowed' : 'hover:bg-innovator-600'"
							:disabled="form.busy"
						>
							<span
								class="flex justify-center gap-2"
								:class="{ 'animate-loop': form.busy }"
							>
								<NLoading
									v-if="form.busy"
									color="text-white"
									class="text-white"
									active
								/>
								<NIcon
									v-else
									as="lightbulb-o1"
									class="text-xl"
								/>
								<span class="font-semibold">{{ form.busy ? 'Posting...' : 'Post your Idea' }}</span>
							</span>
						</button>
						<span class="font-bold flex gap-2 items-center leading-3 text-dust-800"> <NIcon as="lock-solid" /> Private </span>
					</div>
					<div class="text-innovator">
						<p class="font-bold text-center mb-0">
							You can enter <span v-if="$auth.authenticated">{{ totalIdeasLeft }} more idea(s)</span>
							<span v-else>up to {{ gameSetting(challenge, 'max_user_ideas') }} ideas!</span>
						</p>
						<p class="italic font-thin">in this "Ask me anything" challenge.</p>
					</div>
					<div class="italic text-dust-700">
						<p class="text-center mb-0">Ideas stay private even when the challenge ends. These are your ideas, not for the community.</p>
						<p class="text-center" style="display:none;">If {{ challenge.catalyst.first_name }} does not provide feedback then you will not be charged.</p>
					</div>
				</div>


				<div
					v-else-if="challenge.idea_entry_fee > 0"
					class="flex flex-col items-center justify-center mt-5 gap-2"
				>
					<button
						type="submit"
						class="flex justify-center items-center gap-2 py-2 rounded-md text-white px-4 bg-innovator-500"
							:class="form.busy ? 'animate-pulse animate-loop cursor-not-allowed' : 'hover:bg-innovator-600'"
						aria-label="Post your Idea"
						:disabled="form.busy"
					>
					<NLoading
						v-if="form.busy"
						color="text-white"
						class="text-white"
						active
					/>
					<NIcon
						v-else
						as="lightbulb-o1"
						class="text-xl"
					/>
					<span class="font-semibold">{{ form.busy ? 'Sending...' : (fields.id ? 'Update your Idea' : 'Enter your Idea') }}</span>
					</button>
					<div class="flex flex-col items-center">
						<p class="text-innovator mb-0 italic font-bold">
							<NIcon as="funding" class="text-2xl mr-1" />
							You can enter up to {{ gameSetting(challenge, 'max_user_ideas') }} ideas for ${{ challenge.idea_entry_fee }}
						</p>
						<span class="text-innovator italic">in this Challenge question.</span>
					</div>
				</div>

				<div
					v-else
					class="flex flex-col items-center justify-center mt-5"
				>
					<button
						type="submit"
						class="bg-white text-white shadow-active rounded-md overflow-hidden p-0.5 w-[17.5rem]"
						aria-label="Post your Idea"
						:disabled="form.busy"
					>
						<span
							class="flex justify-center gap-2 py-4 rounded-md bg-innovator-500"
							:class="form.busy ? 'animate-pulse animate-loop cursor-not-allowed' : 'hover:bg-innovator-600'"
						>
							<NLoading
								v-if="form.busy"
								color="text-white"
								class="text-white"
								active
							/>
							<NIcon
								v-else
								as="lightbulb-o1"
								class="text-2xl mr-1"
							/>
							<span class="flex flex-col leading-4 justify-start items-start">
								<span class="font-semibold">{{ form.busy ? 'Posting...' : 'Post your Idea' }}</span>
								<small class="italic">then <span class="underline">view & rate</span> other ideas</small>
							</span>
						</span>
					</button>
					<p class="is-color-green mt-2 mb-2 italic font-bold">
						You can enter <span v-if="$auth.authenticated">{{ totalIdeasLeft }} more idea(s)</span>
						<span v-else>up to {{ gameSetting(challenge, 'max_user_ideas') }} ideas</span> in this challenge
					</p>
				</div>

				<Reveal v-if="!challenge.idea_entry_fee" :to="$route.name">
					<div
						v-if="!isAMA"
						class="text-center"
					>
						<p class="mb-0">
							<b><i>To post your idea, you might need to rate a few other ideas to “unlock” yours.</i></b>
						</p>
						<p class="mb-0">
							Ideas are published under a
							<a
								href="https://creativecommons.org/publicdomain/zero/1.0/"
								target="_blank"
								class="text-nerve-400 hover:text-nerve-500"
								>Creative Commons CC0 1.0</a
							>
							public domain license.
						</p>
					</div>
				</Reveal>
			</div>
		</template>
	</NDataForm>
</template>
