<template>
        <div class="container mx-auto">
            <div class="col-span-12 flex flex-col items-center justify-center py-12"> 
                <RouterView v-slot="{ Component }">
                    <template v-if="Component">
                        <component :is="Component" />
                    </template>
                    <div v-else class="w-full h-screen bg-gray-100 rounded-md flex items-center justify-center animate-pulse">
                    </div>
                </RouterView>
            </div>
        </div>
</template>
