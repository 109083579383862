<script setup>
import Cropper from 'cropperjs';
import { useValidation } from '@/composable/form';
import { ref, inject, useSlots } from 'vue';
import { oldApi as nerve, api } from '@/modules/services';
import { bytesToSize } from '@/utils/Helpers';

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    ratio: {
        type: Number,
        default: 1
    },
    name: {
        type: String,
        default: 'image'
    },
    headers: {
        type: Object,
        default: () => ({
            'Content-Type': 'multipart/form-data'
        })
    },
    maxSize: {
        type: Number,
        default: 1024 * 1024 * 10 // 10MB
    },
    quality: {
        type: Number,
        default: 0.5,
    },
});

const form = inject('form');
const emit = defineEmits(['update:modelValue', 'cropping', 'cropped']);
const slots = useSlots();

const busy = ref(false);
const file = ref(null);
const cropper = ref(null);
const canvas = ref(null);
const progress = ref(0);
const previewSrc = ref(null);

const { error, invalid, setError, clearErrors } = useValidation({ name: props.name });

const init = () => {
    cropper.value = new Cropper(canvas.value, {
        zoomable: true,
        dragMode: 'move',
        aspectRatio: props.ratio,
        checkOrientation: false,
        cropend () {
            if (!slots.preview) {
                return;
            }

            previewSrc.value = cropper.value.getCroppedCanvas().toDataURL('image/jpeg', 0.5);
        }
    });
};

const preview = (file) => {
    const reader = new FileReader();
    reader.onloadend = event => {
        if (file.size > props.maxSize) {
            setError(`Image is too large. Max size: ${bytesToSize(props.maxSize)}`);
            busy.value = false;
            return;
        }

        clearErrors();
        canvas.value.src = event.target.result;

        init();
        busy.value = false;
        emit('cropping', canvas.value);
    }
    reader.readAsDataURL(file);
};

const reset = () => {
    if (!cropper.value) {
        return;
    }
    
    cropper.value.destroy();
    canvas.value.src = '';
    cropper.value = null;
    busy.value = false;
    
    if (file.value) {
        file.value.value = null;
    }
};

const remove = () => {
    emit('update:modelValue', null);
    emit('cropped', null);
};

const upload = async (data) => {
    try {
        // const { data: response } = await nerve.utils.uploadImage(data, {}, props.headers);

        const { data: { url } } = await api.resource.uploadImage(
            data, { type: 'image/jpeg' }, {}, (p) => {
                progress.value = p;
            }
        );

        emit('update:modelValue', url);
        emit('cropped', url);
        
        reset();
    } finally {
        busy.value = false;
    }
};

const cropAndUpload = () => {
    if (!cropper.value) {
        return;
    }

    busy.value = true;
    cropper.value.disable();
   
    // upload as file
    const canvas = cropper.value.getCroppedCanvas();
    return new Promise((resolve, reject) => {
        canvas.toBlob(async (blob) => {
            const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
            
            try {
                await upload(file);

                // ensure that the update:modelValue event is emitted
                setTimeout(() => {
                    resolve();
                }, 100);
            } catch (error) {
                reject(error);
            }
        }, 'image/jpeg', props.quality);
    })
};

const handle = (event) => {
    reset();
    busy.value = true;
    preview(event.target.files.item(0));
};

defineExpose({
    cropAndUpload,
})
</script>

<template>
    <div class="w-full">
        <div v-show="cropper" class="grid mb-3 " :class="{ 'sm:grid-cols-2': !!slots.preview }">
            <figure>
                <img ref="canvas" src="" alt="" >
            </figure>

            <slot name="preview" :src="previewSrc" />
        </div>
        
        <slot v-if="!cropper" :src="modelValue" :loading="busy"></slot>

        <NAlert :open="invalid" type="danger" class="mt-3 mb-0" closable>
            {{ error }}
        </NAlert>

        <div class="flex items-center gap-2 py-2">
            <div v-if="!cropper" class="custom-file-upload">
                <label class="bg-dust-600 overflow-hidden relative text-white rounded-md py-1 flex items-center px-8 hover:bg-dust-700 hover:cursor-pointer">
                    <NIcon as="image-regular" class="text-lg mr-2" />
                    {{ busy ? 'Working...' : 'Select image' }}
                    <input
                        type="file"
                        ref="file"
                        class="custom-file-upload__input"
                        accept=".jpg,.jpeg,.png"
                        @change="handle"
                        :disabled="form.busy || busy"
                    >
                    <div v-if="busy" class="absolute h-full bg-dust-700 bg-opacity-40 bottom-0 left-0 transition-all duration-500 easy-in-out" :style="{ width: `${progress}%`,  }"></div>
                </label>
            </div>
            <button aria-label="button" v-if="cropper" type="button" class="bg-dust-600 text-white rounded-md py-1 flex items-center px-8 hover:bg-dust-700" :disabled="busy" :class="{ disabled: busy }" @click.prevent="cropAndUpload">
                <svg class="w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" stroke="currentColor"><path d="M128 384H352V448H128C92.65 448 64 419.3 64 384V128H32C14.33 128 0 113.7 0 96C0 78.33 14.33 64 32 64H64V32C64 14.33 78.33 0 96 0C113.7 0 128 14.33 128 32V384zM384 128H160V64H384C419.3 64 448 92.65 448 128V384H480C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H448V480C448 497.7 433.7 512 416 512C398.3 512 384 497.7 384 480V128z"/></svg>
                {{ busy ? 'Croping...' : 'Crop' }}
            </button>
            <button aria-label="button" v-if="!cropper && modelValue && !busy" type="button" class="bg-dust-600 text-white rounded-md py-1 flex items-center px-8 hover:bg-dust-700" @click.prevent="remove">
                Clear
            </button>
            <button aria-label="button" v-if="cropper && ! busy" type="button" class="bg-dust-600 text-white rounded-md py-1 flex items-center px-8 hover:bg-dust-700" @click.prevent="reset">
                Cancel
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    .preview-helper {
        &::before {
            border: 2px solid #eeee;
        }
    }
</style>
