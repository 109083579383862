const previewable = {
    inject: ['form'],

    computed: {
        title() {
            const title = this.form.fields.challenge.question_title;
            return title
                ? (String(title).includes('?') ? title : title + '?' )
                : 'Your question will appear here as you fill it in...';
        },
        category() {
            const categories = this.form.$parent.categoryTree;

            return categories.length > 0 ? categories[categories.length-1].name : 'Blank';
        },
        image() {
            let image = this.form.$parent.isQQ
                ? '/img/challenge-image-defaults/qq-challenge-image-default.jpg'
                : '/img/challenge-image-defaults/challenge-preview-placeholder.svg';

            return this.form.fields.challenge.primary_image || image;
        },
        user() {
            return this.$store.getters['auth/currentUser'].user;
        },
        avatar() {
            return this.user.avatar_url || '/img/user-profile-images/user-avatar-circle-anon.svg';
        },
    }
}

export const usePreviewable = () => previewable;

export default previewable;
