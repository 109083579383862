const regExpCause = new RegExp(/What(.*)could|How(.*)could/, 'i')

export const restrictedWordsCategories = [
    'entertainment-4bc2525b',
    'messaging-25b464dd',
];

export const restrictedWords = [
    'unexpected',
    'unique',
    'unusual',
    'new',
    'surprising'
];

export default {
    inject: ['form', 'context'],

    computed: {
        challenge() {
            return this.form.fields.challenge;
        },
        hasSlug() {
            return !!this.challenge.slug;
        },
        completedCategory() {
            return !!this.challenge.platform_category || this.hasSlug;
        },
        completedImage() {
            return !!this.challenge.primary_image || this.hasSlug;
        },
        completedDuration() {
            return !!(
                this.challenge.duration_start &&
                this.challenge.duration_end
            ) || this.hasSlug;
        },
        completedPrize() {
            return !!(
                this.challenge.prize_type &&
                this.challenge.prize_money !== null
            ) || this.hasSlug;
        },
        completedCaptionFormat() {
            return !!(
                this.challenge.caption_solution_format
            ) || this.hasSlug;
        },
        completedDescription() {
            return this.isCaption || (
                regExpCause.test(this.challenge.question_title)
            ) || this.hasSlug;
        },
        completedQQQuestion() {
            return !!(
                this.challenge.question_title &&
                this.challenge.question_detail
            ) || this.hasSlug;
        } ,
        completedIdea() {
            return !!(
                this.form.fields.idea.description &&
                this.form.fields.idea.title
            ) || this.hasSlug;
        },
        completedInnovation() {
            return !!this.challenge.innovation_scale
                || this.hasSlug;
        },
        completedArticle() {
            return !!this.challenge.article
                || this.form.fields.article_skiped
                || this.hasSlug;
        },
        completedAssumptions() {
            return !!(this.hasWho && this.hasWhy && this.hasWhat) || this.hasSlug;
        },
        completedPurpose() {
            return !!this.form.fields.idea.purpose || this.hasSlug;
        },
        hasSlug() {
            return !!this.challenge.slug;
        },
        hasWho() {
            return !!this.challenge.assumption_who;
        },
        hasWhy() {
            return !!this.challenge.assumption_why;
        },
        hasWhat() {
            return !!this.challenge.assumption_what;
        },
        hasQuestion() {
            return this.form.$parent.isCaption ||
                ( !this.form.$parent.isQQ
                    ? regExpCause.test(this.challenge.question_title)
                    : !!this.challenge.question_title
                );
        },
        hasCategory() {
            return !!this.challenge.platform_category;
        },
        hasIdea() {
            return !!(this.form.fields.idea.title && this.form.fields.idea.description);
        },
        hasAssumptions() {
            return this.hasWho || this.hasWhy || this.hasWhat;
        },
        hasDuration() {
            return !!(this.challenge.duration_start && this.challenge.duration_end);
        },
        hasPrize() {
            return !!this.challenge.prize_type;
        },
        hasImage() {
            return !!this.challenge.primary_image;
        },
        hasCaption() {
            return !!this.challenge.caption_solution_format;
        },
        hasPurpose() {
            return !!this.form.fields.idea.purpose
        },
        isCaption() {
            const categories = ['Funny', 'Inspiring', 'Insightful', 'Shocking'];
            return this.challenge.caption_solution_format === 'caption'
                || categories.includes(this.challenge.main_category);
        },
        isValid () {
            return ![
                this.hasQuestion,
                this.hasCategory,
                this.hasImage,
                this.hasDuration,
                this.hasPrize,
            ].some(v => !v);
        },
        isImproveIdea() {
            return this.challenge.solution_format === 'improve-an-idea';
        },
        isValidQuestionTitle () {
            const checkWordsRestriction = !!this.challenge.solution_format || restrictedWordsCategories.some(category => 
                this.context.categoryMain && this.context.categoryMain.reference.includes(category),
            );

            return checkWordsRestriction
                ? ['unexpected', 'unique', 'unusual', 'surprising'].some(
                    word => this.challenge.question_title.match(new RegExp(word, 'i'))
                )
                : true
        }
    }
}
