import { createStore } from "vuex";

// Modules
import app from "./modules/app";
import auth from "./modules/auth";
import olympiad from "./modules/olympiad";

export const store = createStore({
  modules: {
    app,
    auth,
    olympiad,
  },
});

export default store;
