<script>
import { excerpt } from '@/utils/Helpers';
import { h, defineComponent, ref } from 'vue';
import { usePlatform } from '@/mixins/ShowsPlatform';
import { get } from 'lodash';
import NIcon from '@/components/NIcon.vue';
import useChallengeCreateFlow from '@/mixins/ChallengeCreateFlow';

const StatusBar = defineComponent({
    props: {
        modelValue: Boolean,
        defaultLabel: String,
        trueLabel: String,
        falseLabel: String,
        required: Boolean
    },
    setup (props) {
        return () => h('span', {
            class: 'challenge-edit-tile__status flex items-center'
        }, [
            h(NIcon, {
                as: props.modelValue || !props.required ? 'check-circle-solid' : 'exclamation-circle-solid',
                class: { 'text-green-500': props.modelValue, 'text-red-500': !props.modelValue && props.required }
            }),
            props.trueLabel || props.falseLabel || props.defaultLabel ? h('span', {
                class: ['text-sm font-semibold ml-2', { 'text-red-500': !props.modelValue && props.required }]
            } , props.modelValue ? (props.trueLabel || props.defaultLabel) : (props.falseLabel || props.defaultLabel)) : null
        ])
    }
});

const ExpandError = defineComponent({
    props: {
        error: String,
    },
    setup (props, { slots }) {
        const showing = ref(false);
        const show = () => showing.value = true;

        return () => props.error
            ? (slots.default
                ? slots.default({ message: props.error, show, on: { click: show }, showing: showing.value })
                : props.error)
            : null
    }
})

export default {
    inject: ['form', 'board'],

    mixins: [useChallengeCreateFlow, usePlatform()],

    components: { StatusBar, ExpandError },

    methods: {
        excerpt,
        to (step) {
            this.$bus('challenge:creation-flow:step', step);
        },
        submit () {
            this.form.submit();
        },
        visited (step) {
            return get(this.form.fields, `steps.${step}`, false);
        }
    }
}
</script>

<template>
    <div class="challenge-final-preview-sidebar">
        <h2 class="challenge-final-preview-sidebar__title mb-0">Publish your Question on {{ contextPlatform.title || 'Bignerve' }}</h2>
        <p v-if="!isBignerve" class="text-center text-nerve text-lg">
            as well as on BigNerve
        </p>
        <NAlert type="danger" :closable="true" :open="form.errors.any()">
            <span>Please check your Question details, something is missing.</span> 

            <ExpandError :error="form.errors.get('*')" #="{ message, on, showing }">
                <button aria-label="button" v-if="!showing" v-on="on" class="underline inline px-2" type="button">show more</button>

                <div v-else class="border p-2 border-red-600 rounded-md">{{ message }}</div>
            </ExpandError>
            
        </NAlert>
        <div class="flex justify-center mt-3 mb-3">
            <button aria-label="button" type="button" class="btn is-large" :disabled="form.busy || !isValid" @click="submit">
                {{ form.busy ? 'Publishing...' : 'Publish & Share' }}
                <span aria-hidden="true" class="btn__icon is-right icon-share-square-solid"></span>
            </button>
        </div>
        <p class="mb-4 text-center">
            <span aria-hidden="true" class="icon-flame"></span>
            <i>You will earn <b>Catalyst</b> points</i>
        </p>

        <div class="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-x-5">
            <div>
                <button aria-label="button" type="button" class="challenge-edit-tile" :class="{ 'border-2 border-red-500 border-dashed': !hasCategory }" @click.prevent="to('category')">
                    <StatusBar :modelValue="hasCategory" false-label="Category missing" required />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-pencil-alt-solid"></span>
                        <h3 class="challenge-edit-tile__title">Category Picker</h3>
                        <p class="challenge-edit-tile__description">Go through the category &amp; subcategory flow</p>
                    </div>
                </button>
            </div>
            <div v-if="! board.isCaption">
                <button aria-label="button" :class="{ 'border-2 border-red-500 border-dashed': !hasQuestion || !isValidQuestionTitle }" type="button" class="challenge-edit-tile" @click.prevent="to('question-description')">
                    <StatusBar :modelValue="!hasQuestion || isValidQuestionTitle" required />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-question-circle-solid"></span>
                        <h3 class="challenge-edit-tile__title">Question &amp; Description</h3>
                        <p class="challenge-edit-tile__description">Clarify your question or description</p>
                    </div>
                </button>
            </div>
            <div v-if="! board.isCaption && ! board.isQQ">
                <button aria-label="button" type="button" class="challenge-edit-tile" @click.prevent="to('assumptions')">
                    <StatusBar :modelValue="hasAssumptions" default-label="Optional" />
                    <div class="challenge-edit-tile__inside">
                        <div class="challenge-edit-tile__deep-dive mt-6">
                            <div class="item" :class="{ 'is-filled': hasWhat }">
                                <NIcon
                                    as="check-circle-solid"
                                    :class="hasWhat ? 'text-green-500' : 'text-dust-400'"
                                    class="mr-1"
                                />
                                <span class="question-word">What</span>
                            </div>
                            <div class="item" :class="{ 'is-filled': hasWho }">
                                <NIcon
                                    as="check-circle-solid"
                                    :class="hasWho ? 'text-green-500' : 'text-dust-400'"
                                    class="mr-1"
                                />
                                <span class="question-word">Who</span>
                            </div>
                            <div class="item" :class="{ 'is-filled': hasWhy }">
                                <NIcon
                                    as="check-circle-solid"
                                    :class="hasWhy ? 'text-green-500' : 'text-dust-400'"
                                    class="mr-1"
                                />
                                <span class="question-word">Why</span>
                            </div>
                        </div>
                        <h3 class="challenge-edit-tile__title">Add your Core Assumptions</h3>
                        <p class="challenge-edit-tile__description">Train your insights</p>
                    </div>
                </button>
            </div>
            <div>
                <button aria-label="button" type="button" class="challenge-edit-tile" :class="{ 'border-2 border-red-500 border-dashed': !hasImage }" @click.prevent="to('image')">
                    <StatusBar :modelValue="hasImage" false-label="Image missing" required />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-image-regular"></span>
                        <h3 class="challenge-edit-tile__title">Image</h3>
                        <p class="challenge-edit-tile__description">Add some visual interest</p>
                    </div>
                </button>
            </div>
            <div v-if="! board.isCaption && ! board.isQQ">
                <button aria-label="button" type="button" class="challenge-edit-tile" @click.prevent="to('your-idea')">
                    <StatusBar :modelValue="hasIdea" default-label="Optional" />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-lightbulb-o1"></span>
                        <h3 class="challenge-edit-tile__title">Test your own idea</h3>
                        <p class="challenge-edit-tile__description">Get Feedback, too</p>
                    </div>
                </button>
            </div>
            <div>
                <button aria-label="button" type="button" class="challenge-edit-tile" :class="{ 'border-2 border-red-500 border-dashed': !hasDuration }" @click.prevent="to('duration')">
                    <StatusBar :modelValue="hasDuration && visited('duration')" :false-label="(!hasDuration ? 'Duration missing' : 'Autofill')"  />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-calendar-date-picker"></span>
                        <h3 class="challenge-edit-tile__title">Set Duration</h3>
                        <p class="challenge-edit-tile__description">How long to run?</p>
                    </div>
                </button>
            </div>
            <div>
                <button aria-label="button" type="button" class="challenge-edit-tile" :class="{ 'border-2 border-red-500 border-dashed': !hasPrize }" @click.prevent="to('money-prizes')">
                    <StatusBar :modelValue="hasPrize && visited('money-prizes')" :false-label="(!hasPrize ? 'Prize missing' : 'Autofill')" />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-money-bill-alt-regular"></span>
                        <h3 class="challenge-edit-tile__title">Prize or Money</h3>
                        <p class="challenge-edit-tile__description">Add Extra Incentive</p>
                    </div>
                </button>
            </div>
            <div v-if="board.isCaption && ! board.isQQ">
                <button aria-label="button" type="button" class="challenge-edit-tile" :class="{ 'border-2 border-red-500 border-dashed': !hasCaption }" @click.prevent="to('caption-format')">
                    <StatusBar :modelValue="hasCaption" false-label="Caption missing" required />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-image-regular"></span>
                        <h3 class="challenge-edit-tile__title">Caption Format</h3>
                        <p class="challenge-edit-tile__description">Choose your caption format</p>
                    </div>
                </button>
            </div>
            <div v-if="! board.isCaption && !board.isQQ && !board.isTestIdea">
                <button aria-label="button" type="button" class="challenge-edit-tile" @click.prevent="to('article-source')">
                    <StatusBar :modelValue="!!form.fields.challenge.article" default-label="Optional" />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-link-solid"></span>
                        <h3 class="challenge-edit-tile__title">Link an Article</h3>
                        <p class="challenge-edit-tile__description">Provide Depth</p>
                    </div>
                </button>
            </div>
            <!-- <div>
                <button aria-label="button" type="button" class="challenge-edit-tile">
                    <span aria-hidden="true" class="challenge-edit-tile__status icon-check-circle-solid"></span>
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-newspaper"></span>
                        <h3 class="challenge-edit-tile__title">Link a News Story</h3>
                        <p class="challenge-edit-tile__description">Solve the News!</p>
                    </div>
                </button>
            </div> -->
            <div v-if="! board.isCaption && ! board.isQQ">
                <button aria-label="button" type="button" class="challenge-edit-tile" :class="{ 'border-2 border-red-500 border-dashed': !form.fields.challenge.innovation_scale }" @click.prevent="to('innovation-scale')">
                    <StatusBar :modelValue="!!form.fields.challenge.innovation_scale" false-label="Scale missing" required />
                    <div class="challenge-edit-tile__inside">
                        <img class="challenge-edit-tile__image" src="/img/innovation-scale.svg" alt="">
                        <h3 class="challenge-edit-tile__title">Innovation Scale</h3>
                        <p class="challenge-edit-tile__description">What kinds of ideas?</p>
                    </div>
                </button>
            </div>
            <div v-if="! board.isCaption && ! board.isQQ && isImproveIdea">
                <button aria-label="button" type="button" class="challenge-edit-tile" @click.prevent="to('purpose')">
                    <StatusBar :modelValue="hasPurpose" default-label="Optional" />
                    <div class="challenge-edit-tile__inside">
                        <span aria-hidden="true" class="challenge-edit-tile__icon icon-bulb-plus"></span>
                        <h3 class="challenge-edit-tile__title">Improve Idea: What Purpose</h3>
                        <p class="challenge-edit-tile__description">Clarify the purpose</p>
                    </div>
                </button>
            </div>
        </div>
        <!-- <div class="grid grid-cols-2 gap-x-5">
            <div class="text-center mt-3">
                <button aria-label="button" href="#" class="btn">
                    <span aria-hidden="true" class="btn__icon is-left icon-bookmark-regular"></span>
                    Save Draft
                </button>
                <p class="mt-2 font-size-0-8-rem"><i>Work on it later in your <a href="#">Catalyst Dashboard</a></i></p>
            </div>
            <div class="text-center mt-3">
                <button aria-label="button" href="#" class="btn is-medium-gray">
                    <span aria-hidden="true" class="btn__icon is-left icon-times-circle-regular"></span>
                    Delete
                </button>
                <p class="mt-2 font-size-0-8-rem"><i>Only Drafts and Scheduled Challenges can be deleted!</i></p>
            </div>
        </div>-->
    </div>
</template>
