export default requester => {
    return {
        /**
         * Gets all users from the user's staff
         *
         * @param {object} handle
         **/
        find (platformSlug, sourceType, sourceId, recipientId) {
            return requester.get(`/content/management/${platformSlug}/invites/sources/${sourceType}/${sourceId}/${recipientId}`);
        },
    };
  }
  