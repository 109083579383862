<script>
import Validatable from '@/mixins/Validatable';
import InputMasked from './InputMasked.vue';

export default {
    name: 'NInput',

    mixins: [Validatable],

    components: {
        InputMasked,
    },

    props: {
        modelValue: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        mask: {
            type: [String, Array],
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue'],

    computed: {
        hasPrepend() {
            return this.$slots['prepend'];
        },
        hasAppend() {
            return this.$slots['append'];
        }
    },

    methods: {
        update(value) {
            this.clearErrors();
            this.$emit('update:modelValue', value);
        },
        tryFocus(visible, focus) {
            if (!visible || !focus) {
                return;
            }
            
            setTimeout(() => {
                const target = this.$refs.target;
            
                target && target.$el && target.$el.focus();
            }, 100);
        },
    },
}
</script>

<template>
    <div v-visible="(e) => tryFocus(e, autofocus)" class="form-group__helper">
        <label v-if="label" :for="name" class="form-group__label" style="display: block;">{{ label }}</label>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <div class="form-group__input-helper ">
            <div class="form-group__input !w-full !flex items-center border border-dust-300 rounded-md" :class="{ 'border-red-400': invalid, 'bg-dust-100': disabled }">
                <span v-if="hasPrepend">
                    <slot name="prepend"></slot>
                </span>
                <InputMasked
                    v-bind="attrs"
                    v-on="(listeners({ ignore: ['update:modelValue'] }))"
                    ref="target"
                    :type="type"
                    :id="name"
                    :name="name"
                    :modelValue="modelValue"
                    :disabled="form.busy || disabled"
                    :options="{ mask }"
                    :autofocus="autofocus"
                    class="w-full focus:placeholder-transparent bg-transparent"
                    @update:modelValue="update"
                />
                <span v-if="hasAppend" class="form-group__append">
                    <slot name="append" />
                </span>
            </div>
        </div>
        <span v-if="invalid" class="form-group__error">{{ error }}</span>

        <slot :error="error" :disabled="disabled" :invalid="invalid"  />
    </div>
</template>
