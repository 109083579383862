import ChallengeSchema from '@/utils/ChallengeSchema';
import IdeaSchema from '@/utils/IdeaSchema';
import defu from 'defu';
import { usePlatform } from '@/composable/platform';
import { createSharedComposable, useStorage } from '@vueuse/core';
import { useChallengeSetting } from '@/composable/challenge';
import { inject, computed } from 'vue';
import { uuid } from "@/utils/Helpers";
import { SOLUTION_FORMAT } from '@/constant/challenge';
import { VISIBILITY } from '@/constant/idea';
import { isCaption } from '@/utils/challenge';

export const useIdeaContentContext = () => {
    return inject('ideaContent');
}

export const useStoredIdeaData = (challenge) => {
    const key = `challenge:${challenge.id}:stored-ideas-data`;
    const data = useStorage(key, [], null, { shallow: true });

    const updateData = (target) => {
        data.value = data.value.map((payload) => {
            if (payload.id === target.id) {
                return {
                    ...payload,
                    ...target,
                }
            }

            return payload;
        })
    }

    const storeData = (payload) => {
        if (payload.id) {
            updateData(payload);

            return data;
        }

        data.value = [
            ...data.value,
            {
                id: uuid(),
                ...payload,
            },
        ];

        return data;
    };

    const removeData = (id) => {
        data.value = data.value.filter((payload) => payload.id !== id);
    }

    const resetData = () => {
        data.value = [];
    }

    return {
        data,
        storeData,
        removeData,
        updateData,
        resetData,
    }
}

export const useIdeaCreationFlow = createSharedComposable((challenge) => {
    const { setting } = useChallengeSetting(challenge);
    const { data } = useStoredIdeaData(challenge);
    const { platform } = usePlatform();
    const maxUserIdeas = setting('max_user_ideas');
    const allowChallengeRating = setting('allow_challenge_rating', true);

    const totalUserIdeas = computed(() => {
        return challenge.user_stats?.total_ideas || data.value?.length || 0;
    });
    const totalIdeasLeft = computed(() => {
        return maxUserIdeas.value - totalUserIdeas.value;
    });
    const ideaEntryFee = computed(() => {
        return props.challenge.idea_entry_fee * totalUserIdeas.value;
    });

    const userStats = computed(() => {
        return {};
    });

    const fields = useStorage(
		`idea-${challenge.id}-draft`,
		challenge.solution_format === SOLUTION_FORMAT.QQ
			? defu(ChallengeSchema, {})
			: defu(IdeaSchema, {
					challenge_id: challenge.id,
					image: isCaption(challenge) ? challenge.primary_image : null,
					title: '',
					detail: '',
			  }),
		null,
		{ deep: true, mergeDefaults: true }
	);

    const createPayload = (customValue = {}) => {
        return defu(customValue, {
            format: challenge.solution_format,
            challenge_id: challenge.id,
            platform_domain: platform.value.id,
            visibility: challenge.solution_format === SOLUTION_FORMAT.AMA
                ? VISIBILITY.PRIVATE : VISIBILITY.PUBLIC,
        });
    }

    return {
        fields,
        userStats,
        maxUserIdeas,
        totalIdeasLeft,
        storedIdeasData: data,
        ideaEntryFee,
        allowChallengeRating,
        totalUserIdeas,
        createPayload,
    };
});
