<script>
import DeepDiveWho from './Who.vue';
import DeepDiveWhy from './Why.vue';
import DeepDiveWhat from './What.vue';

import CoreAssumptions from './CoreAssumptions.vue';
import WordsRestriction from './WordsRestriction.vue';
import SkipButton from './SkipButton.vue';

export default {
    components: {
        DeepDiveWho,
        DeepDiveWhy,
        DeepDiveWhat,
        CoreAssumptions,
        WordsRestriction,
        SkipButton,
    },

    inject: {
        form: { default: null },
        stepper: { default: null },
        context: { default: null },
    },
    
    emits: ['activeTab'],

    data() {
        return {
            tab: null,
        };
    },

    computed: {
        isImplicit() {
            return this.$route.name === 'challenges.show';
        },
        slug() {
            if (['who', 'what', 'why'].includes(this.tab)) {
                return 'deep-dives';
            }

            return '*';
        },
        category () {
            return ['science-technology-40f1b28c']
        },
        title() {
            return this.$t(`challenges.subheads.assumptions.${this.slug}`);
        },
        description() {
            return this.$t(`challenges.intros.assumptions.${this.slug}`);
        },
    }
}
</script>

<template>
    <div v-if="form.fields.challenge">
        <div v-if="! isImplicit" class="challenge-section-heading">
            <div class="flex items-center justify-between mb-3">
                <h2 class="challenge-section-heading__title font-semibold m-0">
                    {{ title }}
                </h2>

                <SkipButton />
            </div>
            <NMarkdown :code="description" class="challenge-section-heading__description mb-3" />
        </div>
        <NTabs v-model="tab" @change="t => $emit('activeTab', t)">
            <NTab title="Assumptions" id="assumptions" icon="assumptions-compass">
                <CoreAssumptions :implicit="isImplicit" @select="t => tab = t" />
            </NTab>
            <NTab title="Deep Dive: What" id="what" icon="clipboard-list">
                <DeepDiveWhat :implicit="isImplicit" />
            </NTab>
            <NTab title="Deep Dive: Who" id="who" icon="clipboard-list">
                <DeepDiveWho :implicit="isImplicit"/>
            </NTab>
            <NTab title="Deep Dive: Why" id="why" icon="clipboard-list">
                <DeepDiveWhy :implicit="isImplicit"/>
            </NTab>
            <NTab title="Question Title" id="question" icon="question-circle-solid">
                <div class="tabs__content-helper">
                    <div class="form-groups">
                        <div class="form-group">
                            <h2 class="form-group__title is-blue">Final Step: Rewrite your Question</h2>
                            <p class="form-block-help-text">
                                By this time you probably have new insights into the problem you’re trying to solve. So now is a good to review all three deep dive worksheets, and rethink your question. <b>Then, can you rewrite it to be more precise?</b>
                            </p>
                            <div class="textarea-with-info mt-0">
                                <div class="textarea-with-info__char-counter"><b>180</b> characters remaining</div>
                                <textarea
                                    v-model="form.fields.challenge.question_title"
                                    placeholder="Rewrite your question"
                                    class="rounded-lg border-2 block w-full p-4 text-blue-600 border-blue-600 text-xl bg-white"
                                    size="small"
                                    @blur="() => $refs.wordsRestriction.notify()"
                                    @keydown.tab="$refs.wordsRestriction.notify()"
                                />
                            </div>
                            <p class="form-block-help-text mt-3">
                                <i>
                                    For complex problems, most people ask the wrong question in the wrong sequence most of the time. Focus on this core question first, see the results from the community of innovators, and then you can focus on the other components if they remain relevant.
                                </i>
                            </p>
                            <WordsRestriction :challenge="form.fields.challenge" ref="wordsRestriction" />
                        </div>
                        <div class="form-group">
                            <h2 class="form-group__title is-blue">Congratulations! You have completed the hardest part of innovating: the analysis.</h2>
                            <p class="form-block-help-text mb-0">
                                You have gained significant Catalyst neuron points for this work. If you enjoy this deep dive, consider becoming an <b>Insightful</b> for the community, and challenge other people’s assumptions. This is one of the most important roles in the community.
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="! isImplicit" class="info-box mt-4">
                    <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
                    <p class="info-box__content">
                        <b>Note:</b> these worksheets are helpful to the community of innovators because they can understand your thought process and your point of view. They can see your initial “exploratory” question and your honed final question.
                    </p>
                </div>
            </NTab>
        </NTabs>
    </div>
</template>
