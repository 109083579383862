<script>
import Tabable from '@/mixins/Tabable';
import Hideable from '@/mixins/Hideable';

export default {
    mixins: [Tabable, Hideable],

    inject: ['stepper'],

    props: {
        title: {
            type: String,
            required: true
        },
        complete: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showLeft: {
            type: Boolean,
            default: false,
        },
        showRight: {
            type: Boolean,
            default: false,
        },
        activator: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
    },

    created () {
        this.stepper.steps.push(this);
    },
}
</script>

<template>
    <div v-show="! hidden && isOpen" :key="slug" class="w-full step-content" >
        <slot></slot>
    </div>
</template>
