/** @module ideaRace */
export default requester => {
    return {
        /**
         * Retrieves the most recent idea race standings up to the specified timestamp (possibly delayed by some small,
         * fixed time offset) for the authenticated user who is calling the endpoint.
         * See https://github.com/bignerve/game-api/issues/32
         *
         * @param {Array} userIds The array of user ids whose standings should be included in this sample
         * @param {string} ideaRaceStartTime The timestamp to use for the beginning of the query range (the timestamp of the beginning of the idea race)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        initialStandings(userIds, ideaRaceStartTime, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST',
                '/idea-race/initial-standings',
                { userIds },
                Object.assign({ 'idea-race-start-time': ideaRaceStartTime }, params),
                headers
            );
        },

        /**
         * Retrieves an array of userIds which were obtained via a specified sampling scheme
         * and tailored to the authenticated user’s connections (e.g. friends).
         * TODO: Eventually the supported query parameters should allow for more advanced sampling.
         * See https://github.com/bignerve/game-api/issues/42.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        userSample(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/idea-race/user-sample', params, headers);
        },

        /**
         * Retrieves the ids of the users that the authenticated user is subscribed to (for the idea race updates).
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        subscriptions(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/idea-race-subscriptions', params, headers);
        }
    }
}
