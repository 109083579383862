<script>
import { date } from '@bignerve/ui-utils';

export default {
    name: 'TournamentMainPlatform',

    data () {
        return {
            busy: false,
            current: null,
            previous: null,
        };
    },

    computed: {
        olympiad () {
            return (this.current && this.current.olympiad)
                || (this.previous && this.previous.olympiad);
        },
        olympiadUrl () {
            const url = new URL(location.href)
            const hostname = location.hostname.split('.');
            const domain = hostname[hostname.length - 2];
            
            url.hostname = url.hostname.replace(domain, 'bignerve');
            url.pathname = '/platform/olympiad/leaderboard';

            return url.href;
        },
    },

    methods: {
        date,
        async fetch () {
            try {
                this.busy = true;
                this.current = null;
                this.previous = null;

                const { data: olympiad } = await this.$api.widgets.olympiads(
                    'base_olympiad_id', { platform_slug: 'bignerve' }
                );

                this.current = olympiad.current;
                this.previous = olympiad.previous;
            } finally {
                this.busy = false;
            }
        }
    },

    mounted () {
        this.fetch();
    },
}
</script>

<template>
    <div class="h-full w-full">
        <h3 class="community-card__title flex items-center text-dust-900 mb-3">
            <NIcon as="stream" class="mr-2" />CURRENT TOURNAMENT
        </h3>

        <p class="italic text-dust-700">No participants here yet. Try another topic or player, or go to BigNerve</p>

        <div class="w-full flex justify-center py-10">
            <a :href="olympiadUrl" class="px-3 py-1 border-2 border-dust-400 shadow-active bg-dust-100 rounded-lg text-dust-800 hover:text-dust-900 hover:bg-dust-200 inline-flex flex-col ">
                <span class="font-bold"><NIcon as="stream" class="mr-2 text-nerve" /> BigNerve leaderboards</span>
                <span v-if="olympiad" class="ml-7">
                    {{ date(olympiad.start_time, 'MMMM DD') }} to {{ date(olympiad.end_time, 'MMM DD') }}
                </span>
            </a>
        </div>
    </div>
</template>