export default requester => {
    return {
        /**
         * Get brain historical
         *
         * @param  {string} handle
         * @param  {object} params
         * @return {object}
         */
         historical(handle, params) {
            return requester.get(`/brain/${handle}/historical`, { params });
        },

        /**
         * Get brain overview
         *
         * @param  {string} handle
         * @param  {object} params
         * @return {object}
         */
         overview(handle, params) {
            return requester.get(`/brain/${handle}/brain`, { params });
        },

        /**
         * Get neurons earned
         *
         * @param  {string} handle
         * @param  {object} params
         * @return {object}
         */
         neurons(handle, params) {
            return requester.get(`/brain/${handle}/neurons`, { params });
        },

        /**
         * Get experience points
         *
         * @param  {string} fqdn
         * @return {object}
         */
         experiencePoints(fqdn) {
            return requester.get(`/brain/neurons/${fqdn}`);
        },
    };
}
