<script>
import MemeGenerator from '@/components/challenge-show/MemeGenerator.vue';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';

export default {
    inject: ['form'],

    mixins: [useChallengeDetails({
        resources: [
            'computed.isComment',
            'computed.isCaption',
            'computed.isMeme'
        ],
    })],

    components: {
        MemeGenerator
    },

    props: {
        challenge: {
            type: Object,
            required: true
        },
    },

    data () {
        return {
            hasError: false,
        };
    },
    
    methods: {
        async upload() {
            try {
                this.form.busy = true;

                const { title, detail } = this.form.fields

                const { data } = await this.$api.resource.memeGenerator({
                    source: this.challenge.primary_image,
                    setup: !!detail ? title : null,
                    pushline: !!detail ? detail : title,
                });

                return data;
            } finally {
                this.form.busy = false;
            }
        },
    },
}
</script>

<template>
    <div class="form-groups">
        <div class="form-group mt-2">
            <h3>Enter your {{ isCaption ? 'Caption' : (isComment ? 'Comment' : 'Meme') }}</h3>
            <ol>
                <li v-if="isCaption || isMeme">
                    What could be a <strong>{{ challenge.main_category }}</strong>
                    {{ isCaption ? 'caption for this image' : (isComment ? 'comment for this article' : 'meme for this image') }}?
                    Be creative!
                </li>
                <li v-if="isComment">
                    You’re not making a typical “internet comment”. Think of yourself as a co-writer in the writing room, adding value to this article with something thought-provoking or entertaining. Be creative!
                </li>
            </ol>
        </div>
        <div v-if="challenge.primary_image" class="form-group flex items-center">
            <MemeGenerator
                v-if="isMeme"
                :setup-text="form.fields.title"
                :punchline-text="form.fields.detail"
                :image-src="challenge.primary_image"
                ref="memeGenerator"
            />

            <MemeGenerator
                v-else
                :punchline-text="form.fields.title"
                :image-src="challenge.primary_image"
                ref="memeGenerator"
            />

            <NAlert v-if="hasError" type="danger" class="w-full mt-6" open>
                There is an error when trying to generate the image for the idea. Please reload the page and try again.
            </NAlert>
        </div>
        <div class="form-group">
            <label class="form-group__label">
                {{ isMeme ? 'Add the Top Text (90 characters)' : 'What is your Caption?' }}
            </label>
            <NInput
                v-model="form.fields.title"
                name="title"
                :placeholder="isMeme ? 'Enter your top text' : 'Caption'"
                maxlength="90"
                required
            ></NInput>
        </div>
        <div v-if="isMeme" class="form-group">
            <label class="form-group__label">Add the bottom text (90 characters)</label>
            <NInput
                v-model="form.fields.detail"
                name="detail"
                placeholder="Enter your bottom text"
                maxlength="90"
            ></NInput>
        </div>
    </div>
</template>