<script setup>
import { Icon } from '@bignerve/ui-core';
import RadioGroup from '@/components/form/RadioGroup.vue';
import Radio from '@/components/form/Radio.vue';
import Button from '@/components/ui/button/index.vue';
import CategoriesPicker from '@/components/game/CategoriesPicker.vue';
import StepImport from "@/components/forum-tools/Import.vue";
import StepInvite from "@/components/forum-tools/Invite.vue";
import { omit } from 'lodash';
import { api } from '@/modules/services';
import { ref } from 'vue';

const props = defineProps({
    channel: {
        type: Object,
        required: true
    },
    platform: {
        type: Object,
        default: () => ({})
    },
});

const saved = ref(false);
const unlocked = ref(false);

const CHANNEL_MODE = {
    PRIVATE: 'private',
    PUBLIC: 'public'
}

const CHANNEL_SCOPE = {
    MEMBERS_ONLY_FREE: 'members_only_free',
    MEMBERS_ONLY_PAID: 'members_only_paid',
    BIGNERVE_SUBSCRIBERS: 'bignerve_subscribers',
    MINIMUM_COMMENTATOR_SCORE: 'minimum_commentator_score',
}

const emit = defineEmits(['update']);

const handleUpdateChannel = async (channel) => {
    if (!props.platform?.slug || !channel?.slug) {
        return;
    }

    saved.value = false;
    
    await api.channel.update(props.platform.slug, channel.slug, channel);

    saved.value = true;
}

const handleChangeCategory = (target, channel) => {
    if (!target) {
        return;
    }

    const updatedChannel = {
        ...channel,
        name: target.name,
        icon: target.icon,
        source_id: target.reference,
        source: omit(target, ['children', 'parent'])
    };

    handleUpdateChannel(updatedChannel);
}

const handleUnlock = () => {
    unlocked.value = true;
}
</script>

<template>
    <div class="flex items-start gap-8 flex-col sm:flex-row w-full">
        <CategoriesPicker v-model="channel.source" @closed="target => handleChangeCategory(target, channel)">
            <template #activator="{ on }">
                <Button v-on="on" :key="channel.source_id" class="h-10 gap-2 font-bold shadow-active rounded-xl min-w-[180px]" type="button">
                    <img :src="`/img/category-icons/${channel.icon}`" :alt="channel.name" class="h-4 filter invert-[70%]" @error="e => e.target.src = `/img/category-icons/article-draft-icon.svg`">
                    {{ channel.name }}
                </Button>
            </template>
        </CategoriesPicker>
        <div class="grid gap-2 flex-1">
            <RadioGroup v-model="channel.channel_mode" :name="`${channel.id}:channel_mode`" @change="handleUpdateChannel(channel)">
                <div class="grid mb-2">
                    <div class="flex gap-2 items-center text-sm">
                        <Radio 
                            label="Public" 
                            :disabled="channel.channel_mode === CHANNEL_MODE.PRIVATE && !unlocked"
                            :modelValue="CHANNEL_MODE.PUBLIC"
                            :class="{
                                'font-bold': channel.channel_mode === CHANNEL_MODE.PUBLIC,
                                'disabled': channel.channel_mode === CHANNEL_MODE.PRIVATE && !unlocked
                            }"
                        />
                        <span v-if="saved && channel.channel_mode === CHANNEL_MODE.PUBLIC" class="italic text-nerve">
                            Saved… your custom channel is now live in your forum <RouterLink :to="{ name: 'discussion-forums.channel', params: { user: platform?.slug || 'platform', slug: channel.slug } }" class="underline text-inherit">forum</RouterLink>.
                        </span>
                    </div>
                    <div class="flex items-center text-sm">
                        <Radio label="Private" :modelValue="CHANNEL_MODE.PRIVATE" :class="{ 'font-bold': channel.channel_mode === CHANNEL_MODE.PRIVATE }" />
                        <Button 
                            v-if="channel.channel_mode === CHANNEL_MODE.PRIVATE"
                            v-tooltip="{
                                content: unlocked 
                                    ? `<div style='max-width: 240px;'>Channel unlocked. You can now change it to Public. This will make all content visible to everyone.</div>`
                                    : `<div style='max-width: 240px;'>Tap the lock if you want to change this Private channel to Public. All existing content will be revealed.</div>`,
                                html: true
                            }" 
                            variant="icon" 
                            class="-ml-2" 
                            @click="handleUnlock"
                        >
                            <Icon :as="unlocked ? 'unlock-solid' : 'lock-solid'" />
                        </Button>
                        <span v-if="saved && channel.channel_mode === CHANNEL_MODE.PRIVATE"  class="italic text-nerve">
                            Saved… your custom channel is now Private.
                        </span>
                    </div>
                </div>
            </RadioGroup>

            <div v-if="channel.channel_mode === CHANNEL_MODE.PRIVATE" class="sm:ml-4 grid gap-2">
                <RadioGroup v-model="channel.channel_scope" :name="`${channel.id}:channel_scope`" @change="handleUpdateChannel(channel)">
                    <div class="grid grid-cols-1 sm:grid-cols-2 gap-1">
                        <Radio label="Members only (free)" :modelValue="CHANNEL_SCOPE.MEMBERS_ONLY_FREE" :class="{ 'font-bold': channel.channel_scope === CHANNEL_SCOPE.MEMBERS_ONLY_FREE }" />
                        <Radio label="Bignerve subscribers" :modelValue="CHANNEL_SCOPE.BIGNERVE_SUBSCRIBERS" :class="{ 'font-bold': channel.channel_scope === CHANNEL_SCOPE.BIGNERVE_SUBSCRIBERS }" />
                        <Radio label="Members only (paid)" :modelValue="CHANNEL_SCOPE.MEMBERS_ONLY_PAID" :class="{ 'font-bold': channel.channel_scope === CHANNEL_SCOPE.MEMBERS_ONLY_PAID }" />
                        <Radio label="Minimum Commentator Score" :modelValue="CHANNEL_SCOPE.MINIMUM_COMMENTATOR_SCORE" :class="{ 'font-bold': channel.channel_scope === CHANNEL_SCOPE.MINIMUM_COMMENTATOR_SCORE }" />
                    </div>
                </RadioGroup>
                
                <div class="border-b w-full my-2"></div>

                <StepImport :platform="platform" :channel="channel" @change="handleUpdateChannel" />

                <StepInvite :platform="platform" :channel="channel" @change="handleUpdateChannel" /> 

                <div class="border-b w-full my-2"></div>
            </div>
        </div>
    </div>
</template> 