<script setup>
import { ref, provide } from 'vue';

const props = defineProps({
    challenge: {
        type: Object,
    },
});

const challenge = ref(props.challenge);

provide('challengeContext', { challenge });
</script>

<template>
    <slot />
</template>