// from radix-ui
import { defineComponent, h, Fragment, Comment, cloneVNode, mergeProps } from "vue"

function renderSlotFragments(children) {
  if (!children)
    return []
  return children.flatMap((child) => {
    if (child.type === Fragment)
      return renderSlotFragments(child.children)

    return [child]
  })
}

export const Slot = defineComponent({
    name: 'PrimitiveSlot',
    inheritAttrs: false,
    setup (_, { attrs, slots }) {
        return () => {
            if (!slots.default) {
                return null;
            }

            const childrens = renderSlotFragments(slots.default());
            const firstNonCommentChildrenIndex = childrens.findIndex(child => child.type !== Comment);
            if (firstNonCommentChildrenIndex === -1) {
                return childrens;
            }

            const firstNonCommentChildren = childrens[firstNonCommentChildrenIndex];

            // remove props ref from being inferred
            delete firstNonCommentChildren.props?.ref;

            const mergedProps = firstNonCommentChildren.props
                ? mergeProps(attrs, firstNonCommentChildren.props)
                : attrs;
            
            // remove class to prevent duplicated
            if (attrs.class && firstNonCommentChildren.props?.class) {
                delete firstNonCommentChildren.props.class;
            }

            // clone the first non-comment child with the merged props
            const cloned = cloneVNode(firstNonCommentChildren, mergedProps);

            // Explicitly override props starting with `on`.
            // It seems cloneVNode from Vue doesn't like overriding `onXXX` props.
            // So we have to do it manually.
            for (const prop in mergeProps) {
                if (prop.startsWith('on')) {
                    cloned.props ||= {};
                    cloned.props[prop] = mergeProps[prop];
                }
            }

            if (childrens.length === 1) {
                return cloned;
            }

            childrens[firstNonCommentChildrenIndex] = cloned;

            return childrens;
        };
    }
});

const SELF_CLOSING_TAGS = ['area', 'img', 'input']

export default defineComponent({
    name: 'Primitive',
    inheritAttrs: false,
    props: {
        asChild: {
            type: Boolean,
            default: false
        },
        as: {
            type: [String, Object, Function],
            default: 'div'
        }
    },
    setup (props, { slots, attrs }) {
        const { as, asChild } = props;
        const asTag = asChild ? 'template' : as;

        if (typeof asTag === 'string' && SELF_CLOSING_TAGS.includes(asTag)) {
            return () => h(asTag, attrs)
        }

        if (asTag !== 'template') {
            return () => h(as, attrs, { default: slots.default });
        }

        return () => h(Slot, attrs, { default: slots.default })
    }
})