<script>
import OlympiadCard from './OlympiadCard.vue';
import CardPlaceholder from '@/components/CardPlaceholder.vue';

export default {
    name: 'TastingOlympiads',

    props: {
        filter: {
            type: String,
            required: true
        },
        title: {
            type: String
        },
        platformSlug: {
            type: String
        },
        q: {
            type: Object,
            default: () => ({}),
        },
        route: {
            type: Object,
            required: false,
        },
    },

    components: {
        OlympiadCard,
        CardPlaceholder,
    },

    data() {
        return {
            busy: false,
            error: false,
            size: 9,
            query: {
                page: 0,
                active: true,
                status: 'open',
                sort: this.filter,
                platform: this.platformSlug,
            },
            olympiads: [],
            total: 0,
        };
    },

    computed: {
        hasMore() {
            return (this.total || 0) > this.olympiads.length;
        },
        isEmpty() {
            return this.olympiads.length === 0;
        },
    },

    watch: {
        platformSlug (val) {
            this.reset();

            this.query.page = 0;
            this.query.platform = val;
            
            this.fetch();
        }
    },

    bus: {
        'catalyst:selected' ({ handle }) {
            this.reset();

            this.query.page = 0;
            this.query.platform = handle;

            this.fetch();
        }
    },

    methods: {
        async fetch() {
            try {
                this.busy = true;

                const { data } = await this.$api.olympiad.query({
                    ...this.query, ...this.q, size: this.size,
                });

                this.merge(data);
                this.afterFetch(data);
            } catch (err) {
                console.log(err)
                this.error = true;
            } finally {
                this.busy = false;
            }
        },
        afterFetch () {},
        loadMore () {
            if (this.hasMore && !this.error) {
                this.query.page = this.query.page + 1;
                this.fetch();
            }
        },
        reset() {
            this.olympiads = [];
            this.total = 0;
        },
        merge({ data, meta }) {
            this.olympiads = [...this.olympiads, ...data];
            this.total = meta.total;
        }
    },

    mounted() {
        this.fetch();
    }
}
</script>

<script setup>
import { usePlatform } from '@/composable/platform';

const currentPlatform = usePlatform();
</script>

<template>
    <section class="card-section">
        <div class="flex items-center justify-between mb-4">
            <h2 class="m-0 text-xl font-narrow text-secondary flex-1">
                {{ title }}
            </h2>
        </div>

        <div v-if="! busy && isEmpty" class="italic py-20 text-center">
            <p class="mb-1">No user tournaments started on {{ currentPlatform.title }} yet.</p>
            <p><RouterLink :to="{ name: 'challenges.create' }" class="text-inherit underline">Create a Challenge</RouterLink> to start your tounament!</p>
        </div>

        <div class="overflow-hidden">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <template v-if="! isEmpty">
                    <OlympiadCard
                        v-for="(olympiad, index) in olympiads"
                        :key="index"
                        :detail="olympiad"
                        class="h-auto"
                    />
                </template>

                <template v-if="busy">
                    <CardPlaceholder v-for="i of 9" :key="`tease-placeholder-${i}`">
                        <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
                    </CardPlaceholder>
                </template>
            </div>
        </div>

        <slot>
            <div class="flex justify-center mt-6">
                <div class="load-more-wrapper">
                    <router-link :to="route || { name: 'home.olympiads', params: { user: $route.params.user || 'platform'}, query: { filter }, hash: '#heading' }" tag="button" class="load-more-btn px-2">
                        <span class="load-more-btn__helper">
                            Load more
                        </span>
                        <span aria-hidden="true" class="icon icon-caret-down-simple"></span>
                    </router-link>
                </div>
            </div>
        </slot>
    </section>
</template>
