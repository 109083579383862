
import { api as $api } from '@/modules/services';

const state = {
    loading: false,
    totalSnoozed: 0,
    snoozeds: [],
}

const getters = {}

const mutations = {
    setTotalSnoozed (state, payload) {
        state.totalSnoozed = payload;
    },
    setSnoozeds (state, payload) {
        payload.forEach(item => item.removing = false);
        
        state.snoozeds = payload;
    }
}

const actions = {
    async getTotalSnoozed ({ commit }) {
        try {
            const { data: response } = await $api.sharing.snoozed();

            commit('setTotalSnoozed', response.meta.total);
        } catch (err) {
            // err
            console.log(err)
        }
    },
    async getSnoozed({ commit, state }) {
        if (state.loading) {
            return
        }
        
        try {
            state.loading = true;
            const { data: response } = await $api.sharing.snoozed()

            commit('setSnoozeds', response.data)
            commit('setTotalSnoozed', response.meta.total);
        } catch (err) {
            // err
            console.log(err)
        } finally {
            state.loading = false;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}