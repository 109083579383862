<script>
import ChallengeTitle from '@/components/challenge/Title.vue';
import ChallengeDetails from '@/components/challenge/Details.vue';
import ChallengeImage from '@/components/challenge/Image.vue';
import ChallengeArticle from '@/components/challenge-show/Article.vue';
import NLoadingNerve from '@/components/NLoadingNerve.vue';
import { useElementVisibility, whenever, templateRef } from '@vueuse/core';
import { useStats } from '@/composable/stats';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';
import { excerpt } from '@/utils/Helpers';

export default {
    name: 'ChallengeCard',

    components: {
        ChallengeTitle,
        ChallengeDetails,
        ChallengeImage,
        ChallengeArticle,
        NLoadingNerve,
    },

    mixins: [useChallengeDetails()],

    props: {
        challenge: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            default: 'catalyst',
        },
        closed: Boolean,
        loading: Boolean,
        flat: Boolean,
        disabled: Boolean,
        loadStats: {
            type: Boolean,
            default: true,
        },
        showDetail: Boolean,
        imageType: {
            type: String,
            default: 'small',
        },
        showArticle: {
            type: Boolean,
            default: true,
        },
        to: [Object, String],
    },

    computed: {
        route () {
            return this.to || (this.challenge.slug && {
                name: 'challenges.show',
                params: { challenge: this.challenge.slug }
            })
        },
        hasBody () {
            return this.$slots.body;
        },
        hasMetadata () {
            return this.$slots.metadata;
        },
        hasRightInfo () {
            return !!this.$slots['right-info'];
        },
    },

    methods: { excerpt },

    setup(props) {
        const elTarget = templateRef('challengeCard');
        const isVisible = useElementVisibility(elTarget)
        const { statsFrom, stats, fetch: resolveStats, loading: loadingStats } = useStats(
            'challenge',
            props.challenge.id,
        );

        whenever(isVisible, resolveStats);

        return { statsFrom, stats, loadingStats };
    },
}
</script>

<template>
    <div
        ref="challengeCard"
        class="challenge-on-card mb-8"
        :class="{
            'is-closed': closed,
            [`is-${color}`]: true,
            'is-elevated': !flat,
            'disabled': !challenge.slug || disabled,
        }">
        <div v-if="loading" class="flex items-center justify-center bg-dust-900 bg-opacity-10 absolute inset-0 z-[1]">
            <NLoadingNerve />
        </div>
        <div class="flex flex-col sm:flex-row sm:flex-nowrap">
            <div class="flex flex-col gap-2">
                <ChallengeArticle v-if="article && showArticle" :article="article" :class="[imageType === 'large' ? 'sm:max-w-[20rem]' : 'sm:max-w-[14rem]', 'border-r border-b mb-3']" >
                    <template #cover>
                        <ChallengeImage
                            :challenge="challenge"
                            :credits="true"
                            :class="imageType === 'large' ? 'min-w-[20rem]' : 'min-w-[14rem]'"
                            class="challenge-detail-card__image-wrapper is-touchable aspect-image"
                        />
                    </template>
                </ChallengeArticle>

                <ChallengeImage v-else
                    :challenge="challenge"
                    :credits="true"
                    :class="imageType === 'large' ? 'min-w-[20rem]' : 'min-w-[14rem]'"
                    class="challenge-detail-card__image-wrapper is-touchable"
                />

                <slot name="left-info" v-bind="{ statsFrom, stats, isClosed, loadingStats }" />
            </div>

            <div class="py-3 px-4 w-full">
                <div class="flex gap-2 w-full flex-col sm:flex-row sm:items-start sm:justify-between">
                    <slot name="title" v-bind="{ statsFrom, stats, isClosed, loadingStats, to: route }" >
                        <ChallengeTitle
                            :challenge="challenge"
                            :to="route"
                            class="text-xl text-dust-800 font-semibold leading-6"
                        />
                    </slot>
                    <div v-if="hasRightInfo" class="flex-col">
                        <!-- <div v-if="closed" class="challenge-on-card__closed-caption m-2">
                            <div class="challenge-on-card__trophy-badge">
                                <span aria-hidden="true" class="icon icon-trophy-solid"></span>
                            </div>
                            Challenge Closed
                        </div> -->
                        <div class="flex flex-row-reverse mb-3 sm:w-full">
                            <slot name="right-info" v-bind="{ statsFrom, stats, isClosed, loadingStats }" />
                        </div>
                    </div>
                </div>

                <slot name="detail" v-bind="{ statsFrom, stats, isClosed, loadingStats }">
                    <ChallengeDetails v-if="showDetail" :challenge="challenge" class="mt-1 bg-dust-100 px-4 pt-4 pb-1" />
                </slot>

                <slot name="nav"  v-bind="{ statsFrom, stats, isClosed, loadingStats }" />
            </div>
        </div>

        <div v-if="hasMetadata" class="challenge-on-card__helper container">
            <div class="flex my-2">
                <slot name="metadata" v-bind="{ statsFrom, stats, isClosed, loadingStats }" />
            </div>
        </div>

        <div v-if="hasBody" class="container my-2">
            <slot name="body" v-bind="{ statsFrom, stats, isClosed, loadingStats }" />
        </div> 

        <slot  v-bind="{ statsFrom, stats, isClosed, loadingStats }" />

        <slot name="footer" v-bind="{ statsFrom, stats, isClosed, loadingStats }" />
    </div>
</template>
