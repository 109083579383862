<script>
import Validatable from '@/mixins/Validatable';

export default {
    mixins: [Validatable],

    props: {
        modelValue: {
            default: ''
        },
        options: {
            type: [Object, Array],
            required: true
        },
        itemLabel: {
            type: String,
            default: 'label'
        },
        itemValue: {
            type: String,
            default: 'value'
        },
        asObject: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
    },

    emits: ['update:modelValue', 'change'],

    computed: {
        hasPrepend() {
            return this.$slots['prepend'];
        },
        hasAppend() {
            return this.$slots['append'];
        },
        selected: {
            get () {
                return this.asObject
                    ? this.valueAccessor(this.modelValue)
                    : this.modelValue;
            },
            set (newValue) {
                const value = this.asObject
                    ? this.valueAccessor(newValue)
                    : newValue;

                this.$emit('update:modelValue', value);
                this.$emit('change', value);
                this.clearErrors();
            },
        },
    },

    methods: {
        labelAccessor (option) {
            if (typeof option === 'object') {
                return option[this.itemLabel];
            }

            return option;
        },
        valueAccessor (option) {
            if (typeof option === 'object') {
                return option[this.itemValue];
            }

            return option;
        },
    }
}
</script>

<template>
    <div class="form-group__helper">
        <label v-if="label" :for="name" class="form-group__label" style="display: block;">{{ label }}</label>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <div class="form-group__input-helper">
            <div class="form-group__input !flex items-center border border-gray-300" :class="{ 'border-red-400': invalid, 'bg-dust-100': disabled }">
                <div v-if="hasPrepend" class="form-group__prepend">
                    <slot name="prepend" />
                </div>
                <select
                    v-bind="attrs"
                    v-model="selected"
                    :class="{ 'is-error': invalid }"
                    :id="name"
                    :name="name"
                    :disabled="form.busy || disabled"
                    class="bg-transparent w-full"
                >
                    <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
                    <option
                        v-for="(option, key) in options"
                        :value="(Array.isArray(options) ? valueAccessor(option) : key)"
                        :key="key"
                    >
                        {{ Array.isArray(options) ?  labelAccessor(option) : option }}
                    </option>
                </select>
                <div v-if="hasAppend" class="form-group__append">
                    <slot name="append" />
                </div>
            </div>
        </div>

        <span v-if="invalid" class="form-group__error">{{ error }}</span>
    </div>
</template>
