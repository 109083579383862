<script>
import Skeleton from '@/components/Skeleton.vue';
import LocationPicker from '@/components/form/LocationSearch.vue';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { mapGetters } from 'vuex';

export default {
    inject: ['form', 'stepper'],

    components: { Skeleton, LocationPicker },

    props: {
        modelValue: {
            type: String,
            default: ''
        },
        categoryTree: {
            type: Array,
            default: () => [],
        },
    },

    emits: ['update:categoryTree', 'update:modelValue', 'change', 'selected'],

    data() {
        return {
            tree: [],
            tools: {
                'qq': {
                    icon: '/img/category-icons/qq-question-question-category-icon.svg',
                    text: 'QQ: Find a better Question'
                },
                'test-an-idea': {
                    icon: '/img/category-icons/test-idea-category-icon.svg',
                    text: 'Test my Idea'
                },
                'improve-an-idea': {
                    icon: '/img/category-icons/improve-category-icon.svg',
                    text: 'Make it better: Improve my idea'
                }
            },
            forced: null,
        };
    },

    watch: {
        tree(categories, history) {
            if (isEqual(categories, history) && !this.forced) {
                return;
            }

            const category = categories.length > 0
                ? categories[categories.length - 1]
                : { id: null, children: [] };

            // notify when categories tree change
            this.$emit('update:categoryTree', categories);

            if (!category.id) {
                return;
            }

            if (!category.children.length || (this.forced && this.forced.id === category.id)) {
                this.$emit('update:modelValue', category.id);
                this.$emit('change', category);
            }
        },
        rootCategories () {
            this.selectById(this.modelValue, { auto: true, force: true });
        },
        categoryMain (category) {
            this.$bus('challenge:creation-flow:category-main', category);
        },
        current (category) {
            if (!this.categoryTree.length && category) {
                this.select({ category, auto: true });
            }
        },
        modelValue: {
            handler (newValue, oldValue) {
                if (!newValue) {
                    this.reset();

                    return;
                }
                
                if (newValue === oldValue) {
                    return;
                }

                this.selectById(newValue, { auto: true, force: true });
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters('app/challenge', {
            'rootCategories': 'categories',
        }),
        categoryMain () {
            const parent = (c) => c && c.parent ? parent(c.parent) : c;

            return parent(this.current);
        },
        current() {
            return this.tree.length > 0 ? this.tree[this.tree.length - 1] : null;
        },
        loopable() {
            return Array.from(this.tree).reverse().find(item => item.children.length > 0)
                || (this.current && this.current.parent)
                || null;
        },
        hasTool () {
            return this.tools[this.form.fields.solution_format]
        },
        hasSelection() {
            return (this.form.$parent.usingTool() && this.hasTool) || this.loopable;
        },
        selectable () {
            return this.current && !['caption'].includes(this.current.slug) || (this.form.$parent.usingTool() && this.hasTool);
        },
        categories () {
            const categories = this.loopable
                ? this.loopable.children
                : this.rootCategories;

            return orderBy(categories, ['name'], ['asc']);
        },
        note () {
            if (this.loopable && !isEmpty(this.loopable.description)) {
                return this.loopable.description;
            }

            return this.$t('component.pick-cateogry.note');
        },
    },

    methods: {
        find(category, id) {
            // find in depth
            if (category.id === id) {
                return category;
            }

            if (category.children.length > 0) {
                for (let i = 0; i < category.children.length; i++) {
                    const child = category.children[i];
                    const found = this.find(child, id);

                    if (found) {
                        return found;
                    }
                }
            }

            return null;
        },
        select({ category, force = false, auto = false }) {
            // force selection
            this.forced = force ? category : null;

            (this.current && this.current.children.length === 0) || force
                ? this.tree = [...this.tree.slice(0, -1), category]
                : this.tree = [...this.tree, category];

            if (!auto) {
                // notify that a category has been selected
                this.$emit('selected', category);
            }
        },
        back() {
            if (this.tree.length === 0 && this.form.$parent.usingTool()) {
                this.form.fields.solution_format = null;
            } else {
                this.tree.splice(
                    this.tree.findIndex(item => item.id === this.loopable.id)
                );

                this.$forceUpdate();
            }
        },
        selectById (id, { auto, force } = {}) {
            if (!id) {
                return;
            }

            const category = this.find({
                children: this.rootCategories },
                id,
            );
            
            if (category) {
                this.select({ category, auto, force });
            }
        },
        fallback(event) {
            event.target.src = '/img/category-icons/article-draft-icon.svg';
        },
        reset () {
            this.tree = [];
        },
    },
}
</script>

<template>
    <div>
        <div class="challenge-categories">
            <div class="grid grid-cols-12 gap-4 mt-3">
                <div v-show="hasSelection" class="col-span-12 sm:col-span-3 xl:col-span-2 challenge-categories__back-btn">
                    <button aria-label="Back" type="button" class="stepper-back-button" @click.prevent="back">
                        <span aria-hidden="true" class="stepper-back-button__icon icon-arrow-alt-circle-left-regular"></span>
                        Back
                    </button>
                </div>

                <div class="challenge-categories__title" :class="{ 'col-span-12 sm:col-span-9 xl:col-span-10': hasSelection, 'col-span-12': ! hasSelection }">
                    <h2 class="stepper-title">Choose a category</h2>
                </div>

                <div v-show="hasSelection" class="col-span-6 sm:col-span-3 xl:col-span-2 challenge-categories__selected-category">
                    <button aria-label="Cagetory"
                        v-if="loopable"
                        :class="{ '!cursor-not-allowed': !selectable }"
                        type="button"
                        class="category-tile is-active is-selected !mb-2"
                        @click.prevent="selectable && select({ category: loopable, force: true })"
                    >
                        <img class="category-tile__icon" :src="`/img/category-icons/${loopable.icon}`" @error="fallback" alt="Category">
                        <span class="category-tile__name">{{ loopable.name }}</span>
                    </button>
                    <button aria-label="Cagetory"
                        v-else-if="form.$parent.usingTool() && hasTool"
                        type="button"
                        class="category-tile is-active is-selected transition duration-100"
                    >
                        <img
                            :src="tools[form.fields.solution_format].icon"
                            alt=""
                            class="category-tile__icon"
                        >
                        <span class="category-tile__name">{{ tools[form.fields.solution_format].text }}</span>
                    </button>

                    <span v-if="selectable" class="text-secondary italic inline-block w-full text-center">
                        Choose this
                    </span>
                </div>

                <div :class="{ 'col-span-12 sm:col-span-9 xl:col-span-10': hasSelection, 'col-span-12': ! hasSelection }">
                    <div v-if="! loopable || loopable.slug !== 'local-civics'" class="grid grid-cols-2 sm:grid-cols-3 gap-4">
                        <template v-if="!rootCategories.length">
                            <div v-for="i of 9" :key="`placeholder-category-${i}`" class="overflow-hidden">
                                <div class="w-full animate-pulse rounded-lg" style="background: #9b9b9b; height: 135px"></div>
                            </div>
                        </template>

                        <div v-for="category in categories" :key="category.slug" :id="category.id">
                            <button aria-label="Cagetory"
                                type="button"
                                class="category-tile is-in-grid transition duration-100 bg-dust-500"
                                :class="{ 'is-active': current && current.id === category.id }"
                                @click.prevent="select({ category, force: current && current.id === category.id })"
                            >
                                <img class="category-tile__icon" :src="`/img/category-icons/${category.icon}`" @error="fallback" alt="">
                                <span class="category-tile__name">{{ category.name }}</span>
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="category-choose-place-wrapper">
                            <h3 class="mt-0 mb-3">Choose what country, region, state, or city this involves:</h3>

                            <LocationPicker
                                :modelValue="form.fields.location"
                                name="region"
                                prepend-icon="map-marker text-dust-700"
                                id="region"
                                placeholder="Country, Region, State, or City"
                                class="w-full"
                                @update:modelValue="({ formatted }) => (form.fields.location = formatted, select({ category: current.children[0] }))"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-span-12 sm:col-span-8 xl:col-span-8 mt-5">
                    <div class="info-box">
                        <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
                        <p  class="info-box__content" v-html="note" ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
