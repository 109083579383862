const STATUS = {
    SCHEDULED: 'scheduled',
    EXECUTED: 'executed',
};

export const checkState = (axios) => {
    axios.interceptors.response.use(response => {
        const { config, data: result } = response;

        if (result.data.status === STATUS.SCHEDULED) {
            return new Promise(resolve => {
                setTimeout(_ => {
                    resolve(axios.get(`/build-tasks/${result.data.id}`))
                }, 5000)
            })
        }

        return response;
    })
}
