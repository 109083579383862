<script>
import VenmoWallet from './wallets/venmo.vue';
import PaypalWallet from './wallets/paypal.vue';

const paymentWallets = {
    'venmo-wallet': VenmoWallet,
    'paypal-wallet': PaypalWallet,
};

export default {
    name: 'PaymentWalletsPage',

    components: paymentWallets,

    data () {
        return {
            availableWallets: [
                {
                    payment_wallet_type: 'VENMO',
                    updated_at: null,
                    wallet_key: null,
                    activate: false
                },
                {
                    payment_wallet_type: 'PAYPAL',
                    updated_at: null,
                    wallet_key: null,
                    activate: false
                }
            ]
        }
    },

    computed: {
        wallets () {
            return this.availableWallets.map(wallet => {
                const { wallets } = this.$auth.profile.payment || { wallets: [] };
                const targetWallet = wallets.find(
                    w => w.payment_wallet_type === wallet.payment_wallet_type
                ) || {};

                return {...wallet, ...targetWallet, component: wallet.payment_wallet_type.toLowerCase() };
            })
        }
    },

    methods: {
        implemend(name) {
            return `${name}-wallet` in paymentWallets;
        },
        getUserDetails() {
            this.$store.dispatch('auth/login');
        }
    }
}
</script>

<template>
    <article class="page">
        <NPageHeader
            title="Payment Wallets"
            subtitle="Manage your payment wallets"
            icon="money-bill-alt-regular"
        />
        <h2>Payment Wallets</h2>
        <p>Choose your payment wallet. If you do not enter any wallet here, your prize winnings will be saved in your BigNerve account until you add a wallet. View your prize winnings in your 
            <router-link class="is-text-underline is-color-black" :to="{ name: 'dashboard.money-prizes', params: { user: 'me'} }">Winnings Dashboard</router-link>.
        </p>
        <p>
            You can save up to 2 wallets, and choose which one to set to ACTIVE. Only one can be active at a time. Both can be set to inactive.
        </p>

        <div v-for="wallet of wallets" :key="wallet.payment_wallet_type" class="page__content mb-4">
            <component
                v-if="implemend(wallet.component)"
                :is="`${wallet.component}-wallet`"
                :wallet="wallet"
                @updated="getUserDetails"
            />
            <p v-else>
                <strong>{{ wallet.payment_wallet_type }}</strong>:  Wallet type not yet implemented!
            </p>
        </div>

        <!-- <div class="flex flex-row justify-center mt-5">
            <button aria-label="button" class="btn is-outlined px-3" @click="() => $refs.chooseWallet.open()">
                Add Payment Wallets
            </button>
        </div>

        <NNotify text-cancel="Cancel" ref="chooseWallet" hide-confirm>
            <template #content>
                <h3>Choose a wallet:</h3>
                <div class="m-4 flex flex-nowrap">
                    <button aria-label="button" class="btn p-4 is-flat mr-3">
                        <img src="/img/brands/paypal.svg" alt="paypal" rule="brand" height="20" />
                    </button>
                    <button aria-label="button" class="btn p-4 is-flat mr-3">
                        <img src="/img/brands/venmo.svg" alt="paypal" rule="brand" height="17" />
                    </button>
                </div>
            </template>
        </NNotify> -->
    </article>
</template>
