<script setup>
import { useExperiencePoints } from '@/composable/brain';

const props = defineProps({
    fqdn: {
        type: String,
        required: true,
    },
});

const { points } = useExperiencePoints(props.fqdn);
</script>

<template>
    <slot :points="points"></slot>
</template>