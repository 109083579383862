<script setup>
import capitalize from 'lodash/capitalize';
import { useFloating } from '@/composable/floating';
import { date, numeric } from '@bignerve/ui-utils';
import { ref } from 'vue';

const props = defineProps({
    detail: Object
});

const { target, ...tooltip } = useFloating();
const open = ref(false);
const fullname = computed(() => {
    return `${props.detail.first_name} ${props.detail.last_name}`;
});
</script>

<template>
    <VTooltip
        v-model:shown="open"
        :disabled="!detail"
        :triggers="[]"
        ref="target"
        container="body"
        placement="right-start"
        title="Recruit Detail"
        theme="raw"
        autoHide
    >
        <div
            @mouseout="e => !detail && (tooltip.hide(), e.target.blur())"
            @click="_ => tooltip.show()"
            @keydown.esc="_ => open = false"
        >
            <slot />
        </div>

        <template #popper>
            <div ref="card" class="w-56 shadow-md overflow-hidden">
                <div class="h-3 bg-scout rounded-tl-xl rounded-tr-xl"></div>
                <div
                    class="scout-card__image"
                    :style="`background-image: url(${detail.avatar_url}), url(/img/user-profile-images/user-avatar-square-anon.svg); background-color: #e1e2e1;`"
                ></div>
                <div class="bg-white p-3">
                    <h3 class="text-lg text-dust-800">{{ fullname }}</h3>
                    <div class="text-sm">
                        <p class="mb-1 text-scout font-semibold">Neurons: <b>{{ numeric(detail.metrics.neurons) }}</b></p>
                        <p class="mb-1">Time Remaining: <b>{{ date(detail.metrics.time_remaining, 'human') }}</b></p>
                        <p class="mb-1">Money they earned you: <b class="text-green-500">${{ numeric(detail.metrics.money, 2) }}</b></p>
                        <template v-if="detail.metrics.recruited_via">
                            <p><i>You recruited them via:</i></p>
                            <div class="flex justify-center">
                                <a href="#" class="btn is-blue is-inverse">
                                    <span aria-hidden="true" class="btn__icon is-left icon-twitter"></span>
                                    {{ capitalize(detail.metrics.recruited_via) }}
                                </a>
                            </div>
                        </template>
                        <div class="inline-flex flex-center space-x-2 mt-3">
                            <NIcon as="comments-solid" class="text-dust-700" left /> {{ detail.metrics.total_messages }}+
                            <span class="text-red-500">({{ detail.metrics.new_messages }}) New</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </VTooltip>
</template>
