<script>
import Closable from '@/mixins/Closable';

export default {
    name: 'NNotify',

    mixins: [Closable],

    emits: ['confirm', 'cancel'],
    
    props: {
        title: {
            type: String
        },
        message: {
            type: String
        },
        textCancel: {
            type: String,
            default: 'No'
        },
        textConfim: {
            type: String,
            default: 'Yes'
        },
        hideConfirm: Boolean,
        hideCancel: Boolean,
        hideOverlay: Boolean,
    },
    bus: {
        'key:escape' () {
            this.close();
        },
    },
    methods: {
        confirm () {
            this.$emit('confirm', this.payload);
            
            this.close();
        },
        cancel () {
            this.$emit('cancel', this.payload);

            this.close();
        },
    },
}
</script>

<template>
    <div v-if="isOpen" class="modal is-open" @click.self="close">
        <div v-if="!hideOverlay" class="modal__background" @click="close"></div>
        <div class="modal__content" :class="{ 'shadow-active rounded-lg': hideOverlay }" @keyup.esc="close">
            <div class="notify">
                <div class="notify__content text-align--left pl-5 pt-5 pr-5 pb-4">
                    <slot name="title">
                        <h3 class="mb-4">{{ title }}</h3>
                    </slot>
                    <slot name="content" v-bind="payload || {}" >
                        {{ message }}
                    </slot>
                </div>
                <div class="px-3 pb-3 pt-2 flex items-center justify-end space-x-2">
                    <slot name="actions" :payload="payload" :cancel="cancel" :confirm="confirm">
                        <button aria-label="button" v-if="!hideCancel" class="px-4 py-2 rounded-sm flex hover:bg-dust-100" @click.prevent="cancel">
                            {{ textCancel }}
                        </button>
                        <button aria-label="button" v-if="!hideConfirm" class="px-4 py-2 rounded-sm flex text-white bg-nerve hover:bg-nerve-600" @click.prevent="confirm">
                            {{ textConfim }}
                        </button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>
