<script setup>
import { useVModel } from '@vueuse/core';
import { ref } from 'vue';
import Embed from './Embed.vue';

const isValidUrl = (link) => {
    try {
        new URL(link)
        return true
    } catch (error) {
        return false
    }
}

const props = defineProps({
    modelValue: {
        type: String,
        required: false,
        default: null,
    },
})

const emit = defineEmits(['update:modelValue'])

const video = useVModel(props, 'modelValue', emit, {
    passive: true,
    defaultValue: props.modelValue,
})

const videoUrl = ref(null)

const inputVideo = (url) => {
    if (!url || !isValidUrl(url)) {
        return
    }

    video.value = url
}
</script>

<template>
    <div class="py-2">
        <div v-if="video" class="bg-dust-100 rounded-md min-h-[315px] min-w-[560px] w-full">
            <Embed width="560" height="315" class="w-full" :src="video" frameborder="0" allowfullscreen />
        </div>

        <input v-else
            v-model="videoUrl"
            type="text"
            class="w-full py-1 text-sm placeholder:text-dust-400"
            placeholder="Paste a YouTube, Vimeo, or other video link, and press Enter"
            @keydown.enter="inputVideo(videoUrl)"
        />
    </div>
</template>