<template>
    <div class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9 challenge-sidebar">
        <div class="onboarding-video" style="background-image: url('/img/placeholder/onboarding-video-02.jpg');">
            <div class="onboarding-video__overlay">
                <span class="onboarding-video__title">Understanding Core<br>Assumptions</span>
                <span aria-hidden="true" class="onboarding-video__icon icon-play-circle-regular"></span>
                <span class="onboarding-video__caption">Watch video</span>
            </div>
        </div>
        <div class="challenge-help-box">
            <p><strong>Optional but recommended!</strong></p>
            <p>It’s up to you to learn when these are appropriate. For most complex problems, people usually ask the wrong questions, which could end up generating “good solutions” to the wrong problem.</p>
            <p><strong>We highly recommend doing the Deep Dives to:</strong></p>
            <ul>
                <li>clarify your question and sharpen your thinking;</li>
                <li>engage more innovators;</li>
                <li>produce better solutions; and</li>
                <li>gain more Catalyst neurons in the game.</li>
            </ul>
            <div class="challenge-help-box__footer">
                <button aria-label="Skip" type="button" class="btn is-secondary">Skip</button>
            </div>
        </div>
    </div>
</template>
