export default {
    computed: {
        tasks() {
            return this.items.tasks.filter(item => {
                return this.role === 'overall' || item.targetGameDash.includes(this.role);
            }).map(item => {
                const role = item.targetGameDash.split(',')[0];

                return Object.assign({}, item, {
                    class: `is-${role}`,
                    title: item.object,
                    actionTitle: `${item.subject} ${item.actionToPerform}`,
                    icon: item.icon,
                    action: item.action ? item.action.bind(this) : () => {},
                    route: item.route
                });
            });
        },
        highlights() {
            return this.items.highlights.map(item => {
                switch (item.notificationType) {
                    case 'solution-delta':
                        item.context = `Your <span class="is-color-orange" data-v-route href="/challenges/${item.contestId}">challenge</span> solution titled <em>${item.contextText}</em> moved up by ${item.positionDelta} points`;
                        break;
                    case 'friend-solution':
                        item.context = `A friend posted a solution in the challenge <span class="is-color-orange" data-v-route href="/challenges/${item.contestId}">${item.contextText}</span>`;
                        break;
                    case 'mentor-comment':
                        item.context = `You received a mentor comment on your <span class="is-color-orange" data-v-route href="/challenges/${item.contestId}">challenge</span> solution titled <em>${item.contextText}</em>`;
                        break;
                    default:
                        item.context = item.contextText;
                        break;
                }

                return item;
            });
        }
    }
};
