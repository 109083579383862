export default class Errors
{
    /**
     * Create a new errors instance.
     *
     * @return {void}
     */
    constructor()
    {
        this.errors = {};
    }

    /**
     * Get all the errors.
     *
     * @return {object}
     */
    all()
    {
        return this.errors;
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param  {string}  field
     * @return {bool}
     */
    has(field)
    {
        return this.errors.hasOwnProperty(field);
    }

    /**
     * Determine if we have any errors.
     *
     * @return {bool}
     */
    any()
    {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param  {string}  field
     * @return {mixed}
     */
    get(field)
    {
        return this.errors[field] || null;
    }

    /**
     * Record the new errors.
     *
     * @param  {object}  errors
     * @return {void}
     */
    record(errors)
    {
        this.errors = errors.reduce((stack, error) => {
            return Object.assign(stack, {
                [error.field ? error.field.toLowerCase() : '*']: error.error
            });
        }, {});
    }

    /**
     * Set the new error.
     *
     * @param  {string}  field
     * @param  {string}  error
     * @return {void}
     */
    set (field, error) {
        this.errors[field ? field.toLowerCase() : '*'] = error;
    }

    /**
     * Clear one or all error fields.
     *
     * @param  {string}  field
     * @return {void}
     */
    clear(field)
    {
        if (field) {
            delete this.errors[field];
            this.errors = Object.assign({}, this.errors);
        } else {
            this.errors = {};
        }
    }
}
