<script>
import { useChallengeDetails } from '@/mixins/ChallengeDetails';

export default {
    name: 'ChallengeRatingInit',

    mixins: [useChallengeDetails()],

    props: ['challenge'],
}
</script>

<template>
    <div class="flex justify-center mt-5 mb-5">
        <div class="interstitial-modal">
            <div class="interstitial-modal__heading is-yellow">
                <h1 class="interstitial-modal__title font-medium">
                    <span aria-hidden="true" class="icon icon-alright"></span>
                    Rating ideas is vital for our innovators and earns you Analyst points
                </h1>
                <!-- <button aria-label="button" aria-hidden="true" class="interstitial-modal__close-btn icon-times-circle-regular"></button> -->
            </div>
            <div class="interstitial-modal__content">
                <div class="flex flex-col items-center sm:items-start sm:flex-row sm:justify-around mb-8 gap-4">
                    <div class="text-center">
                        <router-link :to="{ name: 'challenges.show', params: { challenge: $route.params.challenge } }" class="btn is-green mb-3 py-2 px-4 sm:mb-2">
                            <NIcon as="lightbulb" left />
                            <span class="leading-none">Enter an Idea</span>
                        </router-link>

                        <p v-if="isSuggestion" class="is-color-green mb-0 w-[13rem] italic">
                            then you can rate the other ideas and earn points.
                        </p>
                        <p v-else class="is-color-green mb-0 w-[13rem] italic">
                            (up to {{ useGameSetting('max_user_ideas') }}) then you can rate the other ideas and earn points.
                        </p>
                    </div>
                    <div  class="text-center sm:mt-0">
                        <component :is="$route.query.ideas === 'none' ? 'span' : 'router-link'" :to="{ name: 'challenges.rate', params: { challenge: $route.params.challenge } }" :class="{ disabled: $route.query.ideas === 'none' }" class="btn is-yellow mb-3 py-2 px-4 sm:mb-2">
                            <NIcon as="alright" left />
                            <span class="leading-none">Start Rating</span>
                        </component>
                        <span v-if="$route.query.ideas === 'none'" class="italic text-rater"><br> (0 ideas to rate, currently)</span>
                        <p v-if="isSuggestion" class="text-rater mb-0 w-40 italic">
                            and earn points.
                        </p>
                        <p v-else class="text-rater mb-0 w-[13rem] italic">
                            and mentoring to earn points.
                        </p>
                    </div>
                </div>
                <template v-if="isQQ">
                    <h2 class="text-2xl font-normal mb-3">
                        In a QQ, you can enter your own ideas even after seeing and rating other ideas…
                    </h2>
                    <p class="text-dust-800">
                        Our idea game is normally double-blinded, meaning you can’t <b>see</b> other ideas before putting in your own. But in a QQ, you are allowed to rate first, then enter your ideas after, at any time. Why? Because the goal here is to rapidly suggest great questions for the director to launch. The goal is speed, and to avoid duplicate ideas.
                    </p>
                    <p class="text-dust-800">
                        You can also view all ideas from prior tournament cycles, too. <b>The</b> Suggestion Box Questions are continuous, and reset each month. 
                    </p>
                </template>
                <template v-else>
                    <h2 class="text-2xl font-normal mb-3">
                        If you <b>rate</b> first, you cannot enter your own ideas in this challenge.
                    </h2>
                    <p class="text-dust-800">
                        Why? Our idea challenges are double-blind while running, so you can’t see other players’ ideas <em>before</em> entering your own. You also don’t know whose idea you’re rating — this keeps the game fair.
                    </p>
                    <p class="text-dust-800">
                        For innovators, feedback and mentoring is essential to hone our ideas and improve our thinking skills. Our game motto is: “every idea is worth at least 3 honest ratings”. So like an analyst, rate well &amp; give as many mentoring comments as you can — it helps everyone get better.
                    </p>
                    <p class="text-dust-800">
                        So, if you want to just <b>rate & mentor</b>, go ahead — there are many other challenges to enter your own ideas.
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>
