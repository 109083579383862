<script setup>
import RatingEnd from '@/components/idea/RatingEnd.vue';
import { oldApi as nerve } from '@/modules/services';
import { ref } from 'vue';

const props = defineProps({
	challenge: {
		type: Object,
		required: true,
	},
});
const flow = ref(null);
const loading = ref(false);

const fetchFlow = async () => {
	try {
		loading.value = true;
		const { data } = await nerve.challenges.startRating(props.challenge.slug);

		flow.value = data;
	} finally {
		loading.value = false;
	}
};

fetchFlow();
</script>

<template>
	<RatingEnd
		v-if="flow"
		:challenge="challenge"
		:flow="flow"
	/>
	<div v-else class="animate-pulse">
		<div class="flex-1">
			<div class="relative mb-10">
				<div class="h-40 bg-dust-400 rounded-lg"></div>
				<div
					class="arrows-bottom-[20px] arrows-border-dust-400 absolute arrows-bg-dust-400 !left-1/2 transform !-translate-x-5 !-translate-y-1"
				/>
			</div>
			<div class="bg-dust-400 mb-12 mx-auto w-[50rem] h-[20rem] rounded-lg"></div>
		</div>
	</div>
</template>
