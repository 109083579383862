<script>
import Who from '@/components/challenge-show/Who.vue';
import Why from '@/components/challenge-show/Why.vue';
import What from '@/components/challenge-show/What.vue';

export default {
    props: {
        challenge: {
            type: Object,
            required: true,
        },
    },
    components: {
        Who,
        Why,
        What
    },

    data() {
        return {
            isExpanded: false,
        };
    },

    computed: {
        isCaption () {
            return this.challenge.caption_solution_format === 'caption';
        },
        hasAssumptions () {
            return [
                this.challenge.assumption_what,
                this.challenge.assumption_who,
                this.challenge.assumption_why,
            ].some(assumption => !! assumption);
        },
    },

    watch: {
        challenge: {
            handler (challenge) {
                if (challenge) {
                    this.isExpanded = [
                        challenge.assumption_what,
                        challenge.assumption_who,
                        challenge.assumption_why,
                    ].some(v => !!v)
                }
            },
            immediate: true,
        },
    },

    methods: {
        toggle() {
            this.isExpanded = !this.isExpanded;
        }
    },
}
</script>

<template>
    <div>
        <div v-if="hasAssumptions" class="extendable-content__hidden py-4" v-show="isExpanded">
            <Who :challenge="challenge" />
            <What :challenge="challenge" />
            <Why :challenge="challenge" />
        </div>
        <div class="extendable-content-footer flex flex-wrap items-stretch pb-3 min-h-8 sm:pb-2">
            <div class="extendable-content-footer__col flex-1 p-2 sm:p-0">
                <button aria-label="button" v-if="hasAssumptions" :disabled="isCaption" :class="{ 'opacity-20': isCaption }" type="button" class="extendable-content-footer__btn" @click.prevent="toggle">
                    <NIcon
                        :as="!isExpanded ? 'caret-square-down-solid' : 'caret-square-up-solid'"
                        class="text-nerve"
                    />
                    <b class="mr-2">{{ isExpanded ? 'Hide the details' : 'Expand the details' }}</b>
                    <span class="font-weight-normal">(See the WHO, WHAT, WHY)</span>
                </button>
            </div>
            <div class="flex justify-between sm:items-center">
                <slot name="actions" />
            </div>
        </div>
    </div>
</template>
