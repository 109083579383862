<script setup>
import CurrentTournament from './CurrentTournament.vue';
import toUpper from 'lodash/toUpper';
import RoleIcons from './RoleIcons.vue';
import TipsHelpful from './TipsHelpful.vue';
import Highlights from './Highlights.vue';
import NerveScore from './NerveScore.vue';
import NeuronsEarned from './NeuronsEarned.vue';
import { useLinks as useAuthLinks } from '@/composable/auth';
import { useGameDash } from '@/composable/gamedash';
import { ref } from 'vue';

const props = defineProps({
    hideHeader: {
        type: Boolean,
        default: false
    },
});
const emit = defineEmits(['update']);
const tab = ref('neurons-earned');
const { loginURL } = useAuthLinks();
const { brain, role } = useGameDash();
</script>
<template>
    <section class="game-dash rounded-none shadow-2xl mx-4 overflow-hidden" :class="{ 'rounded-tr-2xl': hideHeader}">
        <slot v-if="!hideHeader" name="header">
            <h2 class="game-dash-header m-0">
                <span class="game-dash-header__title">
                    {{ $t('component.game-dash.title') }}
                </span>
            </h2>
        </slot>
        <div class="game-dash__content bg-white !rounded-none">
            <div class="game-dash-menu">
                <RouterLink :to="{ name: 'platform.olympiad.leaderboard', params: { platformSlug: $route.params.user || 'platform' } }" class="game-dash-menu__item is-left">
                    <span class="game-dash-menu__item-icon icon-race-flag" aria-hidden="true"></span>
                    Idea Race
                </RouterLink>
                <RouterLink v-if="$auth.authenticated" :to="{ name: 'dashboard.notifications', params: { user: $auth.user.screen_name || 'me' } }" class="game-dash-menu__item is-right">
                    Notifications
                    <span class="game-dash-menu__item-icon icon-brain" aria-hidden="true"></span>
                </RouterLink>
            </div>

            <div class="border-x border-300">
                <CurrentTournament />

                <NTabs v-model="tab" active-class="bg-white border-t-2 border-innovator !rounded-none font-bold" class="w-full border-t bg-dust-100" align="between" mode="full">
                    <NTab :title="$t('component.game-dash.neurons-earned.title')" id="neurons-earned">
                        <div v-if="$auth.authenticated" class="p-4 mb-1 bg-white sb-snug sb-dust-400 overflow-y-auto" style="height: calc(59vh - 3rem)">
                            <NerveScore
                                :role="role"
                                :level="brain?.brain_level"
                                :position="brain?.rank_position"
                                :score="brain?.score"
                                :next-level-score="brain?.next_level_score"
                                :title="role === 'overall' ? 'Overall Nerve Score' : `${role} Score`"
                                class="mb-6"
                            />
                            <NeuronsEarned :role="role" ref="neurons" height="calc(36vh - 3rem)" />
                        </div>
                        <div v-else class="flex flex-col items-center justify-center pb-20 bg-white" style="height: calc(59vh - 3rem)">
                            <p class="text-dust mb-5">
                                {{ $t('component.game-dash.neurons-earned.sign-in.note') }}
                            </p>
                            <a :href="loginURL" class="flex justify-center !text-white bg-blue hover:bg-blue-600 px-4 py-3 leading-tight rounded-md font-bold whitespace-nowrap">
                                {{ $t('component.game-dash.neurons-earned.sign-in.action') }}
                            </a>
                        </div>
                    </NTab>
                    <NTab :title="$t('component.game-dash.helpful-tips.title', { prefix: toUpper(role === 'overall' ? 'Helpful' : role)})" icon="lightning text-nerve text-sm" id="tips">
                        <TipsHelpful :role="role" class="p-4 mb-1 bg-white sb-snug sb-dust-400 overflow-y-auto" style="height: calc(59vh - 3rem)" />
                    </NTab>
                    <NTab :title="$t('component.game-dash.highlishts.title')" id="highlights">
                        <Highlights class="p-4 mb-1 bg-white sb-snug sb-dust-400 overflow-y-auto" style="height: calc(59vh - 3rem)" />
                    </NTab>
                </NTabs>
            </div>
        </div>
        <RoleIcons />
    </section>
</template>
