import { oldApi as $nerve } from '@/modules/services';

const state = {
  rawCategories: [],
  categoriesRelationship: []
};

const getters  = {
  categories ({ rawCategories, categoriesRelationship }) {
      const to = (category, relations) => {
          const info = relations.find(relation => relation.id === category.category);
          return {
              id: category.id,
              reference: category.category,
              name: info.name,
              slug: info.slug,
              related_platform: info.related_platform || null,
              description: info.description,
              icon: info.icon.toLowerCase().replace(' ', '-'),
              children: category.subcategories.map(sub => to(sub, relations))
          };
      }
      const putParent = (categories, parent = null) => {
        categories.forEach(c => {
          c.parent = parent;

          if (c.children && c.children.length) {
            putParent(c.children, c);
          }
        })
      }
      const categories = rawCategories.map(
        category => to(category, categoriesRelationship)
      );

      putParent(categories);

      return categories;
  }
};

const mutations = {
  setRawCategories (state, payload) {
      state.rawCategories = payload;
  },

  setCategoriesRelationship (state, payload) {
      state.categoriesRelationship = payload;
  }
};

const actions = {
  async getAllCategories ({ commit }, platformID) {
      try {
          const { data: payload } = await $nerve.challenges.allCategories(platformID);

          commit('setRawCategories', payload.data);
          commit('setCategoriesRelationship', payload.relationships)
      } catch (err) {
          // err
          console.log(err)
      }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
