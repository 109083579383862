<script setup>
import { QuillEditor } from '@vueup/vue-quill';
import { toHTML, toMarkdown } from '@/mixins/Markdown';
import { watch, shallowRef, h, render, customRef } from 'vue';
import { api } from '@/modules/services';
import { useCancelToken } from '@/composable/requester';
import { debounce, orderBy, uniq } from 'lodash';
import { cn } from '@/utils/Helpers';

const DEBOUNCE_USER_SEARCH = 300;
const DEBOUNCE_MENTION_SEARCH = 1000;

const model = defineModel('modelValue', { default: '', type: String });
const mentions = defineModel('mentions', { default: [], type: Array });

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    autofocus: {
        type: Boolean,
        default: false
    },
    tabindex: {
        type: [String, Number],
    },
    readOnly: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    toolbar: {
        type: [Array, Boolean, Object],
        default: () => ({
            container: [
                ['bold', 'italic', 'strike'],
                [{ header: [2, 3, 4, false] }],
                ['link', 'blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['undo', 'redo'],
            ],
            handlers: {
                undo: function() {
                    this.quill.history.undo();
                },
                redo: function() {
                    this.quill.history.redo();
                }
            },
            history: {
                delay: 2000,
                maxStack: 500,
                userOnly: true
            }
        })
    },
    users: {
        type: Array,
        default: () => ([])
    },
    classContainer: {
        type: [String, Object, Array],
        default: ''
    },
    invalid: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['blur']);

const content = customRef((track, trigger) => {
    let internalValue = '';

    return {
        get() {
            track();

            return internalValue || toHTML(model.value);
        },
        set(newValue) {
            const newMarked = toMarkdown(newValue)

            if (newMarked === model.value) return;

            internalValue = newValue;
            model.value = newMarked;

            trigger();
        }
    }
});

const editor = shallowRef(null);
const { abortPrevious, nextToken } = useCancelToken();

const userSearch = debounce(async function (searchTerm, renderList) {
    let values = props.users;

    abortPrevious();
    if (searchTerm.length === 0) {
        renderList(values, searchTerm);
    } else {
        const matches = [];

        for (let i = 0; i < values.length; i++) {
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.push(values[i]);
            }
            
            renderList(matches, searchTerm);
        }

        // if found in the list, don't search the api
        if (matches.length > 0) return;

        await api.user.search(
            { query: searchTerm, size: 50, filter: 'active' },
            { cancelToken: nextToken() }
        ).then(({ data: response }) => {
            const users = orderBy(
                response.data,
                // ensure users starting with the searched term are at the top
                [({ handle }) => handle.startsWith(searchTerm) ? 0 : 1, 'handle'],
                ['asc', 'asc']
            );

            users.forEach((user) => {
                if (matches.find((match) => match.id === user.id)) return;

                matches.push({
                    id: user.id,
                    value: user.handle,
                    name: user.first_name,
                });
            });

            renderList(matches, searchTerm);
        }).catch((e) => {
            if (e.name === 'CanceledError') {
                return;
            }

            throw e;
        })
    }
}, DEBOUNCE_USER_SEARCH);

const options = {
    modules: {
        toolbar: props.toolbar,
        markdownShortcuts: {},
        magicUrl: {},
        clipboard: {
            magicPasteLinks: true,
        },
        mention: {
            allowedChars: /^[A-Za-z]*$/,
            mentionDenotationChars: ['@'],
            renderItem (item) {
                const target = document.createElement("div");
                
                render(h('div', { class: 'flex items-baseline gap-2 ' }, [
                    h('strong', { class: 'text-dust-800 truncate' }, item.value),
                    h('small', { class: 'text-dust-600 whitespace-nowrap' }, item.name),
                ]), target);

                return target;
            },
            source: userSearch,
            renderLoading: function () {
                return document.createTextNode("Searching...");
            },
        },
    }
};

const extractMentionedUsers = debounce((newValue) => {
    const matches = String(newValue).match(/(?<![\w.-])@[\w.-]+(?:(\\)+_[\w.-]+)*/g);

    if (!matches) {
        mentions.value = [];
        return;
    }

    // replace the mentions to ensure the correct mentions are from the editor
    mentions.value = uniq(matches)
        .map((match) => match.slice(1).replace(/(\\)+/g, ''));
}, DEBOUNCE_MENTION_SEARCH);

watch(model, extractMentionedUsers, { immediate: true });

const reset = () => {
    editor.value.setText('');
}

const focus = () => {
    editor.value.focus();
    editor.value.getQuill().setSelection(editor.value.getQuill().getLength(), 0);
}

watch(() => [props.disabled, editor.value], (n) => {
    if (editor.value) {
        const quill = editor.value.getQuill();

        if (quill) quill.enable(!props.disabled)
    }
}, { immediate: true });

watch(() => props.placeholder, (newPlaceholder) => {
    if (editor.value) {
        const quill = editor.value.getQuill();

        if (quill) {
            quill.root.setAttribute('data-placeholder', newPlaceholder);
        }
    }
}, { immediate: true });

defineExpose({
    focus,
    reset,
    editor,
});
</script>

<template>
    <div class="ql-text-editor">
        <div :class="cn('border border-dust-300 rounded-md bg-white', invalid && 'border-red-400', classContainer)">
            <QuillEditor
                ref="editor"
                v-model:content="content"
                :placeholder="placeholder"
                :readOnly="readOnly"
                :tab-index="tabindex"
                :options="options"
                :name="name"
                contentType="html"
                @blur="() => emit('blur', content)"
            />
        </div>
        <span v-if="invalid && error" class="form-group__error">{{ error }}</span>
    </div>
</template>