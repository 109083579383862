<script>
    import RandomImages from '@/components/challenge/RandomImages.vue';
    import useChallengeCreateFlow from '@/mixins/ChallengeCreateFlow';

    export default {
        name: 'Media',

        mixins: [useChallengeCreateFlow],

        data: () => {
            return {
                hasImageUploaded: false,
            };
        },

        components: {
            RandomImages
        },

        inject: ['form', 'stepper', 'board'],

        computed: {
            isImplicit() {
                return this.$route.name === 'challenges.show';
            },
            category() {
                const categories = this.board.categoryTree;

                return categories.length > 0 ? categories[categories.length - 1].name : 'Blank';
            },
            tags() {
                const tree = this.board.categoryTree;
                const main = tree.length > 0 ? tree[tree.length - 1].name : null

                return [main].concat(this.form.fields.challenge.tags);
            },
        },

        methods: {
            jumpToArticle () {
                this.stepper.scrollToSteps();

                setTimeout(() => {
                    this.stepper.animated = true;
                    this.stepper.reorder({
                        slug: 'article-source', 
                        order: 4,
                        select: true,
                    });
                }, 300);
            },
            updateArticleImage({ url }) {
                if (this.form.fields.challenge.article && !this.form.fields.challenge.article.image_url) {
                    this.form.fields.challenge.article.image_url = url;
                }
            }
        }
    }
</script>

<template>
    <div v-if="form.fields.challenge">
        <div v-if="! isImplicit" class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">
                <b>Add an Image to your Question</b>
            </h2>
        </div>
        <div class="form-section-wrapper">
            <div class="flex flex-col gap-6">
                <div>
                    <label class="form-group__label">
                        <b>Upload your own image, graphic or illustration</b>
                        <i v-if="!isCaption" class="font-weight-normal ml-1">(optional, or you can fetch a free Unsplash or Pixabay image)</i>
                    </label>
                    <p class="form-block-help-text mb-0">
                        <i>Upload a JPEG or PNG up to 20mb in size. We’ll resize it to 1200px width and proportional height to be mobile-friendly.</i>
                    </p>
                    <NFile
                        v-model="form.fields.challenge.primary_image"
                        :accept="['image/jpeg', 'image/jpg', 'image/png', 'image/gif']"
                        name="primary_image"
                        @loaded="() => hasImageUploaded = true"
                        @change="updateArticleImage"
                    />
                </div>
                <div v-if="! board.isCaption && !hasImageUploaded">
                    <label class="form-group__label">
                        Or find a free one from Unsplash & Pixabay <i class="font-weight-normal text-dust-600">(optional)</i>
                    </label>
                    <p class="form-block-help-text">
                        We’ll use your main category of <b>{{ category }}</b> and the keywords to fetch some relevant images. If you can’t find one you like after a few tries, consider adding more secondary keywords to get better matches.
                    </p>
                    <RandomImages
                        v-model="form.fields.challenge.primary_image"
                        :tags="tags"
                        :prefetch="true"
                        @prefetch="form.fields.challenge.primary_image = ''"
                        @change="updateArticleImage"
                    >
                        <template #tags="{ fetch }">
                            <NTags v-model="form.fields.challenge.tags" @change="() => fetch()" />
                        </template>
                    </RandomImages>
                </div>
                <div v-if="! board.isCaption && stepper.has('article-source')">
                    <label class="form-group__label">
                        Are you linking to an external <span class="underline">news article</span> or <span class="underline">blog post</span>? <i class="font-weight-normal text-dust-600">(optional)</i>
                    </label>
                    <p class="form-block-help-text">
                        If your news source, article, or blog post has its own image, you can use that main image for your challenge. We’ll pull in the image using OpenGraph.
                    </p>
                    <div>
                        <button aria-label="button" type="button" class="flex items-center p-2 px-6 rounded-md shadow hover:bg-nerve-600 hover:text-white border bg-nerve text-white transition ease-in-out duration-300" @click="jumpToArticle">
                            Jump to the Article Module
                            <NIcon as="image" class="ml-2"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
