<script>
import { usePlatform } from '@/mixins/ShowsPlatform';

export default {
    name: 'AccountSidebar',

    mixins: [usePlatform()],
}
</script>

<template>
    <div class="page__sidebar-inside">
        <ul>
            <li>
                <RouterLink :to="{ name: 'account.my-account.email-preferences' }">Email &amp; Alert Preferences</RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'account.my-account.security' }">Password &amp; Security</RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'account.my-account.social' }">Social Integrations</RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'account.my-account.payment-wallets' }">Payment Wallets
                    <NIcon as="money-bill-alt-regular" class="ml-3" />
                </RouterLink>
            </li>
        </ul>
        <div class="is-divider is-dashed my-3"></div>
        <ul>
            <li>
                <RouterLink :to="{ name: 'account.my-account.masthead' }">Your Masthead</RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'account.my-account.profile' }">Your Bio & Profile</RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'account.my-account.organization' }">Your Organization</RouterLink>
            </li>
        </ul>
        <div class="is-divider is-dashed my-3"></div>
        <ul>
            <li>
                <RouterLink :to="{ name: 'account.my-account.forum-preferences' }">Forum Preferences</RouterLink>
            </li>
            <FeatureFlag flag="forum-custom-channels-tools">
                <li>
                    <RouterLink :to="{ name: 'account.my-account.forum-tools' }">Forum Tools</RouterLink>
                </li>
            </FeatureFlag>
        </ul>
        <div class="is-divider is-dashed my-3"></div>
        <ul v-if="currentUser.user.id">
            <li>
                <em>Shortcuts:</em>
            </li>
            <li>
                <RouterLink :to="{ name: 'dashboard.dashboard', params: { user: currentUser.user.screen_name } }">
                    My Dashboard
                </RouterLink>
            </li>
            <li>
                <RouterLink :to="{ name: 'discussion-forums.most-recent', params: { user: currentUser.user.screen_name } }">
                    My Forum
                </RouterLink>
            </li>
            <li>
                <a href="/nerve-boards">Nerve Boards</a><!-- to fix later -->
            </li>
            <li>
                <RouterLink :to="{ name: 'faqs' }">FAQs</RouterLink>
            </li>
        </ul>
    </div>
</template>
