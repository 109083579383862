<script>
import { h } from 'vue';

export default {
    name: 'Chip',

    props: {
        tag: {
            type: String,
            default: 'span'
        },
        rounded: {
            type: Boolean,
            default: false
        }
    },

    setup (props, { attrs, slots }) {
        return () => h(props.tag, {
            ...attrs,
            class: {
                'is-chip': true,
                'is-rounded': props.rounded,
            },
        }, slots && slots.default());
    }
}
</script>
