import orderBy from 'lodash/orderBy';

const state = {
    olympiads: [],
    fetchingOlympiads: false,
};

const getters = {
    currentOlympiad (state) {
        return state.olympiads.find(olympiad => olympiad.is_current);
    },
};

const mutations = {
    setOlympiads (state, payload) {
        state.olympiads = orderBy(payload, ['start_time'], ['desc']);
    },
};

const actions = {
    async fetchOlympiads ({ commit, state, rootGetters }) {
        const platform = rootGetters['app/platform'];

        state.fetchingOlympiads = true;
        const { data: olympiads } = await this._vm.$api.game.olympiads(platform.id);
        state.fetchingOlympiads = false;

        commit('setOlympiads', olympiads.data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
