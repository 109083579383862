import Handler from 'universal-cookie';

const cookie = new Handler();

export default class Cookie
{
    /**
     * Get the cookie.
     *
     * @param  {string}  key
     * @param  {mixed}  value
     * @return {string}
     */
    static get(key, value = null)
    {
        return cookie.get(key) || value;
    }

    /**
     * Set the cookie.
     *
     * @param  {string}  key
     * @param  {string}  value
     * @param  {number}  days
     * @return {string}
     */
    static set(key, value, shared = false, days)
    {
        const domain = (new URL(import.meta.env.VUE_APP_BASE_URL)).hostname;
        const age = days === Infinity ? 100 * 365 * 24 * 60 * 60 : (days ? days * 24 * 60 * 60 : null);

        cookie.set(key, value, {
            path: '/',
            maxAge: age,
            secure: import.meta.env.PROD,
            sameSite: 'strict',
            domain: shared ? domain : null,
        });

        return Cookie;
    }

    /**
     * Delete the cookie.
     *
     * @param  {string}  key
     * @return {void}
     */
    static delete(key, shared = false)
    {
        const domain = (new URL(import.meta.env.VUE_APP_BASE_URL)).hostname;
        cookie.remove(key, {
            path: '/',
            secure: import.meta.env.PROD,
            sameSite: 'strict',
            domain: shared ? domain : null,
        });

        return Cookie;
    }

    /**
     * Determine if the cookie exists.
     *
     * @param  {string}  key
     * @param  {string}  value
     * @return {bool}
     */
    static has(key, value = null)
    {
        return ! value ? !! this.get(key) : this.get(key) == value;
    }
}
