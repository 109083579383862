export default requester => {
    return {
        /**
         * Share the subject on provided social network
         *
         * @param {object} payload
         **/
        share ({ provider, subject, target, options } = {}) {
            return requester.post(`/sharing/${provider}/${subject}/${target}`, null, { params: options });
        },

        /**
         * Get the intent to share the subject
         *
         * @param {object} payload
         **/
        intent ({ subject, target, options } = {}) {
            return requester.get(`/sharing/intent/${subject}/${target}`, { params: options });
        },

        /**
         * Get snoozed sharings
         *
         * @param {object} params
         **/
        snoozed (params) {
            return requester.get(`/sharing/snoozed`, { params });
        },

        /**
         * Get snoozed sharings
         *
         * @param {object} payload
         **/
        snooze (payload) {
            return requester.post(`/sharing/snoozed`, payload);
        },

        /**
         * Remove snoozed sharing
         *
         * @param {string} id
         **/
        removeSnoozed (id) {
            return requester.delete(`/sharing/snoozed/${id}`);
        },
    };
}
