<script setup>
import Medal from '@/components/leaderboard/Medal.vue';
import ChallengeCard from '@/components/game/challenge-card/index.vue';
import UseMedalMeta from '@/components/game/medals/UseMedalMeta.vue';
import HeaderLinks from "@/layouts/partials/HeaderLinks.vue";
import { api } from '@/modules/services';
import { ref, computed } from 'vue';
import { useInfiniteScroll } from '@vueuse/core';
import { MEDAL } from '@/constant/idea';
import { date } from '@bignerve/ui-utils';
import { useRoute, useRouter } from 'vue-router';
import { groupBy } from 'lodash';
import { useCrossPlatformState } from '@/composable/app';

const sharedState = useCrossPlatformState('bignerve.medals');

const router = useRouter();
const route = useRoute();
const page = ref(0);
const busy = ref(false);
const bestIdeas = ref(false);
const total = ref(0);
const el = ref(null);
const filter = ref(route.query.filter);
const showChallenge = sharedState('show-challenge', {
	transform: (value) => value === 'true',
});

const medals = {
	[MEDAL.CATALYST_GOLD]: {
		image: 'catalyst-gold-medal',
		label: 'Catalyst Gold Medal',
	},
	[MEDAL.CATALYST_SILVER]: {
		image: 'catalyst-silver-medal',
		label: 'Catalyst Silver Medal',
	},
	[MEDAL.CATALYST_BRONZE]: {
		image: 'catalyst-bronze-medal',
		label: 'Catalyst Bronze Medal',
	},
	[MEDAL.COMMUNITY_GOLD]: {
		image: 'community-gold-medal',
		label: 'Community Gold Medal',
	},
	[MEDAL.COMMUNITY_SILVER]: {
		image: 'community-silver-medal',
		label: 'Community Silver Medal',
	},
	[MEDAL.COMMUNITY_BRONZE]: {
		image: 'community-bronze-medal',
		label: 'Community Bronze Medal',
	},
	[MEDAL.BLUE]: {
		image: null,
		label: 'Blue Medal',
	},
};
const ideas = ref([]);
const hasMore = computed(() => ideas.value.length < total.value);
const groupedIdeas = computed(() => {
	return Object.values(groupBy(ideas.value, 'challenge.slug'));
});

const fetch = async (page = 0) => {
	try {
		busy.value = true;

		if (page === 0) ideas.value = [];

		const { data: response } = await api.game.awardMedals(route.params.user, { sort: 'challenge', page, size: 10, filter: filter.value });

		// increment page
		ideas.value = [...ideas.value, ...response.data];

		total.value = response.meta.total;

		return true;
	} finally {
		busy.value = false;
	}
};

const filterByMedal = (medal) => {
	if (filter.value === medal) {
		filter.value = null;
	} else {
		filter.value = medal;
	}

	router.push({ query: { filter: filter.value } });

	fetch();
};

const init = async () => {
	await fetch();

	if (total.value === 0) {
		busy.value = true;

		const { data: response } = await api.game.awardIdeas(route.params.user, {
			page: 0,
			size: 10,
		});

		bestIdeas.value = true;
		ideas.value = response.data;
		total.value = response.meta.total;
		busy.value = false;
	}
};

init();

useInfiniteScroll(
	document,
	() => {
		if (busy.value || !hasMore.value) return;

		page.value += 1;

		fetch(page.value);
	},
	{ distance: 500 }
);

defineOptions({
	inheritAttrs: false,
});
</script>

<template>
	<UseMedalMeta :handle="route.params.user" #="{ metas }" >
		<HeaderLinks class="mb-4">
			<div>
				<p class="italic text-dust mb-2">Select the medal type to filter</p>
				<ul class="list-none flex gap-1 p-0 m-0 flex-wrap">
					<li
						v-for="(medalInfo, medalName) of medals"
						:key="medalName"
						:class="{ disabled: !metas[medalName] }"
					>
						<button aria-label="button"
							v-tooltip="medalInfo.label"
							:class="{ 'bg-dust-200': filter === medalName }"
							class="flex items-center space-x-1 px-4 py-2 bg-dust-100 min-w-25 min-h-14 justify-center hover:bg-dust-200 rounded"
							type="button"
							@click="filterByMedal(medalName)"
						>
							<img
								v-if="medalName !== MEDAL.BLUE"
								class="w-8 h-8"
								:src="`/img/medals/${medalInfo.image}.svg`"
								alt="medal"
							/>
							<Medal
								v-else
								position=""
								class="w-7"
								star
							/>
							<span class="text-xl leading-none font-bold text-dust-600">
								{{ metas[medalName] || 0 }}
							</span>
						</button>
					</li>
				</ul>
			</div>
		</HeaderLinks>

		<div class="border border-dust-300 rounded-md sheet shadow mb-6">
			<div class="px-3 py-2 border-b bg-dust-200 flex justify-between flex-wrap gap-4">
				<h3 class="m-0 flex items-center text-lg space-x-2 whitespace-nowrap">
					<NIcon as="lightbulb-o1" />
					<span>{{ bestIdeas ? $t('page.home.medals.best-ideas') : $t('page.home.medals.winning-ideas') }}</span>
					<span v-if="filter">- {{ medals[filter].label }} ({{ metas[filter] || 0 }})</span>
					<span v-else-if="total">({{ total }})</span>
				</h3>

				<!-- <NSwitch v-model="sortByMedal" label="Sort by medal type" class="text-dust-700" inactive-color="dust-400" icon /> -->
				<NSwitch
					v-model="showChallenge"
					label="Show Challenge"
					class="text-dust-700"
					inactive-color="dust-400"
					icon
				/>
			</div>
			<div ref="el" class="bg-white p-2">
				<div
					v-for="(group, i) of groupedIdeas"
					:key="i"
					:class="{ 'border mt-4': showChallenge }"
				>
					<ChallengeCard
						v-if="showChallenge && group[0].challenge"
						:challenge="group[0].challenge"
						class="border !mb-2 bg-gray-50"
						#detail
					>
						<div
							v-if="group[0].challenge"
							class="flex gap-4 items-center justify-between py-2"
						>
							<div class="flex items-center gap-2">
								<template v-for="idea of group">
									<Medal
										v-if="!idea.medal_type || idea.medal_type === MEDAL.BLUE"
										:medals="{}"
										:position="idea.position || '?'"
										:star="!!idea.medal_type"
										:class="{ 'w-7': !!idea.medal_type }"
									/>
									<img
										v-else-if="medals[idea.medal_type]"
										class="w-8 h-8"
										:src="`/img/medals/${medals[idea.medal_type].image}.svg`"
										alt="medal"
									/>
								</template>
							</div>
							<div class="flex flex-col sm:flex-row items-center gap-4 mt-3">
								<div class="italic text-dust-500">Challenge close date: {{ date(group[0].challenge.end_time) }}</div>
								<RouterLink
									:to="({ name: 'challenges.show', params: { challenge: group[0].challenge.slug } })"
									target="_blank"
									class="px-4 py-1 bg-dust-100 text-dust hover:!text-dust-800 rounded leading-6"
								>
									Open Challenge
									<NIcon
										as="external-link"
										class="text-xs m-1"
									/>
								</RouterLink>
							</div>
						</div>
					</ChallengeCard>

					<div
						v-for="(idea, i) of group"
						:key="`line-${i}`"
						class="mb-2"
						:class="{ border: !showChallenge }"
					>
						<div class="flex p-2">
							<div class="p-2 flex-none">
								<Medal
									v-if="!idea.medal_type || idea.medal_type === MEDAL.BLUE || filter === MEDAL.BLUE"
									:medals="{}"
									:position="idea.position || '?'"
									:star="!!idea.medal_type"
									class="w-7"
								/>
								<img
									v-else-if="medals[idea.medal_type]"
									class="w-8 h-8"
									:src="`/img/medals/${medals[idea.medal_type].image}.svg`"
									alt="medal"
								/>
							</div>
							<div class="flex flex-col sm:flex-row gap-4 flex-1">
								<div class="m-2 flex flex-col justify-center flex-1 leading-tight">
									<p class="font-semibold">{{ idea.title }}</p>
									<NMarkdown
										v-if="idea.description"
										:code="idea.description"
									/>

									<div
										v-if="idea.image"
										class="mb-4"
									>
										<a
											href="#"
											@click.prevent="$refs.modalImage.open(idea)"
										>
											<img
												class="idea-table-meme-image bg-dust-100"
												:src="idea.image"
												alt=""
											/>
										</a>
									</div>

									<div class="italic text-dust-500 text-sm">Published on: {{ date(idea.published_at) }}</div>
								</div>

								<div
									v-if="!showChallenge && idea.challenge"
									class="flex flex-col gap-4 items-center p-4"
								>
									<div class="italic text-dust-500 text-sm">Challenge close date: {{ date(idea.challenge.end_time) }}</div>
									<RouterLink
										:to="{ name: 'challenges.show', params: { challenge: idea.challenge.slug } }"
										target="_blank"
										class="px-4 py-1 bg-dust-100 text-dust hover:!text-dust-800 rounded leading-6"
									>
										Open Challenge
										<NIcon
											as="external-link"
											class="text-xs m-1"
										/>
									</RouterLink>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="!ideas.length && !busy"
					class="text-center py-20 text-dust-600"
				>
					<span>{{ $t('common.no-result', 2) }}</span>
				</div>
				<NLoading
					v-if="busy"
					loading
					class="py-32 flex justify-center w-full"
				>
					Loading ideas...
				</NLoading>

				<NModal
					ref="modalImage"
					#="{ image, title }"
				>
					<img
						class="img-responsive !max-w-[90vw] !max-h-[80vh]"
						:src="image"
						:alt="title"
					/>
				</NModal>
			</div>
		</div>
	</UseMedalMeta>
</template>
