<script>
    import RatingMeter from '@/components/idea/RatingMeter.vue';
    import RatingStandardDeviation from '@/components/idea/RatingStandardDeviation.vue';
    import { usePlatform } from '@/mixins/ShowsPlatform';
    import { standardDeviation } from '@/utils/Helpers';

    export default {
        name: 'RatingBar',

        mixins: [usePlatform()],

        components: {
            RatingMeter,
            RatingStandardDeviation,
        },

        props: {
            flow: Object,
            rating: Object,
            status: Object,
        },

        data() {
            return {
                lockedIdeas: this.flow.ideas.map(idea => ({
                    ...idea, removed: idea.unlocked || idea.ratings === idea.ratings_needed
                })),
                innevatorDashboardRoute: {
                    name: 'dashboard.innovator',
                    params: {
                        user: this.$auth.authenticated
                            ? this.$auth.user.screen_name
                            : '_me_' // fallback
                    },
                },
                standardDeviation: false,
            };
        },

        watch: {
            'flow.ideas': {
                handler (ideas) {
                    let done = false;

                    this.lockedIdeas.forEach(idea => {
                        const target = ideas.find(i => i.id === idea.id);

                        if (!idea.removed && !done) {
                            // update stats
                            idea.ratings = idea.ratings + 1;
                            idea.unlocked = idea.ratings === idea.ratings_needed;

                            // remove idea
                            setTimeout(() => {
                                idea.removed = idea.unlocked;
                                this.$forceUpdate();
                            }, 2000);

                            done = true;
                        }
                    });
                },
                immediate: false,
            },
            rating ({ rating_user, rating_community }) {
                setTimeout(() => {
                    if (!rating_user || !rating_community) {
                        return;
                    }

                    const std = standardDeviation([
                        rating_community - .8,
                        rating_user,
                        rating_community + .8,
                    ])

                    this.standardDeviation = std < 1;
                }, 1000);
            }
        },

        computed: {
            computedIdeas() {
                return this.lockedIdeas.filter(idea => ! idea.removed);
            },
            insufficientRating () {
                // @TODO add logic here
                return false;
            },
            raterOnly() {
                return !this.flow.ideas.length;
            },
        },
    }
</script>

<template>
    <div class="mb-5">
        <div class="rating-bar">

            <!-- <div class="flex flex-wrap sm:flex-nowrap rating-bar__meter pr-4">
                <div class="rating-bar__meter-col">
                    <rating-meter :rating="rating" :hide-avg="insufficientRating"></rating-meter>
                    <span class="rating-bar__meter-title">
                        {{ rating.rating_user ? 'Your previous rating' : 'Start Rating!' }}
                    </span>
                    <RatingStandardDeviation :open="standardDeviation" @closed="standardDeviation = false" />
                </div>
                <span class="rating-bar__meter-help-text ml-6">
                    {{ insufficientRating ? 'Insufficient ratings to show crowd average' : 'As you rate, compare yourself to the crowd’s ratings.' }}
                </span>
            </div> -->

            <div class="rating-bar__body">
                <template v-if="raterOnly">
                    <div class="flex flex-col items-baseline sm:flex-row justify-between mt-2">
                        <h2 class="rating-bar__title m-0">
                            Rating helps promote the best ideas in this Challenge!
                        </h2>

                        <h3 class="rating-bar__title font-weight-normal m-0">
                            You rated <b>{{ status.ratingIdeas }}</b> of {{ status.ratableIdeas }} ideas
                        </h3>
                    </div>
                    <p class="rating-bar__description">Hone your judgement skills by analyzing &amp; rating other people’s ideas by how well they align with the Catalyst’s intent. As an <b>Analyst</b> you’ll earn Neurons for rating ideas fairly. Are you a good judge of creativity?</p>
                </template>
                <template v-else-if="computedIdeas.length > 0">
                    <div class="flex flex-row items-center justify-between mt-2">
                        <h2 class="rating-bar__title m-0">
                            {{ computedIdeas.length > 1 ? 'Keep rating to unlock your ideas!' : 'Keep rating to unlock your idea!' }}
                        </h2>

                        <h3 class="is-color-white font-weight-normal m-0">
                            <i>Rate 3 other ideas to unlock each of yours.</i>
                        </h3>

                        <h3 class="rating-bar__title font-weight-normal m-0">
                            You rated <b>{{ status.ratingIdeas }}</b> of {{ status.ratableIdeas }} ideas
                        </h3>
                    </div>
                    <div class="w-full">
                        <transition-group name="item-remove" tag="div" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-4">
                            <div
                                v-for="idea in computedIdeas"
                                :key="idea.id"
                                >
                                <div class="rating-lock-block" :class="{ 'is-open': idea.unlocked, 'is-active': idea.ratings > 0 }">
                                    <div class="rating-lock-block__icon">
                                        <span
                                            aria-hidden="true"
                                            class="icon"
                                            :class="{ 'icon-lock-solid': ! idea.unlocked, 'icon-unlock-solid shake curt': idea.unlocked }"
                                        ></span>
                                    </div>
                                    <div class="rating-lock-block__body">
                                        <p class="mb-0">{{ idea.title }}</p>
                                        <div v-if="idea.ratings_needed > 0" class="rating-lock-block__bulbs">
                                            <span
                                                v-for="n in idea.ratings_needed"
                                                aria-hidden="true"
                                                class="rating-lock-block__bulb icon-lightbulb "
                                                :class="{ 'is-active': n <= idea.ratings }"
                                                :key="n"
                                            ></span>
                                            <span class="rating-lock-block__bulb-text">You rated these ideas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                </template>
                <div v-else class="mt-2">
                    <h2 class="font-weight-700 is-color-white text-xl">
                        Congrats, your {{ status.unlockedIdeas > 1 ? status.unlockedIdeas : '' }} {{ status.unlockedIdeas > 1 ? 'ideas are' : 'idea is' }} now unlocked and published!
                        <NIcon v-for="(idea, i) of flow.ideas" :key="i" v-tooltip="idea.title" as="lightbulb" class="is-color-green" right />
                    </h2>
                    <template v-if="status.ratableIdeas > 0">
                        <h3 class="font-weight-normal font-size-1-2-rem is-color-white">
                            <i>Your Game Dash will track your progress, as will the
                            <router-link
                                :to="{
                                    name: 'platform.olympiad.leaderboard',
                                    params: {
                                        platformSlug: isUserPlatform
                                            ? (account.user.screen_name || 'platform')
                                            : 'platform'
                                    }
                                }"
                                class="is-color-white"
                            ><u>{{ $platform.current.title }} Leaderboards</u>
                            </router-link>
                            </i>
                        </h3>
                        <h2 v-if="status.availableToRate > 0" class="rating-bar__title font-weight-normal text-xl">
                            {{ status.availableToRate }} other solutions need ratings. Rate more ideas in this challenge — earn neurons & give feedback!
                        </h2>
                    </template>
                    <template v-else>
                        <h3 class="font-weight-normal font-size-1-2-rem is-color-white">
                            <i>See your ideas and how they’re ranked in your <u><router-link :to="innevatorDashboardRoute" class="is-color-white">Innovator Dashboard</router-link></u></i>
                        </h3>
                        <h2 class="rating-bar__title font-weight-normal text-xl">
                            <b>Check back soon</b> and <b>rate other ideas</b> in this challenge, and you’ll earn more neurons.
                        </h2>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
