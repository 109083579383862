<script>
import Step from './Step.vue';
import { ref, computed, provide, watch } from 'vue';

export default {
    name: 'Flow',

    components: {
        Step,
    },

    emits: ['exit', 'done'],

    props: {
        steps: {
            type: Array,
            default: () => [],
        },
        immediate: {
            type: Boolean,
            default: false,
        },
        skippable: {
            type: Boolean,
            default: false,
        },
        delay: {
            type: Number,
            default: 0,
        },
        textDone: {
            type: String,
            default: 'Done',
        },
    },

    setup (props, { emit }) {
        const index = ref(-1);
        const steps = computed(() => props.steps.map((step, i) => ({ ...step, index: i })));
        const setIndex = (target) => {
            if (typeof target === 'function') {
                index.value = target(index.value);
                
                return;
            }

            index.value = target;
        }
        const step = computed(() => props.steps[index.value] || null);
        const done = computed(() => index.value === props.steps.length < 0);
        const isFirst = computed(() => index.value === 0);
        const isLast = computed(() => index.value === props.steps.length - 1);
        const toPreviousStep = () => setIndex(current => current - 1);
        const start = () => setIndex(0);
        const exit = () => {
            if (isLast.value) {
                emit('done')
            }

            setIndex(-1)

            emit('exit');
        };
        const toNextStep = () => {
            if (index.value < props.steps.length - 1) {
                setIndex(current => current + 1);
            } else {
                exit();
            }
        }

        const api = {
            index,
            steps,
            step,
            done,
            start,
            exit,
            isFirst,
            isLast,
            toNextStep,
            toNextStep,
            toPreviousStep,
        }
        
        provide('api', api);

        watch(() => props.steps, () => {
            exit();
            
            if (props.immediate) {
                setTimeout(start, props.delay);
            }
        }, { immediate: props.immediate });

        return {
            done,
            step,
            toPreviousStep,
            toNextStep,
            start,
            exit,
            index,
        };
    }
}
</script>

<template>
    <div v-if="!done">
        <slot :index="index">
            <Step v-if="step" :key="step.attachTo.element" :skippable="skippable" :text-done="textDone" />
        </slot>
    </div>
</template>