<script setup>
import { api } from '@/modules/services';

import { computed, watch, ref } from 'vue';
import { useAuth } from '@/composable/auth';
import { useHead } from '@vueuse/head';
import { useNotyf } from '@/composable/notyf';

import FeatureFlag from "@/components/FeatureFlag.vue";
import LocationSearch from '@/components/form/LocationSearch.vue';
import LocationSuggest from '@/components/form/LocationSuggest.vue';
import NBlockEditor from '@/components/tools/article/index.vue';

const { auth } = useAuth();
const notyf = useNotyf();
const loading = ref(true);
const previewing = ref(false);
const profile = computed(() => {
    return auth.profile;
});
const bio = ref({
    title: 'Bio',
    image_cover: null,
    blocks: [
        { order: 1, block_type: 'text', content: null },
    ],
});
const bioLink =  computed(() => {
    return `${location.origin}/${auth.user.screen_name}/about`;
})

useHead({
    title: 'Your Bio & Profile',
});

const update = (profile) => {
    auth.profile = profile;
}

const updateLocation = (location) => {
    if (location) {
        const { formatted, point, city, state, country } = location;
        const [lat, lon] = point.coordinates;

        profile.value.bio.geographical_location = formatted;
        profile.value.bio.location = {
            country,
            state,
            city,
            lat,
            lon,
        }

        return;
    }

    profile.value.bio.geographical_location = '';
    profile.value.bio.location = {};
}

const saveBio = async (payload) => {
    try {
        const { data: response } = await api.content.save(payload);

        bio.value = { ...bio.value, ...response };
    } catch (e) {
        console.log(e)

        notyf.error('There was an error updating your bio, please try again.');
    }
}

const fetchBio = async () => {
    if (!auth.user.screen_name) return;

    try {
        loading.value = true;
        const { data: response } = await api.content.find('bio', auth.user.screen_name);

        if (response.data.id) {
            bio.value = response.data;
        }
    } catch (e) {
        console.log(e)
    } finally {
        loading.value = false;
    }
}

watch(() => auth.user.screen_name, () => fetchBio());

fetchBio();
</script>

<script>

export default {
    name: 'MyAccountBio',
}
</script>

<template>
    <article class="page">
        <NPageHeader
            title="Your Bio &amp; Profile"
            subtitle="Customize your bio and profile"
            icon="user-avatar-regular"
        />
        <div class="page__content mb-5">
            <NDataForm action="/me/profiles/bio" :data="profile.bio" @success="update">
                <template #default="form">
                    <div class="form-groups">
                        <div class="form-group">
                            <label for="university_place" class="form-group__label">Your College/University <span class="form-inline-help-text">Optional</span></label>
                            <p class="form-block-help-text">
                                If you're a student, what's your school?  This will help you find other students in the <strong>University Olympiads</strong> (coming soon).
                            </p>

                            <LocationSuggest
                                v-model="form.fields.college"
                                name="university_place"
                                id="university_place"
                                placeholder="Your College or University"
                                class="w-full"
                                prepend-icon="university text-dust-700"
                                entity-types="business"
                            />
                        </div>
                        <div class="form-group">
                            <label for="work_place" class="form-group__label">
                                Your work place <span class="form-inline-help-text">Optional</span>
                            </label>
                            <p class="form-block-help-text">
                                Where are you working? You can find other coworkers playing in the idea tournaments.
                            </p>
                            <div class="form-group__helper" id="bio">
                                <NInput v-model="form.fields.work_place" :min="4" :max="45" placeholder="Your work place" name="work_place" id="work_place"></NInput>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="living_place" class="form-group__label">Your geographical location <span class="form-inline-help-text">Optional</span></label>
                            <p class="form-block-help-text">
                                Where are you living now? You can discover other players in your area and also be found on the <a href="/nerve-boards">Nerve Boards</a>.
                            </p>

                            <LocationSearch
                                :modelValue="form.fields.geographical_location"
                                name="geographical_location"
                                prepend-icon="map-marker text-dust-700"
                                id="geographical_location"
                                placeholder="Start typing your city, state or country"
                                class="w-full"
                                return-object
                                @update:model-value="updateLocation"
                            />
                        </div>
                        <div class="form-group">
                            <label class="form-group__label" for="interests">
                                Your Interests
                                <span class="form-inline-help-text">Optional</span>
                            </label>
                            <p class="form-block-help-text">
                                Fill in some of your interests to help tailor your game suggestions, and to show the world what you care about. The first 2 appear in your Catalyst bio on any challenges you create or ideas you enter. <em>Press return after each tag.</em>
                            </p>
                            <NTags name="interests" v-model="form.fields.interests" :min="4" :max="45" id="interests" />
                            <p>
                                <em>Type your interest "categories" and hashtags; separate them by commas (or press return after each word if you're using a keyboard).</em>
                            </p>
                        </div>
                    </div>
                    <div>
                        <NAlert type="danger" closable :open="form.errors.any()">
                            <span>{{ form.errors.get('*') || 'One of the form fields was invalid, please check your changes again' }}</span>
                        </NAlert>
                        <NAlert type="success" closable :open="form.success">
                            <span>Your personal bio has been updated!</span>
                        </NAlert>
                        <button aria-label="button" type="submit" class="btn is-primary min-w-[8rem] justify-center" :disabled="form.busy">
                            {{ form.busy ? 'Saving...' : 'Save' }}
                        </button>
                    </div>
                </template>
            </NDataForm>
        </div>

        <FeatureFlag flag="user-bio">
            <div class="page__content" id="bio">
                <div class="flex items-center justify-between py-2">
                    <label class="font-bold text-md mb-2 inline-block leading-5">Your Bio (About me)</label>

                    <div class="flex items-center">
                        <button aria-label="button" type="button" class="flex items-center gap-2 px-4 py-1 rounded-md hover:bg-dust-200" @click="previewing = !previewing">
                            <NIcon :as="previewing ? 'pencil-alt-solid' : 'eye'" class="-mt-1" /> {{ previewing ? 'Edit' : 'Preview' }}
                        </button>
                    </div>
                </div>
                <div v-if="loading" class="bg-dust-200 h-[200px] animate-loading rounded-md w-auto"></div>
                <NDataForm v-else :action="saveBio" :data="bio" #="form">
                    <NBlockEditor  v-model="bio" class="mb-3" :previewing="previewing" />

                    <NAlert type="danger" closable :open="form.errors.any()">
                        {{
                            form.errors.get("*") ||
                                "There was an error updating your bio, please try again"
                        }}
                    </NAlert>

                    <NAlert type="success" closable :open="form.success">
                        <span>
                            Your bio has been saved successfully!
                        </span> 
                        <!-- // give quick link  to public view so they can proof their edits -->
                        <a :href="bioLink" target="_blank" class="underline hover:underline !text-[inherit] ml-1">Open public view</a>
                    </NAlert>
                    
                    <button aria-label="button" type="submit" class="btn is-primary min-w-[8rem] justify-center" :disabled="form.busy">
                        {{ form.busy ? 'Saving...' : 'Save' }}
                    </button>
                </NDataForm>
            </div>
        </FeatureFlag>
    </article>
</template>
