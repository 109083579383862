<script>
import Validatable from '@/mixins/Validatable';

export default {
    name: 'NTags',

    mixins: [Validatable],

    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        outlined: {
            type: Boolean,
            default: true
        },
        backgroundColor: {
            type: String,
            default: 'bg-white'
        },
        max: {
            type: Number,
            default: -1,
        },
    },
    
    emits: ['update:modelValue', 'added', 'change'],

    data() {
        return {
            tag: '',
        };
    },

    computed: {
        tags: {
            get () {
                return this.modelValue;
            },
            set (v) {
                this.$emit('update:modelValue', v);
                this.$emit('change', v);
            }
        }
    },

    methods: {
        add() {
            if (this.max > 0 && this.tags.length >= this.max) {
                return;
            }

            let tag = this.tag.replace(',', '').replace(';','').trim();

            if (tag && ! this.tags.includes(tag)) {
                this.tags = [...this.tags, tag.trim()];
                this.tag = '';

                this.$nextTick(() => {
                    this.$emit('added', tag);
                })
            }
        },
        remove(target) {
            this.tags = this.tags.filter(tag => tag !== target);

            this.$refs.input.focus();
        },
        removeLast() {
            if (! this.tag) {
                this.tags = this.tags.slice(0, -1);
            }
        },
        reset() {
            this.tag = '';
            this.tags = this.modelValue;
        },
        resolveTag(e) {
            if (!this.tag) {
                return;
            }

            switch (e.key) {
                case 'Enter':
                case 'Tab':
                case ',':
                case ';':
                    e.preventDefault();
                    this.add();
                    break;
            }
        },
    },
}
</script>

<template>
    <label :for="name" class="form-group__helper">
        <span v-if="label" class="form-group__label" style="display: block;">{{ label }}</span>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <div class="form-group__tags relative border border-dust-300 rounded-md" :class="{ 'bg-dust-100': disabled, 'border': outlined, [backgroundColor]: true }">
            <template v-if="tags.length > 0" class="inline-flex items-center">
                <span v-for="(tag, index) in tags" :key="index" class="form-group__tag bg-white my-0.2 mx-1">
                    <span class="ml-1">{{ tag }}</span>
                    <NIcon as="times-circle-regular" v-if="!disabled" @click.prevent="remove(tag)" />
                </span>
            </template>
            <input
                v-bind="attrs"
                v-model.trim="tag"
                :name="name"
                :id="name"
                ref="input"
                type="text"
                class="min-h-[2.025rem] ml-1 flex-grow outline-none"
                :class="{ 'is-error': invalid, 'bg-dust-100': disabled, [backgroundColor]: true }"
                :disabled="form.busy || disabled"
                @keydown.backspace="removeLast"
                @keydown="resolveTag"
                @blur="add"
            >

            <small v-if="max > 0" class="text-dust-400 absolute bottom-1 right-2">{{ tags.length }}/{{ max }}</small>
        </div>
        <span v-if="invalid" class="form-group__error">{{ error }}</span>
    </label>
</template>
