<script setup>
import PrivateChat from '@/components/chat/Chat.vue';
import NModal from '@/components/NModal.vue';
import NIcon from '@/components/NIcon.vue';
import { useDiscussionStats } from '@/composable/discussion';
import { computed } from 'vue'

const props = defineProps({
	context: {
		type: String,
		required: true,
	},
	id: {
		type: String,
	},
});

const { update, total, unread, replied, mine, participating, hasMessages, loading } = useDiscussionStats({
	context: props.context,
	id: computed(() => props.id),
	immediate: !!props.id
});
</script>

<template>
	<NModal @close="update">
		<template #activator="{ on, open }">
			<slot
				name="activator"
				:on="on"
				:open="open"
				:total="total"
				:unread="unread"
				:replied="replied"
				:mine="mine"
				:participating="participating"
				:hasMessages="hasMessages"
				:loading="loading"
			/>
		</template>

		<template #default="{ visible, close }">
			<div class="overflow-hidden rounded-md flex flex-col w-full max-w-[95dvw] sm:w-[40rem] bg-dust-100">
				<div class="flex justify-between items-center px-3 py-2 bg-secondary text-white">
					<div class="flex items-center">
						<NIcon as="comments-solid" class="text-xl mb-1 mr-2" />
						<h3 class="m-0">{{ $t('component.private-chat.title') }}</h3>
					</div>

					<button aria-label="button" type="button" @click="close">
						<NIcon as="times-circle-regular" />
					</button>
				</div>

				<slot name="body" :visible="visible" :close="close">
					<PrivateChat :polling="visible" :context="context" :id="id" class="h-[75dvh] " />
				</slot>
			</div>
		</template>
	</NModal>
</template>
