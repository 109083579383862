<script setup>
import { getCurrentInstance } from 'vue';

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    },
    handler: {
        type: Function,
        required: false
    }
});

const instance = getCurrentInstance();

const nextPage = () => {
    if (props.handler) {
        props.handler();

        return
    }

    instance.parent.proxy.nextPage();
}
</script>

<template>
    <div class="flex justify-center mt-3">
        <button aria-label="button" @click.prevent="nextPage" :disabled="loading" type="button"
            class="bg-dust text-white px-3 py-1 leading-1 rounded inline-flex items-center font-bold hover:bg-dust-600">
            {{ loading ? 'Loading...' : `Load more` }}
            <NIcon as="caret-down-simple" class="ml-2" />
        </button>
    </div>
</template>

