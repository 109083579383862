<script>
import kebabCase from 'lodash/kebabCase';

export default {
    name: 'NFilterItem',

    props: {
        label: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        icon: {
            type: String,
            required: false
        },
        to: {
            type: [String, Object],
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
        },
    },

    emits: ['open', 'close'],

    data() {
        return {
            isTab: true
        };
    },

    watch: {
        isOpen(opened) {
            opened ? this.$emit("open") : this.$emit("close");
        }
    },

    computed: {
        computedId () {
            return this.id || kebabCase(this.label);
        },
        isOpen() {
            return this.$parent.active === this.computedId;
        }
    },

    created () {
        this.$parent.tabs.push(this);
    },
};
</script>

<template>
    <div class="filter" v-show="isOpen">
        <slot></slot>
    </div>
</template>
