<script setup>
import { useInvitation } from '@/queries/invitation';
import { provide } from 'vue';

const props = defineProps({
    platformSlug: String,
    recipientId: String,
    sourceType: String,
    sourceId: String,
})

const { data } = useInvitation(
    props.platformSlug,
    props.sourceType,
    props.sourceId,
    props.recipientId,
)

provide('invitation', data)
</script>

<template>
    <slot :invitation="data" />
</template>