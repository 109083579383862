/** @module comments */
export default requester => {
    return {
        /**
         * Retrieves an array of comments.
         *
         * @param {string} commentType The type of comment to retrieve (one of: 'contest’, 'solution’, 'solution-iteration’, 'solution-explanation’, ‘idea-performance’)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Any additional parameters. (One or multiple of: commentTypeId, challengeId, date, pinned, commenterId)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        all(commentType, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/comments', Object.assign({ commentType, start, num }, params), headers
            );
        },

        /**
         * Appends complete user information to a supplied array of comments.
         *
         * @param {Array} commentsArray A json body containing an array of comment objects (the objects for which to add complete user information). Note that while the swagger annotation only says to supply a comment, this should actually be an array of comments.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        appendWithUsers(commentsArray, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/comments-with-users', { commentsArray }, params, headers);
        },

        /**
         * Creates a comment.
         *
         * @param {Object} comment The comment to create
         * @param {Object} params Any additional parameters. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(comment, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/comments', { comment }, params, headers);
        },

        /**
         * Gets a comment by its id.
         *
         * @param {(string|number)} id ID of comment to get
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        get(id, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/comments/${id}`, {}, params, headers);
        },

        /**
         * Deletes a comment by its id.
         *
         * @param {(string|number)} id ID of comment to delete
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        delete(id, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/comments/${id}`, {}, params, headers);
        },

        /**
         * Updates a comment.
         *
         * @param {(string|number)} id ID of comment to update
         * @param {Object} comment Comment object to overwrite the comment with
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        update(id, comment, params = {}, headers = {}) {
            return requester.makeRequest('PUT', `/comments/${id}`, { comment }, params, headers);
        },

        /**
         * Provides a rudimentary healthcheck system for the comment API
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        health(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/comments/health', {}, params, headers);
        }
    }
}
