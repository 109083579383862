export default requester => {
    return {
        /**
         * Retrives the olympiads.
         *
         * @param {string} platformSlug
         */
        all (platformSlug) {
            return requester.get(`/olympiad/${platformSlug}/olympiads`);
        },

        /**
         * Retrive the olympiad.
         *
         * @param {string} olympiadId
         */
        getById (olympiadId) {
            return requester.get(`/olympiad/olympiads/${olympiadId}`);
        },

        /**
         * Retrive results races from olympiad.
         *
         * @param {string} olympiadId
         * @param {string} raceId
         */
        raceResults (olympiadId, raceId, params = {}) {
            return requester.get(`/olympiad/olympiads/${olympiadId}/races/${raceId}/result`, { params });
        },

        /**
         * Retrive results bound from olympiad.
         *
         * @param {string} olympiadId
         * @param {string} raceType
         */
        raceResultsBound (olympiadId, raceType, params = {}) {
            return requester.get(`/olympiad/olympiads/${olympiadId}/results/${raceType}/bound`, { params });
        },

        /**
         * Retrive races from olympiad
         *
         * @param {string} olympiadId
         * @param {object} params
         */
        races (olympiadID, params = {}) {
            return requester.get(`/olympiad/olympiads/${olympiadID}/races`, { params });
        },

        /**
         * Retrives the base olympiads.
         *
         * @param {string} platformSlug
         * @param {object} params
         */
        bases (platform, params = {}) {
            return requester.get(`/olympiad/${platform}/base-olympiads`, { params });
        },

        /**
         * Retrives the base olympiad.
         *
         * @param {string} platformSlug
         * @param {string} olympiadId
         * @param {object} params
         */
        base (platformSlug, olympiadId, params = {}) {
            return requester.get(`/olympiad/${platformSlug}/base-olympiads/${olympiadId}`, { params });
        },

        /**
         * Updates the base olympiad.
         *
         * @param {string} platformSlug
         * @param {string} olympiadId
         * @param {object} olympiad
         */
        updateBase (platformSlug, olympiadId, olympiad) {
            return requester.put(`/olympiad/${platformSlug}/base-olympiads/${olympiadId}`, olympiad);
        },

        /**
         * Get payment data.
         *
         * @param {string} olympiadId
         * @param {object} params
         * @return {object}
         */
        payment (olympiadId, params = {}) {
            return requester.get(`/admin/payments/olympiads/${olympiadId}`, { params });
        },

        /**
         * Pay participant.
         *
         * @param {string} payload
         */
        pay(payload) {
            return requester.post('/admin/payout/olympiad', payload);
        },

        /**
         * Query olympiads.
         *
         * @param {string} platformSlug
         * @param {object} params
         */
        query (params = {}) {
            return requester.get('/olympiad/query', { params });
        },

        /**
         * Get nerve boards.
         *
         * @param {string} categorySlug
         * @param {object} params
         */
         nerveBoards (categorySlug, params = {}, config = {}) {
            return requester.get(`/nerveboard/category/${categorySlug}/result`, { params, ...config });
        },

        /**
         * Calculate prizes
         * 
         * @param {string} olympiadId 
         * @returns 
         */
        calculatePrizes (olympiadId) {
            return requester.post(`/admin/olympiads/${olympiadId}/result-prize-calcualtion`);
        }
    }
}
