/** @module users */
export default requester => {
    return {
        /**
         * Gets the average time (in seconds) that the given user takes to respond to the mentor comments that they retrieve.
         * Note that this only counts the time difference between ratings they provide and the mentor comments that they retrieve.
         * It does not account for cases where no rating is provided.
         * In other words, it does not penalize people who have many mentor comments to respond to,
         * so long as those people respond to some mentor comments in a timely fashion.
         *
         * @param {string} userId ID of the user whose average mentor rating response time is being retrieved
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        averageMentorRatingResponseTime(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/avg-mentor-rating-response-time`, {}, params, headers);
        },

        /**
         * Gets all users who are subscribed to the given user’s idea olympiad.
         *
         * @param {string} userId The id of the user for which to retrieve idea race subscribers
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        ideaRaceSubscribers(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/idea-race-subscribers`, {}, params, headers);
        },

        /**
         * Gets all the solutions that the user has been asked to iterate upon, and has not yet responded to.
         *
         * @param {string} userId The user for whom to get solutions
         * @param {string} orderBy The ordering of the returned solutions (currently only created_at, but will eventually support popularity and money)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        iterationRequests(userId, orderBy, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET',
                `/users/${userId}/iterations/request`,
                Object.assign({ orderBy, start, num }, params),
                headers
            );
        },

        /**
         * Subscribes to a user and automatically renerves any of their new challenges.
         *
         * @param {string} userId The ID of the user to subscribe to
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        subscribeToUserOlympiad(userId, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/users/${userId}/subscribe-olympiad`, {}, params, headers);
        },

        /**
         * Unsubscribes from a user’s olympiad and stops renerving new challenges.
         *
         * @param {string} userId The ID of the user to unsubscribe from
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        unSubscribeFromUserOlympiad(userId, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/users/${userId}/unsubscribe-olympiad`, {}, params, headers);
        },

        /**
         * Returns the user’s various brain levels.
         *
         * @param {string} userId The ID of the user to get points for
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        points(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/points/${userId}`, params, headers);
        },

        /**
         * Returns the user’s stats.
         *
         * @param {string} userId The ID of the user to get stats for
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        stats(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/stats`, params, headers);
        },

        /**
         * Finalizes the sign-up and registration process and creates a user.
         *
         * @param {Object} data The user data
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        signup(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/account/signup', data, params, headers);
        },

        /**
         * Resend the user’s verification email.
         *
         * @param {Object} data The POST data
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        resend(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/account/resend', data, params, headers);
        },

        /**
         * Verifies a user’s signup credentials.
         *
         * @param {Object} data The POST data
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        verify(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/account/verify', data, params, headers);
        },

        /**
         * Gets a user’s admin roles by the user’s id.
         *
         * @param {string} userId The ID of the user
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        adminRoles(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/admin-roles/${userId}`, params, headers);
        },

        /**
         * Verifies a user’s auth credentials and returns an access token response.
         *
         * @param {Object} user A goth user object in the POST body (with valid email, access token, secret, etc.)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        verifyCredentials(user, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/generalsocial/verifyCredentials', { user }, params, headers);
        },

        /**
         * Retrieves an OAuth2 token for the user (used in the login workflow) and sets a cookie.
         *
         * @param {Object} data The POST data
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        generateOauth2Token(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/oauth2/token', data, params, headers);
        },

        /**
         * Resets a password.
         *
         * @param {Object} data The POST data
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        resetPassword(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/account/password-reset', data, params, headers);
        },

        /**
         * Initiates a password reset request and sends an email.
         *
         * @param {Object} data The POST data
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        requestPasswordReset(data, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/account/password-reset-request', data, params, headers);
        },

        /**
         * Queries for users based on query parameters.
         *
         * @param {string} orderBy The ordering of the returned users (currently only created_at)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        all(orderBy, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/users', Object.assign({ orderBy, start, num }, params), headers
            );
        },

        /**
          * Creates a user.
          *
          * @param {Object} user The user to create
          * @param {Object} params Any additional parameters. (One or multiple of: organizationIdForTemporarySession)
          * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(user, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/users', { user }, params, headers);
        },

        /**
         * Gets a user by its id.
         *
         * @param {string} userId ID of user to get
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        get(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}`, {}, params, headers);
        },

        /**
         * Updates a user.
         *
         * @param {string} id ID of user to update
         * @param {Object} user User object to overwrite the user with
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        update(id, user, params = {}, headers = {}) {
            return requester.makeRequest('PUT', `/users/${id}`, { user }, params, headers);
        },

        /**
         * Gets a user's contributions
         * Note: returns a user’s contributions in the specified challenge sorted by creation time (within each group).
         * A contribution is considered a solution, a comment (challenge comment or mentor comment),
         * or a rating (either a solution rating, a mentor rating, or a challenge rating) on that challenge.
         * If the challengeId parameter is not present then the response should also contain challenges created by this user.
         *
         * @param {string} userId ID of user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        contributions(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/contributions`, params, headers);
        },

        /**
         * Gets a user’s impact causes response see user api issue 40.
         *
         * @param {string} userId ID of user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        impactCauses(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/impact-causes`, params, headers);
        },

        /**
         * Gets a user’s impact globe.
         *
         * @param {string} userId ID of user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        impactGlobe(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/impact-globe`, params, headers);
        },

        /**
         * Gets a user’s impact organizations response see user api issue 41.
         *
         * @param {string} userId ID of user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        impactOrganizations(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/impact-organizations`, params, headers);
        },

        /**
         * Gets a user’s impact people response see user api issue 41.
         *
         * @param {string} userId ID of user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        impactPeople(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/impact-people`, params, headers);
        },

        /**
         * Gets all people scouted by this user.
         *
         * @param {string} userId ID of user
         * @param {Object} params Additional query arguments to append to the URL. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        scoutedPeople(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/scouted-people`, params, headers);
        },

        /**
         * Note: Only the user whose profile image is being updated can call this endpoint.
         * Uploads the given user image to s3 so that we can store our own version and generate a https link for the image.
         * Also updates the user's image to the new s3 url.
         *
         * @param {string} userId ID of the user to add an image to
         * @param {string} url URL of image to upload
         * @param {Object} formImageData The image data to upload if url is not present
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        uploadImage(userId, url, formImageData = null, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST', `/users/${userId}/upload-image`, { url, formImageData }, params, headers
            );
        },

        /**
         * Gets a list of organizations given a user id.
         *
         * @param {string} userId ID of the user
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        organizations(userId, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/organizations/joined-organizations', Object.assign({ userId }, params), headers
            );
        },

        /**
         * Gets a list of organizations given a user id.
         *
         * @param {string} userId ID of the user
         * @param {string} relationshipType One of: (friend, follow, block)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        relationships(userId, relationshipType, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET',
                '/relationships',
                Object.assign({ userId, relationshipType, start, num }, params),
                headers
            );
        },

        /**
         * Creates a relationship request toward a target.
         *
         * @param {string} targetId The recipient of the relationship request
         * @param {string} relationshipType One of: (friend, follow, block)
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        createRelationship(targetId, relationshipType, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST',
                '/relationships',
                {},
                Object.assign({ targetId, relationshipType }, params),
                headers
            );
        },

        /**
         * Gets a list of organizations given a user id.
         *
         * @param {string} userId ID of the user
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        relationshipWith(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/relationships/${userId}`, params, headers);
        },

        /**
         * Accepts a friend request, currently no use for update follow/block.
         *
         * @param {string} userId ID of the user
         * @param {string} relationshipType One of: (friend, follow, block)
         * @param {Object} params Any additional parameters.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        acceptRelationshipRequest(userId, relationshipType, params = {}, headers = {}) {
            return requester.makeRequest(
                'POST',
                `/relationships/${userId}`,
                {},
                Object.assign({ relationshipType }, params),
                headers
            );
        },

        /**
         * Gets a list of the authenticated user’s twitter friends and cross references it with a list of dailynerve users,
         * and then gets the interests (tags) of those users.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        twitterFriendInterests(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/twitter-friend-interests', params, headers);
        },

        /**
         * Gets a list of the authenticated user’s twitter friends and cross references it with a list of dailynerve users.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        twitterFriends(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/twitter-friends', params, headers);
        },

        /**
         * Retrieves the authenticated user’s linked social media platforms and their auth tokens (for use in automated sharing).
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        linkedSocialPlatforms(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/users/linked-social-platforms', params, headers);
        },

        /**
         * Retrieves the authenticated user’s linked social media platforms and their auth tokens (for use in automated sharing).
         *
         * @param {string} userId ID of the user
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        organizationInvites(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${userId}/organization-invites`, params, headers);
        },

        /**
         * Retrieves the authenticated user's user object.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        me(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/me', params, headers);
        },

        /**
         * Retrieves the authenticated user's details object.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        detailedMe(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/me/detailed', params, headers);
        },

        /**
         * Retrieves the authenticated user's details object.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        myChallenges(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/me/challenges', params, headers);
        },

        /**
         * Retrieves the user challenge.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        myChallenge(id, headers = {}) {
            return requester.makeRequest('GET', `/me/challenges/${id}`, null, headers);
        },

        /**
         * Retrieves the user's details object by its handle.
         *
         * @param {string} handle
         * @param {Object} params
         * @param {Object} headers
         */
        detailed(handle, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/${handle}/detailed`, params, headers);
        },

        /**
         * Return the avaliability of an handler.
         *
         * @param {string} handle
         * @param {Object} params
         * @param {Object} headers
         */
        handlers(params = {}, headers = {}) {
            return requester.makeRequest('GET', `/users/handlers`, params, headers);
        },

        /**
         * Retrieves the user's olympiad leaderboard.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        olympiadLeaderboard(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/game/olympiad-leaderboard/prizes', params, headers);
        },

        /**
         * Retrieves the user's olympiad overall leaderboard.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        olympiadOverallLeaderboard(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/game/olympiad-leaderboard/platform', params, headers);
        },

        /**
         * Retrieves the user's olympiad scout leaderboard.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        olympiadScoutLeaderboard(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/game/olympiad-leaderboard/scout', params, headers);
        },

        /**
         * Retrieves the user's details object by its handle.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        mentoring(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/me/mentor-comments', params, headers);
        },

        /**
         * Retrieves the user's details object by its handle.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        innovator(params = {}, headers = {}, config = {}) {
            return requester.makeRequest('GET', '/me/innovator', params, headers, {}, config);
        },

        /**
         * Remove social account link.
         *
         * @param {Object} params
         * @param {Object} headers
         */
        disconnectSocialAccount(provider, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', `/me/accounts/${provider}`, params, headers);
        }
    }
}
