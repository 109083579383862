<script>
import Validatable from '@/mixins/Validatable';

export default {
    mixins: [Validatable],

    props: {
        modelValue: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'medium'
        },
        startWith: {
            type: String
        },
        rows: {
            type: [Number, String],
            default: 3
        },
        maxlength: {
            type: [Number, String],
            default: 1000
        },
    },

    emits: ['update:modelValue', 'blur'],

    computed: {
        input () {
            return this.$refs.input;
        }
    },

    methods: {
        update({ target }) {
            this.clearErrors();

            if (this.startWith && target.textLength < this.startWith.length) {
                this.$emit('update:modelValue', this.startWith + ' ');
            } else {
                this.$emit('update:modelValue', target.value);
            }
        }
    }
}
</script>

<template>
    <div class="form-group__helper">
        <label v-if="label" :for="name" class="form-group__label" style="display: block;">{{ label }}</label>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <textarea
            v-bind="attrs"
            class="form-group__input is-textarea border border-dust-300 rounded-md"
            ref="input"
            :class="[`is-${size}`, { 'is-error': invalid }, { '!text-red-400 !border-red-500 !placeholder-red-300': invalid, 'bg-dust-100': disabled }]"
            :id="name"
            :name="name"
            :value="modelValue"
            :disabled="form.busy || disabled"
            :rows="rows"
            :maxlength="maxlength"
            @input="update"
            @keydown="form.errors.clear(name)"
            @blur="e => $emit('blur', e)"
        ></textarea>
        <span v-if="invalid && error" class="form-group__error">{{ error }}</span>
    </div>
</template>
