/** @module ratings */
export default requester => {
    return {
        /**
         * Retrieves a count of the number of ratings.
         *
         * @param {string} ratingType The type of rating to retrieve (one of: challenge, solution, comment)
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        count(ratingType, params = {}, headers = {}) {
            return requester.makeRequest('GET', '/rating-count', Object.assign({ ratingType }, params), headers);
        },

        /**
         * Queries for ratings based on query parameters.
         *
         * @param {string} ratingType The type of rating to retrieve (one of: challenge, solution, comment)
         * @param {string} orderBy The ordering of the returned ratings (currently only created_at, but will eventually support popularity and money)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        all(ratingType, orderBy, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/ratings', Object.assign({ ratingType, orderBy, start, num }, params), headers
            );
        },

        /**
         * Creates a ratings.
         *
         * @param {Object} rating The rating to create
         * @param {Object} params Any additional parameters. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(rating, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/ratings', { rating }, params, headers);
        },

        /**
         * Gets a rating by its id.
         *
         * @param {string} id ID of rating to get
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        get(id, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/ratings/${id}`, {}, params, headers);
        }
    }
}
