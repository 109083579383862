<script setup>
import Sidebar from "@/layouts/partials/Sidebar.vue";
import { cn } from '@/utils/Helpers';

const type = "boxed";

defineExpose({ type });
</script>

<script>
export default {
	name: "LayoutBoxed",
}
</script>

<template>
	<div class="container mx-auto pt-3">
		<div class="grid grid-cols-12 gap-4 game-dash-row">
			<div class="col-span-12 game-dash-row__breadcrumb">
				<NBreadcrumbs />
			</div>
		</div>
		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-12">
				<div class="flex flex-wrap md:flex-nowrap gap-4 custom-page-row">
					<div class="w-full md:w-[13rem]">
						<Sidebar />
					</div>
					<div class="w-full">
						<RouterView v-slot="{ Component }">
							<template v-if="Component">
								<component :is="Component" />
							</template>
							<div v-else class="w-full h-screen bg-dust-100 rounded-md flex items-center justify-center animate-pulse" />
						</RouterView>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
