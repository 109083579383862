<script setup>
import NModal from '@/components/NModal.vue';
import RatingForm from '@/components/idea/RatingForm.vue';
import MentoringEdition from '@/components/mentor/MentoringEdition.vue';
import { useFeatureFlag } from '@/composable/feature-flag';
import { oldApi as nerve } from '@/modules/services';
import { ref } from 'vue';
import { date } from '@bignerve/ui-utils';
import { useDetails } from '@/composable/challenge';
import { useIdeaContentContext } from '@/composable/idea';

const props = defineProps({
	idea: {
		type: Object,
		required: true,
	},
	challenge: {
		type: Object,
		required: true,
	},
    title: {
        type: String,
        default: 'Rate & Mentor',
    },
    forcedRating: {
        type: Number,
        default: null,
    },
});

const emit = defineEmits(['success']);
const { enabled: isAMAEnabled } = useFeatureFlag('sb-ama-request-extension');
const modal = ref(null);
const { isAMA } = useDetails(props.challenge);

const updateRatingFlow = () => {
    return nerve.challenges.startRating(props.challenge.slug);
}

const onRatingSuccess = ({ fields, commented }) => {
    props.idea.user_stats.rated = true;
    props.idea.user_stats.rating = fields.rating;
    props.idea.user_stats.mentored = commented;

    emit('success', { fields, commented });
};

const open = () => {
    modal.value?.open();
};

const close = () => {
    modal.value?.close();
};

const { content } = useIdeaContentContext();

defineExpose({
    open,
    close,
});
</script>

<template>
	<NModal ref="modal">
		<template #activator="{ on }">
			<slot
				name="activator"
				:on="on"
			/>
		</template>

        <template #default="{ visible, close }">
            <div v-if="visible" class="bg-white p-2 rounded-md flex flex-col w-full sm:p-4 sm:m-4 sm:w-[50rem]" >

                <div class="flex items-center justify-between border-b pb-2">
                    <div class="flex items-center ">
                        <slot name="header">
                            <NIcon as="rater" class="text-lg text-dust-700" />
                            <span class="ml-1 text-dust-700 font-bold text-lg">{{ title }}</span>
                        </slot>
                    </div>
                    <button aria-label="button" type="button" class="text-dust-700 h-8 w-8 rounded-full flex items-center justify-center hover:bg-dust-100" @click="close">
                        <NIcon as="close" />
                    </button>
                </div>

                <div class="max-h-[80dvh] sb-none sb-dust-300 overflow-y-auto sm:sb-snug">
                    <MentoringEdition v-if="isAMA && idea?.mentor_comment?.id" :challenge="challenge" v-model:mentorComment="idea.mentor_comment">
                        <template #activator="{ on }">
                            <button aria-label="button" v-on="on" type="button" class="btn inline-flex items-baseline justify-center leading-tingh is-mentor px-4 py-2 mb-3 min-w-[10rem]">
                                <NIcon as="pencil-alt-solid" class="text-lg" left />
                                Edit Mentoring
                            </button>

                            <span v-if="content" class="font-semibold text-red-500 leading-none">Complete your mentoring by {{ date(content.expiration.expires_at, 'MMMM D, YYYY') }}</span>
                        </template>
                    </MentoringEdition>
                    
                    <RatingForm
                        v-else
                        :idea="idea"
                        :rating="forcedRating || idea.user_stats.rating"
                        :force-rating="!!forcedRating"
                        :before-rating="updateRatingFlow"
                        :default-mentoring="true"
                        :has-quick-feedback="false"
                        :mentored="idea?.user_stats?.mentored"
                        :mentorComment="idea?.mentor_comment || {}"
                        :store="true"
                        :disable-rating="challenge.is_closed"
                        :default-fields="{ request_extension: false }"
                        class="shadow-none bg-transparent"
                        @success="(...args) => (onRatingSuccess(...args), close())"
                    >
                        <template v-if="isAMA && isAMAEnabled" #footer="{ fields }">
                            <div class="flex items-center justify-center pt-2">
                                <div class="flex items-baseline max-w-[30rem]">
                                    <input id="sb-ama-request-extension" name="sb-ama-request-extension" type="checkbox" v-model="fields.request_extension" class="mr-2" />
                                    <label for="sb-ama-request-extension" class="italic">Request a 14 day extension. Leave a brief response in the "what I like" field.  Then you can return and edit this feedback before it expires.</label>
                                </div>
                            </div>
                        </template>
                    </RatingForm>
                </div>
            </div>
        </template>
	</NModal>
</template>
