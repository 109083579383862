/** @module notifications */
export default requester => {
    return {
        /**
         * Retrieves a chat log history based on the specified parameters.
         *
         * @param {string} chatType The type of the object for which to retrieve chat logs. This can be one of: 'direct’, 'closed_challenge’, 'open_challenge’, 'lab’, 'catalyst_idea_feedback’, 'solution’, 'global_admin’, 'platform_admin’, ‘organization’
         * @param {string} relatedId The id of the related object for which to retrieve chat logs
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        chatLog(chatType, relatedId, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/chat-log', Object.assign({ chatType, relatedId }, params), headers
            );
        },

        /**
         * Gets the user’s email preferences.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        emailPreferences(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/email-preferences', params, headers);
        },

        /**
         * Sets the user’s email preferences.
         *
         * @param {Object} preferences The preferences object
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        setEmailPreferences(preferences, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/email-preferences', { preferences }, params, headers);
        },

        /**
         * Retrieves past notifications (used to populate the mini dashboard with historical data).
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        past(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/notifications/past', params, headers);
        },

        /**
         * Retrieves past task notifications (used to populate the mini dashboard with historical data).
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        pastTasks(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/notifications/past-tasks', params, headers);
        },

        /**
         * Retrieves all past task notifications (used to populate the mini dashboard with historical data).
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        allPast(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/notifications/all-past', params, headers);
        }
    }
}
