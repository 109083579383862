<script>
import Schema from '@/utils/ChallengeSchema';
import FlexTable from '@/components/FlexTable.vue';
import { useDrafts } from '@/composable/drafts.js';
import { date as formatDate } from '@bignerve/ui-utils';
import { defu } from 'defu';
    
export default {
    props: {
        challenge: Object
    },

    components: {
        FlexTable,
    },

    data () {
        return {
            ideas: [],
            busy: false,
        };
    },

    computed: {
        header () {
            return [
                {
                    text: this.$responsive.sm ? 'Idea' : '',
                    name: 'position',
                    grow: .5,
                },
                {
                    text: this.$responsive.sm ? '' : 'Idea Title (tap to expand to view idea detail)',
                    name: 'title',
                    grow: 3
                },
                {
                    text: 'Score',
                    align: 'center',
                    name: 'score',
                },
                {
                    text: 'Innovator',
                    name: 'solver',
                },
                {
                    text: 'Actions',
                    name: 'actions',
                },
            ]
        },
    },

    methods: {
        formatDate,
        async fetch () {
            this.busy = true;
            const { data: response } = await this.$nerve.challenges.ideas(
                this.challenge.slug
            );

            response.data.ideas.forEach(idea => {
                idea.promoting = false;
            });

            this.ideas = response.data.ideas.filter(idea => !idea.is_promoted);
            this.busy = false;
        },
        async saveToPromote (idea) {
            try {
                idea.promoting = true;
                const { data: response } = await this.$api.challenges.idea(idea.id);
                const draftTarget =  defu({
                    challenge: response.data.challenge,
                    origin: {
                        id: idea.id,
                        type: 'challenge',
                        parent: this.challenge.id,
                    },
                }, {
                    challenge: {
                        co_author: idea.solver,
                    }
                }, Schema);

                // save draft
                await this.$api.drafts.create({
                    origin: 'challenge-promotion-flow',
                    draft_type: 'challenge',
                    content: draftTarget,
                });

                // notify draft added
                this.$bus('challenge:draft:promotion:added', draftTarget);
            } finally {
                idea.promoting = false;
            }
        },
    },

    mounted () {
        this.fetch();
    },

    setup () {
        const { drafts, remove, fetch } = useDrafts({
            draft_type: 'challenge',
            origin: 'challenge-promotion-flow',
        });

        const resolveDraft = (idea) => {
            return drafts.value.find(
                draft => draft.content.origin.id === idea.id
            );
        }

        const savedToPromote = (idea) => {
            return !!resolveDraft(idea)
        }

        const removePromotion = async (idea) => {
            try {
                idea.promoting = true;

                const draft = resolveDraft(idea)

                await remove(draft._id);
                await fetch();
            } finally {
                idea.promoting = false;
            }
        }

        return {
            savedToPromote,
            removePromotion,
        };
    }
}
</script>

<template>
    <div class="p-1">
        <h3>Incoming QQ ideas for new questions</h3>
        <p class="italic text-dust-700">
            These are the incoming ideas for new questions for you to ask on your platform. They are ranked by the crowd; the best are listed first. You can “<b>promote</b>” the ideas you like at any time; these promoted ideas become Question Challenge drafts in your “<b>Create Question</b>” flow.
            <router-link :to="{ name: 'challenges.create', query: { start: 'promotions' } }" class="text-dust-700">
                <span class="underline">Go there now to review and publish</span>
                <NIcon as="external-link" class="text-sm" right />
            </router-link>
        </p>

        <FlexTable
            v-if="!busy"
            :header="header"
            :items="ideas"
            header-class="border-b-2 pb-3"
            row-class="border-b border-dashed border-dust-300"
            class="bg-white border rounded-lg px-2 py-4">
            
            <template #col:position="{ item }">
                <div class="py-4 pr-3 flex items-center gap-4">
                    <NIcon as="qq" class="text-xl text-innovator-500" />

                    <span v-if="item.ranked_position" class="h-8 w-8 flex bg-dust-100 items-center justify-center font-semibold rounded-full text-dust-700">
                        {{ item.ranked_position }}
                    </span>
                </div>
            </template>

            <template #col:title="{ item }">
                <NExpansionPanel>
                    <template #header="{ on, active }">
                        <div class="flex cursor-pointer w-full -m-2 p-2 foucs:outline-none" v-on="on" >
                            <h3 class="m-0 font-bold text-dust-700 flex-grow">
                                {{ item.title }}
                            </h3>
                            <NIcon :as="active ? 'arrow-circle-up' : 'arrow-circle-down'" class="text-dust-700 mt-0.5 mx-2" />
                        </div>
                    </template>

                    <NMarkdown :code="item.detail" class="mt-3 text-dust-700" />
                </NExpansionPanel>
            </template>

            <template #col:score="{ item }">
                <span>{{ item.stats.ranked_score }}</span>
            </template>

            <template #col:solver="{ item }">
                <NPlayerDetails :user="item.solver" simple medium />
            </template>

            <template #col:actions="{ item }">
                <div>
                    <button aria-label="button" type="button" class="flex items-center justify-center min-w-33 bg-dust-100 px-4 py-1 rounded-md hover:bg-dust-200" @click="(!savedToPromote(item) ? saveToPromote(item) : removePromotion(item))">
                        <NLoading v-if="item.promoting" class="text-dust-700 -my-1" loading />
                        <NIcon v-else :as="!savedToPromote(item) ? 'heart' : 'heart-solid'" class="mr-2" />
                        {{ item.promoting ? 'Loading...' : 'Promote' }}
                    </button>
                </div>
            </template>
        </FlexTable>

        <div v-else class="bg-white py-20 bg-white border rounded-lg">
            <NLoadingNerve />
        </div>
    </div>
</template>