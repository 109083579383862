<script>
import NModal from '@/components/NModal.vue';
import upperFirst from 'lodash/upperFirst';

import TwitterProvider from './TwitterPreview.vue';
import FacebookProvider from './FacebookPreview.vue';

export default {
    name: 'Sharer',

    components: { NModal, TwitterProvider, FacebookProvider },

    data () {
        return {
            payload: null,
            provider: null,
            onShared: () => {},
            onClosed: () => {},
        };
    },

    bus: {
        'sharer:share' ({ provider, payload, onShared, onClosed }) {
            if (!provider) {
                throw new Error('Sharer: provider is required');
            }

            this.provider = provider;
            this.payload = payload;
            this.onShared = onShared || (() => {});
            this.onClosed = onClosed || (() => {});

            this.$refs.modal.open()
        },
    },

    computed: {
        title () {
            return `Share - ${upperFirst(this.provider || 'Social')}`;
        },
        
    },
    methods: {
        done() {
            this.onShared();
        },
    },
}
</script>

<template>
    <NModal ref="modal" :closeClickOutside="false" #default="{ visible, sharing, close }">
        <div v-if="visible" class="sharing-popup-panel m-3 transition-xy transition-transform duration-500 ease-in-out max-w-180 min-w-160">
            <div class="sharing-popup-panel__heade flex items-center justify-between bg-nerve text-white  rounded-t-lg px-4 py-2">
                <div class="flex items-center">
                    <NIcon as="share-square-solid -mt-1" class="mr-2" />
                    <h2 class="m-0 leading-none">{{ title }}</h2>
                </div>
                <button aria-label="button" type="button" class="sharing-popup-panel__close-btn" @click.prevent="onClosed() & close()">
                    <span aria-hidden="true" class="icon icon-times-circle-regular"></span>
                </button>
            </div>

            <TwitterProvider
                v-if="provider === 'twitter'"
                :content="payload"
                class="sharing-popup-panel__body"
                @cancel="close"
                @done="done"
            />

            <p v-else class="text-center py-30 text-dust-600">
                {{ provider }} is not supported yet
            </p>
        </div>
    </NModal>
</template>

