<template>
    <div class="info-text" style="width: 90%;">
        <!-- Content -->
        <h1>Hello <span style="color:#E36749;">Maine Innervators</span>!</h1>
        
        <h3><em>Our site is undergoing planned maintenance this morning (Friday)</em></h3>
        
        <p>
            Please return this afternoon to continue adding your questions and entering ideas! Thank you...  
        </p>

    </div>
</template>
