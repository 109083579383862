<template>
    <div class="discover-wrapper">
        <div class="discover-heading">
            <div class="discover-heading__helper">
                <span aria-hidden="true" class="discover-heading__icon icon-nerve-shape-solid"></span>
                <h1 class="discover-heading__title">Discover More of BigNerve</h1>
            </div>
            <div class="discover-heading__search-form">
                <input class="discover-heading__input" placeholder="Search across the Nerv Us System">
                <!-- delete this button and css because this is not an overlay anymore  <button aria-label="button" aria-hidden="true" class="discover-heading__clear icon-times-circle-regular"></button> -->
            </div>
        </div>


        <section>
            <div class="discover-search-bar-heading">
                <h2 class="discover-search-bar-heading__title">
                    People
                </h2>
                <div class="discover-search-bar-heading__search-form">
                    <button aria-label="button" class="discover-search-bar-heading__submit" type="button">
                        <span aria-hidden="true" class="icon-search-solid"></span>
                    </button>
                    <input class="discover-search-bar-heading__input">
                    <span class="discover-search-bar-heading__help-text">Search within this section</span>
                </div>
            </div>
            <div class="discover-list-wrapper">
                <div>
                    <span class="discover-list-wrapper__meta">Useful ways to discover people. You can tap more than one filter to filter the user results.</span>
                </div>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Find players by Roles</h3>
                            <ul>
                                <li><a href="#">Catalysts</a></li>
                                <li><a href="#">Mentors</a></li>
                                <li><a href="#">Analysts</a></li>
                                <li><a href="#">Scouts</a></li>
                                <!--
                                <li><a href="#">Insightfuls</a></li>
                                <li><a href="#">Directors</a></li>
                                <li><a href="#">Patrons</a></li>
                                -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Categories & Topics</h3>
                            <ul>
                                <li><a href="#">Coming soon!</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Colleges & Universities</h3>
                            <ul>
                                <li><a href="#">UMaine</a></li>
                                <li><a href="#">USM</a></li>
                                <li><a href="#">Thomas College</a></li>
                                <li><a href="#">UNE</a></li>
                                <li><a href="#">YCCC</a></li>
                                <li><a href="#">SMCC</a></li>
                                <li><a href="#">Colby</a></li>
                                <li><a href="#">Bowdoin College</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Locations</h3>
                            <ul>
                                <li><a href="#">City</a></li>
                                <li><a href="#">City</a></li>
                                <li><a href="#">City</a></li>
                                <li><a href="#">City</a></li>
                                <li><a href="#">City</a></li>
                                <li><a href="#">City</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 class="discover-list__title is-red is-italic">{Suggested players based on activity} / {These players match the active filter}</h3>
                </div>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-05.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-05.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-05.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-03.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-03.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-03.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-04.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-04.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-04.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-02.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-02.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <img src="/img/placeholder/user-02.png" class="discover-media-item__avatar" alt="">
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">User Name</a>
                                    </h4>
                                    <span class="discover-media-item__social">
                                        <a class="discover-media-item__social-handler" href="#">@handle-social-media</a>
                                        <span class="discover-media-item__social-icons">
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-twitter"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-facebook-square"></span>
                                            </a>
                                            <a href="#">
                                                <span aria-hidden="true" class="icon icon-github"></span>
                                            </a>
                                            <a href="#">
                                                <img class="brain-icon" src="/img/brain.svg" alt="A Bignerve Platform Logo" aria-hidden="true">
                                            </a>
                                        </span>
                                    </span>
                                    <div class="discover-media-item__meta">
                                        Interests, Interests
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-list-wrapper__footer">
                    <button aria-label="button" type="button" class="load-more-btn px-2">
                        <span class="load-more-btn__helper">Load 50</span> more
                        <span aria-hidden="true" class="icon icon-caret-down-simple"></span>
                    </button>
                </div>
            </div>
        </section>
        
        <section class="non-functional">
            <div class="discover-search-bar-heading">
                <h2 class="discover-search-bar-heading__title">
                    Organizations (Groups, NGOs, Businesses)
                </h2>
                <div class="discover-search-bar-heading__search-form">
                    <button aria-label="button" class="discover-search-bar-heading__submit" type="button">
                        <span aria-hidden="true" class="icon-search-solid"></span>
                    </button>
                    <input class="discover-search-bar-heading__input">
                    <span class="discover-search-bar-heading__help-text">Search within this section</span>
                </div>
            </div>
            <div class="discover-list-wrapper">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Science</h3>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Science</h3>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Science</h3>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Science</h3>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Active Organization Name</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item">
                                <div class="discover-media-item__logo">
                                    <img src="/img/placeholder/water-org.png" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Water.org</a>
                                    </h4>
                                    <span class="discover-media-item__highlighted-meta">Created 20 Challenges</span>
                                    <div class="discover-media-item__meta">
                                        Cause Name or Sub-Cause, Long Cause Name
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-list-wrapper__footer">
                    <button aria-label="button" type="button" class="load-more-btn px-2">
                        <span class="load-more-btn__helper">Load 50</span> more
                        <span aria-hidden="true" class="icon icon-caret-down-simple"></span>
                    </button>
                </div>
            </div>
        </section>
        <section>
            <div class="discover-search-bar-heading">
                <h2 class="discover-search-bar-heading__title">
                    Causes &amp; Categories
                </h2>
                <div class="discover-search-bar-heading__search-form">
                    <button aria-label="button" class="discover-search-bar-heading__submit" type="button">
                        <span aria-hidden="true" class="icon-search-solid"></span>
                    </button>
                    <input class="discover-search-bar-heading__input">
                    <span class="discover-search-bar-heading__help-text">Search within this section</span>
                </div>
            </div>
            <div class="discover-list-wrapper">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Science</h3>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,510 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,210 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 910 Challenge
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Entertainment</h3>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,510 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,210 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 910 Challenge
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Technology</h3>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,510 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,210 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 910 Challenge
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div class="discover-list">
                            <h3 class="discover-list__title">Social issues</h3>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,510 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 1,210 Challenge
                                    </div>
                                </div>
                            </div>
                            <div class="discover-media-item is-category">
                                <div class="discover-media-item__icon">
                                    <img src="/img/category-icons/economics.svg" alt="">
                                </div>
                                <div class="discover-media-item__data">
                                    <h4 class="discover-media-item__name">
                                        <a href="#">Cause or Category Name could be long</a>
                                    </h4>
                                    <div class="discover-media-item__meta">
                                        Tagged in 910 Challenge
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover-list-wrapper__footer">
                    <button aria-label="button" type="button" class="load-more-btn px-2">
                        <span class="load-more-btn__helper">Load 50</span> more
                        <span aria-hidden="true" class="icon icon-caret-down-simple"></span>
                    </button>
                </div>
            </div>
        </section>
        <section>
            <div class="discover-search-bar-heading">
                <h2 class="discover-search-bar-heading__title">
                    Curated Platforms
                </h2>
                <div class="discover-search-bar-heading__search-form">
                    <button aria-label="button" class="discover-search-bar-heading__submit" type="button">
                        <span aria-hidden="true" class="icon-search-solid"></span>
                    </button>
                    <input class="discover-search-bar-heading__input">
                    <span class="discover-search-bar-heading__help-text">Search within this section</span>
                </div>
            </div>
            <div class="discover-list-wrapper">
                <div class="grid grid-cols-12 gap-4">
                    <!-- <h2 class="discover-platforms__title">Curated Platforms</h2> -->
                    <p class="discover-platforms__meta">Browse visually across the BigNerve ecosystem. Tap and drag to explore.</p>
                    <div class="col-span-12 sm:col-span-8 lg:col-span-9">
                        <img src="/img/placeholder/cloud-canvas.png" alt="" class="img-responsive">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
