<script>
import { drawMeme } from '@bignerve/meme-generator';
import { Buffer } from "buffer";
import axios from 'axios';

const useImage = (source) => {
    const img = new Image();

    img.src = source;

    return new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Could not load image at ' + source));
    });
};

const useBaseDimensions = (img) => ({
    width: 500,
    height: (img.height / img.width) * 500,
});

export default {
    name: 'MemeGenerator',
    props: {
        // The image to be displayed as context
        imageSrc: String,
        // The setup text (title)
        setupText: String,
        // The pushline text (description)
        punchlineText: String,
        // format (meme|caption)
        format: {
            type: String,
            default: 'meme',
        },
        // shows loading indicator
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            busy: false,
            image: null,
            logo: null,
            width: 500,
            height: 500,
        }
    },
    computed: {
        canvas () {
            return this.$refs.canvas;
        }
    },
    watch: {
        setupText: 'draw',
        punchlineText: 'draw',
        imageSrc: 'draw',
    },
    methods: {
        async draw () {
            const img = await useImage(this.imageSrc);

            this.canvas && this.drawMeme(this.canvas, img);
        },
        drawMeme (canvas, image) {
            const { width, height } = useBaseDimensions(image);

            this.width = width;
            this.height = height;

            const ctx = canvas.getContext('2d');

            ctx.clearRect(0, 0, this.width, this.height);

            // Draw the texts
            drawMeme(canvas, {
                setupText: this.setupText,
                punchlineText: this.punchlineText,
                image: image,
                format: this.format,
                height: this.height,
                width: this.width,
                logo: this.logo,
                footer: true,
            });

            return canvas;
        },
        async loadLogo () {
            this.logo = await useImage(`${location.origin}/img/brain.svg`)
        },
        toFile (canvas) {
            return new Promise((resolve, reject) => {
                try {
                    canvas.toBlob((blob) => {
                        resolve(new File([blob], 'image.png', { type: 'image/png' }))
                    }, 'image/png');
                } catch (e) {
                    reject(e);
                }
            })
        },
        async uploadImage () {
            try {
                this.busy = true;
                const { data, headers } = await axios.get(
                    `${import.meta.env.VUE_APP_FUNC_URL}/use-image?source=${this.imageSrc}`,
                    { responseType: 'arraybuffer' },
                );
                const base64 = Buffer.from(data).toString('base64');
                const imageBase64 = `data:${headers['Content-Type']};base64,${base64}`;

                const image = await useImage(imageBase64);
                const canvas = document.createElement('canvas');

                this.drawMeme(canvas, image)

                const file = await this.toFile(canvas);
                const { data: response } = await this.$api.resource.uploadImage(
                    file, { type: file.type }, { 'Content-Type': file.type },
                );
                
                return response;
            } catch (err) {
                console.log(err)
                this.hasError = true;
                this.$sentry.withScope(scope => {
                    scope.setTag('event', 'meme-generator');
                    scope.setTag('step', 'upload-image');
                    
                    this.$sentry.captureException(err);
                });

                throw err;
            } finally {
                this.busy = false;
            }
        },
        init () {
            this.loadLogo();

            this.draw();
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.init();
        });
    },
}
</script>

<template>
    <div class="relative">
        <div v-if="busy && loading" class="bg-black bg-opacity-40 absolute inset-0 flex items-center justify-center">
            <NLoading class="text-white" active>
                Uploading image...
            </NLoading>
        </div>
        <canvas ref="canvas" class="w-full"></canvas>
    </div>
</template>
