<script setup>
import LoadMore from '@/layouts/partials/LoadMore.vue';
import toUpper from 'lodash/toUpper';
import OpenChallengeCard from './OpenChallengeCard.vue';
import CardPlaceholder from '@/components/CardPlaceholder.vue';
import { usePlatform } from '@/composable/platform';
import { ref, computed, onMounted } from 'vue';
import { useRouteParams, useRouteQuery } from '@vueuse/router';
import { useChallenges } from '@/queries/widgets';
import bus from '@/modules/bus';

const props = defineProps({
    filter: {
        type: String,
        default: 'all'
    },
    title: {
        type: String
    },
    platformDomain: {
        type: Boolean,
    },
});

const { currentPlatform, platform } = usePlatform();
const catalyst = useRouteParams('user');
const category = useRouteQuery('category');

const catalystRef = ref(catalyst.value);

// To replace the event bus functionality
onMounted(() => {
    bus.on('catalyst:selected', ({ handle }) => {
        catalystRef.value = handle;
    });
});

const params = computed(() => ({
    size: props.filter === 'default' ? 9 : 18,
    page: 0,
    status: 'open',
    sort: props.filter,
    category: category.value,
    platform_domain: props.platformDomain ? platform.value?.id : undefined,
}));

const platformSlug = computed(() => catalystRef.value || catalyst.value || currentPlatform.value?.slug || 'bignerve');

const {
    data: challenges,
    hasNextPage: hasMore,
    isFetching: busy,
    fetchNextPage: loadMore,
    refetch,
} = useChallenges(platformSlug, params);

const isEmpty = computed(() => !challenges.value || challenges.value.length === 0);
const total = computed(() => challenges.value?.meta?.total || 0);
</script>

<template>
    <section class="card-section">
        <h2 class="mb-4 text-xl font-narrow text-secondary">
            {{ title }} <span v-if="platformDomain && platform">for {{ toUpper(platform.subdomain) }}</span>
        </h2>
        <div v-show="!isEmpty" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <OpenChallengeCard
                v-for="(challenge, index) in challenges || []"
                :key="`${index}-open-${challenge.id}`"
                :challenge="challenge"
            />
        </div>
        <div v-if="busy" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
            <CardPlaceholder v-for="i of 6" :key="`open-placeholder-${i}`">
                <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
            </CardPlaceholder>
        </div>
        <LoadMore v-if="hasMore && !busy" :loading="busy" :handler="loadMore" />
        <p v-else-if="! busy && isEmpty" class="italic">
            No open challenges match this filter!
        </p>
    </section>
</template>
