<script setup>
import Editor from '@/components/form/TextEditor.vue';
import Field from '@/components/form/Field.vue';
import { Reveal } from '@/components/Reveal';
import { inject } from 'vue';
import { isAMA } from '@/utils/challenge';

const form = inject('form')

defineProps({
    challenge: {
        type: Object,
        required: true
    },
});
</script>

<template>
    <div class="form-groups">
        <div class="form-group">
            <label class="form-group__label">
                {{ $t(`page.challenge-detail.tab.enter-your-idea.${challenge.solution_format || 'challenge'}.fields.title.label`) }} <span class="form-inline-help-text">{{ $t(`page.challenge-detail.tab.enter-your-idea.${challenge.solution_format || 'challenge'}.fields.title.tip`) }}</span>
            </label>

            <NInput
                v-model="form.fields.title"
                name="title"
                id="title"
                :placeholder="$t(`page.challenge-detail.tab.enter-your-idea.${challenge.solution_format || 'challenge'}.fields.title.placeholder`)"
                maxlength="180"
                field-name="Idea Title"
                aria-label="Idea Title"
                required
            />

            <p v-if="challenge.innovation_scale && !isAMA(challenge)" class="my-3">
                <b class="mr-1">
                    This Challenge is looking for “{{ $t(`page.challenge-detail.tab.enter-your-idea.challenge.innovation-scale.${challenge.innovation_scale || '*'}.label`) }}” ideas:
                </b>
                <i class="mr-1">
                    {{ $t(`page.challenge-detail.tab.enter-your-idea.challenge.innovation-scale.${challenge.innovation_scale || '*'}.note`) }}
                </i>
                <img :src="`/img/innovative-level/${challenge.innovation_scale}.png`" class="inline h-6 filter drop-shadow" alt="Innovation Scale">
            </p>

            <Reveal :to="$route.name">
                <p class="form-block-help-text mt-2 text-sm mb-0">
                    Make your title clear, clever &amp; as interesting as possible to attract attention from the community. It should summarize your idea concisely. Check your description and title for typos and grammar, because once you submit your idea, you can’t change the details later.
                </p>
            </Reveal>
        </div>

        <div class="form-group">
            <div class="quill-editor-wrapper">
                <span class="quill-editor-title">{{ $t(`page.challenge-detail.tab.enter-your-idea.${challenge.solution_format || 'challenge'}.fields.description.label`) }}</span>
                
                <Field name="detail" :modelValue="form.fields.detail" :required="true">
                    <Editor
                        v-model="form.fields.detail"
                        id="detail"
                        name="detail"
                        :placeholder="$t(`page.challenge-detail.tab.enter-your-idea.${challenge.solution_format || 'challenge'}.fields.description.placeholder`)"
                    />
                </Field>
            </div>
        </div>
        <div v-if="$auth.authenticated" class="form-group">
            <label class="form-group__label">
                Add an image, photo or artwork to explain or enhance your idea
                <span class="form-inline-help-text">[optional]</span>
            </label>
            <NFile v-if="!form.fields.image" v-model="form.fields.image" button-text="Select" name="file">
                <template #draging>
                    <div class="text-dust hidden sm:block px-2 flex-1 text-center">
                        Drag and drop an image here
                    </div>
                </template>
            </NFile>
            <div v-if="form.fields.image" class="flex flex-col sm:flex-row image-preview mt-4">
                <div class="image-preview__file">
                    <img :src="form.fields.image" alt="Image Preview">
                </div>
                <div class="flex items-center">
                    <button type="button" aria-label="Remove Image" class="px-4 py-2 border rounded-md shadow bg-white flex items-center text-dust-700" @click="form.fields.image = null">
                        <NIcon as="remove" left />
                        Remove image
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
