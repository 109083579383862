export default requester => {
    return {
        /**
         * Gets user's social accounts
         *
         * @param {object} handle
         **/
        social (handle = {}) {
            return requester.get(`/users/${handle}/social`);
        },
        
        /**
         * Shadow user
         *
         * @param {object} handle
         **/
        shadow (params) {
            return requester.post(`account/shadow`, params);
        },

        /**
         * Gets user's profile accounts
         *
         * @param {object} handle
         **/
        profile (handle = {}) {
            return requester.get(`/users/${handle}/profile`);
        },

        search (params, config = {}) {
            return requester.get(`admin/users`, { ...config, params });
        }
    };
}
