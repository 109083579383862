<script setup>
import { watch, computed } from 'vue';
import { useFloating } from '@/composable/floating';
import { useCrossPlatformState } from '@/composable/app';

const props = defineProps({
	placement: {
		type: String,
		default: 'top',
	},
	open: {
		type: Boolean,
		default: false,
	},
	target: {
		type: String,
	},
	title: {
		type: String,
	},
	description: {
		type: String,
	},
});

const viewed = useCrossPlatformState('bignerve.tips')(props.target);
const emits = defineEmits(['closed', 'open']);

const { target, ...tooltip } = useFloating();

watch(
	() => props.open,
	(opended) => {
		if (!props.target) {
			opended ? tooltip.show() : tooltip.hide();
		}
	}
);

const opened = computed(() => {
	return props.target ? props.open && !viewed.value : props.open;
});

const hide = () => {
	tooltip.hide();

	viewed.value = true;
};
</script>

<template>
	<VTooltip
		:shown="opened"
		:placement="placement"
		:autoHide="false"
		:padding="25"
		:distance="15"
		:triggers="[]"
		theme="tip"
		ref="target"
		@hide="$emit('closed')"
	>
		<slot />
		<template #popper>
			<div class="bg-white rounded-lg border max-w-xl p-2">
				<div class="bg-mentor text-white px-3 pt-3 pb-1">
					<slot name="content">
						<h3>{{ title }}</h3>
						<slot name="description">
							<p class="mb-2">
								{{ description }}
							</p>
						</slot>
					</slot>
					<div class="flex justify-end mb-2">
						<button aria-label="button"
							class="px-3 py-1 inline-flex items-baseline border border-transparent rounded-md hover:border-mentor-600"
							@click="hide"
						>
							{{ $t('common.go-it')
							}}<NIcon
								as="times-circle-regular"
								class="ml-2"
							/>
						</button>
					</div>
				</div>
			</div>
		</template>
	</VTooltip>
</template>

<style lang="scss">
._v-popper--theme-tip .v-popper__arrow-inner {
	&::before {
		content: '';
		@apply border-mentor absolute -left-[14px] -top-[5px];
		border-width: 15px;
		border-style: solid;
		border-left-color: transparent !important;
		border-right-color: transparent !important;
		border-top-color: transparent !important;
	}
}
</style>
