export default requester => {
    return {
        /**
         * Create draft
         *
         * @param {object} draft
         **/
        create (draft) {
            return requester.post('/drafts', draft);
        },

        /**
         * Create draft
         *
         * @param {string} id
         * @param {object} draft
         **/
        update (id, draft) {
            return requester.put(`/drafts/${id}`, draft);
        },

        /**
         * Create draft
         *
         * @param {object} params
         **/
        find (params) {
            return requester.get('/drafts', { params });
        },

        /**
         * Remove draft
         *
         * @param {object} draft
         **/
        remove (id) {
            return requester.delete(`/drafts/${id}`);
        },
    };
}
