import { ref, provide, inject, defineComponent, reactive, computed, watch } from 'vue';

export const DiscussionContext = defineComponent({
    name: 'DiscussionContext',
    props: {
        strictReply: {
            type: Boolean,
            default: false,
        }
    },
    setup: (props, { slots }) => {
        const replying = reactive({});

        const isReplying = computed(() => {
            return Object.values(replying).some(value => value);
        })

        const setReplying = (messageId, status) => {
            if (!messageId) {
                return;
            }

            if (props.strictReply && status) {
                Object.keys(replying).forEach(key => {
                    replying[key] = false;
                });
            }

            replying[messageId] = status;
        }

        provide('discussionContext', {
            isReplying,
            replying,
            setReplying,
        });

        return () => slots.default({ isReplying, setReplying });
    },
})

export const useDiscussionContext = () => {
    return inject('discussionContext', {
        isReplying: ref(false),
        setReplying: () => {},
        replying: reactive({}),
    });
}

export const useMessageable = (messageId) => {
    const { replying, setReplying } = useDiscussionContext();

    const isReplying = computed({
        get: () => replying[messageId],
        set: (value) => {
            replying[messageId] = value;
        }
    })

    watch(isReplying, (value) => {
        setReplying(messageId, !!value);
    }, { immediate: true });

    const toggleReplying = () => {
        isReplying.value = !isReplying.value;
    }

    return { isReplying, toggleReplying }
}