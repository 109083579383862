import { v4 as uuidV4 } from 'uuid';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { toRaw } from '@/mixins/Markdown';
import truncate from 'lodash/truncate';
import defu from 'defu';

/**
 * Flatten the object that contains the request parameters.
 *
 * @param  {object}  object
 * @param  {string}  prefix
 * @return {object}
 */
export const flatten = (object, namespace = '', separator = '[{key}]') => {
    return Object.keys(object).reduce((carry, key) => {
        const prefix = (namespace ? namespace + separator.replace('{key}', key) : '') || key;

        return Object.assign(
            carry,
            typeof object[key] === 'object'
                ? flatten(Object.assign({}, object[key]), prefix, separator)
                : { [prefix]: object[key] }
        );
    }, {});
};

/**
 * Returns all unique values of an array, based on a provided comparator function.
 *
 * @param  {object}  object
 * @param  {string}  prefix
 * @return {object}
 */
export const uniqueBy = (arr, fn) =>
  arr.reduce((acc, v) => {
    if (!acc.some(x => fn(v, x))) acc.push(v);
    return acc;
  }, []);

/**
 * Resolve taks in sequence
 *
 * @param  {array}      tasks
 * @param  {function}   fn
 * @return {promise}
 */
export const sequence = (tasks, fn, skipError = false) => {
    return tasks.reduce(
        (promise, task) => promise
            .then(() => fn(task))
            .catch((err) => skipError ? Promise.resolve() : err),
        Promise.resolve()
    );
};

/**
 * Next promise on fail
 */
export const nextOnFail = async (promises = [], { onError } = {}) => {
    for (const p of promises) {
        try {
            const result = await p();

            return result;
        } catch (e) {
            if (onError) {
                await onError(e);
            }

            continue;
        }
    }
}

/**
 * Converts bytes to size
 *
 * @param  {number} bytes
 * @return {size}
 */
export const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

/**
 * Format numbers
 *
 * @param  {number} bytes
 * @return {size}
 */
export const numeric = (value = 0, digits = 0) => {
    return Number(value).toFixed(digits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Generate a uuid
 *
 * @return {string}
 */
export const uuid = () => {
    return 'crypto' in window
        ? uuidV4() : (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
}

/**
 * Format dates
 *
 * @param  {string|number|date} date
 * @param  {string} format
 * @return {size}
 */
export const date = (value, format = 'YYYY-MM-DD') => {
    const time = moment(value);
    switch (format) {
        case 'human':
            return time.fromNow();
        case 'days':
            const days = time.diff(new Date(), 'days');
            return `${days} ${days > 1 ? 'days' : 'day'}`;
        default:
            return time.format(format);
    }
}

/**
 * Create a date range
 *
 * @param {date|moment} start The start date
 * @param {date|moment} end The end date
 * @param {string} interval The range type. eg: 'days', 'hours' etc
 */
export const dateRange = (start, end, interval = 'days') => {
    const from = moment(start)
    const to = moment(end)
    const diff = to.diff(from, interval)
    return (new Array(diff + 2))
        .fill(null)
        .map((_, n) => {
            return moment(from).add(n, interval)
        })
}

export const promiseAll = promisses => {
    return Promise.all(
        promisses.map(p => p.catch(e => e))
    );
};

/**
 * Turns the target object with computed properties.
 * 
 * @param {Object} target 
 * @returns 
 */
export const useComputedProperty = (target = {}) => 
    new Proxy(target, {
        get: (_, key) => {
            if (typeof target[key] === 'function') {
                return target[key].call(target, target[key]);
            }

            return target[key];
        }
    });

/**
 * Get starndard deviation
 * 
 * @param {n} args 
 * @returns 
 */
export const standardDeviation = (args = []) => {
    const n = args.length
    const mean = args.reduce((a, b) => a + b) / n

    return Math.sqrt(args.map(
        x => Math.pow(x - mean, 2)
    ).reduce((a, b) => a + b) / n)
}

/**
 * Remove caracters from string
 * 
 * @param {*} text 
 * @param {*} length 
 * @returns 
 */
export const excerpt = (text, length = 40) => truncate(text, { length, separator: /,? +/ });

/**
 * Clone object
 */
export const clone = (target = {}, ...sources) => 
    defu(...sources, JSON.parse(JSON.stringify(target)));

/**
 * Merge classnames
 */
export const cn = (...inputs) =>
    twMerge(clsx(inputs));

/**
 * Decode HTML 
 */
export const decodeHtml = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

/**
 * Check if the string is useless
 */
export const isUseless = (str) => {
	const list = [
		'nothing(\s*)(?:^|)(new|to|at|another|really|about|perfect|great)',
		'^nothing$',
		'^nothing(.){0,4}$',
		'^try to be more creative',
		'^better luck next time',
		'^above average',
		'^You tried',
		'^nice try',
		'^not very funny',
		'^Not my thing',
		"^Don't really get this one",
		'no need to nurture',
	];

	return str && list.map((e) => new RegExp(e, 'i').test(toRaw(str))).some((r) => r);
};

export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 * Truncate HTML
 * 
 * @param {string} html 
 * @param {object} options 
 * @returns 
 */
export const truncateHTML = (html, { length, separator, omission = '...' } = {}) => {
    const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  function traverse(node, chars) {
    if (chars.truncated) return true

    if (node.nodeType === Node.TEXT_NODE) {
      const remainingChars = length - chars.count
      if (node.textContent && node.textContent.length > remainingChars) {
        const regex = new RegExp(separator || '\\s')
        const words = node.textContent.split(regex)
        let truncated = ''
        for (let word of words) {
          if ((truncated + word).length <= remainingChars) {
            truncated += `${word} `
          } else {
            break
          }
        }
        node.textContent = truncated + omission
        chars.count += truncated.length
        chars.truncated = true
        return true
      } else if (node.textContent) {
        chars.count += node.textContent.length
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const childNodes = Array.from(node.childNodes)
      for (let child of childNodes) {
        if (traverse(child, chars)) {
          // Remove remaining siblings
          while (child?.nextSibling) {
            node.removeChild(child.nextSibling)
          }
          return true
        }
      }
    }
    return false
  }

  const chars = { count: 0, truncated: false }
  traverse(doc.body, chars)

  return doc.body.innerHTML
}