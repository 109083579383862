<script>
import { date } from '@bignerve/ui-utils';
import neuronRoute from './neuron-route';

export default {
    name: 'NotificationNeuronEarned',

    props: {
        detail: {
            type: Object,
        },
        simple: {
            type: Boolean,
            default: false,
        },
    },
    
    computed: {
        computedClass () {
            return [
                `is-${this.detail.type}`,
                `from-${this.detail.user_origin}`,
                `is-${this.detail.role}`
            ]
        },
        neuron () {
            const route = this.$router.resolve(
                neuronRoute[this.detail.meaningful_name]
                    .call(this, this.detail)
            );
    
            return {
                ...this.detail,
                point_created_at: date(this.detail.point_created_at, 'human'),
                context: this.t(
                    ['gamedash', this.pointsTypeAccessor(this.detail), this.userOriginAccessor(this.detail), this.roleAccessor(this.detail), this.statementAccessor(this.detail)].join('.'),
                    { challenge: this.detail.challenge_title, link: route ? route.href : '/' },
                    `You ${this.detail.points > 0 ? 'earned' : 'lost' } points as ${this.detail.role} in [${this.detail.challenge_title}](${route ? route.href : '/'})`
                ),
            };
        }
    },

    methods: {
        t(str, values, fallbackStr) {
            // use `$te` and `$t
            return this.$t
                ? this.$t(str, values, fallbackStr)
                : fallbackStr
                    ? fallbackStr
                    : str
        },
        pointsTypeAccessor (detail) {
            if (detail.points > 0) {
                return 'points-earned';
            }

            return 'points-deducted';
        },
        userOriginAccessor (detail) {
            switch (detail.user_origin) {
                case 'you':
                    return 'you';
                case 'others':
                    return 'others';
                default:
                    console.warn('[NeuronsEarned]: Unknown user origin', detail.user_origin);
                    return;
            }
        },
        roleAccessor (detail) {
            return detail.role;
        },
        statementAccessor (detail) {
            return [
                detail.event_created,
                detail.meaningful_name,
            ].filter(v => v).join(':');
        },
    },
}
</script>


<template>
    <div class="game-dash-list-item" :class="computedClass">
        <router-link v-if="$auth.authenticated" :to="{ name: 'dashboard.notifications', params: { user: $auth.user.screen_name || 'me' }}" :title="neuron.points" tag="span" class="game-dash-list-item__point cursor-pointer">
            <span v-if="neuron.points > 0">+</span>
            {{ Math.ceil(neuron.points).toFixed(0) }}
        </router-link>
        <div class="game-dash-list-item__content">
            <NMarkdown class="game-dash-list-item__text opacity-95" :code="neuron.context" inline />
            <div v-if="!simple" class="game-dash-list-item__meta">{{ neuron.point_created_at }}</div>
        </div>
    </div>
</template>