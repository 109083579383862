import nprogress from 'nprogress';
import Cookie from '@/utils/Cookie';
import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import { useAutoUpdate } from '@/composable/app';
import { Session } from '@/utils/Auth';

const { tryUpdate: tryUpdateApp } = useAutoUpdate();

// configure progress bar
nprogress.configure({
    showSpinner: false,
});

const accessorScrollTo = (scroll, to, from, savedPosition) => {
    if (!scroll) {
        return false
    }

    if (typeof scroll === 'function') {
        return scroll(to, from, savedPosition)
    }

    return !!scroll;
}

const defaultScrolling = {
    top: 0,
    left: 0,
    behavior: 'smooth',
};

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        // savedPosition is only available for popstate navigations.
        if (savedPosition) {
            return savedPosition;
        }

        // scroll to anchor by returning the selector
        if (to.hash) {
            return {
                ...defaultScrolling,
                el: to.hash,
                top: -10,
            }
        }

        // create a new array of matched routes but reversed
        const routes = Array.isArray(to.matched) ? to.matched.slice().reverse() : [];
        const route = routes.find((r) => 'scrollToTop' in r?.meta);

        if (route) {
            const scrollToTop = accessorScrollTo(
                route.meta.scrollToTop, to, from, savedPosition
            );

            return typeof scrollToTop === 'object' ? {
                ...defaultScrolling,
                ...scrollToTop,
            } : (scrollToTop && defaultScrolling)
        }
    },
});

router.beforeEach((to, from, next) => {
    // check if shadow mode is enabled
    if (to.query.shadow === 'true') {
        Session.startShadow(to.query.token);

        const query = { ...to.query };
        delete query.shadow;
        delete query.token;

        return next({
            ...to,
            query,
            replace: true
        });
    }
    // set new token
    else if (to.query.token) {
        Session.setToken(to.query.token);

        const query = { ...to.query };
        delete query.token;
        
        return next({
            ...to,
            query,
            replace: true
        });
    }

    next();
});

router.beforeEach((to, from, next) => {
    if (to.path !== from.path) {
        nprogress.start();

        // TODO remove this condition soon
        if (!to.path.includes('discussion-forums/channel')) {
            tryUpdateApp();
        }
    }

    next();
});

router.afterEach(() => {
    nprogress.done();
});

export default router;
