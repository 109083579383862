<script setup>
import NLazyImage from '@/components/NLazyImage.vue';
import ExpandImage from '@/components/ui/image/Expand.vue'
import { useDetails } from '@/composable/challenge';
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

defineEmits(['click']);

const props = defineProps({
    challenge: {
        type: Object,
        required: true,
    },
    category: {
        type: String,
    },
    to: [Object, String],
    credits: {
        type: Boolean,
        default: false,
    },
    showLoading: {
        type: Boolean,
        default: false,
    },
    prefetch: {
        type: Boolean,
        default: false,
    },
});

const computedChallenge = computed(() => props.challenge);

const { isSuggestion, thumbnailImage, image, imageCredits, questionTitle } = useDetails(
    computedChallenge,
);
</script>

<template>
    <figure class="relative" >
        <RouterLink v-if="isSuggestion || category || challenge.main_category" :to="{ name: 'home.challenges.search', query: { ...$route.query, mc: challenge.main_category, ic: challenge.is_closed || undefined } }" class="challenge-card__category shadow" :aria-label="questionTitle || 'Challenge image'">
            <span v-if="isSuggestion">SB - {{ $t(`component.challenge.solution-format.${challenge.solution_format}.abbreviation`) }}</span>
            <span v-else class="capitalize">
                {{ category || challenge.main_category }}
            </span>
        </RouterLink>
        <Component :is="to ? RouterLink : ExpandImage" v-bind="to ? { to } : { src: image }">
            <NLazyImage
                :src="(!isSuggestion ? [thumbnailImage, image, challenge.article && challenge.article.image_url] : [image])"
                :show-loading="showLoading"
                :prefetch="prefetch"
                :alt="questionTitle || 'Challenge image'"
                class="aspect-image"
                @click="e => $emit('click', e)"
            />
        </Component> 

        <slot v-if="imageCredits && credits" name="credits" :credits="imageCredits">
            <div class="!text-dust-600 text-xs font-body text-center py-2 bg-dust-50">
                Image by <a v-if="imageCredits.user" :href="imageCredits.link" class="text-dust-600 underline" target="_blank" rel="noopener noreferrer">{{ imageCredits.user }}</a> <span v-if="imageCredits.user">on</span> <a :href="imageCredits.link" class="text-dust-600 underline" target="_blank" rel="noopener noreferrer">{{ imageCredits.source }}</a>
            </div>
        </slot>
    </figure>
</template>
