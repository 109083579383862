<script>
export default {
    name: 'FAQs',

    metaInfo: {
        title: 'FAQs',
    },
}
</script>

<template>
    <article class="page">
        <NPageHeader
            :title="$route.meta.title"
            color="nerve"
        />
        <div class="page__content">
            <router-view />
        </div>
    </article>
</template>

<style lang="scss">
.page__content pre.language-text {
    @apply text-xs;
    @apply text-dust-600;
    @apply rounded-md;
    @apply bg-dust-200;
    @apply p-3;
    @apply mb-4;
}
</style>
