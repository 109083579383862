<script>
    export default {
        props: {
            modelValue: {
                type: String,
                requied: true
            },
            label: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            checked() {
                return this.$parent.checked.includes(this.modelValue);
            }
        }
    }
</script>

<template>
    <label :disabled="disabled" class="custom-checkbox">
        <input
            type="checkbox"
            v-model="$parent.checked"
            :value="modelValue"
            :checked="checked"
            :disabled="$parent.busy || disabled"
        >
        <span class="custom-checkbox__label">{{ label }}</span>
    </label>
</template>
