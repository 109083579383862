<script setup>
import { ref, computed, nextTick, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useAuth } from '@/composable/auth';
import { api } from '@/modules/services';
import QuestionIdeaTable from './QuestionIdeaTable.vue';
import SendIdeasByEmail from '@/components/idea/SendByEmail.vue';
import orderBy from 'lodash/orderBy';
import { RANK_CRITERIA } from '@/constant/challenge';
import { MEDAL } from '@/constant/idea';

const props = defineProps({
    challenge: {
        type: Object,
        required: true
    },
    expandAll: {
        type: Boolean,
        default: false
    }
});

const communityMedals = {
    1: MEDAL.COMMUNITY_GOLD,
    2: MEDAL.COMMUNITY_SILVER,
    3: MEDAL.COMMUNITY_BRONZE,
    4: MEDAL.BLUE,
};

const route = useRoute();
const { auth } = useAuth();

const loading = ref(true);
const response = ref({});
const sharingCatalystPopup = ref(null);
const sharingCommuniyPopup = ref(null);

const innevatorDashboardRoute = computed(() => ({
    name: 'dashboard.innovator',
    params: {
        user: auth.authenticated
            ? auth.user.screen_name
            : '_me_' // fallback
    },
    query: { tab: 'closed-challenges' }
}));

const groupIdeas = () => {
    return response.value.ideas ? {
        picked: response.value.ideas.filter(({ stats }) =>
            stats.catalyst_medal && stats.catalyst_position && !stats.catalyst_hide
        ),
        community: response.value.ideas.filter(({ stats }) =>
            stats.ranked_position && !stats.catalyst_hide
        ),
        insufficient_score: response.value.ideas.filter(({ stats }) =>
            !stats.ranked_position && !stats.catalyst_hide
        ),
    } : {};
};

const computedIdeas = computed(() => {
    const { picked = [], community = [], insufficient_score = [] } = groupIdeas();

    return {
        picked: orderBy(picked, ['stats.catalyst_position']).map(
            idea => ({
                ...idea,
                position: idea.stats.catalyst_position,
                medal: idea.stats.catalyst_medal
            }),
        ),
        community: orderBy(community, ['stats.ranked_position']).map(
            idea => ({
                ...idea,
                position: idea.stats.ranked_position,
                medal: idea.stats.ranked_position <= 3 ? communityMedals[idea.stats.ranked_position] : (
                    idea.stats.ranked_position <= 5 ? communityMedals[4] : null
                )
            })
        ),
        insufficient_score: orderBy(insufficient_score, ['stats.ranked_position']),
    };
});

const showInsufficientScore = computed(() => {
    return response.value.rank_criteria_to_rank !== RANK_CRITERIA.MINIMAL_QUANTITY
        && computedIdeas.value.insufficient_score.length > 0;
});

const fetch = async () => {
    try {
        const query = route.query;

        loading.value = true;
        const { data: responseData } = await api.challenges.ideas(
            props.challenge.slug
        );

        response.value = responseData.data;

        response.value.ideas.forEach((idea) => {
            if (query.idea === idea.id) {
                idea.expand = true;
                idea.highlight = true;
                return;
            }

            if (String(idea.detail).length < 500) {
                idea.expand = true;
            }

            if (props.expandAll) {
                idea.expand = true;
            }
        });

        if (query.idea) {
            // scroll to the idea
            nextTick(() => {
                const idea = document.querySelector(
                    `[data-idea-id="${query.idea}"]`
                );
                if (idea) {
                    idea.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            });
        }
    } catch(e) {
        response.value = {};
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    fetch();
});
</script>

<template>
    <div>
        <h3>
            Check out the crowdsourced ideas &amp; player rankings in this completed challenge!
        </h3>
        <p>
            When a challenge ends, all ideas and participants are revealed. Innovators, don't forget to check for Mentor feedback in your <router-link class="text-black underline" :to="innevatorDashboardRoute">Innovator Dashboard</router-link>.
        </p>

        <div v-if="auth.authenticated" class="flex items-center justify-between mb-3">
            <!-- <ReaderMode :challenge="challenge" /> -->
            <span></span>
            <SendIdeasByEmail :challenge="challenge" />
        </div>

        <template v-if="computedIdeas.picked.length">
            <div class="flex flex-wrap items-center justify-between sm:flex-nowrap gap-3">
                <div>
                    <h3>Catalyst's Top 3 Picks</h3>
                    <p>The question author can choose any idea as their Top 3 Picks.</p>
                </div>
                <div :class="{ disabled: !computedIdeas.picked.length }" class="mb-4">
                    <button aria-label="button" :class="{ 'shadow-active': computedIdeas.picked.length }" type="button" class="relative flex items-center whitespace-nowrap font-medium px-1 py-1 leading-none rounded-md border border-nerve text-nerve bg-white hover:bg-dust-100" @click="() => sharingCatalystPopup.open()">
                        <NIcon as="lightbulb-o1" class="mr-1" />
                        Share Ideas
                        <NIcon as="forward" class="absolute -right-3 filter drop-shadow -top-3 text-xl" />
                    </button>

                    <NSharingPopup :item="challenge" :query="{ winners: 'picked' }" ref="sharingCatalystPopup" />
                </div>
            </div>

            <QuestionIdeaTable
                :ideas="computedIdeas.picked"
                :challenge="challenge"
                :loading="loading"
                empty-message="This Catalyst did not choose any Top 3 picks"
                class="mb-8"
                show-position
            />
        </template>

        <div class="flex flex-wrap items-center justify-between sm:flex-nowrap gap-3">
            <div>
                <h3>Rated by the Community</h3>
                <p>Congratulations everyone! The Top 5 ideas win a share of any challenge prize money.</p>
            </div>
            <div :class="{ disabled: !computedIdeas.community.length }" class="mb-4">
                <button aria-label="button" :class="{ 'shadow-active': computedIdeas.community.length }" type="button" class="relative flex items-center whitespace-nowrap font-medium px-1 py-1 leading-none rounded-md border border-nerve text-nerve bg-white shadow-active hover:bg-dust-100" @click="() => sharingCommuniyPopup.open()">
                    <NIcon as="lightbulb-o1" class="mr-1" />
                    Share Ideas
                    <NIcon as="forward" class="absolute -right-3 filter drop-shadow -top-3 text-xl" />
                </button>

                <NSharingPopup :item="challenge" :query="{ winners: 'community' }" ref="sharingCommuniyPopup" />
            </div>
        </div>

        <QuestionIdeaTable
            :ideas="computedIdeas.community"
            :challenge="challenge"
            :loading="loading"
            class="mb-8"
            show-prize-money
            show-position
            star-medal
        />

        <template v-if="showInsufficientScore">
            <h3 class="mt-5">These ideas received insufficient ratings</h3>
            <p>These ideas are still ranked but their crowd rating scores lack statistical validity.</p>

            <QuestionIdeaTable
                :ideas="computedIdeas.insufficient_score"
                :challenge="challenge"
                class="mb-4"
            />
        </template>
    </div>
</template>
