<script setup>
import TextEditor from '@/components/form/TextEditor.vue';
import RandomImages from './RandomImages.vue';
import { inject, computed } from 'vue';

const form = inject('form');
const board = inject('board');
const context = inject('context');

const tags = computed(() => {
    return context.categorySelected
        ? [context.categorySelected.name] : [];
})
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <div class="flex items center justify-between mb-2">
                <h2 class="challenge-section-heading__title has-icon">
                    <span aria-hidden="true" class="icon icon-lightbulb-o1"></span>
                    <b>
                        <span v-if="board.usingTool('improve-an-idea')">
                            Improve your own Idea (or Message) — Make it Better
                        </span>
                        <span v-else>Add your own idea to test</span>
                    </b>
                </h2>
               <!-- <SkipButton /> -->
            </div>
            <p v-if="board.usingTool('improve-an-idea')" class="challenge-section-heading__description">
                This challenge format is a special version: you have an idea already that you would like to improve and make the idea better by <strong>openly</strong> asking the crowd to improve it.
            </p>
            <p v-else>
                Do you have an idea already? Then add it here to test anonymously in your challenge. See what the crowd thinks of it. 
            </p>
        </div>
        <div class="form-section-wrapper">
            <div class="form-groups">
                <div class="form-group">
                    <div class="quill-editor-wrapper">
                        <span class="quill-editor-title">What is your idea?</span>
                        <TextEditor
                            name="idea_description"
                            v-model="form.fields.idea.description"
                            placeholder="Describe your idea"
                            required
                        />
                    </div>
                    <p class="form-block-help-text has-margin-top has-no-margin-bottom">
                         Write as much as you need to describe your idea.
                    </p>
                </div>
                <div class="form-group">
                    <label class="form-group__label">
                        Title your Idea <span class="form-inline-help-text">Up to 180 characters</span>
                    </label>
                    <NInput
                        name="title"
                        v-model="form.fields.idea.title"
                        placeholder="Make it interesting!"
                        maxlength="180"
                    ></NInput>
                    <p class="form-block-help-text has-margin-top has-no-margin-bottom">
                        Make your title clear, clever &amp; as interesting as possible to attract attention from the community. It should summarize your idea concisely.
                    </p>
                </div>
            </div>
        </div>
        <div class="form-section-wrapper">
            <p
                v-show="!board.usingTool()"
                class="challenge-section-heading__description is-color-red mb-3"
            >
                <span class="tag is-red">Caution</span>
                If you attach an image to your idea; <b>do not use that same image</b> for your Question, to preserve your idea’s anonymity!
            </p>
            <div class="form-groups">
                <div class="form-group">
                    <label class="form-group__label">
                        Add an Image <span class="form-inline-help-text">Optional; max upload size 20mb.</span>
                    </label>
                    
                    <NFile name="idea-image" v-model="form.fields.idea.primary_image" />
                    
                    <RandomImages
                        v-model="form.fields.idea.primary_image"
                        :tags="tags"
                        :prefetch="!!tags.length"
                        class="mt-4"
                    />

                    <hr v-show="form.fields.idea.primary_image">

                    <div v-if="form.fields.idea.primary_image" class="image-preview__file mt-4">
                        <img :src="form.fields.idea.primary_image" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="info-box">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p v-if="! board.usingTool()" class="info-box__content">
                <b>Tip</b>. There’s 3 benefits to adding your own idea: this will help prime your challenge and make it more inviting to other innovators (there’s already an idea to check out); it is a way to test your idea anonymously, which is a rigorous way to vet your idea against other people’s ideas and maybe gain valuable insights. Lastly, you can gain more feedback via the rating and mentoring by others. To prevent the players from guessing that the first idea is yours, we'll insert it at a random time within other ideas.
            </p>
            <p v-else-if="board.usingTool('test-an-idea')" class="info-box__content">
                <strong>VALIDATE: Double-blind challenge.</strong> Your idea will be hidden in the crowd’s ideas, and ranked in a rigorous double-blind way. You think you know what you want, but you need to be certain it’s the best option. This is the best way to test your idea before putting it out the world. You’ll get a more accurate judgement of your idea, and possibly more divergent ideas. Example use case: you’re running a $5k ad on the radio, you should do a blind test of your message, to gauge the impact more realisticly.
            </p>
            <p v-else-if="board.usingTool('improve-an-idea')" class="info-box__content">
                <strong>IMPROVE: This is an open “Make it Better” Challenge.</strong> Your idea will be posted so that people know that this is your idea (open challenge). They will make it better by offering suggestions, and the best suggestions will be ranked by the crowd for you to choose. (By showing them your idea, people will know where you’re heading with it, so you might get less divergent options.) You have an idea, but is it the best it could be? Let our community of innovators make this idea better. You know what you want, you don’t need more ideas, you just need a quick way to make an idea better. This assumes you’ve reached a local maximum. You’re not trying to hide your idea in the crowd’s.
            </p>
        </div>
    </div>
</template>
