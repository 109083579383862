<script>
import Sidebar from "./partials/Sidebar.vue";

export default {
    components: {
        Sidebar,
    },

    data() {
        return {
            type: "boxedWithoutGameDash",
        };
    },
};
</script>

<template>
    <div class="container mx-auto">
        <div class="flex pt-3 pb-3">
            <div class="w-full">
                <div class="flex gap-4 custom-page-row">
                    <div class="custom-page-row__sidebar">
                        <sidebar></sidebar>
                    </div>
                    <div class="custom-page-row__content w-full">
                        <RouterView v-slot="{ Component }">
                            <template v-if="Component">
                                <component :is="Component" />
                            </template>
                            <div v-else
                                class="w-full h-screen bg-gray-100 rounded-md flex items-center justify-center animate-pulse">
                            </div>
                        </RouterView>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
