import { useBreakpoints, createSharedComposable, useWindowSize, useMediaQuery } from '@vueuse/core';
import { reactive, watch } from 'vue';

const responsive = reactive({
  mobile: false,
  sm: false,
  sme: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  xxxl: false,
  dimension: {
    width: 0,
    height: 0,
  },
})

export const useResponsive = createSharedComposable(() => {
  const { width, height } = useWindowSize();
  const b = useBreakpoints({
    xs: 390, sm: 768, md: 960, lg: 1200, xl: 1280, '2xl': 1280
  })

  watch(() => [width, height], () => {
    responsive.mobile = b.smallerOrEqual('xs');
    responsive.sm = b.smallerOrEqual('sm');
    responsive.md = b.smallerOrEqual('md');
    responsive.lg = b.smallerOrEqual('lg');
    responsive.xl = b.smallerOrEqual('xl');
    responsive.xxl = b.smallerOrEqual('xxl');
    responsive.xxxl = b.smallerOrEqual('xxxl');
    responsive.dimension.width = width.value;
    responsive.dimension.height = height.value;
  }, { immediate: true });

  return responsive;
})

const MOBILE_BREAKPOINT = 768

export const isMobile = () => {
  const mobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`)

  return mobile;
}
