<script>
    export default {
        inject: ['form'],

        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            implicit: {
                type: Boolean,
                default: false
            },
        },
    }
</script>

<template>
    <div>
        <div class="tabs__content-helper">
            <p class="form-block-help-text">
                <b>Why is your question interesting?</b><br>
                Describe why this problem is important to you and to others. Why is it an urgently needed, or a pervasive problem? Make it personal by telling a compelling story: why would people trade <b>time, money  and attention</b> for a solution? NOTE: It is also important to think about the entire ecosystem and understand why some stakeholders would be disincentivized against it.
            </p>
            <div class="form-groups">
                <div class="form-group">
                    <h2 class="form-group__title is-blue">Now think it through&hellip; you first wrote this:</h2>
                    <NTextarea size="small" v-model="form.fields.challenge.assumption_why" :disabled="disabled || !!form.fields.challenge.slug" />
                </div>
            </div>
            <div class="form-groups mt-5">
                <div class="form-group">
                    <label class="form-group__title is-blue">“Why” reasons for you, personally:</label>
                    <div v-for="(reason, index) in form.fields.deep_dives.why.personal_reasons" :key="index" class="repeater-item mb-6">
                        <NTextarea
                            v-model="form.fields.deep_dives.why.personal_reasons[index].reason"
                            size="small"
                            placeholder="Describe your reasons"
                            :disabled="disabled"
                         />
                        <div class="grid grid-cols-12 mt-3">
                            <div class="col-span-12 lg:col-span-10 lg:col-start-2">
                                <p><i>For you, how strong is your incentive for this possible solution?</i></p>
                                <NRange
                                    min="1"
                                    max="10"
                                    v-model="form.fields.deep_dives.why.personal_reasons[index].strength"
                                    :disabled="disabled"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12">
                        <div class="col-span-12 lg:col-span-10 lg:col-start-2">
                            <button aria-label="button"
                                v-if="!disabled"
                                type="button"
                                class="btn is-small is-white mt-3"
                                @click.prevent="form.fields.deep_dives.why.personal_reasons.push({ reason: null, strength: 5 })"
                            >
                                <span aria-hidden="true" class="btn__icon is-left icon-plus-square-solid"></span>
                                Add another
                            </button>
                            <p class="form-block-help-text mt-3">
                                <i>
                                    If it’s lower than a 5 consider rewriting your challenge to address a different problem. You need to assess whether something is truly worth your time and talents.
                                </i>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-group__title is-blue">“Why” reasons for each of your Stakeholders:</label>
                    <div v-for="(reason, index) in form.fields.deep_dives.why.stakeholder_reasons" :key="index" class="repeater-item">
                        <NInput v-model="form.fields.deep_dives.why.stakeholder_reasons[index].reason" placeholder="Describe a stakeholder" :disabled="disabled"></NInput>
                        <div class="grid grid-cols-12 mt-3">
                            <div class="col-span-12 lg:col-span-10 lg:col-start-2">
                                <p><i>For this stakeholder, how strong is their incentive for or against this possible solution?</i></p>
                                <NRange v-model="form.fields.deep_dives.why.stakeholder_reasons[index].strength" min="-5" max="5" :disabled="disabled" />
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 w-full">
                        <div class="col-span-12 lg:col-span-10 lg:col-start-2">
                            <button aria-label="button"
                                v-if="!disabled"
                                type="button"
                                class="btn is-small is-white mt-3"
                                @click.prevent="form.fields.deep_dives.why.stakeholder_reasons.push({ reason: null, strength: 0 })"
                            >
                                <span aria-hidden="true" class="btn__icon is-left icon-plus-square-solid whitespace-nowrap"></span>
                                Add another
                            </button>
                        </div>
                    </div>
                    <p class="form-block-help-text mt-3">
                        If any of your “stakeholders” are ranked lower than <b>0</b>, consider adding prizes or money to entice more innovators, or rewrite your question. If any of your stakeholders are in the negative, and have a lot of leverage or influence, consider reframing the problem to address them specifically. Start with the one strongly against the innovation or else they’ll actively discourage any solution that doesn’t include them. Reframe based on incentives and motivations. If you found a negative stakeholder, you might want to reframe your question.
                    </p>
                </div>
                <div class="form-group">
                    <label class="form-group__title is-blue">Anthropolgy (Experiments)</label>
                    <p class="form-block-help-text">
                        Have you talked with other people who think this is also a problem? Does it affect them directly? Describe your sample and/or prior experiments. Describe your evidence that this is urgent and compelling. Asking people what they <em>want</em> might not give insights, usually, but people usually can often be more accurate about what they don’t like.
                    </p>
                    <NTextarea placeholder="Describe your evidence" v-model="form.fields.deep_dives.why.anthropology_experiments" :disabled="disabled" />
                </div>
            </div>
        </div>
        <div v-if="!implicit" class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <b>Note:</b> these worksheets are helpful to the community of innovators to help them understand your thought processes and your point of view. They can see your initial “exploratory” question and your honed final question.
            </p>
        </div>
    </div>
</template>
