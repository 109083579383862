import { reactive, toRefs } from 'vue'

const state = reactive({
  title: null,
  message: null,
  payload: null,
  textConfirm: null,
  textCancel: null,
  hideCancel: false,
  hideConfirm: false,
  hideOverlay: false,
  opened: false,
  onConfirm: () => {},
})

export const useNotifier = () => {
  const open = ({ title, message, payload, onConfirm, textCancel = 'Cancel', textConfirm = 'Continue', hideCancel, hideConfirm, hideOverlay } = {}) => {
    state.title = title
    state.message = message
    state.textConfirm = textConfirm
    state.textCancel = textCancel
    state.payload = payload
    state.opened = true
    state.hideCancel = !!hideCancel
    state.hideConfirm = !!hideConfirm
    state.hideOverlay = !!hideOverlay
    state.onConfirm = onConfirm || (() => {})
  }

  const close = () => {
    state.title = null
    state.message = null
    state.textConfirm = null
    state.textCancel = null
    state.payload = null
    state.opened = false
    state.hideCancel = false
    state.hideConfirm = false
    state.hideOverlay = false
    state.onConfirm = () => {}
  }

  return { open, close, ...toRefs(state) }
}
