<script setup>
import NModal from '@/components/NModal.vue';
import GameDash from '@/components/game-dash/GameDash.vue';
import { api } from '@/modules/services';
import { useGameDash } from '@/composable/gamedash';
import { ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { usePreferences } from '@/composable/preferences';

const modal = ref(null);
const route = useRoute();
const hasUpdates = ref(false);
const { brain, role } = useGameDash();

const emit = defineEmits(['close', 'show']);

const handleClose = () => {
    modal.value?.close();
}

const handleShow = () => {
    emit('show');

    if (role.value !== 'overall') {
        return false;
    }

    api.me.savePreferences({
        'gamedash': {
            'shared_state': {
                'gamedash_score': brain.value?.score,
            }
        }
    });

    hasUpdates.value = false;
}

const { sync } = usePreferences();
const score = sync('user.gamedash.shared_state.gamedash_score');

watch(route, () => {
    handleClose();
});

watchEffect(() => {
    if (role.value !== 'overall') {
        return false;
    }

    if (!brain.value?.score) {
        return;
    }
    
    hasUpdates.value = brain.value?.score && score.value && score.value !== brain.value.score;
});

defineExpose({
    open: () => {
        modal.value?.open();
    },
    close,
});
</script>

<template>
    <NModal ref="modal" class="w-full sm:max-w-xl" @show="handleShow" @close="_ => emit('close')">
        <template #activator="{ on, close: handleClose }">
            <slot name="activator" :on="on" :hasUpdates="hasUpdates" />
        </template>

        <GameDash>
            <template #header>
                <h2 class="game-dash-header m-0">
                    <span class="game-dash-header__title">
                        {{ $t('component.game-dash.title') }}
                    </span>

                    <button type="button" aria-label="button" class="game-dash-header__btn" @click="handleClose">
                        <NIcon as="close-circle" />
                    </button>
                </h2>
            </template>
        </GameDash>
    </NModal>
</template>