export default requester => {
    return {
        /**
         * Get the completed challenges for the home page.
         *
         * @param  {object}  params
         * @return {object}
         * @deprecated
         */
        completedChallenges(params = {}) {
            return requester.get('/widgets/completed-challenges', { params });
        },

        /**
         * Get the open challenges for the home page.
         *
         * @param {Object} params
         * @return {object}
         * @deprecated
         */
        openChallenges(params = {}) {
            return requester.get('/widgets/challenges-homepage', { params });
        },

        /**
         * Get the open challenges for the home page.
         *
         * @param {String} platform
         * @param {Object} params
         * @return {object}
         */
        challenges(platform, params = {}, config = {}) {
            return requester.get(`/${platform}/challenges-homepage`, { ...config, params });
        },

        /**
         * Get the olympiad for the home page.
         *
         * @param  {object}  params
         * @return {object}
         */
        olympiads(target, params = {}) {
            return requester.get(`/widgets/olympiads/${target}`, { params });
        },

        /**
         * Get the categories for the home page.
         *
         * @param  {object}  params
         * @return {object}
         */
        categories(params = {}) {
            return requester.get('/widgets/categories', { params });
        },
    };
}
