<script setup>
import ChallengeTitle from '@/components/challenge/Title.vue';
import ChallengeImage from '@/components/challenge/Image.vue';
import { date } from '@bignerve/ui-utils';

const props = defineProps({
    challenge: {
        type: Object,
        required: true
    }
});
</script>

<script>
export default {
    name: 'SuggestionBoxCard',
}
</script>

<template>
    <article class="challenge-card is-full-height relative">
        <ChallengeImage
            :challenge="challenge"
            :to="{ name: 'challenges.show', params: { challenge: challenge.slug } }"
        />
        <div class="challenge-card__body"> 
            <NPlayerDetails :user="challenge.catalyst" :clamp="false" />

            <h3 class="challenge-card__title no-swiping">
                <ChallengeTitle
                    :challenge="challenge"
                    :to="{ name: 'challenges.show', params: { challenge: challenge.slug } }">
                </ChallengeTitle>
            </h3>

            <div class="flex items-center justify-between py-2 mt-auto">
                <template v-if="challenge.is_closed">
                    <span class="challenge-card__finished-meta-status">
                        Suggestion Box Closed <img class="icon" src="@/assets/icons/stopwatch.svg" alt="">
                    </span>
                </template>
                <span v-else class="text-sm">
                    <b class="text-dust-700">Time Remaining:</b> {{ date(challenge.duration_end, 'human', { suffix: false }) }}
                </span>
            </div>
            <div class="challenge-card__footer">
                <router-link
                    :to="{ name: 'challenges.show', params: { challenge: challenge.slug }, query: { tab: 'ideas' } }"
                    class="challenge-card__footer-item"
                >
                    <NIcon as="lightbulb-o1" left />
                    Ideas ({{ challenge.unlocked_ideas }})
                </router-link>

                <button aria-label="button" class="btn has-no-style" @click.prevent="$bus('sharing:popup', challenge)">
                    <NIcon as="share-square-solid"/>
                </button>
            </div>
        </div>
    </article>
</template>
