<script>
import Validatable from '@/mixins/Validatable';

export default {
    name: 'Field',
    
    mixins: [Validatable],

    inheritAttrs: false,

    props: ['modelValue'],
}
</script>

<template>
    <div class="form-group__helper">
        <label v-if="label" :for="name" class="form-group__label" style="display: block;">{{ label }}</label>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>

        <slot :error="error" :invalid="invalid" :clearErrors="clearErrors" />

        <span v-if="invalid" class="form-group__error">{{ error }}</span>
    </div>
</template>