<script setup>
import { api } from '@/modules/services';
import { ref } from 'vue';

const props = defineProps({
    handle: {
        type: String,
        required: true,
    },
});

const loading = ref(false);
const metas = ref([]);

const fetch = async () => {
    try {
        loading.value = true;

        const { data: response } = await api.game.awardMedalsMeta(props.handle);

        metas.value = response.data.metas.reduce((acc, item) => {
            acc[item.name] = item.total;

            return acc;
        }, {});
    } finally {
        loading.value = false;
    }
}

fetch();
</script>

<template>
    <slot :metas="metas" :loading="loading"></slot>
</template>