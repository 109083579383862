<script>
    export default {
        props: {
            modelValue: {
                type: [String, Number, Boolean],
                default: ''
            },
            label: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            checked() {
                return this.modelValue === this.$parent.modelValue;
            }
        },

        methods: {
            update(event) {
                if (event.target.checked) {
                    this.$parent.update(event.target.value);
                }
            }
        }
    }
</script>

<template>
    <label :disabled="disabled" class="custom-radio">
        <input
            type="radio"
            :value="modelValue"
            :name="$parent.name"
            :checked="checked"
            :disabled="$parent.busy || disabled"
            :class="{ 'bg-dust-100': disabled }"
            @change="update"
        >
        <span class="custom-radio__label">{{ label }}</span>
    </label>
</template>
