import { createPlausible } from 'v-plausible/vue';

const plausible = createPlausible({
    init: {
      domain: 'bignerve.com',
      trackLocalhost: true,
    },
    settings: {
      enableAutoOutboundTracking: false,
      enableAutoPageviews: true,
    },
    partytown: false,
});

export const install = ({ app }) => {
    app.use(plausible);
};
