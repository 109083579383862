<script setup>
import { ref } from 'vue'

const expanded = ref(false)

const toggleExpand = () => {
    expanded.value = !expanded.value
    if (expanded.value) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = 'auto'
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>

<template>
    <div class="relative max-w-md mx-auto group" >
        <slot :toggleExpand="toggleExpand">
            <img v-bind="$attrs" class="w-full h-auto rounded-lg shadow-md" :class="{ 'cursor-zoom-in': !expanded }" @click="toggleExpand" />
        </slot>

        <button @click="toggleExpand"
            type="button"
            class="absolute top-2 right-2 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out focus:outline-none bg-black bg-opacity-50 h-8 w-8 flex items-center justify-center rounded-full">
            <NIcon as="expand" v-if="!expanded" />
            <NIcon as="contract" v-else />
        </button>

        <Transition name="fade">
            <div v-if="expanded" class="fixed inset-0 z-[2000] flex items-center justify-center bg-black bg-opacity-90"
                @click.self="toggleExpand">
                <img v-bind="$attrs" class="max-w-full max-h-[85dvh] object-contain" />
                <button @click="toggleExpand" type="button"
                    class="absolute top-16 right-16 text-white hover:text-gray-300 focus:outline-none">
                    <NIcon as="close-circle" class="text-xl" />
                </button>
            </div>
        </Transition>
    </div>
</template>
