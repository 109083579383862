<script>
import { ref, inject, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';

const useRouter = () => {
    const { proxy } = getCurrentInstance();
    return proxy.$router;
}

export default {
    name: 'Guide',

    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: 'Take a Tour – Learn more about the BigNerve idea tournaments'
        },
        onNext: {
            type: Function,
        },
        to: {
            type: Object,
        },
        textNext: {
            type: String,
            default: 'Next',
        },
        handlerNext: {
            type: Function,
        },
        transition: {
            type: Object,
            default: () => ({}),
        },
        closeOutside: {
            type: Boolean,
            default: false,
        },
        closable: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['next', 'open'],

    setup(props, { emit }) {
        const router = useRouter();
        const element = ref(null);
        const api = inject('tour');
        const isLast = inject('is-last');
        const index = computed(() => api.guides.value.indexOf(api.guides.value.find(guide => guide.id === props.id)));
        const active = computed(() => index.value > -1 && api.activeIndex.value === index.value);

        onMounted(() => {
            api.registerGuide(props.id, element);
        });
        onUnmounted(() => {
            api.unregisterGuide(props.id);
        });

        const next = async () => {
            if (props.to) {
                api.exit();
                await router.push(props.to);
            } else if (props.handlerNext) {
                api.exit();
                await props.handlerNext();
            } else {
                await api.nextGuide();
            }

            emit('next');
        }

        watch(active, (opened) => {
            if (opened) {
                emit('open', { isLast: isLast.value })
            }
        })

        const handlerCloseOutside = () => {
            if (props.closeOutside) {
                api.exit();
            }
        }

        return { element, active, index, api, next, isLast, handlerCloseOutside };
    },
}
</script>

<template>
    <transition v-bind="{ enterActiveClass: 'swap-enter-active', enterClass: 'swap-enter', leaveActiveClass: 'swap-leave-active', leaveToClass: 'swap-leave-to', ...transition }" mode="out-in">
        <div v-if="active" v-click-outside="{ handler: handlerCloseOutside }" :style="!$responsive.sm ? 'left: calc(50% - 460px)' : null" ref="element" class="bg-white border shadow-high p-2 rounded-bl-lg z-[10001] rounded-br-lg absolute mx-3 sm:w-[57.5rem] inline-flex flex-col">
            <div class="flex items-center justify-between px-4 py-2 bg-nerve text-white">
                <div class="flex items-center">
                    <img src="/img/guide-map.svg" :alt="title" class="w-8 h-8 mr-2" />
                    <span class="ml-2 font-bold text-lg">
                        {{ title }}
                    </span>
                </div>
                <button v-if="closable" aria-label="button" type="button" class="h-8 w-8 rounded-full flex items-center justify-center hover:bg-nerve-600" @click="api.close">
                    <NIcon as="times-circle" />
                </button>
            </div>
            <div class="p-4 sm:p-8 bg-dust-100">
                <slot :active="active" :next="next" />

                <div class="flex items-center justify-center gap-2">
                    <slot name="actions" :isLast="isLast" :next="next" :exit="() => api.exit()">
                        <template v-if="!isLast" >
                            <button aria-label="button" type="button" class="px-4 sm:px-6 py-1 flex items-center text-white bg-nerve rounded-md hover:bg-nerve-600" @click="next">
                                <img src="/img/guide-map.svg" alt="textNext" class="w-5 h-5 mr-2" />
                                {{ textNext }}
                            </button>

                            <button aria-label="button" type="button" class="px-4 py-1 flex items-center text-nerve bg-nerve-100 border-nerve-200 border rounded-md hover:bg-nerve-200" @click.prevent="api.close">
                                <NIcon as="times-circle" class="mr-1" />Close
                            </button>
                        </template>
                        <button aria-label="button" v-else type="button" class="px-4 py-1 flex items-center text-white bg-nerve rounded-md hover:bg-nerve-600" @click="() => api.exit()">
                            <img src="/img/guide-map.svg" alt="Finished" class="w-5 h-5 mr-2" />
                            Finished!
                        </button>
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>
