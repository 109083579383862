import { toRef, useStorage } from '@vueuse/core';
import { api } from '@/modules/services';
import { ref, computed, isRef, watch } from 'vue';
import { useElementVisibility, useTimeoutFn } from '@vueuse/core';

const stats = useStorage('discussions:messages:stats', {});
const drafts = useStorage('discussions:messages:drafts', {});
const viewed = useStorage('discussions:messages:viewed', {});

export const useDiscussionStats = ({ context, id, immediate } = {}) => {
    const computedId = isRef(id) ? id : toRef(id);
    const key = computed(() => `${context}:${computedId.value}`);
    const cache = computed({
        get: () => stats.value[key.value] ?? 0,
        set: (value) => {
            stats.value[key.value] = value;
        },
    });
    const participating = ref(false);
    const mine = ref(0);
    const total = ref(0);
    const unread = ref(0);
    const loading = ref(false);
    const hasMessages = computed(() => total.value > 0);
    const replied = computed(() => mine.value !== total.value)

    const update = async () => {
        if (loading.value || !computedId.value) return;

        loading.value = true;

        const { data: response } = await api.discussions.meta(context, computedId.value);
    
        {   // update stats
            unread.value = response.data.message_unread - cache.value;
            cache.value = response.data.message_unread;
            total.value = response.data.message_total;
            mine.value = response.data.message_mine;
            participating.value = response.data.is_participant;
        }

        loading.value = false;
    
        return {
            unread: unread.value,
            hasMessages: total.value > 0,
            total: total.value,
            mine: mine.value,
            participating: participating.value,
            replied: replied.value,
        };
    };

    watch(computedId, () => {
        update();
    }, { immediate })

    return { total, unread, replied, mine, participating, hasMessages, loading, update };
}

export const useDraft = ({ context, id } = {}) => {
    const key = `${context}:${id}`;
    const content = computed({
        get: () => drafts.value[key] ?? '',
        set: (value) => {
            drafts.value[key] = value || null;
        },
    });

    return { content };
}

export const useMessageViewed = ({ messageId, target }) => {
    const targetEl = isRef(target) ? target : toRef(target);

    if (!messageId || viewed.value[messageId]) {
        return { targetEl };
    }

    const targetIsVisible = useElementVisibility(targetEl);

    const { start, stop } = useTimeoutFn(() => {
        api.discussions.viewed(messageId).catch(console.log);

        viewed.value[messageId] = new Date().toISOString();
    }, 6000, { immediate: false });

    watch(targetIsVisible, (value) => {
        value ? start() : stop();
    });

    return { targetEl };
}
