<script setup>
import NExperiencePoints from '@/components/game/brain/ExperiencePoints.vue';
import NBubble from '@/components/NBubble.vue';
import NDataForm from '@/components/form/Form.vue';
import NMarkdown from '@/components/NMarkdown.vue';
import FeatureFlag from '@/components/FeatureFlag.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import { createReusableTemplate } from '@vueuse/core'
import { stringSimilarity } from 'string-similarity-js';
import { computed, reactive, ref, toValue, watch } from 'vue';
import { isUseless, cn } from '@/utils/Helpers';
import { toRaw } from '@/mixins/Markdown';
import { debounce } from 'lodash';

const ideaEditorToolbar = [
	['bold', 'italic', 'underline', 'strike'],
	['link', { list: 'ordered' }, { list: 'bullet' }],
];

const emit = defineEmits(['update:mentorComment']);

const props = defineProps({
    challenge: {
        type: Object,
        required: true,
    },
	mentorComment: {
		type: Object,
		default: () => ({}),
	},
    disabled: {
        type: Boolean,
        default: false,
    },
});

const fields = reactive({
    what_liked: props.mentorComment.what_liked,
    what_wish: props.mentorComment.what_wish,
});
const preview = reactive({
    what_liked: props.mentorComment.what_liked,
    what_wish: props.mentorComment.what_wish,
});
const editing = ref(false);
const showTip = ref(true);
const whatWishAdvice = ref(null);
const whatLikedEl = ref(null);

const showAdvice = reactive({
	what_liked: false,
	what_wish: false,
});

const useless = computed(() => ({
	what_liked: fields.what_liked ? isUseless(fields.what_liked) : false,
	what_wish: fields.what_wish ? isUseless(fields.what_wish) : false,
}));

const validate = computed(() => ({
	what_liked: fields.what_liked
		? toRaw(fields.what_liked).length >= minLength.what_liked && !useless.what_liked
		: true,
	what_wish: fields.what_wish
		? toRaw(fields.what_wish).length >= minLength.what_wish && !useless.what_wish
		: true,
	similarComments: stringSimilarity(toRaw(fields.what_liked), toRaw(fields.what_wish)) >= 0.8,
}));

const whatLikedFilled = computed(() => toRaw(fields.what_liked).length >= minLength.what_liked);
const whatWishFilled = computed(() => toRaw(fields.what_wish).length >= minLength.what_wish);

const minLength = {
	what_liked: 30,
	what_wish: 50,
};

const toggleVisibleTip = debounce(function () {
	showTip.value = false;
}, 5000);

const toggleAdivice = debounce(function ({ what_liked, what_wish }) {
	showAdvice.what_liked = what_liked && fields.what_liked;
	showAdvice.what_wish = what_wish && fields.what_wish;
}, 2000);

watch(useless, (newValue) => {
    toggleAdivice(newValue);
});

const onSuccess = () => {
    preview.value = toValue(fields);

    emit('update:mentorComment', {
        ...props.mentorComment,
        ...fields,
    });

    editing.value = false;
};

const [DefineMentoringCard, MentoringCard] = createReusableTemplate()
</script>

<template>
    <DefineMentoringCard v-slot="{ $slots }">
        <div class="border-4 bg-mentor border-mentor rounded-lg">
            <div class="h-4"></div>
            <div class="px-6 pt-4 pb-8 bg-white rounded-lg">
                <component :is="$slots.default" />
            </div>
        </div>
    </DefineMentoringCard>

    <NDataForm
        v-if="editing"
        :action="`/challenges/${challenge.slug}/catalyst/mentor_comment/${mentorComment.id}`"
        method="put"
        :data="fields"
        @success="onSuccess"
        :class="cn('flex flex-col gap-4', disabled && 'disabled')"
        #default="form"
    >
        <NAlert type="danger" :closable="true" :open="form.errors.any()">
            {{ form.errors.get('*') || 'An error occured, please check your fields and try again.' }}
        </NAlert>

        <MentoringCard>
            <h2 class="text-mentor mb-3">
                <NIcon as="mentor" class="text-mentor text-xl mr-2" />
                <span class="font-bold text-mentor">Mentor this person and earn more Neurons!</span>
            </h2>

            <p class="text-mentor-400 mb-4">
                Help the innovator get better at thinking through their ideas. Mentor only the ideas you think you can help improve.
            </p>

            <div class="form-group is-rating mt-4">
                <div class="flex flex-wrap sm:flex-nowrap justify-between items-center mb-2 w-full">
                    <label class="font-bold text-lg mb-0">1. What do you like about this Idea?</label>

                    <NExperiencePoints fqdn="mentor.IdeaMentored.MentoredIdeaLike" #="{ points }" >
                        <NBubble v-if="whatLikedFilled && points" color="mentor" class="-mb-2" :animated="true" >
                            +{{ points }} Neurons
                        </NBubble>
                    </NExperiencePoints>
                </div>
                <NAdvice
                    :open="!!showAdvice.what_liked"
                    :placement="$responsive.sm ? 'bottom' : 'left'"
                    class="w-full"
                    name="whatLikedEl"
                    @closed="() => (whatLikedEl?.editor.root.focus(), (showAdvice.what_liked = false))"
                >
                    <template #content>
                        <h3 class="mb-3">A word of advice!</h3>
                        <p>
                            This comment must be encouraging; you used words that do not appear to be positive. Do not write “You
                            tried” or a negative comment here.
                        </p>
                        <p class="mb-0">
                            Innovators and other mentors will down-rate low-quality mentor comments, affecting your points.
                        </p>
                    </template>
                    <div class="quill-editor-wrapper">
                        <span class="quill-editor-title">
                            <span
                                :class="
                                    fields.what_liked && !whatLikedFilled
                                        ? 'text-red-500'
                                        : 'text-dust-600'
                                "
                                class="font-normal text-sm ml-1 inline-block"
                            >
                                ({{ minLength.what_liked }} chars min)
                            </span>
                        </span>
                        <TextEditor
                            v-model="fields.what_liked"
                            v-visible="toggleVisibleTip"
                            :force-error="useless.what_liked || validate.similarComments"
                            :toolbar="ideaEditorToolbar"
                            ref="what_liked"
                            name="what_liked"
                            size="medium"
                            class="mb-3 !bg-mentor-200"
                            placeholder="Be encouraging..."
                        />
                    </div>
                </NAdvice>
                <NTip
                    :open="showTip"
                    placement="bottom"
                    title="New Feature! Mentor Rating Game"
                    description="If you mentor this innovator's idea, you can join in a Mentor Rating Game. You will see all the Mentoring comments on this idea, and rate them on helpfulness — earn more points and help others improve, too!"
                    target="challenge:rating-flow:what_liked"
                >
                    <div class="w-[50dvw] sm:w-[25rem]"></div>
                </NTip>
            </div>
            <div class="form-group is-rating mt-4">
                <div class="flex flex-wrap sm:flex-nowrap justify-between items-center mb-1 w-full">
                    <label class="font-bold text-lg mb-0">2. How would you nurture this idea along?</label>
                    <NExperiencePoints
                        fqdn="mentor.IdeaMentored.MentoredIdeaImprove"
                        #="{ points }"
                    >
                        <NBubble
                            v-if="fields.what_wish && whatWishFilled && points"
                            color="mentor"
                            :animated="true"
                        >
                            +{{ points }} Neurons
                        </NBubble>
                    </NExperiencePoints>
                </div>
                <p class="is-italic text-dust-600">
                    You must give an encouraging comment above before you can give a constructive comment here.
                </p>
                <NAdvice
                    :open="!!showAdvice.what_wish"
                    :placement="$responsive.sm ? 'bottom' : 'left'"
                    class="w-full"
                    name="whatWishAdvice"
                    @closed="() => (whatWishAdvice?.editor.root.focus(), (showAdvice.what_wish = false))"
                >
                    <template #content>
                        <h3 class="mb-3">A word of advice!</h3>
                        <p>
                            This comment must be constructive; you used words that do not appear to help improve the idea. Do not
                            write “Nothing to improve” or similar!
                        </p>
                        <p class="mb-0">
                            Innovators and other mentors will down-rate low-quality mentor comments, affecting your points.
                        </p>
                    </template>
                    <div class="quill-editor-wrapper">
                        <span class="quill-editor-title">
                            <span
                                :class="
                                    fields.what_wish && !whatWishFilled
                                        ? 'text-red-500'
                                        : 'text-dust-600'
                                "
                                class="font-normal text-sm ml-1 inline-block"
                            >
                                ({{ minLength.what_wish }} chars min)
                            </span>
                        </span>
                        <TextEditor
                            v-model="fields.what_wish"
                            :disabled="!whatLikedFilled"
                            :class="{ '!bg-dust-200': !whatLikedFilled }"
                            :force-error="useless.what_wish || validate.similarComments"
                            :toolbar="ideaEditorToolbar"
                            name="what_wish"
                            ref="what_wish"
                            class="mb-3 !bg-mentor-200"
                            size="left"
                            placeholder="Be specific… this is the place for constructive feedback, if you have any."
                        />
                    </div>
                </NAdvice>

                <p class="font-normal text-dust-600">
                    Make sure your “helpful” comment is <b>truly constructive</b> or you'll get a low rating on this comment by
                    the Innovator or other Mentors.
                </p>
            </div>
        </MentoringCard>

        <NAlert :open="validate.similarComments" type="danger" >
            Your mentoring comments are too similar. Please make them more unique.
        </NAlert>

        <div class="mt-4 mb-5 flex flex-col items-center">
            <button aria-label="button" type="submit" class="btn inline-flex items-baseline justify-center leading-tingh is-mentor px-4 py-2 mb-3 min-w-[10rem]" :disabled="validate.similarComments || !validate.what_liked || !validate.what_wish || form.busy">
                <NIcon as="mentor" class="text-lg" left />
                {{ form.busy ? 'Saving...' : 'Save and Send' }}
            </button>
        </div>
    </NDataForm>

    <MentoringCard v-else>
        <div v-if="mentorComment.id" class="flex flex-col gap-6">
            <h2 class="text-mentor mb-0">
                <NIcon
                    as="mentor"
                    class="text-mentor text-xl mr-2"
                />
                <span class="font-bold text-mentor">Your mentoring</span>
            </h2>

            <p class="text-mentor-400 mb-0">
                Your mentoring helps the innovator get better at thinking through their ideas. You can edit your comments up until the innovator replies.
            </p>

            <div v-if="preview.what_liked" class="p-4 rounded-lg border flex flex-col gap-2">
                <label class="font-bold text-dust-800">1. What do you like about this Idea?</label>

                <NMarkdown :code="preview.what_liked" />
            </div>

            <div v-if="preview.what_wish" class="p-4 rounded-lg border flex flex-col gap-2">
                <label class="font-bold text-dust-800">2. How would you nurture this Idea along?</label>

                <NMarkdown :code="preview.what_wish" />
            </div>
        </div>
        <div v-else class="flex flex-col items-center py-20 ">
            <h2>You have mentored this idea</h2>
            <p class="text-dust-600 mb-0">
                View your mentorings on
                <RouterLink
                    :to="{ name: 'dashboard.mentoring' }"
                    class="text-dust-800 underline hover:text-dust-900 hover:underline"
                    >Mentor Dashboard</RouterLink
                >
            </p>
        </div>
    </MentoringCard>

    <FeatureFlag flag="sb-ama-request-extension">
        <div v-if="!editing" class="mt-4 mb-5 flex flex-col items-center">
            <slot name="activator" :on="{ click: () => editing = true }">
                <button aria-label="button" type="button" class="btn inline-flex items-baseline justify-center leading-tingh is-mentor px-4 py-2 mb-3 min-w-[10rem]" @click="editing = true">
                    <NIcon as="pencil-alt-solid" class="text-lg" left />
                    Edit Mentoring
                </button>
            </slot>
        </div>
    </FeatureFlag>
</template>