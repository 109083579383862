import kebabCase from 'lodash/kebabCase';

export default {
    watch: {
        isOpen(n, o) {
            n ? this.$emit('open') : this.$emit('close');
        }
    },

    emits: ['open', 'close'],

    computed: {
        slug() {
            return this.id || kebabCase(this.title)
        },
        isOpen() {
            return this.$parent.selected === this.slug;
        }
    }
}
