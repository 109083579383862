<script>
import { ref, computed, watch } from 'vue';
import { whenever, useMagicKeys, useVModel, syncRef, useScrollLock } from '@vueuse/core';

export default {
	name: 'Modal',
	props: {
		modelValue: Boolean,
		closeOnBackdrop: {
			type: Boolean,
			default: true,
		},
	},
	inheritAttrs: false,
	emits: ['close', 'show', 'update:modelValue'],
	setup(props, { emit }) {
		const keys = useMagicKeys();
		const state = useVModel(props, 'modelValue', emit);
		const showModal = ref(state.value);
		const showBackdrop = ref(false);
		const showContent = ref(false);
		const backdropLeaving = ref(false);
		const contentLeaving = ref(false);
		const leaving = computed(() => backdropLeaving.value || contentLeaving.value);
		const payload = ref({});
		const scrollLocked = useScrollLock(document.body, showModal.value);

		const open = (data = {}) => {
			payload.value = data;
			showModal.value = true;
			showBackdrop.value = true;
			showContent.value = true;
			emit('show');
		};

		const close = () => {
			showBackdrop.value = false;
			showContent.value = false;
			showModal.value = false;
			emit('close');
		};

		syncRef(showModal, state, { direction: 'ltr' });
		syncRef(showModal, scrollLocked, { direction: 'ltr' });

		watch(
			() => props.modelValue,
			() => {
				props.modelValue ? open(payload.value) : close();
			},
			{ immediate: true }
		);

		watch(leaving, (active) => {
			if (!active) {
				showModal.value = false;
				emit('close');
			}
		});

		whenever(keys.Escape, () => {
			if (showModal.value) close();
		});

		return {
			open,
			close,
			payload,
			showModal,
			showContent,
			contentLeaving,
			showBackdrop,
			backdropLeaving,
			leaving,
		};
	},
};
</script>

<template>
	<slot
		name="activator"
		v-bind="{ on: { click: open }, close, open }"
	/>
	<Teleport to="#modals">
		<div
			v-if="showModal"
			class="fixed inset-0 flex items-center justify-center z-20"
		>
			<transition
				@before-leave="backdropLeaving = true"
				@after-leave="backdropLeaving = false"
				enter-active-class="transition-all transition-fast ease-out-quad"
				leave-active-class="transition-all transition-medium ease-in-quad"
				enter-from-class="opacity-0"
				enter-to-class="opacity-100"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
				appear
			>
				<div
					v-if="showBackdrop"
					class="absolute inset-0 bg-black bg-opacity-40"
					@click="closeOnBackdrop && close()"
				/>
			</transition>
			<transition
				@before-leave="contentLeaving = true"
				@after-leave="contentLeaving = false"
				enter-active-class="transition-all transition-fast ease-out-quad"
				leave-active-class="transition-all transition-medium ease-in-quad"
				enter-from-class="opacity-0 scale-70"
				enter-to-class="opacity-100 scale-100"
				leave-from-class="opacity-100 scale-100"
				leave-to-class="opacity-0 scale-70"
				appear
			>
				<div
					v-if="showContent"
					class="relative"
					v-bind="$attrs"
				>
					<slot
						v-bind="payload"
						:visible="showModal"
						:close="close"
					></slot>
				</div>
			</transition>
		</div>
	</Teleport>
</template>
