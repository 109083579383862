<script setup>
import NMarkdown from '@/components/NMarkdown.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import NDataForm from '@/components/form/Form.vue';
import NLoading from '@/components/NLoading.vue';
import NIcon from '@/components/NIcon.vue';
import ReportDiscussion from './Report.vue';
import NPlayerDetails from '@/components/game/players/Details.vue';
import Timer from '@/components/Timer.vue';
import moment from 'moment';
import Button from '@/components/ui/button/index.vue';
import VoteButton from './VoteButton.vue';
import Attachments from '../discussion-forums/Attachments.vue';
import { useDraft } from '@/composable/discussion';
import { useAuth } from '@/composable/auth';
import { api } from '@/modules/services';
import { cn } from '@/utils/Helpers';
import { date as formatDate } from '@bignerve/ui-utils';
import { unrefElement } from '@vueuse/core';
import { computed, ref, reactive, h, watch, nextTick, toRaw, onMounted } from 'vue';
import { useMessageViewed } from '@/composable/discussion';
import { MODERATION_TYPE } from '@/constant/discussion';
import { useMessageable } from './DiscussionContext';
import { useFeatureFlag } from '@/composable/feature-flag';
import { useClipboard } from '@vueuse/core';
import { userHandleAccessor } from '@/utils/accessors';

const dateToString = (date) => {
	return formatDate(moment.unix(date), 'YYYY-MM-DD @ HH:mm');
};

const props = defineProps({
	discussion: {
		type: Object,
		required: true,
	},
	canReply: {
		type: Boolean,
		default: false,
	},
	canDelete: {
		type: Boolean,
		default: true,
	},
	canEdit: {
		type: Boolean,
		default: true,
	},
	truncate: {
		type: Boolean,
		default: false,
	},
	authors: {
		type: Array,
	},
	resolveUser: {
		type: Function,
		default: (user) => user,
	},
	returnHandler: {
		type: Function,
	},
	class: {
		type: String,
		default: '',
	},
	messageInitHandler: {
		type: Function,
	},
});

const { copy, copied, isSupported } = useClipboard()
const emit = defineEmits(['onReply', 'onRemove']);
const { isReplying: replying, toggleReplying: toggleReply } = useMessageable(props.discussion.id);
const { auth } = useAuth();
const replyPostEl = ref(null);
const replyLinks = ref([]);
const mentions = ref([]);
const attaching = ref(false);
const uploading = ref(false);
const editing = ref(false);
const deleting = ref(false);
const discuss = reactive(toRaw(props.discussion));
const expanded = ref(!props.truncate);
const hidden = ref([
	MODERATION_TYPE.HIDDEN,
	MODERATION_TYPE.REMOVED,
].includes(props.discussion.moderation_type));
const isOwner = computed(() => auth.user && auth.user.id === discuss.author_id);
const hasInteractions = computed(() => {
	return discuss.stats && (
		discuss.stats.total_insightful ||
		discuss.stats.total_helpful ||
		discuss.stats.total_funny ||
		discuss.stats.total_report ||
		discuss.stats.total_replies
	);
});
const hasReplies = computed(() => discuss.stats.total_replies > 0);
const { content: replyContent } = useDraft({
	context: discuss.context_type,
	id: discuss.id,
});

const { enabled: attachEnabled, ...rest } = useFeatureFlag('discussion-forums-attachments');
const deleteEnabled = computed(() => !hasInteractions.value && props.canDelete);

const tooltipDate = computed(() => {
	return [discuss.created_at && `Published ${dateToString(discuss.created_at)}`, discuss.edited_at && `Edited: ${dateToString(discuss.edited_at)}`]
		.filter((v) => v)
		.join('<br>');
});

const showRemovedAlert = computed(() => {
	return discuss.moderation_type === MODERATION_TYPE.REMOVED
		&& (hasReplies.value || isOwner.value);
});

const showHiddenAlert = computed(() => {
	return discuss.moderation_type === MODERATION_TYPE.HIDDEN
		&& hidden.value;
});

const sendReply = async (payload) => {
	const { data: response } = await api.discussions.reply(
		discuss.context_type,
		discuss.context_id,
		discuss.id,
		payload,
	);

	replyContent.value = '';
	replying.value = false;

	emit('onReply', {
		...response.data,
		author: auth.user,
	});
};

const updateMessage = async () => {
	discuss.links ??= [];

	await api.discussions.update(
		discuss.context_type,	
		discuss.context_id,
		discuss.id,
		discuss,
	);

	discuss.edited = true;
	editing.value = false;
	attaching.value = false;
};

const deleteMessage = async () => {
	deleting.value = true;
	await api.discussions.delete(
		discuss.context_type,
		discuss.context_id,
		discuss.id,
	);

	editing.value = false;
	attaching.value = false;
	deleting.value = false;

	emit('onRemove', discuss);
};

const startEditing = () => {
	editing.value = true;

	if (discuss.links?.length) {
		attaching.value = true;
	}
};

const scrollToReplyForm = () => {
    const el = unrefElement(replyPostEl);
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });

    setTimeout(() => {
        replyPostEl.value.focus();
    }, 1000);
};

const copyLink = () => {
	const thread = discuss.conversation_id ? `${discuss.id}@${discuss.conversation_id}` : discuss.id;
	const mLink = window.location.origin + window.location.pathname + `?thread=${thread}`;

	if (isSupported.value) {
		copy(mLink);

		return;
	} 

	console.warn(`[discussion-message] Unable to copy link: ${mLink}`);
}

const addAuthorMention = () => {
	const authorHandle = userHandleAccessor(discuss.author);
	const userHandle = userHandleAccessor(auth.user)

	const rules = [
		() => !!discuss.author,
		() => !replyContent.value.includes(authorHandle),
		() => !replyContent.value,
		() => authorHandle !== userHandle,
	]

	if (rules.every(rule => rule())) {
		replyContent.value = `@${authorHandle} ${replyContent.value}`;
	}
}

watch(replying, (isActive) => {
	if (!isActive) {
        return;
	}

    addAuthorMention();

	nextTick(() => {
		scrollToReplyForm();
	})
});

const { targetEl } = useMessageViewed({
	messageId: discuss.id,
});

defineExpose({ toggleReply, scrollToReplyForm });
defineOptions({ inheritAttrs: false });

onMounted(() => {
	props.messageInitHandler && props.messageInitHandler(discuss);
});
</script>

<script>
import { h } from 'vue';

const ReturnMessage = (props, { slots }) =>
	props.handler ? h('button', {
		type: 'button', class: 'hover:text-dust-900',
		onClick: props.handler,
	}, slots) : slots.default();
</script>

<template>
	<div  class="flex flex-col w-full gap-8" @keyup.esc="() => editing = false">
		<div>
			<div class="flex">
				<div v-if="discuss.conversation_id && (!discuss.moderation_type || showRemovedAlert)" class="p-4 text-dust-600" :class="!hidden ? 'pt-10' : 'pt-4'">
					<ReturnMessage :handler="returnHandler && (() => returnHandler(discuss))">
						<NIcon as="return" />
					</ReturnMessage>
				</div>

				<template v-if="!hidden">
					<NDataForm v-if="editing"
						:action="updateMessage"
						:name="`editing-message-${discuss.id}`"
						class="w-full group"
						#="{ busy }"
					>
						<div class="form-group">
							<div class="quill-editor-wrapper quill-editor-wrapper--message rounded-lg p-4 border border-transparent" :class="{
								'group-hover:shadow group-hover:border-dust-200 group-hover:bg-dust-100': true,
								'shadow border-dust-200 bg-dust-100': replying || attaching
							}">
								<div class="quill-editor-title !font-normal flex items-center justify-start">
									<NPlayerDetails
										v-if="auth.user"
										:user="auth.user"
										:routable="false"
										class="content-start"
									/>
								</div>
								<TextEditor
									v-model="discuss.content"
									v-model:mentions="discuss.mentions"
									:autofocus="true"
									:users="authors"
									:placeholder="`Reply to ${discuss.author ? discuss.author.first_name : 'this message'}`"
									name="post-comment"
									class="border-dust-200"
									required
								/>
							</div>
						</div>

						<Attachments v-if="attaching" v-model="discuss.links" class="message-attachments sm:ml-[65px] w-auto shadow border-dust-200 bg-dust-100" :arrow-class="'arrows-bg-dust-100'" :editable="true" @uploading="state => uploading = state" />

						<div class="py-2 flex items-center justify-end gap-4">
							<Button v-if="attachEnabled" aria-label="button"
								type="button"
								variant="muted"
								class="gap-2"
								@click="attaching = !attaching"
							>
								<NIcon as="attach" />
								{{ attaching ? 'Close...' : 'Attach' }}
								<span v-if="discuss.links.length">({{  discuss.links.length }})</span>
							</Button>
							<Timer
								v-if="deleteEnabled"
								:seconds="6"
								#="{ on, isActive, seconds, stop }"
							>
								<button aria-label="button"
									v-if="!isActive"
									type="button"
									class="flex items-center px-2 py-1 gap-2 rounded-md border text-dust-800 hover:bg-dust-50 "
									:class="{ 'animate-loading': deleting, 'hover:border-red-500 hover:text-red-500': !deleting }"
									:disabled="deleting"
									v-on="on"
								>
									<NLoading
										v-if="deleting"
										active
									/>
									<NIcon
										v-else
										as="trash"
									/>

									{{ deleting ? 'Deleting' : 'Delete' }}
								</button>
								<button aria-label="button"
									v-else
									type="button"
									class="flex items-center px-2 py-1 rounded-md border text-red-500 border-red-500 hover:bg-dust-50"
									@click="() => {
										stop(); deleteMessage();
									}"
								>
									<NIcon
										as="trash"
										class="mr-1"
									/>
									Confirm ({{ seconds }})
								</button>
							</Timer>
							<button aria-label="button"
								type="button"
								class="flex items-center px-2 py-1 rounded-md hover:bg-dust-50 border text-dust-800"
								@click="editing = false"
							>
								Cancel (Esc)
							</button>
							<button aria-label="button"
								type="submit"
								class="flex items-center px-2 py-1 gap-2 bg-dust-700 rounded-md hover:bg-dust-600 text-white"
								:class="{ disabled: busy || discuss.content.length < 5 || uploading }"
                            	:disabled="busy || discuss.content.length < 5 || uploading"
							>
								<NLoading
									v-if="busy"
									active
								/>
								<NIcon
									v-else
									as="discussion-icon"
								/>
								Save
							</button>
						</div>
					</NDataForm>

					<div v-else
						:id="`discussion-message-${discuss.id}`"
						:class="cn('relative border bg-white shadow rounded-lg p-4 mb-2 w-full', props.class)"
					>
						<div ref="targetEl" class="flex items-center justify-between mb-2">
							<slot name="header" :data="discuss">
								<NPlayerDetails
									v-if="discuss.author"
									:user="resolveUser(discuss.author)"
								/>
							</slot>
							<div class="flex items-center">
								<Button v-if="isOwner && !hasInteractions && canEdit" v-tooltip="'Edit'" variant="icon" type="button" class="hover:bg-nerve-200 rounded-md" @click="startEditing">
									<NIcon as="pencil-alt-solid" />
								</Button>
								<Button v-tooltip="'Copy link'" variant="icon" type="button" class="text-dust-500 hover:bg-nerve-200 rounded-md" @click="copyLink">
									<NIcon :as="copied ? 'check-solid' : 'link-solid'" class="text-sm" />
								</Button>
								<!-- <small class="text-dust-500">Posted/Edited Yesterday at 10:23 AM</small> -->
								<small
									v-tooltip="{ content: tooltipDate, html: true }"
									class="text-dust-500 leading-none -mb-1 ml-2"
									>{{ dateToString(discuss.created_at) }}</small
								>
							</div>
						</div>

						<NMarkdown
							:code="discuss.content"
							:truncate="!expanded && truncate ? 1000 : null"
							class="text-dust-700 px-2"
						/>

						<button v-if="truncate && !expanded && discuss.content?.length > 1000" class="mr-auto underline text-sm mx-2 mt-2" @click="expanded = true">Show more</button>
					
						<slot name="content" :data="discuss" />
					</div>
				</template>

				<div v-if="showHiddenAlert"  class="grid gap-2 w-full">
					<div class="border rounded-lg p-4 bg-white">
						Post hidden for low quality. <button type="button" class="underline" @click="() => hidden = false">SHOW</button>
					</div>
				</div>
				<div v-else-if="showRemovedAlert"  class="grid gap-2 w-full">
					<div  class="border border-red-500 text-red-500 rounded-lg p-4 bg-white">
						<NIcon as="times-circle" class="mr-1" /> This comment was removed by the moderators for violating the <a href="/about/code-of-conduct" class="text-inherit underline">community guidelines</a> or <a href="/about/terms-of-use" class="text-inherit underline">terms of use</a>.
					</div>
				</div>
			</div>

			<template v-if="!hidden">
				<Attachments v-if="!editing && discuss.links.length" v-model="discuss.links" class="message-attachments sm:ml-[65px] w-auto" @uploading="state => uploading = state" />

				<div v-if="!editing" class="flex items-center justify-end gap-2 w-full">
					<div class="flex items-center">
						<ReportDiscussion
							:discussion="discuss"
							:disabled="isOwner"
							content-type="discussion"
							
						>
							<template #activator="{ showing, total, hasVote }">
								<button aria-label="button"
									type="button"
									:class="cn(
										'flex items-center px-2 py-2 rounded-md text-dust-700 leading-none',
										showing && 'text-catalyst',
										!isOwner && 'hover:bg-dust-200',
										isOwner && 'cursor-default',
									)"
								>
									<NIcon as="frown-regular" :class="hasVote && 'text-red-500'" />
									<span v-if="total" class="ml-1 text-sm">({{ total }})</span>
								</button>
							</template>
						</ReportDiscussion>
						<button aria-label="button"
							type="button"
							class="flex items-center px-2 py-2 rounded-md hover:bg-dust-200 text-dust-700 disabled"
						>
							<NIcon as="bookmark-regular" />
						</button>
					</div>

					<VoteButton
						:discuss="discuss"
						type="insightful"
						icon="insightful"
						color="insightful"
					/>
					<VoteButton
						:discuss="discuss"
						type="helpful"
						icon="mentor"
						color="mentor"
					/>
					<VoteButton
						:discuss="discuss"
						type="funny"
						icon="happy"
						color="orange"
					/>

					<button v-if="canReply"
						aria-label="button"
						type="submit"
						class="flex items-center px-2 py-1 bg-dust-700 rounded-md hover:bg-dust-600 text-white"
						:class="{ disabled: replying }"
						:disabled="replying"
						@click="toggleReply"
					>
						<NIcon
							as="discussion-icon"
							class="mr-2"
						/>
						Reply
					</button>
				</div>
			</template>
		</div>

		<slot />

		<div
			v-if="canReply && !replying && hasReplies"
			class="flex w-full"
		>
			<div class="px-4">
				<NIcon
					as="return"
					class="text-dust-600"
				/>
			</div>

			<div>
				<button aria-label="button"
					type="submit"
					class="flex items-center px-2 py-1 bg-dust-700 rounded-md hover:bg-dust-600 text-white"
					@click="toggleReply"
				>
					<NIcon
						as="discussion-icon"
						class="mr-2"
					/>
					Reply
				</button>
			</div>
		</div>

		<div v-if="replying" class="flex w-full">
			<div class="px-4 pt-10">
				<NIcon
					as="return"
					class="text-dust-600"
				/>
			</div>

			<NDataForm
				:action="sendReply"
				:name="`post-reply-${discuss.id}`"
				:data="{ content: replyContent, links: replyLinks, mentions }"
				class="w-full group"
				#="{ busy }"
			>
				<div class="form-group">
					<div class="quill-editor-wrapper quill-editor-wrapper--message rounded-lg p-4 border border-transparent" :class="{
						'group-hover:shadow group-hover:border-dust-200 group-hover:bg-dust-100': true,
						'shadow border-dust-200 bg-dust-100': replying || attaching
					}">
						<div class="quill-editor-title !font-normal flex items-center justify-start">
							<NPlayerDetails
								v-if="auth.user"
								:user="auth.user"
								:routable="false"
								class="content-start"
							/>
						</div>
						<TextEditor
							v-model="replyContent"
							v-model:mentions="mentions"
							:users="authors"
							:placeholder="`Reply to ${discuss.author ? discuss.author.first_name : 'this message'}`"
							ref="replyPostEl"
							name="post-comment"
							required
						/>
					</div>

					<Attachments v-if="attaching" v-model="replyLinks" editable class="shadow border-dust-200 bg-dust-100" :arrow-class="'arrows-bg-dust-100'" @uploading="state => uploading = state" />
				</div>

				<div class="py-2 flex items-center justify-end gap-2">
					<FeatureFlag flag="discussion-forums-attachments">
						<Button aria-label="button"
							type="button"
							variant="muted"
							class="gap-2"
							@click="attaching = !attaching"
						>
							<NIcon as="attach" />
							{{ attaching ? 'Close...' : 'Attach' }}
							<span v-if="replyLinks.length">({{  replyLinks.length }})</span>
						</Button>
					</FeatureFlag>
					<Button aria-label="button"
						type="button"
						variant="outline"
						@click="replying = false"
					>
						Cancel
					</Button>
					<Button aria-label="button"
						type="submit"
						variant="inverted"
						:class="{ disabled: busy || replyContent.length < 5 || uploading, 'gap-2': true }"
						:disabled="busy || replyContent.length < 5 || uploading"
					>
						<NLoading
							v-if="busy"
							active
						/>
						<NIcon
							v-else
							as="discussion-icon"
						/>
						Reply
					</Button>
				</div>
			</NDataForm>
		</div>
	</div>
</template>
