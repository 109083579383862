<script>
import PlayerDetails from '@/components/game/players/Details.vue';
import { h, inject, computed } from 'vue'
import { cva } from 'class-variance-authority'

const TotalSent = ({ item }) => {
    const invitation = inject('invitation')
    const total = computed(() => {
        const n = invitation.value?.event_history?.filter(({ status }) => {
            return status === 'sent'
        })?.length || 0

        if (!n && (item.status === 'active' || invitation.value?.invite_status === 'sent')) {
            return 1
        }

        return n
    })

    return h('span', { class: 'text-dust-800' }, total.value)
}

const statusVariants = cva(
    'w-4 h-4 rounded-full inline-block bg-dust-200',
    {
        variants: {
            variant: {
                active: 'bg-green-400',
                open: 'bg-yellow-400',
            },
        },
    }
)

const InviteStatus = ({ item }) => {
    return h('span', {
        class: statusVariants({
            variant: item.status
        })
    }, '')
}
</script>

<script setup>
import NModal from '@/components/NModal.vue'
import FlexTable from '@/components/FlexTable.vue'
import Loading from '@/components/NLoading.vue'
import NumberFlow from '@number-flow/vue'
import DeleteButton from './MembersDeleteButton.vue'
import InvitationStatusProvider from './InvitationStatusProvider.vue'
import { computed, ref, watch, h } from 'vue'
import { Icon } from '@bignerve/ui-core'
import { api } from '@/modules/services'
import { useChannelMemberList } from '@/queries/forum-tools'
import { createReusableTemplate } from '@vueuse/core'

const props = defineProps({
    channel: {
        type: Object,
        required: true,
    },
    platform: {
        type: Object,
        required: true,
    },
    dataImported: {
        type: Array,
        default: () => [],
    }
})

const loading = ref(false)
const showModal = ref(false)
const { data, refetch } = useChannelMemberList(props.platform.slug, props.channel.slug)

const handleDelete = () => {
    //
}

const totalMembers = computed(() => data.value.length)
const totalJoined = computed(() => data.value?.filter(member => member.status === 'active').length || 0)
const joinRate = computed(() => {
    if (totalMembers.value === 0) return 0
    return ((totalJoined.value / totalMembers.value) * 100).toFixed(1)
})

const handleUpdateMembers = async (members) => {
    try {
        loading.value = true
        const { data } = await api.channel.updateMembers(
            props.platform.slug,
            props.channel.slug,
            { members },
        )

        refetch()
    } finally {
        loading.value = false
    }

}

watch(() => props.dataImported, (value) => {
    handleUpdateMembers(value?.filter((item) => item.valid) || [])
})

const LineInvitationProvider = ({ item }, { slots }) => {
    return h(InvitationStatusProvider, {
        platformSlug: props.platform.slug,
        sourceType: 'member',
        sourceId: props.channel.id,
        recipientId: item.id,
    }, slots.default)
}

const [DefineMetricsCard, MetricsCard] = createReusableTemplate()
</script>

<template>

    <DefineMetricsCard #="{ label, value, change, suffix }">
        <div class="flex flex-col gap-1">
            <div class="text-sm font-medium text-muted-foreground uppercase">{{ label }}</div>
            <div class="flex items-baseline gap-2">
                <span class="text-2xl font-bold text-dust-800">
                    <NumberFlow :value="value" :trend="0" :suffix="suffix" willChange continuous
                        :transformTiming="{ duration: 1050 }" />
                </span>
                <span v-if="change" :class="`text-sm`">
                    {{ change >= 0 ? "↑" : "↓" }} {{ Math.abs(change) }}%
                </span>
            </div>
        </div>
    </DefineMetricsCard>

    <div class="grid gap-4">
        <div class="px-3 pt-3 pb-2 flex gap-2 rounded-lg border text-dust-800 bg-white items-center">
            <Loading v-if="loading" active />
            <Icon v-else as="user-avatar" class="text-xl" />
            <p class="m-0">
                <b>Member List
                    <NumberFlow :value="data?.length || 0" willChange continuous :transformTiming="{ duration: 750 }" />
                    .
                </b> <button type="button" class="underline text-inherit" @click="() => showModal = true">View this
                    channel's full member list.</button>
            </p>
        </div>

        <ul class="pl-4">
            <li>You can <b>append</b> to this list at any time by uploading a new CSV, and duplicates will be removed.
            </li>
            <li>To <b>delete</b> this member list, <DeleteButton class="underline text-inherit">tap here</DeleteButton>.
                <i>No one will be able to enter your channel after this action. Their existing BigNerve user accounts
                    will not be removed.</i>
            </li>
        </ul>

        <NModal v-model="showModal" #="{ close }">
            <div class="overflow-hidden rounded-md flex flex-col w-full max-w-[95dvw] sm:w-[65rem] bg-white">
                <div class="flex justify-between items-center px-3 py-2 bg-secondary text-white">
                    <div class="flex items-center">
                        <h3 class="m-0">Member List</h3>
                    </div>

                    <button aria-label="button" type="button" @click="close">
                        <NIcon as="times-circle-regular" />
                    </button>
                </div>

                <div class="h-[75vh] overflow-y-auto sb-snug sb-dust-300">
                    <div class="border-b py-2 px-4">
                        <div class="grid grid-cols-2 lg:grid-cols-4 gap-8">
                            <MetricsCard label="Members Count" :value="showModal ? totalMembers : 0" />
                            <MetricsCard label="Join Count" :value="showModal ? totalJoined : 0" />
                            <MetricsCard label="Pending" :value="showModal ? totalMembers - totalJoined : 0" />
                            <MetricsCard label="Join Percentage" :value="showModal ? joinRate : 0" suffix="%" />
                        </div>
                    </div>
                    <FlexTable :header="[
                        { text: 'Invited', value: 'invited', grow: 4 },
                        { text: 'Member', value: 'member', grow: 4 },
                        { text: 'Joined', value: 'joined', grow: 1, align: 'center' },
                        { text: 'Invite(s)', value: 'invites', grow: 1, align: 'center' },
                        { text: 'Delivered', value: 'delivered', grow: 1, align: 'center' },
                        // { text: '', value: 'active', grow: 0.5 },
                    ]" :items="data" :line-provider="LineInvitationProvider"
                        noResultsText="No members found. Import members to see them listed here."
                        header-class="border-b-2 py-3 sticky top-0 bg-white" class="bg-white px-4 pb-4">
                        <template #col:invited="{ item }">
                            <div class="grid leading-5">
                                <span class="font-bold text-dust-700">{{ item.first_name }} {{ item.last_name }}</span>
                                <span class="text-dust">{{ item.email }}</span>
                            </div>
                        </template>
                        <template #col:member="{ item }">
                            <PlayerDetails v-if="item.user" :user="item.user" medium />
                            <span v-else class="text-dust-800">
                                - - -
                            </span>
                        </template>
                        <template #col:invites="{ item }">
                            <TotalSent :item="item" />
                        </template>
                        <template #col:joined="{ item }">
                            <span class="px-2 py-1 rounded-full bg-dust-600 text-white text-xs"
                                :class="{ 'bg-green-600': item.status === 'active' }">
                                {{ item.status === 'active' ? 'Yes' : 'No' }}
                            </span>
                        </template>
                        <template #col:delivered="{ item }">
                            <InviteStatus :item="item" />
                        </template>
                        <template #col:active="{ item }">
                            <span class="h-4 w-4 rounded-full inline-block" :class="item.active ? 'bg-green-500' : 'bg-red-500'"></span>
                        </template>
                    </FlexTable>
                </div>
            </div>
        </NModal>
    </div>
</template>