import axios from 'axios';

import { authorization, applyIdentity } from '@/services/middlewares';
import { checkState } from './utils';

export default class Pooler {
    constructor (config) {
        this.requests = [];
        this.axios = new axios.create(config);

        authorization(this.axios);
        applyIdentity(this.axios);
        checkState(this.axios);

        this.awake();
    }

    awake () {
        // awake state
    }

    from () {
        return this.axios;
    }
}
