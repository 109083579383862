<script setup>
import LoadMore from '@/layouts/partials/LoadMore.vue';
import ChallengeCard from './ClosedChallengeCard.vue';
import CardPlaceholder from '@/components/CardPlaceholder.vue';
import { usePlatform } from '@/composable/platform';
import { ref, computed, watch, shallowRef } from 'vue';
import { useRouteQuery, useRouteParams } from '@vueuse/router';
import { useCancelToken } from '@/composable/requester';
import { api } from '@/modules/services';

const props = defineProps({
    title: {
        type: String,
        default: 'Completed Challenges'
    },
    platformDomain: {
        type: Boolean,
        default: false
    }
})

const busy = ref(false);
const catalyst = useRouteParams('user');
const category = useRouteQuery('category');
const filter = useRouteQuery('filter');
const query = ref({
    page: 0,
    status: 'closed',
    sort: 'end_time_desc',
    category: category.value,
    size: filter.value === 'closed' ? 18 : 6,
});
const challenges = shallowRef([]);
const total = ref(0);
const isEmpty = computed(() => challenges.value.length === 0);
const hasMore = computed(() => total.value > challenges.value.length);
const { currentPlatform, platform } = usePlatform();
const { abortPrevious, nextToken } = useCancelToken();

const fetch = async () => {
    abortPrevious();

    try {
        busy.value = true;

        const { data } = await api.widgets.challenges(
            catalyst.value || currentPlatform.value?.slug || 'bignerve',
            {
                ...query.value, 
                platform_domain: props.platformDomain ? platform.value?.id : undefined,
            }, 
            { cancelToken: nextToken() },
        );

        merge(data);
        afterFetch(data);
        
        busy.value = false;
    } catch (e) {
        if (e.name === 'CanceledError') {
            return;
        }

        busy.value = false;

        throw e;
    }
};

const reset = () => {
    challenges.value = [];
    query.value.page = 0;
    total.value = 0;
};

const merge = ({ data, meta }) => {
    challenges.value = [...challenges.value, ...data];
    total.value = meta.total;
};

const refetch = () => {
    reset();

    query.value.page = 0;
    query.value.category = category.value;
    query.value.size = filter.value === 'closed' ? 18 : 6;

    fetch();
};

const loadMore = () => {
    if (busy.value || !hasMore.value) {
        return;
    }

    query.value.page = query.value.page + 1
        
    fetch();
};

const afterFetch = () => {
    // implemented on extended component
}

watch([catalyst, category, filter, currentPlatform], () => {
    refetch()
});

fetch();

defineExpose({
    fetch,
    afterFetch,
    loadMore,
});
</script>

<template>
    <section class="card-section">
        <div class="flex items-center justify-between mb-4">
            <h2 class="m-0 text-xl font-narrow text-secondary flex-1">
                {{ title }}
                <span class="secondary-heading-section__sub-section"> — Browse the Winning Ideas!</span>
            </h2>
        </div>

        <p v-if="!busy && isEmpty" class="text-center italic py-20">
            No closed challenges!
        </p>

        <template v-if="$route.query.filter !== 'closed'" >
            <div class="overflow-hidden">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <ChallengeCard
                        v-for="(challenge, index) in challenges"
                        :key="index"
                        :challenge="challenge"
                        class="h-auto"
                    />

                    <template v-if="busy">
                        <CardPlaceholder v-for="i of 9" :key="`tease-placeholder-${i}`">
                            <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
                        </CardPlaceholder>
                    </template>
                </div>
            </div>

            <LoadMore v-if="challenges.length > 3" :handler="() => ($router.push({ query: { filter: 'closed' }, hash: '#heading' }))" />
        </template>

        <div v-else>
            <div v-if="!isEmpty" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <ChallengeCard
                    v-for="(challenge, index) in challenges"
                    :key="`${index}-closed-${challenge.id}`"
                    :challenge="challenge"
                />
            </div>
            <div v-if="busy" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <CardPlaceholder v-for="i of ($route.query.filter === 'closed' ? 6 : 3)" :key="`tease-placeholder-${i}`">
                    <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
                </CardPlaceholder>
            </div>

            <LoadMore v-if="hasMore" :handler="loadMore" />
        </div>
    </section>
</template>
