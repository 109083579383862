<script>
import useChallengeCreateFlow from '@/mixins/ChallengeCreateFlow';
import ChallengeDrafts from './Drafts.vue';
import { usePlatform } from '@/mixins/ShowsPlatform';

export default {
	name: 'Stepper',

	inject: ['form'],

	emits: ['change'],

	mixins: [useChallengeCreateFlow, usePlatform()],

	components: {
		ChallengeDrafts,
	},

	provide() {
		return {
			stepper: this,
		};
	},

	data() {
		return {
			steps: [],
			selected: null,
			animated: false,
		};
	},

	computed: {
		visibles() {
			return this.steps.filter((step) => !step.hidden);
		},
		activeIndex() {
			return this.visibles.findIndex((step) => step.slug === this.selected) || 0;
		},
		prevTitle() {
			return this.activeIndex > 0 ? this.visibles[this.activeIndex - 1].title : null;
		},
		nextTitle() {
			return this.activeIndex === this.visibles.length - 1 ? null : this.visibles[this.activeIndex + 1].title;
		},
		stepper() {
			return this;
		},
		isFirst() {
			return this.visibles.length > 0 && this.activeIndex === 0;
		},
		isLast() {
			return this.visibles.length > 0 && this.activeIndex === this.visibles.length - 1;
		},
		pixelWidth() {
			return this.visibles.length * 120;
		},
		prevStep() {
			return this.activeIndex > 0 ? this.visibles[this.activeIndex - 1] : {};
		},
		nextStep() {
			return this.activeIndex === this.visibles.length - 1 ? {} : this.visibles[this.activeIndex + 1];
		},
		activeStep() {
			return this.visibles[this.activeIndex] || {};
		},
	},

	watch: {
		selected(n, o) {
			this.$emit('change');
		},
	},

	methods: {
		number(n = 1) {
			const step = this.visibles[n - 1];

			if (step && !step.disabled) {
				this.to(step.slug);
			}
		},
		prev() {
			const step = this.visibles[this.activeIndex - 1];

			if (step && !step.disabled) {
				this.to(step.slug);
			}
		},
		next() {
			const step = this.visibles[this.activeIndex + 1];

			if (step && !step.disabled) {
				this.to(step.slug);
			}
		},
		to(slug) {
			this.selected = slug;

			this.$bus('challenge:creation-flow:stepper:changed', { active: slug });

			this.scrollToSteps();
		},
		has(slug) {
			return this.steps.some((step) => step.slug === slug);
		}, 
		scrollToSteps() {
			const el = this.$refs.stepper;
			el &&
				el.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				});
		},
		submit() {
			this.form.submit();
		},
		reorder({ slug, order, select = false }) {
			const step = this.steps.find((step) => step.slug === slug);

			if (!step) return;

			const index = this.steps.indexOf(step);
			if (index > -1 && index !== order) {
				this.steps.splice(index, 1);
				this.steps.splice(order, 0, step);
			}

			if (select) {
				setTimeout(() => {
					this.animated = false;
					this.to(slug);
				}, 1000);
			}
		},
		whenVisible(visible) {
			visible && this.$bus('challenge:stepper:visible');
		},
	},

	mounted() {
		this.selected = this.steps[0].slug;

		if (this.$route.query.start) {
			switch (this.$route.query.start) {
				case 'promotions':
					this.$refs.drafts.open({
						title: 'Saved QQs to promote',
						origin: 'challenge-promotion-flow',
					});
					break;
			}

			this.$router.replace({
				query: { start: null },
			});
		}
	},
};
</script>

<template>
	<div
		ref="stepper"
		class="py-5 -my-5"
	>
		<div
			class="flex flex-wrap gap-2 justify-center py-4"
			v-visible="whenVisible"
		>
			<button aria-label="button"
				v-show="isFirst && form.dirty"
				type="button"
				class="rounded-md bg-secondary-500 text-white focus:bg-secondary-600 hover:bg-secondary-600 px-4 py-2 leading-tight"
				@click="$bus('challenge:creation-flow:start-over')"
			>
				Start over
			</button>
			<button aria-label="button"
				v-show="isFirst && form.dirty"
				type="button"
				class="rounded-md bg-secondary-500 text-white focus:bg-secondary-600 hover:bg-secondary-600 px-4 py-2 leading-tight"
				@click="next"
			>
				Resume prior question
			</button>

			<ChallengeDrafts
				ref="drafts"
				#default="{ open, groups }"
			>
				<button aria-label="button"
					v-show="isFirst || isLast"
					type="button"
					class="rounded-md bg-secondary-500 text-white focus:bg-secondary-600 hover:bg-secondary-600 px-4 py-2 leading-tight"
					@click="open({ origin: 'challenge-promotion-flow', title: 'Saved QQs to promote' })"
				>
					Saved QQs to promote
					<span
						v-if="groups['challenge-promotion-flow']"
						class="ml-1"
						>({{ groups['challenge-promotion-flow'].length }})</span
					>
				</button>

				<button aria-label="button"
					v-show="isFirst || isLast"
					type="button"
					class="rounded-md bg-secondary-500 text-white focus:bg-secondary-600 hover:bg-secondary-600 px-4 py-2 leading-tight"
					@click="open({ origin: 'challenge-creation-flow', title: 'Saved drafts' })"
				>
					Saved drafts
					<span
						v-if="groups['challenge-creation-flow']"
						class="ml-1"
						>({{ groups['challenge-creation-flow'].length }})</span
					>
				</button>
			</ChallengeDrafts>
		</div>
		<div v-show="selected !== 'category' && !isLast">
			<div class="relative overflow-auto mb-6 pt-1">
				<div class="z-10 w-10 absolute left-0 top-0 bottom-2 bg-gradient-to-r from-white"></div>
				<div class="z-10 w-10 absolute right-0 top-0 bottom-2 bg-gradient-to-l from-white"></div>
				<div
					ref="breadcrumbs"
					class="challenge-steps overflow-y-hidden snap snap-x !m-0"
				>
					<transition-group
						:move-class="animated ? 'breadcrumb-move' : null"
						tag="div"
						class="challenge-steps__inside"
						:style="{ width: pixelWidth + 'px' }"
						appear
					>
						<button aria-label="button"
							v-for="(step, index) in visibles"
							class="challenge-step snap-proximity"
							type="button"
							:class="{ 'is-active': step.isOpen }"
							:key="step.slug"
							:id="`step-${step.slug}`"
							:disabled="step.disabled"
							@click="to(step.slug)"
						>
							<span
								class="challenge-step__num"
								:class="{ 'is-complete': step.complete, 'ring-offset-1 ring-4 ring-secondary-300': step.isOpen }"
							>
								<NIcon
									v-if="step.complete"
									as="check-solid"
									style="padding-top: 1px"
								/>

								<template v-else>{{ index + 1 }}</template>
							</span>
							<span class="challenge-step__title bg-white px-2 py-1 -mx-2">{{ step.title }} </span>
						</button>
					</transition-group>
				</div>
			</div>
		</div>
		<div class="grid grid-cols-12 gap-4">
			<slot
				v-if="activeStep.showLeft"
				name="left"
				:selected="selected"
			/>
			<slot />
			<slot
				v-if="activeStep.showRight"
				name="right"
				:selected="selected"
			/>
		</div>
		<div
			v-show="!activeStep.hideActions"
			class="stepper-footer"
		>
			<div class="grid grid-cols-12 gap-4">
				<div class="col-span-12 lg:col-span-6 stepper-footer__col is-left">
					<span
						v-if="prevTitle"
						class="stepper-footer__help-text flex items-center"
					>
						<span
							aria-hidden="true"
							class="icon icon-chevron-left"
						></span>
						{{ prevTitle }}
					</span>
					<button aria-label="button"
						class="btn is-orange"
						:disabled="!prevTitle || prevStep.disabled"
						@click.prevent="prev"
					>
						Back
					</button>
				</div>
				<div class="col-span-12 lg:col-span-6 stepper-footer__col is-right">
					<button aria-label="button"
						v-if="activeStep.activator"
						type="button"
						class="btn is-orange"
						:disabled="form.busy || !isValid"
						@click="submit"
					>
						{{ form.busy ? 'Saving...' : 'Save' }}
					</button>
					<button aria-label="button"
						v-else-if="nextTitle"
						:disabled="nextStep.disabled"
						type="button"
						class="btn is-orange"
						@click.prevent="next"
					>
						Next
					</button>
					<span
						v-if="nextTitle"
						class="stepper-footer__help-text flex items-center"
					>
						{{ nextTitle }}
						<span
							aria-hidden="true"
							class="icon icon-chevron-right"
						></span>
					</span>
				</div>
				<!-- <div class="col-span-12 lg:col-span-6 stepper-footer__col is-draft">
                    <button aria-label="button" class="btn is-draft">Save Draft</button>
                </div> -->
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.breadcrumb-move {
	@apply transform ease-in-out duration-1000;
}
</style>
