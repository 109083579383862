import axios from 'axios';
import * as tus from 'tus-js-client';
import { Session } from '@/utils/Auth';
import { deferredPromise } from '@/utils/promise';

const API_BASE_URL = import.meta.env.VUE_APP_API_BASE_URL;

export default requester => {
    const provider = axios.create();

    return {
        /**
         * Attach file using presigned url
         */
        async attachFile (file, params = {}, { onUpdateProgress = () => {}, cancelToken } = {}) {
            try {
                const payload = { name: file.name, type: file.type, ...params };
                const { data } = await requester.post('/content/attachments/upload', payload);

                if (!data) {
                    throw new Error('No data');
                }

                const { fileKey, signedUrl } = data;

                await provider.put(
                    signedUrl,
                    file,
                    {
                        headers: {
                            'Content-Type': file.type,
                            // 'Content-Disposition': `attachment; filename=${file.name}`,
                        },
                        onUploadProgress: (event) => {
                            const progress = Math.round((event.loaded * 100) / event.total);
                            
                            onUpdateProgress(progress);
                        },
                        cancelToken,
                    }
                );

                return { ...payload, fileKey };
            } catch (err) {
                throw err;
            }
        },

        /**
         * Attach video using tus protocol
         **/   
        async attachVideo (file, params = {}, { onUpdateProgress = () => {} } = {}) {
            const { resolve, reject, promise } = deferredPromise();

            const upload = new tus.Upload(file, {
                overridePatchMethod: true,
                endpoint: `${API_BASE_URL}/content/attachments/stream-upload?name=${file.name}&type=${file.type}&private=${params.private}`,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                chunkSize: 1024 * 1024 * 100, // 10MB
                metadata: {
                  filename: file.name,
                  filetype: file.type,
                },
                onBeforeRequest: (req) => {
                    const url = req.getURL();

                    if (url.includes('attachments')) {
                        req.setHeader('Authorization', `Bearer ${Session.getToken()}`);
                    }
                },
                onError: reject,
                onProgress: (bytesUploaded, bytesTotal) => {
                  const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

                  onUpdateProgress(percentage);
                },
                onSuccess: () => {
                    const url = new URL(upload.url);
                    const videoId = url.pathname.split('/').pop();

                    resolve({ videoId, name: file.name, type: file.type });
                },
            });

            // Check if there are any previous uploads to continue.
            upload.findPreviousUploads().then((previousUploads) => {
                // Found previous uploads so we select the first one.
                if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0])
                }

                // Start the upload
                upload.start()
            })

            return promise;
        },

        /**
         * Get channels
         * 
         * @return {object}
         */
        channels (platformSlug) {
            return requester.get(`/content/channels/${platformSlug}`);
        },

        /**
         * Get channel
         * 
         * @return {object}
         */
        channel (platformSlug, channelSlug) {
            return requester.get(`/content/channels/${platformSlug}/${channelSlug}`);
        },

        /**
         * Get tasks
         * 
         * @return {object}
         */
        tasksQueue (platformSlug, channelSlug) {
            return requester.get(`/content/management/${platformSlug}/channels/${channelSlug}/tasks`);
        },

        /**
         * Update task with patch request
         * 
         * @param {number} id
         * @param {object} data
         * @return {object}
         */
        updateTask (platformSlug, channelSlug, data) {
            return requester.patch(`/content/management/${platformSlug}/channels/${channelSlug}/tasks`, data);
        },
    };
};

