import platforms from '@/store/modules/app/platforms.json';
import { createSharedComposable } from '@vueuse/core';
import { computed, reactive, watch, ref } from 'vue';
import { api } from '@/modules/services';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useAuth } from './auth';
import { usePlatform as usePlatformBySlug } from '@/queries/platform';

// available user hooks
// usePlatformUtils - returns platform utils
// usePlatform - returns current platform data
// useUserPlatform - returns user platform data

// available user hooks
// useContextAccount - returns context account data
// useCurrentAccount - returns current account data
// useContextUser - returns context user data
// useCurrentUser - returns current user data

const [, domain, subdomain] = location.hostname.split('.').reverse()

export const resolvePlatform = (target) => {
    return platforms.find(
        platform => platform.subdomain === target
    );
}

export const userPlatformRules = [
	/home\.user/,
	/home\.user\.medals/,
	/challenges\.show/,
	/challenges\.rate/,
	/challenges\.start-rate/,
	/dashboard\./,
	/account\./,
	({ name, params }) => /home.olympiads/.test(name) && params.user !== 'platform',
	({ name, params }) => /platform\./.test(name) && params.platformSlug !== 'platform',
	({ name, params }) => /discussion-forums\./.test(name) && params.user !== 'platform',
	({ name, params }) => /home.challenges.search/.test(name) && !!params.user,
];

export const usePlatformUtils = createSharedComposable(() => {
	const route = useRoute();
	const isUserPlatform = computed(() => {
		return userPlatformRules.some((rule) => {
			return typeof rule === 'function' ? rule(route) : rule.test(route.name);
		});
	});

	const isProfilePage = computed(() => /dashboard\.|account\./.test(route.name));

	return {
		isUserPlatform,
		isProfilePage,
	}
});

export const useContextAccount = createSharedComposable(() => {
	const store = useStore();
	const { auth } = useAuth();
	const { isProfilePage } = usePlatformUtils();

	return computed(() => {
		if (isProfilePage.value && auth.authenticated) {
			return auth.current;
		}
		
		return store.getters['app/user'];
	});
})

export const useUserPlatform = createSharedComposable(() => {
	const account = useContextAccount();
	const { isProfilePage } = usePlatformUtils();
	const { auth } = useAuth();

	const userSlug = computed(() => {
		return account.value && account.value.user.screen_name
	});
	const { data: platform } = usePlatformBySlug(userSlug)

	const title = computed(() => {
		return account.value ? `${account.value.user.first_name} ${account.value.user.last_name}` : null;
	});

	const description = computed(() => {
		return account.value && account.value.user.bio;
	});

	const slogan = computed(() => {
		return account.value && account.value.user.slogan;
	});

	const isLoggedUser = computed(() => {
		return account.value && account.value.user.id === auth.user.id;
	});

	const isViewingSelf = computed(() => {
		return isProfilePage.value && isLoggedUser.value;
	});

	const isViewingSelfPlatform = computed(() => {
		return platform.value?.owner === auth.user.id;
	});

	return { title, description, slogan, platform, account, isLoggedUser, isViewingSelf, isViewingSelfPlatform };
});

export const usePlatform = createSharedComposable(() => {
	const platform = ref(
		resolvePlatform(subdomain) ||
		resolvePlatform(domain) ||
		resolvePlatform('bignerve')
	);
	const subPlatform = ref(resolvePlatform(subdomain));
	const { isViewingSelf, platform: userPlatform } = useUserPlatform();
	const { isUserPlatform, isProfilePage } = usePlatformUtils();

	const isBignerve = computed(() => {
		return platform.value.name === 'bignerve';
	});

	const isSubplatform = computed(() => {
		return subdomain && subdomain !== 'www';
	});

	const currentPlatform = computed(() => {
		if (isUserPlatform.value) {
			return userPlatform.value;
		}

		return isSubplatform.value ? subPlatform.value : platform.value;
	});

	const title = computed(() => {
		return currentPlatform.value?.title;
	});

	const description = computed(() => {
		if (!currentPlatform.value) {
			return null;
		}

		return isSubplatform.value
			? currentPlatform.value.description
			: currentPlatform.value.slogan;
	});

	const slogan = computed(() => {
		return currentPlatform.value?.slogan;
	});

	// fill main platform
	if (domain) {
		api.platform.find(domain).then(({ data: response }) => {
			platform.value = response.data;
		});
	}

	// fill subPlatform
	if (subdomain) {
		api.platform.find(subdomain).then(({ data: response }) => {
			subPlatform.value = response.data;
		});
	}

	return {
		title,
		description,
		slogan,
		platform,
		currentPlatform,
		subPlatform,
		contextPlatform: currentPlatform,
		rootPlatform: platform,
		isUserPlatform,
		isProfilePage,
		isViewingSelf,
		isBignerve,
		isSubplatform,
	};
});
