<script>
import Meter from '@/components/idea/Meter.vue';
import Who from '@/components/challenge-show/Who.vue';
import Why from '@/components/challenge-show/Why.vue';
import What from '@/components/challenge-show/What.vue';
import useChallengeDetails from '@/mixins/ChallengeDetails';
import MemeGenerator from '@/components/challenge-show/MemeGenerator.vue';

export default {
    mixins: [useChallengeDetails()],

    props: {
        idea: Object,
        challenge: Object
    },

    components: {
        Who,
        Why,
        What,
        Meter,
        MemeGenerator,
    },

    data() {
        return {
            imageExpanded: false,
            showDetails: false,
            scales: {
                'blue-sky': {
                    note: 'Actually feasible with available time and resources',
                    definition: '<b>Blue sky</b>: creative and actually feasible with available time and resources.',
                },
                'moonshot': {
                    note: '<i>Possibly</i> feasible, with uncertain time and money',
                    definition: '<b>Moonshot</b>: creative and possibly feasible, with uncertain time and money.',
                },
                'playful': {
                    note: 'No way feasible, pure fun',
                    definition: '<b>Playful</b>: creative and in no way feasible; just pure fun.',
                },
            }
        };
    },

    computed: {
        assumptions() {
            if (this.isQQ) {
                return Object.assign({}, this.idea.challenge, { deep_dives: this.idea.deep_dives });
            }

            return {};
        }
    }
}
</script>

<template>
    <div>
        <div v-if="isQQ" class="rounded-lg border-4 border-innovator font-body shadow overflow-hidden relative bg-white" >
            <div class="bg-innovator text-white p-4 flex items-center -mt-1">
                <NIcon as="lightbulb-o1" class="text-2xl mr-3" />
                <h3 class="m-0 text-lg">Here is an idea to rate from another player in this QQ challenge:</h3>
            </div>

            <!-- <div class="flex items-center px-4 pb-4">
                <div class="flex-1">
                    <a href="#" @click.prevent="$refs.lightbox.open()">
                        <img class="w-full h-auto block bg-dust-200 overflow-hidden rounded-md" :src="preview" alt="">
                    </a>
                </div>
                <div class="flex flex-col items-center px-3">
                    <Meter :level="9" color="innovator" class="w-20" />
                    <span class="text-dust text-xs text-center max-w-20 mt-2 leading-3 italic font-normal">
                        Innovator Responsiveness Score
                    </span>
                </div>
            </div> -->

            <div class="p-4">
                <h3 class="m-0 text-xl text-dust-800 mb-0">{{ idea.title }}</h3>
            </div>

            <NMarkdown v-if="idea.detail" :code="idea.detail" :unfurl="true" class="py-0 px-4" />

            <div class="px-3 pb-4">
                <Who :challenge="idea.challenge" />
                <Why :challenge="idea.challenge" />
                <What :challenge="idea.challenge" />
            </div>
        </div>

        <div v-else class="rounded-lg border-4 border-innovator-500 bg-innovator-500 font-body overflow-hidden relative">
            <div class=" text-white p-4 flex items-center -mt-1">
                <NIcon as="lightbulb-o1" class="text-2xl mr-3" />
                <h3 class="m-0 text-lg">Here is an idea from another player in this challenge:</h3>
            </div>

            <div class="bg-white rounded-lg flex flex-col gap-4 p-6">
                <h3 class="m-0 text-xl text-dust-800 mb-0">{{ idea.title }}</h3>

                <NMarkdown v-if="idea.detail" :code="idea.detail" :unfurl="true" />

                <div v-if="idea.image">
                    <h3 class="text-dust-700">Supporting Image(s)</h3>
                    <div class="w-full">
                        <a href="#" @click.prevent="$refs.lightbox.open()">
                            <MemeGenerator
                                v-if="isCaption"
                                :setup-text="!!idea.detail ? idea.title : null"
                                :punchline-text="!!idea.detail ? idea.detail : idea.title"
                                :image-src="challenge.primary_image"
                            />

                            <img v-else class="w-full h-auto block bg-dust-200 overflow-hidden rounded-md" :src="idea.image" alt="">
                        </a>
                    </div>

                    <NModal ref="lightbox" @close="imageExpanded = false">
                        <div class="overflow-auto sb-tight relative sb-dust-200 h-200 max-h-screen max-w-screen m-4">
                            <img :src="idea.image" :style="{ zoom: imageExpanded ? '2' : 'normal' }" :class="{ 'max-h-full': !imageExpanded }" alt="" @click="imageExpanded = !imageExpanded" class="cursor-pointer">
                            <div class="top-10 right-10 absolute flex items-center justify-center gap-2">
                                <button aria-label="button" v-if="!$responsive.sm" type="button" class="w-9 h-9 text-white flex items-center justify-center bg-black bg-opacity-60 hover:bg-opacity-80 rounded-md" @click="imageExpanded = !imageExpanded">
                                    <svg v-if="imageExpanded" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" stroke="currentColor" viewBox="0 0 448 512"><path d="M128 320H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32v-96C160 334.3 145.7 320 128 320zM416 320h-96c-17.69 0-32 14.31-32 32v96c0 17.69 14.31 32 32 32s32-14.31 32-32v-64h64c17.69 0 32-14.31 32-32S433.7 320 416 320zM320 192h96c17.69 0 32-14.31 32-32s-14.31-32-32-32h-64V64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96C288 177.7 302.3 192 320 192zM128 32C110.3 32 96 46.31 96 64v64H32C14.31 128 0 142.3 0 160s14.31 32 32 32h96c17.69 0 32-14.31 32-32V64C160 46.31 145.7 32 128 32z"/></svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" stroke="currentColor" viewBox="0 0 448 512"><path d="M128 32H32C14.31 32 0 46.31 0 64v96c0 17.69 14.31 32 32 32s32-14.31 32-32V96h64c17.69 0 32-14.31 32-32S145.7 32 128 32zM416 32h-96c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32V64C448 46.31 433.7 32 416 32zM128 416H64v-64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96c0 17.69 14.31 32 32 32h96c17.69 0 32-14.31 32-32S145.7 416 128 416zM416 320c-17.69 0-32 14.31-32 32v64h-64c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c17.69 0 32-14.31 32-32v-96C448 334.3 433.7 320 416 320z"/></svg>
                                </button>
                                <button aria-label="button" type="button" class="w-9 h-9 text-white flex items-center justify-center bg-black bg-opacity-60 hover:bg-opacity-80 rounded-md" @click="$refs.lightbox.close()">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </NModal>

                </div>
            </div>
        </div>

        <div v-if="!isCaption && !isAMA" class="mt-4 ml-1">
            <p class="mb-1 mt-3">
                Remember, the Catalyst asked for:
                <img v-tooltip.top="{ content: scales[challenge.innovation_scale].note, hasHtml: true, theme: 'alert' }" :src="`/img/innovative-level/${challenge.innovation_scale}.png`" alt="Requested idea innovation scale graphic" class="inline-block h-10 mx-2"> ideas.
            </p>
            <p v-html="scales[challenge.innovation_scale].definition" class="text-dust-900 mt-1 mb-2" />
            <p class="mb-2"><i>Keep this in mind when you rate.</i></p>
        </div>
    </div>
</template>
