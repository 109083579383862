<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { isArray } from 'lodash';
import { Icon } from '@bignerve/ui-core';
import { breadcrumbs } from '@/utils/Categories';

const store = useStore();

const props = defineProps({
    modelValue: {
        type: Object,
        default: null
    },
    initialValue: {
        type: Object,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false
    },
    allowAll: {
        type: Boolean,
        default: false
    },
    select: {
        type: Function,
        default: (v) => v,
    }
});

const emit = defineEmits(['update:modelValue', 'change']);

const category = ref(null);
const categories = computed(() => store.getters['app/challenge/categories']);

const selected = computed(() => {
    if (!props.modelValue) {
        return null;
    }

    const breadcrumbsList = breadcrumbs({ children: categories.value }, props.modelValue.id);
    return isArray(breadcrumbsList) && breadcrumbsList.length > 0 ? breadcrumbsList.at(-1) : null;
});

const context = computed(() => {
    return category.value && (
        category.value.children.length
            ? category.value
            : category.value.parent
    );
});

const children = computed(() => {
    return context.value
        ? context.value.children
        : categories.value;
});

const history = computed(() => {
    if (!category.value) {
        return [];
    }

    return breadcrumbs({ children: categories.value }, category.value && category.value.id).filter(c => c.name);
});

const filtered = computed(() => {
    return children.value.filter(props.select);
});

watch(() => selected.value, (newCategory) => {
    category.value = newCategory;
}, { immediate: true });

watch(() => categories.value, (newCategories) => {
    if (!props.modelValue && props.initialValue) {
        const initialCategory = breadcrumbs(
            { children: newCategories },
            props.initialValue.id,
        ).at(-1);

        emit('update:modelValue', initialCategory);
    }
}, { immediate: true });

function handleSelect(selectedCategory = {}) {
    category.value = selectedCategory;

    setTimeout(() => {
        emit('update:modelValue', selectedCategory);
        emit('change', selectedCategory);
    }, 100);
}

function handleReset() {
    category.value = null;
}

function handlePrevious() {
    if (context.value.parent) {
        category.value = context.value.parent;
    }
}
</script>

<template>
    <div class="p-4 overflow-hidden rounded-lg">
        <div class="flex flex-wrap items-center justify-between bg-white p-4 -mx-4 -mt-4 z-[1]">
            <div class="flex flex-wrap gap-3">
                <span v-if="!history.length" class="px-2 py-1 text-dust-700 font-semibold">
                    Select a category
                </span>
                <button aria-label="button" v-if="history.length" class="hover:bg-dust-200 hover:text-dust-800 group flex items-center rounded-md bg-dust-100 text-dust-600 text-sm font-medium px-4 py-2 focus:outline-none" type="button" @click="handleReset()">
                    <Icon as="th-solid" class="mr-2" />{{ $t('component.game.category-piker.all-categories') }}
                </button>
                <button aria-label="button" v-if="context && context.parent" class="hover:bg-dust-200 hover:text-dust-800 group flex items-center rounded-md bg-dust-100 text-dust-600 text-sm font-medium px-4 py-2 focus:outline-none" type="button" @click="handlePrevious()">
                    <Icon as="chevron-left" class="mr-2" />{{ $t('component.game.category-piker.previous') }}
                </button>
                <button aria-label="button" v-for="(item, i) of history" :key="i" class="hover:bg-dust-200 hover:text-dust-800 group flex items-center rounded-md bg-dust-100 text-dust-600 text-sm font-medium px-4 py-2 focus:outline-none" type="button" @click="handleSelect(item, true)">
                    <Icon :as="selected && selected.id === item.id ? 'radio-circle-checked' : 'radio-circle'" class="mr-2" />{{ item.name }}
                </button>
                <!-- <button aria-label="button" v-if="category" class="flex items-center rounded-md bg-dust-200 text-dust-800 text-sm font-medium px-4 py-2 cursor-default focus:outline-none">
                    <Icon as="radio-circle" class="mr-2" />
                    {{ category.name }}
                </button> -->
            </div>

            <div class="flex items-center">
                <span v-if="children.length" class="text-dust-600 w-8 h-8 rounded-md bg-dust-100 flex items-center justify-center text-sm">
                    {{ filtered.length }}
                </span>
            </div>
        </div>
        
        <transition
            enter-active-class="transition-transform duration-150"
            leave-active-class="transition-transform duration-150"
            enter-from-class="transform translate-x-full"
            enter-to-class="transform translate-x-0"
            leave-from-class="transform translate-x-0"
            leave-to-class="transform -translate-x-full"
            mode="out-in"
        >
            <div :key="context ? context.id : 'all'" class="grid grid-cols-2 sm:grid-cols-5 gap-4">
                <button aria-label="button" v-if="context" :class="{ '!bg-secondary-50 border border-secondary-500 !text-dust-800': selected && context.id === selected.id }" type="button" class="flex w-[10rem] min-h-[7.5rem] px-2 h-full text-center flex-col items-center justify-center py-4 rounded-md bg-dust-50 hover:bg-dust-100 text-dust-700" @click="handleSelect(context, true)">
                    <img :src="`/img/category-icons/${context.icon}`" :alt="context.name" class="h-10 filter invert-[70%] drop-shadow-md mb-3" @error="e => e.target.src = `/img/category-icons/article-draft-icon.svg`">
                    <span class="font-semibold">{{ context.name || (selected && context.id === selected.id ? $t('component.game.category-piker.selected-all') : $t('component.game.category-piker.select-all')) }}</span>
                </button>

                <button aria-label="button" v-else-if="allowAll" :class="{ '!bg-secondary-50 border border-secondary-500 !text-dust-800': !selected }" class="mt-1 flex w-[10rem] min-h-[7.5rem] px-2 h-full text-center flex-col items-center justify-center py-4 rounded-md bg-dust-50 hover:bg-dust-100 text-dust-700" type="button" @click="handleSelect(null, true)">
                    <Icon as="th-solid" class="text-4xl mb-3 text-dust-700" />
                    <span class="font-semibold">{{ $t('component.game.category-piker.all-categories') }}</span>
                </button>

                <button aria-label="button" v-for="category of filtered" :key="category.id" :class="{ '!bg-secondary-50 border border-secondary-500 !text-dust-800': selected && category.id === selected.id }" type="button" class="flex snap-end w-[10rem] min-h-[7.5rem] px-2 h-full text-center flex-col items-center justify-center py-4 rounded-md bg-dust-50 hover:bg-dust-100 text-dust-700" @click="handleSelect(category)">
                    <img :src="`/img/category-icons/${category.icon}`" :alt="category.name" class="h-10 filter invert-[70%] drop-shadow-md mb-3" @error="e => e.target.src = `/img/category-icons/article-draft-icon.svg`">
                    <span class="font-semibold">{{ category.name }}</span>
                </button>
            </div>
        </transition>
    </div>
</template>