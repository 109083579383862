import { registerSW } from 'virtual:pwa-register';
import { inject, customRef } from 'vue';
import { useEventListener } from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';
import { crossPlatformState } from '@/utils/App';

export const useApp = () => {
    const app = inject('app');

    return app;
};

export const useReaderMode = () => {
    const enabled = useRouteQuery('reader-mode', false, {
        transform: (v) => v === 'true',
    });

    return {
        enabled,
    };
}

export const autoUpdateEnabled = import.meta.env.VUE_APP_AUTOUPDATE;
export const useAutoUpdate = () => {
    let update = null;

    registerSW({
        immediate: true,
        onRegisteredSW (swUrl, r) {
            update = r ? async () => {
                // Check if there is a new service worker installed
                if (!(!r.installing && navigator)) {
                    return;
                }

                // Checks if connection is available
                if (('connection' in navigator) && !navigator.onLine) {
                    return;
                }

                const resp = await fetch(swUrl, {
                    cache: 'no-store',
                    headers: {
                        'cache': 'no-store',
                        'cache-control': 'no-cache',
                    }
                });

                if (resp.status === 200) {
                    await r.update();
                }
            } : null
        }
    })

    return {
        tryUpdate() {
            if (autoUpdateEnabled && update) {
                console.log('Checking latest version');

                update();
            }
        }
    };
}

export const useCrossPlatformState = (key) => {
    const state = crossPlatformState(key);

    return (target, { transform } = {}) => {
        let transformGet = (value) => value
        let transformSet = (value) => value
      
        if (typeof transform === 'function') {
          transformGet = transform
        } else if (transform) {
          if (transform.get)
            transformGet = transform.get
          if (transform.set)
            transformSet = transform.set
        }

        return customRef((track, trigger) => {
            useEventListener(window, 'focus', trigger);

            return {
                get() {
                    track();
    
                    const value = state[target];
                    return transformGet(value);
                },
                set(value) {
                    state[target] = transformSet(value);
                    trigger();
                },
            };
        });
    }
}
