export default requester => {
    return {
        /**
         * Create new prizd
         *
         * @param {object} handle
         **/
        charge (type, data = {}, config = {}) {
            return requester.post(`/prizes/${type}`, data, config);
        },

        update (type, id, data = {}, config = {}) {
            return requester.put(`/prizes/${type}/${id}`, data, config);
        }
    };
}
