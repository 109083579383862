<script setup>
import Navbar from './Navbar.vue'
import UserInfo from './UserInfo.vue'
import TourContainer from '@/components/home/TourContainer.vue'
import { onMounted, watch, ref, computed } from 'vue'
import { usePlatform, useUserPlatform } from '@/composable/platform'
import { on } from '@/modules/bus'
import { useRoute } from 'vue-router'
import { useReaderMode } from '@/composable/app'
import { crossPlatformState } from '@/utils/App';
import { useFeatureFlag } from '@/composable/feature-flag';

const readingState = crossPlatformState('bignerve.reading');
const route = useRoute()
const { account } = useUserPlatform()
const { isUserPlatform, currentPlatform } = usePlatform()
const { enabled: readerMode } = useReaderMode()
const computedStyle = computed(() => {
    if (!currentPlatform.value?.masthead_background_image) {
        return {}
    }

    return {
        backgroundImage: `url(${currentPlatform.value?.masthead_background_image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backgroundRepeat: 'no-repeat',
    }
})

const headerRef = ref(null)
const isSplash = computed(() => route.meta.splash)
const homePageRoute = computed(() => {
    if (isUserPlatform.value && account.value.user.id) {
        return {
            name: 'home.user',
            params: {
                user: account.value.user.screen_name
            }
        }
    }

    return { name: 'home.challenges' }
})

const scrollToTop = () => {
    const header = headerRef.value

    if (header) {
        header.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }
}

const { enabled: readerModeEnabled } = useFeatureFlag('challenge-reader-mode-toggle');

const renderReaderMode = (active) => {
    if (!active || !readerModeEnabled.value) {
        readingState["reader-mode"] = null

        document.documentElement.classList.remove("reader")

        return
    }

    document.documentElement.classList.add("reader")
}

watch(readerMode, (active) => {
    renderReaderMode(active)
}, { immediate: true })

on('header:scroll-to-top', scrollToTop)
on('app:reader-mode:toggle', renderReaderMode)
</script>

<template>
    <header ref="headerRef" class="app-header">
        <Navbar v-if="!isSplash" />
        <div id="navbar-notifications" class="mt-[2.7rem]"></div>
        <div class="relative">
            <div :style="computedStyle" class="absolute top-0 left-0 w-full h-full z-[-1]"></div>
        
            <TourContainer />

            <div class="container mx-auto app-headbar">
                <div class="grid grid-cols-12 gap-4 py-4">
                    <div class="app-headbar__col is-first col-span-12"
                        :class="{ 'sm:col-span-4': !isUserPlatform, 'sm:col-span-6': isUserPlatform }">
                        <RouterLink :to="homePageRoute" title="View this User's Platform Home"
                            class="app-headbar-heading flex items-center space-x-3">
                            <img src="/img/brain.svg" alt="A Nerve Platform Logo" class="w-16" aria-hidden="true">
                            <div>
                                <span class="app-headbar-heading__title"
                                    :class="{ '!text-white text-border': !!currentPlatform?.masthead_background_image }">
                                    {{ currentPlatform?.title || 'Big' }}
                                    <b v-if="isUserPlatform" style="font-weight: 300; color: #bbb;">Nerve</b>
                                </span>
                                <span v-if="currentPlatform?.slogan" class="app-headbar-heading__subtitle"
                                    :class="{ '!text-white text-border': !!currentPlatform?.masthead_background_image }">{{
                                        currentPlatform?.slogan }}</span>
                            </div>
                        </RouterLink>
                    </div>
                    <div v-if="!isSplash && !isUserPlatform" class="col-span-12 sm:col-span-5 app-headbar__col is-middle">
                        <p v-if="currentPlatform?.marketing_phrase" class="app-headbar__slogen"
                            :class="{ '!text-white text-border': !!currentPlatform?.masthead_background_image }">
                            {{ currentPlatform?.marketing_phrase }}
                        </p>
                    </div>
                    <div v-if="!isSplash" class="app-headbar__col is-last col-span-12"
                        :class="{ 'sm:col-span-3': !isUserPlatform, 'sm:col-span-6': isUserPlatform }">
                        <Transition name="entered" mode="out-in">
                            <UserInfo v-if="isUserPlatform && account.user.id" :key="account.user.id" />

                            <div v-else-if="$auth.authenticated" key="helper-panel"
                                class="flex justify-end w-full app-headbar__btn-helper-wrapper">
                                <a href="/challenges/create"
                                    class="btn justify-center items-center is-red !px-4 sm:flex-none">
                                    <span class="btn__icon is-left icon-question-circle" aria-hidden="true"></span>
                                    Create Challenge
                                </a>
                                <a non-functional href="/challenges/test-idea"
                                    class="btn justify-center items-center !px-4 is-green sm:flex-none">
                                    <span class="btn__icon is-left icon-lightbulb-o1" aria-hidden="true"></span>
                                    Test Idea
                                </a>
                                <a non-functional href="#"
                                    class="btn justify-center items-center is-orange disabled hidden !px-4 sm:flex-none"
                                    @click.prevent>
                                    <span class="btn__icon is-left icon-bulb-plus" aria-hidden="true"></span>
                                    Save Idea
                                </a>
                            </div>
                            <button aria-label="button" non-functional v-else type="button" key="take-a-tour"
                                class="flex items-center text-dust-800 px-4 py-2 bg-dust-50 rounded-md border leading-3 hover:bg-dust-100"
                                @click="() => ($bus('tour:next', { guide: 'start-guide' }))">
                                <NIcon as="onboarding-flow" class="text-2xl mr-2" />
                                <span class="font-bold">Take A Tour</span>
                            </button>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
