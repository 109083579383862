<script>
import Help from './Help.vue'
import Step from './Step.vue'
import Idea from './Idea.vue'
import Media from './Media.vue'
import Prize from './Prize.vue'
import Share from './Share.vue'
import Stepper from './Stepper.vue'
import Preview from './Preview.vue'
import Publish from './Publish.vue'
import Duration from './Duration.vue'
import Overview from './Overview.vue'
import Question from './Question.vue'
import MediaArticle from './Article.vue'
import Innovation from './Innovation.vue'
import Assumptions from './Assumptions.vue'
import CaptionFormat from './CaptionFormat.vue'
import InnervationTools from './InnervationTools.vue'
import CategoriesPicker from './Categories.vue'
import useChallengeCreateFlow from '@/mixins/ChallengeCreateFlow'

import { usePlatform } from '@/mixins/ShowsPlatform'
import { breadcrumbs } from '@/utils/Categories'

export default {
    mixins: [useChallengeCreateFlow, usePlatform()],

    inject: ['context', 'form'],

    components: {
        Help,
        Step,
        Idea,
        Prize,
        Share,
        Media,
        Stepper,
        Preview,
        Publish,
        Duration,
        Overview,
        Question,
        Innovation,
        Assumptions,
        MediaArticle,
        CaptionFormat,
        InnervationTools,
        CategoriesPicker,
    },

    data() {
        return {
            activeAssumptionsTab: null,
        }
    },

    computed: {
        stepper() {
            return this.$refs.stepper
        },

        updateCategoryTree: {
            get() {
                return this.form.$parent.categoryTree
            },
            set(categoriesTree) {
                this.form.$parent.categoryTree = categoriesTree
            }
        }
    },

    methods: {
        breadcrumbs,
        startEdition(category) {
            this.$nextTick(() => {
                this.$refs.stepper.number(2)

                this.$bus('challenge:creation-flow:edition-started', category)
            })
        },
        categorySelected(category) {
            this.$bus('challenge:creation-flow:category-selected', category)
        }
    },
}
</script>

<template>
    <Stepper ref="stepper">
        <template #left="{ selected }">
            <div class="order-2 lg:order-1 col-span-12 sm:col-span-4 lg:col-span-4 xl:col-span-3 mt-5 lg:mt-0 ">
                <div class="sm:sticky sm:top-20 sm:z-10">
                    <div class="challenge-section-heading is-center ">
                        <p class="challenge-section-heading__title">
                            <i>Your Question Preview</i>
                        </p>
                        <small class="challenge-section-heading__description">
                            <i v-tooltip="(breadcrumbs({ children: $store.getters['app/challenge/categories'] }, form.fields.challenge.platform_category).map(c => c.name).join(' > '))">
                                In progress
                            </i>
                        </small>
                    </div>

                    <Preview :challenge="form.fields.challenge" />

                    <div class="overflow-hidden">
                        <Transition enter-active-class="transition-all ease-in-out transition-fast"
                            leave-active-class="transition-all ease-in-out transition-fast"
                            enter-from-class="transform -translate-y-full" enter-to-class="transform translate-y-0"
                            leave-from-class="transform translate-y-0" leave-to-class="transform -translate-y-full"
                            appear>
                            <!-- transition-all ease-in-out duration-1000 transform translate-x-0 -->
                            <div v-if="activeAssumptionsTab === 'what' && selected === 'assumptions'" class="flex mt-2">
                                <img src="/img/monkey-on-the-pole.png" class="mr-2" />
                                <div class="px-2">
                                    <h4 class="text-nerve text-2xl font-bold mb-4">
                                        Start with the Monkey first!
                                    </h4>
                                    <p class="text-dust italic">
                                        If you wanted to train a monkey to recite Shakespeare at the top of a 100ft
                                        pole, don’t start with building the pole, start with the most impactful or
                                        difficult part of the problem—monkey first!
                                    </p>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>

            </div>
        </template>
        <Step id="category" class="col-span-12" title="Category" :complete="completedCategory" :disabled="hasSlug"
            hide-actions>
            <div class="flex flex-col items-center mt-2">
                <h2 class="text-center text-nerve text-2xl mb-1">
                    This Question will be created for the <span class="font-bold">{{ contextPlatform.title }}</span>
                    platform.
                </h2>
                <p v-if="!isBignerve" class="text-center text-nerve text-lg">
                    as well as on BigNerve
                </p>
            </div>

            <CategoriesPicker v-model:modelValue="form.fields.challenge.platform_category"
                v-model:categoryTree="updateCategoryTree" @change="startEdition" @selected="categorySelected" />

            <!-- <InnervationTools
                v-if="! form.fields.challenge.platform_category"
                v-model="form.fields.solution_format"
            /> -->
        </Step>
        <Step id="question-description" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Question &amp; Description" :disabled="hasSlug" :complete="completedDescription" show-left>
            <Question ref="question" />
        </Step>
        <Step id="assumptions" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Assumptions" :disabled="hasSlug || !completedDescription" :complete="completedAssumptions" show-left>
            <Assumptions :challenge="$parent.fields.challenge" @activeTab="t => activeAssumptionsTab = t" />
        </Step>
        <Step id="image" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9" title="Image"
            :disabled="hasSlug || !completedDescription" :complete="completedImage" show-left>
            <Media />
        </Step>
        <Step id="your-idea" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Your Idea" :disabled="hasSlug || !completedDescription" :complete="completedIdea" show-left>
            <Idea />
        </Step>
        <Step id="innovation-scale" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Innovation Scale" :disabled="hasSlug || !completedDescription" :complete="completedInnovation"
            show-left>
            <Innovation />
        </Step>
        <Step id="article-source" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Article Source" :disabled="hasSlug || !completedDescription" :complete="completedArticle" show-left>
            <MediaArticle />
        </Step>
        <Step id="duration" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Duration" :disabled="hasSlug || !completedDescription" :complete="completedDuration" show-left>
            <Duration />
        </Step>
        <Step id="money-prizes" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Money &amp; Prizes" :disabled="hasSlug || !completedDescription" :complete="completedPrize"
            show-left>
            <Prize />
        </Step>
        <Step id="caption-format" class="col-span-12 order-1 sm:order-2 sm:col-span-8 lg:col-span-8 xl:col-span-9"
            title="Caption Format" :disabled="hasSlug || !completedDescription" :complete="completedCaptionFormat"
            show-left>
            <CaptionFormat />
        </Step>
        <Step class="col-span-12" title="Preview, Edit &amp; Publish" :disabled="hasSlug || !completedDescription"
            :complete="hasSlug" activator>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-8">
                    <Publish />
                </div>

                <div class="col-span-12 sm:col-span-4">
                    <Overview />
                </div>
            </div>
        </Step>
        <Step class="col-span-12" :disabled="!hasSlug" title="Share" hide-actions>
            <Share />
        </Step>
    </Stepper>
</template>
