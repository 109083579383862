<script>
export default {
  name: "OlympiadsProvider",

  emits: ["loaded"],

  props: {
    platform: String,
  },

  data() {
    return {
      olympiads: {
        bases: [],
        publics: [],
        privates: [],
      },
      process: null,
      busy: false,
    };
  },

  provide() {
    return {
      olympiads: this.olympiads,
    };
  },

  methods: {
    async fetch() {
      try {
        this.busy = true;

        const { data: olympiads } = await this.$api.olympiad.bases(
          this.platform
        );

        this.olympiads.bases = olympiads.data;

        this.$emit("loaded", { olympiads: this.olympiads });

        return olympiads.data;
      } finally {
        this.busy = false;
        this.process = null;
      }
    },
  },

  watch: {
    platform(value) {
      if (value) {
        this.fetch();
      }
    },
  },

  render({ $slots }) {
    return $slots.default();
  },
};
</script>
