export default {
    neuronsEarned: [
        {
            id: 'NX3343NZ0F',
            issuedAt: '2018-10-11T15:41:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'This is the title for a challenge question?',
            pointType: 'catalyst',
            isStatic: false,
            pointsEarned: 10,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'your challenge was rated in your challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId from another user'
        },
        {
            id: 'UV3BQW7UP0',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'catalyst',
            isStatic: true,
            pointsEarned: 5,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'you created a challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId'
        },
        {
            id: 'NX3343NZ0F_1',
            issuedAt: '2018-10-11T15:41:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'This is the title for a challenge question?',
            pointType: 'innovator',
            isStatic: false,
            pointsEarned: 15,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: ' you received a solution rating in the challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId'
        },
        {
            id: 'UV3BQW7UP0_1',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'innovator',
            isStatic: true,
            pointsEarned: 10,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'you created a solution in the challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId'
        },
        {
            id: 'NX3343NZ0F_2',
            issuedAt: '2018-10-11T15:41:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'This is the title for a challenge question?',
            pointType: 'rater',
            isStatic: false,
            pointsEarned: 20,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: "you created a solution rating which was deemed 'good' (by various factors, such as how close it was to the mean rating) in the challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId"
        },
        {
            id: 'UV3BQW7UP0_2',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'rater',
            isStatic: true,
            pointsEarned: 30,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'you created a rating (either solution, challenge, or mentor comment, but this is not specified in the notification) in the challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId'
        },
        {
            id: 'NX3343NZ0F_3',
            issuedAt: '2018-10-11T15:41:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'This is the title for a challenge question?',
            pointType: 'mentor',
            isStatic: false,
            pointsEarned: 40,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'you received a mentor comment rating from another user in the challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId'
        },
        {
            id: 'UV3BQW7UP0_3',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'mentor',
            isStatic: true,
            pointsEarned: 50,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'you created a mentor comment in the challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId'
        },
        {
            id: 'NX3343NZ0F_4',
            issuedAt: '2018-10-11T15:41:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'This is the title for a challenge question?',
            pointType: 'scout',
            isStatic: false,
            pointsEarned: 60,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'your recruit received variable points for any action (in any of the other categories, other than scout) in the challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId so you receive 1/10th of their variable point earnings as scout points'
        },
        {
            id: 'UV3BQW7UP0_4',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'scout',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'another user signed up for the platform using your scoutReferrerId (I can add the name of the user who signed up if needed, or we can keep it anonymous)'
        },
        {
            id: 'UV3BQW7UP0_5',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'insightful',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: ' you submitted a new assumption in a challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 1
        },
        {
            id: 'UV3BQW7UP0_6',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'insightful',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'you submitted a new assumption vote in a challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 2
        },
        {
            id: 'UV3BQW7UP0_7',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'insightful',
            isStatic: false,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'another player submitted an assumption vote/kudo/catalyst assumption vote on your assumption challenge in a challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 1
        },
        {
            id: 'UV3BQW7UP0_8',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'insightful',
            isStatic: false,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: "A catalyst closed their challenge and selected one of your assumptions as one of the new 'who', 'what', or 'why' in a challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId",
            ownershipType: 2
        },
        {
            id: 'UV3BQW7UP0_9',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'insightful',
            isStatic: false,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: " A catalyst closed their challenge early because of your assumption challenge in a challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeId",
            ownershipType: 3
        },
        {
            id: 'UV3BQW7UP0_10',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'director',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'you renerved a challenge titled + challengeTitle + at https://bignerve.com/challenges/challengeIdif neuronsEarned pointType = director, and isStatic = true, and ownershipType = 2 - another player submitted a solution in a challenge you renerved titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 1
        },
        {
            id: 'UV3BQW7UP0_11',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'director',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'another player submitted a solution in a challenge you renerved titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 2
        },
        {
            id: 'UV3BQW7UP0_12',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'director',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'another player submitted a solution rating in a challenge you renerved titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 3
        },
        {
            id: 'UV3BQW7UP0_13',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'director',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'another player submitted a challenge rating in a challenge you renerved titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 4
        },
        {
            id: 'UV3BQW7UP0_14',
            issuedAt: '2019-12-27T14:35:55Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'HYZJUHXFPA',
            challengeTitle: 'How could androids dream of more than just electric sheep?',
            pointType: 'director',
            isStatic: true,
            pointsEarned: 0,
            nerveLevel: 57,
            nervePoints: 2120.5,
            nervePointsToLevel: 15100,
            yourRelatedObjectID: 'ICDAUHXFPA',
            sourceDescription: 'another player submitted a mentor comment rating in a challenge you renerved titled + challengeTitle + at https://bignerve.com/challenges/challengeId',
            ownershipType: 5
        }
    ],
    highlights: [
        {
            id: '78A5Z21HN8',
            issuedAt: '2019-12-27T12:43:59Z',
            recipientID: 'B162NT2Q0R',
            contestId: 'XYZJUHXFPA',
            contextText: 'This is in interesting solution to 123 ...',
            notificationType: 'solution-delta',
            positionNew: 12,
            positionTotal: 400,
            positionDelta: 2,
            yourRelatedObjectID: 'SOMESOLNID1'
        },
        {
            id: 'Z8A5Z21HN8',
            issuedAt: '2019-12-27T11:43:59Z',
            recipientID: 'B162NT2Q0R',
            contestId: '151AXDK40V',
            contextText: 'This is in interesting solution to 1234 ...',
            notificationType: 'solution-delta',
            positionNew: 26,
            positionTotal: 345,
            positionDelta: 82,
            yourRelatedObjectID: 'SOMESOLNID2'
        }
    ],
    tasks: [
        {
            id: '1G9UXR7DTJ',
            issuedAt: '2022-02-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'Visit your Mentor Dashboard',
            targetGameDash: 'mentor',
            subject: 'A Mentor',
            actionToPerform: 'gives encouraging and helpful guidance on ideas.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'mentor text-mentor',
            route: '/my-dashboard/mentor'
        },
        {
            id: '2G9UXR7DTJ',
            issuedAt: '2022-02-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'Create a question now!',
            targetGameDash: 'catalyst',
            subject: 'A Catalyst',
            actionToPerform: 'asks interesting questions for the innovators to explore.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'question-circle-solid text-catalyst',
            route: { name: 'challenges.create' }
        },
        {
            id: '3G9UXR7DTJ',
            issuedAt: '2022-02-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'Share your platform with your friends.',
            targetGameDash: 'scout',
            subject: 'A Scout',
            actionToPerform: 'recruits new talent for the community.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'binoculars-solid text-scout',
            action() {
                this.$bus('sharing:popup', this.$auth.current.user || {})
            }
        },
        {
            id: '4G9UXR7DTJ',
            issuedAt: '2022-02-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'Visit your Analyst Dashboard.',
            targetGameDash: 'rater',
            subject: 'An Analyst',
            actionToPerform: 'rates the creativity of ideas.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'alright text-rater',
            route: '/my-dashboard/rater'
        },
        {
            id: '5G9UXR7DTJ',
            issuedAt: '2022-02-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'Visit your Innovator Dashboard.',
            targetGameDash: 'innovator',
            subject: 'An Innovator',
            actionToPerform: 'enters their clever, imaginative ideas in challenges.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'lightbulb text-innovator',
            route: '/my-dashboard/innovator'
        },
        {
            id: '6F9UXR7DTJ',
            issuedAt: '2022-05-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'Michael Shermer is sponsoring an idea tournament for the Skeptics Society.',
            targetGameDash: 'patron',
            subject: 'A Patron',
            actionToPerform: 'funds questions and innovative thinkers, to help improve the world.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'funding text-patron',
            route: { name: 'platform.olympiad.leaderboard', params: { platformSlug: 'platform' } }
        },
        {
            id: '6F9UXR7DTJ',
            issuedAt: '2022-05-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'Brian Brushwood is sponsoring an idea tournament for Rogue Nation.',
            targetGameDash: 'patron',
            subject: 'A Patron',
            actionToPerform: 'funds questions and ideas from innovative thinkers.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'funding text-patron',
            route: { name: 'platform.olympiad.leaderboard', params: { platformSlug: 'platform' } }
        },
        {
            id: '6G9UXR7DTJ',
            issuedAt: '2022-02-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            object: 'MaineNerve is funding the overall tournament for Maine.',
            targetGameDash: 'patron',
            subject: 'A Patron',
            actionToPerform: 'funds questions they care about, and funds innovative thinkers, to help improve the world.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'funding text-patron',
            route: { name: 'platform.olympiad.leaderboard', params: { platformSlug: 'platform' } }
        },
        {
            id: '7G9UXR7DTJ',
            issuedAt: '2022-02-01T11:47:48-07:00',
            recipientID: '8WQAQ7Y6KT',
            contestId: 'UUY9G78J31',
            challengeTitle: null,
            object: 'Ask your own challenge questions.',
            targetGameDash: 'director, olympiads',
            subject: 'A Director',
            actionToPerform: 'is the host of their own idea tournament, made up of many challenges.',
            relatedObjectID: 'GM61Z7WHUW',
            icon: 'role-icon-director-brown text-director',
            route: { name: 'challenges.create' }
        },
    ]
}
