<script>
// Social networks
import networks from 'vue-social-sharing/src/networks';
import { ShareNetwork } from 'vue-social-sharing';

export default {
    extends: ShareNetwork,

    props: {
        beforeShare: {
            type: Function,
            default: (next) => next(),
        },
    },

    computed: {
        networks() {
            return {
                ...networks,
                imgur: 'https://imgur.com',
            };
        },
    },

    methods: {
        originalShare: ShareNetwork.methods.share,
        originalTouch: ShareNetwork.methods.touch,

        share() {
            // it will be called as beforeShare(next, provider)
            this.beforeShare(this.originalShare.bind(this), this.key);
        },

        touch() {
            // it will be called as beforeShare(next, provider)
            this.beforeShare(this.originalTouch.bind(this), this.key);
        },
    },
}
</script>
