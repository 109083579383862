export default requester => {
    return {
        /**
         * Get channels
         * 
         * @return {object}
         */
        list (platformSlug) {
            return requester.get(`/content/management/${platformSlug}/channels`);
        },

        /**
         * Create channel
         * 
         * @param {*} payload 
         * @returns 
         */
        create (platformSlug, payload) {
            return requester.post(`/content/management/${platformSlug}/channels`, payload);
        },

        /**
         * Create channel
         * 
         * @param {*} payload 
         * @returns 
         */
        update (platformSlug, channelSlug, payload) {
            return requester.put(`/content/management/${platformSlug}/channels/${channelSlug}`, payload);
        },

        /**
         * Get channel
         * 
         * @param {*} platformSlug
         * @param {*} channelSlug
         * @returns
         **/
        invitation (platformSlug, token) {
            return requester.get(`/content/management/${platformSlug}/invites/${token}`);
        },

        /**
         * Join channel
         * 
         * @param {*} platformSlug
         * @param {*} token
         * @param {*} payload
         * @returns
         **/
        join (platformSlug, token, payload) {
            return requester.patch(`/content/management/${platformSlug}/invites/${token}`, payload);
        },

        /**
         * Get all members of a channel
         * 
         * @param {*} platformSlug 
         * @param {*} channelSlug 
         * @returns 
         */
        memberList (platformSlug, channelSlug) {
            return requester.get(`/content/management/${platformSlug}/channels/${channelSlug}/members`);
        },

        /**
         * Create a new signup for a channel
         * 
         * @param {*} platformSlug 
         * @param {*} payload 
         * @returns 
         */
        signupCreate (platformSlug, payload, headers = {}) {
            return requester.post(`/content/channels/${platformSlug}/signup`, payload, headers);
        },

        /**
         * Get all signups of a channel
         * 
         * @param {*} platformSlug 
         * @param {*} channelSlug 
         * @returns 
         */
        signupList (platformSlug, params) {
            return requester.get(`/content/channels/${platformSlug}/signup`, { params });
        },

        /**
         * Operate on a signup
         * 
         * @param {*} platformSlug
         * @param {*} signupId
         * @param {*} payload
         * @returns
         */
        signupOperate (platformSlug, signupId, payload) {
            return requester.patch(`/content/channels/${platformSlug}/signup/${signupId}`, payload);
        },

        /**
         * Update member
         * 
         * @param {*} platformSlug 
         * @param {*} channelSlug 
         * @param {*} memberId
         * @param {*} payload
         * @returns 
         */
        signupUpdate (platformSlug, signupId, payload) {
            return requester.put(`/content/channels/${platformSlug}/signup/${signupId}`, payload);
        },

        /**
         * Update members of a channel
         * 
         * @param {*} platformSlug 
         * @param {*} channelSlug 
         * @param {*} payload 
         * @returns 
         */
        updateMembers (platformSlug, channelSlug, payload) {
            return requester.post(`/content/management/${platformSlug}/channels/${channelSlug}/members`, payload);
        },

        /**
         * Send the invitation to join a channel
         * 
         * @param {*} platformSlug 
         * @param {*} channelSlug 
         * @returns 
         */
        invite (platformSlug, payload) {
            return requester.post(`/content/management/${platformSlug}/invites`, payload);
        },

        /**
         * Send the invitation preview to the user
         * 
         * @param {*} platformSlug 
         * @param {*} channelSlug 
         * @returns 
         */
        previewInvite (platformSlug, payload) {
            return requester.post(`/content/management/${platformSlug}/invites/preview`, payload);
        },

        /**
         * Create account
         * 
         * @param {*} payload 
         * @returns 
         */
        createAccount (payload) {
            return requester.post(`/account/signup/invite`, payload);
        }
    };
}