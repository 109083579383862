<script setup>
import { computed } from 'vue';

const props = defineProps({
    position: [String, Number],
    medals: {
        type: Object,
        default: () => ({
            1: "gold-medal-position",
            2: "silver-medal-position",
            3: "bronze-medal-position"
        })
    },
    star: Boolean
});

const medal = computed(() => props.medals[props.position])
</script>

<template>
    <svg v-if="star" viewBox="0 0 39 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="img-medal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group" transform="translate(5.000000, 1.000000)" fill="#CCCCCC" fill-rule="nonzero" stroke="#FFFFFF">
                <g id="Path">
                    <polygon points="12.2346369 0 22.305739 17.6153846 13.1335703 22.9692308 0 0"></polygon>
                    <polygon points="30 0 16.8664297 22.9692308 7.69426105 17.6230769 17.7653631 0 30 0"></polygon>
                </g>
            </g>
            <polygon id="Path" stroke="#FFFFFF" stroke-width="0.672" fill="#4A90E2" fill-rule="nonzero" transform="translate(19.960600, 23.349000) rotate(-360.000000) translate(-19.960600, -23.349000) " points="10.3901224 28.0430666 1.13744515 18.6861925 14.1724669 17.0190434 20.1950428 5.349 25.8112512 17.227437 38.7837548 19.3426324 29.2184871 28.355657 31.2086463 41.349 19.6844784 35.0346729 7.94149725 40.9426324"></polygon>
            <text font-family="PTSans-NarrowBold, PT Sans Narrow" :font-size="position === '?' || position < 10 ? 13 : 12" font-style="condensed" font-weight="bold" fill="#FFF" :transform="`translate(${position === '?' || position < 10 ? '2' : '-1'} -4)`">
                <tspan :x="position === '?' || position < 10 ? 15 : 14" y="33">
                    <slot name="position">{{ position }}</slot>
                </tspan>
            </text>
        </g>
    </svg>
    
    <img
        v-else-if="!position"
        class="community-card-user-placement-list__rank-medal"
        :src="`/img/medals/unknown-medal.svg`"
        alt="medal"
    />

    <img
        v-else-if="medal"
        class="community-card-user-placement-list__rank-medal"
        :src="`/img/medals/${medal}.svg`"
        alt="medal"
    />

    <span
        v-else
        class="border border-dust-600 rounded-md px-2 py-1 text-sm font-semibold text-dust-700 leading-tight mr-2"
    >
        <slot name="position">{{ position }}</slot>
    </span>
</template>

<script>
export default {
    name: "NMedal",
};
</script>

