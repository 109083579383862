import { createI18n } from 'vue-i18n'

/**
 * messages are generated using vite-plugin-i18n
 * each .json files located in the ./src/locales are registered in messages
 * @see https://github.com/intlify/vite-plugin-vue-i18n
 */
import messages from '@intlify/vite-plugin-vue-i18n/messages'

// const defaultLocale = useStorage('lang', navigator?.language || 'en-US')

const i18n = createI18n({
  locale: 'en-US',
  messages,
  legacy: false,
})

export default i18n