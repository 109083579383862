/** @module search  */
export default requester => {
    return {
        /**
         * Creates a new search index.
         *
         * @param {string} name Name of the index to create
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        createIndex(name, params = {}, headers = {}) {
            return requester.makeRequest('PUT', '/index', { name }, params, headers);
        },

        /**
         * Deletes a search index.
         *
         * @param {string} name Name of the index to delete
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        deleteIndex(name, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', '/index', { name }, params, headers);
        },

        /**
         * Search.
         *
         * @param {string} documentIndexName The name of the index in which to search for the object
         * @param {string} searchField The field on which to search
         * @param {string} query The elastic 'term query' to run
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        search(documentIndexName, searchField, query, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', `/index/${documentIndexName}`, Object.assign({}, { searchField, query }, params), headers
            );
        },

        /**
         * Inserts a new document into the elasticsearch database.
         *
         * @param {string} documentIndexName The name of the index into which to insert the object
         * @param {Object} document The document to insert
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        createDocument(documentIndexName, document, params = {}, headers = {}) {
            return requester.makeRequest('PUT', `/index/${documentIndexName}`, { document }, params, headers);
        },

        /**
         * Updates a document’s index by its ID.
         *
         * @param {string} documentIndexName The name of the index into which to insert the object
         * @param {string} indexID The index of the document
         * @param {Object} document The document to update
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        updateDocument(documentIndexName, indexID, document, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/index/${documentIndexName}/${indexID}`, { document }, params, headers);
        },

        /**
         * Updates a document’s index by its ID.
         *
         * @param {string} documentIndexName The name of the index
         * @param {string} indexID The index of the document
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        deleteDocument(documentIndexName, indexID, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/index/${documentIndexName}/${indexID}`, {}, params, headers);
        },

        /**
         * Initializes each search index
         */
        initialize() {
            return requester.makeRequest('POST', '/search/init');
        },

        /**
         * Gets all tags associated with a given platform.
         *
         * @param {string} platformId The id of the platform
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        platformTags(platformId, params = {}, headers = {}) {
            return requester.makeRequest('GET', '/platform-tags', Object.assign({}, { platformId }, params), headers);
        },

        /**
         * Gets all platforms.
         *
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        platforms(params = {}, headers = {}) {
            return requester.makeRequest('GET', '/search/platforms', params, headers);
        },

        /**
         * Gets all tags associated with a given object
         *
         * @param {string} objectId The id of the object
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        tags(objectId, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/tags', { objectId }, params, headers);
        },

        /**
         * Disassociates a tag and a given object.
         *
         * @param {string} tag The tag to delete
         * @param {string} objectId The ID of object to remove it from
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        deleteTags(tag, objectId, params = {}, headers = {}) {
            return requester.makeRequest('DELETE', '/tags', { objectId, tag }, params, headers);
        }
    }
}
