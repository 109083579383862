import { get } from "lodash";

export default {
    inject: ['form'],

    computed: {
        rules () {
            const platformCategory = get(this.form, 'fields.challenge.platform_category');

            return {
                'innovation-scale': [
                    () => true,  // force hidden
                    () => this.form.$parent.isCaption,
                ],
                'duration': [
                    () => true,  // force hidden
                ],
                'money-prizes': [
                    () => true,  // force hidden
                ],
                'assumptions': [
                    () => true, // force hidden
                    () => this.form.$parent.isCaption,
                    () => (platformCategory || '').includes('comedy-'),
                ],
                'question-description': [
                    () => ! this.form.$parent.usingTool('qq') && this.form.$parent.isCaption,
                ],
                'your-idea': [
                    () => this.$route.name !== 'challenges.test-idea',
                    () => this.form.$parent.isCaption,
                ],
                'article-source': [
                    () => this.form.$parent.isCaption,
                    () => (platformCategory || '').includes('comedy-'),
                ],
                'caption-format': [
                    () => ! this.form.$parent.isCaption,
                ],
            }
        },
        hidden() {
            return this.isHidden(this.slug)
        }
    },

    methods: {
        isHidden (slug) {
            return !this.isOpen && this.rules[slug] && this.rules[slug].some(rule => rule())
        }
    },
}
