<script>
import { children } from '@/pages/faqs/routes';

export default {
    name: 'FaqSidebar',
    data () {
        return {
            routes: children.filter(r => !r.meta.hidden)
        }
    }
}
</script>

<template>
    <div class="page__sidebar-inside">
        <ul v-if="routes.length">
            <li v-for="(route, i) of routes" :key="i">
                <router-link :to="{ name: route.name }">
                    {{ route.meta.breadcrumb }}
                </router-link>
            </li>
        </ul>

        <div v-else class="text-dust">
            Empty!
        </div>
    </div>
</template>
