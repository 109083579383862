<template>
    <div class="tabs__content-helper">
        <p class="form-block-help-text">To promote your idea tournament, share your ReNerved and personal Challenges with your friends, colleagues, and followers. You earn <b>Scout</b> game Neurons (points) for sharing earn <b>Olympiad Director</b> neurons, too. And, best of all, you get more creative ideas by sharing!</p>

        <div class="form-section-wrapper">
            <h2 class="form-group__title mb-5">Customize your invite Email</h2>
            <div class="grid grid-cols-12 gap-4mb-3">
                <div class="col-span-12 md:col-span-10 col-start-2">
                    <div class="form-groups">
                        <div class="form-group is-fixed-inline">
                            <label class="form-group__label">Subject:</label>
                            <NInput></NInput>
                        </div>
                        <div class="form-group is-fixed-inline">
                            <label class="form-group__label">To:</label>
                            <div class="form-group__fix-helper has-two-col">
                                <div>
                                    <NTags />
                                    <p class="form-block-help-text mt-3 mb-0"><i>Paste in your emails separated by a comma. We’ll save these for you to use later. Emails are sent on your behalf from notifications@BigNerve.com, with your email as the reply-to.</i></p>
                                </div>
                                <div class="upload-csv">
                                    <div class="upload-csv__inside">
                                        <span aria-hidden="true" class="upload-csv__icon icon-address-card-regular"></span>
                                        <span class="upload-csv__text"><a href="#">Import your contact list</a><br>CSV, TSV formats</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group is-fixed-inline">
                            <label class="form-group__label">Your Message:</label>
                            <NTextarea size="small"></NTextarea>
                            <NTextarea size="small" class="mt-3"></NTextarea>
                        </div>
                        <div class="form-group is-fixed-inline">
                            <label class="form-group__label">Not editable:</label>
                            <div class="email-preview-body">
                                <p>Here’s the challenge question: <b><u>Question title here up to 180 characters</u></b></p>
                                <p class="is-color-red">{This challenge has [2] Prize[s] [and] $50 on it.}</p>
                                <p>Enter your idea or rate other player’s ideas before the challenge ends on MM/DD/YYYY.</p>
                                <div class="email-preview-body__btns">
                                    <a href="#" class="two-row-btn is-green">
                                        <span aria-hidden="true" class="two-row-btn__icon is-left icon-lightbulb-o1"></span>
                                        <span class="two-row-btn__caption">
                                            <span class="two-row-btn__title">Enter your own idea</span>
                                            <span class="two-row-btn__subtitle">then view & rate other ideas</span>
                                        </span>
                                    </a>
                                    <a href="#" class="two-row-btn is-yellow">
                                        <span aria-hidden="true" class="two-row-btn__icon is-left icon-lightbulb-o1"></span>
                                        <span class="two-row-btn__caption">
                                            <span class="two-row-btn__title">View the Ideas & Rate</span>
                                            <span class="two-row-btn__subtitle">without entering your own idea</span>
                                        </span>
                                    </a>
                                </div>
                                <p><b>What’s this about?</b><br>
                                BigNerve is friendly competiton that’s like a battle of imaginations. It’s a way to help others with our ideas by solving interesting challenges across different topics.</p>
                                <p>You can <a href="#">subscribe to my Game to get notices of all new challenges</a>. Launch your own game.<br><a href="#">Opt-out of future emails from me</a>. Opt-out of all emails from BigNerve. </p>
                            </div>
                        </div>
                        <div class="form-group is-fixed-inline">
                            <span class="form-group__label">Color Theme:</span>
                            <div class="color-picker">
                                <div class="color-picker-item">
                                    <input class="color-picker-item__input" id="blue" type="radio" value="value1" name="color-picker" checked>
                                    <label class="color-picker-item__label" for="blue" style="background-color: #356bac;">Blue</label>
                                </div>
                                <div class="color-picker-item">
                                    <input class="color-picker-item__input" id="light-blue" type="radio" value="value2" name="color-picker" checked>
                                    <label class="color-picker-item__label" for="light-blue" style="background-color: #4a90e2;">Light Blue</label>
                                </div>
                                <div class="color-picker-item">
                                    <input class="color-picker-item__input" id="lime" type="radio" value="value3" name="color-picker" checked>
                                    <label class="color-picker-item__label" for="lime" style="background-color: #cada2a;">Lime</label>
                                </div>
                                <div class="color-picker-item">
                                    <input class="color-picker-item__input" id="gray" type="radio" value="value4" name="color-picker" checked>
                                    <label class="color-picker-item__label" for="gray" style="background-color: #cccccc;">Gray</label>
                                </div>
                                <div class="color-picker-item">
                                    <input class="color-picker-item__input" id="blueish-gray" type="radio" value="value5" name="color-picker" checked>
                                    <label class="color-picker-item__label" for="blueish-gray" style="background-color: #8aa4c1;">Blueish Gray</label>
                                </div>
                                <div class="color-picker-item">
                                    <input class="color-picker-item__input" id="orange" type="radio" value="value6" name="color-picker" checked>
                                    <label class="color-picker-item__label" for="orange" style="background-color: #fc9436;">Orange</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <button aria-label="button" class="btn is-blue">
                Send Now
                <span aria-hidden="true" class="btn__icon is-right icon-paper-plane-regular"></span>
            </button>
        </div>
    </div>
</template>
