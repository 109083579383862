<script>
import bus from '@/modules/bus';
import { ref, provide, onMounted, computed } from 'vue';

export default {
    name: 'Tour',

    props: {
        immediate: {
            type: Boolean,
            default: false,
        },
        showGuide: {
            type: String,
            default: null,
        },
    },

    emits: ['next', 'exit', 'done', 'close'],

    setup (props, { emit }) {
        const context = ref({});
        const container = ref(null)
        const guides = ref([]);
        const activeIndex = ref(-1);
        const isLast = computed(() => activeIndex.value === guides.value.length - 1);

        const setIndex = (target) => {
            if (typeof target === 'function') {
                activeIndex.value = target(activeIndex.value);
                
                return;
            }

            activeIndex.value = target;
        }

        const api = {
            guides,
            isLast,
            context,
            activeIndex,
            setIndex,
            registerGuide: (id, dataRef) => {
                if (!guides.value.find(guide => guide.id === id)) {
                    guides.value = [...guides.value, { id, dataRef }];
                }
            },
            unregisterGuide: (id) => {
                const guide = guides.value.find(guide => guide.id === id);
                const index = guides.value.indexOf(guide);

                if (index !== -1) {
                    guides.value.splice(index, 1);
                }
            },
            goToGuide: (id) => {
                const guide = guides.value.find(guide => guide.id === id);
                const index = guides.value.indexOf(guide);

                if (index !== -1) {
                    setIndex(index);
                }
            },
            nextGuide: () => {
                const nextIndex = activeIndex.value + 1;

                if (nextIndex < guides.value.length) {
                    setIndex(nextIndex);

                    emit('next', { id: guides.value[nextIndex].id });
                }
            },
            exit: () => {
                emit('exit', context.value);

                if (activeIndex.value === guides.value.length - 1) {
                    emit('done', context.value);
                }

                setIndex(-1);
            },
            close: () => {
                emit('close', context.value);

                if (activeIndex.value === guides.value.length - 1) {
                    emit('done', context.value);
                }

                setIndex(-1);
            }
        }

        provide('tour', api);
        provide('is-last', isLast);

        bus.on('tour:next', ({ guide, payload, scrollToTop, delay = 0 } = {}) => {
            setTimeout(() => {
                if (payload) {
                    context.value = payload;
                }

                api.goToGuide(guide);

                if (scrollToTop && container.value) {
                    container.value.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }, delay);
        });

        onMounted(() => {
            if (props.immediate && props.showGuide) {
                api.goToGuide(props.showGuide);
            }
        })

        return { container, context, nextGuide: api.nextGuide, goToGuide: api.goToGuide, exit: api.exit };
    },
}
</script>

<template>
    <div ref="container">
        <slot v-bind="{ context, goToGuide, nextGuide, exit }" />
    </div>
</template>