<script setup>
import { useElementVisibility } from '@vueuse/core';
import { ref, computed, watch } from 'vue';
import { cn } from '@/utils/Helpers';

const fallback = '/img/challenge-image-defaults/challenge-preview-placeholder.svg';

const visible = ref(false)
const target = ref(null);
const props = defineProps({
    src: String,
    alt: String,
    srcs: {
        type: Array,
        default: () => [],
    },
    class: {
        type: String,
        default: '',
    },
});

const isVisible = useElementVisibility(target);
const computedStyle = computed(() => {
    const images = [props.src, ...props.srcs, fallback].filter(Boolean);

    return visible.value ? {
        backgroundImage: images.map(img => `url(${img})`).join(', ')
    } : null;
})

watch(isVisible, (value) => {
    if (value && !visible.value) {
        visible.value = true;
    }
})
</script>

<template>
    <div
        ref="target"
        :style="computedStyle"
        :class="cn('bg-dust-100 overflow-hidden flex-none bg-center bg-cover bg-no-repeat overflow-hidden w-full aspect-4/9', props.class)"
    />
</template>
