import { useAuth } from '@/composable/auth';

export const install = ({ app }) => {
  app.mixin({
    beforeCreate() {
      const { auth } = useAuth();

      this.$auth = auth;
    },
  });
};
