<script>
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';

export default {
    data() {
        return {
            show: {
                oldPassword: false,
                newPassword: false,
            },
            fields: {
                old_password: null,
                new_password: null,
            }
        };
    },

    computed: {
        socialProvider () {
            return get(this.$auth, 'profile.social.accounts.0.provider');
        }
    },

    methods: {
        upperFirst,
        updated() {
            this.fields = {
                old_password: null,
                new_password: null,
            };
        }
    }
}
</script>

<template>
    <article class="page">
        <NPageHeader
            title="Password &amp; Security"
            subtitle="Change your password"
            icon="lock-solid"
        />
        <div class="page__content">
            <p>
                Here you can change your password. (You will not have to sign in again immediately.)
            </p>

            <!-- <p v-if="!!socialProvider" class="mb-6 -m-1 px-3 py-2 text-nerve-600 bg-nerve-100 rounded-md border-nerve-300 border">
                No password is set because you used {{ upperFirst(socialProvider) }} to sign in. If you change your password here,  {{ upperFirst(socialProvider) }} OAUTH still works; you can use either method to sign in.
            </p> -->

            <NDataForm action="/account/password" :data="fields" @success="updated" autocomplete="off">
                <template #default="form" >
                    <NAlert type="danger" closable :open="form.errors.any()">
                        <span>{{ form.errors.get('*') || 'One of the form fields was invalid, please check your changes again?' }}</span>
                    </NAlert>
                    <NAlert type="success" closable :open="form.success">
                        <span>Your password has been updated!</span>
                    </NAlert>

                    <div class="form-groups">
                        <div class="form-group">
                            <label for="password" class="form-group__label">Your Password</label>
                            <p class="form-block-help-text">
                                Make your password strong and do not re-use from any another online account.
                            </p>
                            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
                                <NInput
                                    v-model="form.fields.old_password"
                                    :type="show.oldPassword ? 'text' : 'password'"
                                    label="Old password"
                                    name="old_password"
                                    autocomplete="off"
                                    id="old_password"
                                >
                                    <template #append>
                                        <button aria-label="button" class="btn__only-icon" style="margin-top: -3px;" type="button" @click.prevent="show.oldPassword = ! show.oldPassword">
                                            <span class="icon icon-eye" aria-hidden="true"></span>
                                        </button>
                                    </template>
                                </NInput>
                                <NInput
                                    v-model="form.fields.new_password"
                                    :type="show.newPassword ? 'text' : 'password'"
                                    label="New password"
                                    name="new_password"
                                    autocomplete="off"
                                    id="new_password"
                                >
                                    <template #append>
                                        <button aria-label="button" class="btn__only-icon" style="margin-top: -3px;" type="button" @click.prevent="show.newPassword = ! show.newPassword">
                                            <span class="icon icon-eye" aria-hidden="true"></span>
                                        </button>
                                    </template>
                                </NInput>
                            </div>
                        </div>
                        <button aria-label="button" type="submit" class="btn is-primary px-6" :disabled="form.busy">
                            {{ form.busy ? 'Saving...' : 'Save' }}
                        </button>
                    </div>
                </template>
            </NDataForm>
            <div class="form-group mt-3">
                <!--
                <h3>Enable 2FA</h3>
                <p>We recommend that you enable 2FA (Two Factor Authentication). We support SMS or an authenticator app like <a href="https://authy.com/download/" target="_blank">Authy</a>, or a hardware device like <a href="https://www.yubico.com/" target="_blank">Yubikey</a>.
                </p>
                -->
            </div>
        </div>
    </article>
</template>
