import challenge from './challenge';
import sharing from './sharing';
import platforms from './platforms.json';
import Storage from "@/utils/Storage";
import { oldApi as $nerve } from '@/modules/services';
import { bus as $bus } from '@/modules/bus';

const [, domain, subdomain] = location.hostname.split('.').reverse()

const getPlatform = target =>
    platforms.find(
        platform => platform.subdomain === target
    );

export default {
    namespaced: true,

    state: {
        platform: getPlatform(subdomain) || getPlatform(domain) || getPlatform('bignerve'),
        sidebarCollapsed: false,
        user: { user: {} },
        categories: [],
    },

    getters: {
        platform(state) {
            return state.platform;
        },
        sidebarCollapsed(state) {
            return state.sidebarCollapsed;
        },
        user(state) {
            return state.user;
        }
    },

    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setCurrentPlatform(state, payload) {
            state.platform = payload;
        },
        setSidebarCollapsed(state, payload) {
            state.sidebarCollapsed = payload;
        },
        setCategories(state, payload) {
            state.categories = payload;
        },
        resetUser(state) {
            state.user = { user: {} }
        },
    },

    actions: {
        platform({ commit, dispatch, state }) {
            const match = window.location.hostname.match(/\w+?(?=\.)/);
            const name = match[0] === 'www' ? 'bignerve' : match[0];

            return new Promise((resolve, reject) => {
                $nerve.search.platforms().then(response => {
                    const platform = response.data.find(platform => platform.name.includes(name));
                    if (platform) {
                        commit('setCurrentPlatform', platform);
                        $bus('platform:change', platform);
                    }

                    dispatch('categories', state.platform);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        toggleSidebarCollapsed({ commit }, payload) {
            commit('setSidebarCollapsed', payload);
        },
        user({ commit, rootState }, payload) {
            return new Promise((resolve, reject) => {
                if (payload.user === rootState.auth.currentUser.user.screen_name) {
                    commit('setUser', rootState.auth.currentUser);

                    resolve(rootState.auth.currentUser);
                }

                commit('resetUser');

                $nerve.users.detailed(payload.user).then(response => {
                    commit('setUser', response.data.data);
                    
                    resolve(response.data.data);
                }).catch(error => {
                    console.log(error)
                    reject(error);
                });
            });
        },
        categories({ commit }, { id: platformId } = {}) {
            $nerve.challenges.categories(platformId).then(response => {
                commit('setCategories', response.data.data);
            }).catch(error => {});
        }
    },

    modules: {
        challenge,
        sharing,
    }
}
