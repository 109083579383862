<script setup>
import { PLATFORM_TYPE } from '@/constant/olympiad';
import { computed } from 'vue';

const props = defineProps({
    olympiad: Object,
    type: {
        type: String,
        default: 'homepage'
    },
})

const isUserPlatform = computed(() => {
    return props.olympiad.user && props.olympiad.platform_type === PLATFORM_TYPE.USER;
})

const mainPlatformUrl = computed(() => {
    return !!props.olympiad.platform ? `${props.olympiad.platform.url}` : location.origin;
})

const setup = computed(() => {
    switch (props.type) {
        case 'leaderboard':
            if (isUserPlatform.value) {
                return {
                    to: {
                        name: 'platform.olympiad.leaderboard',
                        params: {
                            platformSlug: props.olympiad.user.handle
                        },
                        query: {
                            olympiad: props.olympiad.id,
                        },
                    }
                };
            }

            return { href: `${mainPlatformUrl.value}/platform/olympiad/leaderboard` };
        case 'homepage':
            if (isUserPlatform.value) {
                return {
                    to: {
                        name: 'home.user',
                        params: {
                            user: props.olympiad.user.handle
                        }
                    }
                };
            }

            return { href: mainPlatformUrl.value };
        default:
            return { name: 'home.challenges' };
    }
})
</script>

<template>
    <Component :is="isUserPlatform ? 'router-link' : 'a'" v-bind="setup">
        <slot />
    </Component>
</template>