<script>
    export default {
        inject: ['form'],
    }
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">
                <b>How innovative do you want the ideas?</b>
            </h2>
            <p class="challenge-section-heading__description">
                Our site is not for common sense, obvious or expected ideas. Yet, creative ideas can exist on a continuum:
            </p>
        </div>
        <div class="form-section-wrapper">
            <div class="innovative-level-wrapper flex flex-wrap mb-4">
                <label v-for="scale in ['blue-sky', 'moonshot', 'playful']" :key="scale" class="mr:0 sm:mr-3">
                    <div
                        class="innovative-level"
                        :class="{ 'is-selected': form.fields.challenge.innovation_scale === scale }"
                    >
                        <img :src="`/img/innovative-level/${scale}.png`" alt="" style="width: 180px">
                        <span v-if="form.fields.challenge.innovation_scale === scale" class="innovative-level__caption">
                            Selected choice
                        </span>
                    </div>
                    <input
                        class="non-functional"
                        type="radio"
                        v-model="form.fields.challenge.innovation_scale"
                        :value="scale"
                    >
                </label>
            </div>
            <p class="mb-2">Definitions:</p>
            <ol>
                <li><b>Blue sky:</b> creative and <i>actually</i> feasible with available time and resources.</li>
                <li><b>Moonshot:</b> creative and <i>possibly</i> feasible, with uncertain time and money.</li>
                <li><b>Playful:</b> creative and <i>in no way</i> feasible; just pure fun.</li>
            </ol>
        </div>
    </div>
</template>
