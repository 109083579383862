<script setup>
import ChallengeYourIdeas from '@/components/challenge-show/YourIdeas.vue';
import { useIdeaCreationFlow, useStoredIdeaData } from '@/composable/idea';
import { isSuggestion, isAMA } from '@/utils/challenge';
import { oldApi as nerve } from '@/modules/services';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';

const router = useRouter();
const loading = ref(false);
const props = defineProps({
    challenge: {
        type: Object,
        required: true,
    },
});

const { removeData } = useStoredIdeaData(props.challenge);
const { totalUserIdeas, storedIdeasData, totalIdeasLeft, maxUserIdeas } = useIdeaCreationFlow(props.challenge);
const isFirstParticipant = computed(() => {
    return props.challenge.stats.total_ideas === 0
        || props.challenge.stats.total_ideas === props.challenge.user_stats.total_ideas;
});
const isSuggestionBox = computed(() => {
    return isSuggestion(props.challenge);
});

const markGameAsStaterted = async () => {
    try {
        loading.value = true;

        const { data: userStats } = await nerve.challenges.startRating(props.challenge.slug);

        props.challenge.stats.total_ideas = userStats.total_challenge_ideas;
        props.challenge.user_stats = userStats;

        removeData();

        router.push({ name: 'challenges.rating-end', params: { challenge: props.challenge.slug } });
    } catch (e) {
        console.log(e);
        
        throw e;
    } finally {
        loading.value = false;
    }
}
</script>

<template>
    <div class="flex justify-center mt-5 mb-5">
        <div class="interstitial-modal">
            <div class="interstitial-modal__heading is-green">
                <h1 class="interstitial-modal__title">
                    <NIcon as="lightbulb" />
                    Your idea is almost ready to be posted!
                </h1>
            </div>
            <div class="interstitial-modal__content">
                <ChallengeYourIdeas :total="totalUserIdeas" :challenge="challenge" class="mb-6" />

                <div v-if="totalUserIdeas" class="mb-6">
                    <div v-for="(_, i) of totalUserIdeas" :key="i" class="font-weight-normal mb-2 shadow rounded text-lg bg-white p-[1rem]">
                        <NIcon as="lock-solid" class="mr-2" />
                        <span v-if="storedIdeasData[i]">{{ storedIdeasData[i].title }}</span>
                        <span v-else class="text-dust italic">Missing Idea Title</span>
                    </div>
                </div>

                <template v-if="!isAMA(challenge)">
                    <p v-if="isFirstParticipant">
                        As the first participant, you can post your ideas directly. Remember, once your ideas are posted, you won't be able to add more. However, you can come back soon to rate ideas from other participants as they join the challenge.
                    </p>

                    <p v-else>
                        To publish your idea(s), you will now rate up to 3 other ideas for each idea you want to enter. Give as much helpful feedback as you can — it helps everyone get better.
                    </p>
                </template>

                <div class="flex flex-col items-center justify-around gap-4 mb-8 sm:flex-row sm:items-start">
                    <div :class="{ disabled: !totalIdeasLeft }" class="text-center w-60">
                        <RouterLink :to="{ name: 'challenges.show', params: { challenge: $route.params.challenge } }" class="btn is-green mb-1" tag="button">
                            <span aria-hidden="true" class="btn__icon is-left icon-lightbulb-o1"></span>
                            {{ totalIdeasLeft >= 1 ? 'Enter another idea?' : 'Enter an Idea' }}
                        </RouterLink>
                        <p class="is-color-green mb-0 mt-2 italic">
                            Add up to {{ maxUserIdeas }} of your ideas. <span v-if="!isSuggestionBox">Once you start rating, you can’t add more ideas in this challenge</span>
                        </p>
                    </div>
                    
                    <div v-if="isFirstParticipant" class="text-center w-60">
                        <button aria-label="button" type="button" class="btn is-yellow mb-1" @click="markGameAsStaterted">
                            <span aria-hidden="true" class="btn__icon is-left icon-alright"></span>
                            {{ loading ? 'Posting my idea...' : 'Post my idea now' }}
                        </button>
                        <p class="is-color-yellow mb-0 mt-2">
                            <i>Publish your ideas directly to the challenge. You can come back to rate ideas soon.</i>
                        </p>
                    </div>
                    <div v-else class="text-center w-60">
                        <RouterLink :to="{ name: 'challenges.rate', params: { challenge: $route.params.challenge } }" class="btn is-yellow mb-1">
                            <span aria-hidden="true" class="btn__icon is-left icon-alright"></span>
                            Start Rating
                        </RouterLink>
                        <p class="is-color-yellow mb-0 mt-2">
                            <i>Then your idea(s) get unlocked and posted in the challenge</i>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
