import Requester from './requester';
import adminEndpoints from './endpoints/admin';
import assumptionEndpoints from './endpoints/assumptions';
import attachmentEndpoints from './endpoints/attachments';
import challengeEndpoints from './endpoints/challenges';
import commentEndpoints from './endpoints/comments';
import feedbackEndpoints from './endpoints/feedback';
import gamePointEndpoints from './endpoints/gamePoints';
import ideaLogEndpoints from './endpoints/ideaLogs';
import ideaOlympiadEndpoints from './endpoints/ideaOlympiad';
import ideaRaceEndpoints from './endpoints/ideaRace';
import notificationEndpoints from './endpoints/notifications';
import organizationEndpoints from './endpoints/organizations';
import ratingsEndpoints from './endpoints/ratings';
import searchEndpoints from './endpoints/search';
import solutionEndpoints from './endpoints/solutions';
import userEndpoints from './endpoints/users';
import utilEndpoints from './endpoints/utils';
import widgetEndpoints from './endpoints/widgets';

export default class Api {
    constructor(config) {
        this.requester = new Requester(config);
    }

    get admin() {
        return adminEndpoints(this.requester);
    }

    get assumptions() {
        return assumptionEndpoints(this.requester);
    }

    get attachments() {
        return attachmentEndpoints(this.requester);
    }

    get challenges() {
        return challengeEndpoints(this.requester);
    }

    get comments() {
        return commentEndpoints(this.requester);
    }

    get feedback() {
        return feedbackEndpoints(this.requester);
    }

    get gamePoints() {
        return gamePointEndpoints(this.requester);
    }

    get ideaLogs() {
        return ideaLogEndpoints(this.requester);
    }

    get ideaOlympiads() {
        return ideaOlympiadEndpoints(this.requester);
    }

    get ideaRaces() {
        return ideaRaceEndpoints(this.requester);
    }

    get notifications() {
        return notificationEndpoints(this.requester);
    }

    get organizations() {
        return organizationEndpoints(this.requester);
    }

    get ratings() {
        return ratingsEndpoints(this.requester);
    }

    get search() {
        return searchEndpoints(this.requester);
    }

    get solutions() {
        return solutionEndpoints(this.requester);
    }

    get users() {
        return userEndpoints(this.requester);
    }

    get utils() {
        return utilEndpoints(this.requester);
    }

    get widgets() {
        return widgetEndpoints(this.requester);
    }
}
