<script setup>
import { useIdeaContent } from '@/queries/idea';
import { provide } from 'vue';

const props = defineProps({
    ideaId: {
        type: String,
        required: true,
    },
    challengeSlug: {
        type: String,
        required: true,
    },
});

const { data, refetch, isLoading, error } = useIdeaContent(props.challengeSlug, props.ideaId);

provide('ideaContent', { content: data, refetch, loading: isLoading, error });
</script>

<template>
    <slot :content="data" :refetch="refetch" :loading="isLoading" :error="error">
    </slot>
</template>