<script setup>
import { ref, shallowRef, watch, useSlots } from 'vue';

const props = defineProps({
    promise: {
        type: [Promise, Function],
        required: true
    },
    prune: {
        type: Function,
        default: d => d,
    },
    immediate: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(['done', 'rejected']);

const error = ref(null);
const loading = ref(false);
const payload = shallowRef({});
const slots = useSlots();

const trigger = async (...args) => {
    try {
        loading.value = true;

        const result = await (typeof props.promise === 'function'
            ? props.promise(...args) : props.promise
        )
        
        payload.value = props.prune(result && result.request ? result.data : result);

        emit('done', payload.value);
    } catch (err) {
        error.value = err;

        console.error(err);

        emit('rejected', err);
    } finally {
        loading.value = false;
    }
};

watch(() => props.promise, trigger, { immediate: props.immediate });

defineExpose({
    trigger,
    loading,
    error,
    payload,
});
</script>

<template>
    <slot v-if="!slots.done" v-bind="payload" :loading="loading" :error="error" :trigger="trigger" />
    <slot v-else-if="loading" name="loading" />
    <slot v-else-if="error" name="rejected" />
    <slot v-else v-bind="payload" name="done" :trigger="trigger" />
</template>