<script>
import get from 'lodash/get';

export default {
    props: {
        challenge: {
            type: Object,
            required: true,
        },
        challengeable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isExpanded: true,
        };
    },

    computed: {
        why() {
            return get(this.challenge, 'deep_dives.why', null);
        },
        personal() {
            return this.why ? this.why.personal_reasons.filter(item => !! item.reason) : [];
        },
        stakeholder() {
            return this.why ? this.why.stakeholder_reasons.filter(item => !! item.reason) : [];
        },
        hasContent() {
            return this.personal.length || this.stakeholder.length;
        }
    },

    methods: {
        toggle() {
            this.isExpanded = ! this.isExpanded;
        }
    },
    
    mounted() {
        this.isExpanded = this.hasContent;
    }
}
</script>

<template>
    <div class="challenge-deep-dive-card mt-4 border-2 border-director-100">
        <div class="challenge-deep-dive-card__content">
            <h2 class="challenge-deep-dive-card__title">Why is the solution important to you and to others?</h2>
            <p class="challenge-deep-dive-card__description m-0">
                {{ challenge.assumption_why }}
            </p>
            <div v-if="why" class="challenge-deep-dive-card__collapsed mt-4 mb-3" v-show="isExpanded">
                <h3 v-if="personal.length > 0" class="form-group__title is-blue">
                    Why is this important <u>for you</u>?
                </h3>
                <div v-if="personal.length > 0" class="incentive-scale-list">
                    <div v-for="(reason, index) in personal" :key="index" class="incentive-scale-list__item">
                        <div class="content"><p>{{ reason.reason }}</p></div>
                        <div class="incentive-scale">
                            <span class="incentive-scale__title">Incentive Scale</span>
                            <span class="incentive-scale__numbers">
                                <span class="incentive-scale__current-value">{{ reason.strength }}</span> of 10
                            </span>
                        </div>
                    </div>
                </div>
                <h3 v-if="stakeholder.length > 0" class="form-group__title is-blue mt-5">
                    Why is this important for all the <u>Stakeholders</u>:
                </h3>
                <div v-if="stakeholder.length > 0" class="incentive-scale-list">
                    <div v-for="(reason, index) in stakeholder" :key="index" class="incentive-scale-list__item">
                        <div class="content">
                            <p>{{ reason.reason }}</p>
                        </div>
                        <div class="incentive-scale">
                            <span class="incentive-scale__title">Incentive Scale</span>
                            <span class="incentive-scale__numbers">
                                <span class="incentive-scale__current-value">{{ reason.strength }}</span> of 5
                            </span>
                        </div>
                    </div>
                </div>
                <h3 v-if="why.anthropology_experiments" class="form-group__title is-blue mt-5">
                    Anthropolgy —evidence that this is urgent and compelling:
                </h3>
                <p v-if="why.anthropology_experiments">{{ why.anthropology_experiments }}</p>
            </div>
            <button aria-label="button" v-if="hasContent" :class="{ 'is-disabled': !hasContent }" type="button" class="challenge-deep-dive-card__toggle flex items-center" @click.prevent="toggle" :disabled="! hasContent">
                <span>{{ isExpanded ? 'Hide Full Details' : 'View Full Details' }}</span>
                <NIcon :as="isExpanded ? 'caret-square-up-solid' : 'caret-square-down-solid'" class="-mb-1" right />
            </button>
        </div>
        <div class="challenge-deep-dive-card__footer flex flex-wrap items-center justify-between">
            <span
                class="challenge-deep-dive-card__question"
                :class="challenge.assumption_why || hasContent ? 'is-active' : 'is-disabled'"
            >
                <span aria-hidden="true" class="icon icon-check-circle-solid"></span>
                <b class="mr-1">Why</b>
                <span v-if="hasContent">with Deep Dive</span>
            </span>
            <div v-if="challengeable" class="flex flex-wrap items-center">
                <button aria-label="button" :class="{ 'is-disabled': !hasContent }" type="button" class="btn is-small mr-2" :disabled="! hasContent">
                    <NIcon as="eye" left />
                    Challenge this Assumption
                </button>
                <span :class="{ 'is-color-default': hasContent, 'is-color-gray': ! hasContent }">(0)</span>
            </div>
        </div>
    </div>
</template>