<script>
export default {
    emits: ['select'],
}
</script>


<template>
    <div class="my-5">
        <div class="flex flex-col items-center justify-center">
            <div class="grid grid-cols-1 sm:grid-cols-3 gap-4 challenge-categories__grid">
                <div class="col-category-item">
                    <button aria-label="button" class="category-tile is-blue is-in-grid" @click.prevent="$emit('select')">
                        <img class="category-tile__icon" src="/img/challenge-category-icons/lightbulb-solid.svg" alt="">
                        <span class="category-tile__name">Ask a Question</span>
                    </button>
                </div>
                <div class="col-category-item">
                    <button aria-label="button" class="category-tile is-blue is-in-grid">
                        <img class="category-tile__icon" src="/img/challenge-category-icons/question-circle-solid.svg" alt="">
                        <span class="category-tile__name">Test an Idea</span>
                    </button>
                </div>
                <div class="col-category-item">
                    <button aria-label="button" class="category-tile is-blue is-in-grid">
                        <img class="category-tile__icon" src="/img/challenge-category-icons/question-circle-solid.svg" alt="">
                        <span class="category-tile__name">Save an Idea</span>
                    </button>
                </div>
            </div>
            <div class="info-box mb-4 -mt-4 max-w-4xl">
                <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
                <p class="info-box__content">
                    <b>Note:</b> a "Question Challenge" is an interesting question that you post to the community and in return receive creative ideas &amp; solutions that have been rated by the "wisdom of the crowd". You also might already have an <b>idea or message</b> that you would like to test with the community or have it improved.
                </p>
            </div>
        </div>
    </div>
</template>
