export const RANK_CRITERIA = {
    MINIMAL_QUANTITY: 'MinimalQuantity',
    UNLOCKED_OBJECT: 'UnlockedObject'
}

export const CHALLENGE_FORMAT = {
    SUGGESTION: 'suggestion',
    STANDARD: 'standard',
}

export const SOLUTION_FORMAT = {
    QQ: 'qq',
    TOPIC: 'topic',
    CHALLENGE: 'challenge',
    AMA: 'ama',
}