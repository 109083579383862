<script>
import { computed } from 'vue';
import { usePlatform } from '@/mixins/ShowsPlatform';
import { useRouteBreadcrumbs } from '@bignerve/ui-shared'
import { useRoute, useRouter } from 'vue-router';
import { useAuth } from '@/composable/auth';
import uniqBy from 'lodash/uniqBy';

export default {
    mixins: [usePlatform()],

    props: {
        params: {
            type: Object,
            default: () => {}
        },
        prepend: {
            type: Array,
            default: () => ([])
        },
        append: {
            type: Array,
            default: () => ([])
        },
        disableAutoCrumbs: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            homeRoute: { title: 'Home', name: 'home.challenges' }
        };
    },

    watch: {
        'account.user': {
            handler: 'resolveHomeRoute',
            immediate: true,
        },
    },

    methods: {
        resolveHomeRoute() {
            if (this.isUserPlatform && this.profilePage && this.account.user.id) {
                this.homeRoute = {
                    title: this.viewingSelf ? 'My Home' : 'Home',
                    name: 'home.user',
                    params: {
                        user: this.account.user.screen_name
                    }
                };

                return;
            }

            this.homeRoute = { title: 'Home', name: 'home.challenges' };
        },
    },

    setup (props) {
        const route = useRoute();
        const router = useRouter();
        const { auth } = useAuth();
        const { breadcrumbs: items } = useRouteBreadcrumbs({ auth }, { route, router });

        const crumbs = computed(() => {
            if (props.disableAutoCrumbs) {
                return []
            }

            if (items.value.length) {
                return items.value;
            }

            return [];
        })


        const breadcrumbs = computed(() => {
            return [
                ...props.prepend,
                ...uniqBy(crumbs.value, 'title'),
                ...props.append,
            ];
        })

        return { breadcrumbs };
    }
}
</script>

<template>
    <div class="page__breadcrumb flex items-center justify-between overflow-x-auto sb-dust-800 sb-tight sm:overflow-x-none">
        <slot name="prepend" />

        <ul class="breadcrumbs is-large w-full">
            <li><router-link class="breadcrumb-item" :to="homeRoute">{{ homeRoute.title }}</router-link></li>
            <li v-for="(item, index) in breadcrumbs" :key="index" :class="{ 'text-xl leading-none font-bold text-nerve sm:truncate': index == breadcrumbs.length - 1 }">
                <router-link v-if="index < breadcrumbs.length - 1" class="breadcrumb-item" :to="item.route ? item.route : `/${item.path}`">
                    {{ item.title }}
                </router-link>
                <template v-else>{{ item.title }}</template>
            </li>
        </ul>

        <slot name="append" />
    </div>
</template>
