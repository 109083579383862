<script>
import Validatable from '@/mixins/Validatable';

export default {
    mixins: [Validatable],

    props: {
        modelValue: {
            type: [Array, Object],
            default: () => []
        }
    },

    emits: ['update:modelValue'],

    watch: {
        checked(n, o) {
            this.update();
        }
    },

    data() {
        return {
            busy: this.form.busy,
            checked: Array.isArray(this.modelValue) ? this.modelValue : []
        };
    },

    methods: {
        update() {
            this.form.errors.clear(this.id);
            this.$emit('update:modelValue', this.checked);
        }
    }
}
</script>

<template>
    <div :class="{ 'is-error': invalid }">
        <label v-if="label" class="form-group__label" style="display: block;">{{ label }}</label>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <slot></slot>
        <span v-if="invalid" class="form-group__error">{{ error }}</span>
    </div>
</template>
