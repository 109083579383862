<script>
export default {
    name: 'Learn',
}
</script>


<template>
    <div class="non-functional onboarding-video mt-4"><!-- until we add the real How to Play video -->
        <slot v-bind="{ on: { click: () => $refs.modal.open() } }">
            <div class="onboarding-video__overlay" @click="() => $refs.modal.open()">
                <h2 class="onboarding-video__title">Learn about the Idea Game and how to play!</h2>
                <span aria-hidden="true" class="onboarding-video__icon icon-play-circle-regular"></span>
                <span class="onboarding-video__caption">Watch video</span>
            </div>
        </slot>

        <NModal ref="modal">
            <div class="modal-video-helper">
                <button aria-label="button" class="icon-times-circle-regular absolute top-0 right-0 -mt-8 text-white text-xl hover:text-gray-200" @click.prevent="() => $refs.modal.close()"></button>
                <div class="relative p-4/11 bg-black">
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/bsSy3p5lvgo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </NModal>
    </div>
</template>
