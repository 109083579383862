<script>
export default {
    props: {
        challenge: {
            type: Object,
        },
    },
}
</script>

<template>
    <div
        v-if="challenge && challenge.co_author && challenge.co_author.id"
        class="flex items-center space-x-2"
    >
        <router-link v-tooltip="'Question for a Question'" :to="{ name: 'challenges.show', params: { challenge: challenge.slug } }">
            <NIcon as="qq" class="text-innovator text-2xl" />
        </router-link>
        <NPlayerDetails
            v-tooltip="{ html: true, content: $t('challenges.co-author.tip', {
                co_author_name: `${challenge.co_author.first_name} ${challenge.co_author.last_name }`,
                catalyst_name: `${challenge.catalyst.first_name} ${challenge.catalyst.last_name }`,
            }) }"
            :user="challenge.co_author"
            :clamp="false"
            medium
        />
    </div>
</template>