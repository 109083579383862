<script setup>
import NAvatar from '@/components/NAvatar.vue';
import { cn } from '@/utils/Helpers';
import { computed } from 'vue';

const props = defineProps({
    tag: {
        type: String,
        default: 'div'
    },
    to: {
        type: Object
    },
    participants: {
        type: Array,
        default: () => []
    },
    show: {
        type: Number,
        default: 3
    },
    friends: {
        type: Boolean,
        default: false
    },
    anonymous: {
        type: [Boolean, Number],
        default: false
    },
    class: {
        type: String,
        default: ''
    }
});

const normalized = computed(() => {
    return props.participants.slice(0, props.show);
});

const wrapper = computed(() => {
    return props.to ? 'router-link' : props.tag;
});

const anonymity = computed(() => {
    return Number.isInteger(props.anonymous)
        ?  props.participants.length <= props.anonymous
        : props.anonymous;
});
</script>

<template>
    <component :is="wrapper" :to="to" :class="cn('challenge-card__friends flex flex-row items-center', props.class)">
        <template v-if="participants.length > 0">
            <component
                :is="anonymity || to ? 'div' : 'router-link'"
                v-for="(participant, i) of normalized"
                :key="i"
                :to="{ name: 'home.user', params: { user: participant.handle } }">
                <NAvatar
                    v-tooltip="anonymity ? 'Hidden' : participant.handle"
                    :src="anonymity ? null : participant.avatar_url"
                    :alt="participant.handle"
                    small
                />
            </component>
        </template>
        <slot v-else name="empty" :show="show">
            <NAvatar v-tooltip="'Hidden'" small src="/img/user-profile-images/user-avatar-circle-anon.svg" />
        </slot>
        <span v-if="participants.length > show">
            <slot name="label" :show="show" :total="participants.length">
                <span v-if="friends" class="more-friend">
                    <NIcon as="friends" /><b class="is-color-secondary">+{{ participants.length - show }} players</b>
                </span>
                <span v-else class="is-color-dark-gray">
                    and <b>+{{ participants.length - show }} others</b>
                </span>
            </slot>
        </span>
    </component>
</template>
