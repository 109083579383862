<script setup>
import { computed } from 'vue'

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
    to: {
        type: Object,
        required: false
    },
    simple: {
        type: Boolean,
        default: false
    },
    medium: {
        type: Boolean,
        default: false
    },
    anonymous: {
        type: Boolean,
        default: false
    },
    clamp: {
        type: Boolean,
        default: true
    },
    routable: {
        type: Boolean,
        default: true
    },
});
const handle = computed(() => {
    return props.user.handle || props.user.screen_name;
})
const isRoutable = computed(() => {
    return props.routable && !props.anonymous && !!handle.value && !(/recipient|anonymous/ig.test(handle.value));
})
const fullname = computed(() => {
    let { first_name, last_name } = props.user;

    if (props.anonymous) {
        return 'Anonymous';
    }

    if (!props.user.id) {
        return 'Account removed';
    }

    if (!handle.value) {
        return 'Will be revealed soon';
    }

    if (first_name.includes('SurveyRecipient')) {
        first_name = 'Survey'
        last_name = 'Recipient'
    }

    return (
        first_name || last_name
            ? `${first_name} ${last_name}`
            : handle.value
    )
})
const interests = computed(() => {
    const { bio } = {};

    if (bio && bio.interests) {
        return bio.interests.slice(0, 3).join(', ');
    }
})
const route = computed(() => {
    return props.to || {
        name: 'home.user',
        params: { user: handle.value },
    };
})
</script>

<template>
     <Component :is="isRoutable ? 'router-link' : 'div'" :to="isRoutable ? route : null" :class="{ 'opacity-30': !user.id }" :data-user-id="$auth.isAdmin && user.id" data-origin="game-players-details"  class="flex items-center" aria-label="Link to user platform">
        <NAvatar :src="user.avatar_url" :alt="handle" class="mr-2 flex-none" :large="!medium" />
        <div class="flex flex-col items-start">
            <span class="mb-0 leading-5" :class="[clamp ? 'line-clamp-1' : 'whitespace-nowrap', { 'text-secondary': isRoutable }]">
                {{ fullname }}
            </span>
            <span v-if="!simple && handle" class="text-dust-700 leading-none flex items-center">
                <small class="mr-1" :class="clamp ? 'line-clamp-1' : 'whitespace-nowrap' ">
                    @{{ handle }}
                </small>
                <img src="/img/brain.svg" alt="Bing Nerve" aria-hidden="true" class="h-3 mt-[1px]">
            </span>
            <span v-if="interests" class="discover-media-item__meta text-dust-700" :class="clamp ? 'line-clamp-1' : 'whitespace-normal' ">
                {{ interests }}
            </span>
        </div>
    </Component>
</template>

<script>
export default {
    name: 'PlayerDetails',
}
</script>
