<script setup>
import { useCategory } from '@/composable/category';
import { ref, computed } from 'vue';

const props = defineProps({
    modelValue: String,
});

const emit = defineEmits(['update:modelValue']);
const target = computed(() => props.modelValue);
const show = ref(false);
const modal = ref(null);

const { categories, category: current } = useCategory(target);

const select = (category) => {
    show.value = false;

    const slug = category && category.slug

    if (slug === props.modelValue) {
        return;
    }

    emit('update:modelValue', slug);
};
</script>

<template>
    <div id="categories-picker">
        <button aria-label="button" type="button" @click="() => modal.open()" class="flex items-center px-3 py-[0.5rem] border rounded-md whitespace-nowrap text-white bg-dust-600 hover:bg-dust-500">
            <img v-if="current" class="h-6" :src="`/img/category-icons/${current.icon}`"/>
            <NIcon v-else as="th-solid" class="text-lg" />
            <span class="ml-2 font-bold text-lg leading-none">{{ current ? current.name : 'Subcategories' }}</span>
        </button>
        <NModal v-model="show" ref="modal" #default="{ visible }">
            <div class="bg-white p-4 rounded-md flex flex-col m-4">
                <div class="flex items-center justify-between border-b pb-1">
                    <div class="flex items-center text-dust-700">
                        <NIcon as="th-solid" class="text-lg" />
                        <span class="ml-2 font-bold text-lg">Top Categories</span>
                    </div>
                    <!-- <span class="italic text-dust">
                        tap again to remove
                    </span> -->
                    <button aria-label="button" type="button" class="text-dust-700 h-8 w-8 rounded-full flex items-center justify-center hover:bg-dust-100" @click="() => modal.close()">
                        <NIcon as="close" />
                    </button>
                </div>
                <div v-if="visible" class="pt-6">
                    <div class="grid grid-cols-2 sm:grid-cols-3 gap-2 category-item">
                        <button aria-label="button" type="button" :class="{ 'font-bold !text-white !bg-secondary-700': !current }" class="bg-secondary  hover:bg-secondary-600 whitespace-nowrap h-[7.5rem] rounded-md text-white flex flex-col items-center justify-center p-4" @click="select(null)">
                            <NIcon as="th-solid" class="text-2xl" />
                            <span class="mt-2">All Categories</span>
                        </button>
                        <template v-for="(category, index) in categories" :key="index" >
                            <button aria-label="button" type="button" :class="{ 'font-bold !bg-secondary-700': current && category.slug === current.slug }" class="bg-secondary hover:bg-secondary-600 h-[7.5rem] flex items-center justify-center flex-col !text-white rounded-md p-4" @click="select(category)">
                                <div class="">
                                    <img class="h-8" :src="`/img/category-icons/${category.icon}`" />
                                </div>
                                <span class="mt-2">{{ category.name }}</span>
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </NModal>
    </div>
</template>
