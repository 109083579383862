export default requester => {
    return {
        /**
         * Retrives preferences.
         */
        preferences () {
            return requester.get(`/me/preferences`);
        },

        /**
         * Save preferences.
         *
         * @param {object} payload
         **/
        savePreferences (payload = {}) {
            return requester.put('/me/preferences', payload);
        },
    };
}
