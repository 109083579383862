const defaultProps = {
    ms: 1000 * 60 * 1,
    handler: () => {},
    immediate: false,
}

export const useInterval = (props = {}) => {
    const { ms, handler, immediate } = Object.assign(defaultProps, props)

    return {
        data () {
            return {
                intervalTimes: 0,
                intervalEnabled: true,
                intervalLastCall: null,
                intervalUpdater: null,
                intervalRunning: !!immediate,
            };
        },
        watch: {
            intervalRunning (running) {
                if (running && this.intervalEnabled) {
                    this.instervalStartUpdater()
                } else {
                    this.intervalStopUpdater()
                }
            }
        },
        methods: {
            intervalHandler () {},
            instervalStartUpdater () {
                if (!this.intervalEnabled) {
                    return;
                }

                this.intervalUpdater = setInterval(() => {
                    if (typeof handler === 'string') {
                        this[handler]()
                    }

                    if (typeof hanlder === 'function') {
                        handler.call(this)
                    }

                    this.intervalTimes = this.intervalTimes + 1;
                    this.intervalLastCall = new Date();
                    this.intervalHandler();
                    this.$forceUpdate();
                }, ms);
            },
            intervalStopUpdater() {
                if (this.intervalUpdater) {
                    clearInterval(this.intervalUpdater);
                    this.intervalUpdater = null;
                }
            },
            intervalDisable () {
                this.intervalEnabled = false;
                this.intervalRunning = false;
                this.intervalStopUpdater();
            },
        },
        mounted () {
            if (this.intervalRunning) {
                this.instervalStartUpdater()
                this[handler]()
            }
        },
        beforeDestroy () {
            this.intervalStopUpdater()
        },
    }
}
