<script>
import Skeleton from './Skeleton.vue';

export default {
    components: {
        Skeleton
    }
};
</script>

<template>
    <skeleton :height="550">
        <rect x="0" y="0" rx="3" ry="3" width="400" height="220" />

        <circle cx="50" cy="280" r="30"  />
        <rect x="100" y="265" rx="3" ry="3" width="100" height="30" />

        <rect x="25" y="330" rx="3" ry="3" width="100" height="15" />
        <rect x="140" y="330" rx="3" ry="3" width="50" height="15" />
        <rect x="205" y="330" rx="3" ry="3" width="100" height="15" />

        <rect x="25" y="355" rx="3" ry="3" width="50" height="15" />
        <rect x="90" y="355" rx="3" ry="3" width="100" height="15" />
        <rect x="205" y="355" rx="3" ry="3" width="150" height="15" />

        <rect x="25" y="380" rx="3" ry="3" width="100" height="15" />
        <rect x="140" y="380" rx="3" ry="3" width="125" height="15" />

        <slot />
    </skeleton>
</template>
