import { store } from '@/store';
export const install = ({ app }) => {
    app.mixin({
        beforeCreate() {
            this.$platform = {
                current: store.getters['app/platform'],
            };
        },
    });
};
