<script>
export default {
    name: 'NDropdownMenu',

    props: {
        modelValue: Boolean,
        disabled: Boolean,
        width: [Number, String]
    },

    emits: ['update:modelValue', 'change', 'close', 'open'],

    computed: {
        active: {
            get () {
                return this.modelValue;
            },
            set (value) {
                this.$emit('update:modelValue', value);
            }
        },
        style () {
            return {
                width: this.width && `${this.width}px`
            };
        }
    },

    methods: {
        toggle () {
            if (this.disabled) {
                return;
            }

            this.active = !this.active;
            this.$emit('change', this.active);

            this.$nextTick(() => {
                if (this.active) {
                    this.$emit('open');
                } else {
                    this.$emit('close');
                }
            })
        },
        close () {
            this.active = false;
            this.$emit('close');
        }
    }
}
</script>

<template>
    <div class="dropdown-menu h-[35px] sm:h-auto" :style="style" @keyup.esc="close()">
        <div v-click-outside="close" class="dropdown-menu__wrapper" :class="{ 'is-active z-[20]': active }" >
            <slot name="activator" :close="close" :on="{ click: toggle, 'click.outside': toggle }" />
            <div v-show="active" class="dropdown-menu__wrapper-content">
                <slot />
            </div>
        </div>
    </div>
</template>
