<script setup>
import toUpper from 'lodash/toUpper'
import OpenChallenges from '@/components/home/OpenChallenges.vue'
import OpenSuggestionBox from '@/components/home/OpenSuggestionBox.vue'
import ClosedChallenges from '@/components/home/ClosedChallenges.vue'
import ClosedSuggestionBox from '@/components/home/ClosedSuggestionBox.vue'
import TastingChallenges from '@/components/home/TastingChallenges.vue'
import TastingOlympiads from "@/components/home/TastingOlympiads.vue"
import { useFeatureFlag } from '@/composable/feature-flag'
import { useUserPlatform, usePlatform } from '@/composable/platform'
import { useRouteQuery } from '@vueuse/router'
import { useCategory } from '@/composable/category'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const route = useRoute()

const category = useRouteQuery('category', '')
const { isUserPlatform, isBignerve, isSubplatform, contextPlatform } = usePlatform()
const { account } = useUserPlatform()
const { category: currentCategory } = useCategory(category)
const { expect, variantTo, flag } = useFeatureFlag('suggestion-box')
const filter = useRouteQuery('filter', 'all')
const suggestionBoxEnabled = expect(() => contextPlatform.value?.owner, 'TMTTRT51CX');
const owner = computed(() => contextPlatform.value?.owner)
const variant = variantTo(owner)
</script>

<template>
    <TastingChallenges v-if="(!isBignerve && isUserPlatform && ['all'].includes(filter))" :filter="'recents'"
        :platform-domain="true" :options="{ size: !$responsive.sm ? 18 : 3 }" id="open-bignerve-challenges">
        <template #header>
            <h2 class="m-0 flex items-center text-xl font-narrow text-secondary flex-1">
                {{ [`OPEN CHALLENGES: for ${toUpper(contextPlatform?.subdomain)} Tournament`,
                currentCategory && currentCategory.name ? toUpper(currentCategory.name) : null
                ].filter(v => v).join(' in ') }}
                <NIcon v-tooltip="{
                    content: `<div class='max-w-[17.5rem]'>These challenges were created for the ${toUpper(contextPlatform?.subdomain)} tournament. Entering these earns you points. Prizes or money may have qualification criteria.</div>`,
                    html: true,
                }" class="ml-2" />
            </h2>
        </template>
    </TastingChallenges>
    <OpenChallenges v-if="!['all', 'closed', 'suggestion_box'].includes(filter)" :title="[
        `OPEN CHALLENGES: ${toUpper(filter)}`,
        currentCategory && currentCategory.name ? toUpper(currentCategory.name) : null
    ].filter(v => v).join(' in ')" :filter="$route.query.filter" :platform-domain="!!$route.query.domain"
        :options="{ size: !$responsive.sm ? 18 : 3 }" />
    <TastingChallenges v-if="['all'].includes(filter)" :title="[
        `OPEN CHALLENGES: DEFAULT`,
        currentCategory && currentCategory.name ? toUpper(currentCategory.name) : null
    ].filter(v => v).join(' in ')" filter="default" :options="{ size: !$responsive.sm ? 18 : 3, cache: true }"
        id="open-challenges" />
    <!-- <TastingChallenges
    v-if="['all'].includes(filter)"
    :title="[
        `OPEN CHALLENGES: ${isUserPlatform ? 'TRENDING' : 'FEATURED'}`,
        currentCategory && currentCategory.name ? toUpper(currentCategory.name) : null
    ].filter(v => v).join(' in ')"
    :filter="isUserPlatform ? 'trending' : 'trending-weighted'"
    id="open-challenges"
/> -->
    <!-- <TastingChallenges
    v-if="['all'].includes(filter)"
    :title="[
        'OPEN CHALLENGES: MOST RECENT',
        currentCategory && currentCategory.name ? toUpper(currentCategory.name) : null
    ].filter(v => v).join(' in ')"
    filter="recents"
/> -->
    <OpenSuggestionBox v-if="['all', 'suggestion_box'].includes(filter) && suggestionBoxEnabled"
        :filter="$route.query.filter" />
    <ClosedSuggestionBox v-if="['suggestion_box'].includes(filter) && suggestionBoxEnabled"
        :filter="$route.query.filter" />
    <ClosedChallenges v-if="['all', 'closed'].includes(filter)" :title="[
        'COMPLETED CHALLENGES',
        currentCategory && currentCategory.name ? toUpper(currentCategory.name) : null
    ].filter(v => v).join(' in ')" :platform-domain="true" />

    <TastingOlympiads
        v-if="contextPlatform && !isUserPlatform && !['home.olympiads', 'home.challenges.search'].includes($route.name)"
        :key="contextPlatform.id"
        :class="isUserPlatform ? 'order-3' : 'order-2'" :platform-slug="isSubplatform ? contextPlatform.slug : null"
        :title="isSubplatform ? `${contextPlatform.title}'s Tournaments` : 'Popular Tournaments'"
        filter="trending"
    />
</template>
