<script>
import { h, computed } from 'vue';

export default {
    props: {
        src: String,
        alt: String,
        fallback: {
            type: String,
            default: '/img/user-profile-images/user-avatar-circle-anon.svg'
        },
        position: {
            type: String,
            default: 'center center'
        },
        contain: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        }
    },

    setup (props, { attrs, listeners }) {
        const backgroundImage = computed(() => [
            props.src && `url("${props.src}")`, `url("${props.fallback}")`
        ].filter(i => !!i));

        return () => h('div', {
            ...attrs,
            ...listeners,
            class: [
                'rounded-full bg-dust-100 bg-no-repeat border border-dust-100',
                {
                    'w-6 h-6': props.small,
                    'w-8 h-8': !props.small && !props.large,
                    'w-10 h-10': props.large,
                },
                attrs.class,
            ],
            style: {
                backgroundSize: props.contain ? 'contain' : 'cover',
                backgroundImage: backgroundImage.value.join(', '),
                backgroundPosition: props.position,
                ...attrs.style,
            },
        })
    }
}
</script>
