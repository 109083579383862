<script setup>
import Brain from '@/components/NBrain.vue';
import NumberFlow from '@number-flow/vue';
import { useStorage, toReactive } from '@vueuse/core';
import { vElementVisibility } from '@vueuse/components';
import { computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    level: {
        type: [String, Number],
        default: 0
    },
    position: {
        type: [String, Number],
        default: 0
    },
    score: {
        type: [String, Number],
        default: 0
    },
    nextLevelScore: {
        type: [String, Number],
        default: 0
    },
    role: {
        type: String,
        default: 'overall'
    }
});

const cached = toReactive(useStorage(`gamedash:nerve-score:${props.role}`, {
    score: 0,
    level: 0,
    levelProgress: 0,
}));

const progressToNextRank = computed(() => {
    if (props.nextLevelScore < props.score) {
        return 0;
    }

    return ((props.score / props.nextLevelScore) * 100).toFixed(0);
});

const currentLevel = computed(() => {
    const bit = Number(props.level * .1).toFixed(1);
    const [, n] = bit.split('.');

    return Number(n);
});

const onScoreVisibility = (visible) => {
    if (visible) {
       setTimeout(() => {
            cached.score = props.score;
            cached.level = props.level;
            cached.levelProgress = currentLevel.value * 10;
            cached.currentLevel = currentLevel.value * 10;
        }, 1000);
    }
}
</script>

<template>
    <RouterLink v-if="$auth.authenticated" :to="{ name: 'dashboard.my-progress.score-stats', params: { user: $auth.user.screen_name || 'me' }, query: { tab: 'nerve-score'} }" class="nerve-score" :class="`flex gap-6 is-${role}`">
        <div v-element-visibility="onScoreVisibility" class="grid gap-2 flex-1">
            <div>
                <h3 class="nerve-score__title">{{ title }}</h3>
                <div v-tooltip="{ html: true, content: `Brain Level: ${level}<br>Rank position: ${position}`}" class="nerve-score__score">
                    <NumberFlow :value="cached.score" willChange continuous :transformTiming="{ duration: 750 }"  />
                </div>
            </div>
            <div class="nerve-score__level">
                <span class="nerve-score__level-title">You're at <b>Level <NumberFlow :value="cached.level" :duration="4" :continuous="true" /></b></span>
                <div v-tooltip="`Game Dash ${currentLevel * 10}% Meter to next level`" class="nerve-score__bar-wrapper">
                    <div class="nerve-score__bar ease-in-out transition-[width] duration-[3s]" :style="{ width: `${cached.levelProgress}%` }"></div>
                </div>
            </div>
        </div>

        <div class="w-[9rem] h-[9rem]">
            <Brain v-tooltip="`${currentLevel} / 10`" :level="cached.currentLevel" simple />
        </div>
    </RouterLink>
</template>
