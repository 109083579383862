<script setup>
import { computed, ref, watch } from 'vue';

import Brain from '@/components/NBrain.vue';
import NRating from '@/components/NRating.vue';
import { useVModel } from '@vueuse/core'

const props = defineProps({
    modelValue: {
        type: Number,
        required: false,
        default: 0,
    },
    defaultValue: {
        type: Number,
        default: 0,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isReadOnly: {
        type: Boolean,
        default: false,
    },
});

const rating = useVModel(props, 'modelValue', null, {
    defaultValue: props.defaultValue,
});
const preview = ref(0);
const level = computed(() => (props.modelValue || preview.value) * 10);

watch(rating, (newValue) => {
    if (!newValue) {
        preview.value = 0;
    }
});
</script>

<template>
    <div class="border-4 border-rater rounded-lg mb-4 bg-rater select-none">
        <div class="h-4"></div>
        <div class="relative flex flex-wrap sm:flex-nowrap items-center bg-white rounded-lg p-3">
            <div v-if="isReadOnly" class="absolute bg-white bg-opacity-80 inset-0 flex items-center justify-center z-[1]">
                <slot name="rating-overlay">
                    <span class="px-8 py-2 bg-rater-500 text-white font-bold text-xl rounded-full">Your rating: {{ rating }}</span>
                </slot>
            </div>

            <div class="flex flex-col flex-grow sm:space-x-3">
                <div class="flex items-center justify-between mt-1 mb-2">
                    <div class="text-rater font-semibold uppercase whitespace-nowrap h-8">
                        <NIcon as="alright" class="text-xl" left/>YOUR RATING:
                    </div>

                    <NExperiencePoints v-if="!isReadOnly" fqdn="analyst.IdeaRated.RatedIdea" #="{ points }">
                        <NBubble v-if="!!points && rating > 0" color="analyst" :animated="true">
                            +{{ points }} Neurons
                        </NBubble>
                    </NExperiencePoints>

                    <span class="text-rater italic text-md font-semibold min-w-16 text-right">
                        {{ preview || rating }} of {{ 10 }}
                    </span>
                </div>
                <div class="flex flex-1 items-center flex-col">
                    <NRating
                        v-model="rating"
                        :increment="0.1"
                        :max="10"
                        class="sm:max-h-[2rem] w-full"
                        :class="{ disabled }"
                        @hovered="v => preview = v"
                    />

                    <div class="text-sm sm:text-base px-2 mt-3 grid grid-cols-3 w-full items-end">
                        <span class="text-rater font-semibold text-left">
                            <span class="text-2xl leading-none hidden sm:inline-block">&lBarr;</span> Obvious
                        </span>
                        <span class="text-rater font-semibold text-center">
                            Unexpected
                        </span>
                        <span class="text-rater font-semibold text-right">
                            Highly Creative <span class="text-2xl leading-none hidden sm:inline-block">&dbkarow;</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="sm:pl-4 flex items-center justify-center w-full sm:w-auto p-10 sm:p-0">
                <div class="relative h-auto w-auto" style="color: #f5a623;">
                    <Brain
                        :level="level"
                        :class="{ disabled }"
                        class="!w-60 !h-60 sm:!w-[7.5rem] sm:!h-[7.5rem]"
                        simple
                        animationTiming="0.3s"
                        @selected="v => rating = (v / 10)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>