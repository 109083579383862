<script setup>
import Message from './Message.vue';
import { api } from '@/modules/services';
import { ref, computed } from 'vue';

const props = defineProps({
	discussion: {
		type: Object,
		required: true,
	},
	canReply: {
		type: Boolean,
		default: false,
	},
	loadReplies: {
		type: Boolean,
		default: true,
	},
	resolveUser: {
		type: Function,
		default: (user) => user,
	},
	filter: {
		type: String,
		default: 'default',
	},
	initialAuthors: {
		type: Array,
		default: () => ([]),
	},
	returnHandler: {
		type: Function,
	},
	highlightHandler: {
		type: Function,
		default: () => {},
	},
	messageInitHandler: {
		type: Function,
		default: () => {},
	},
});

const emit = defineEmits(['onReply', 'onRemove']);

const replies = ref([]);
const authors = computed(() => {
	return replies.value.reduce((acc, currnet) => {
		if (acc.find(item => item.id === currnet.author.id)) {
			return acc;
		}

		return [...acc, { id: currnet.author.id, value: currnet.author.handle }];
	}, props.initialAuthors);
});

const fetchReplies = async () => {
    const { data: response } = await api.discussions.replies(
		props.discussion.context_type,
        props.discussion.context_id,
        props.discussion.id,
		{ filter: props.filter },
    );

    replies.value = response.data;
};

const handleOnReply = (payload) => {
    replies.value = [...replies.value, payload];
};

// if (props.discussion.stats.total_replies > 0) {
	props.loadReplies && fetchReplies();
// }

const handleOnRemoveReply = (payload) => {
	replies.value = replies.value.filter((reply) => reply.id !== payload.id);
};
</script>

<template>
	<Message :discussion="discussion" :canReply="canReply && !discussion.conversation_id" :resolveUser="resolveUser" :authors="authors" :returnHandler="returnHandler" :messageInitHandler="messageInitHandler" :class="highlightHandler(discussion) && 'border-2 border-nerve'" @onReply="handleOnReply" @onRemove="emit('onRemove', discussion)">
		<Message v-for="reply of replies" :key="reply.id" :authors="authors" :resolveUser="resolveUser" :discussion="reply" :returnHandler="returnHandler" :messageInitHandler="messageInitHandler" :class="highlightHandler(reply) && 'border-2 border-nerve'" @onRemove="handleOnRemoveReply" />
	</Message>
</template>
