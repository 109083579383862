<script>
import moment from 'moment';

export default {
    inject: ['form'],

    props: {
        hideTips: Boolean,
        days: Number,
        disabled: Boolean,
    },

    data () {
        return {
            defaultDays: 30,
        };
    },

    computed: {
        now() {
            return moment().toISOString();
        },
        currentDays () {
            if (this.form.fields.challenge.duration_end) {
                const days = moment(this.form.fields.challenge.duration_end).diff(
                    moment(this.form.fields.challenge.duration_start), 'days'
                )
                return (d => d < 20 ? 15 : (d < 45 ? 30 : 90))(days);
            }

            return this.defaultDays;
        },
        duration: {
            get () {
                return this.currentDays;
            },
            set (value) {
                this.form.fields.challenge.duration_end = value;
            }
        },
    },

    // TODO move it to parent component and remove the dependencies
    bus: {
        'challenge:creation-flow:reseted' () {
            this.setDuration(this.defaultDays);
        },
        'challenge:creation-flow:recovered' () {
            this.setDuration(this.currentDays);
        },
        'challenge:creation-flow:edition-started' () {
            this.setDuration(this.currentDays);
        },
    },

    methods: {
        setDuration(days) {
            const interval = moment().add(days, 'days').toISOString();

            this.$nextTick(() => {
                this.form.fields.challenge.duration_start = this.now;
                this.form.fields.challenge.duration_end = interval;
            })
        },
        setDate (value, field) {
            this.form.fields.challenge[field] = moment(value).toISOString();
        },
    },

    mounted () {
        if (this.days) {
            this.setDuration(this.days);
        }
    },
}
</script>

<template>
    <div>
        <div class="challenge-section-heading">
            <h2 class="challenge-section-heading__title">
                <b>How long will your challenge run?</b>
            </h2>
        </div>
        <div class="form-section-wrapper flex flex-col md:flex-row gap-4 mb-4" :class="{ disabled: disabled || form.busy }">
            <div class="form-group is-boxed md:min-w-[13rem]">
                <span class="form-group__label">Choose a Default</span>
                <div class="form-group__box">
                    <NRadioGroup :modelValue="duration" name="duration" @change="setDuration">
                        <NRadio label="15 Days" class="my-1" :modelValue="15" />
                        <NRadio label="30 Days" class="my-1" :modelValue="30" />
                        <NRadio label="90 Days" class="my-1" :modelValue="90" />
                    </NRadioGroup>
                </div>
            </div>
            <div class="form-group is-boxed min-w-[25rem]">
                <span class="form-group__label">Or set a custom Date and Time range</span>
                <div class="form-group__box">
                    <NDatepicker
                        :modelValue="form.fields.challenge.duration_start"
                        :end-time="$refs.duration_end"
                        name="duration_start"
                        label="Start Date:"
                        @change="setDate($event, 'duration_start')"
                    ></NDatepicker>
                    <NDatepicker
                        :modelValue="form.fields.challenge.duration_end"
                        ref="duration_end"
                        name="duration_end"
                        label="End Date:"
                        @change="setDate($event, 'duration_end')"
                    ></NDatepicker>
                </div>
            </div>
        </div>
        <div v-if="!hideTips" class="info-box mt-4">
            <span aria-hidden="true" class="info-box__icon icon-exclamation-circle-solid"></span>
            <p class="info-box__content">
                <b>Tip:</b>  Depending on your player’s engagement, optimal duration can vary: <b>a longer</b> duration can bring in more ideas and thus higher chances of quality. But <b>shorter</b> durations means that your challenges close and award winners frequently, so maybe you’ll get more engagement.
            </p>
        </div>
    </div>
</template>
