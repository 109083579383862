<script>
import { uuid } from "@/utils/Helpers";
import { useNotifier } from '@/composable/notifier';
import { bus } from '@/modules/bus';
import { computed } from 'vue';
import { Toaster } from 'vue-sonner'
import { sentry } from '@/modules/sentry';
import { useReaderMode } from '@/composable/app';
import { useHead } from '@vueuse/head';
import { usePlatform } from '@/composable/platform';
import { Session } from '@/utils/Auth';
import Storage from '@/utils/Storage';
import Cookie from "@/utils/Cookie";
import kebabCase from "lodash/kebabCase";

import AppProvider from "@/components/providers/AppProvider.vue";
import NNotifier from "@/components/NNotifier.vue";

// partials
import AppHeader from "@/layouts/partials/Header.vue";
import AppFooter from "@/layouts/partials/Footer.vue";

// layouts
import Wide from "@/layouts/Wide.vue";
import Boxed from "@/layouts/Boxed.vue";
import Auth from "@/layouts/Auth.vue";
import Raw from "@/layouts/Raw.vue";
import BoxedWithoutGameDash from "@/layouts/BoxedWithoutGameDash.vue";

const showProductionAlert =
    import.meta.env.VUE_APP_SHOW_PRODUCTION_ALERT ||
    (process.env.NODE_ENV !== "production" &&
        import.meta.env.VUE_APP_API_BASE_URL.endsWith("/api"));

const prefix =
    process.env.NODE_ENV !== "production"
        ? showProductionAlert
            ? "[prod]"
            : "[dev]"
        : null;

export default {
    name: "App",

    data() {
        return {
            showProductionAlert,
        };
    },

    components: {
        Auth,
        Wide,
        Boxed,
        Raw,
        BoxedWithoutGameDash,
        AppFooter,
        // SuspendedActions,
        AppHeader,
        AppProvider,
        NNotifier,
        Toaster,
    },

    provide() {
        return {
            app: this,
        };
    },

    created() {
        // get all categories
        this.$store.dispatch("app/challenge/getAllCategories", this.platform.id);
    },

    watch: {
        $route(n, o) {
            this.fetchContextUser();
            this.fetchCurrentUser();

            this.handleScout();
        },
        
    },

    computed: {
        classes() {
            return [kebabCase(`page.${this.$route.name}`), this.layout];
        },
        layout() {
            return (this.$route.meta.layout || "wide").toLowerCase();
        },
        isSplash() {
            return this.$route.meta.splash;
        },
        platform() {
            return this.$store.state.app.platform;
        },
    },

    bus: {
        "user:logged-in@once"(user) {
            // sentry scope
            const scope = sentry.getCurrentScope()
            scope.setUser({
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                handle: user.screen_name,
                id: user.id,
                scout_referrer: user.scout_referrer,
            });

            // pusher - ensure authentication
            this.$api.auth.notification().then(({ data }) => {
                Cookie.set("notification_token", data.notification_token, true, 30);
            });
        },
        "sharing:popup"(payload) {
            this.$refs.sharingPopup.open(payload);
        },
        "user:update-details"() {
            this.fetchCurrentUser();
        },
        "platform:change"(platform) {
            this.$store.dispatch("app/categories", platform);
        },
        "account:not-found" ({ slug }) {
            this.$router.push({ name: "home.challenges" });

            this.notify({
                title: `User not found!`,
                message: `The username "${slug}" does not exist. Check for typos, try a different link, or browse the Nerve Boards.`,
                textCancel: "Ok",
                hideConfirm: true,
            });
        }
    },

    methods: {
        fetchCurrentUser() {
            if (Session.exists() && !this.$auth.authenticated) {
                this.$store.dispatch("auth/login").then(({ user }) => {
                    this.$bus("user:logged-in", user);

                    Storage.set("user", {
                        id: user.id,
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        screen_name: user.screen_name,
                    });
                });
            }
        },
        async fetchContextUser() {
            // List of slugs that should be ignored when fetching user context
            const ignoredSlug = ['me', 'platform', 'search'];

            // Get the user slug from route params
            const slug = this.$route.params.user || this.$route.params.platformSlug;

            // Add initial breadcrumb
            this.$sentry.addBreadcrumb({
                category: 'user-context',
                message: `Starting fetchContextUser with slug: ${slug}`,
                level: 'info'
            });

            // Only fetch user if we have a valid slug
            if (slug && !ignoredSlug.includes(slug)) {
                const currentAccount = this.$store.getters['app/user'];
                const isNewUser = !currentAccount.user || currentAccount.user.screen_name !== slug;

                this.$sentry.addBreadcrumb({
                    category: 'user-context',
                    message: `User context check - isNewUser: ${isNewUser}`,
                    data: {
                        currentScreenName: currentAccount.user?.screen_name,
                        requestedSlug: slug
                    },
                    level: 'info'
                });

                // Fetch new user data if needed
                if (isNewUser && slug) {
                    try {
                        this.$sentry.addBreadcrumb({
                            category: 'user-context',
                            message: 'Fetching new user data',
                            level: 'info'
                        });

                        const newAccount = await this.$store.dispatch('app/user', { user: slug });

                        // If the slug is not the same as the screen_name, we need to redirect to the correct slug
                        if (newAccount.user.screen_name !== slug) {
                            this.$sentry.addBreadcrumb({
                                category: 'user-context',
                                message: 'Redirecting to correct screen name',
                                data: {
                                    originalSlug: slug,
                                    correctScreenName: newAccount.user.screen_name
                                },
                                level: 'info'
                            });


                            console.log(`[app] Redirecting from ${slug} to ${newAccount.user.screen_name}`);
                            this.$router.push({ name: 'home.user', params: { user: newAccount.user.screen_name } });
                        }

                        return newAccount;
                    } catch (error) {
                        this.$sentry.addBreadcrumb({
                            category: 'user-context',
                            message: 'Error fetching user data',
                            data: { error: error.message },
                            level: 'error'
                        });

                        this.$sentry.withScope(scope => {
                            scope.setExtra('slug', slug);
                            
                            this.$sentry.captureException(error);
                        });

                        this.$bus('account:not-found', { slug });
                    }
                }

                return currentAccount;
            }

            this.$sentry.addBreadcrumb({
                category: 'user-context',
                message: 'Resetting user state - no valid slug',
                level: 'info'
            });

            // Reset user state if no valid slug
            this.$store.commit('app/resetUser');
        },
        handleScout() {
            if (this.$route.query.scout_id) {
                Cookie.set("scout_id", this.$route.query.scout_id, true, 270);
            }
        },
        firstAccess() {
            if (!Cookie.has("fingerprint")) {
                Cookie.set("fingerprint", uuid(), true, Infinity);

                this.$api.analytics.track("FirstAccess");
            }
        },
    },

    mounted() {
        this.fetchCurrentUser();
        this.fetchContextUser();
        this.handleScout();
        this.firstAccess();

        // platform
        this.$bus("platform:change", this.$store.state.app.platform);
    },

    setup () {
        // useChatNotification();

        const { open: notify } = useNotifier();
        const { title, slogan, isUserPlatform, rootPlatform } = usePlatform();

        const titled = computed(() => {
            if (isUserPlatform.value) {
                return [rootPlatform.value?.title, title.value].filter(Boolean).join(' · ');
            }

            return title.value;
        })

        useHead({
            title: titled.value,
            titleTemplate: (t) => {
                return [prefix, ...(t === titled.value ? [t, slogan.value] : [t, titled.value])].filter(Boolean).join(' · ');
            },
        });

        return {
            notify,
        };
    },
};
</script>

<template>
    <AppProvider>
        <NNavbarNotification :immediate="showProductionAlert">
            <div class="bg-red-500 text-white py-4 z-[800]">
                <div class="container mx-auto flex items-center leading-none">
                    <NIcon as="exclamation-circle-solid" class="mr-2 pb-1" />
                    Be careful, you are accessing the platform connected to the production
                    database.
                </div>
            </div>
        </NNavbarNotification>

        <AppHeader v-if="!$route.meta.hideHeader" />

        <main class="app-main" :class="classes">
            <Component :is="layout" />

            <NSharingPopup ref="sharingPopup" />
        </main>

        <AppFooter v-if="!$route.meta.hideFooter" />
        <Toaster position="bottom-right" rich-colors />
        <NNotifier />
    </AppProvider>
</template>
