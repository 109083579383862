<script>
import { usePlatform  } from '@/mixins/ShowsPlatform';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';

export default {
    name: 'ChallengeTitle',

    mixins: [
        usePlatform(),
        useChallengeDetails(),
    ],

    props: {
        challenge: {
            type: Object,
            required: true,
        },
        tag: {
            type: String,
            default: 'h3',
        },
        to: {
            type: Object,
            default: null,
        },
    },
}
</script>

<template>
    <component :is="to ? 'router-link' : tag" :to="to" :aria-label="questionTitle">
        <span v-if="challenge.solution_format && challenge.solution_format !== 'challenge'" class="text-sm bg-nerve text-white px-2 mr-1 rounded-full">
            {{ $t(`component.challenge.solution-format.${challenge.solution_format}.abbreviation`) }}
        </span>
        <slot :questionTitle="questionTitle">{{ questionTitle }}</slot>
        <span v-if="challenge.idea_entry_fee" class="px-2 bg-nerve text-white rounded-lg text-sm ml-2 leading-3 pt-[0.2rem] pb-[0.1rem] inline-block">
            ${{ challenge.idea_entry_fee }}
        </span>
    </component>
</template>
