export default {
    props: {
        name: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        hint: {
            type: String,
            default: null
        },
        fieldName: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        forceError: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
    },

    inheritAttrs: false,

    inject: ['form'],

    computed: {
        invalid() {
            return this.forceError || !!(this.name && this.form.errors.has(this.name));
        },
        error() {
            return this.invalid ? this.form.errors.get(this.name) : null;
        },
        attrs() {
            return Object.keys(this.$attrs)
                .filter(key => {
                    return key !== 'model';
                }).reduce((attrs, key) => {
                    return Object.assign(attrs, { [key]: this.$attrs[key] });
                }, {});
        },
    },

    mounted () {
        this.form.formFields.push(this)
    },

    methods: {
        clearErrors() {
            if (this.invalid) {
                this.form.errors.clear(this.name);
            }
        },
        listeners ({ ignore = [] } = {}) {     
            this.$listeners = []
            
            return Object.keys(this.$listeners)
                .filter(k => !ignore.includes(k))
                .reduce((listeners, key) => {
                    return Object.assign(listeners, { [key]: this.$listeners[key] });
                }, {});
        },
        focus () {
            this.$refs.target.$el.focus();
        },
        reset () {
            // implement in field component
        },
    }
}
