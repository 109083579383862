export default requester => {
    return {
        /**
         * Get discussion
         *
         * @return {object}
         */
        find (contextType, contextId, params = {}, config = {}) {
            return requester.get(`/content/contexts/${contextType}/${contextId}/messages`, { ...config, params });
        },

        /**
         * Create or update discussion
         * @return {object}
         */
        create (contextType, contextId, payload) {
            return requester.post(`/content/contexts/${contextType}/${contextId}/messages`, payload);
        },

        /**
         * Update discussion
         * @return {object}
         */
        update (contextType, contextId, messageId, payload) {
            return requester.put(`/content/contexts/${contextType}/${contextId}/messages/${messageId}`, payload);
        },

        /**
         * Delete discussion
         * @return {object}
         */
        delete (contextType, contextId, messageId) {
            return requester.delete(`/content/contexts/${contextType}/${contextId}/messages/${messageId}`);
        },

        /**
         * Send reply to discussion
         * @return {object}
         */
        reply (contextType, contextId, messageId, payload) {
            return requester.post(`/content/contexts/${contextType}/${contextId}/messages/${messageId}/replies`, payload);
        },

        /**
         * Get replies
         * @return {object}
         */
        replies (contextType, contextId, messageId, params = {}) {
            return requester.get(`/content/contexts/${contextType}/${contextId}/messages/${messageId}/replies`, { params });
        },

        /**
         * Vote for discussion
         * @return {object}
         */
        vote (payload) {
            return requester.post(`/vote/votes`, payload);
        },

        /**
         * Get discussion resources
         * 
         * @param {string} contextType 
         * @param {string} contextId 
         * @returns 
         */
        resources (contextType, contextId) {
            return requester.get(`/content/contexts/${contextType}/${contextId}/resources`);
        },

        /**
         * Get discussion meta
         * 
         * @param {string} contextType
         * @param {string} contextId
         * @returns
         */
        meta (contextType, contextId, params = {}) {
            return requester.get(`/content/contexts/${contextType}/${contextId}/meta`, { params });
        },

        /**
         * Set message view
         **/
        viewed (id) {
            return requester.get(`/content/views/messages/${id}`);
        },

        /**
         * Search by discussions
         **/
        search (params = {}) {
            return requester.get(`/search/discussions`, { params });
        }
    };
}
