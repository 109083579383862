const namespace = 'retryRequest';

const defaultOptions = {
    retries: 3,
    isError: false,
    statusCodes: [],
    methods: ['get'],
    retryCondition: _ => true,
    retryDelay: _ => 2000, // 2s
    middleware: config => config,
};

const getState = config => {
    const state = config[namespace] || {};
    state.count = state.count || 0;
    config[namespace] = state;
    return state;
};

const getOptions = (config, options) => {
    return Object.assign({}, defaultOptions, options, config[namespace]);
};

const fixConfig = (axios, config) => {
    if (axios.defaults.agent === config.agent) {
      delete config.agent;
    }
    if (axios.defaults.httpAgent === config.httpAgent) {
      delete config.httpAgent;
    }
    if (axios.defaults.httpsAgent === config.httpsAgent) {
      delete config.httpsAgent;
    }
};

// delay strategies
export const retryStrategies = {
    multiply: ({ count = 0 } = {}) => {
        return count * 1000;
    },
    exponential: ({ count = 0 } = {}) => {
        const delay = Math.pow(2, count) * 100;
         // 0-20% of the delay
        const randomSum = delay * 0.2 * Math.random();
        return delay + randomSum;
    }
};

export const retryRequest = (axios, options = {}) => {
    axios.interceptors.request.use(config => {
        const state = getState(config);
        state.lastRequest = Date.now();

        return config;
    });

    const interceptor = payload => {
        const response = payload.response || payload;
        const { config, status } = response;

        if (!config) {
            return Promise.reject(payload);
        }

        // options
        const {
            retries,
            retryDelay,
            retryCondition,
            statusCodes,
            middleware,
            methods,
            isError
        } = getOptions(config, options);

        if (statusCodes.includes(status) && methods.includes(config.method)) {
            const state = getState(config);
            const shouldRetry = retryCondition(payload) && state.count < retries;

            if (shouldRetry) {
                state.count += 1;
                const delay = retryDelay(state);

                // Axios fails merging this configuration to the default configuration because it has an issue
                // with circular structures: https://github.com/mzabriskie/axios/issues/370
                fixConfig(axios, config);

                config.transformRequest = [data => data];

                return new Promise(resolve => {
                    setTimeout(_ => {
                        resolve(axios(middleware(config)))
                    }, delay)
                });
            }
        }

        return isError ? Promise.reject(payload) : payload;
    }

    // interceptors
    const [success, fail] = options.isError ? [null, interceptor] : [interceptor, null];

    // register interceptors
    axios.interceptors.response.use(success, fail);
};
