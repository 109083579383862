import { get, set, del, createStore } from 'idb-keyval'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { experimental_createPersister as createPersister } from '@tanstack/query-persist-client-core'

const customStore = createStore('bignerve', 'keyval')

export const install = ({ app }) => {
    const persister = createPersister({
        storage: {
            getItem: async (key) => {
                try {
                    return await get(key, customStore)
                } catch (error) {
                    console.warn('Failed to get from storage:', error)
                    return null
                }
            },
            setItem: async (key, value) => {
                try {
                    await set(key, value, customStore)
                } catch (error) {
                    console.warn('Failed to set to storage:', error)
                }
            },
            removeItem: async (key) => {
                try {
                    await del(key, customStore)
                } catch (error) {
                    console.warn('Failed to remove from storage:', error)
                }
            },
        },
        retry: {
            maxRetries: 3,
            retryDelay: 1000,
        },
    })

    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    gcTime: 1000 * 60 * 60 * 24, // 24 hours
                    persister,
                    staleTime: 5000,
                    retry: 3,
                },
            },
        },
    })
}
