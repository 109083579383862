/** @module solutions */
export default requester => {
    return {
        /**
         * Returns an array of solutions.
         *
         * @param {string} solvedStatus One of active, inactive, or both
         * @param {string} orderBy The ordering of the returned solutions (currently only created_at, but will eventually support popularity and money)
         * @param {string} start The page offset
         * @param {string} num The page size
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        all(activationStatus, orderBy, start, num, params = {}, headers = {}) {
            return requester.makeRequest(
                'GET', '/solutions', Object.assign({ activationStatus, orderBy, start, num }, params), headers
            );
        },

        /**
         * Appends complete user information to a supplied array of solutions.
         *
         * @param {Array} solutions A json body containing an array of solution objects (the objects for which to add complete user information).
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        appendWithUsers(solutions, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/solutions-with-users', { solutions }, params, headers);
        },

        /**
         * Gets a solution by its id.
         *
         * @param {string} solutionId ID of solution to get
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        get(solutionId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/solutions/${solutionId}`, {}, params, headers);
        },

        /**
         * Gets a the iteration log.
         *
         * @param {string} solutionId ID of solution to get
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        iterationLog(solutionId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/solutions/${solutionId}`, params, headers);
        }
    }
}
