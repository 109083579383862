<script setup>
import { ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';

const props = defineProps({
    handler: {
        type: Function,
        default: () => {},
    },
    seconds: {
        type: Number,
        default: 10,
    },
    launch: {
        type: Function,
        default: () => {},
    },
});

const emit = defineEmits(['done']);
const timing = ref(props.seconds || 0);
const launching = ref(false);

const { resume, pause, isActive } = useIntervalFn(async () => {
    timing.value -= 1;

    if (timing.value <= 0) {
        pause();

        launching.value = true;
        await props.launch();
        launching.value = false;

        timing.value = props.seconds;
        emit('done');
    }
}, 1000, { immediate: false });

const trigger = async () => {
    await props.handler()

    resume();
}

const stop = () => {
    pause();
    timing.value = props.seconds;
}
</script>

<template>
    <slot :trigger="trigger" :pause="pause" :resume="resume" :stop="stop" :isActive="isActive" :launching="launching" :seconds="timing" :on="{ click: trigger }"></slot>
</template>