export default requester => {
    return {
        /**
         * Search by topics
         **/
        search (params = {}) {
            return requester.get(`/search/topics`, { params });
        },

        /**
         * Get topic by id
         **/
        find (id, params = {}) {
            return requester.get(`/content/topics/${id}`, { params });
        },
    };
}
