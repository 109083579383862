<script setup>
import { scaleLinear } from 'd3';
import { computed } from 'vue';

const props = defineProps({
    icon: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: 'gray',
    },
    width: {
        type: [String, Number]
    },
    min: {
        type: Number,
        default: 1,
    },
    max: {
        type: Number,
        default: 100,
    },
    modelValue: {
        type: Number,
        default: 1,
    },
    label: {
        type: String,
    },
    label: {
        type: String,
    },
    info: {
        type: String,
    },
    omitted: {
        type: Boolean,
        default: false,
    },
    reverse: {
        type: Boolean,
        default: true,
    },
    direction: {
        type: String,
        default: 'right',
        validator: (value) => ['right', 'left'].includes(value),
    },
});

const position = computed(() => {
    const scale = scaleLinear()
        .domain([props.min, props.max])
        .range([0 , 100]);

    const percent = scale(props.modelValue);

    return props.reverse ? 100 - percent : percent;
});
</script>

<script>
export default {
    name: 'RankHistogram',
}
</script>

<template>
    <div class="flex items-center gap-3">
        <div :style="{ width: !$responsive.sm && width ? `${width}px` : null }" class="w-full flex items-center rounded-x-lg h-8">
            <div :class="[ `bg-${color}-500` ]" class="text-white flex items-center justify-center w-9 h-full rounded-l-lg">
                <slot name="box">
                    <NIcon :as="icon" />
                </slot>
            </div>
            <div :class="[ `bg-${color}-100` ]" class="w-full flex items-center h-full p-3 rounded-r-lg">
                <div class="h-1.5 w-full bg-dust-300 relative" >
                    <div
                        v-tooltip="{ content: `${!omitted ? modelValue : '?'}`, placement: 'top', distance: 18, skidding: 6, 'arrow-padding': 6 }"
                        :style="{ [direction === 'right' ? 'left' : 'right']: !omitted ? `${position}%` : 0 }"
                        :class="[ `after:border-${direction === 'right' ? 'l' : 'r'}-${color}-500`, `to-${direction}` ]"
                        class="absolute -ml-2 histogram-arrow"
                    />
                </div>
            </div>
        </div>

        <div class="flex flex-col items-center">
            <slot :min="min" :max="max" :label="label">
                <div class="flex items-end whitespace-nowrap leading-none font-narrow">
                    <b :class="`text-${color}`" class="text-xl mr-1 min-w-5 text-right !text-center leading-5">{{ !omitted ? `#${modelValue}` : '?' }}</b>
                    <span class="mr-1 text-sm text-dust-800">of</span>
                    <b class="mr-1 text-md text-dust-800">{{ max }}</b>
                    <span class="text-dust-800">{{ label }}</span>
                </div>
                <small v-if="info" :class="`text-${color}`" class="italic leading-none">
                    {{ info }}
                </small>
            </slot>
        </div>
    </div>
</template>

<style lang="scss">
    .histogram-arrow {
        &:after {
            content: "";
            position: absolute;
            top: -7.5px;
            border-color: transparent;
            border-top-width: 10px;
            border-bottom-width: 10px;
            border-style: solid;
        }

        &:before {
            content: "";
            position: absolute;
            top: -9.5px;
            border-color: transparent;
            border-top-width: 12px;
            border-bottom-width: 12px;
            border-style: solid;
        }

        &.to-right {
            &:after {
                border-right-width: 0px;
                border-left-width: 16px;
            }

            &:before {
                left: -1.5px;
                border-right-width: 0px;
                border-left-width: 20px;
                border-left-color: white;
            }
        }

        &.to-left {
            &:after {
                border-left-width: 0px;
                border-right-width: 16px;
            }

            &:before {
                left: -2.5px;
                border-left-width: 0px;
                border-right-width: 20px;
                border-right-color: white;
            }
        }
    }
</style>
