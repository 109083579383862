<script setup>
import { api } from '@/modules/services';
import { once } from '@/modules/bus';
import { reactive, provide, watch } from 'vue';
import { usePlatform } from '@/composable/platform';

const { currentPlatform } = usePlatform();

const preferences = reactive({
  user: {},
  platform: {},
  system: {},
});

const fetchUserPreferences = async () => {
  try {
    const { data: response } = await api.me.preferences();

    preferences.user = response.data;
  } catch (e) {
    console.error(e);
  }
};

const fetchPlatformPreferences = async (id) => {
  try {
    const { data: response } = await api.platform.preferences(id);

    preferences.platform = response.data;
  } catch (e) {
    console.error(e);
  }
};

provide('preferences', preferences);

once('user:logged-in', () => {
  fetchUserPreferences();
})

watch(currentPlatform, (platform) => {
  if (!platform) return;

  fetchPlatformPreferences(platform.id);
}, { immediate: true });
</script>

<template>
  <slot /> 
</template>
