export default requester => {
    return {
        /**
         * Get notification authorization
         *
         * @return {object}
         */
        notification () {
            return requester.get(`/me/notification`);
        },

        /**
         * Create new account
         *
         * @return {object}
         */
        signup (data, config = {}) {
            return requester.post(`/account/signup`, data, config);
        },

        /**
         * Resend verification email
         * 
         * @param {Object} data 
         * @returns 
         */
        resendEmailVerification (data, config = {}) {
            return requester.post('/account/resend', data, config);
        },
    };
}
