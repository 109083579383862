<script>
import Help from './Help.vue';
import Step from './Step.vue';
import TestIdea from './Idea.vue';
import Media from './Media.vue';
import Prize from './Prize.vue';
import Share from './Share.vue';
import Stepper from './Stepper.vue';
import Preview from './Preview.vue';
import Publish from './Publish.vue';
import Duration from './Duration.vue';
import Overview from './Overview.vue';
import Question from './Question.vue';
import MediaArticle from './Article.vue';
import Innovation from './Innovation.vue';
import Categories from './Categories.vue';
import Assumptions from './Assumptions.vue';
import CaptionFormat from './CaptionFormat.vue';
import useChallengeCreateFlow from '@/mixins/ChallengeCreateFlow';

import { breadcrumbs } from '@/utils/Categories';

export default {
    mixins: [useChallengeCreateFlow],

    components: {
        Help,
        Step,
        TestIdea,
        Prize,
        Share,
        Media,
        Stepper,
        Preview,
        Publish,
        Duration,
        Overview,
        Question,
        Innovation,
        Categories,
        Assumptions,
        MediaArticle,
        CaptionFormat
    },

    computed: {
        stepper() {
            return this.$refs.stepper;
        },
        categories () {
            return this.$store.getters['app/challenge/categories'];
        }
    },

    methods: { breadcrumbs }
}
</script>

<template>
    <Stepper ref="stepper">
        <template #left>
            <div class="order-2 lg:order-1 col-span-12 sm:col-span-5 lg:col-span-4 xl:col-span-3 mt-5 lg:mt-0">
                <div class="challenge-section-heading is-center">
                    <p class="challenge-section-heading__title">
                        <i>Your Question Preview</i>
                    </p>
                    <small class="challenge-section-heading__description">
                        <i v-tooltip="(breadcrumbs({ children: $store.getters['app/challenge/categories'] }, form.fields.challenge.platform_category).map(c => c.name).join(' > '))">
                            In progress
                        </i>
                    </small>
                </div>
                <Preview :challenge="$parent.fields.challenge" />
            </div>
        </template>
        <Step class="col-span-12" title="Category" :complete="completedCategory" :disabled="hasSlug" hide-actions>
            <Categories
                v-model="$parent.fields.challenge.platform_category"
                v-model:categoryTree="$parent.$parent.categoryTree"
                @change="() => stepper.number(2)"
            />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Your Idea" :disabled="hasSlug" :complete="completedIdea" show-left>
            <TestIdea />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Question &amp; Description" :disabled="hasSlug" :complete="completedDescription" show-left>
            <Question />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Assumptions" :disabled="hasSlug || !completedDescription" :complete="completedAssumptions" show-left>
            <Assumptions :challenge="$parent.fields.challenge" />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Image" :disabled="hasSlug || !completedDescription" :complete="completedImage" show-left>
            <Media />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Innovation Scale" :disabled="hasSlug || !completedDescription" :complete="completedInnovation" show-left>
            <Innovation />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Duration" :disabled="hasSlug || !completedDescription" :complete="completedDuration" show-left>
            <Duration />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Money &amp; Prizes" :disabled="hasSlug || !completedDescription" :complete="completedPrize" show-left>
            <Prize />
        </Step>
        <Step class="col-span-12 order-1 sm:order-2 sm:col-span-7 lg:col-span-8 xl:col-span-9" title="Caption Format" :disabled="hasSlug || !completedDescription" :complete="completedCaptionFormat" show-left>
            <CaptionFormat />
        </Step>
        <Step class="col-span-12" title="Preview, Edit &amp; Publish" :disabled="hasSlug || !completedDescription" :complete="hasSlug" activator>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 sm:col-span-8">
                    <Publish />
                </div>

                <div class="col-span-12 sm:col-span-4">
                    <Overview />
                </div>
            </div>
        </Step>
        <Step :disabled="!hasSlug" title="Share" hide-actions>
            <Share />
        </Step>
    </Stepper>
</template>
