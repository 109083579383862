<script>
import QQCoAuthor from '@/components/challenge/QQCoAuthor.vue';
import ChallengeTitle from '@/components/challenge/Title.vue';
import ChallengeImage from '@/components/challenge/Image.vue';
import NMedal from '@/components/leaderboard/Medal.vue';
import ReNerve from '@/components/challenge/ReNerve.vue';
import orderBy from 'lodash/orderBy';
import { MEDAL } from '@/constant/idea';
import { currency } from '@bignerve/ui-utils';
import { useChallengeDetails } from '@/mixins/ChallengeDetails';
import { toRaw } from '@/mixins/Markdown';

export default {
    name: 'ClosedChallengeCard',

    mixins: [useChallengeDetails()],

    props: {
        challenge: {
            type: Object,
            required: true
        }
    },

    components: { NMedal, QQCoAuthor, ChallengeTitle, ChallengeImage, ReNerve },

    data () {
        return {
            MEDAL,
            medals: {
                [MEDAL.CATALYST_GOLD]: 'catalyst-gold-medal',
                [MEDAL.CATALYST_SILVER]: 'catalyst-silver-medal',
                [MEDAL.CATALYST_BRONZE]: 'catalyst-bronze-medal',
                [MEDAL.COMMUNITY_GOLD]: 'community-gold-medal',
                [MEDAL.COMMUNITY_SILVER]: 'community-silver-medal',
                [MEDAL.COMMUNITY_BRONZE]: 'community-bronze-medal',
            },
        }
    },

    computed: {
        winners() {
            const winners = orderBy(
                this.challenge.winners,
                [ 'prize_catalyst_position', 'prize_winner_position' ]
                [ 'desc', 'desc' ]
            );

            const catalyst = winners.filter(
                winner => winner.prize_catalyst_medal
            ).map(w => ({...w, medal: w.prize_catalyst_medal }))

            const community = winners.filter(winner =>
                winner.prize_winner_medal && !winner.prize_catalyst_medal
            ).map(w => ({...w, medal: w.prize_winner_medal }))

            return [...catalyst, ...community].filter(({ solver }) => solver && solver.handle);
        },
    },

    methods: {
        currency,
        toRaw,
        currency,
    }
}
</script>

<template>
    <article class="challenge-card is-full-height relative">
        <ChallengeImage
            :challenge="challenge"
            :to="{ name: 'challenges.show', params: { challenge: challenge.slug } }"
        />

        <div v-if="winners.length" class="challenge-card__trophy-badge">
            <NIcon as="trophy-solid" />
        </div>
        
        <div class="challenge-card__body flex flex-col">
            <!-- <div class="challenge-card__renerve-link-wrapper">
                <ReNerve :challenge="challenge" />
            </div> -->

            <NPlayerDetails :user="challenge.catalyst" :clamp="false" />
            
            <RouterLink :to="{ name: 'challenges.show', params: { challenge: challenge.slug } }">
                <ChallengeTitle
                    :challenge="challenge"
                    class="font-bold text-lg leading-snug no-swiping text-dust-800"
                    tag="p"
                />
            </RouterLink>

            <QQCoAuthor :challenge="challenge" />

            <div class="challenge-card__finished-meta !mt-auto">
                <div class="challenge-card__finished-meta-status">
                    Challenge Closed <img class="icon" src="@/assets/icons/stopwatch.svg" alt="">
                </div>
                <RouterLink
                    :to="{ name: 'challenges.show', params: { challenge: challenge.slug }, query: { tab: 'ideas' } }"
                    class="challenge-card__finished-meta-link"
                >
                    See winning ideas!
                </RouterLink>
            </div>
            <div class="flex flex-col space-y-2 mb-3">
                <div v-for="(winner, index) in winners" :key="index" class="flex item-cemter justify-between text-sm ">
                    <RouterLink :to="{ name: 'challenges.show', params: { challenge: challenge.slug }, query: { idea: winner.id } }" class="flex item-cemter" aria-label="Access the challenge detail view" >
                        <div class="w-10">
                            <img
                                v-if="winner.medal && medals[winner.medal]"
                                class="w-8 h-8"
                                :src="`/img/medals/${medals[winner.medal]}.svg`"
                                alt="medal"
                            />
                            <NMedal
                                v-else
                                :medals="{}"
                                :position="winner.position || winner.prize_catalyst_position"
                                :star="winner.medal === MEDAL.BLUE"
                                class="w-7"
                            />
                        </div>
                        <div class="flex flex-col justify-center flex-1 leading-tight">
                            <strong v-tooltip="winner.title" class="text-dust-700 clamp-1">
                                {{ winner.title.slice(0, 40) }}
                            </strong>
                            <span v-if="winner.detail" class="text-dust-600 clamp-1 break-words">
                                {{ toRaw(winner.detail).slice(0, 40) }}
                            </span>
                        </div>
                    </RouterLink>
                    <RouterLink :to="{ name: 'home.user', params: { user: winner.solver.handle } }"  class="ml-2 flex items-center" :aria-label="`View ${winner.solver.first_name} profile`">
                        <NAvatar v-tooltip="winner.solver.first_name" :src="winner.solver.avatar_url" small alt="avatar" />
                    </RouterLink>
                </div>
            </div>
            <div class="challenge-card__footer !mt-0">
                <RouterLink
                    :to="{ name: 'challenges.show', params: { challenge: challenge.slug }, query: { tab: 'ideas' } }"
                    class="challenge-card__footer-item"
                >
                    <NIcon as="lightbulb-o1" left />
                    Ideas ({{ challenge.unlocked_ideas }})
                </RouterLink>
                <span :class="{ disabled: !currency(challenge.prize_money) }" class="challenge-card__footer-item">
                    <NIcon as="money-bill-alt-regular" class="text-lg" left />
                    ${{ currency(challenge.prize_money) }}
                </span>
                <span v-if="challenge.prize_type" class="challenge-card__footer-item">
                    <NIcon as="trophy-solid"  left />
                    {{ $t(`challenges.prize-type.${challenge.prize_type}`) }}
                </span>
                <button aria-label="button" class="btn has-no-style" @click.prevent="$bus('sharing:popup', challenge)">
                    <NIcon as="share-square-solid" />
                </button>
            </div>
        </div>
    </article>
</template>
