/** @module attachments */
export default requester => {
    return {
        /**
         * Creates an attachment object.
         *
         * @param {Object} attachment The attachment to create
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(attachment, params = {}, headers = {}) {
            return requester.makeRequest('POST', '/assumptions', { attachment }, params, headers);
        },

        /**
         * Gets all attachments for the specified object.
         *
         * @param {string} objectId The id of the object whose attachments should be retrieved
         * @param {Object} params Any additional parameters. (One or multiple of: organizationIdForTemporarySession)
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        all(objectId, params = {}, headers = {}) {
            return requester.makeRequest('GET', `/attachments/${objectId}`, params, headers);
        }
    }
}
