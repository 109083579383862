<script>
import { cva } from 'class-variance-authority';

const variants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-nerve-500 hover:bg-nerve-600 text-white hover:text-white',
        default: 'bg-white border text-dust-700 hover:text-dust-900 hover:bg-dust-100',
        destructive: 'bg-red-500 text-white hover:bg-red-600',
        outline: 'bg-white border text-dust-700 hover:bg-dust-100 hover:text-dust-900',
        ghost: 'text-dust-900 hover:bg-dust-100 hover:text-dust-900',
        link: 'text-dust-900 underline-offset-4 hover:underline hover:text-dust-900',
        muted: 'bg-dust-100 text-dust-800 hover:text-dust-900 hover:bg-dust-200',
        solid: 'bg-dust-200 text-dust-900 hover:bg-dust-300',
        inverted: 'bg-dust-700 hover:bg-dust-600 text-white hover:text-white',
        icon: 'items-center justify-center text-dust-900 hover:text-dust-900 hover:bg-dust-100',
      },
      size: {
        default: 'h-8 px-4 py-2',
        xs: 'h-7 rounded px-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
    compoundVariants: [
      {
        variant: 'icon',
        size: 'default',
        class: 'h-8 w-8 p-0 m-0 rounded-full',
      },
      {
        variant: 'icon',
        size: 'xs',
        class: 'h-7 w-7 p-0 m-0 rounded-full',
      },
      {
        variant: 'icon',
        size: 'sm',
        class: 'h-9 w-9 p-0 m-0 rounded-full',
      },
      {
        variant: 'icon',
        size: 'lg',
        class: 'h-12 w-12 p-0 m-0 rounded-full',
      },
    ]
  },
);
</script>

<script setup>
import Primitive from '../primitive';
import { cn } from '@/utils/Helpers';

const props = defineProps({
    variant: {
        type: String,
        default: 'default',
    },
    size: {
        type: String,
        default: 'default',
    },
    asChild: {
        type: Boolean,
        default: false,
    },
    as: {
        type: String,
        default: 'button',
    },
    type: {
        type: String,
        default: 'button',
    },
});
</script>

<template>
    <Primitive :as="as" :type="type" :asChild="asChild" :class="cn(variants({ variant, size }), $attrs.class)">
      <!-- // testing comment -->
        <slot></slot>
    </Primitive>
</template>