<script>
const regExpCause = new RegExp(/What(.*)could|How(.*)could/, 'i')

export default {
    name: 'TitleField',

    inject: ['form'],

    emits: ['update:modelValue', 'blur'],

    props: {
        modelValue: {
            type: String,
            default: '',
        },
        initialValue: {
            type: String
        },
        placeholder: {
            type: String,
            default: 'Type here to fill in the rest of your question',
        },
        forceError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: 'question_title',
        },
        maxLength: {
            type: Number,
            default: 180,
        },
    },

    data() {
        return {
            causes: [
                { title: 'What could', match: /What(.*)could/ },
                { title: 'How could', match: /How(.*)could/ }
            ],
        };
    },

    computed: {
        hasInitialWords () {
            return regExpCause.test(this.questionTitle)
        },
        questionTitle: {
            get () {
                return this.modelValue;
            },
            set (value) {
                this.$emit('update:modelValue', value);
            }
        },
    },

    watch: {
        questionTitle () {
            this.valid();
        },
    },

    methods: {
        changeCause(cause) {
            let title = this.questionTitle;

            if (!this.hasInitialWords) {
                title = cause + ' ' + title;
            }

            this.questionTitle = title.replace(regExpCause, cause);
            this.$forceUpdate();
        },
        isActive(cause) {
            return cause.test(this.questionTitle);
        },
        valid () {
            const text = []

            if (!this.hasInitialWords) {
                text.push('either "How could" or "What could"')
            }

            if (text.length) {
                this.form.errors.set(
                    this.id,
                    `Your title should contain ${text.join(' and contain ')}`,
                );
            } else {
                this.form.errors.clear(this.id);
            }
        },
    },

    created () {
        this.questionTitle = this.initialValue || 'What could';
    }
}
</script>

<template>
    <div class="tab-input has-question-mark">
        <div class="tab-input__header">
            <div class="tab-input__tabs">
                <button aria-label="button"
                    class="tab-input__tab"
                    v-for="(cause, index) in causes"
                    :key="index"
                    :class="{
                        'is-active shadow-sm shadow-black': isActive(cause.match),
                        '!bg-red-500': (forceError === true && isActive(cause.match)) || (isActive(cause.match) && !hasInitialWords),
                    }"
                    :disabled="disabled"
                    @click.prevent="changeCause(cause.title)"
                >
                    {{ cause.title }}...
                </button>
            </div>
        </div>
        <div class="tab-input__content">
            <div class="tab-input__field">
                <NTextarea
                    v-model="questionTitle"
                    :id="id"
                    :name="id"
                    :placeholder="placeholder"
                    :maxlength="maxLength"
                    :force-error="forceError === true || !hasInitialWords"
                    :disabled="disabled"
                    :required="required"
                    class=""
                    @blur="$emit('blur', $event)"
                    @keydown.tab="$emit('blur', $event)"
                />
            </div>
            <div v-if="questionTitle" class="tab-input__char-counter text-sm text-right px-1 pt-2">
                <b>{{ maxLength - questionTitle.length }}</b> characters remaining
            </div>
        </div>
    </div>
</template>