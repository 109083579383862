import LoadMore from '@/layouts/partials/LoadMore.vue';

const mixin = {
    components: {
        LoadMore
    },

    watch: {
        query: {
            handler(n, o) {
                // if (this.$route.name !== 'home') {
                //     this.updateUri();
                // }

                this.fetch();
            },
            deep: true
        },
        'query.sort'(n, o) {
            this.reset();
        }
    },

    data() {
        return {
            query: {
                page: 0
            },
            hash: null,
            busy: false,
            response: { items: [], meta: {} }
        };
    },

    computed: {
        isEmpty() {
            return this.response.items.length === 0;
        },
        hasMore() {
            return (this.response.meta.total || 0) > this.response.items.length;
        }
    },

    methods: {
        nextPage({ size } = {}) {
            this.query.size = size || this.query.size;
            this.query.page++;
        },
        merge(response) {
            this.response.meta = response.meta;
            this.response.items.push(...response.data);
        },
        fetch() {
            //
        },
        updateUri() {
            this.$router.replace({ query: this.query });
        },
        reset() {
            this.response = { items: [], meta: {} };
        },
        setQuery(key, value, hash) {
            const query = this.$route.query;

            if (query[key] !== value) {
                this.$router.push({ query: {
                    ...query, [key]: value
                }, hash: this.hash || hash });

                this.reset();
                this.query && (this.query[key] = value);
            }
        }
    }
}

export const useQueryable = () => mixin;

export default mixin;
