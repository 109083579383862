import { useQuery, useInfiniteQuery } from '@tanstack/vue-query';
import { api } from '@/modules/services';
import { computed, toValue } from 'vue';
import { useAuth } from '@/composable/auth';
import { uniqBy } from 'lodash';
import { relate } from '@/utils/Relationships';

export const useResources = (contextType, contextId) => {
    const { auth } = useAuth();
    const { data, isLoading } = useQuery({
        queryKey: ['discussion-resources', contextType, contextId],
        queryFn: async () => {
            const { data: response } = await api.discussions.resources(
                contextType, contextId,
            );

            return response.data;
        },
    });

    const resources = computed(() => {
        return (data?.value || []).reduce((acc, current) => {
            switch (current.type) {
                case 'user':
                    if (!acc.users) acc.users = {};
                    acc.users[current.role] = current;

                    if (current.id !== auth.user.id) {
                        acc.receiver = current;
                    }

                    break;
                default:
                    acc[current.type] = current;
            }

            return acc;
        }, {
            sender: auth.user,
        });
    });

    return { data: resources, isLoading }
};

export const useSearchDiscussions = (params = {}, enabled = true) => {
    const { data, ...passthrough } = useInfiniteQuery({
        queryKey: ['discussions', 'search', params],
        queryFn: async ({ pageParam }) => {
            const { data: response } = await api.discussions.search({
                ...toValue(params), scroll: pageParam,
            });

            return response;
        },
        getNextPageParam: ({ meta, data }) => {
            return data.length  ? (data.length === meta.total ? null : meta.scroll) : null;
        },
        initialPageParam: null,
        enabled,
    });

    const items = computed(() => data.value?.pages.reduce((acc, page) => {
        return acc.concat(page.data).filter(d => !d.context?.channel_ids?.length);
    }, []) || []);

    return {
        ...passthrough,
        data: items,
        meta: computed(() =>
            // get last page meta
            data.value?.pages[data.value.pages.length - 1]?.meta || {},
        ),
    };
}

export const useDiscussions = (context, id, params = {}) => {
    const { data, ...passthrough } = useInfiniteQuery({
        queryKey: ['discussions', 'find', context, id, params],
        queryFn: async ({ pageParam }) => {
            const { data: response } = await api.discussions.find(
                context,    
                id, {
                    size: 50,
                    after: pageParam,
                    ...toValue(params),
                },
            );

            return response;
        },
        getNextPageParam: ({ meta }) => {
            return meta.after || null;
        },
        initialPageParam: null,
    });

    // reduce data to a single array
    const items = computed(() => data.value?.pages.reduce((acc, page) => {
        return acc.concat(page.data);
    }, []) || []);

    const meta = computed(() => page.value?.meta || {});
    const relationships = computed(() => page.value?.relationships || {});

    // relationships

    return {
        ...passthrough,
        data: items,
        meta,
        relationships,
    };
}

export const useSuggestions = () => {
    return useQuery({
        queryKey: ['content', 'suggestions'],
        queryFn: async () => {
            const { data: response } = await api.content.suggestions().then(relate([
                ['platform', 'platform'],
            ]));

            // sort by suggestion_type === channel
            const items = uniqBy(response.data, 'id').sort((a, b) => {
                return a.suggestion_type === 'channel' ? -1 : 1;
            });

            return items.slice(0, 10);
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}

export const useChannels = (platformSlug) => {
    const isEndbled = computed(() => !!toValue(platformSlug));
    return useQuery({
        queryKey: ['content', 'channels', platformSlug],
        queryFn: async () => {
            const { data: response } = await api.forum.channels(toValue(platformSlug));

            return response.data;
        },
        enabled: isEndbled,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}

export const useChannel = (platformSlug, channelSlug) => {
    const isEndbled = computed(() => !!toValue(platformSlug) && !!toValue(channelSlug));
    return useQuery({
        queryKey: ['content', 'channels', platformSlug, channelSlug],
        queryFn: async () => {
            const { data: response } = await api.forum.channel(toValue(platformSlug), toValue(channelSlug));

            return response.data;
        },
        enabled: isEndbled,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}
