<script>
import WidgetTournaments from "@/components/widget/tournaments/index.vue";
import { usePlatform } from "@/mixins/ShowsPlatform";
import { useRouteQuery } from '@vueuse/router';

const Ask = () => import("@/components/home/Ask.vue");
const Forum = () => import("@/components/home/Forum.vue");
const Onboarding = () => import("@/components/home/Onboarding.vue");
// const Actions = () => import('@/components/home/Actions.vue');
// const Suggestions = () => import('@/components/home/Suggestions');

export default {
  name: "HomePage",

  components: {
    Ask,
    Forum,
    Onboarding,
    WidgetTournaments,
    // Actions,
    // Suggestions,
  },

  mixins: [usePlatform()],

  metaInfo() {
    const user = this.account.user;
    const image =
      user && user.screen_name
        ? `https://func.bignerve.com/og-image/${user.screen_name}.png`
        : `${import.meta.env.VUE_APP_BASE_URL}`;
    const [width, height] = user && user.screen_name ? [850, 420] : [60, 74];
    const description = (user && user.bio) || this.slogan;
    const contentUrl = `${import.meta.env.VUE_APP_BASE_URL}${
      this.$route.path
    }`;

    return {
      title: this.title,
      meta: [
        // Twitter Card
        {
          name: "twitter:card",
          content: "summary_large_image",
          vmid: "twitter:card",
        },
        { name: "twitter:title", content: this.title, vmid: "twitter:title" },
        {
          name: "twitter:description",
          content: description,
          vmid: "twitter:description",
        },
        {
          name: "twitter:site",
          content: `@${this.$platform.current.name}`,
          vmid: "twitter:site",
        },
        { name: "twitter:url", content: contentUrl, vmid: "twitter:url" },
        {
          name: "twitter:image:src",
          content: image,
          vmid: "twitter:image:src",
        },
        // Facebook OpenGraph
        { property: "og:title", content: this.title, vmid: "og:title" },
        {
          property: "og:site_name",
          content: this.$platform.current.title,
          vmid: "og:site_name",
        },
        { property: "og:type", content: "article", vmid: "og:type" },
        { property: "og:image", content: image, vmid: "og:image" },
        {
          property: "og:image:alt",
          content: description,
          vmid: "og:image:alt",
        },
        { property: "og:image:width", content: width, vmid: "og:image:width" },
        {
          property: "og:image:height",
          content: height,
          vmid: "og:image:height",
        },
        {
          property: "og:description",
          content: description,
          vmid: "og:description",
        },
        { property: "og:url", content: contentUrl, vmid: "og:url" },
        {
          property: "fb:app_id",
          content: "2555644634703447",
          vmid: "fb:app_id",
        },
      ],
    };
  },

  watch: {
    '$route.params.user' (handle) {
      if (handle !== 'platform') {
        this.$bus("catalyst:selected", { handle });
      }
    },
  },

  computed: {
    showOnboarding() {
      return false; // ! Cookie.has('onboarding-close', 3);
    },
    platformContextTitle() {
      if (/category/.test(this.$route.name)) {
        return "Topic";
      }

      if (/home.user|user.home|dashboard\./.test(this.$route.name)) {
        return this.isOrganization
          ? this.account.user.screen_name
          : this.account.user.first_name;
      }

      return this.title;
    },
  },

  setup () {
    const category = useRouteQuery('category', '');
    
    return {
      category,
    }
  },
};
</script>

<template>
  <div>
    <div class="grid grid-cols-12 gap-4" v-if="showOnboarding">
      <div class="col-span-12">
        <Onboarding />
      </div>
    </div>

    <div id="home-grid" class="home-grid">
      <div id="content" class="home-grid__top-content flex flex-col">
        <RouterView class="order-1" />
      </div>

      <div id="sidebar" class="home-grid__sidebar ">
        <div non-functional class="flex items-center justify-end disabled" >
          <RouterLink
            :to="{ name: 'discover' }"
            class="inline-flex items-center font-bold px-4 py-2 mb-4 rounded-lg border border-white hover:bg-white"
          >
            <NIcon as="nerve-shape-solid" class="mr-2 text-xl" /> Discover More
          </RouterLink>
        </div>

        <WidgetTournaments
          :platform-name="platformContextTitle"
          :platform-slug="$route.params.user || platform.slug"
          class="sm:sticky sm:top-[3.5rem]"
        />

        <!-- <div class="col-span-12">
          <div class="tertiary-heading-section">
                <h2 class="tertiary-heading-section__title">
                    Spark new Challenges for this Player
                </h2>
                <span class="tertiary-heading-section__subtitle">Suggest an interesting question!</span>
            </div>
            <div class="simple-challenge-card-list">
                <ask></ask>
            </div>
            <a href="#" class="btn is-full-width" title="Subscribe to automatically post (ReNerve) their challenges in your own Idea Tournament">
                <span aria-hidden="true" class="btn__icon is-left icon-retweet"></span>
                Subscribe to this Catalyst
                <span aria-hidden="true" class="btn__icon is-right icon-pencil-alt-solid"></span>
            </a>
        </div> -->

        <!-- <div class="col-span-12">
            <div class="tertiary-heading-section">
                <h2 class="tertiary-heading-section__title">Join the Discussions</h2>
            </div>
            <forum></forum>
        </div> -->

      </div>
    </div>
  </div>
</template>
