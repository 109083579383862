<script setup>
import { Loading as NNerveLoading } from '@bignerve/ui-core';
import { UseVirtualList, vInfiniteScroll } from '@vueuse/components';
import { useNeurons } from '@/queries/gamedash';
import { useAuth } from '@/composable/auth';
import { computed } from 'vue';
import NeuronEarned from './notification/NeuronEarned.vue';

const props = defineProps({
    role: {
        type: String,
        default: 'overall',
    },
    height: {
        type: String,
        default: '160px',
    },
});

const { auth } = useAuth();
const { data, isFetching, fetchNextPage } = useNeurons(auth.user.screen_name, computed(() => ({
    role: props.role,
})));
</script>

<template>
    <div ref="neuronsContent">
        <div v-if="data.length" class="game-dash-list overflow-x-hidden">
            <UseVirtualList v-infinite-scroll="[fetchNextPage, { distance: 200 }]" :list="data" :options="{ itemHeight: 100, overscan: 3 }" :height="height" class="sb-tight sb-rounded sb-dust-700 pr-3">
                <template #="{ data: neuron }">
                    <NeuronEarned :detail="neuron" class="mb-2" />
                </template>
            </UseVirtualList>
        </div>
        <div v-else-if="isFetching" :style="{ height }" class="flex items-center justify-center">
            <NNerveLoading />
        </div>
        <div v-else-if="!data.length" :style="{ height }" class="flex items-center justify-center text-dust-500">
            {{ $t('component.game-dash.neurons-earned.no-neurons') }}
        </div>
    </div>
</template>
