<script setup>
import { computed } from 'vue';
import { useFeatureFlag } from '@/composable/feature-flag';

const props = defineProps({
    flag: {
        type: String,
        required: true,
    },
    variant: {
        type: String,
        default: null,
    },
    expect: {
        type: [String, Boolean],
        default: null,
    },
    transform: {
        type: Function,
        default: null,
    },
    strict: {
        type: Boolean,
        default: true,
    },
});

const { enabled, variantTo, flag: flagTarget } = useFeatureFlag(props.flag);

const variantTarget = variantTo(props.variant);
const shouldRender = computed(() => {
    // the don't wants to check for a specific flag
    if (!props.flag) {
        return true;
    }

    // the user doesn't have the flag enabled
    if (!enabled.value && props.strict) {
        return false;
    }

    // the user has the flag enabled, but he doesn't 
    // want to check for a specific variant
    if (!props.variant) {
        return true;
    }

    // the user has the flag enabled, but the variant is not available
    
    if (!variantTarget.value) {
        return false;
    }

    // the user has the flag enabled, but the variant is not the expected one
    const payload = props.transform
        ? props.transform(variantTarget.value.payload) : variantTarget.value.payload;
    if (props.expect !== undefined) {
        return props.expect === payload;
    }

    return true;
})

</script>

<template>
    <slot v-if="shouldRender" :flag="flagTarget" :variant="variantTarget" :enabled="enabled" />
</template>
