<script>
import NotificationHighlight from './notification/Highlight.vue';
import { Loading as NNerveLoading } from '@bignerve/ui-core';
import { UseVirtualList, vInfiniteScroll } from '@vueuse/components';

export default {
    name: 'Highlights',

    props: {
        role: {
            type: String,
            default: 'overall',
        },
        height: {
            type: String,
            default: '160px',
        },
    },

    directives: {
        infiniteScroll: vInfiniteScroll,
    },

    components: {
        NotificationHighlight,
        NNerveLoading,
        UseVirtualList,
    },

    data() {
        return {
            busy: false,
            total: 0,
            size: 10,
            highlights: [],
        }
    },

    computed: {
        hasMore() {
            return this.highlights.length && this.highlights.length < this.total;
        }
    },

    watch: {
        role () {
            this.fetch(10, true)
        },
        highlights (items) {
            if (items.length) {
                this.$bus('latest-notifications:new', {
                    type: 'highlight',
                    detail: items[0],
                })
            }
        },
    },

    bus: {
        'game-dash:highlight' (data) {
            this.highlights.unshift(data);
        },
        'user:logged-in@once' () {
            this.fetch(15, true);
        },
    },

    methods: {
        async fetch (more = 10, reset = false, page = 0) {
            if (this.busy) return;

            try {
                if (!this.$auth.authenticated) {
                    return;
                }

                if (reset) {
                    this.highlights = [];
                }

                this.busy = true;
                const { data: response } = await this.$api.game.highlights(
                    this.$auth.user.id, {
                        size: this.highlights.length + more,
                        role: this.role, page,
                    },
                );

                this.total = response.meta.total;
                this.highlights = response.data;
            } finally {
                this.busy = false;
            }
        },
    },

    mounted() {
        this.fetch(15, true);
    },
}
</script>

<template>
    <div>
        <div v-if="highlights.length" class="game-dash-list overflow-x-hidden">
            <UseVirtualList v-infinite-scroll="[() => fetch(10), { distance: 200 }]" :list="highlights" :options="{ itemHeight: 100, overscan: 3 }" :height="height" class="sb-tight sb-rounded sb-dust-700 pr-3">
                <template #="{ data }">
                    <NotificationHighlight :detail="data" class="mb-2" />
                </template>
            </UseVirtualList>
        </div>
        <p v-else-if="!highlights.length" class="p-4 text-dust text-center">
            {{ $t('common.empty') }}
        </p>
        <div v-else-if="busy" class="h-20 flex items-center">
            <NNerveLoading />
        </div>
    </div>
</template>
