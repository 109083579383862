import { inject, ref, computed, getCurrentInstance, onMounted, isRef } from 'vue';
import { unrefElement } from '@vueuse/core';

export const useValidation = (props = {}) => {
    const form = inject('form');
    const vm = getCurrentInstance();
    const name = isRef(props.name) ? props.name : computed(() => props.name);
    const target = isRef(props.target) ? props.target : ref(null);

    const invalid = computed(() => {
        return form.errors.has(name.value);
    });

    const error = computed(() => {
        return invalid.value ? form.errors.get(name.value) : null;
    });

    const attrs = computed(() => {
        return Object.keys(form.$attrs)
            .filter(key => {
                return key !== 'model';
            }).reduce((attrs, key) => {
                return Object.assign(attrs, { [key]: form.$attrs[key] });
            }, {});
    });

    const clearErrors = () => {
        form.errors.clear(name.value);
    }

    const setError = (message) => {
        form.errors.set(name.value, message);
    }

    const focus = () => {
        unrefElement(target.value).focus();
    }

    onMounted(() => {
        form.formFields.push(vm);
    });

    return {
        invalid,
        error,
        attrs,
        focus,
        target,
        clearErrors,
        setError,
    };
}