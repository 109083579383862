<script setup>
import Attachments from '../discussion-forums/Attachments.vue';
import Discussion from '@/components/discussions/Discussion.vue';
import TextEditor from '@/components/form/TextEditor.vue';
import NDataForm from '@/components/form/Form.vue';
import NLoading from '@/components/NLoading.vue';
import NIcon from '@/components/NIcon.vue';
import MessagePlaceholder from '@/components/discussions/MessagePlaceholder.vue';
import { useLinks as useAuthLinks, useAuth } from '@/composable/auth';
import { useRouteQuery } from '@vueuse/router';
import { useElementVisibility, whenever, unrefElement } from '@vueuse/core';
import { ref, watch, computed, nextTick } from 'vue';
import { useDraft } from '@/composable/discussion';
import { useDiscussions } from '@/queries/discussion';
import { useChannel } from '@/composable/pusher';
import { DiscussionContext } from './DiscussionContext';
import { useStorage } from '@vueuse/core';
import { cn } from '@/lib/utils';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    context: {
        type: String,
        required: true,
    },
    select: {
        type: Function,
        default: v => v,
    },
    placeholder: {
        type: String,
        default: 'Start or contribute to a discussion about this Question!',
    },
});

const { listen } = useChannel(`discussion-${props.context}-${props.id}`);
const { signin } = useAuthLinks();
const { auth } = useAuth();
const links = useStorage('links', []);
const attaching = ref(false);
const uploading = ref(false);
const anchor = ref(null);
const mentions = ref([]);
const size = useRouteQuery('size', 50);
const after = ref(null);
const filter = useRouteQuery('filter', 'default');
const thread = useRouteQuery('thread', null);
const isVisible = useElementVisibility(anchor);

const messageId = computed(() => {
    const [id] = thread.value?.split('@') || [];

    return id;
})
const conversationId = computed(() => {
    const parts = thread.value?.split('@') || [];

    return parts.at(-1);
})

const createPostEl = ref(null);
const authors = ref([]);
const { content } = useDraft({
	context: props.context,
	id: props.id,
});

const params = computed(() => ({
    filter: filter.value,
    size: size.value,
    after: after.value,
    message: conversationId.value,
}));

const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useDiscussions(
    props.context,
    props.id,
    params,
);

const discussions = computed(() => {
    return data.value.filter(props.select);
});

watch(discussions, (newData) => {
    newData.forEach((d) => {
        if (authors.value.find((mention) => mention.id === d.author.id)) return;

        authors.value.push({
            id: d.author.id,
            value: d.author.handle,
            name: d.author.first_name
        });
    })
})

const handleOnSuccess = () => {
    createPostEl.value.reset();
    links.value = [];

    refetch();
};

const handleBeforeSubmit = () => {
    if (!auth.authenticated) {
            signin();

            return false;
    }

    return true;
};

const handleOnRemove = (payload) => {
    refetch();
};

whenever(isVisible, () => {
    if (isFetching.value || !isFetchingNextPage.value) return;

    fetchNextPage();
});

const scrollToCreatePost = () => {
    const el = unrefElement(createPostEl);
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });

    setTimeout(() => {
        createPostEl.value.focus();
    }, 1000);
};

const selectThread = (message) => {
    if (!message) {
        thread.value = null;
        
        return;
    }

    thread.value = message.conversation_id ? `${message.id}@${message.conversation_id}` : message.id;
};

const messageInitHandler = ({ id }) => {
    if (id !== messageId.value) return;

    nextTick(() => {
        const target = `discussion-message-${messageId.value}`;
        const el = document.getElementById(target);

        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    })
}

// event message-created
const updateMessages = (data) => {
    if (data.author_id === auth.user.id || isFetching.value) {
        return;
    }

    refetch();
}
listen('message-created', updateMessages);
listen('message-deleted', updateMessages);
</script>

<template>
    <DiscussionContext strict-reply #="{ isReplying }">
        <div>
            <slot name="header" :scrollToCreatePost="scrollToCreatePost"></slot>
            
            <NFilters v-model="filter" class="mb-4">
                <NFilterItem
                    label="First to Last"
                    id="default"
                />
                <NFilterItem
                    label="Most Recent"
                    id="recent"
                />
                <NFilterItem
                    label="Most Helpful"
                    id="helpful"
                />
                <NFilterItem
                    label="Most Insightful"
                    id="insightful"
                />
                <NFilterItem
                    label="Most Funny"
                    id="funny"
                />
                <NFilterItem
                    label="My Comments"
                    id="mine"
                />
            </NFilters>

            <div class="flex flex-col gap-8">
                <button v-if="thread && discussions?.length" type="button" class="w-full border border-dashed rounded-lg h-24 flex items-center justify-center border-dust-400 text-dust-800 hover:bg-dust-100" @click="selectThread(null)">
                    <NIcon as="reply" class="mr-2" />Load all messages
                </button>

                <Discussion
                    v-for="discussion of discussions"
                    :key="discussion.id"
                    :discussion="discussion"
                    :canReply="true"
                    :loadReplies="['recent', 'default'].includes(filter)"
                    :filter="filter"
                    :initialAuthors="authors"
                    :returnHandler="selectThread"
                    :highlightHandler="({ id }) => messageId === id"
                    :messageInitHandler="messageInitHandler"
                    @onRemove="handleOnRemove"
                />

                <template v-if="!discussions.length && isFetching">
                    <MessagePlaceholder v-for="i of size" :key="`placeholder-message-${i}`" />
                </template>

                <MessagePlaceholder v-if="hasNextPage" ref="anchor" />

                <NDataForm
                    :action="`/content/contexts/${context}/${id}/messages`"
                    :data="{ content: content, links, mentions }"
                    :beforeSubmit="handleBeforeSubmit"
                    :class="{ 'disabled': isReplying.value, group: true }"
                    @success="handleOnSuccess"
                    #="{ busy }"
                >
                    <div class="form-group">
                        <div
                            :class="cn(
                                'quill-editor-wrapper quill-editor-wrapper--message rounded-lg p-4 border border-transparent',
                                'group-hover:shadow group-hover:border-dust-200 group-hover:bg-dust-100',
                                { 'shadow border-dust-200 bg-dust-100': content.length > 0 || attaching }
                            )"
                        >
                            <div class="quill-editor-title min-h-8 !font-normal flex items-center justify-start">
                                <NPlayerDetails
                                    v-if="auth.authenticated"
                                    :user="auth.user"
                                    :routable="false"
                                    class="content-start"
                                />
                            </div>
                            <TextEditor
                                v-model="content"
                                v-model:mentions="mentions"
                                :users="authors"
                                :placeholder="placeholder"
                                ref="createPostEl"
                                name="post-comment"
                                required
                            />
                        </div>
                    </div>

                    <Attachments v-if="attaching" v-model="links" class="w-auto sm:ml-[65px] shadow border-dust-200 bg-dust-100" :arrow-class="'arrows-bg-dust-100'" :editable="true" @uploading="state => uploading = state" />

                    <div class="py-2 flex items-center justify-end mb-2 gap-2">
                        <FeatureFlag flag="discussion-forums-attachments">
                            <button aria-label="button"
                                type="button"
                                class="flex items-center px-4 py-1 rounded-md bg-dust-200 hover:bg-dust-300 text-dust-800"
                                @click="attaching = !attaching"
                            >
                                <NIcon as="attach" class="mr-2" />
                                {{ attaching ? 'Close...' : 'Attach' }} <span v-if="links.length" class="ml-1">({{  links.length }})</span>
                            </button>
                        </FeatureFlag>
                        <button aria-label="button"
                            type="submit"
                            class="flex items-center px-4 py-1 gap-2 bg-dust-700 rounded-md hover:bg-dust-600 text-white"
                            :class="{ disabled: busy || content.length < 5 || uploading }"
                            :disabled="busy || content.length < 5 || uploading"
                        >
                            <NLoading v-if="busy" active />
                            <NIcon
                                v-else
                                as="discussion-icon"
                            />
                            Post
                        </button>
                    </div>
                </NDataForm>
            </div>
        </div>
    </DiscussionContext>
</template>
