/** @module feedback */
export default requester => {
    return {
        /**
         * Creates a new feedback entry.
         *
         * @param {Object} payload Feedback to create
         * @param {Object} params Additional query arguments to append to the URL.
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        create(ideaLog, params = {}, headers = {}) {
            return requester.makeRequest('POST', `/feedback`, { ideaLog }, params, headers);
        },

        /**
         * Gets all feedback submitted for this url.
         *
         * @param {string} url The url of the page for which feedback should be retrieved
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        byUrl(url, params = {}, headers = {}) {
            return requester.makeRequest('GET', '/feedback-by-url', { url }, params, headers);
        },

        /**
         * Gets all feedback submitted by this user
         * @param {string} userId The id of the user whose feedback should be retrieved
         * @param {Object} headers Additional headers to send along with your request. This could include authentication headers.
         */
        byUser(userId, params = {}, headers = {}) {
            return requester.makeRequest('GET', '/feedback-by-user', { userId }, params, headers);
        }
    }
}
