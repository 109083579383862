<script setup>
import Button from '@/components/ui/button/index.vue';
import Alert from '@/components/NAlert.vue';
import Icon from '@/components/NIcon.vue';
import { api } from '@/modules/services';
import { middleTruncate } from '@bignerve/ui-utils';
import { cn } from '@/utils/Helpers';
import { useDropZone } from '@vueuse/core';
import { ref, watch } from 'vue';
import { useCancelToken } from '@/composable/requester';
import { startCase } from 'lodash';

const CLOUDFLARE_CODE = 'k7nwwkrdvbmq0j3f';
const API_BASE_URL = import.meta.env.VUE_APP_API_BASE_URL;

const props = defineProps({
    truncate: {
        type: Number,
        default: 30
    },
    accept: {
        type: Array,
        default: () => ['*']
    },
    placeholder: {
        type: String,
        default: 'Upload...'
    },
    maxSize: {
        type: Number,
        default: 1024 * 1024 * 10 // 10MB
    },
    headers: {
        type: Object,
        default: () => ({})
    },
    class: {
        type: String,
        default: ''
    }
})

const dropZoneRef = ref(null);
const uploading = ref(false);
const error = ref(null);
const progress = ref(0);
const file = ref(null);
const label = ref(null);
const { abortPrevious, nextToken } = useCancelToken();
const emit = defineEmits(['uploading', 'uploaded', 'cancel']);

const handleSelectedFile = (event) => {
    const [target] = event.target.files;
    if (!target) return;

    if (!label.value) {
        // normalize label
        label.value = startCase(target.name.replace(/\.[^/.]+$/, ''));
    }

    file.value = target;
}

const handlePaste = (event) => {
    event.preventDefault();
    const items = event.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
            const pastedFile = item.getAsFile();
            file.value = pastedFile;
            
            if (!label.value) {
                // normalize label
                label.value = startCase(pastedFile.name.replace(/\.[^/.]+$/, ''));
            }
            break;
        }
    }
}

const { isOverDropZone } = useDropZone(dropZoneRef, {
    onDrop: (files) => {
        const [target] = files;
        if (!target) return;

        file.value = target;
        if (!label.value) {
            // normalize label
            label.value = startCase(target.name.replace(/\.[^/.]+$/, ''));
        }
    },
    onEnter: () => {
        // Optional: Add any visual feedback when file enters the drop zone
    },
    onLeave: () => {
        // Optional: Reset any visual feedback when file leaves the drop zone
    }
})

const reset = () => {
    uploading.value = false;
    progress.value = 0;
    file.value = null;
    label.value = null;
    error.value = null;
}

const uploadFile = async () => {
    try {
        error.value = null;
        uploading.value = true;
        emit('uploading', true);

        if (file.value.type.startsWith('video')) {
            const data = await api.forum.attachVideo(file.value, {
                name: label.value,
                type: file.value.type,
                private: true,
            }, {
                onUpdateProgress: (value) => {
                    progress.value = value;
                },
            });

            const url = `${API_BASE_URL}/content/attachments/video/${data.videoId}/iframe`

            emit('uploaded', { url, name: label.value, type: file.value.type });

            return;
        }

        const data = await api.forum.attachFile(file.value, {
            name: label.value,
            type: file.value.type,
        }, {
            onUpdateProgress: (value) => {
                progress.value = value;
            },
            cancelToken: nextToken()
        });

        const url = `${API_BASE_URL}/content/attachments/file/${data.fileKey}`

        emit('uploaded', { url, name: label.value, type: file.value.type });
    } catch (error) {
        console.error(error);

        error.value = 'An error occurred while uploading the file. Please try again.';

        throw error;
    } finally {
        emit('uploading', false);
        reset();
    }
}

const cancelUpload = () => {
    reset();
    abortPrevious();

    emit('cancel');
}
</script>

<template>
    <form :class="cn('grid gap-4 w-full transition-colors', props.class, { 'bg-gray-50': isOverDropZone })" @submit.prevent="uploadFile" ref="dropZoneRef" @dragover.prevent @paste="handlePaste">
        <input v-model="label" placeholder="Describe your file in 90 characters or less..." maxlength="90" class="placeholder:italic h-9 border border-dust-300 rounded-md px-4" />
        <label class="flex-1 flex items-center relative" :disabled="uploading">
            <div class="flex items-center border border-dust-300 h-9 px-4 bg-white mr-2 rounded-md overflow-hidden whitespace-nowrap w-full sm:w-[300px]">
                <span v-if="!file" class="text-gray-400 italic">
                    {{ placeholder }}
                </span>
                <span v-else>
                    {{ middleTruncate(file.name, truncate) }}
                </span>
            </div>
            <div :class="{ 'hover:bg-nerve-600': !uploading }" class="relative inline-flex overflow-hidden h-9 items-center justify-center px-6 rounded-md shadow border bg-nerve text-white transition ease-in-out duration-300 cursor-pointer whitespace-nowrap">
                Browse
            </div>
            <input type="file" class="hidden" :accept="accept.join(', ')" @change="handleSelectedFile" />
        </label>

        <Alert :open="!!error" type="danger" class="!m-0 p-0">
            {{  error  }}
        </Alert>

        <div class="flex items-center gap-2 justify-between">
            <div class="flex gap-2">
                <Button variant="solid" size="sm" type="submit" class="relative overflow-hidden gap-2 min-w-40" :disabled="!file || uploading">
                    <span class="z-[1] flex items-center gap-2">
                        <Icon as="arrow-circle-up" class="leading-0" />
                        {{ uploading ? 'Uploading...' : 'Upload' }}
                    </span>
                    
                    <span class="absolute h-full bg-dust-500 animate-loading bottom-0 left-0 transition-all duration-500 easy-in-out" :style="{ width: `${progress}%`,  }"></span>
                </Button>
                <Button v-if="uploading" variant="ghost" size="sm" class="min-w-20" type="button" @click="cancelUpload">
                    Cancel
                </Button>
            </div>
            <span v-if="isOverDropZone" class="text-sm text-dust-600 p-2">
                Drop files here
            </span>
        </div>
    </form>
</template>