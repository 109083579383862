<script>
import flatpickr from 'flatpickr';
import Validatable from '@/mixins/Validatable';
import { nanoid } from 'nanoid';

export default {
    mixins: [Validatable],

    props: {
        modelValue: {
            type: [String, Date],
            default: ''
        },
        range: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        enableTime: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: 'Y-m-d'
        },
        endTime: {
            type: Object
        },
        minDate: {
            type: [Date, Object, String],
            default: '1900-01'
        },
        maxDate: {
            type: [Date, Object, String],
            default: '2099-12'
        },
    },

    emits: ['update:modelValue', 'change'],

    watch: {
        modelValue(newValue) {
            if (this.flatpickr) {
                this.flatpickr.setDate(
                    newValue, false, this.flatpickr.config.altFormat
                );
            }
        },
        minDate(newValue) {
            if (this.flatpickr) {
                this.flatpickr.set('minDate', newValue);
            }
        },
        maxDate(n, o) {
            if (this.flatpickr) {
                this.flatpickr.set('maxDate', n)
            }
        }
    },

    computed: {
        id () {
            return this.name || nanoid();
        },
    },

    mounted() {
        let self = this;
        this.$nextTick(() => {
            this.flatpickr = flatpickr(this.$refs.dateInput, {
                static: true,
                enableTime: this.enableTime,
                enableSeconds: false,
                altInput: true,
                dateFormat: 'Z',
                altFormat: this.format,
                allowInput: true,
                minDate: this.minDate,
                maxDate: this.maxDate,
                mode: this.range ? 'range' : 'single',
                onChange: (selDates, dateStr) => {
                    this._selDateStr = dateStr;

                    if (this.endTime) {
                        this.endTime.flatpickr.set('minDate', selDates[0]);
                    }
                },
                onClose: function (selDates, dateStr, instance) {
                    if (this.config.allowInput && this._input.value && this._input.value !== this._selDateStr) {
                        this.setDate(this.altInput.value, false, this.config.altFormat);
                    }
                    self.update(this.altInput.value);
                }
            });
        })
    },

    data() {
        return {
            flatpickr: null
        };
    },

    methods: {
        update(value) {
            this.form.errors.clear(this.id);
            this.$emit('update:modelValue', value);
            this.$emit('change', value);
        },
        clear() {
            this.flatpickr.clear();
            this.update(null);
        }
    },

    destroyed () {
        if (this.flatpickr) {
            this.flatpickr.clear();
        }
    }
}
</script>

<template>
    <div class="form-group__helper is-datepicker" :class="{ 'is-range': range }">
        <label v-if="label" :for="id" class="form-group__label whitespace-nowrap" style="display: block;">{{ label }}</label>
        <span v-if="hint" class="form-group__hint" style="display: block;">{{ hint }}</span>
        <label :for="id" class="form-group__input-wrapper m-0 flex items-center border border-dust-300 rounded-md">
            <input
                v-bind="attrs"
                class="form-group__input"
                type="text"
                ref="dateInput"
                :class="{ 'is-error': invalid, 'is-rounded': rounded }"
                :id="id"
                :name="id"
                :value="modelValue"
                :disabled="disabled"
            >
            <NIcon as="calendar-date-picker" class="ml-1 mt-[2px]" />
        </label>
        <span v-if="invalid" class="form-group__error">{{ error }}</span>
    </div>
</template>

<style lang="scss">
    .flatpickr-calendar.static.open {
        position: absolute !important;
    }
</style>
