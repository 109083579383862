import { fetcher } from '@/modules/services';
import { useQuery } from '@tanstack/vue-query';

export const useIdeaContent = (challengeSlug, ideaId) => {
    return useQuery({
        queryKey: ['idea-content', challengeSlug, ideaId],
        queryFn: async () => {
            const { data } = await fetcher(`/challenges/${challengeSlug}/ideas/${ideaId}/contents`);

            return data;
        },
    });
};