import * as Sentry from '@sentry/vue';
import { get } from 'lodash';
import { version } from '../../package.json';
import { formatComponentName, generateComponentTrace } from '@/utils/component';

const ignoredStatus = [401, 404, 412, 422, 429, 504];

const useMetadata = (vm, options = {}) => {
  if (!vm) {
    return;
  }

  const { attachProps, attachAttrs } = options;
  const trace = generateComponentTrace(vm);
  const component = formatComponentName(vm, true);
  const metadata = { component, trace };

  if (attachProps) {
    metadata.props = JSON.stringify(vm.$props, null, 2);
  }

  if (attachAttrs) {
    metadata.attrs = JSON.stringify(vm.$attrs, null, 2);
  }

  // adds extra info to the error
  const context = vm._;
  if (context) {
    metadata.context = JSON.stringify({
      name: get(context, 'type.name', get(context, 'type.title', 'Anonymous')),
      file: context.__file || get(vm, '$options.__file'),
    }, null, 2);
  }

  return metadata;
}

const useErrorHandler = (app, options = {}) => {
  const { errorHandler } = options;

  app.config.errorHandler = (err, vm, lifecycleHook) => {
    const component = useMetadata(vm, options);
    const parent = vm.$parent ? useMetadata(vm.$parent, options) : {};

    Sentry.withScope(scope => {
      scope.setContext('Component', {
        lifecycleHook, ...component,
      });

      scope.setContext('Parent...', parent);

      Sentry.captureException(err);
    })

    if (typeof errorHandler === 'function') {
      errorHandler.call(app, err, vm, lifecycleHook);
    }
  }
}

export const sentry = Sentry;

export const install = ({ app, router }) => {
  const [, platformName] = location.hostname.split('.').reverse();
  const { errorHandler } = app.config;

  if (!!import.meta.env.VUE_APP_SENTRY_DSN) {
    console.log('Sentry enabled');

    Sentry.init({
      app,
      dsn: import.meta.env.VUE_APP_SENTRY_DSN,
      environment: import.meta.env.MODE,
      attachStacktrace: true,
      trackComponents: true,
      release: `${platformName}-app@${version}`,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 0.3,
      ignoreErrors: [
        /ResizeObserver/i,
        /CanceledError/i,
        ...ignoredStatus.map(n => `code ${n}`)
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // TODO: remove this url when fixed
        /api\/brain\/(.*)\/neurons/i
      ],
      beforeSend(event) {
        // Ignore "Request aborted" errors
        if (event.exception && event.exception.values) {
          const exception = event.exception.values[0];
          if (exception.value && exception.value.includes('Request aborted')) {
            return null;
          }
        }
        return event;
      },
    })

    // replace the default Vue error handler from Sentry
    useErrorHandler(app, { attachProps: true, attachAttrs: true, errorHandler });
  }

  app.provide('sentry', Sentry);

  app.mixin({
    beforeCreate() {
      this.$sentry = Sentry;
    }
  })
}
