<script setup>
import ImagePreview from '@/components/NImage.vue';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: null,
  },
  metadata: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const thumbnail = computed(() => {
  return String(props.modelValue).replace('/images/', '/thumbnail/')
});
</script>

<template>
  <a target="_blank" :href="modelValue" rel="noopener noreferrer">
    <ImagePreview :srcs="[thumbnail, modelValue]" class="!aspect-image" v-bind="metadata" />
  </a>
</template>