<script>
import Tour from '@/components/tour/index.vue';
import Guide from '@/components/tour/Guide.vue';
import OnboardingFlow from '@/components/onboarding/Flow.vue';
import Resolver from '@/components/Resolver.vue';
import { usePlatform } from '@/mixins/ShowsPlatform';
import { steps } from '@/store/onboarding';
import { date as formatDate, currency } from '@bignerve/ui-utils';
import { crossPlatformState } from '@/utils/App';
import { useFeatureFlag } from '@/composable/feature-flag';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const tourState = crossPlatformState('bignerve.tour');

export default {
    name: 'TourContainer',

    mixins: [usePlatform()],

    components: {
        Tour,
        Guide,
        OnboardingFlow,
        Resolver,
    },

    data() {
        return {
            afterOnboarding: null,
        };
    },

    computed: {
        onboardingSteps () {
            if (this.$route.query.tour) {
                return steps.apply(this, [this.$route.query.tour]);
            }

            return [];
        },
    },

    watch: {
        '$route.query.tour': {
            handler (guide) {
                setTimeout(() => {
                    if (guide) {
                        this.$refs.tour && this.$refs.tour.goToGuide(guide);

                        this.scrollToTop();
                    } else {
                        this.$refs.tour && this.$refs.tour.exit();
                    }
                }, 1000)
            },
            immediate: true,
        },
    },

    methods: {
        currency,
        formatDate,
        scrollToTop () {
            this.$bus('header:scroll-to-top')
        },
        finishTour ({ storeKey = 'onboarding-guide' } = {}) {
            tourState[storeKey] = true;

            if (this.$route.query.tour) {
                delete this.$route.query.tour;

                this.$router.push({ query: this.$route.query });
            }
        },
        dispatchOnboarindDone () {
            if (this.afterOnboarding) {
                this.afterOnboarding();
            }
        },
        redirectAfter (route) {
            this.afterOnboarding = () => {
                this.$router.push(route)

                this.afterOnboarding = null
            }
        },
        getOnboardingSteps () {
            return [
                'The **Leaderboards** showing your points & crowd ranking',
                `${this.contextUser.user.id ? this.contextUser.user.first_name + ' ' + this.contextUser.user.last_name : this.title }’s **Home**`,
                this.$auth.authenticated && 'Your Innovator Dashboard',
                'Video intro and Slack invite',
            ].filter(t => t)
        },
    },

    setup () {
        const route = useRoute();
        const { variantTo } = useFeatureFlag('tournament-extra-prize');
        const variant = variantTo(() => route.params.platformSlug);

        const extraPrize = computed(() => {
            if (!variant.value) {
                return null;
            }
            return Array.isArray(variant.value.payload)
                ? variant.value.payload.join(' ')
                : variant.value.payload;
        })

        return {
            extraPrize,
        }
    },
}
</script>

<template>
    <OnboardingFlow ref="onboarding" :steps="onboardingSteps" text-done="Next" @done="dispatchOnboarindDone" />

    <Tour ref="tour" #default="{ goToGuide }" @close="finishTour" @done="finishTour">
        <Guide ref="guide" id="start-guide" :to="{ name: 'platform.olympiad.leaderboard', params: { platformSlug: contextUser.user.id && contextUser.user.screen_name || 'platform' }, query: { tour: 'leaderboard-1' } }" text-next="Let's go!">
            <template v-if="$route.name === 'challenges.rate'">
                <h3 class="text-xl">Kudos, you just rated the ideas in one of BigNerve’s question challenges.</h3>
                <p class="italic">Learn more on a brief guided tour of the site...</p>
            </template>
            <template v-else>
                <h3 class="text-xl">Welcome to BigNerve, a new kind of crowdsourcing idea tournament.</h3>
                <div class="mb-3 text-lg">
                    <div class="mb-6 text-lg">
                        <p>Watch the intro video below to learn more, or just exit and start browsing. You can also create challenges in our <a href="https://join.slack.com/t/bignervecommunity/shared_invite/zt-17o8qx4sy-yIMdF6ozFUM77i1PNrmd7w" target="__blank">Slack Community</a>.</p>
                        <div class="flex items-center justify-center h-[27.5rem] overflow-hidden rounded-md">
                            <iframe class="h-full w-full" src="https://www.youtube-nocookie.com/embed/bsSy3p5lvgo?autoplay=1" title="Nerve on" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <p class="italic">Learn more...</p>
                </div>
            </template>
            <ul class="list-none p-0 sm:pl-6 mb-3">
                <li v-for="(step, i) of getOnboardingSteps()" :key="i" class="mb-3">
                    <div class="relative">
                        <div class="relative flex items-center space-x-1">
                            <div class="px-1">
                                <div class="h-7 w-7 bg-nerve-500 rounded-full font-bold flex items-center justify-center text-white">
                                    {{ i + 1 }}
                                </div>
                            </div>
                            <NMarkdown :code="step" class="min-w-0 flex-1 py-0" inline />
                        </div>
                    </div>
                </li>
            </ul>
        </Guide>
        <Guide ref="guide" id="leaderboard-1" text-next="Show Tips" :handler-next="() => $refs.onboarding.start()" :transition="{ leaveToClass: 'slide-leave-to' }" @open="afterOnboarding = () => goToGuide('leaderboard-2')">
            <div class="flex items-center mb-3">
                <span class="!h-7 !w-7 whitespace-nowrap bg-nerve-500 rounded-full font-bold inline-flex items-center justify-center text-white mr-2">
                    1
                </span>
                <h3 class="text-xl m-0">This is {{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }}’s Tournament Leaderboard</h3>
            </div>
            <div class="relative mb-6 text-lg">
                <p>It shows all the participants in {{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }}’s Question Challenges. A tournament is a <b>think tank/writing room</b>. It renews monthly, and shows your points generated by crowd-sourced ranking.</p>

                <Resolver v-if="$route.params.platformSlug === 'shermer'" :promise="() => ($api.widgets.olympiads('x', { platform_slug: $route.params.platformSlug || 'bignerve' }))" :prune="data => data.current.olympiad" #done="{ prize_total, end_time }">
                    <ul class="list-circle">
                        <li>Your best ideas could be <b>tweeted</b> out by {{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }} on his Twitter feed.</li>
                        <li v-if="prize_total">It has <b>${{ currency(prize_total) }}</b> in prize money for {{ formatDate(end_time, 'MMMM') }}.</li>
                        <li v-if="extraPrize">It has a <b>{{ extraPrize }}</b>.</li>
                    </ul>
                </Resolver>
                <span class="font-bold absolute -bottom-13.5 right-5">1/2</span>
            </div>
        </Guide>
        <Guide ref="guide" id="leaderboard-2" :handler-next="() => ($refs.onboarding.start())" :to="{ name: 'home.challenges', query: { tour: 'home-page' } }" :transition="{ enterClass: 'slide-enter' }">
            <div class="flex items-center mb-3">
                <span class="h-7 w-7 bg-nerve-500 rounded-full font-bold flex items-center justify-center text-white mr-2">
                    1
                </span>
                <h3 class="text-xl m-0">This is {{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }}’s Tournament Leaderboard.</h3>
            </div>
            <div class="relative mb-6 text-lg">
                <p>You earn points by entering your ideas, rating other players’ ideas, mentoring ideas, and asking Questions. Each activity has a Role shown on the leaderboard.</p>
                <p>You earn some points for quantity, but the most points for high quality participation. These points express your innovativeness, which we call your <b>Nerve Score</b>.</p>
                <p>This a place where you can be <b>recognized</b> for your insightful thinking.</p>
                <span class="font-bold absolute -bottom-13.5 right-5">2/2</span>
            </div>
        </Guide>
        <Guide ref="guide" id="home-page" text-next="Show Features" :handler-next="() => $refs.onboarding.start()" @open="redirectAfter($auth.authenticated ? { name: 'dashboard.innovator', params: { user: $auth.user.screen_name }, query: { tour: 'innovator-dashboard' } } : { name: 'home.challenges', query: { tour: 'nerve-on' }, hash: '#app-navbar' })">
            <div class="flex items-center mb-3">
                <span class="h-7 w-7 bg-nerve-500 rounded-full font-bold flex items-center justify-center text-white mr-2">
                    3
                </span>
                <h3 class="text-xl m-0">{{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }}'s Home</h3>
            </div>
            <div class="mb-6 text-lg">
                <p>Browse these Challenge Questions written by {{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }}. Join {{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }}’s personal <b>think tank</b> and get recognized for co-creating together.</p>
                <p v-if="$route.params.user === 'shermer'">{{ contextUser.user.id ? `${contextUser.user.first_name} ${contextUser.user.last_name}` : title }} is putting up $2500 on his tournament {{ extraPrize ? `and ${extraPrize}` : '' }}, this month.</p>
                <p class="italic">Every user’s Home page shows just their challenges and tournaments. BigNerve encompasses all of them in a meta game layer.</p>
            </div>
            <button aria-label="button" type="button" class="px-3 py-1 flex items-center sm:-mb-7 text-nerve hover:bg-nerve-100 rounded-md font-semibold" @click.prevent="$bus('tour:next', { guide: 'nerve-on' })">
                <NIcon as="play-circle-regular" class="mr-1" />Watch video
            </button>
        </Guide>
        <Guide ref="guide" id="innovator-dashboard" text-next="Show Tips" :handler-next="() => $refs.onboarding.start()" @open="redirectAfter({ name: 'home.challenges', query: { tour: 'nerve-on' }, hash: '#app-navbar' })">
            <div class="flex items-center mb-3">
                <span class="h-7 w-7 bg-nerve-500 rounded-full font-bold flex items-center justify-center text-white mr-2">
                    4
                </span>
                <h3 class="text-xl m-0">Your Innovator Dashboard</h3>
            </div>
            <div class="mb-6 text-lg">
                <p>Keep track of all your ideas and activities in the Question challenges. Every Game Role has a dedicated panel showing your progress based on the community’s feedback.</p>
            </div>
        </Guide>
        <Guide ref="guide" id="nerve-on">
            <div class="flex items-center mb-3">
                <span class="h-7 w-7 bg-nerve-500 rounded-full font-bold flex items-center justify-center text-white mr-2">
                    5
                </span>
                <h3 class="text-xl m-0">Nerve on!</h3>
            </div>
            <div class="mb-6 text-lg">
                <p>Watch the intro video below to learn more, or just exit and start browsing. You can also create challenges in our <a href="https://join.slack.com/t/bignervecommunity/shared_invite/zt-17o8qx4sy-yIMdF6ozFUM77i1PNrmd7w" target="__blank">Slack Community</a>.</p>
                <div class="flex items-center justify-center h-[27.5rem] overflow-hidden rounded-md">
                    <iframe class="h-full w-full" src="https://www.youtube-nocookie.com/embed/eLKGuCdu6H0?autoplay=1" title="Nerve on" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </Guide>
    </Tour>

    <Tour #default="{ context: { olympiad, catalyst } }">
        <Guide ref="guide" id="challenge-detail-welcome" close-outside>
            <h3 class="text-xl">Welcome to {{ catalyst && `${catalyst.first_name} ${catalyst.last_name}` }}'s idea tournament. </h3>

            <div class="mb-6 text-lg">
                <p class="italic">Powered by BigNerve, the web's newest place to think together.</p>
                <p>Feel free to <b>enter your unexpected ideas</b>. To only <b>browse the ideas</b>, just begin rating.</p>
                <p>There's ${{ currency(olympiad ? olympiad.prize_total : 0) }} prize money <span v-if="extraPrize">and a {{ extraPrize }} with {{ catalyst && `${catalyst.first_name} ${catalyst.last_name}` }}</span>.</p>
                <p>This round ends {{ olympiad && formatDate(olympiad.end_time, 'MMM DD, YYYY')}} and restarts again next month.</p>
            </div>

            <template #actions="{ exit }">
                <div class="grid grid-cols-2 sm:grid-cols-3 w-full">
                    <div class="flex justify-center">
                        <button aria-label="button" type="button" class="px-3 py-1 flex items-center text-nerve hover:bg-nerve-100 rounded-md font-semibold" @click.prevent="() => ($bus('tour:next', { guide: 'nerve-on' }), exit())">
                            <NIcon as="play-circle-regular" class="mr-1" />Watch video
                        </button>
                    </div>
                    
                    <div class="flex justify-center">
                        <button aria-label="button" type="button" class="px-4 py-1 flex items-center text-white bg-nerve rounded-md hover:bg-nerve-600" @click.prevent="exit">
                            Close
                        </button>
                    </div>
                </div>
            </template>
        </Guide>
    </Tour>
</template>
