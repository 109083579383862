import FloatingVue from 'floating-vue';

import 'floating-vue/dist/style.css';

export const install = ({ app }) => {
    app.use(FloatingVue, {
        themes: {
            tooltip: {
              // Delay (ms)
              delay: {
                show: 100,
                hide: 0,
              },
              distance: 8,
              instantMove: true,
              html: true,
            },
            dropdown: {
              distance: 8,
              disposeTimeout: 10,
            },
            details:{
                $extend: 'tooltip',
                $resetCss: true,
            },
            alert:{
                $extend: 'tooltip',
                $resetCss: true,
            },
            helper:{
                $extend: 'tooltip',
                $resetCss: true
            },
            tip:{
                $extend: 'dropdown',
                $resetCss: true
            },
            info:{
                $extend: 'dropdown',
                $resetCss: true
            },
            raw:{
                $extend: 'tooltip',
                $resetCss: true
            },
            report:{
                $extend: 'dropdown',
                $resetCss: true,
                disposeTimeout: 0,
                distance: 0,
            },
            menu:{
                $extend: 'dropdown',
                $resetCss: true,
                disposeTimeout: 0,
                distance: 0,
            },
            note:{
                $extend: 'tooltip',
                $resetCss: true
            },
            nerve:{
                $extend: 'tooltip',
            },
          },
    });
}
