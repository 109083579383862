<script>
import LoadMore from '@/layouts/partials/LoadMore.vue';
import useQueryable from '@/mixins/Queryable';
import SuggestionBoxCard from './SuggestionBoxCard.vue';
import CardPlaceholder from '@/components/CardPlaceholder.vue';

export default {
    name: 'SuggestionBoxes',

    components: {
        SuggestionBoxCard,
        CardPlaceholder,
        LoadMore,
    },

    props: {
        filter: {
            type: String,
            default: 'suggestion'
        },
        title: {
            type: String,
            default: 'Ongoing Suggestion Box'
        }
    },

    mixins: [useQueryable],

    watch: {
        filter (sort) {
            this.reset();

            this.fetch({ page: 0, size: 18, sort });
        },
        '$route.params.category' (category) {
            this.reset();

            this.fetch({ page: 0, category });
        }
    },

    bus: {
        'catalyst:selected' ({ handle }) {
            this.reset();

            this.fetch({ page: 0, catalyst: handle });
        }
    },

    data() {
        return {
            offset: 3,
            busy: false,
            query: {
                page: 0,
                size: 3,
                status: 'open',
                sort: this.filter,
                catalyst: this.$route.params.user,
                category: this.$route.params.category,
            }
        };
    },

    computed: {
        totalPrize () {
            return this.response.items.reduce((t, i) => t + Number(i.prize_money), 0);
        }
    },

    methods: {
        fetch(query = {}) {
            if (this.busy) {
                return;
            }

            this.busy = true;
            this.$nerve.challenges.suggestions(this.$platform.current.id, { ...this.query, ...query })
                .then(response => {
                    this.merge(response.data);
                }).catch(error => {
                    //
                }).finally(() => {
                    this.busy = false;
                });
        },
        nextPage({ size } = { }) {
            if (this.query.size !== this.offset){
                this.query.page++;
            }

            this.query.size = size || this.query.size;
        }
    },

    mounted() {
        this.reset();
        
        this.fetch({ page: 0, catalyst: this.$route.params.user });
    },
}
</script>

<template>
    <section class="card-section">
        <h2 class="mb-4 text-xl font-narrow text-secondary">
            {{ title }} <span v-if="totalPrize > 0">- ${{ totalPrize }} total</span>
        </h2>
        <div v-show="!isEmpty">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <SuggestionBoxCard
                    v-for="(challenge, index) in response.items"
                    :key="`${index}-open-${challenge.id}`"
                    :challenge="challenge"
                />
            </div>
            <div v-if="hasMore" class="flex justify-center">
                <LoadMore :loading="busy" :handler="() => nextPage({ size: 18 })" />
            </div>
        </div>
        <div v-if="busy && isEmpty">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
                <CardPlaceholder v-for="i of 6" :key="`open=placeholder-${i}`">
                    <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
                </CardPlaceholder>
            </div>
        </div>
        <p v-else-if="! busy && isEmpty" class="italic">
            No suggestions match this filter!
        </p>
    </section>
</template>
