import {
    sentry as Sentry
} from '@/modules/sentry';

export const captureException = axios => {
    axios.interceptors.request.use(config => {
        config.startTime = new Date().getTime();
        return config;
    });
    axios.interceptors.response.use(null, (error) => {
        const { status, data } = error.response || {};

        if (![401, 412, 422].includes(status)) {
            Sentry.withScope(scope => {
                // needs to check if is not a timeout error as well
                if (false && error.response === undefined) {
                    scope.addBreadcrumb({
                        category: 'CORS',
                        message: 'This could be a CORS issue or a dropped internet connection',
                        level: 'error',
                        type: 'error'
                    });
                    scope.setTag('probable_cause', 'CORS');
                }

                scope.setTag('axios.error', error.isAxiosError ? 'yes' : 'no')
                scope.setTag('status-code', status || 'unknown');

                if (error.config) {
                    scope.setContext('request', {
                        url: `${error.config.baseURL}${error.config.url}`,
                        params: error.config.params ? JSON.stringify(error.config.params) : 'no',
                        method: error.config.method,
                        data: error.config.data ? JSON.stringify(error.config.data) : 'no',
                        headers: JSON.stringify(error.config.headers),
                        duration: `${new Date().getTime() - error.config.startTime} ms`,
                        retry: error.config.retryRequest ? JSON.stringify(error.config.retryRequest) : 'no',
                        timeout: error.config.timeout || 'no',
                    });

                    scope.setFingerprint(['{{default}}', status || 'unknown']);
                }

                scope.setContext('response', {
                    statusCode: status || 'no',
                    data: data || 'no',
                    headers: error.response ? error.response.headers : 'no',
                });

                Sentry.captureException(error);
            });
        }

        // used by sentry middleware to ignore this error
        error.captured = true;

        return Promise.reject(error);
    });
};
