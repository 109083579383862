import moment from 'moment';
import Cookie from '@/utils/Cookie';
import Storage from '@/utils/Storage';
import clickOutside from '@/directives/click-outside';
import flagDirective from '@/directives/feature-flag';
import { vElementVisibility } from '@vueuse/components';

import NIcon from '@/components/NIcon.vue';
import NLoading from '@/components/NLoading.vue';
import NModal from '@/components/NModal.vue';
import NImage from '@/components/NImage.vue';
import NPlayerDetails from '@/components/game/players/Details.vue';
import NPlayers from '@/components/game/players/Participants.vue';
import NTimeRemaining from '@/components/TimeRemaining.vue';
import NLoadingNerve from '@/components/NLoadingNerve.vue';
import NAvatar from '@/components/NAvatar.vue';
import NTabs from '@/components/tabs/Tabs.vue';
import NTab from '@/components/tabs/Tab.vue';
import NMarkdown from '@/components/NMarkdown.vue';
import NDropdownMenu from '@/components/NDropdownMenu.vue';
import NNotify from '@/components/NNotify.vue';
import NTip from '@/components/NTip.vue';
import NBrain from '@/components/NBrain.vue';
import NPageHeader from '@/components/NPageHeader.vue';
import NSwitch from '@/components/NSwitch.vue';
import NSharingPopup from '@/components/sharing/Popup.vue';
import NExpansionPanel from '@/components/NExpansionPanel.vue';
import NCropper from '@/components/form/Cropper.vue';
import NAlert from '@/components/NAlert.vue';
import NBreadcrumbs from '@/components/NBreadcrumbs.vue';
import NRating from '@/components/NRating.vue';
import NNote from '@/components/NNote.vue';
import NRankHistogram from '@/components/game/rank-histogram/index.vue';
import NEncouraging from '@/components/NEncouraging.vue';
import NFilters from '@/components/filters/Filters.vue';
import NFilterItem from '@/components/filters/FilterItem.vue';
import NChip from '@/components/NChip.vue';
import NAdvice from '@/components/NAdvice.vue';
import NBubble from '@/components/NBubble.vue';
import NExperiencePoints from '@/components/game/brain/ExperiencePoints.vue';
import NOverlay from "@/components/NOverlay.vue";
import NCopy from "@/components/NCopy.vue";
import NNavbarNotification from "@/components/NNavbarNotification.vue";

import NForm from '@/components/form/Form.vue';
import NField from '@/components/form/Field.vue';
import NInput from '@/components/form/Input.vue';
import NTextarea from '@/components/form/Textarea.vue';
import NSelect from '@/components/form/Select.vue';
import NCheckboxGroup from '@/components/form/CheckboxGroup.vue';
import NCheckbox from '@/components/form/Checkbox.vue';
import NRadioGroup from '@/components/form/RadioGroup.vue';
import NRadio from '@/components/form/Radio.vue';
import NDatepicker from '@/components/form/Datepicker.vue';
import NTags from '@/components/form/Tags.vue';
import NFile from '@/components/form/File.vue';
import NRange from '@/components/form/Range.vue';
import NAutocomplete from '@/components/form/Autocomplete.vue';

import NBrainLevelDemo from '@/components/demo/BrainLevelDemo.vue';
import NNeuronsEarnedDemo from '@/components/demo/NeuronsEarnedDemo.vue';

import FeatureFlag from '@/components/FeatureFlag.vue'

import '@bignerve/ui-icons';

export const install = ({ app }) => {
    window.moment = moment;
    window.Cookie = Cookie;
    window.Storage = Storage;
    window.log = (...args) => import.meta.env.DEV ? console.log(...args) : null;

    // components
    app.component('NIcon', NIcon);
    app.component('NLoading', NLoading);
    app.component('NModal', NModal);
    app.component('NImage', NImage);
    app.component('NPlayerDetails', NPlayerDetails);
    app.component('NTimeRemaining', NTimeRemaining);
    app.component('NLoadingNerve', NLoadingNerve);
    app.component('NAvatar', NAvatar);
    app.component('NPlayers', NPlayers);
    app.component('NTabs', NTabs);
    app.component('NTab', NTab);
    app.component('NMarkdown', NMarkdown);
    app.component('NDropdownMenu', NDropdownMenu);
    app.component('NNotify', NNotify);
    app.component('NTip', NTip);
    app.component('NBrain', NBrain);
    app.component('NPageHeader', NPageHeader);
    app.component('NAlert', NAlert);
    app.component('NSwitch', NSwitch);
    app.component('NBreadcrumbs', NBreadcrumbs);
    app.component('NRating', NRating);
    app.component('NNote', NNote);
    app.component('NSharingPopup', NSharingPopup);
    app.component('NExpansionPanel', NExpansionPanel);
    app.component('NAutocomplete', NAutocomplete);
    app.component('NCropper', NCropper);
    app.component('NRankHistogram', NRankHistogram);
    app.component('NEncouraging', NEncouraging);
    app.component('NFilters', NFilters);
    app.component('NFilterItem', NFilterItem);
    app.component('NChip', NChip);
    app.component('NAdvice', NAdvice);
    app.component('NBubble', NBubble);
    app.component('NExperiencePoints', NExperiencePoints);
    app.component('NOverlay', NOverlay);
    app.component('NCopy', NCopy);
    app.component('NNavbarNotification', NNavbarNotification);

    // Form components
    app.component('NDataForm', NForm);
    app.component('NField', NField);
    app.component('NInput', NInput);
    app.component('NTextarea', NTextarea);
    app.component('NSelect', NSelect);
    app.component('NCheckboxGroup', NCheckboxGroup);
    app.component('NCheckbox', NCheckbox);
    app.component('NRadioGroup', NRadioGroup);
    app.component('NRadio', NRadio);
    app.component('NDatepicker', NDatepicker);
    app.component('NTags', NTags);
    app.component('NFile', NFile);
    app.component('NRange', NRange);

    // demos component
    app.component('NBrainLevelDemo', NBrainLevelDemo);
    app.component('NNeuronsEarnedDemo', NNeuronsEarnedDemo);

    // other components
    app.component('FeatureFlag', FeatureFlag);

    // directives
    app.directive('click-outside', clickOutside);
    app.directive('visible', vElementVisibility);
    app.directive('flag', flagDirective);

    console.log('[app] Global resources installed');
}
