import { Session } from '@/utils/Auth';

export default (to, _, next) => {
	const redirect = `${import.meta.env.VUE_APP_ACCOUNT_URL}/gateway`;
	const continueURL = encodeURIComponent(`${location.origin}${to.fullPath}`);
	const [, appName] = location.hostname.split('.').reverse();

	return !Session.isAuthenticated()
		? location.replace(`${redirect}?continue=${continueURL}&platform=${appName}`)
		: next();
};
