<script>
export default {
    name: 'ChallengeRating',
    props: {
        challenge: {
            type: Object,
            required: true,
        },
    },
    emits: ['rated'],
    data () {
        const isOwner = this.challenge.catalyst.id === this.$auth.user.id;
        return {
            isOwner,
            busy: false,
            shake: false,
            hoveredRating: 0,
            rating: isOwner
                ? this.challenge.stats.rating_challenge
                : this.challenge.user_stats.rating_value
        }
    },
    computed: {
        stats () {
            return this.challenge.user_stats || {};
        },
        isRated () {
            return this.isOwner || this.stats.rated;
        },
        communityRating () {
            return this.challenge.stats.rating_challenge;
        },
        ratings () {
            return this.challenge?.stats?.rating_challenge_total || 0;
        },
        showRating () {
            return this.isOwner ? this.ratings > 1 : true;
        },
        currentRating () {
            const rating = () => {
                if (!this.showRating) {
                    return 0;
                }

                if (this.isOwner) {
                    return this.challenge.stats.rating_challenge;
                }

                return this.hoveredRating || (this.isRated
                    ? this.stats.rating_value
                    : 0
                ) || 0;
            }

            return rating().toFixed(1);
        },
        currentRatingCeil () {
            return Math.ceil(this.currentRating);
        },
    },
    methods: {
        setShowScale (scale) {
            this.hoveredRating = scale
        },
        async rate () {
            if (!this.rating) {
                this.shake = true;
                setTimeout(() => {
                    this.shake = false;
                }, 500);

                return;
            }

            try {
                this.busy = true;
                await this.$api.challenges.rate(this.challenge.slug, {
                    rating: this.rating,
                });
                this.challenge.user_stats.rating_value = this.rating;
                this.challenge.user_stats.rated = true;
                this.$emit('rated', this.challenge);
            } finally {
                this.busy = false;
            }
        }
    }
}
</script>

<template>
    <div class="current-challenge-rating-card">
        <div class="current-challenge-rating-card__title flex justify-between items-center mb-2">
            <h3 class="m-0">
                {{ isRated ? 'Challenge Rating' : 'Challenge Rating' }}
            </h3>
            <span v-if="isOwner && ratings > 1" class="m-0 text-sm font-semibold">
                {{ $t('component.challenge.rating.total-ratings', { total: ratings }) }}
            </span>

            <NExperiencePoints fqdn="analyst.ChallengeRated.RatedChallenge" #default="{ points }">
                <NBubble v-if="!!points && !isOwner && !isRated && rating" color="analyst" :animated="true">
                    +{{ points }} Neurons
                </NBubble>
            </NExperiencePoints>
        </div>
        <div class="current-challenge-rating-card__content relative flex flex-col items-center pt-2 overflow-hidden">
            <h3 v-if="!isOwner" class="text-dust-700">{{ !isRated ? $t('component.challenge.rating.notify.rate') : $t('component.challenge.rating.notify.rated') }}</h3>
            <h3 v-else class="text-dust-700">{{ $t('component.challenge.rating.notify.community_rating') }}</h3>
            <div class="pt-3 px-4" :class="{ 'animate-shake': shake }">
                <NRating
                    v-if="showRating"
                    v-model="rating"
                    :disabled="isRated"
                    :increment="0.1"
                    :format-tooltip="v => Number(v).toFixed(1)"
                    color="nerve"
                    class="mb-3 h-7 align-center max-w-xs"
                    inactive-color="#fff"
                    @hovered="value => setShowScale(value)"
                />

                <NRating
                    v-else
                    :modelValue="0"
                    class="mb-3 h-7 align-center max-w-xs"
                    inactive-color="#fff"
                    disabled
                />
            </div>
            <p class="mb-4 font-body italic" :class="!isRated ? 'text-nerve' : 'text-dust'">
                <Transition name="slide" mode="out-in">
                    <div :key="currentRatingCeil" class="-mx-2">
                        {{ $t(`component.challenge.rating.scale.${currentRatingCeil ? currentRatingCeil : (!isRated ? 'rate' : 'unrated') }`) }}
                    </div>
                </Transition>
            </p>
            <div v-if="$auth.authenticated">
                <p v-if="isRated" class="text-nerve text-lg italic m-1 px-3 py-1 bg-nerve-100 rounded-md">
                    <span v-if="showRating">
                        {{ isOwner ? 'Community' : 'Your' }} Rating <b>{{ currentRating }}</b>
                    </span>
                    <span v-else>
                        {{ $t('component.challenge.rating.no-ratings-yet') }}
                    </span>
                </p>
                <button aria-label="button" v-else :disabled="busy" type="button" class="px-3 py-1 bg-nerve rounded-md text-white hover:bg-nerve-600 flex items-center justify-center" @click="rate">
                    {{ busy ? $t('component.challenge.rating.sending') : $t('component.challenge.rating.action.save') }}
                    <NLoading v-if="busy" class="text-white ml-2 -mr-2" loading />
                </button>
            </div>
            <p v-else class="mt-2 mb-2 text-dust italic">
                {{ $t('component.challenge.rating.notify.authenticated') }}
            </p>
        </div>
    </div>
</template>
