<script setup>
import { Tab } from '@bignerve/ui-core';
import { useChannels } from '@/queries/discussion';
import { computed } from 'vue';
import { usePlatform } from '@/composable/platform';

const { currentPlatform } = usePlatform();
const platformSlug = computed(() => currentPlatform.value?.slug);
const { data, isFetching } = useChannels(platformSlug);
</script>

<template>
    <Tab v-for="channel of data" :key="channel.id" :to="{ name: 'discussion-forums.channel', params: { slug: channel.slug } }" class="text-dust-800 px-4 py-3 rounded-lg flex items-center leading-none cursor-pointer hover:text-dust-900 focus:text-dust-900 sm:rounded-none sm:rounded-l-lg gap-2" active-class="border-dust-400 bg-white border sm:border-r-0">
        <img :src="`/img/category-icons/${channel.icon}`" :alt="channel.name" class="h-4 w-4 filter invert-[70%] drop-shadow-md" @error="e => e.target.src = `/img/category-icons/article-draft-icon.svg`">
        {{ channel.name }}
    </Tab>

    <div v-if="isFetching" class="grid gap-6 py-3">
        <div v-for="i of 4" :key="i" class="animate-loading px-3 flex items-center gap-1">
            <div class="h-[1.5rem] w-[1.5rem] bg-dust-300 rounded-full"></div>
            <div class="h-[1rem] w-[4rem] bg-dust-300 rounded-md"></div>
            <div class="h-[1rem] w-[2rem] bg-dust-300 rounded-md"></div>
        </div>
    </div>
</template>