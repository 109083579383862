<script setup>
import OpenChallengeCard from '@/components/home/OpenChallengeCard.vue';
import ClosedChallengeCard from '@/components/home/ClosedChallengeCard.vue';
import CardPlaceholder from '@/components/CardPlaceholder.vue';
import { useCategory } from '@/composable/category';
import { usePlatform } from '@/composable/platform';
import { useInfiniteScroll } from '@vueuse/core';
import { api } from '@/modules/services';
import { useRouteQuery, useRouteParams } from '@vueuse/router';
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const toBool = (value) => value === 'on';

const user = useRouteParams('user');
const query = useRouteQuery('q', '');
const rootCategory = useRouteQuery('category', '');
const mainCategory = useRouteQuery('mc', '');
const includeCatalyst = useRouteQuery('ct', 'off', { transform: toBool });
const includeCategory = useRouteQuery('sc', 'off', { transform: toBool });
const includeClosed = useRouteQuery('cc', 'on', { transform: toBool });
const includeAll = useRouteQuery('all', 'off', { transform: toBool });
const challenges = ref([]);
const meta = ref({});
const searching = ref(false);
const route = useRoute();
const hasMore = computed(() => meta.value.total > challenges.value.length);
const isEmpty = computed(() => challenges.value.length === 0);
const { category: currentCategory } = useCategory(rootCategory);
const { platform } = usePlatform();
const { t } = useI18n();

const search = async (q) => {
    try {
        searching.value = true
        challenges.value = [];

        const { data: response } = await api.challenges.search({
            query: q,
            include_catalyst: includeCatalyst.value || undefined,
            include_category: includeCategory.value || undefined,
            include_closed: includeClosed.value || undefined,
            root_category: rootCategory.value || undefined,
            main_category: mainCategory.value || undefined,
            platform_slug: includeAll.value ? undefined : (user.value || platform.slug),
            strict: true, // only docuemtns with score greater than or equal to 0.1
        });

        challenges.value = response.data;
        meta.value = response.meta;
    } catch (e) {
        console.error(e);
    } finally {
        searching.value = false
    }
}

const loadMore = async () => {
    try {
        searching.value = true

        const { data: response } = await api.challenges.search({ scroll: meta.value.scroll });

        challenges.value = [...challenges.value, ...response.data];
        meta.value = response.meta;
    } catch (e) {
        console.error(e);
    } finally {
        searching.value = false
    }
}

watch([rootCategory, mainCategory, includeCategory, includeCatalyst, query, includeAll, includeClosed], () => {
    if (route.name === 'home.challenges.search') {
        search(query.value);
    }
});

useInfiniteScroll(
	document,
	() => {
		if (searching.value || !hasMore.value || !challenges.value.length) return;

		loadMore();
	},
	{ distance: 1000 }
);

if (!searching.value) {
    search(query.value);
}
</script>

<template>
  <section>
        <div class="flex items-center justify-between mb-4">
            <slot name="header">
                <div class="flex items-center justify-start gap-2">
                    <div class="flex items-center gap-2 text-secondary text-xl font-narrow">
                        <h2 class="m-0 flex-1">
                            SEARCH RESULTS
                        </h2>
                        <span v-if="meta.total" class="!text-normal">- {{ t('page.search.matches', meta, meta.total) }}</span>
                        <template v-if="currentCategory">in <b>{{ currentCategory.name }}</b></template>
                    </div>
                    <button aria-label="button"  v-if="meta.total" v-tooltip="'Share this searching'" class="w-8 h-8 rounded-md flex items-center justify-center hover:bg-dust-200" @click.prevent="$bus('sharing:popup', {})">
                        <NIcon as="share-square-solid" />
                    </button>
                </div>
            </slot>
        </div>

        <div v-if="!searching && isEmpty" class="py-20">
            <p v-if="query" class="italic text-center">
                {{ t('page.search.no-result') }}
            </p>
            <p v-else class="italic text-center">
                {{ t('page.search.no-query') }}
            </p>
        </div>

        <div class="overflow-hidden">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <Component
                    v-for="(challenge, index) in challenges"
                    :is="challenge.is_closed ? ClosedChallengeCard : OpenChallengeCard"
                    :key="index"
                    :challenge="challenge"
                    class="h-auto"
                />

                <template v-if="searching">
                    <CardPlaceholder v-for="i of 9" :key="`tease-placeholder-${i}`">
                        <circle v-for="cx of [45, 100, 150]" :key="cx" x="0" y="0" :cx="cx" cy="440" r="20"  />
                    </CardPlaceholder>
                </template>
            </div>
        </div>
  </section>
</template>