export default {
    props: {
        modelValue: {
            default: false,
        }
    },

    emits: ['open', 'close'],

    data() {
        return {
            isOpen: !!this.modelValue,
            payload: null,
        };
    },

    watch: {
        modelValue (value) {
            this.isOpen = value
        }
    },

    methods: {
        open(payload = {}) {
            this.payload = payload;

            if (! this.isOpen) {
                this.isOpen = true;
                this.$emit('open');
                this.afterOpen();
            }
        },
        close() {
            if (this.isOpen) {
                this.isOpen = false;
                this.$emit('close');
                this.afterClose();
            }
        },
        afterOpen () {},
        afterClose () {},
    }
}
