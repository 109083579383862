<script>
import { nanoid } from 'nanoid';
import NFireworks from '@/components/NFireworks.vue';

export default {
    name: 'RatingStandardDeviation',

    components: {
        NFireworks,
    },

    props: {
        placement: {
            type: String,
            default: 'bottom'
        },
        open: {
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            groupId: `rating-standard-deviation-${nanoid()}`,
        }
    },

    emits: ['closed'],

    watch: {
        open (isOpen) {
            const note = this.$refs.note;

            isOpen
                ? !this.show && note.show()
                : this.show && note.hide();

            if (isOpen) {
                // close after 4 seconds
                setTimeout(() => {
                    try {
                        this.$sentry.addBreadcrumb({
                            message: 'Close standard deviation note',
                            category: 'rating',
                            level: 'info',
                            data: {
                                open: isOpen,
                                component: note,
                            },
                        });

                        this.show && note.hide();
                    } catch (e) {
                        console.error(e.message);
                    }
                }, 4000);
            }
        }
    },

    computed: {
        show () {
            return this.$children[0].$children[0].open;
        }
    },

    bus: {
        'challenge:idea:rating' () {
            this.hide();
        },
        'challenge:mentoring-rating:done' () {
            this.hide();
        },
    },

    methods: {
        hide () {
            this.show && this.$refs.note.hide();
        }
    }
}
</script>

<template>
    <VTooltip
        :shown="open"
        :placement="placement"
        :auto-hide="true"
        :open-group="groupId"
        :triggers="[]"
        theme="note"
        ref="note"
        @hide="$emit('closed')"
    >
        <slot />

        <template #popper>
            <div class="bg-white rounded-lg shadow-high border max-w-xl p-2">
                <NFireworks :fire="open" />
                <div class="bg-dust-600 text-white p-3">
                    <slot name="content">
                        <h3>Kudos, you're rating well!</h3>
                        <p class="italic !mb-1 text-dust-100">You're aligned with the crowd</p>
                    </slot>
                    <div class="flex items-center justify-between">
                        <div>
                            <span class="px-3 py-1 text-xs bg-rater text-white shadow-high rounded-full">
                                +20 Neurons
                            </span>
                        </div>
                        <button aria-label="button" class="p-2 inline-flex items-baseline" @click="hide">
                            <NIcon as="times-circle-regular" class="ml-2" />
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </VTooltip>
</template>

<style lang="scss">
// .v-popper--theme-note[x-placement^=bottom] .v-popper__arrow {
//     border-width: 0px 14px 10px 14px;
//     top: -10px;
//     left: calc(50% - 14px);
//     margin-top: 0;
//     margin-bottom: 0;

//     &::before {
//         content: "";
//         @apply border-white;
//         border-width: 15px 20px 15px 20px;
//         height: 0;
//         width: 0;
//         border-style: solid;
//         position: absolute;
//         left: calc(50% - 20px);
//         border-left-color: transparent !important;
//         border-right-color: transparent !important;
//         border-top-color: transparent !important;
//         top: -14px;
//     }

//     &::after {
//         content: "";
//         @apply border-dust-600;
//         border-width: 19px 23px 16px 23px;
//         height: 0;
//         border-style: solid;
//         position: absolute;
//         width: 0;
//         left: calc(50% - 23px);
//         border-left-color: transparent !important;
//         border-right-color: transparent !important;
//         border-top-color: transparent !important;
//         top: -10px;
//     }
// }

// .v-popper--theme-note[x-placement^=top] .v-popper__arrow {
//     border-width: 0px 14px 10px 14px;
//     bottom: -20px;
//     left: calc(50% - 14px);
//     margin-top: 0;
//     margin-bottom: 0;

//     &::before {
//         content: "";
//         @apply border-white;
//         border-width: 15px 20px 15px 20px;
//         height: 0;
//         width: 0;
//         border-style: solid;
//         position: absolute;
//         left: calc(50% - 20px);
//         border-left-color: transparent !important;
//         border-right-color: transparent !important;
//         border-bottom-color: transparent !important;
//         bottom: -14px;
//     }

//     &::after {
//         content: "";
//         @apply border-dust-600;
//         border-width: 19px 23px 16px 23px;
//         height: 0;
//         border-style: solid;
//         position: absolute;
//         width: 0;
//         left: calc(50% - 23px);
//         border-left-color: transparent !important;
//         border-right-color: transparent !important;
//         border-bottom-color: transparent !important;
//         bottom: -10px;
//     }
// }

// .v-popper--theme-note[x-placement^=left] .v-popper__arrow {
//     border-width: 15px 20px 15px 20px;
//     right: -40px;
//     margin-top: 0;
//     margin-bottom: 0;

//     &::before {
//         content: "";
//         @apply border-white;
//         border-width: 15px 20px 15px 20px;
//         height: 0;
//         width: 0;
//         border-style: solid;
//         position: absolute;
//         top: calc(50% - 15px);
//         border-top-color: transparent !important;
//         border-right-color: transparent !important;
//         border-bottom-color: transparent !important;
//         right: -19px;
//     }

//     &::after {
//         content: "";
//         @apply border-dust-600;
//         border-width: 19px 23px 16px 23px;
//         height: 0;
//         border-style: solid;
//         position: absolute;
//         width: 0;
//         top: calc(50% - 19px);
//         border-top-color: transparent !important;
//         border-right-color: transparent !important;
//         border-bottom-color: transparent !important;
//         right: -10px;
//     }
// }
</style>
