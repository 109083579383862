export const MEDAL = {
    CATALYST_GOLD: 'CatalystGoldMedal',
    CATALYST_SILVER: 'CatalystSilverMedal',
    CATALYST_BRONZE: 'CatalystBronzeMedal',
    COMMUNITY_GOLD: 'CommunityGoldMedal',
    COMMUNITY_SILVER: 'CommunitySilverMedal',
    COMMUNITY_BRONZE: 'CommunityBronzeMedal',
    BLUE: 'BlueMedal',
}

export const VISIBILITY = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
}

export const PAYMENT_STATUS = {
    PENDING: 'PENDING',
    PAID: 'PAID',
    FAILED: 'FAILED',
    REFUNDED: 'REFUNDED',
    DECLINED: 'DECLINED',
}

export const FEE_STATUS = {
    PENDING: 'PENDING',
    EXPIRED: 'EXPIRED',
    DECLINED: 'DECLINED',
    COMPLETED: 'COMPLETED',
    ON_EXTENSION: 'ON_EXTENSION',
}
